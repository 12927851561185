import { PortVisibility } from '@syncfusion/ej2-react-diagrams';
import { DiagramHelperUtil, MapTypes } from './diagram-helper-util';
import { DiagramJsonHelper } from './diagram-json-helper';
import { DiagramSelectionHelper } from './diagram-selection-helper';
export class DiagramOkrHelper {
	diagramIns: any;
	diagramTemplateHelper: any;
	firstRowHeight: any;
	krHeight: any;
	margin: any;
	krSpace: any;
	iconInfo: any;
	port: any;
	bindProps: any;
	distInfoLineDefaultStyle = DiagramHelperUtil.distInfoLineDefaultStyle;
	distInfoNodeDefaultStyle = DiagramHelperUtil.distInfoNodeDefaultStyle;
	ref_overview: any = null;
	userDetail: any = null;
	diagramSelectionHelper: any;
	constructor(userDetail: any = null) {
		this.userDetail = userDetail;
		this.Reset();
	}
	Reset() {
		this.firstRowHeight = 190;
		let marginIn: number = 10;
		let iconMarginLeftIn: number = 6;
		let iconMarginTopIn: number = 6;
		let portYSpace: number = 22;
		if (
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewOkr
		) {
			this.firstRowHeight = 109;
			portYSpace = 20;
		}
		if (
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
		) {
			this.firstRowHeight = 52;
			marginIn = 5;
			iconMarginLeftIn = 8;
			iconMarginTopIn = 5;
			portYSpace = 13;
		}
		this.krHeight = 36;
		this.margin = { left: marginIn, top: marginIn, right: marginIn, bottom: marginIn };
		this.krSpace = 5;
		this.iconInfo = {
			width: 24,
			height: 24,
			margin: { left: iconMarginLeftIn, top: iconMarginTopIn, right: 15, bottom: 10 },
		};
		this.port = { width: 24, height: 24, x: this.iconInfo.margin.left, y: portYSpace + this.iconInfo.margin.top };
		if (
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewOkr
		) {
			this.port = { width: 24, height: 24, x: this.iconInfo.margin.left, y: portYSpace + this.iconInfo.margin.top + 2 };
		}
		this.bindProps = DiagramJsonHelper.bindProps;
	}
	SetHelpers(diagramInstance: any, diagramSelectionHelper: DiagramSelectionHelper) {
		this.diagramIns = diagramInstance;
		this.diagramSelectionHelper = diagramSelectionHelper;
	}

	//#region - Parent Connector Port Creation
	getLeftPort(node: any) {
		return this.getPort(node, 'left');
	}
	getRightPort(node: any) {
		return this.getPort(node, 'right');
	}
	getVRightPort(node: any) {
		return this.getPort(node, 'v-right');
	}
	getVLeftPort(node: any) {
		return this.getPort(node, 'v-left');
	}
	getPort(node: any, type: string, style?: any) {
		let pt = this.getPortPoints(node, type);
		let portId = node.id + '_' + type + '-port';
		let port = this.getPortFromNode(node, portId);
		if (port) {
			port.offset.x = pt.x;
			port.offset.y = pt.y;
			DiagramHelperUtil.RefreshUI(port);
		} else {
			style = !style
				? {
						strokeColor: 'none',
						fill: 'none',
				  }
				: style;
			//let actionId = `${node.data.actionLevel > 0 ? '-left-con-port' : '-left-port'}`;
			port = {
				style: style,
				width: pt.width,
				height: pt.height,
				shape: 'Square',
				id: node.id + '_' + type + '-port',
				visibility: PortVisibility.Visible,
				offset: {
					x: pt.x,
					y: pt.y,
				},
			};
		}
		return port;
	}
	getPortFromNode(node: any, portId: string) {
		if (node.ports && node.ports.length > 0) return node.ports.find((p: any) => p.id === portId);
		return null;
	}
	getPortPoints(node: any, type: string) {
		let data = node.data;
		let portProp = { x: 0, y: (this.port.y * 100) / node.height / 100, width: 0, height: 0, isVisible: false };
		let ptX = ((this.port.x + this.port.width / 2) * 100) / node.width / 100;
		let isVisible = false;
		if (data) {
			if (type === 'v-right') {
				portProp.x = 1;
				if (data.isVirtualLink && data.isMyOkr) {
					portProp.x += ptX;
					if (data.isVirtualLink && data.isContributorExist) {
						portProp.y *= 2;
					}
					isVisible = true;
				}
				if (data.isParentVirtualLink) {
					portProp.y *= 2;
					isVisible = true;
				}
			} else if (type === 'right') {
				portProp.x = 1;
				if (data.isContributorExist && (!data.isParentOkr || data.isMyOkr)) {
					portProp.x += ptX;
					isVisible = true;
				} else if (data && data.sourceResponse === true) {
					ptX = ((this.port.x - 3) * 100) / node.width / 100;
					portProp.x += ptX;
					isVisible = true;
				}
			} else if (type === 'v-left') {
				if (data.isSourceExist && (data.isParentOkr || data.isMyOkr)) {
					portProp.x = -ptX;
					isVisible = true;
				}
				portProp.y *= 2;
			} else if (type === 'left') {
				if (data && data.isSourceExist && (data.isParentOkr || data.isMyOkr)) {
					portProp.x = -ptX;
					isVisible = true;
				} else if (data && data.isSourceExist && data.actionLevel > 0) {
					ptX = ((this.port.x + 3 + this.port.width / 2) * 100) / node.width / 100;
					portProp.x = -ptX;
					isVisible = true;
				}
			}
		}
		if (isVisible) {
			portProp.width = this.port.width;
			portProp.height = this.port.height;
			portProp.isVisible = isVisible;
		}
		return portProp;
	}
	UpdatePorts(node: any) {
		if (node && node.ports && node.ports.length > 0) {
			let portTypes = ['left', 'right', 'v-left', 'v-right'];
			for (let i = 0; i < node.ports.length; i++) {
				let pt = this.getPortPoints(node, portTypes[i]);
				node.ports[i].offset.x = pt.x;
				node.ports[i].offset.y = pt.y;
			}
		}
	}
	updateOverview() {
		if (this.ref_overview && this.ref_overview.current) this.ref_overview.current.updateOverview();
	}
	//#endregion

	//#region Okr Templated Nodes Creation
	GetOkrHeight(node: any) {
		let height = this.firstRowHeight;
		if (DiagramHelperUtil.MapType === MapTypes.Relax || DiagramHelperUtil.MapType === MapTypes.RelaxTeam) {
			if (node.data && node.data.objectiveType === 2) {
				height += 4;
			} else if (node.data && node.data.okrViewKeyResults && node.data.okrViewKeyResults.length > 0) {
				//height += Math.ceil((this.getTextWidth(node.data.name) / 275) - 1) * 12;
				let resCount = node.data.okrViewKeyResults.length;
				height += resCount * this.krHeight + (resCount - 1) * this.krSpace + 14;
			}
			height += this.margin.bottom;
		}
		if (DiagramHelperUtil.MapType === MapTypes.CompactViewOkr) {
			if (node.data && node.data.objectiveType === 2) {
				height = 113;
			}
		}
		return height;
	}
	CreateOkrNodes(diagramInstance: any, diagramSelectionHelper: DiagramSelectionHelper) {
		this.SetHelpers(diagramInstance, diagramSelectionHelper);
		let ins = this;
		let nodes = this.diagramIns.nodes;
		let len = nodes.length;
		for (let i = 0; i < len; i++) {
			ins.CreateOkrNode(nodes[i], diagramInstance);
		}
	}
	CreateOkrNode(node: any, diagramIns: any) {
		if (node.data) {
			this.CreateOkrTopItem(node, diagramIns);
			if (
				DiagramHelperUtil.MapType === MapTypes.Relax ||
				DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.RelaxTeam
			) {
				this.CreateKrResultItems(node, diagramIns);
			}
		}
		//this.UpdateChildOffsets(node);
	}

	CreateOkrTopItem(nodeModel: any, diagramIns: any) {
		let margin = this.margin;
		let data = nodeModel.data;
		data.diagramParentNodeId = nodeModel.id;
		// if (DiagramHelperUtil.IsTeamViewMap()) {
		// 	let okrTopItem = {
		// 		id: nodeModel.id + '_okrItem', // + data[DiagramJsonHelper.bindProps.okrId],
		// 		data: data,
		// 		height: this.firstRowHeight,
		// 		width: nodeModel.width - margin.right - margin.left,
		// 		shape: {
		// 			type: 'HTML',
		// 		},
		// 		style: {
		// 			fill: 'green',
		// 		},
		// 		excludeFromLayout: true,
		// 	};

		// 	this.AddChildren(nodeModel, okrTopItem, diagramIns);
		// }
		if (DiagramHelperUtil.IsRelaxViewMap()) {
			this.CreateVirtualContributorIcon(nodeModel, diagramIns);
			this.CreateVirtuaSourceIcon(nodeModel, diagramIns);
		}
		this.CreateSourceIcon(nodeModel, diagramIns);
		this.CreateContributorIcon(nodeModel, diagramIns);
	}
	CreateContributorIcon(node: any, diagramIns: any) {
		let data = node.data;
		if (data.isContributorExist && (!data.isParentOkr || data.isMyOkr)) {
			let contributorIcon = {
				id: node.id + '_contributorIcon',
				height: this.iconInfo.width,
				width: this.iconInfo.height,
				data: {
					diagramParentId: node.id,
					isContributorExist: data.isContributorExist,
					isVirtualLink: data.isVirtualLink,
					isParentOkr: data.isParentOkr,
					expanded: false,
				},
				style: {
					strokeColor: 'none',
					fill: 'none',
				},
				shape: {
					type: 'HTML',
				},
				visible: false,
				excludeFromLayout: true,
			};
			diagramIns.add(contributorIcon);
		}
	}
	CreateSourceIcon(node: any, diagramIns: any) {
		let data = node.data;
		if ((data.isSourceExist && (data.isParentOkr || data.isMyOkr)) || data.actionLevel > 0) {
			let sourceIcon = {
				id: node.id + '_sourceIcon',
				height: this.iconInfo.width,
				width: this.iconInfo.height,
				data: {
					isSourceExist: true,
					isParentOkr: data.isParentOkr,
					isobjectiveType: data.objectiveType,
					isactionLevel: data.actionLevel,
					diagramParentId: node.id,
					expanded: false,
				},
				style: {
					strokeColor: 'none',
					fill: 'none',
				},
				shape: {
					type: 'HTML',
				},
				visible: false,
				excludeFromLayout: true,
			};
			diagramIns.add(sourceIcon);
		}
	}
	CreateVirtualContributorIcon(node: any, diagramIns: any) {
		let data = node.data;
		if (data.isVirtualLink && data.isMyOkr) {
			let vContributorIcon = {
				id: node.id + '_contributorIcon_virtual',
				height: this.iconInfo.width,
				width: this.iconInfo.height,
				data: {
					diagramParentId: node.id,
					isContributorExist: data.isContributorExist,
					isVirtualLink: data.isVirtualLink,
					isMyOkr: data.isMyOkr,
					expanded: false,
				},
				style: {
					strokeColor: 'none',
					fill: 'none',
				},
				shape: {
					type: 'HTML',
				},
				visible: false,
				excludeFromLayout: true,
			};
			diagramIns.add(vContributorIcon);
		}
	}
	CreateVirtuaSourceIcon(node: any, diagramIns: any) {
		let data = node.data;
		const isOwnerLoggedIn =
			(data.teamId && data.owner === this.userDetail.employeeId && data.owner === data.employeeId) ||
			(!data.teamId && this.userDetail.employeeId === data.employeeId);
		if (data.isParentVirtualIcon && data.isMyOkr && isOwnerLoggedIn) {
			let vSourceIcon = {
				id: node.id + '_sourceIcon_virtual',
				height: this.iconInfo.width,
				width: this.iconInfo.height,
				data: {
					diagramParentId: node.id,
					isSourceExist: data.isSourceExist,
					isParentVirtualIcon: data.isParentVirtualIcon,
					isParentVirtualLink: data.isParentVirtualLink,
					isMyOkr: data.isMyOkr,
					expanded: false,
				},
				style: {
					strokeColor: 'none',
					fill: 'none',
				},
				visible: false,
				shape: {
					type: 'HTML',
				},
				excludeFromLayout: true,
			};
			diagramIns.add(vSourceIcon);
		}
	}
	AddChildren(parentNode: any, child: any, diagramIns: any) {
		diagramIns.add(child);
		//diagramIns.addChild(parentNode, child);
	}

	CreateKrResultItems(nodeModel: any, diagramIns: any) {
		let margin = this.margin;
		let okrData = nodeModel.data;
		let krNodes = [];
		let firstRowHeight = this.firstRowHeight; //+ Math.ceil((this.getTextWidth(okrData.name) / 275) - 1) * 12;
		if (okrData && okrData.okrViewKeyResults && okrData.okrViewKeyResults.length > 0) {
			for (let i = 0; i < okrData.okrViewKeyResults.length; i++) {
				let kr = okrData.okrViewKeyResults[i];
				let krId = kr[DiagramJsonHelper.bindProps.krId];
				let krWidth = nodeModel.width - margin.right - margin.left;
				okrData['krItemId'] = krId; //for kr result card template
				okrData['diagramParentNodeId'] = nodeModel.id;
				let krItem = {
					id: 'krResItem_' + krId,
					data: okrData,
					height: this.krHeight,
					width: krWidth - 4,
					// shape: {
					// 	type: 'HTML',
					// 	content: '<div style="width:100%;height:100%;background:transparent"></div>'
					// },
					style: {
						strokeColor: 'none',
						fill: 'none',
					},
					//margin: { left: margin.left, top: startY, right: 0, bottom: 0 },
					excludeFromLayout: true,
				};
				this.AddChildren(nodeModel, krItem, diagramIns);
				krNodes.push(krItem);
			}
		}
		return krNodes;
	}

	UpdateChildOffsets(node: any) {
		if (this.diagramIns) {
			let margin = this.margin;
			// if (DiagramHelperUtil.IsTeamViewMap()) {
			// 	let okrFirstRow = this.diagramIns.nodes.find((n: any) => n.id === node.id + '_okrItem');
			// 	if (okrFirstRow) {
			// 		okrFirstRow.offsetX = node.offsetX;
			// 		okrFirstRow.offsetY = node.offsetY - node.height / 2 + okrFirstRow.height / 2 + margin.top;
			// 	}
			// }
			this.UpdateKrResultNodeOffsets(node, this.diagramIns);
			this.UpdateContributorIconOffsets(node, margin);
			this.UpdateVirtualContributorIconOffsets(node, margin);
			this.UpdateSourceIconOffsets(node, margin);
			this.UpdateVirtualSourceIconOffsets(node, margin);
		}
	}
	UpdateContributorIconOffsets(node: any, margin: any) {
		let contributorIcon = this.diagramIns.nodes.find((n: any) => n.id === node.id + '_contributorIcon');
		if (contributorIcon) {
			contributorIcon.offsetX = node.offsetX + node.width / 2 + this.iconInfo.margin.left;

			contributorIcon.offsetY =
				node.offsetY - node.height / 2 + contributorIcon.height / 2 + margin.top + this.iconInfo.margin.top;
			if (
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
			) {
				// contributorIcon.offsetX += 1;
				contributorIcon.offsetY -= 5;
			}
			DiagramHelperUtil.SetVisibility(contributorIcon, true);
			DiagramHelperUtil.RefreshUI(contributorIcon);
		}
	}
	UpdateVirtualContributorIconOffsets(node: any, margin: any) {
		let vContributorIcon = this.diagramIns.nodes.find((n: any) => n.id === node.id + '_contributorIcon_virtual');
		if (vContributorIcon) {
			if (
				DiagramHelperUtil.MapType === MapTypes.Relax ||
				DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr
			) {
				vContributorIcon.offsetX = node.offsetX + node.width / 2 + this.iconInfo.margin.left;
				// if (
				// 	DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				// 	DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
				// ) {
				// 	vContributorIcon.offsetX += 1;
				// }
				vContributorIcon.offsetY =
					node.offsetY - node.height / 2 + vContributorIcon.height / 2 + margin.top + this.iconInfo.margin.top;
				if (vContributorIcon.data && vContributorIcon.data.isContributorExist)
					vContributorIcon.offsetY += vContributorIcon.height / 2 + margin.top + this.iconInfo.margin.top;

				if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
					vContributorIcon.offsetY -= 5;
				}
				DiagramHelperUtil.SetVisibility(vContributorIcon, true);
			}
			DiagramHelperUtil.RefreshUI(vContributorIcon);
		}
	}

	UpdateSourceIconOffsets(node: any, margin: any) {
		let sourceIcon = this.diagramIns.nodes.find((n: any) => n.id === node.id + '_sourceIcon');
		if (sourceIcon) {
			sourceIcon.offsetX = node.offsetX - node.width / 2 - this.iconInfo.margin.left;
			sourceIcon.offsetY =
				node.offsetY - node.height / 2 + sourceIcon.height / 2 + margin.top + this.iconInfo.margin.top;
			if (
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
			) {
				sourceIcon.offsetY -= 5;
			}
			DiagramHelperUtil.SetVisibility(sourceIcon, true);
			DiagramHelperUtil.RefreshUI(sourceIcon);
		}
	}
	UpdateVirtualSourceIconOffsets(node: any, margin: any) {
		let vSourceIcon = this.diagramIns.nodes.find((n: any) => n.id === node.id + '_sourceIcon_virtual');
		if (vSourceIcon) {
			vSourceIcon.offsetX = node.offsetX - node.width / 2 - this.iconInfo.margin.left;
			vSourceIcon.offsetY =
				node.offsetY - node.height / 2 + vSourceIcon.height / 2 + margin.top + this.iconInfo.margin.top;

			if (vSourceIcon.data && vSourceIcon.data.isSourceExist)
				vSourceIcon.offsetY += vSourceIcon.height / 2 + margin.top + this.iconInfo.margin.top;

			if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
				vSourceIcon.offsetY -= 5;
			}
			DiagramHelperUtil.SetVisibility(vSourceIcon, true);
			DiagramHelperUtil.RefreshUI(vSourceIcon);
		}
	}
	UpdateKrResultNodeOffsets(node: any, diagramIns: any) {
		let okrData = node.data;
		let krFullHeight = 0;
		let krMarginLeft = 0;
		if (
			DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam
		) {
			krMarginLeft = 0; //-10;
		}
		if (okrData && okrData.okrViewKeyResults && okrData.okrViewKeyResults.length > 0) {
			let margin = this.margin;
			let startY = node.offsetY - node.height / 2 + this.margin.top + this.firstRowHeight + this.krSpace + 23;
			for (let i = 0; i < okrData.okrViewKeyResults.length; i++) {
				let kr = okrData.okrViewKeyResults[i];
				let krId = kr[DiagramJsonHelper.bindProps.krId];
				let krItemNode = diagramIns.nodes.find((n: any) => n.id === 'krResItem_' + krId);
				if (krItemNode) {
					krItemNode.offsetX = node.offsetX + krMarginLeft;
					krItemNode.offsetY = startY;
					startY += krItemNode.height + this.krSpace;
					krFullHeight += krItemNode.height + this.krSpace;

					let krWidth = node.width - margin.right - margin.left - 4;
					// krItemNode.height = this.krHeight
					krItemNode.width = krWidth;

					DiagramHelperUtil.RefreshUI(krItemNode);
				}
			}
		}
		return krFullHeight;
	}
	//#endregion

	//#region - Okr Labels Creation
	CreateTopLabels(diagramIns: any, myOkrData: any) {
		DiagramHelperUtil.TimerStart('okr label top');
		let x = myOkrData[0].offsetX;
		let y = myOkrData[0].offsetY;
		let h = myOkrData[0].height;
		let w = myOkrData[0].width;
		let hS = 200;
		let top = 50;
		let parentTitle: any = { text: 'PARENT OKRs', width: 98 };
		let okrTitle: any = { text: 'MY OKRs', width: 70 };
		let conTitle: any = { text: 'MY CONTRIBUTORS', width: 130 };
		if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
			hS = 180;
		}
		if (
			DiagramHelperUtil.MapType === MapTypes.RelaxTeam ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam ||
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
		) {
			hS = 180;
			okrTitle.text = 'TEAM OKRs';
			okrTitle.width = 88;
			conTitle.text = 'CONTRIBUTORS';
			conTitle.width = 102;
		}
		if (DiagramHelperUtil.MapType === MapTypes.RelaxTeam) {
			parentTitle.width = 102;
			hS = 200;
		}
		if (DiagramHelperUtil.MapType === MapTypes.CompactViewTeam) {
			hS = 200;
		}
		this.CreateOkrLabel(
			diagramIns,
			myOkrData[0],
			'okrlabel_My_OKRs',
			x,
			y - h / 2 - top,
			okrTitle.width,
			okrTitle.text
		);
		this.CreateOkrLabel(
			diagramIns,
			myOkrData[0],
			'okrlabel_Parent_OKRs',
			x - (w + hS),
			y - h / 2 - top,
			parentTitle.width,
			parentTitle.text
		);
		this.CreateOkrLabel(
			diagramIns,
			myOkrData[0],
			'okrlabel_Contributor_OKRs',
			x + (w + hS),
			y - h / 2 - top,
			conTitle.width,
			conTitle.text
		);
		DiagramHelperUtil.TimerEnd('okr label top end');
	}

	CreateInfoLabels(diagramIns: any, myOkrDataList: any, startIndex: any) {
		DiagramHelperUtil.TimerStart('info label distance node');
		this.CreateOkrDistanceNodes(diagramIns, myOkrDataList, startIndex);
		DiagramHelperUtil.RefreshDiagram(diagramIns);
		DiagramHelperUtil.TimerEnd('info label distance node done');
	}

	CreateOkrDistanceNodes(diagramIns: any, myOkrList: any[], startIndex: any) {
		for (let i = startIndex; i < myOkrList.length - 1; i++) {
			let nextOkrsCount = myOkrList.length - (i + 1);
			let text: string = nextOkrsCount + ' MORE OKR';
			text += nextOkrsCount > 1 ? 's' : '';
			this.CreateDistanceInfoNode(diagramIns, myOkrList[i], myOkrList[i + 1], text);
		}
	}

	CreateDistanceInfoNode(diagramIns: any, srcOkrNode: any, targetOkrNode: any, text: string) {
		let topNodeId = 'topDistNode_' + srcOkrNode.id;
		let btmNodeId = 'btmDistNode_' + srcOkrNode.id;
		let linkId = topNodeId + '_dist_link_helper';
		let bottomLineId = topNodeId + '_dist_link_helper' + '_btm_line';

		if (targetOkrNode.offsetY - targetOkrNode.height / 2 - (srcOkrNode.offsetY + srcOkrNode.height / 2) >= 150) {
			let topInfoNode = this.GetInfoLabelsObj(diagramIns, topNodeId, 'nodes');
			let btmInfoNode = this.GetInfoLabelsObj(diagramIns, btmNodeId, 'nodes');

			if (topInfoNode) {
				topInfoNode.offsetX = srcOkrNode.offsetX;
				topInfoNode.offsetY = srcOkrNode.offsetY + srcOkrNode.height / 2 + 30;
				DiagramHelperUtil.SetVisibility(topInfoNode, true);
				DiagramHelperUtil.RefreshUI(topInfoNode);
			} else {
				topInfoNode = {
					id: topNodeId,
					offsetX: srcOkrNode.offsetX,
					offsetY: srcOkrNode.offsetY + srcOkrNode.height / 2 + 30,
					width: 100,
					height: 30,
					style: {
						fill: 'none',
						strokeColor: 'none',
					},
					annotations: [
						{
							content: text,
						},
					],
					excludeFromLayout: true,
				};
				diagramIns.add(topInfoNode);
			}
			if (btmInfoNode) {
				btmInfoNode.offsetX = targetOkrNode.offsetX;
				btmInfoNode.offsetY = targetOkrNode.offsetY - targetOkrNode.height / 2 - 30;
				DiagramHelperUtil.SetVisibility(btmInfoNode, true);
				DiagramHelperUtil.RefreshUI(btmInfoNode);
			} else {
				btmInfoNode = {
					id: btmNodeId,
					offsetX: srcOkrNode.offsetX,
					offsetY: targetOkrNode.offsetY - targetOkrNode.height / 2 - 30,
					width: srcOkrNode.width,
					height: 30,
					style: {
						fill: 'none',
						strokeColor: 'none',
					},
					excludeFromLayout: true,
				};
				diagramIns.add(btmInfoNode);
			}

			let linkObj = this.GetInfoLabelsObj(diagramIns, linkId, 'connectors');

			if (linkObj) {
				DiagramHelperUtil.SetVisibility(linkObj, true);
				DiagramHelperUtil.RefreshUI(linkObj);
			} else {
				linkObj = {
					id: linkId,
					sourceID: topInfoNode.id,
					targetID: btmInfoNode.id,
					sourceDecorator: { shape: 'None' },
					targetDecorator: { shape: 'None' },
					style: {
						strokeColor: this.distInfoLineDefaultStyle.strokeColor,
						strokeWidth: this.distInfoLineDefaultStyle.strokeWidth,
					},
				};
				diagramIns.add(linkObj);
			}

			let bottomLineObj = this.GetInfoLabelsObj(diagramIns, bottomLineId, 'connectors');
			if (bottomLineObj) {
				bottomLineObj.sourcePoint = bottomLineObj.sourcePoint;
				bottomLineObj.targetPoint = bottomLineObj.targetPoint;
				DiagramHelperUtil.SetVisibility(bottomLineObj, true);
				DiagramHelperUtil.RefreshUI(bottomLineObj);
			} else {
				bottomLineObj = {
					id: bottomLineId,
					sourcePoint: {
						x: topInfoNode.offsetX - topInfoNode.width / 2,
						y: topInfoNode.offsetY + topInfoNode.height / 2,
					},
					targetPoint: {
						x: topInfoNode.offsetX + topInfoNode.width / 2,
						y: topInfoNode.offsetY + topInfoNode.height / 2,
					},
					sourceDecorator: { shape: 'None' },
					targetDecorator: { shape: 'None' },
					style: {
						strokeColor: this.distInfoLineDefaultStyle.strokeColor,
						strokeWidth: this.distInfoLineDefaultStyle.strokeWidth,
					},
				};
				diagramIns.add(bottomLineObj);
			}
		} else {
			this.HideInfoLabelsObj(diagramIns, topNodeId, 'nodes');
			this.HideInfoLabelsObj(diagramIns, btmNodeId, 'nodes');
			this.HideInfoLabelsObj(diagramIns, linkId, 'connectors');
			this.HideInfoLabelsObj(diagramIns, bottomLineId, 'connectors');
		}
	}

	GetInfoLabelsObj(diagramIns: any, id: string, type: string) {
		let obj = diagramIns[type].find((n: any) => n.id === id);
		if (obj) {
			return obj;
		}
	}
	HideInfoLabelsObj(diagramIns: any, id: string, type: string) {
		let obj = diagramIns[type].find((n: any) => n.id === id);
		if (obj) {
			DiagramHelperUtil.SetVisibility(obj, false);
			DiagramHelperUtil.RefreshUI(obj);
		}
	}

	ClearIfExist(diagramIns: any, id: string, type: string) {
		let obj = diagramIns[type].find((n: any) => n.id === id);
		if (obj) {
			return diagramIns.remove(obj);
		}
	}

	CreateOkrLabel(diagramIns: any, okrNode: any, id: string, x: number, y: number, w: number, label: string) {
		let node = diagramIns.nodes.find((n: any) => n.id === id);
		let isUpdate = false;
		if (node) {
			node.height = 20;
			node.offsetX = x;
			node.offsetY = y;
			node.width = w;
			isUpdate = true;
			DiagramHelperUtil.RefreshUI(node);
		} else {
			node = {
				id: id,
				height: 20,
				offsetX: x,
				offsetY: y,
				width: w,
				style: {
					strokeColor: 'none',
					fill: 'none',
				},
				annotations: [
					{
						content: label,
					},
				],
				excludeFromLayout: true,
			};
			diagramIns.add(node);
		}
		if (
			DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam
		) {
			node.annotations[0].style = {
				color: '#292929',
			};
		}
		let leftLineId = node.id + '_lbl_link_helper' + '_left';
		let rightLineId = node.id + '_lbl_link_helper' + '_right';
		let leftLine, rightLine;
		if (isUpdate) {
			leftLine = diagramIns.connectors.find((c: any) => c.id === leftLineId);
			leftLine.sourcePoint = {
				x: node.offsetX - okrNode.width / 2 - 30,
				y: node.offsetY,
			};
			DiagramHelperUtil.RefreshUI(leftLine);
			rightLine = diagramIns.connectors.find((c: any) => c.id === rightLineId);
			rightLine.targetPoint = {
				x: node.offsetX + okrNode.width / 2 + 30,
				y: node.offsetY,
			};
			DiagramHelperUtil.RefreshUI(rightLine);
		} else {
			leftLine = {
				id: leftLineId,
				sourcePoint: {
					x: node.offsetX - okrNode.width / 2 - 30,
					y: node.offsetY,
				},
				targetID: node.id,
				sourceDecorator: { shape: 'None' },
				targetDecorator: { shape: 'None' },
				style: {
					strokeColor: this.distInfoLineDefaultStyle.strokeColor,
					strokeWidth: this.distInfoLineDefaultStyle.strokeWidth,
				},
			};

			rightLine = {
				id: rightLineId,
				sourceID: node.id,
				targetPoint: {
					x: node.offsetX + okrNode.width / 2 + 30,
					y: node.offsetY,
				},
				sourceDecorator: { shape: 'None' },
				targetDecorator: { shape: 'None' },
				style: {
					strokeColor: this.distInfoLineDefaultStyle.strokeColor,
					strokeWidth: this.distInfoLineDefaultStyle.strokeWidth,
				},
			};
			diagramIns.add(leftLine);
			diagramIns.add(rightLine);
		}
	}
	//#endregion
}

import React, { Fragment, useEffect, useState } from 'react';
import '../Goals/OkrListing/style.scss';
import { OKRDrawer } from '../Common/OKRDrawer';
import { KrDeclineAttributesDetail } from './KrDeclineAttributesDetail';
import clone from 'clone';
import { OneToOneModule } from '../Admin/AdminConstant';
import { checkPermission } from '../../config/rolePermission';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../config/enums';
import { useSnackbar } from 'notistack';
import { getUserDetails } from '../../config/utils';
import { getLocalStorageItem } from '../../services/StorageService';

export const KrDeclineAttributes: React.FC<any> = (props) => {
	const { open, okr, loader, kr, KRType, setFeedback, setModalProps, setOpenDrawer, setLoader, fetchOkr, isAlignment } = props;
	const keyData = KRType === 'WithParent' ? clone(kr) : clone(okr);
	const [orphanKRData, setOrphanKRData] = useState<any>(keyData);
	const [declineMessage, setDeclineMessage] = useState<any>(null);
	const [sourceContributor, setSourceContributor] = useState<any>();
	const goalId = KRType === 'WithParent' ? okr.goalObjectiveId : keyData.goalKeyId;
	const goalType = KRType === 'WithParent' ? 'kr' : 'okr';
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const loggedInUserDetail = getUserDetails();
	const year = getLocalStorageItem('currentYear') || null;
	const selectedYear = getLocalStorageItem('year') || year;
	const cycleId = getLocalStorageItem('cycleId') || 0;

	useEffect(() => {
		const { contributors } = kr;
		if (contributors) {
			const sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			setSourceContributor(sourceData);
		} else {
			const { contributors } = okr;
			const sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			setSourceContributor(sourceData);
		}
	}, [okr, kr]);

	useEffect(() => {
		const objectiveData = clone(okr);
		const myObj =
			KRType === 'WithParent'
				? { objectiveName: objectiveData?.objectiveName || '', goalObjectiveId: objectiveData?.goalObjectiveId }
				: {};
		setOrphanKRData({ ...keyData, ...myObj });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		loader && setDeclineMessage(null);
	}, [loader]);

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const showErrorMsgWithObject = (response: any) => {
		try {
			const errorMessages = Object.values(response?.data?.messageList);
			if (errorMessages && errorMessages.length > 0) {
				errorMessages.forEach((item: any) =>
					enqueueSnackbar(item, {
						variant: 'error',
						autoHideDuration: 5000,
					})
				);
			}
		} catch (e) {}
	};

	const handleDeclineDrawerClose = async (
		event: any,
		type: String,
		orphanKRData: any,
		declineMessage: any,
		okr: any,
		kr: any,
		goalId: any,
		goalType: any,
		sourceContributor: any
	) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'requestOneOnOne') {
			setFeedback({ open: true, reqType: 4, okr, kr, goalType, goalId, sourceContributor, declineMessage });
		} else if (type === 'close') {
			if (declineMessage) {
				setModalProps({
					open: true,
					type: 'DeclineKR',
					message: t('unSavedItemAlert'),
					module: 'user',
					moduleType: 1,
				});
			} else {
				setOpenDrawer({ open: false });
				setModalProps({ open: false, type: '', message: '', module: '' });
			}
		} else {
			const declineMessageValue = clone(declineMessage);
			const apiRequest = {
				goalKeyId: orphanKRData?.goalKeyId || 0,
				goalObjectiveId: orphanKRData?.goalObjectiveId || 0,
				keyDescription: orphanKRData?.keyDescription || '',
				objectiveName: orphanKRData?.objectiveName || '',
				score: orphanKRData?.score || 0,
				startDate: orphanKRData?.startDate || new Date(),
				dueDate: orphanKRData?.dueDate || new Date(),
				krStatusId: Enums.KR_STATUS_DECLINED,
				importedType: orphanKRData?.importedType || 0,
				importedId: orphanKRData?.importedId || 0,
				progress: orphanKRData?.progress || 0,
				source: orphanKRData?.source || 0,
				startValue: orphanKRData?.startValue || 0,
				targetValue: orphanKRData?.targetValue || 0,
				assignmentTypeId: orphanKRData?.assignmentTypeId || 0,
				krAssignerMessage: orphanKRData?.krAssignerMessage || '',
				krAssigneeMessage: declineMessageValue || '',
				currencyId: orphanKRData?.currencyId || 0,
				currencyCode: orphanKRData?.currencyCode || '',
				goalStatusId: Enums.TWO,
			};
			setLoader(true);
			const response = await props.acceptKr(apiRequest);
			setLoader(false);
			if (response && response.data.status === Enums.STATUS_SUCCESS) {
				setOpenDrawer({ open: false });
				setModalProps({ open: false, type: '', message: '', module: '' });
				showApiMsgs(response?.data?.messageList?.Result, 'success');
				fetchOkr();
				if (isAlignment) {
					const endpoint = `empId=${loggedInUserDetail && loggedInUserDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
					props.getAlignmentList(endpoint);
				}
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				showErrorMsgWithObject(response);
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				showApiMsgs(t('goalAddedFail'), 'error');
			}
		}
	};

	return (
		<Fragment>
			<OKRDrawer
				open={open}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={'Share your reasons for declining'}
				children={
					<KrDeclineAttributesDetail
						{...props}
						setOrphanKRData={setOrphanKRData}
						orphanKRData={orphanKRData}
						declineMessage={declineMessage}
						setDeclineMessage={setDeclineMessage}
					/>
				}
				handleDrawerClose={(event: any) => {
					event.preventDefault();
					event.stopPropagation();
					handleDeclineDrawerClose(
						event,
						'close',
						orphanKRData,
						declineMessage,
						okr,
						keyData,
						goalId,
						goalType,
						sourceContributor
					);
				}}
				handleSaveClick={(event: any) =>
					handleDeclineDrawerClose(
						event,
						'save',
						orphanKRData,
						declineMessage,
						okr,
						keyData,
						goalId,
						goalType,
						sourceContributor
					)
				}
				handleCancelClick={(event: any) => {
					event.preventDefault();
					event.stopPropagation();
					handleDeclineDrawerClose(
						event,
						'requestOneOnOne',
						orphanKRData,
						declineMessage,
						okr,
						kr,
						keyData.goalKeyId,
						'kr',
						sourceContributor
					);
				}}
				hideDefaultButton={false}
				cancelBtnText={'Request 1:1'}
				saveButtonText={'Decline'}
				cancelBtnClass={'request-btn'}
				cancelBtnIcon={''}
				loader={loader}
				isSaveButtonDisabled={!declineMessage ? true : false}
				isCancelButtonDisabled={
					checkPermission(props.getPermissionResult?.employeePermissions, OneToOneModule) ? false : true
				}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
		</Fragment>
	);
};

import React from 'react';
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormLabel,
	Grid,
	Switch,
	TextField,
	Typography,
	FormControl,
	Select,
	MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BorderCheckboxIcon, CheckedIcon } from '../../config/svg/formElementIcons';
import DatePicker from '../Common/DatePicker';

export const EditOrgForm: React.FC<any> = (props) => {
	const {
		currentItem,
		orgDetail,
		handleFieldChange,
		handleInputBlur,
		moduleList,
		handleModuleChange,
		defaultHomeScreenList,
		handleDropDownChange,
	} = props;
	const { t } = useTranslation();

	const handleSelectFinalRating = (value: any) => {
		if (value) {
			handleDropDownChange({ defaultHome: value });
		}
	};
	// console.log('orgDetail ===>', orgDetail);

	return (
		<>
			<form className='drawer-form-fields' noValidate autoComplete='off'>
				<Box className='drawer-form-fields-group'>
					<Box className='drawer-input-field drawer-input-field-col2'>
						<Box className='user-info-details'>
							<Typography variant='h4'>{orgDetail.companyName}</Typography>
							<Typography variant='subtitle2'>{orgDetail.subDomain}</Typography>
						</Box>
						<Box className='user-info-details'>
							<Typography variant='h4'>{orgDetail.adminUserName}</Typography>
							<Typography variant='subtitle2'>{orgDetail.adminEmailId}</Typography>
						</Box>
					</Box>
					{/* <Box className='drawer-input-field drawer-input-field-col2'>
						<TextField
							id='company-name'
							className='field-col2 helperText-bottom'
							label={<>{t('companyNameLabel')}</>}
							placeholder={t('companyNameLabel')}
							value={currentItem.companyName}
							name={t('companyNameLabel')}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{ 'aria-label': t('companyNameLabel'), disableUnderline: true }}
						/>
						<TextField
							id='subdomain-name'
							className='field-col2 helperText-bottom'
							label={<>{t('subDomainLabel')}</>}
							placeholder={t('subDomainLabel')}
							value={currentItem.subDomain}
							name={t('subDomainLabel')}
							//error={ ''}
							// helperText={
							// 	<>
							// 		<ErrorIcon />
							// 	</>
							// }
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{ 'aria-label': t('subDomainLabel'), disableUnderline: true }}
						/>
					</Box> */}
					<Box className='drawer-input-field drawer-input-field-col2'>
						<TextField
							id='purchase-license'
							className='field-col2 helperText-bottom'
							type='number'
							label={<>{t('purchaseLicenseLabel')}</>}
							placeholder={t('purchaseLicenseLabel')}
							value={orgDetail.purchaseLicense}
							name={'purchaseLicense'}
							onChange={handleFieldChange}
							InputLabelProps={{
								shrink: true,
							}}
							onKeyDown={(e) => e.keyCode !== 69}
							inputProps={{ inputmode: 'numeric', pattern: '/^-?d+(?:.d+)?$/g' }}
							InputProps={{ 'aria-label': t('purchaseLicenseLabel'), disableUnderline: true }}
							onBlur={handleInputBlur}
						/>
						<TextField
							id='buffer-license-label'
							className='field-col2 helperText-bottom'
							type='number'
							label={<>{t('bufferLicenseLabel')}</>}
							placeholder={t('bufferLicenseLabel')}
							value={orgDetail.bufferLicense}
							onChange={handleFieldChange}
							name={'bufferLicense'}
							InputLabelProps={{
								shrink: true,
							}}
							onBlur={handleInputBlur}
							InputProps={{ 'aria-label': t('bufferLicenseLabel'), disableUnderline: true }}
						/>
					</Box>
					<Box className='drawer-input-field drawer-input-field-col2'>
						<Grid component='label' container alignItems='center' spacing={1}>
							<Grid item>{t('inactiveLabel')}</Grid>
							<Grid item>
								<Box className={''}>
									<Switch
										id='isActive'
										name='isActive'
										checked={orgDetail.isActive}
										onChange={handleFieldChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								</Box>
							</Grid>
							<Grid item>{t('activeLabel')}</Grid>
						</Grid>
						<Grid component='label' container alignItems='center' spacing={1}>
							<Grid item>{`Allowed UBot`}</Grid>
							<Grid item>
								<Box className={''}>
									<Switch
										id='isChatBotAllowed'
										name='isChatBotAllowed'
										checked={orgDetail.isChatBotAllowed}
										onChange={handleFieldChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								</Box>
							</Grid>
							{/* <Grid item>{t('activeLabel')}</Grid> */}
						</Grid>
						<Grid component='label' container alignItems='center' spacing={1}>
							<Grid item>{`Allowed Letter Generation`}</Grid>
							<Grid item>
								<Box className={''}>
									<Switch
										id='isLGAllowed'
										name='isLGAllowed'
										checked={orgDetail.isLGAllowed}
										onChange={handleFieldChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box className='drawer-input-field'>
						{moduleList && moduleList.length > 0 && (
							<>
								<FormLabel>{t('purchasedModules')}</FormLabel>
								{moduleList.map((row: any, index: number) => (
									<FormControlLabel
										control={
											<Checkbox
												name='moduleName'
												checked={orgDetail.modules.filter((module: any) => module === row.moduleId).length > 0}
												onChange={(e) => handleModuleChange(e, row.moduleId)}
												icon={<BorderCheckboxIcon />}
												checkedIcon={<CheckedIcon />}
												id={'module-name'}
											/>
										}
										label={row.moduleName}
									/>
								))}
							</>
						)}
					</Box>
					<Box className='drawer-input-field drawer-input-field-col2'>
						<Box className='field-col2 helperText-bottom expiry-date-picker'>
							<DatePicker
								id={'kr-contributor-start-date'}
								value={orgDetail.demoExpiryDate ? orgDetail.demoExpiryDate : new Date()}
								name={'Expiry'}
								disablePast={false}
								showLabel={false}
								format='MMM d, yyyy'
								open={true}
								handleDateChange={props.handleDateChange}
								module='key-attribute'
								minDate={new Date()}
							/>
						</Box>
						<TextField
							id='salesperson'
							className='field-col2 helperText-bottom'
							type='text'
							label={<>{t('salesPersonLabel')}</>}
							placeholder={t('salesPersonLabel')}
							value={orgDetail.salesPerson}
							name={'salesPerson'}
							onChange={handleFieldChange}
							InputLabelProps={{
								shrink: true,
							}}
							onKeyDown={(e) => e.keyCode !== 69}
							InputProps={{ 'aria-label': t('salesPersonLabel'), disableUnderline: true }}
						/>
					</Box>
					<Box className='drawer-input-field drawer-input-field-col2'>
						<Box className='field-col2 helperText-bottom'>
							<FormControl fullWidth>
								<FormLabel>{t('Default Home')}</FormLabel>
								<Select
									id='defaultHomeName'
									className='select-filter download-dropdown '
									value={orgDetail?.defaultHome || ''}
									onChange={(e: any) => handleSelectFinalRating(e.target.value)}
									label='Default Home'
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left',
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left',
										},
										classes: { paper: 'okr-default-dropdown select-filter-dropdown full-width-dropdown' },
									}}
								>
									{defaultHomeScreenList &&
										defaultHomeScreenList.length > 0 &&
										defaultHomeScreenList.map((screen: any) => (
											<MenuItem value={screen?.homeId}>{screen?.home}</MenuItem>
										))}
								</Select>
							</FormControl>
						</Box>
						<TextField
							id='learnUrl'
							className='field-col2 helperText-bottom'
							type='text'
							label={<>{t('Learn URL')}</>}
							placeholder={t('Learn URL')}
							value={orgDetail.learnUrl}
							name={'learnUrl'}
							onChange={handleFieldChange}
							InputLabelProps={{
								shrink: true,
							}}
							onKeyDown={(e) => e.keyCode !== 69}
							InputProps={{ 'aria-label': t('Learn URL'), disableUnderline: true }}
						/>
					</Box>
				</Box>
			</form>
		</>
	);
};

import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, IconButton, MenuItem, Select, Tab, Tabs, Typography, Box } from '@material-ui/core';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import { DownloadIcon } from '../../config/svg/CommonSvgIcon';
import LinearLoader from '../Common/Loader';
import ViewPersonalFeedback from './ViewPersonalFeedback';
import RequestPersonalFeedback from './RequestPersonalFeedback';
import GivePersonalFeedback from './GivePersonalFeedback';
import RequestOneOnOne from './RequestOneOnOne';
import AlertDialog from '../Common/Dialog';
import { DrawerTransitionSetting } from '../../config/utils';
import { OKRButton } from '../Common/OKRButton';

const PersonalFeedbackDrawer = (props) => {
	const { type, openDrawer, loggedInUser, currentUser, showPersonalFeedback, setFeedbackData, isPeoplePage } = props;
	const { t } = useTranslation();
	const givenListRef = useRef(null);
	const [modalWarningOpen, setWarningModalOpen] = useState(false);
	const [formDataUpdated, setFormDataUpdated] = useState(false);
	const [loader, setLoader] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const [peopleFeedbackLoader, setPeopleFeedbackLoader] = useState(false);
	const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);

	useEffect(() => {
		if ((type === 'givePersonalFeedback' || type === 'askPersonalFeedback') && tabValue !== 0) {
			if (Boolean(givenListRef) && givenListRef.current) {
				givenListRef.current.scrollTop = 0;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabValue]);

	useEffect(() => {
		if (showPersonalFeedback === 'viewListing') {
			setTabValue(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPersonalFeedback]);

	/** Change form on dropdown change */
	const handleLinkChange = (event) => {
		setFormDataUpdated(false);
		openDrawer(event, event.target.value);
		changeTabValue(0);
	};
	/** open popup for warning */
	const handleDrawerConfirmation = (event) => {
		if (formDataUpdated) {
			setWarningModalOpen(true);
		} else {
			handleWaringCloseModal(event, 1);
		}
	};
	/** open warning popup */
	const handleWaringCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			handlePersonalFeedbackClose(e);
		}
		setWarningModalOpen(false);
	};
	/** Close Personal Feedback Drawer  */
	const handlePersonalFeedbackClose = (e) => {
		openDrawer(e, 'none');
	};

	const changeTabValue = (value) => {
		setTabValue(value);
		if (Boolean(showPersonalFeedback)) {
			setFeedbackData(null);
		}
	};
	const feedbackReport = async () => {
		if (!peopleFeedbackLoader) {
			setPeopleFeedbackLoader(true);
			await props
				.fetchFeedbackReport(`empId=${currentUser.employeeId}&type=3`)
				.then((r) => {
					const url = window.URL.createObjectURL(new Blob([r.data]));
					const link = document.createElement('a')
					link.href = url
					var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					var matches = filenameRegex.exec(r.headers['content-disposition']);
					link.setAttribute('download', matches && matches.length> 0 ? matches[1] : 'Feedback.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					setPeopleFeedbackLoader(false);
				})
				.catch((e) => console.log(e));
		}
	};

	return (
		<Fragment>
			<Drawer
				className='main-drawer-panel drawer-no-footer'
				anchor='right'
				open={props.open}
				onClose={handleDrawerConfirmation}
				// onEscapeKeyDown={handleDrawerConfirmation}
				// onBackdropClick={handleDrawerConfirmation}
				transitionDuration={DrawerTransitionSetting}
			>
				<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={(e) => handleDrawerConfirmation(e)}>
					<RightArrowIcon />
				</IconButton>
				<Box className='drawer-area'>
					<Box className='drawer-head drawer-head-tabs'>
						{type === 'viewPersonalFeedback' && <Typography variant='h3'>{
							!currentUser ?
								t('viewFeedbackPF') : t('peoplePersonalFeedback', {
									name: currentUser.firstName
								})
						}</Typography>}
						{type === 'givePersonalFeedback' &&
							(loggedInUser && !Boolean(props.feedbackData) ? (
								<Tabs
									value={tabValue}
									onChange={(e, value) => changeTabValue(value)}
									TabIndicatorProps={{ style: { background: '#39A3FA' } }}
									textColor='primary'
									className='okr-tabs drawer-tabs'
									centered
								>
									<Tab key={'givePersonalFeedback'} label={t('givePF')} id='give-pf' />
									<Tab key={'giveDetailsPersonalFeedback'} label={t('giveDetailsPF')} id='given-pf' />
								</Tabs>
							) : (
								<Typography variant='h3'>{t('givePF')}</Typography>
							))}
						{type === 'askPersonalFeedback' &&
							(loggedInUser ? (
								<Tabs
									value={tabValue}
									onChange={(e, value) => changeTabValue(value)}
									TabIndicatorProps={{ style: { background: '#39A3FA' } }}
									textColor='primary'
									className='okr-tabs drawer-tabs'
									centered
								>
									<Tab key={'askPersonalFeedback'} label={t('askPF')} id='ask-pf' />
									<Tab key={'askDetailsPersonalFeedback'} label={t('askDetailsPF')} id='requested-pf' />
								</Tabs>
							) : (
								<Typography variant='h3'>{t('askPF')}</Typography>
							))}
						{type === 'requestOneOnOne' && <Typography variant='h3'>{t('requestOnetoOne')}</Typography>}

						{loggedInUser && !Boolean(props.feedbackData) && (
							<Select value={type} onChange={handleLinkChange} className='drawer-okr-links' id='okr-link-name'>
								<MenuItem value={'viewPersonalFeedback'} id={'view-feedback-link'}>
									{t('viewFeedbackPF')}
								</MenuItem>
								<MenuItem value={'askPersonalFeedback'} id={'ask-feedback-link'}>
									{t('askFeedbackPF')}
								</MenuItem>
								<MenuItem value={'givePersonalFeedback'} id={'give-feedback-link'}>
									{t('giveFeedbackPF')}
								</MenuItem>
								<MenuItem value={'requestOneOnOne'} id={'request-one-onone-link'}>
									{t('requestOnetoOne')}
								</MenuItem>
							</Select>
						)}
						{(currentUser && isDownloadDisabled) && 
							<OKRButton className='btn-circle-blue' icon={<DownloadIcon />} title={'Download'} handleClick={() => { feedbackReport() }} ></OKRButton>
						}
					</Box>
					{loader && (
						<Box className='loader-wrap'>
							<LinearLoader data={true} />
						</Box>
					)}
					<Box className='drawer-content' id='custom-scroll' ref={givenListRef}>
						<Box className='drawer-inner-content '>
							<Box className='okr-drawer-form-area drawer-content-panel'>
								{type === 'viewPersonalFeedback' && (
									<ViewPersonalFeedback
										{...props}
										setLoader={setLoader}
										loader={loader}
										handleDrawerConfirmation={handleDrawerConfirmation}
										setFormDataUpdated={setFormDataUpdated}
										handlePersonalFeedbackClose={handlePersonalFeedbackClose}
										loggedInUser={loggedInUser}
										currentUser={currentUser}
										feedbackData={props.feedbackData}
										setIsDownloadDisabled={setIsDownloadDisabled}
										isPeoplePage={isPeoplePage}
									/>
								)}
								{type === 'askPersonalFeedback' && (
									<RequestPersonalFeedback
										{...props}
										tabValue={tabValue}
										changeTabValue={changeTabValue}
										setLoader={setLoader}
										loader={loader}
										handleDrawerConfirmation={handleDrawerConfirmation}
										setFormDataUpdated={setFormDataUpdated}
										handlePersonalFeedbackClose={handlePersonalFeedbackClose}
										loggedInUser={loggedInUser}
										currentUser={currentUser}
									/>
								)}
								{type === 'givePersonalFeedback' && (
									<GivePersonalFeedback
										{...props}
										tabValue={tabValue}
										changeTabValue={changeTabValue}
										setLoader={setLoader}
										loader={loader}
										handleDrawerConfirmation={handleDrawerConfirmation}
										setFormDataUpdated={setFormDataUpdated}
										handlePersonalFeedbackClose={handlePersonalFeedbackClose}
										loggedInUser={loggedInUser}
										currentUser={currentUser}
										feedbackData={props.feedbackData}
									/>
								)}
								{type === 'requestOneOnOne' && (
									<RequestOneOnOne
										{...props}
										setLoader={setLoader}
										loader={loader}
										handleDrawerConfirmation={handleDrawerConfirmation}
										setFormDataUpdated={setFormDataUpdated}
										handlePersonalFeedbackClose={handlePersonalFeedbackClose}
										loggedInUser={loggedInUser}
										currentUser={currentUser}
									/>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Drawer>
			{modalWarningOpen && (
				<AlertDialog
					module='mygoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleWaringCloseModal}
					modalOpen={modalWarningOpen}
				/>
			)}
		</Fragment>
	);
};

export default PersonalFeedbackDrawer;

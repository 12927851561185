import React, { Fragment, useEffect, useState } from 'react';
import { Box, TextField, InputAdornment, Modal, Typography, Switch, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
	BusinessName,
	BusinessDomainName,
	BusinessEmailIcon,
	NameIcon,
	BusinessPassword,
	ErrorIcon,
} from '../../config/svg/formElementIcons';
import { OKRButton } from '../Common/OKRButton';
import {
	freeTrialDataFields,
	defaultFreeTrialData,
	prepareApiPayload,
	freeTrialDataRequiredFields,
	isFieldValid,
} from './FreeTrialHelper';
import { FreeTrialCredentials } from '../../config/constant';
import Logo from '../../images/okr-logo.svg';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../config/app-url';
interface SimpleModalProps {
	onCloseModal?: any;
	open?: any;
	param?: any;
	handleClose?: any;
}

export const SimpleModal: React.FC<SimpleModalProps> = (props) => {
	const { onCloseModal, open, handleClose } = props;
	const { t } = useTranslation();
	const [param, setParam] = useState({ userName: '', password: '' });
	const history = useHistory();
	const handleCredentials = (parame: any, e: any) => {
		if (parame === 'userName') {
			setParam({ ...param, userName: e.target.value });
		} else if (parame === 'password') {
			setParam({ ...param, password: e.target.value });
		}
	};

	const handleCompareCredentials = (event: any) => {
		if (param.userName === FreeTrialCredentials.userName && param.password === FreeTrialCredentials.password) {
			handleClose();
		}
	};

	const handleBack = () => {
		history.push(HOME);
	};

	const body = (
		<form className='drawer-form-fields' noValidate autoComplete='off'>
			<Box className='drawer-input-field'>
				<TextField
					id='userName'
					fullWidth
					className='helperText-bottom'
					required
					label={
						<Fragment>
							<NameIcon />
							{t('userName')}
						</Fragment>
					}
					placeholder={t('userName')}
					value={param.userName}
					name={t('userName')}
					//error={''}
					// helperText={
					// 	<>
					// 		<ErrorIcon /> error txt
					// 	</>
					// }
					onChange={(e) => {
						handleCredentials('userName', e);
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{ 'aria-label': t('userName'), disableUnderline: true }}
				/>
			</Box>
			<Box className='drawer-input-field'>
				<TextField
					id='userpsw'
					fullWidth
					className='helperText-bottom'
					required
					label={
						<Fragment>
							<BusinessPassword />
							{t('passwordPlaceholder')}
						</Fragment>
					}
					placeholder={t('passwordPlaceholder')}
					value={param.password}
					type='password'
					name={t('passwordPlaceholder')}
					//error={''}
					// helperText={
					// 	<>
					// 		<ErrorIcon />
					// 	</>
					// }
					onChange={(e) => {
						handleCredentials('password', e);
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{ 'aria-label': t('passwordPlaceholder'), disableUnderline: true }}
				/>
			</Box>

			<Box className='drawer-input-field form-submit'>
				<OKRButton
					disabled={false}
					className={'btn-primary'}
					id={'user-submit'}
					text={t('submit')}
					handleClick={(event) => handleCompareCredentials(event)}
				/>
				<OKRButton
					disabled={false}
					className={'btn-link ml-1'}
					id={'user-submit'}
					text={t('Back')}
					handleClick={handleBack}
				/>
			</Box>
		</form>
	);

	return (
		<Modal
			open={open}
			onClose={onCloseModal}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className='free-auth-popup'
			disableEscapeKeyDown
		>
			<div className='free-auth-popup-inner'>{body}</div>
		</Modal>
	);
};

let timerId: NodeJS.Timeout;

export const FreeTrial: React.FC<any> = (props) => {
	const { freeTrialApiDetails, freeTrialApiEmailIdDetails, freeTrialApiDomainDetails, freeTrialApiPasswordDetails } =
		props;
	const [freeTrialFormData, setFreeTrialFormData] = useState<any>(defaultFreeTrialData);
	const [freeTrialFormError, setFreeTrialFormError] = useState<any>({});
	const [loader, setLoader] = useState(false);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState('');
	const history = useHistory();
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onCloseModal = (event: any, reason: any) => {
		if (reason && reason === 'backdropClick') {
			return;
		}
		handleClose();
	};

	useEffect(() => {
		handleOpen();
	}, []);

	const getFreeTrialFieldData = (field: string) => {
		return freeTrialFormData[field];
	};

	const handleBack = () => {
		history.push(HOME);
	};

	const validateUserForm = async () => {
		let isValid = true;
		let errorList: any = {};
		let requireFields = freeTrialDataRequiredFields;
		for (let i = 0; i < requireFields.length; i++) {
			const fieldName = requireFields[i];
			let fieldError = await isFieldValid(fieldName, freeTrialFormData[fieldName], t, {
				password: freeTrialApiPasswordDetails,
				domain: freeTrialApiDomainDetails,
				email: freeTrialApiEmailIdDetails,
			});
			if (fieldError !== '') {
				errorList[fieldName] = fieldError;
			}
			isValid = isValid && fieldError === '';
		}
		setFreeTrialFormError(errorList);
		return isValid;
	};

	const setFreeTrialFieldData = (field: string, value: any) => {
		setFreeTrialFormData({ ...freeTrialFormData, [field]: value });
		setFreeTrialFormError({ ...freeTrialFormError, [field]: '' });

		if (timerId) {
			clearTimeout(timerId);
		}
		timerId = setTimeout(() => validateFreeTrialFieldData(field, value), 300);
	};

	const validateFreeTrialFieldData = async (field: string, value: any) => {
		let fieldError = await isFieldValid(field, value, t, {
			password: freeTrialApiPasswordDetails,
			domain: freeTrialApiDomainDetails,
			email: freeTrialApiEmailIdDetails,
		});
		setFreeTrialFormError({ ...freeTrialFormError, [field]: fieldError || '' });
	};

	const handleSaveClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		if ((await validateUserForm()) === true) {
			setLoader(true);
			event.preventDefault();
			let createFreeTrialPayload: any = prepareApiPayload(freeTrialFormData);
			let response = await freeTrialApiDetails(createFreeTrialPayload);
			setLoader(false);
			if (response?.status === 200) {
				setLoader(false);
				setSuccess(response?.data?.messageList.emailId);
				setFreeTrialFormError({});
				handleBack();
			} else {
				if (response?.messageList) {
					if (response?.messageList.message) {
					} else {
						setFreeTrialFormError(response?.messageList);
					}
				}
				setLoader(false);
			}
		}
	};

	return (
		<>
			{/* {open && <SimpleModal onCloseModal={onCloseModal} open={open} handleClose={handleClose} />} */}
			<Box className='free-trial-create-form'>
				<div className='logo-img' onClick={handleBack}>
					<img src={Logo} alt={t('HeaderLogo')} />
				</div>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<Box className='drawer-form-fields-group'>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<TextField
								id='firstName'
								className='field-col2 helperText-bottom'
								required
								label={
									<Fragment>
										<NameIcon />
										{t('firstNameLabel')}
									</Fragment>
								}
								placeholder={t('firstNameLabel')}
								value={getFreeTrialFieldData(freeTrialDataFields.firstName)}
								name={t('firstNameLabel')}
								error={(freeTrialFormError && freeTrialFormError[freeTrialDataFields.firstName]) || ''}
								helperText={
									freeTrialFormError &&
									freeTrialFormError[freeTrialDataFields.firstName] && (
										<>
											<ErrorIcon /> {freeTrialFormError[freeTrialDataFields.firstName] || ''}
										</>
									)
								}
								onChange={(e) => {
									setFreeTrialFieldData(freeTrialDataFields.firstName, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('firstNameLabel'), disableUnderline: true }}
							/>
							<TextField
								id='lastName'
								className='field-col2 helperText-bottom'
								required
								label={
									<Fragment>
										<NameIcon />
										{t('lastNameLabel')}
									</Fragment>
								}
								placeholder={t('lastNameLabel')}
								value={getFreeTrialFieldData(freeTrialDataFields.lastName)}
								name={t('lastNameLabel')}
								onChange={(e) => {
									setFreeTrialFieldData(freeTrialDataFields.lastName, e.target.value);
								}}
								error={(freeTrialFormError && freeTrialFormError[freeTrialDataFields.lastName]) || ''}
								helperText={
									freeTrialFormError &&
									freeTrialFormError[freeTrialDataFields.lastName] && (
										<>
											<ErrorIcon /> {freeTrialFormError[freeTrialDataFields.lastName] || ''}
										</>
									)
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('lastNameLabel'), disableUnderline: true }}
							/>
						</Box>
						<Box className='drawer-input-field'>
							<TextField
								fullWidth
								id='bizemailAddress'
								className='helperText-bottom'
								required
								label={
									<Fragment>
										<BusinessEmailIcon />
										{t('businessEmail')}
									</Fragment>
								}
								placeholder={t('emailAddressPlaceholder')}
								error={(freeTrialFormError && freeTrialFormError[freeTrialDataFields.emailId]) || ''}
								helperText={
									freeTrialFormError &&
									freeTrialFormError[freeTrialDataFields.emailId] && (
										<>
											<ErrorIcon /> {freeTrialFormError[freeTrialDataFields.emailId] || ''}
										</>
									)
								}
								value={getFreeTrialFieldData(freeTrialDataFields.emailId)}
								name={t('businessEmail')}
								onChange={(e) => {
									setFreeTrialFieldData(freeTrialDataFields.emailId, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('businessEmail'), disableUnderline: true }}
							/>
						</Box>
						<Box className='drawer-input-field'>
							<TextField
								id='bizName'
								fullWidth
								className='helperText-bottom'
								required
								label={
									<Fragment>
										<BusinessName />
										{t('businessName')}
									</Fragment>
								}
								placeholder={t('businessName')}
								value={getFreeTrialFieldData(freeTrialDataFields.companyName)}
								name={t('businessName')}
								error={(freeTrialFormError && freeTrialFormError[freeTrialDataFields.companyName]) || ''}
								helperText={
									freeTrialFormError &&
									freeTrialFormError[freeTrialDataFields.companyName] && (
										<>
											<ErrorIcon /> {freeTrialFormError[freeTrialDataFields.companyName] || ''}
										</>
									)
								}
								onChange={(e) => {
									setFreeTrialFieldData(freeTrialDataFields.companyName, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('businessName'), disableUnderline: true }}
							/>
						</Box>
						<Box className='drawer-input-field'>
							<TextField
								id='bizDomainName'
								fullWidth
								className='helperText-bottom'
								required
								label={
									<Fragment>
										<BusinessDomainName />
										{t('businessDomainName')}
									</Fragment>
								}
								placeholder={t('businessDomainName')}
								value={getFreeTrialFieldData(freeTrialDataFields.domain)}
								name={t('businessDomainName')}
								error={(freeTrialFormError && freeTrialFormError[freeTrialDataFields.domain]) || ''}
								helperText={
									freeTrialFormError &&
									freeTrialFormError[freeTrialDataFields.domain] && (
										<>
											<ErrorIcon /> {freeTrialFormError[freeTrialDataFields.domain] || ''}
										</>
									)
								}
								onChange={(e) => {
									setFreeTrialFieldData(freeTrialDataFields.domain, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('businessDomainName'),
									disableUnderline: true,
									endAdornment: <InputAdornment position='end'>{<>{t('.unlockokr.com')}</>}</InputAdornment>,
								}}
							/>
						</Box>
						<Box className='drawer-input-field'>
							<TextField
								id='bizPsw'
								fullWidth
								className='helperText-bottom'
								required
								label={
									<Fragment>
										<BusinessPassword />
										{t('passwordPlaceholder')}
									</Fragment>
								}
								placeholder={t('passwordPlaceholder')}
								value={getFreeTrialFieldData(freeTrialDataFields.password)}
								type='password'
								name={t('passwordPlaceholder')}
								error={(freeTrialFormError && freeTrialFormError[freeTrialDataFields.password]) || ''}
								helperText={
									freeTrialFormError &&
									freeTrialFormError[freeTrialDataFields.password] && (
										<>
											<ErrorIcon /> {freeTrialFormError[freeTrialDataFields.password] || ''}
										</>
									)
								}
								onChange={(e) => {
									setFreeTrialFieldData(freeTrialDataFields.password, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('passwordPlaceholder'), disableUnderline: true }}
							/>
						</Box>

						<Box className='drawer-input-field'>
							<FormControlLabel
								control={
									<Switch
										onChange={(e) => setFreeTrialFieldData(freeTrialDataFields.isLicense, e.target.checked)}
										checked={getFreeTrialFieldData(freeTrialDataFields.isLicense)}
										name='isLicense'
									/>
								}
								label='Is License'
							/>
							<FormControlLabel
								control={
									<Switch
										onChange={(e) => setFreeTrialFieldData(freeTrialDataFields.isSSO, e.target.checked)}
										checked={getFreeTrialFieldData(freeTrialDataFields.isSSO)}
										name='isSSO'
									/>
								}
								label='Is SSO'
							/>
						</Box>

						<Box className='form-submit'>
							<OKRButton
								disabled={false}
								className={'btn-primary'}
								id={'start-free-trail'}
								text={t('startFreeTrialBtn')}
								handleClick={handleSaveClick}
							/>

							<OKRButton
								disabled={false}
								className={'btn-link ml-1'}
								id={'user-submit'}
								text={t('Back')}
								handleClick={handleBack}
							/>
						</Box>

						<Box className='drawer-input-field'>
							<Typography className='free-trial-msg' variant='body2'>
								{success}
							</Typography>
						</Box>
					</Box>
				</form>
			</Box>
		</>
	);
};

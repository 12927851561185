import React, { Fragment, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Chip,
	InputAdornment,
	List,
	ListItem,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '../../config/svg/CommonSvg';
import { CrossIcon, SortingIcon } from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import { NoRecord } from '../Admin/CommonComponent/NoRecord';
import { getUserName } from '../../config/utils';
import LinearLoader from '../Common/Loader';

export const ImpersonateDrawer: React.FC<any> = (props) => {
	const {
		selectedInstance,
		userList,
		paggingInfo,
		loadMoreUser,
		handleSortUsers,
		sortOrder,
		searchClicked,
		handleChange,
		searchValue,
		clearSearch,
		searchTextArray,
		handleImpersonateUserClick,
	} = props;
	const { t } = useTranslation();
	return (
		<Box className='manage-user-drawer impersonate-drawer'>
			<Box className='drawer-input-field drawer-user-search-field'>
				<TextField
					className='user-search-field'
					id='user-src-field'
					placeholder={t('searchPeople')}
					fullWidth
					value={searchValue}
					onChange={(e) => handleChange(e)}
					onKeyDown={(e) => searchClicked(e)}
					inputProps={{
						'aria-label': 'search',
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				{searchTextArray && searchTextArray.length > 0 && (
					<Box className='user-search-chip'>
						<List>
							<ListItem key={`user${searchTextArray}`} id={`user-chip-${searchTextArray}`}>
								{searchTextArray.length > 20 ? (
									<Tooltip title={searchTextArray} arrow>
										<Chip
											label={searchTextArray.substring(0, 20) + '...'}
											onDelete={(e) => clearSearch()}
											deleteIcon={
												<span>
													<CrossIcon />
												</span>
											}
										/>
									</Tooltip>
								) : (
									<Chip
										label={searchTextArray}
										onDelete={(e) => clearSearch()}
										deleteIcon={
											<span>
												<CrossIcon />
											</span>
										}
									/>
								)}
							</ListItem>
						</List>
					</Box>
				)}
			</Box>
			{userList && userList?.length > 0 ? (
				<Box className={'users-listing users-listing-three-col'}>
					<Box className='userCount'>
						<Typography variant='subtitle2'>{`(${userList?.length} of ${paggingInfo?.totalRecords} ${t(
							'membersLabel'
						)})`}</Typography>
					</Box>

					<Fragment>
						<Box className='okr-list-panel-head'>
							<List component='ul' disablePadding>
								<ListItem className='user-col1'>
									<Typography
										variant='body2'
										className={`sort-title ${sortOrder === 'asc' ? 'desc' : 'asc'}`}
										onClick={handleSortUsers}
										id='sort-name'
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>
												{t('nameLabel')} <SortingIcon />
											</span>
										</span>
									</Typography>
								</ListItem>
								<ListItem className='user-col2'>
									<Typography variant='body2' className={'sort-title'} id='sort-email'>
										<span className={`sortable-label `}>
											<span className='txt-wrap'>{t('reportingToLabel')}</span>
										</span>
									</Typography>
								</ListItem>
								<ListItem className='user-col3'>
									<Typography variant='body2' className={'sort-title'} id='sort-role'>
										<span className={`sortable-label `}>
											<span className='txt-wrap'></span>
										</span>
									</Typography>
								</ListItem>
							</List>
						</Box>
						<Box className='users-list-content'>
							{userList.map((item: any, index: number) => (
								<Fragment>
									<Box
										className='users-list-row'
										key={`selectedUser${item.employeeId}`}
										id={`selected-user-${item.employeeId}`}
									>
										<List component='ul' disablePadding>
											<ListItem className='user-col1'>
												<Box className='user-info'>
													<Box className='user-img'>
														<Avatar
															className='avatar-default'
															key={`user-icon-${item.employeeId}`}
															src={item.imagePath || ''}
														>
															{getUserName({
																firstName: item.firstName || '',
																lastName: item.lastName || '',
																fullName: item.label || '',
															})}
														</Avatar>
													</Box>
													<Box className='user-info-details'>
														<Typography variant='h4'>
															{item.firstName} {item.lastName}
														</Typography>
														<Typography variant='subtitle2'>{item.designation}</Typography>
														{item?.emailId && item?.emailId.length > 32 ? (
															<Tooltip arrow title={item?.emailId} aria-label='Email'>
																<Typography variant='h6'>{item?.emailId.substring(0, 32)}...</Typography>
															</Tooltip>
														) : (
															<Typography variant='h6'>{item?.emailId}</Typography>
														)}
													</Box>
												</Box>
											</ListItem>
											<ListItem className='user-col2'>
												{item?.reportingManagerResponse?.firstName ? (
													<Box className='reporting-to-section'>
														<Typography variant='h4'>
															{item?.reportingManagerResponse?.firstName} {item?.reportingManagerResponse?.lastName}
														</Typography>
														<Typography variant='subtitle2'>{item?.reportingManagerResponse?.designation}</Typography>
														{item?.reportingManagerResponse?.emailId &&
														item?.reportingManagerResponse?.emailId.length > 32 ? (
															<Tooltip arrow title={item?.reportingManagerResponse?.emailId} aria-label='Email'>
																<Typography variant='h6'>
																	{item?.reportingManagerResponse?.emailId.substring(0, 32)}...
																</Typography>
															</Tooltip>
														) : (
															<Typography variant='h6'>{item?.reportingManagerResponse?.emailId}</Typography>
														)}
													</Box>
												) : (
													<Box className='reporting-to-section'>
														<Typography variant='h4'>--</Typography>
													</Box>
												)}
											</ListItem>
											<ListItem className='user-col3'>
												<Box className='chip-group'>
													<OKRButton
														text={'Impersonate'}
														handleClick={(e: any) => handleImpersonateUserClick(e, item)}
														className='btn-link-type1'
													/>
												</Box>
											</ListItem>
										</List>
									</Box>
								</Fragment>
							))}

							{paggingInfo?.totalPages !== paggingInfo?.pageIndex && (
								<Box className='alignCenter' display={'flex'} justifyContent={'center'}>
									<OKRButton
										id='load-more'
										className='btn-link'
										handleClick={loadMoreUser}
										text={'Load More...'}
									></OKRButton>
								</Box>
							)}
						</Box>
					</Fragment>
				</Box>
			) : (
				<NoRecord noRecordMessage='No Records...' />
			)}
		</Box>
	);
};

/** users section props */

import { USER_ROLE_ID } from '../../../config/users';
import { checkForSpace, isValidEmail } from '../../../config/utils';

export const userDataFields = {
	firstName: 'firstName',
	lastName: 'lastName',
	emailId: 'emailId',
	employeeCode: 'employeeCode',
	designation: 'designation',
	reportingTo: 'reportingTo',
	roleId: 'roleId',
	teamId: 'teamId',
};

export interface UserDataProps {
	firstName: string;
	lastName: string;
	emailId: string;
	employeeCode: string;
	designation: string;
	reportingTo: number;
	roleId: number;
	teamId: number[];
	employeeId?: number;
}

/** users section default values */
export const defaultUserData: UserDataProps = {
	firstName: '',
	lastName: '',
	emailId: '',
	employeeCode: '',
	designation: '',
	reportingTo: 0,
	roleId: 0,
	teamId: [],
};
export const freeTrialsRequiredFields = ['firstName', 'lastName', 'emailId'];
export const userDataRequiredFields = ['firstName', 'lastName', 'emailId', 'roleId', 'teamId'];
export const userDataRequiredFieldsErrorMsg: any = {
	firstName: 'firstNameRequired',
	firstNameInvalid: 'firstNameInvalid',
	lastName: 'lastNameRequired',
	lastNameInvalid: 'lastNameInvalid',
	emailIdInvalid: 'emailInvalid',
	emailId: 'emailRequired',
	designation: 'designationRequired',
	teamId: 'organizationRequired',
};

/**
 * To check if a field is valid
 * @param field field to be checked
 * @param fieldValue field value
 * @param t translation function to fetch translation string
 * @returns blank string in case of no error else return error text
 */
export const isFieldValid = (field: string, fieldValue: any, t: any): string => {
	switch (field) {
		case userDataFields.firstName:
		case userDataFields.lastName:
			if (fieldValue.trim() === '') {
				return t(userDataRequiredFieldsErrorMsg[field]);
			}
			// else if (checkForSpace(fieldValue)) {
			// 	return t(userDataRequiredFieldsErrorMsg[field + 'Invalid']);
			// }
			return '';
		case userDataFields.designation:
			return fieldValue.trim() === '' ? t(userDataRequiredFieldsErrorMsg[field]) : '';
		case userDataFields.emailId:
			if (fieldValue.trim() === '') {
				return t(userDataRequiredFieldsErrorMsg[field]);
			} else if (!isValidEmail(fieldValue)) {
				return t(userDataRequiredFieldsErrorMsg[field + 'Invalid']);
			}
			return '';
		case userDataFields.teamId:
		case userDataFields.roleId:
			return fieldValue === 0 || fieldValue.length === 0 ? t(userDataRequiredFieldsErrorMsg[field]) : '';
			break;
		default:
			return '';
	}
};

export const prepareApiPayload = (payload: any): UserDataProps => {
	let createUserPayload: UserDataProps = Object.assign({}, defaultUserData);
	createUserPayload.firstName = payload.firstName;
	createUserPayload.lastName = payload.lastName;
	createUserPayload.emailId = payload.emailId;
	createUserPayload.employeeCode = payload.employeeCode;
	createUserPayload.designation = payload.designation;
	createUserPayload.roleId = payload.roleId;
	if (payload.roleId && payload.roleId.length > 0) {
		createUserPayload.roleId = payload.roleId[0].value;
	}
	if (payload.reportingTo && payload.reportingTo.length > 0) {
		createUserPayload.reportingTo = payload.reportingTo[0].value;
	}
	if (payload.teamId && payload.teamId.length > 0) {
		createUserPayload.teamId = payload.teamId.map((teamData: any) => {
			return teamData.value;
		});
	}
	return createUserPayload;
};

export const prepareAddAnotherFormData = (formData: any) => {
	let formDefaultData = Object.assign({}, defaultUserData);
	formDefaultData.designation = formData.designation;
	formDefaultData.teamId = formData.teamId;
	formDefaultData.roleId = formData.roleId;
	return formDefaultData;
};

export const prepareEditUserFormData = (userData: any) => {
	let formDefaultData: any = {};
	formDefaultData.firstName = userData.firstName;
	formDefaultData.lastName = userData.lastName;
	formDefaultData.emailId = userData.emailId;
	formDefaultData.employeeCode = userData.employeeCode;
	formDefaultData.designation = userData.designation;
	if (userData.reportingManagerResponse.reportingManagerId) {
		formDefaultData.reportingTo = [
			{
				label: userData.reportingManagerResponse.firstName + ' ' + userData.reportingManagerResponse.lastName,
				value: userData.reportingManagerResponse.reportingManagerId,
				imagePath: userData.reportingManagerResponse.imagePath,
				secondaryText: userData.reportingManagerResponse.designation,
			},
		];
	} else {
		formDefaultData.reportingTo = 0;
	}
	formDefaultData.teamId = userData.employeeTeamResponses.map((employeeTeam: any) => {
		return {
			label: employeeTeam.teamName,
			value: employeeTeam.teamId,
			isNotDeletable: employeeTeam.isTeamLeader,
			imagePath: employeeTeam.imagePath,
			secondaryText: employeeTeam.organisationHead,
			count: employeeTeam.employeeCount,
			colorCode: employeeTeam.colorCode,
			backGroundColorCode: employeeTeam.backGroundColorCode,
		};
	});
	formDefaultData.roleId = [
		{
			label: userData.roleName,
			value: userData.roleId,
		},
	];

	return formDefaultData;
};

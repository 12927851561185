import React from 'react';
import { Box, FormLabel } from '@material-ui/core';
import * as ClassicEditor from 'ckeditor5-custom-build';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getRandomBackgroundColor, getUserName, getRandomColor } from '../../../config/utils';
import { UPLOAD_FILE } from '../../../config/api-url';
import { getHeaders } from '../../../config/auth';
import { defaultToolbar, ImageUploadToolbar, EmoticonsToolbar } from './CkEditorEnums';

function MentionCustomization(editor) {
	// The upcast converter will convert view <a class="mention" href="" data-user-id="">
	// elements to the model 'mention' text attribute.
	editor.conversion.for('upcast').elementToAttribute({
		view: {
			name: 'a',
			key: 'data-mention',
			classes: 'mention',
			attributes: {
				//href: true,
				'data-user-id': true,
			},
		},
		model: {
			key: 'mention',
			value: (viewItem) => {
				// The mention feature expects that the mention attribute value
				// in the model is a plain object with a set of additional attributes.
				// In order to create a proper object use the toMentionAttribute() helper method:
				const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
					// Add any other properties that you need.
					link: viewItem.getAttribute('href'),
					userId: viewItem.getAttribute('data-user-id'),
				});
				return mentionAttribute;
			},
		},
		converterPriority: 'high',
	});

	// Downcast the model 'mention' text attribute to a view <a> element.
	editor.conversion.for('downcast').attributeToElement({
		model: 'mention',
		view: (modelAttributeValue, { writer }) => {
			// Do not convert empty attributes (lack of value means no mention).
			if (!modelAttributeValue) {
				return;
			}

			return writer.createAttributeElement(
				'a',
				{
					class: 'mention tagged-name',
					'data-mention': modelAttributeValue.id,
					'data-user-id': modelAttributeValue.userId,
					rel: modelAttributeValue.userId,
				},
				{
					// Make mention attribute to be wrapped by other attribute elements.
					priority: 20,
					// Prevent merging mentions together.
					id: modelAttributeValue.uid,
				}
			);
		},
		converterPriority: 'high',
	});
}

function customItemRenderer(item) {
	const itemElement = document.createElement('span');
	itemElement.classList.add('custom-item');
	itemElement.id = `mention-list-item-id-${item.userId}`;
	itemElement.textContent = '';

	let imageHtml = document.createElement('div');
	imageHtml.classList.add('avatar-default');
	if (item.imagePath) {
		const img = document.createElement('img');
		img.src = item.imagePath;
		imageHtml.appendChild(img);
	} else {
		imageHtml.style.backgroundColor = getRandomBackgroundColor(item.backGroundColorCode);
		imageHtml.style.color = getRandomColor(item.colorCode);
		imageHtml.textContent = getUserName(item);
	}

	itemElement.appendChild(imageHtml);

	const userWrap = document.createElement('div');
	userWrap.classList.add('search-user-info');

	const usernameElement = document.createElement('p');
	usernameElement.textContent = `${item.name} `;

	const usernameDes = document.createElement('span');
	usernameDes.classList.add('email-text');
	usernameDes.textContent = `${item.emailId} `;

	userWrap.appendChild(usernameElement);
	userWrap.appendChild(usernameDes);

	itemElement.appendChild(userWrap);
	return itemElement;
}

export default class CkEditor extends React.Component {
	constructor(props) {
		super(props);
		let toolbarOptions = props.toolbar ? props.toolbar : defaultToolbar;
		if (props.noToolbar) {
			toolbarOptions = [];
		} else {
			if (props.uploadImage) {
				toolbarOptions = [...toolbarOptions, ...ImageUploadToolbar];
			}
			if (props.showEmoticons) {
				toolbarOptions = [...toolbarOptions, ...EmoticonsToolbar];
			}
		}
		this.state = {
			editorValue: props.value,
			extraConfig: props.extraConfig ? props.extraConfig : {},
			toolbar: toolbarOptions,
		};
	}

	getFeedItems = async (text) => {
		let resp = await this.props.globalSearchAPIWithTeam(text, 1, 15, 1);
		let entityList = resp.data.entityList;
		if (entityList) {
			entityList = entityList.map((data) => {
				return {
					...data,
					id: data.searchType === 2 ? '@' + data.teamName : '@' + data.firstName + '' + data.lastName,
					userId: data.searchType === 2 ? data.teamId + '_teamId' : data.employeeId + '',
					name: data.searchType === 2 ? data.teamName : data.firstName + ' ' + data.lastName,
					value: data.searchType === 2 ? data.teamName : data.firstName + ' ' + data.lastName,
					imagePath: data.searchType === 2 ? data.teamLogoImagePath : data.imagePath,
					emailId: data.emailId ? data.emailId : '',
					fullName: data.searchType === 2 ? data.teamName : data.fullname,
				};
			});
		}
		return entityList;
	};

	imageUploadSuccess = (fileData) => {
		this.props.imageList.push(fileData);
	};

	render() {
		return (
			<Box className='ck-editor-box '>
				{
					<CKEditor
						id={this.props.id}
						className={'ck-editor-area'}
						editor={ClassicEditor}
						onError={(a) => {
							console.log('Error', a);
						}}
						onReady={(a) => {
							a?.setData && this.props.value && a.setData(this.props.value);
						}}
						data={this.props.value}
						config={{
							...this.state.extraConfig,
							toolbar: this.state.toolbar,
							removePlugins: [
								'SpecialCharactersEssentials',
								'SpecialCharactersArrows',
								'SpecialCharactersCurrency',
								'SpecialCharactersLatin',
								'SpecialCharactersMathematical',
								'SpecialCharactersText',
							],
							extraPlugins: this.props.uploadImage
								? [MentionCustomization, MyCustomUploadAdapterPlugin]
								: [MentionCustomization],
							uploadUrl: UPLOAD_FILE,
							mention: {
								feeds: [
									{
										marker: '@',
										feed: this.getFeedItems,
										itemRenderer: customItemRenderer,
										dropdownLimit: 10,
										minimumCharacters: 3,
									},
								],
							},
							ImageUploadSuccess: {
								onUpload: this.imageUploadSuccess,
								uploadType: this.props.uploadType,
							},
							editorType: this.props.editorType || 2,
							placeholder: this.props.placeHolderText || '',
							link: {
								defaultProtocol: 'http://',
								decorators: {
									addTargetToExternalLinks: {
										mode: 'automatic',
										callback: (url) => 1 === 1,
										attributes: {
											target: '_blank',
											rel: 'noopener noreferrer',
										},
									},
								},
							},
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							this.props.handleEditorChange(data);
						}}
						onBlur={(event, editor) => {
							this.props.handleBlur && this.props.handleBlur();
						}}
						ImageUploadSuccess={this.imageUploadSuccess}
					/>
				}
			</Box>
		);
	}
}

class MyUploadAdapter {
	constructor(loader, uploadUrl, uploadSuccess, editorType) {
		// The file loader instance to use during the upload. It sounds scary but do not
		// worry — the loader will be passed into the adapter later on in this guide.
		this.loader = loader;
		this.uploadUrl = uploadUrl;
		this.uploadSuccess = uploadSuccess;
		this.editorType = editorType;
	}
	// Starts the upload process.
	upload() {
		return this.loader.file.then(
			(file) =>
				new Promise((resolve, reject) => {
					this._initRequest(this.uploadSuccess);
					this._initListeners(resolve, reject, file, this.uploadSuccess);
					this._sendRequest(file);
				})
		);
	}

	// Aborts the upload process.
	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}
	// Initializes the XMLHttpRequest object using the URL passed to the constructor.
	_initRequest(uploadSuccess) {
		const xhr = (this.xhr = new XMLHttpRequest());

		// Note that your request may look different. It is up to you and your editor
		// integration to choose the right communication channel. This example uses
		// a POST request with JSON as a data structure but your configuration
		// could be different.
		xhr.open('POST', this.uploadUrl + `${uploadSuccess.uploadType ? '?type=' + uploadSuccess.uploadType : ''}`, true);
		xhr.responseType = 'json';
	}
	_initListeners(resolve, reject, file, uploadSuccess) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = `Couldn't upload file: ${file.name}.`;

		xhr.addEventListener('error', () => reject(genericErrorText));
		xhr.addEventListener('abort', () => reject());
		xhr.addEventListener('load', () => {
			const response = xhr.response;
			// This example assumes the XHR server's "response" object will come with
			// an "error" which has its own "message" that can be passed to reject()
			// in the upload promise.
			//
			// Your integration may handle upload errors in a different way so make sure
			// it is done properly. The reject() function must be called when the upload fails.
			if (!response || response.error) {
				return reject(response && response.error ? response.error.message : genericErrorText);
			}
			uploadSuccess.onUpload({
				filePath: response.entity,
				fileName: file.name,
			});
			// If the upload is successful, resolve the upload promise with an object containing
			// at least the "default" URL, pointing to the image on the server.
			// This URL will be used to display the image in the content. Learn more in the
			// UploadAdapter#upload documentation.
			resolve({
				default: response.entity,
			});
		});

		// Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
		// properties which are used e.g. to display the upload progress bar in the editor
		// user interface.
		if (xhr.upload) {
			xhr.upload.addEventListener('progress', (evt) => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}
	_sendRequest(file) {
		const data = new FormData();
		data.append('UploadFiles', file);
		data.append('featureListType', this.editorType);
		let headers = getHeaders();
		Object.keys(headers).forEach((value) => {
			this.xhr.setRequestHeader(value, headers[value]);
		});
		this.xhr.send(data);
	}
}

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		// Configure the URL to the upload script in your back-end here!
		return new MyUploadAdapter(
			loader,
			editor.config.get('uploadUrl'),
			editor.config.get('ImageUploadSuccess'),
			editor.config.get('editorType')
		);
	};
}

export const HOME = '/dashboard';
export const MY_GOAL = '/my-goal';
export const PEOPLE = '/people';
export const ALIGNMENT_MAP = '/goal-map';
export const ALIGNMENT_MAP_POC = '/goal-map-poc';
export const USER_VIEW = '/people-view';
export const TEAM_VIEW = '/team-view';
export const ADMIN = '/admin';
export const ORGANIZATIONS = '/organization';
export const ROLES = 'roles';
export const USERS = 'users';
export const SECRET_LOGIN = '/secretlogin';
export const LOGIN_INITIATOR_URL = SECRET_LOGIN;
export const LOGOUT_INITIATOR_URL = '/logout';
// process.env.NODE_ENV === 'development' ? `${SECRET_LOGIN}` : 'https://passport.compunnel.com';
export const INSIGHT = '/insight';
export const REPORTS = '/reports';
export const ALIGNMENTMAPS = '/alignment-maps';
export const PEOPLEMAPS = '/people-maps';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';
export const ADMIN_ROUTES = ['admin', 'forbidden'];
export const UNAUTHORIZED = '/unauthorized';
export const WHATS_NEW = '/whats-new-updates';
export const MAILS = '/mails';
export const USER_ROUTES = [
	'admin',
	'dashboard',
	'people',
	'people-view',
	'goal-map',
	'insight',
	'forbidden',
	'privacy-policy',
	'terms-of-use',
	'type-script-poc',
	'alignment-maps',
	'people-maps',
	'reports',
	'team-view',
	'whats-new-updates',
	'mails',
];
export const VALID_ROUTES = [
	'admin',
	'dashboard',
	'people',
	'people-view',
	'goal-map',
	'insight',
	'forbidden',
	'privacy-policy',
	'terms-of-use',
	'type-script-poc',
	'alignment-maps',
	'people-maps',
	'reports',
	'team-view',
	'whats-new-updates',
	'mails',
];

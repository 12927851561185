import { getAllMailListAPI, createMailAPI } from '../services/MailService';
import * as actionTypes from './actionTypes';

const mailListPending = () => ({
	type: actionTypes.GET_Mail_List_PENDING,
});

const mailListCompleted = (payload) => ({
	type: actionTypes.GET_Mail_List_SUCCESS,
	payload,
});

const mailListFailed = (payload) => ({
	type: actionTypes.GET_Mail_List_FAILED,
	payload,
});
export const getAllMailList = (data) => {
	return (dispatch) => {
		dispatch(mailListPending());
		return getAllMailListAPI(data)
			.then((response) => {
				response?.data?.entityList && dispatch(mailListCompleted(response.data?.entityList));
			})
			.catch((error) => {
				dispatch(mailListFailed(error));
			});
	};
};
export const createMailFeature = (data) => {
	return () => {
		return createMailAPI(data);
	};
};

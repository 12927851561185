import React, { useEffect, useState } from 'react';
import clone from 'clone';
import {
	Box,
	FormLabel,
	ListItemAvatar,
	TextField,
	MenuItem,
	Typography,
	MenuList,
	InputAdornment,
	List,
	ListItem,
	Menu,
	Avatar,
} from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { BooleanIcon, CurrencyIcon, NoUnitsIcon, NumbersIcon, PercentageIcon } from '../../config/svg/MyGoalSvg';
import { formatDate, getCurrencyIcon, getDueDays, getFullName, getMonthDate, getUserName } from '../../config/utils';
import { UserAvatarGroup } from '../Common/UserAvatarGroup';
import { Enums } from '../../config/enums';
import { NumberFormatComponent } from '../Common/NumberFormat';
import { AssignmentSelection } from '../Common/assignment/AssignmentSelection';
import { UserAvatar } from '../Common/UserAvatar';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import moment from 'moment';
import { getLocalStorageItem } from '../../services/StorageService';
import { KrWeight } from '../Common/KrWeight';
import { KrConfidence } from '../Common/KrConfidence';

export const KrAcceptAttributeDetail: React.FC<any> = (props) => {
	const {
		okr,
		goalList,
		durationCycleDetail,
		okrMasterData,
		orphanKRData,
		setOrphanKRData,
		krAttrDateError,
		setKRAttrDateError,
		setIsAcceptKRFormUpdated,
		KRType,
		kr,
		t,
	} = props;
	const allCycleDetails = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const keyData = KRType === 'WithParent' ? clone(kr) : clone(okr);
	const [openCalender, setOpenCalender] = useState<Boolean>(false);
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [selectedGoalData, setSelectedGoalData] = useState<any>(null);
	const [sourceContributor, setSourceContributor] = useState<any>();
	const [parentData, setParentData] = useState<any>({});
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>();
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [dueDaysDiff, setDueDaysDiff] = useState<any>(0);
	const filterGoal =
		goalList &&
		clone(goalList).filter(
			(goal: any) =>
				(goal.krStatusId !== Enums.KR_STATUS_DECLINED || goal.krStatusId !== Enums.KR_STATUS_PENDING) &&
				goal.goalStatusId === Enums.GOAL_STATUS_PUBLIC &&
				goal.assignmentTypeId !== Enums.ONE &&
				goal.goalTypeId !== Enums.ONE &&
				!goal.isPrivate
		);
	useEffect(() => {
		const startDateValue = orphanKRData.startDate;
		const endDateValue = orphanKRData.dueDate;
		updateDueDays(startDateValue, endDateValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orphanKRData?.startDate, orphanKRData.dueDate]);

	useEffect(() => {
		if (allCycleDetails && allCycleDetails.cycleDetails && allCycleDetails.cycleDetails.length) {
			allCycleDetails.cycleDetails.forEach((cycleDetails: any) => {
				const currentQuarterDetails = cycleDetails.quarterDetails.find(
					(item: any) => item.organisationCycleId === props?.okr?.objectiveCycleId
				);
				if (currentQuarterDetails) {
					setSelectedCycleDetails({ ...currentQuarterDetails, year: props?.okr?.year });
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const { metricId } = keyData;
		if (metricId) {
			const { metricMasters } = okrMasterData;
			const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 5 : keyData?.metricId;
			const selectedCurrencyId = keyData?.currencyId ? keyData.currencyId : keyData?.currencyId;
			let selectedCurrencyDetail: any = {};
			const selectedMetric =
				metricMasters && metricMasters.length
					? metricMasters.find((item: any) => item.metricId === selectedMetricId)
					: null;
			if (selectedMetric && selectedMetric?.name === 'Currency') {
				const { metricDataMaster } = selectedMetric;
				const selectedCurrency =
					metricDataMaster && metricDataMaster.length
						? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
						: null;
				selectedCurrencyDetail = selectedCurrency;
			}
			setOrphanKRData({
				...orphanKRData,
				metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
				metricDescription: selectedMetric && selectedMetric?.description ? selectedMetric?.description : '',
				currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
			});
		}
		orphanKRData?.goalObjectiveId
			? setSelectedGoalData(filterGoal.map((item: any) => item.goalObjectiveId === orphanKRData.goalObjectiveId))
			: setSelectedGoalData(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const { contributors } = kr;
		if (contributors) {
			const sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			setSourceContributor(sourceData);
		} else {
			const { contributors } = okr;
			const sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			setSourceContributor(sourceData);
		}
		let contributorsList =
			keyData && keyData.contributors && keyData.contributors.length
				? keyData.contributors.slice(0, 1).filter((items: any) => items.krStatusId === Enums.KR_STATUS_ACCEPTED)
				: [];
		if (contributorsList.length > 0) {
			setParentData(contributorsList[0]);
		}
	}, [okr, kr]);

	const checkNumber = (value: any) => {
		const regexp = /^[0-9\b]+$/;
		const regexpWithDecimal = /^(\d*\.)?\d+$/;
		if (Boolean(keyData) && keyData.currencyId === 2) {
			// this is for decimal
			if (value === '' || regexpWithDecimal.test(value)) {
				return parseFloat(!Boolean(value) ? 0 : value);
			}
		} else {
			// this is for number only
			if (value === '' || regexp.test(value)) {
				return parseInt(!Boolean(value) ? 0 : value);
			}
		}
	};
	const handleClickListItem = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuItemClick = (event: any, selectedGoal: any) => {
		if (selectedGoalData && selectedGoalData?.goalObjectiveId === selectedGoal?.goalObjectiveId) {
			setSelectedGoalData(null);
			setOrphanKRData({
				...orphanKRData,
				objectiveName: '',
				goalObjectiveId: Enums.ZERO,
				assignmentTypeId: Enums.ONE,
			});
		} else {
			setOrphanKRData({
				...orphanKRData,
				objectiveName: selectedGoal?.objectiveName || '',
				goalObjectiveId: selectedGoal?.goalObjectiveId || Enums.ZERO,
				assignmentTypeId: selectedGoal.assignmentTypeId || Enums.ONE,
			});
			setSelectedGoalData(selectedGoal);
		}
		setIsAcceptKRFormUpdated(true);
		setAnchorEl(null);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const updateData = (data: any) => {
		let teamId = 0;
		if (data.selectedTeams && data.selectedTeams.length > 0) {
			teamId = data.selectedTeams[0].id;
		}
		setKRAttrDateError({
			error: '',
			helperText: '',
			type: '',
		});
		setOrphanKRData({
			...orphanKRData,
			GoalTypeId: data.currentGoalId,
			IsSelf: data.currentGoalId === 2,
			TeamId: teamId,
			Contributors: data.contributors ? data.contributors : [],
		});
	};

	const updateDueDays = (startDt: any, endDt: any) => {
		const diffInDays = getDueDays(startDt, endDt, props.userSelectedCycle);
		setDueDaysDiff(diffInDays);
	};
	const dateChangeHandler = (range: any, start: any, end: any, selectionType: any) => {
		setOrphanKRData({
			...orphanKRData,
			startDate: formatDate(new Date(start)),
			dueDate: formatDate(new Date(end)),
		});
		setOpenCalender(false);
		krAttrDateError.type === 'Date' && setKRAttrDateError({ error: '', helperText: '', type: '' });
		setIsAcceptKRFormUpdated(true);
	};

	return (
		<Box className='drawer-inner-content'>
			<Box className='drawer-content-panel'>
				<Box className='drawer-content-head '>
					{okr.goalTypeId === Enums.ONE && (
						<Box className='drawer-field-row'>
							<UserAvatar userDetails={parentData} t={t} showParentTag={true} />
						</Box>
					)}
				</Box>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{orphanKRData?.assignmentTypeId === Enums.TWO ? (
						<Box className='drawer-input-field helperText-bottom'>
							<TextField
								className=''
								id='objective-name-dw'
								value={orphanKRData?.objectiveName}
								onChange={(event: any) => {
									const { value } = event.target;
									setOrphanKRData({ ...orphanKRData, objectiveName: value });
									setIsAcceptKRFormUpdated(true);
								}}
								multiline
								error={(krAttrDateError.type === 'objectiveName' && krAttrDateError?.error) || ''}
								helperText={(krAttrDateError.type === 'objectiveName' && krAttrDateError?.helperText) || ''}
								rows={2}
								label='Objective'
								InputProps={{ 'aria-label': 'Objective', disableUnderline: true }}
								fullWidth
							/>
						</Box>
					) : (
						<></>
					)}
					<Box className='drawer-input-field helperText-bottom'>
						<TextField
							className=''
							id='key-des-dw'
							value={orphanKRData?.keyDescription}
							onChange={(event: any) => {
								const { value } = event.target;
								setOrphanKRData({ ...orphanKRData, keyDescription: value });
								setIsAcceptKRFormUpdated(true);
							}}
							multiline
							rows={2}
							label='Key Result'
							error={(krAttrDateError.type === 'keyResult' && krAttrDateError?.error) || ''}
							helperText={(krAttrDateError.type === 'keyResult' && krAttrDateError?.helperText) || ''}
							InputProps={{ 'aria-label': 'key result', disableUnderline: true }}
							fullWidth
						/>
					</Box>
					<Box className='drawer-input-field helperText-bottom'>
						<KrWeight keyAttribute={kr} {...props} viewOnly={true} />
					</Box>
					<Box className='drawer-input-field drawer-date-field helperText-bottom'>
						<FormLabel component='legend'>{t('setATimeframe')}</FormLabel>
						<ReactDateRangePicker
							type={'AddContributor'}
							currentCycleEndDate={new Date(orphanKRData?.dueDate)}
							durationCycleDetail={durationCycleDetail}
							startDateValue={orphanKRData.startDate}
							endDateValue={orphanKRData.dueDate}
							minimumDate={new Date(kr?.startDate || okr?.startDate || orphanKRData?.startDate)}
							maximumDate={new Date(kr?.dueDate || okr?.endDate || orphanKRData?.dueDate)}
							isDateRangeTextFieldVisible={true}
							handleDateSelection={(
								range: any,
								start: any,
								end: any,
								selectionType: String,
								isCycleChanged: Boolean
							) => {
								if (selectionType === 'range') {
									const startDt = moment(start);
									const endDt = moment(end);
									const daysDiff = getDueDays(startDt, endDt, props.userSelectedCycle);
									setDueDaysDiff(daysDiff);
								}
								setSelectedDateRange(range);
								dateChangeHandler(range, start, end, selectionType);
							}}
							bottomChildComponent={<></>}
							allCycleDetails={[]}
							dueDaysDiff={dueDaysDiff}
							setDueDaysDiff={setDueDaysDiff}
							isCurrentCycle={props.userSelectedCycle}
							isCycleVisible={false}
							isEndOfMonthVisible={false}
							numberOfCalendars={Enums.TWO}
							selectedDateRange={selectedDateRange}
							setSelectedDateRange={setSelectedDateRange}
							showBottomText={true}
							selectedCycleDetails={selectedCycleDetails}
						/>
					</Box>
					<Box className='drawer-input-field drawer-date-field helperText-bottom'>
						<KrConfidence
							{...props}
							confidenceLabel={true}
							handleConfidenceClick={(confidenceValue: number) => {
								setOrphanKRData({
									...orphanKRData,
									confidenceId: confidenceValue,
								});
							}}
							confidenceValue={orphanKRData.confidenceId}
							showWeekelyTrend={false}
						/>
					</Box>
					<Box className='drawer-input-field kr-measure-unit'>
						<FormLabel component='legend'>How will you measure progress?</FormLabel>
						<Box display='flex'>
							<OKRButton
								className={`measure-btn active`}
								id={`measure-btn-${orphanKRData && orphanKRData?.metricName}`}
								icon={
									orphanKRData && orphanKRData?.metricName === 'Percentage' ? (
										<PercentageIcon />
									) : orphanKRData && orphanKRData?.metricName === 'Numbers' ? (
										<NumbersIcon />
									) : orphanKRData && orphanKRData?.metricName === 'Currency' ? (
										<CurrencyIcon />
									) : orphanKRData && orphanKRData?.metricName === 'Boolean' ? (
										<BooleanIcon />
									) : orphanKRData && orphanKRData?.metricName === 'NoUnits' ? (
										<NoUnitsIcon />
									) : (
										<></>
									)
								}
								text={orphanKRData?.metricDescription || ''}
								disabled={true}
							/>
							{orphanKRData && (orphanKRData?.metricName === 'NoUnits' || orphanKRData?.metricName === 'Boolean') ? (
								<></>
							) : (
								<Box className='kr-metrics-fileds'>
									<Box className='kr-metrics-fileds-inner'>
										<TextField
											value={orphanKRData.startValue || '0'}
											onChange={(event: any) => {
												// event.preventDefault();
												// event.stopPropagation();
												const { value } = event?.target;
												setOrphanKRData({ ...orphanKRData, startValue: checkNumber(value) });
												setIsAcceptKRFormUpdated(true);
											}}
											className='current-value helperText-bottom'
											id={'current-value'}
											label='Current'
											placeholder='0'
											fullWidth
											error={(krAttrDateError.type === 'startValue' && krAttrDateError?.error) || ''}
											helperText={(krAttrDateError.type === 'startValue' && krAttrDateError?.helperText) || ''}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
											InputProps={{
												...(orphanKRData?.metricName !== 'Percentage' && {
													inputComponent: NumberFormatComponent,
												}),
												startAdornment: (
													<InputAdornment position='start'>
														{orphanKRData?.metricName === 'Percentage' ? (
															<PercentageIcon />
														) : orphanKRData?.metricName === 'Numbers' ? (
															<NumbersIcon />
														) : (
															getCurrencyIcon(orphanKRData?.currencyName ? orphanKRData.currencyName : 'Dollar')
														)}
													</InputAdornment>
												),
											}}
										/>
										<Box className='matrics-field-seprator' component='span'>
											{'/'}
										</Box>
										<TextField
											value={orphanKRData.targetValue || '0'}
											onChange={(event: any) => {
												// event.preventDefault();
												// event.stopPropagation();
												const { value } = event?.target;
												setOrphanKRData({ ...orphanKRData, targetValue: checkNumber(value) });
												setIsAcceptKRFormUpdated(true);
											}}
											className='target-value helperText-bottom'
											id={'target-value'}
											label='Target'
											placeholder='0'
											fullWidth
											error={(krAttrDateError.type === 'targetValue' && krAttrDateError?.error) || ''}
											helperText={(krAttrDateError.type === 'targetValue' && krAttrDateError?.helperText) || ''}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
											InputProps={{
												...(orphanKRData?.metricName !== 'Percentage' && {
													inputComponent: NumberFormatComponent,
												}),
												startAdornment: (
													<InputAdornment position='start'>
														{orphanKRData?.metricName === 'Percentage' ? (
															<PercentageIcon />
														) : orphanKRData?.metricName === 'Numbers' ? (
															<NumbersIcon />
														) : (
															getCurrencyIcon(orphanKRData?.currencyName ? orphanKRData.currencyName : 'Dollar')
														)}
													</InputAdornment>
												),
											}}
										/>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
					{sourceContributor && sourceContributor?.krAssignerMessage && (
						<Box className='message-trail drawer-input-field'>
							<Typography className='message-trail-label'>Message</Typography>
							<List className='message-trail-first-label' component='ul'>
								<ListItem className='first-label-row'>
									<Box className='user-info'>
										<Box className='user-img'>
											{sourceContributor?.imagePath ? (
												<Avatar alt={getFullName(sourceContributor)} src={sourceContributor?.imagePath} />
											) : (
												<Avatar>{getUserName(sourceContributor)}</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{getFullName(sourceContributor)}</Typography>
											<Box className='user-info-list'>
												<Typography variant='subtitle2'>{`${sourceContributor?.designation}`}</Typography>
												<Typography variant='subtitle2'>{`Received on ${getMonthDate(
													sourceContributor?.createdOnAssigner || new Date()
												)}`}</Typography>
											</Box>
										</Box>
									</Box>
									<Box className='user-message-description'>
										<Typography>{sourceContributor?.krAssignerMessage}</Typography>
									</Box>
								</ListItem>
							</List>
						</Box>
					)}
					{KRType === 'Standalone' ? (
						<Box className='kr-field-row add-objective-dropdown'>
							<FormLabel component='legend'>Would you like to move this Key Result in an existing Objective</FormLabel>
							<List
								component='ul'
								aria-label='Device settings'
								className='add-objective-select'
								onClick={handleClickListItem}
							>
								{selectedGoalData === null ? (
									<ListItem className='choose-txt'>
										<Typography variant='body1'>{'Choose an Objective to add this Key Result...'}</Typography>
									</ListItem>
								) : (
									<ListItem aria-haspopup='true' className='add-objective-select-list'>
										<Box className='kr-add-objective-left'>
											<Box className='obj-head-title'>
												<Typography variant='h4'>{selectedGoalData?.objectiveName || ''}</Typography>
											</Box>
											<Box className='kr-short-info'>
												<OKRButton
													className='btn-grey-border'
													id={'key-rs-btn'}
													text={`${
														selectedGoalData.myGoalsDetails ? selectedGoalData.myGoalsDetails.length : ''
													} Key Result`}
												/>
												<Typography variant='body2'>
													{getMonthDate(selectedGoalData.dueDate)}, Cycle: {selectedGoalData.dueCycle}
												</Typography>
											</Box>
										</Box>
										<Box className='kr-add-objective-right'>
											<ListItemAvatar>
												<UserAvatarGroup
													contributorDetails={
														selectedGoalData.contributors && selectedGoalData.contributors.length
															? selectedGoalData.contributors.filter(
																	(item: any) => item.krStatusId === Enums.KR_STATUS_ACCEPTED
															  )
															: []
													}
													avatarClassName='contributor-people-img'
													isButtonVisible={false}
													isButtonDisable={true}
													avatarGroupClassName='contributor-user-avatar'
													loginUserAvatar={selectedGoalData}
													max={3}
												/>
											</ListItemAvatar>
										</Box>
									</ListItem>
								)}
							</List>
							<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
								<MenuItem className='kr-add-objective kr-add-existing-objective'>
									<MenuList className='kr-add-objective-wrap-list'>
										{filterGoal && filterGoal.length > 0 ? (
											filterGoal.map((item: any, index: any) => (
												<MenuItem
													className=''
													key={`list-${item}`}
													id={item}
													selected={item?.goalObjectiveId === selectedGoalData?.goalObjectiveId}
													onClick={(event: any) => handleMenuItemClick(event, item)}
												>
													<Box className='kr-add-objective-left'>
														<Box className='obj-head-title'>
															<Typography variant='h4'>{item.objectiveName}</Typography>
														</Box>
														<Box className='kr-short-info'>
															<OKRButton
																className='btn-grey-border'
																text={`${item.myGoalsDetails ? item.myGoalsDetails.length : ''} Key Result`}
															/>
															<Typography variant='body2'>
																{getMonthDate(item.dueDate)}, Cycle: {item.dueCycle}
															</Typography>
														</Box>
													</Box>
													<Box className='kr-add-objective-right'>
														<ListItemAvatar>
															<UserAvatarGroup
																contributorDetails={item.contributors}
																avatarClassName='contributor-people-img'
																isButtonVisible={false}
																isButtonDisable={true}
																avatarGroupClassName='contributor-user-avatar'
																loginUserAvatar={item}
																max={3}
															/>
														</ListItemAvatar>
													</Box>
												</MenuItem>
											))
										) : (
											<MenuItem className='' onClick={(event: any) => setAnchorEl(null)}>
												{t('NoObjCreated')}
											</MenuItem>
										)}
									</MenuList>
								</MenuItem>
							</Menu>
							<Typography variant='body1' className='add-objective-short-des'>
								If you are not sure leave it as is.
							</Typography>
						</Box>
					) : (
						<></>
					)}
					{okr.goalTypeId === Enums.ONE && (
						<Box className='drawer-field-row'>
							<AssignmentSelection
								{...props}
								keyData={orphanKRData}
								kr={kr}
								okr={okr}
								okrMasterData={okrMasterData}
								durationCycleDetail={durationCycleDetail}
								krAttrDateError={krAttrDateError}
								updateData={updateData}
							/>
						</Box>
					)}
				</form>
			</Box>
		</Box>
	);
};

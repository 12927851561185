import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import { MenuList, MenuItem, Typography, Box, IconButton } from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import { UnlockOkrIcon, OpinionIcon } from '../../config/svg/DrawerSvg';
import { CloseIcon } from '../../config/svg/GlobalSvg';
import { getLocalStorageItem } from '../../services/StorageService';
import Dialog from '@material-ui/core/Dialog';
import '../../styles/pages/about/about.scss';
import Logo from '../../images/okr-logo.svg';
import { getQuarterStartEnd, openPost } from '../../config/utils';
import { CallOutTooltip } from '../../components/OkrAction/CallOutTooltip';
import DownloadReports from '../Admin/Header/DownloadReports';
import { LeftArrow } from '../../config/svg/ArrowSvg';
import { GlossaryIcon, FaqIcon, OKRExamIcon, NewTabIcon } from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import { LEARNING } from '../../config/constant';

function SimpleDialog(props) {
	const { openAboutUs, aboutUsOpen } = props;
	const { t } = useTranslation();
	let { currentYear } = getQuarterStartEnd();
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));

	return (
		<Dialog
			onClose={(e) => openAboutUs(e, false)}
			aria-labelledby='simple-dialog-title'
			open={aboutUsOpen}
			className='about-main-wrapper'
		>
			<Link id='close-icon' className='close-icon-right' onClick={(e) => openAboutUs(e, false)}>
				<CloseIcon />
			</Link>
			<div className='about-us-wrapper'>
				<div className='logo-img'>
					<img src={Logo} alt={t('HeaderDrawerListLogo')} />
				</div>
				<div className='inner'>
					<div className='about-info'>
						<Typography>{`${t('version')} ${userDetail.version}`}</Typography>
						{/* <p>Product ID: {userDetail.productID}</p> */}
						<Typography>{`${t('license')} ${userDetail.license}`}</Typography>
						{/* <Typography>{`${t('belongsTo')} ${userDetail.belongsTo}`}</Typography> */}
						<Typography className='about-copy-reserve'>
							&copy; {currentYear} {t('infoproRights')}
						</Typography>
						<p>
							{/* <Link onClick={(e) => handleMenuClick(e)}>{t('licenseTerms')}</Link>{' '} */}
							{/* |{' '}
							<a href='' target='_blank'>
								{t('thirdPartyNotice')}
							</a> */}
							<Link id='terms-of-use' target='_blank' to='/terms-of-use'>
								{t('licenseTerms')}
							</Link>
							<span className='seprator'>|</span>
							<Link id='privacy-policy' target='_blank' to='/privacy-policy'>
								{t('privacyPolicy')}
							</Link>
						</p>
					</div>
					<div className='warning-info'>{t('warningAboutUs')}</div>
				</div>
			</div>
		</Dialog>
	);
}

export default function DrawerList(props) {
	// const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const [aboutUsOpen, setAboutUsOpen] = useState(false);
	const { t } = useTranslation();
	const accessTokenId = JSON.parse(getLocalStorageItem('accessTokenId'));

	const handleMenuClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		return false;
	};

	const openJiraIssueCollector = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.handleToggle(2);

		document.getElementById('atlwdg-trigger').click();
	};

	useEffect(() => {
		const script = document.createElement('script');
		const script2 = document.createElement('script');

		script.type = 'text/javascript';
		script.src =
			'https://servicedesk.compunnel.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/8bkccv/814001/1b2186679a631144916156ab6aec18b5/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=7dfe95aa';
		script.async = true;
		script2.type = 'text/javascript';
		script2.src =
			'https://servicedesk.compunnel.com/s/ba53967f66ec0096b89bb4fd64ec1120-T/8bkccv/814001/1b2186679a631144916156ab6aec18b5/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7dfe95aa';
		script2.async = true;

		document.head.append(script);
		document.head.append(script2);

		window.ATL_JQ_PAGE_PROPS = {
			triggerFunction: function (showCollectorDialog) {
				showCollectorDialog();
			},
			fieldValues: {
				fullname: (userDetail && userDetail.firstName) + ' ' + (userDetail && userDetail.lastName),
				email: userDetail && userDetail.emailId,
				customfield_11308: window.location.hostname,
			},
		};
	}, []);

	const openAboutUs = (e, value) => {
		e.preventDefault();
		e.stopPropagation();
		setAboutUsOpen(value);
	};
	//callout popup
	const [callOut, setCallOut] = useState({ open: false, text: '', timer: 0 });
	const [anchorEl, setAnchorEl] = useState(null);
	// const inputRefGuidedTourLink = useRef();

	// useEffect(() => {
	// 	if (props.listOkrSuccess !== 'success' || props.currentRoute !== '/unlock-me' || props.dashboardCurrentTab !== 0) {
	// 		setCallOut({
	// 			open: false,
	// 		});
	// 	} else {
	// 		const timeout = setTimeout(() => {
	// 			isGuidedTourShow === 'true' && inputRefGuidedTourLink.current.focus();
	// 			isGuidedTourShow === 'true' && setAnchorEl(inputRefGuidedTourLink.current);
	// 			isGuidedTourShow === 'true' &&
	// 				setCallOut({
	// 					open: true,
	// 					timer: getReadTimer(t('guidedTourTooltip')),
	// 					text: `${t('guidedTourTooltip')}`,
	// 				});
	// 		}, 500);
	// 		return () => {
	// 			clearTimeout(timeout);
	// 		};
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props.currentRoute, props.listOkrSuccess, props.dashboardCurrentTab]);
	const handleNavigateToLearning = () => {
		const unique_name = jwtDecode(getLocalStorageItem('accessTokenId'))?.unique_name || '';
		const static_token = 'poiuytrewq0987654321';
		const body = `${unique_name}${static_token}`;
		const hash = CryptoJS.MD5(body);
		const payloadToSend = `?access_token=${accessTokenId}&securitykey(${hash})`;
		const params = [];
		params['payload'] = payloadToSend;
		openPost(LEARNING, params);
	};
	return (
		<Fragment>
			<Box className='drawer-nav-head'>
				<IconButton
					color='inherit'
					aria-label={t('HeaderMainAriaLabel')}
					onClick={(e) => props.handleToggle(2)}
					edge='start'
					className='nav-drawer-close'
					id='nav-drawer-close'
				>
					<LeftArrow />
				</IconButton>
				<div className='logo-img'>
					<img src={Logo} alt={t('HeaderDrawerListLogo')} />
				</div>
			</Box>
			<Box className='drawer-link-list'>
				<MenuList>
					<MenuItem id='okr-exm-link'>
						<a href='https://unlockokr.com/okr-examples/' target='_blank' rel='noreferrer noopener'>
							<OKRExamIcon />
							<Typography>{t('okrExamples')}</Typography>
						</a>
					</MenuItem>
					<MenuItem id='faq-link'>
						<a href='https://unlockokr.com/faq/' target='_blank' rel='noreferrer noopener'>
							<FaqIcon />
							<Typography>{t('faqs')}</Typography>
						</a>
					</MenuItem>
					<MenuItem id='glossary-link'>
						<a href='https://unlockokr.com/glossary/' target='_blank' rel='noreferrer noopener'>
							<GlossaryIcon />
							<Typography>{t('glossary')}</Typography>
						</a>
					</MenuItem>
				</MenuList>
				{/* {userDetail && userDetail.roleId && userDetail.roleId === 1 && <DownloadReports {...props} />} */}
				<DownloadReports {...props} />
			</Box>
			<Box className='drawer-bottom-links'>
				{/* {isGuidedTourShow === 'true' && (
					<MenuItem
						disabled={
							props.listOkrSuccess !== 'success' ||
							props.currentRoute !== '/unlock-me' ||
							props.dashboardCurrentTab !== 0
						}
						ref={inputRefGuidedTourLink}
					>
						<Link
							onClick={(e) => {
								props.openGuidedMenu(e);
							}}
						>
							<JourneyIcon />
							<Typography>{t('guidedTour')}</Typography>
						</Link>
					</MenuItem>
				)} */}
				<MenuList className='academy-link'>
					<MenuItem id='academy-link'>
						<Box className='academy-link-wrap' onClick={handleNavigateToLearning}>
							<Box>
								<Typography variant='body2'>{t('Learn about OKRs!')}</Typography>
								<Typography variant='h4'>{t('Unlock:Academy')}</Typography>
							</Box>
							<OKRButton className='academy-btn' icon={<NewTabIcon />}></OKRButton>
						</Box>
					</MenuItem>
				</MenuList>
				<MenuList className='bottom-link-info'>
					<MenuItem id='about-link'>
						<Link onClick={(e) => openAboutUs(e, true)}>
							<UnlockOkrIcon />
							<Typography>{t('aboutUnlockOKR')}</Typography>
						</Link>
					</MenuItem>
					<MenuItem id='improvements-link'>
						<Link onClick={(e) => openJiraIssueCollector(e)}>
							<OpinionIcon />
							<Typography>{t('productFeedback')}</Typography>
						</Link>
					</MenuItem>
				</MenuList>
			</Box>
			<SimpleDialog aboutUsOpen={aboutUsOpen} openAboutUs={openAboutUs} handleMenuClick={handleMenuClick} />
			<CallOutTooltip
				open={callOut.open}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				setCallOut={setCallOut}
				text={t(callOut.text)}
				timer={callOut.timer}
				iconForText={true}
				classname={'guided-tour-callout'}
			/>
		</Fragment>
	);
}

import {
	Avatar,
	Box,
	Checkbox,
	ClickAwayListener,
	FormControlLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { CrossIcon, NewFilterIcon, TeamUserIcon, UserReportingToIcon } from '../../config/svg/CommonSvgIcon';
import { BorderCheckboxIcon, CheckedIcon, ErrorIcon, UnCheckedIcon } from '../../config/svg/formElementIcons';
import { OKRButton } from '../Common/OKRButton';
import '../../styles/pages/reports/filter.scss';

const ReportFilter: React.FC<any> = (props: any) => {
	const {
		filterSelectionError,
		isFilterChanged,
		secondFiltersOption,
		handleFilterOption,
		handleApplyFilter,
		handleResetFilter,
		teams,
		handleFilterBtnClick,
		handleClose,
		anchorEl,
		isSencondFilters,
		toolTipText,
		filterId,
	} = props;
	const { t } = useTranslation();
	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Box className='report-filter'>
				<OKRButton
					className={`report-filter-btn ${anchorEl ? 'active' : ''}`}
					icon={<NewFilterIcon />}
					text={t('filterLabel')}
					handleClick={(e) => handleFilterBtnClick(e)}
					id={`btn-${filterId}`}
				/>
				{anchorEl && (
					<Box className={`filter-popover  ${!isSencondFilters ? 'filter-second-popover' : ''}`}>
						<Box className='connnector-icon'></Box>
						<Box className='action-btn action-btn-top'>
							{isFilterChanged && (
								<OKRButton className='btn-link' handleClick={handleResetFilter} text={t('resetLabel')} />
							)}
							<OKRButton className='btn-cross' handleClick={handleClose} icon={<CrossIcon />} />
						</Box>
						<Box className='filter-popover-inner'>
							<Box className='col-head'>
								<Box className='col'>
									{teams && teams.length > 0 && (
										<Tooltip
											title={toolTipText ? toolTipText : ''}
											arrow={true}
											classes={{ popper: 'tooltip-layout4' }}
										>
											<FormControlLabel
												label={t('byTeamLabel')}
												control={
													<Checkbox
														onChange={(e) => handleFilterOption(e, 'selectAllTeam')}
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														indeterminateIcon={<UnCheckedIcon />}
														indeterminate={
															teams.filter((item: any) => item.isChecked).length > 0 &&
															teams.filter((item: any) => item.isChecked).length !== teams.length
														}
														checked={teams.filter((item: any) => item.isChecked).length === teams.length}
														id={`${filterId}-team-chkbox`}
													/>
												}
											/>
										</Tooltip>
									)}
								</Box>
								{isSencondFilters && (
									<Box className='col'>
										{secondFiltersOption && secondFiltersOption.length > 0 && (
											<FormControlLabel
												label={t('natureOfOKRs')}
												control={
													<Checkbox
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														indeterminateIcon={<UnCheckedIcon />}
														onChange={(e) => handleFilterOption(e, 'selectAllNature')}
														checked={
															secondFiltersOption.filter((item: any) => item.isChecked).length ===
															secondFiltersOption.length
														}
														indeterminate={
															secondFiltersOption.filter((item: any) => item.isChecked).length > 0 &&
															secondFiltersOption.filter((item: any) => item.isChecked).length !==
																secondFiltersOption.length
														}
														id={`${filterId}-nature-chkbox`}
													/>
												}
											/>
										)}
									</Box>
								)}
							</Box>
							<Box className='col-content'>
								<Box className='col'>
									<List className='filter-list' component='div' disablePadding>
										{teams &&
											teams.length > 0 &&
											teams.map((team: any, index: number) => (
												<ListItem component='div' key={index} id={`${filterId}-col-${index}`}>
													<ListItemIcon>
														<Checkbox
															onChange={(e) => handleFilterOption(e, 'teams', team.teamId)}
															icon={<BorderCheckboxIcon />}
															checkedIcon={<CheckedIcon />}
															checked={team.isChecked}
														/>
													</ListItemIcon>
													<ListItemText className='team-details' disableTypography>
														<ListItemAvatar>
															<Avatar
																className='team-avatar'
																style={{
																	backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
																	color: getRandomColor(team?.colorCode),
																}}
																src={team.teamLogo || ''}
															>
																{getUserName({
																	firstName: '',
																	lastName: '',
																	fullName: team.teamName,
																})}
															</Avatar>
														</ListItemAvatar>
														<Box className='team-info'>
															<Typography variant='h5'>{team.teamName}</Typography>
															<Box display='flex'>
																<Typography variant='subtitle2'>
																	<TeamUserIcon /> {team.employeeCount}
																</Typography>
																<Typography variant='subtitle2'>
																	<UserReportingToIcon />
																	{team.teamHeadName && team.teamHeadName.length > 10 ? (
																		<Tooltip arrow title={team?.teamHeadName} aria-label='Teams'>
																			<span>{team?.teamHeadName.substring(0, 9)}...</span>
																		</Tooltip>
																	) : (
																		<span>{team.teamHeadName}</span>
																	)}
																</Typography>
															</Box>
														</Box>
													</ListItemText>
												</ListItem>
											))}
									</List>
								</Box>
								{isSencondFilters && (
									<Box className='col'>
										<List className='filter-list filter-list-nature' component='div' disablePadding>
											{secondFiltersOption &&
												secondFiltersOption.length > 0 &&
												secondFiltersOption.map((item: any, index: any) => (
													<ListItem component='div' key={index} id={`${filterId}-scdcol-${index}`}>
														<FormControlLabel
															control={
																<Checkbox
																	onChange={(e) => handleFilterOption(e, 'natureOfOkr', item.okrNatureId)}
																	checked={item.isChecked}
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																/>
															}
															label={item.name}
														/>
													</ListItem>
												))}
										</List>
									</Box>
								)}
							</Box>
						</Box>
						<Box className='action-btn'>
							{filterSelectionError && (
								<Box className='information-message'>
									<Typography variant='h6' className='no-sentence-case'>
										<ErrorIcon />
										{t('filterInfoMessage')}
									</Typography>
								</Box>
							)}
							<OKRButton
								disabled={filterSelectionError}
								className='btn-secondary'
								handleClick={handleApplyFilter}
								text={t('applyLabel')}
								id={`apply-btn-${filterId}`}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</ClickAwayListener>
	);
};

export default ReportFilter;

import React, { useState } from 'react';
import { Box, Slider, Typography, Tooltip } from '@material-ui/core';
import '../../styles/dashboard.scss';
import './style.scss';
import { ProfileIcon } from '../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import { ProgressDownArrow, ProgressUpArrow } from '../../config/svg/ArrowSvg';
import { ContributorsListPopover } from '../Common/ContributorsListPopover';
import { KeyResultIcon, ObjectiveIcon } from '../../config/svg/MyGoalSvg';

export const Dashboard: React.FC<any> = (props) => {
	const { deltaScoreData, handleCardClick, cardDashboard, dashboardProgressData } = props;
	const {
		progressCode,
		avgScore,
		okrCount,
		keyCount,
		atRisk,
		lagging,
		notStarted,
		onTrack,
		contributorsCount,
		deltaProgress,
		isDeltaVisible,
	} = dashboardProgressData;

	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);

	const [popupView, setPopupView] = useState<number>(0);
	const contributorDetails = deltaScoreData && deltaScoreData.contributorsLastSevenDaysProgress;
	const getCardColor = (progress: any) => {
		let colorClass = 'grey';
		if (progress) {
			if (progress?.toLowerCase() === 'ff9401') {
				colorClass = 'yellow';
			} else if (progress?.toLowerCase() === 'ff0000') {
				colorClass = 'red';
			} else if (progress?.toLowerCase() === '07a363') {
				colorClass = 'green';
			}
		}
		return colorClass;
	};

	// const handleProgressOpen = (event: any) => {
	// 	setAnchorEl(event.currentTarget);
	// 	setOpen(true);
	// };

	/**
	 * Method to display number
	 * @param avgScore Number
	 * @param lastProgress Number
	 * @returns Number
	 */
	// const displayScore = (avgScore: number, lastProgress: number) => {
	// 	return Math.max(0, avgScore - lastProgress);
	// };

	const ProgressCard = (cardDetails: any) => {
		const { cardName, cardData, cardClassName, cardType, cardId } = cardDetails;

		return (
			<Box
				className={cardDashboard[cardType] ? 'progress-card active' : 'progress-card'}
				id={cardId}
				onClick={(e) => handleCardClick(e, cardType)}
			>
				<Box className='progress-card-top'>
					<Box className='user-progress-text'>
						{cardData && cardData.length > 0 && cardData[0] && cardData[0].score ? (
							<>
								<Typography variant='h2' id={`score-${cardId}`}>{`${cardData[0].score}%`}</Typography>
							</>
						) : (
							<Typography variant='h2'>0%</Typography>
						)}
					</Box>
					<Box className={`progress-card-name ${cardClassName}`}>
						<span>{cardName}</span>
					</Box>
				</Box>
				<Box className='progress-card-bottom'>
					<Box className='progress-bar'>
						<Slider
							className={cardClassName || ''}
							id={`okr-avg-progress-${cardId}`}
							value={cardData && cardData.length > 0 && cardData[0] && cardData[0].score ? cardData[0].score : 0}
							aria-label=''
							valueLabelDisplay='off'
							marks={false}
							disabled
						/>
					</Box>
					<Box className='okrs-count'>
						<Typography variant='h6'>{t('okrsLabel')}</Typography>
						<Typography variant='h6' id={`okr-count-${cardId}`}>
							{cardData && cardData.length > 0 && cardData[0] && cardData[0].objectiveCount
								? cardData[0].objectiveCount
								: 0}
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	};

	return (
		<Box className='pad-container'>
			<Box className='progress-cards' id='dashboard'>
				<Box className='progress-cards-dashboard'>
					<Box className='progress-main-card' id='my-progress-card'>
						<Box className='progress-card-top'>
							<Box className='progress-card-title'>
								<Box className='p-card-title-left'>
									<Typography variant='h6'>{t('myProgressLabel')}</Typography>
									<Typography variant='h2' id='my-avg-progress'>
										{avgScore}%
									</Typography>
								</Box>
								<Box className='p-card-title-right'>
									{isDeltaVisible && (
										<Tooltip title={`${t('deltaProgressHover')}`} arrow classes={{ popper: 'tooltip-layout2' }}>
											<Typography
												id='delta-progress-started'
												variant='body2'
												className='progress-started'
												// onClick={handleProgressOpen}
											>
												{deltaProgress < 0 ? <ProgressDownArrow /> : deltaProgress > 0 ? <ProgressUpArrow /> : ''}
												{deltaProgress}%
											</Typography>
										</Tooltip>
									)}
								</Box>
							</Box>
							<Box className='progress-bar'>
								<Slider
									className={getCardColor(progressCode)}
									id='my-avg-progress-bar'
									aria-label=''
									value={avgScore || 0}
									valueLabelDisplay='off'
									marks={false}
									disabled
								/>
							</Box>
						</Box>
						<Box className='progress-card-bottom'>
							<Box className='tabpanel-okr-counts'>
								<Box className='okr-counts-text'>
									<Typography variant='h6' id='okr-count'>
										<ObjectiveIcon />
										{`${okrCount || 0} Objective(s)`}
									</Typography>
									<Typography variant='h6' id='kr-count'>
										<KeyResultIcon />
										{`${keyCount || 0} Key Result(s)`}
									</Typography>
								</Box>
								<Box className='user-count-txt'>
									<Tooltip title={`${t('contributorsLabel')}`} arrow>
										<span>
											<ProfileIcon />
											<Typography variant='h6' id='contri-count'>
												{contributorsCount || 0}
											</Typography>
										</span>
									</Tooltip>
								</Box>
							</Box>
						</Box>
					</Box>
					<ProgressCard
						cardName={'On Track'}
						cardData={onTrack}
						cardType={'onTrack'}
						cardClassName={'green'}
						cardId={'on-track-card'}
					/>
					<ProgressCard
						cardName={'Lagging'}
						cardData={lagging}
						cardType={'lagging'}
						cardClassName={'yellow'}
						cardId={'lagging-card'}
					/>
					<ProgressCard
						cardName={'At Risk'}
						cardData={atRisk}
						cardType={'atRisk'}
						cardClassName={'red'}
						cardId={'at-risk-card'}
					/>
					<ProgressCard
						cardName={'Not Started'}
						cardData={notStarted}
						cardType={'notStarted'}
						cardClassName={'grey'}
						cardId={'not-started-card'}
					/>
				</Box>
				{open && (
					<ContributorsListPopover
						{...props}
						open={open}
						setOpen={setOpen}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
						contributorDetails={contributorDetails}
						loginUserAvatar={false}
						poperContainer={false}
						popupView={popupView}
						setPopupView={setPopupView}
					/>
				)}
			</Box>
		</Box>
	);
};

// import { render } from 'react-dom';
// import './index.css';
import * as React from 'react';
import {
	DiagramComponent,
	Inject,
	LineRouting,
	// PortVisibility,
	SnapConstraints,
	DiagramConstraints,
	DiagramTools,
} from '@syncfusion/ej2-react-diagrams';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { SampleBase } from './sample-base';
import { OKRTabPanelCard } from '../../Common/OKRTabPanelCard';
import PeopleRootNode from '../AlignmentListing/PeopleRootNode';
import { ALIGNMENTMAPS } from '../../../config/app-url';
import { Box } from '@material-ui/core';

let connectors2 = [];
let diagramInstance;
let template;
let dynamicNodes;
const sortMatch = {
	desc_progress: 'score',
	asc_progress: 'score',
	desc_contribution: 'contributions',
	asc_contribution: 'contributions',
	leastProgress7days: 'leastProgress7days',
	leastProgress15days: 'leastProgress15days',
	leastProgress30days: 'leastProgress30days',
};
export default class DirectReportsMapUltra extends SampleBase {
	constructor(props) {
		super(props);
		template = this.diagramTemplate;
		dynamicNodes = JSON.parse(JSON.stringify(this.props.directReportResult));
		this.createLayout(dynamicNodes);
	}

	createLayout(dynamicNodes) {
		const pIndex = dynamicNodes.findIndex(
			(item) => Number(item.employeeId) === Number(this.props.userDetail.employeeId)
		);
		const parentNode = dynamicNodes.splice(pIndex, 1);
		this.alignNodes(dynamicNodes);
		this.createConnections(dynamicNodes);
		// let rows = Math.ceil(dynamicNodes.length / 3);
		dynamicNodes.unshift({
			...parentNode[0],
			id: 'parent',
			offsetX: 200,
			offsetY: 100,
			shape: { type: 'HTML' },
			data: parentNode[0],
		});
	}

	onItemClick(args) {
		switch (args.item.text) {
			case 'Zoom In':
				let zoomin = { type: 'ZoomIn', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomin);
				break;
			case 'Zoom Out':
				let zoomout = { type: 'ZoomOut', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomout);
				break;
			case 'Reset':
				// diagramInstance.fitToPage({ mode: 'Width' });
				diagramInstance.reset();
				diagramInstance.fitToPage();
				diagramInstance.zoomTo({
					type: 'ZoomOut',
					zoomFactor: 0.5,
				});
				break;
			default:
				break;
		}
	}

	diagramTemplate(node) {
		// console.log('node-----', this.props, node.data);

		if (Number(node.data.employeeId) === Number(this.props.userDetail.employeeId)) {
			return <PeopleRootNode {...this.props} data={node} userDetail={this.props.userDetail} />;
		}

		return (
			<OKRTabPanelCard
				{...this.props}
				panelUsed={ALIGNMENTMAPS}
				data={node}
				userDetail={this.props.userDetail}
				t={this.props.t}
				diagramInstance={diagramInstance}
				alignmentmapTab={true}
			/>
		);
		/* return (
			<div style={{ width: '150px', height: '80px', backgroundColor: '#fff', border: 'solid 1px #ccc' }}>
				name: 
				{node.data.firstName}
			</div>
		); */
	}

	createConnections(allNodes) {
		connectors2 = allNodes
			// .filter((item, i) => i > 0)
			.map((item, i) => {
				return {
					id: 'Connector' + (i + 1),
					sourceID: item.id,
					// sourceID: item.employeeId.toString(),
					targetID: 'parent',
				};
			});
	}

	alignNodes(allNodes) {
		let x = 200;
		let y = 100;
		// let rows = Math.ceil(allNodes.length / 3);
		// rows = rows > 4 ? rows : 4;
		allNodes.forEach((item, i) => {
			item.data = JSON.parse(JSON.stringify(item));
			if (i % 3 !== 0) {
				x += 440;
			} else if (i % 3 === 0) {
				x = 610;
				y += 100;
			}

			item.offsetX = x;
			item.offsetY = y;
			item.shape = {
				type: 'HTML',
				// content: renderNode(item),
			};
			item.id = `id_${item.employeeId}`;
		});
	}

	rendereComplete() {
		// diagramInstance.fitToPage({ mode: 'Width' });
		diagramInstance.fitToPage();
		diagramInstance.zoomTo({
			type: 'ZoomOut',
			zoomFactor: 0.5,
		});
	}

	sortData(data, sortBy) {
		if (sortBy.indexOf('desc_') > -1) {
			data.sort((a, b) => b[sortMatch[sortBy]] - a[sortMatch[sortBy]]);
		} else {
			data.sort((a, b) => a[sortMatch[sortBy]] - b[sortMatch[sortBy]]);
		}
	}

	render() {
		let filteredData = JSON.parse(JSON.stringify(dynamicNodes));
		if (!connectors2.length && !dynamicNodes.length) {
			return <div>{this.props.t('loading')}</div>;
		} else {
			if (this.props.organisationFilterData && this.props.organisationFilterData.length) {
				filteredData = dynamicNodes.filter(
					(item) =>
						item.employeeId === this.props.userDetail.employeeId ||
						item.directReportsTeamResponse.filter(
							(team) => team.teamId === this.props.organisationFilterData[0].organisationId
						).length
					//  === this.props.organisationFilterData[0].organisationId
					// item.organisationId === this.props.organisationFilterData[0].organisationId
				);
				// this.createLayout(filteredData);
				// console.log('dynamicNodes', connectors2, filteredData);
			}
			/* if (this.props.directReportSortBy) {
				this.sortData(filteredData, this.props.directReportSortBy);
				// console.log('filteredData', filteredData);
			} */
			this.createLayout(filteredData);

			return (
				<Box className='diagram-control-pane'>
					<Box className='control-panel'>
						<Box className='control-section'>
							<Box className='content-wrapper'>
								<ToolbarComponent
									id='toolbar_diagram'
									clicked={this.onItemClick}
									items={[
										{
											type: 'Button',
											//tooltipText: 'ZoomIn',
											text: 'Zoom In',
											//prefixIcon: 'e-diagram-icons e-diagram-zoomin',
										},
										{
											type: 'Button',
											//tooltipText: 'ZoomOut',
											text: 'Zoom Out',
											//prefixIcon: 'e-diagram-icons e-diagram-zoomout',
										},
										{
											type: 'Button',
											//tooltipText: 'Reset',
											text: 'Reset',
											//prefixIcon: 'e-diagram-icons e-diagram-reset',
										},
									]}
								/>
							</Box>
						</Box>
					</Box>
					<div className='control-pane'>
						<div className='control-section'>
							<div className='content-wrapper' style={{ width: '100%' }}>
								<DiagramComponent
									id='diagram'
									ref={(diagram) => (diagramInstance = diagram)}
									width={'100%'}
									height={'calc(100vh - 200px)'}
									//height={'1500px'}
									tool={DiagramTools.ZoomPan}
									nodeTemplate={template.bind(this)}
									snapSettings={{ constraints: SnapConstraints.None }}
									constraints={DiagramConstraints.Default | DiagramConstraints.LineRouting}
									// nodes={nodes}
									nodes={filteredData}
									connectors={connectors2}
									getConnectorDefaults={getConnectorDefaults}
									getNodeDefaults={(node) => getNodeDefaults(node, this.props.organisationFilterData, filteredData)}
								>
									<Inject services={[LineRouting]} />
								</DiagramComponent>
							</div>
						</div>
					</div>
				</Box>
			);
		}
	}
}

function getNodeDefaults(node, filters, data) {
	node.height = 80;
	node.width = 400;
	if (node.id === 'parent') {
		node.width = 120;
		node.height = 120;
		// if (filters.length) {
		setTimeout(() => {
			// node.offsetY = 102;
			node.offsetX = 202;
		}, 0);
		// }
		// if (diagramInstance) {
		// 	diagramInstance.dataBind();
		// 	diagramInstance.doLayout();
		// }
		if (data.length === 2) {
			node.offsetY = 200;
		}
	}
	node.style = { strokeColor: 'transparent' };
	return node;
}

function getConnectorDefaults(connector) {
	connector.type = 'Orthogonal';
	connector.cornerRadius = 5;
	connector.style = { strokeColor: '#5B708B', strokeWidth: 1.25 };
	connector.targetDecorator.style = {
		fill: '#5B708B',
		strokeColor: '#5B708B',
	};
	return connector;
}

import * as actionTypes from './actionTypes';
import {
	getUsersListAPI,
	addUsersAPI,
	addAdUsersAPI,
	editUsersAPI,
	uploadUserAPI,
	downloadUserSampleAPI,
	getSearchUsersListAPI,
	deleteUsersAPI,
	unlockUsersAPI,
	changeUsersRoleAPI,
	changeUsersReportingToAPI,
	changeUsersOrganizationAPI,
	getDesignationAPI,
	getProfileAPI,
	updateProfileAPI,
	updateProfilePicAPI,
	updateProfilePasswordAPI,
	deleteProfilePicAPI,
	getLicenseAPI,
	validateBulkFileUploadAPI,
	validateBulkEmailsUploadAPI,
	uploadEmailsAPI,
	getUserDetailsApi,
} from '../services/UsersService';
import { showLoader } from './common';

const fetchUsersPending = () => ({
	type: actionTypes.FETCH_USERS_PENDING,
});
const fetchUsersCompleted = (payload) => ({
	type: actionTypes.FETCH_USERS_SUCCESS,
	payload,
});
const fetchUsersFailed = (error) => ({
	type: actionTypes.FETCH_USERS_FAILED,
	error,
});

export const getUsersList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchUsersPending());
		return getUsersListAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchUsersCompleted(response.data));
				} else {
					dispatch(fetchUsersFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchUsersFailed(error));
				dispatch(showLoader(false));
			});
	};
};

const searchUsersPending = () => ({
	type: actionTypes.SEARCH_USERS_PENDING,
});
const searchUsersCompleted = (payload) => ({
	type: actionTypes.SEARCH_USERS_SUCCESS,
	payload,
});
const searchUsersFailed = (error) => ({
	type: actionTypes.SEARCH_USERS_FAILED,
	error,
});

export const getAllUserList = (data) => {
	return () => {
		return getUsersListAPI(data);
	};
};

export const getSearchUsersList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(searchUsersPending());
		return getSearchUsersListAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(searchUsersCompleted(response.data));
				} else {
					dispatch(searchUsersFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(searchUsersFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const addUsers = (data) => {
	return () => {
		return addUsersAPI(data);
	};
};

export const addAdUsers = (data) => {
	return () => {
		return addAdUsersAPI(data);
	};
};

export const editUsers = (data) => {
	return () => {
		return editUsersAPI(data);
	};
};

export const updateStatus = () => ({
	type: actionTypes.UPDATE_USERS_STATUS,
});

export const deleteUsers = (data) => {
	return () => {
		return deleteUsersAPI(data);
	};
};

export const unlockUsers = (data) => {
	return () => {
		return unlockUsersAPI(data);
	};
};

export const changeUsersRole = (data) => {
	return () => {
		return changeUsersRoleAPI(data);
	};
};

export const changeUsersReportingTo = (data) => {
	return () => {
		return changeUsersReportingToAPI(data);
	};
};

export const changeUsersOrganization = (data) => {
	return () => {
		return changeUsersOrganizationAPI(data);
	};
};

export const downloadUserSample = (data) => {
	return () => {
		return downloadUserSampleAPI(data);
	};
};

export const uploadUser = (data) => {
	return () => {
		return uploadUserAPI(data);
	};
};

export const uploadEmails = (data) => {
	return () => {
		return uploadEmailsAPI(data);
	};
};

export const getDesignation = (data) => {
	return () => {
		return getDesignationAPI(data);
	};
};

export const getProfile = (data) => {
	return () => {
		return getProfileAPI(data);
	};
};

export const updateProfileDetail = (data) => {
	return () => {
		return updateProfileAPI(data);
	};
};

export const updateProfilePic = (data) => {
	return () => {
		return updateProfilePicAPI(data);
	};
};

export const updateProfilePassword = (data) => {
	return () => {
		return updateProfilePasswordAPI(data);
	};
};

export const deleteProfilePic = (data) => {
	return () => {
		return deleteProfilePicAPI(data);
	};
};

/** Get License details */

const fetchLicensePending = () => ({
	type: actionTypes.FETCH_LICENCE_DETAIL_PENDING,
});
const fetchLicenseCompleted = (payload) => ({
	type: actionTypes.FETCH_LICENCE_DETAIL_SUCCESS,
	payload,
});
const fetchLicenseFailed = (error) => ({
	type: actionTypes.REFETCH_LICENCE_DETAIL,
	error,
});

export const getLicenseDetails = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchLicensePending());
		return getLicenseAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchLicenseCompleted(response.data));
				} else {
					dispatch(fetchLicenseFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchLicenseFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const refetchLicenceDetails = (error) => ({
	type: actionTypes.REFETCH_LICENCE_DETAIL,
	error,
});

export const refetchUserDetails = (error) => ({
	type: actionTypes.REFETCH_USER_DETAIL,
	error,
});

export const validateBulkFileUpload = (data) => {
	return () => {
		return validateBulkFileUploadAPI(data);
	};
};

export const validateBulkEmailsUpload = (data) => {
	return () => {
		return validateBulkEmailsUploadAPI(data);
	};
};

export const getUserDetails = (data) => {
	return () => {
		return getUserDetailsApi(data);
	};
};

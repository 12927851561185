// import {
// 	DiagramComponent,
// 	identityMatrix,
// 	Rect,
// 	scaleMatrix,
// 	transformPointByMatrix,
// 	translateMatrix,
// } from '@syncfusion/ej2-react-diagrams';
import * as React from 'react';
import { SampleBase } from '../RelaxedMap/sample-base';

export default class DiagramOverview extends SampleBase {
	ref_draggbleDiv: any;
	ref_parent: any;
	constructor(props: any) {
		super(props);
		// const { diagramId } = this.props;
		this.ref_draggbleDiv = React.createRef();
		this.ref_parent = React.createRef();

		this.state = {
			width: 0,
			height: 0,
			dragging: false,
			rel: null,
			pos: props.initialPos || { x: 0, y: 0 },
		};
	}
	componentDidMount() {
		this.updateOverview();
		document.addEventListener('mousemove', this.onMouseMove.bind(this));
		document.addEventListener('mouseup', this.onMouseUp.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove.bind(this));
		document.removeEventListener('mouseup', this.onMouseUp.bind(this));
	}
	getTransformObj() {
		let dIns = this.getDiagramInstance(this.props.diagramId);
		let parentBounds = this.ref_parent.current.getBoundingClientRect();
		let s: any = dIns.scroller;
		let scx = (s.transform.scale / s.diagramWidth) * (parentBounds.width - 2);
		let scy = (s.transform.scale / s.diagramHeight) * (parentBounds.height - 2);
		let tx = ((s.transform.tx * s.transform.scale) / s.diagramWidth) * parentBounds.width;
		let ty = ((s.transform.ty * s.transform.scale) / s.diagramHeight) * parentBounds.height;

		return { tx: tx, ty: ty, scaleX: scx, scaleY: scy };
	}
	getClonedElemntHtml(id: string) {
		let element: any = document.getElementById(id);
		let clonedElement = element.cloneNode(true);

		let childs: any[] = clonedElement.getElementsByTagName('*');
		if (childs && childs.length > 0) {
			for (let i = 0; i < childs.length; i++) {
				childs[i].style.pointerEvents = 'none';
				if (childs[i].id) childs[i].id += '_cloned';
			}
		}
		clonedElement.style.pointerEvents = 'none';
		clonedElement.id += '_cloned';
		return clonedElement;
	}
	cloneDiagramHtml() {
		let container: any = document.getElementById('diagramHtmlContainer');
		container.innerHTML = '';
		let trans = this.getTransformObj();

		let svgElement = this.getClonedElemntHtml(this.props.diagramId + '_diagramLayer_div');
		let svgTransStyle =
			'translate(' + trans.tx + ',' + trans.ty + '), scale(' + trans.scaleX + ',' + trans.scaleY + ')';
		container.innerHTML += svgElement.outerHTML;
		let g: any = document.getElementById(this.props.diagramId + '_diagramLayer_cloned');
		if (g) g.setAttribute('transform', svgTransStyle);

		let htmlElement = this.getClonedElemntHtml(this.props.diagramId + '_htmlLayer_div');
		let transStyle =
			'translate(' + trans.tx + 'px' + ',' + trans.ty + 'px' + ') scale(' + trans.scaleX + ',' + trans.scaleY + ')';
		htmlElement.style.transform = transStyle;
		container.innerHTML += htmlElement.outerHTML;
	}
	updateOverview(isUpdateContent: boolean = true) {
		let parentBounds = this.ref_parent.current.getBoundingClientRect();
		let dIns = this.getDiagramInstance(this.props.diagramId);
		// let diagramElement: any = document.getElementById(this.props.diagramId);
		// let dBounds: any = diagramElement?.getBoundingClientRect();
		if (dIns) {
			let s: any = dIns.scroller;
			let diagramWidth = s.diagramWidth;
			let diagramHeight = s.diagramHeight;
			let overviewBorder = 2;

			let w = (s.viewPortWidth / diagramWidth) * (parentBounds.width - overviewBorder);
			let h = (s.viewPortHeight / diagramHeight) * (parentBounds.height - overviewBorder);

			let dx = s.transform.tx * s.transform.scale - s.horizontalOffset;
			let dy = s.transform.ty * s.transform.scale - s.verticalOffset;
			let x = (dx / s.diagramWidth) * parentBounds.width;
			let y = (dy / s.diagramHeight) * parentBounds.height;
			x = x > 0 ? x : 0;
			y = y > 0 ? y : 0;

			this.setState({ pos: { x: x, y: y }, width: w, height: h });
		}
		if (isUpdateContent) this.cloneDiagramHtml();
	}
	onMouseDown(e: any) {
		if (e.button !== 0) return;
		let element: any = this.ref_draggbleDiv.current;
		this.setState({
			dragging: true,
			rel: {
				x: e.pageX - element?.offsetLeft,
				y: e.pageY - element?.offsetTop,
			},
		});
		e.stopPropagation();
		e.preventDefault();
	}
	onMouseUp(e: any) {
		this.setState({ dragging: false });
		e.stopPropagation();
		e.preventDefault();
	}
	onMouseMove(e: any) {
		if (!this.state.dragging) return;
		let x = e.pageX - this.state.rel.x;
		let y = e.pageY - this.state.rel.y;
		let bounds = this.ref_draggbleDiv.current.getBoundingClientRect();
		let parentBounds = this.ref_parent.current.getBoundingClientRect();
		x = x < 0 ? 0 : x;
		y = y < 0 ? 0 : y;
		x = x > parentBounds.width - bounds.width ? parentBounds.width - bounds.width : x;
		y = y > parentBounds.height - bounds.height ? parentBounds.height - bounds.height : y;
		let diffX = x - this.state.pos.x;
		let diffY = y - this.state.pos.y;
		this.setState({
			pos: {
				x: x,
				y: y,
			},
		});
		let dIns = this.getDiagramInstance(this.props.diagramId);
		// let diagramElement: any = document.getElementById(this.props.diagramId);
		let s: any = dIns.scroller;
		// let ss: any = dIns.scrollSettings;
		// let currentZoom = s.currentZoom;
		let diagramWidth = s.diagramWidth; //diaPageBounds.width; //
		let diagramHeight = s.diagramHeight; //diaPageBounds.height;
		diffX = (diffX / parentBounds.width) * diagramWidth;
		diffY = (diffY / parentBounds.height) * diagramHeight;
		this.panDiagram(-diffX, -diffY);
		e.stopPropagation();
		e.preventDefault();
	}
	getDiagramInstance(diagramId: string) {
		let diagramElement: any = document.getElementById(diagramId);
		if (diagramElement && diagramElement.ej2_instances && diagramElement.ej2_instances.length > 0) {
			return diagramElement.ej2_instances[0];
		}
		return null;
	}
	panDiagram(x: number, y: number) {
		if (this.props.diagramId) {
			let dIns = this.getDiagramInstance(this.props.diagramId);
			if (dIns) {
				dIns.pan(x, y);
			}
		}
	}
	render() {
		return (
			<div
				ref={this.ref_parent}
				className='overview-parent'
				id='overview-panel'
				style={{ visibility: 'hidden', opacity: 0 }}
			>
				<div
					id='diagramHtmlContainer'
					style={{
						width: '100%',
						height: '100%',
						background: '#fffff',
						pointerEvents: 'none',
					}}
				></div>
				<div
					style={{
						width: '100%',
						height: '100%',
						background: 'transparent',
						position: 'relative',
						display: 'inline-block',
						top: 'calc(-100%)',
					}}
				></div>
				<div
					onMouseDown={this.onMouseDown.bind(this)}
					style={{
						position: 'absolute',
						left: this.state.pos.x + 'px',
						top: this.state.pos.y + 'px',
						width: this.state.width + 'px',
						height: this.state.height + 'px',
					}}
					ref={this.ref_draggbleDiv}
					draggable='true'
					className='overview-dragger'
				></div>
				<div></div>
			</div>
		);
	}
}

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Logo } from '../../../config/svg/GlobalSvg';
import { MenuIcon } from '../../../config/svg/HeaderActionSvg';
import { LeftArrow } from '../../../config/svg/ArrowSvg';
import DrawerList from './DrawerList';
import AdminNavigation from './AdminNavigation';
import MyAccount from './MyAccount';
import LinearLoader from '../../Common/Loader';
import { AppBar, Toolbar, IconButton, Drawer, Box, Typography, Tooltip } from '@material-ui/core';
import {
	clearLocalStorage,
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from '../../../services/StorageService';
import { LOGIN_INITIATOR_URL, ADMIN } from '../../../config/app-url';
import { ClientLogo } from '../../Common/ClientLogo';
import { getUserDetails } from '../../../config/utils';
import '../../../styles/pages/admin/main.scss';

export const Header: React.FC<any> = (props) => {
	const { loaderStatus, listOkrSuccess } = props;

	const history = useHistory();
	const [profileOpen, setProfileOpen] = useState(false);
	const userDetail = getUserDetails();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (props.getPermissionStatus !== 'success') {
			//==== Get employee profile related permission
			props.getEmployeePermission(undefined, history);
		}
		if (props.listRolesSuccess !== 'success') {
			//==== Fetch roles listing
			props.getRoles();
		}
	}, [history.location]);

	if (!userDetail) {
		//==== if clearing storage then
		//==== save redirect Url before clearing storage

		let redirectUrl = getLocalStorageItem('redirectUrl');
		let otherCycleObjective = getLocalStorageItem('otherCycleObjective');
		let empId = getLocalStorageItem('empId');
		clearLocalStorage();
		if (redirectUrl) {
			setLocalStorageItem('redirectUrl', redirectUrl);
			setLocalStorageItem('otherCycleObjective', otherCycleObjective);
		}
		if (empId) {
			setLocalStorageItem('empId', empId);
		}
		window.location.href = LOGIN_INITIATOR_URL;
	}

	const handleToggle = (flag: any) => {
		if (flag === 1) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			setOpen(false);
		}
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const navigateToHomePage = () => {
		removeLocalStorageItem('currentUser');
		props.resetOkr();

		history.push(ADMIN);
		props.updateRoute(ADMIN);
	};

	const checkDrawer = () => {
		if (open) {
			setOpen(false);
		}
	};

	useEffect(() => {
		window.onfocus = function () {
			const url = getLocalStorageItem('currentRoute');
			if (url && url !== props.currentRoute) {
				props.updateRoute(url);
				window.location.href = url;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	return (
		<Fragment>
			{userDetail && (
				<Fragment>
					<AppBar className='app-header' position='fixed' onClick={checkDrawer}>
						<Toolbar>
							<Box className='header-left'>
								<IconButton
									color='inherit'
									aria-label={t('HeaderMainAriaLabel')}
									onClick={() => handleToggle(1)}
									edge='start'
									className='drawer-open'
									id='side-nav-bar'
								>
									{open ? <LeftArrow /> : <MenuIcon />}
								</IconButton>
								<Box className='app-logo'>
									<Typography variant='h1'>
										<Tooltip title={`${t('homeLabel')}`} arrow>
											<IconButton color='inherit' onClick={() => navigateToHomePage()} id='logo'>
												<Logo />
											</IconButton>
										</Tooltip>
									</Typography>
								</Box>
							</Box>
							<nav className='main-nav' aria-label='Main'>
								<AdminNavigation {...props} />
							</nav>
							<Box className='header-right'>
								<ClientLogo className='client-logo' />
								<MyAccount
									{...props}
									profileOpen={profileOpen}
									setProfileOpen={setProfileOpen}
									loginAs={props.loginAs}
								/>
							</Box>
						</Toolbar>
						{loaderStatus && <LinearLoader data={listOkrSuccess === 'success' ? false : true} />}
					</AppBar>
					<Drawer
						className='drawer-nav'
						anchor='left'
						open={open}
						onEscapeKeyDown={handleDrawerClose}
						onBackdropClick={handleDrawerClose}
					>
						<DrawerList {...props} handleToggle={handleToggle} />
					</Drawer>
				</Fragment>
			)}
		</Fragment>
	);
};

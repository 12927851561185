import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Paper,
	Popper,
	Grow,
	ClickAwayListener,
	List,
	ListItem,
	ListItemText,
	Button,
	Tooltip,
	Box,
} from '@material-ui/core';
import { AddIconSvg } from '../../../config/svg/CommonSvg';
import { AlignObjectiveIcon, ImportOkrsIcon, ObjectiveIcon } from '../../../config/svg/MyGoalSvg';
import { SearchUserPopover } from '../../Common/SearchUserPopover';
import { USER_VIEW, HOME } from '../../../config/app-url';
import { removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { getCurrentUserDetails, isCurrentCycle, GAevent } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { ImportOKR } from '../../Goals/ImportOkrs';
import { ImportOkrsDrawer } from '../../Goals/ImportOkrs/ImportOkrsDrawer';

export interface CreateAlignOkrProps {
	showAddGoalForm?: (hideShowFlag: Boolean) => void;
	history?: any;
	updateRoute?: (route: string) => void;
	resetOkr: () => void;
	listOkrResult?: any;
}
export const CreateAlignOkr: React.FC<any> = (props) => {
	const { showAddGoalForm, handleOpenImportDrawer, openImportDrawer, handleImportDrawerClose } = props;
	const { t } = useTranslation();

	const [addContributorOpen, setAddContributorOpen] = useState<boolean>(false);
	const [addContributorAnchorEl, setAddContributorAnchorEl] = useState<HTMLElement | null>(null);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [loader, setLoader] = useState(false);

	const isLocked = props.listOkrResult && props.listOkrResult.isLocked;
	const currentUser = getCurrentUserDetails();
	const anchorRef = useRef<HTMLButtonElement>(null);

	const onHandleAddUserClick = (event: any) => {
		setCustomStyle(false);
		setAddContributorAnchorEl(addContributorAnchorEl ? null : anchorEl || event.currentTarget);
		setAddContributorOpen(true);
	};
	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const onSelectedUser = (selected: any, ref?: any) => {
		if (Boolean(selected) && selected.length) {
			if (selected.isLeaderClicked === true) {
				setLocalStorageItem('currentUser', JSON.stringify(selected[0]));
				props.resetOkr();
				props.history.push(USER_VIEW);
				props.updateRoute(USER_VIEW);
			} else {
				if (selected[0]?.searchType && selected[0]?.searchType === 2) {
					setLocalStorageItem('currentSearchedTeam', JSON.stringify(selected[0]));
					setLocalStorageItem('currentSearchedTeamId', JSON.stringify(selected[0].organisationId));
					setLocalStorageItem('currentSearchedTeamData', JSON.stringify(selected[0]));
					props.resetOkr();
					props.history.push(HOME);
					props.updateRoute(HOME);
				} else {
					setLocalStorageItem('currentUser', JSON.stringify(selected[0]));
					props.resetOkr();
					props.history.push(USER_VIEW);
					props.updateRoute(USER_VIEW);
				}
			}
		}
	};

	const scrollTo = (e: any) => {
		//let element = document.getElementById('dashboard');
		if (props.isFutureCycle === true) {
			setTimeout(function () {
				window.scroll({ top: 90, left: 0, behavior: 'smooth' });
			}, 500);
		} else {
			setTimeout(function () {
				window.scroll({ top: 280, left: 0, behavior: 'smooth' });
			}, 500);
		}
	};

	const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(anchorEl ? false : true);
		removeLocalStorageItem('showCallouts');
	};
	const closePopup = () => {
		GAevent('OKRCreation', 'Clicked Create OKR', 'Create a new Objective');
		setAnchorEl(null);
		setOpen(false);
	};
	return (
		<React.Fragment>
			{!isLocked &&
				!currentUser &&
				(isCurrentCycle() || props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID) && (
					<Tooltip title={`${t('createandAlign')}`} arrow>
						<Button
							className={open || addContributorOpen ? 'add-btn active' : 'add-btn'}
							color='primary'
							ref={anchorRef}
							onClick={handleToggle}
							id='create-okr-bn'
						>
							<AddIconSvg />
						</Button>
					</Tooltip>
				)}

			<Popper className='my-goal-actions' open={open || false} anchorEl={anchorEl} placement={'bottom-end'} transition>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<Box className='connnector-icon'></Box>
							<ClickAwayListener onClickAway={() => closePopup()}>
								<List>
									<ListItem
										className='set-new-goal'
										id='create-new-okr'
										onClick={(e) => {
											closePopup();
											showAddGoalForm(true);
											scrollTo(e);
										}}
									>
										<ObjectiveIcon />
										<ListItemText primary={t('createGoalLabel')} />
									</ListItem>
									<ListItem
										className='align-to-colleague'
										id='align-to-colleague'
										onClick={(e) => {
											onHandleAddUserClick(e);
											closePopup();
										}}
									>
										<AlignObjectiveIcon />
										<ListItemText primary={t('contributeToColleague')} />
									</ListItem>
									<ListItem
										className='import-okr'
										id='import-okr'
										onClick={(e) => {
											handleOpenImportDrawer(e);
											closePopup();
										}}
									>
										<ImportOkrsIcon />
										<ListItemText primary={t('importOkrsLevel')} />
									</ListItem>
								</List>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			<SearchUserPopover
				{...props}
				popperAddClassName={'search-add-contributor-popup'}
				addContributorAnchorEl={addContributorAnchorEl}
				addContributorOpen={addContributorOpen}
				setAddContributorOpen={setAddContributorOpen}
				setAddContributorAnchorEl={setAddContributorAnchorEl}
				isCustomStyle={customStyle}
				onSelectedUser={onSelectedUser}
				popperAnchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				searchPlaceHolderText={t('SearchTeamColleague')}
				handleSearchUserInputChange={handleSearchUserInputChange}
				isTeamSearch={true}
				customId={'align-okr-src-field'}
			/>
			<ImportOkrsDrawer
				{...props}
				open={openImportDrawer}
				loader={loader}
				setOpenImportDrawer={props.setOpenImportDrawer}
				year={props.year}
				cycleId={props.cycleId}
				showApiMsgs={props.showApiMsgs}
				setLoader={props.setLoader}

				handleImportDrawerClose={(event: any, type: string) => handleImportDrawerClose(event, type)}
			/>
		</React.Fragment>
	);
};

import React, { useEffect } from 'react';
import '../../../styles/pages/login/login.scss';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function Login(props) {
	const { t } = useTranslation();

	useEffect(() => {
		window.location.href = window.location.origin + '/custom-login';
	});

	return (
		<section className='sign-in-area'>
			<Typography id='loading-first' variant='h5'>
				{t('loading')}
			</Typography>
		</section>
	);
}

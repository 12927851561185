import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge } from '@material-ui/core';
import { ObjectiveIcon, DirectReportsIcon, TeamIcon } from '../../../config/svg/MyGoalSvg';
import { checkPermission } from '../../../config/rolePermission';
import { feedbackModule } from '../../Admin/AdminConstant';
import { Enums } from '../../../config/enums';

export const AlignmentMapTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`notification-tabpanel-${index}`}
			aria-labelledby={`notification-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

AlignmentMapTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `okr-tab-${index}`,
		'aria-controls': `okr-tabpanel-${index}`,
	};
}

export const AlignmentMapTabs: React.FC<any> = (props) => {
	const {
		t,
		tabValue,
		handleTabChange,
		setTabFilter,
		setTabButtonFilter,
		userDetail,
		addAlignmentFilter,
		addOrganisationFilter,
		addTeamOkrFilter,
		isUnreadFeedback,
		getPermissionResult,
	} = props;

	const getTabText = (text: string, isUnread: Number) => {
		return isUnread && checkPermission(getPermissionResult?.employeePermissions, feedbackModule) ? (
			<Badge variant='dot' className='chat-bubble' color='secondary'>
				{text}
			</Badge>
		) : (
			text
		);
	};

	return (
		<Tabs className='okr-tabs okr-tabs-space' value={tabValue} onChange={handleTabChange} aria-label='OKR Tabs'>
			<Tab
				className={`not-filled-icon`}
				icon={<ObjectiveIcon />}
				label={getTabText(t('myOkrsLabel'), isUnreadFeedback.allUnread)}
				onClick={() => {
					addTeamOkrFilter(null);
					addAlignmentFilter([]);
					setTabButtonFilter('okrView');
					setTabFilter('relaxed');
				}}
				{...a11yProps(0)}
			/>
			<Tab
				icon={<TeamIcon />}
				label={getTabText(t('TeamOkrsLabel'), isUnreadFeedback.teamUnread)}
				onClick={() => {
					addTeamOkrFilter(null);
					addAlignmentFilter([]);
					setTabFilter('relaxed');
				}}
				{...a11yProps(1)}
			/>

			{props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && userDetail.directReports === true && (
				<Tab
					icon={<DirectReportsIcon />}
					label={t('DirectReportsLable')}
					onClick={() => {
						addTeamOkrFilter(null);
						addAlignmentFilter([]);
						addOrganisationFilter([]);
						setTabFilter('relaxed');
						setTabButtonFilter('directReport');
					}}
					{...a11yProps(2)}
				/>
			)}
		</Tabs>
	);
};

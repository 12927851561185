import React, { useState, useRef, useEffect } from 'react';
import { Box, List, ListItem, Modal, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { OKRButton } from '../../Common/OKRButton';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import { DownFilledArrow } from '../../../config/svg/ArrowSvg';
import { DownloadIcon } from '../../../config/svg/CommonSvgIcon';
import { DropDown } from '../Dropdown';
import CkEditor from '../../Common/CkEditor/CkEditor';
import {
	createVersionFeature,
	deleteFeatureDocument,
	featureDocumentUpload,
	updateVersionFeature,
} from '../../../action/okrUpdate';
import { useDispatch } from 'react-redux';
import { DeleteIcon, LinkDeleteIcon } from '../../../config/svg/CommonSvg';

export const AddNewFeaturePopup: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const inputFileRef = useRef<HTMLInputElement>(null);
	const featureTypeList = [
		{
			name: t('newFeature'),
			value: 1,
		},
		{
			name: t('FixLabel'),
			value: 2,
		},
	];
	const {
		open,
		handleClose,
		handleChangeValue,
		selectedVersion,
		tabType,
		getSelectedVersionFeatureList,
		type,
		editableData,
		imageList,
		setImageList,
	} = props;

	const [openDropdown, setOpenDropdown] = useState<any>(false);
	const [uploadedFileName, setUploadedFileName] = useState<any>({});
	const [selectedFileName, setSelectedFileName] = useState<any>({});
	const [formData, setFormData] = useState<any>({
		featureType: featureTypeList[0]?.value,
		featureTypeName: featureTypeList[0]?.name,
	});
	const [isError, setIsError] = useState<any>({});

	useEffect(() => {
		if (type === 'Edit') {
			const { featureUserManual } = editableData || {};
			setFormData({
				...formData,
				title: editableData?.title || '',
				subTitle: editableData?.subTitle || '',
				description: editableData?.description || '',
				featureType: editableData?.featureType ? featureTypeList[editableData?.featureType - 1]?.value : 1,
				featureTypeName: editableData?.featureType
					? featureTypeList[editableData?.featureType - 1]?.name
					: t('newFeature'),
			});
			if (featureUserManual) {
				setUploadedFileName({
					fileName: featureUserManual?.uploadFileName || '',
					filePath: featureUserManual?.filePath || '',
				});
			}
		} else {
			setFormData({
				featureType: featureTypeList[0]?.value,
				featureTypeName: featureTypeList[0]?.name,
			});
			setUploadedFileName({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, editableData]);

	const handleChangeTextValue = (event: any) => {
		const { value, name } = event.target;
		setFormData({ ...formData, [name]: value });
		handleChangeValue(event);
	};
	const handleFeatureChange = (item: any) => {
		setFormData({ ...formData, featureType: item?.value, featureTypeName: item?.name });
		setUploadedFileName({});
		setOpenDropdown(false);
	};

	const apiCallToSaveFeature = async (fileDetail: any) => {
		const fileNameValues = fileDetail?.filePath?.includes('/')
			? fileDetail?.filePath?.split('/')
			: fileDetail?.filePath;
		const storedFileName =
			fileNameValues && fileNameValues.length > 1 ? fileNameValues[fileNameValues.length - 1] : fileDetail?.filePath;
		const requestPayload: any = {
			versionId: selectedVersion?.versionId || 0,
			title: formData?.title || '',
			subTitle: formData?.subTitle || '',
			description: formData?.description || '',
			featureType: formData?.featureType || 1,
			featureListType: tabType === 'Updates' ? 1 : 2,
			documentRequests: fileDetail?.filePath
				? [
						{
							documentModuleId: tabType === 'Updates' ? 1 : 2,
							moduleDetailsId: 0,
							uploadFileName: fileDetail?.fileName || '',
							filePath: fileDetail?.filePath,
							storageFileName: storedFileName || fileDetail?.filePath,
						},
				  ]
				: [],
		};
		if (type === 'Edit') {
			requestPayload.featureId = editableData?.featureId || 0;
			requestPayload.versionId = editableData?.versionId || requestPayload.versionId;
			const response: any = await dispatch(updateVersionFeature(requestPayload));
			if (response && response?.data) {
				const { messageList } = response?.data;
				enqueueSnackbar(messageList?.message || 'Feature updated successfully', {
					variant: 'success',
					autoHideDuration: 5000,
				});
				setUploadedFileName({});
				setSelectedFileName({});
				getSelectedVersionFeatureList(selectedVersion);
			} else {
				const { messageList } = response?.data;
				enqueueSnackbar(messageList?.message || 'Some error Occurred!', {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		} else {
			const response: any = await dispatch(createVersionFeature(requestPayload));
			if (response && response?.data) {
				const { messageList } = response?.data;
				enqueueSnackbar(messageList?.message || 'Feature created successfully', {
					variant: 'success',
					autoHideDuration: 5000,
				});
				setUploadedFileName({});
				setSelectedFileName({});
				getSelectedVersionFeatureList(selectedVersion);
			} else {
				const { messageList } = response?.data;
				enqueueSnackbar(messageList?.message || 'Some error Occurred!', {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
	};
	const checkValidation = () => {
		let errorData = {};
		if (!formData?.title) {
			errorData = { ...errorData, title: true };
		}
		if (!formData?.subTitle) {
			errorData = { ...errorData, subTitle: true };
		}
		if (!formData?.featureType) {
			errorData = { ...errorData, featureType: true };
		}
		if (errorData && Object.keys(errorData).length > 0) {
			setIsError({ ...errorData });
			return false;
		} else {
			setIsError({});
			return true;
		}
	};
	const handleFeatureSaveClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		if (checkValidation()) {
			if (uploadedFileName?.fileName && selectedFileName?.fileName) {
				uploadFeatureFile(uploadedFileName);
			} else {
				apiCallToSaveFeature(uploadedFileName);
			}
			resetForm(true);
		}
	};
	const handleFeatureAddAnotherClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		if (checkValidation()) {
			if (uploadedFileName?.fileName && selectedFileName?.fileName) {
				uploadFeatureFile(uploadedFileName);
			} else {
				apiCallToSaveFeature(uploadedFileName);
			}
			resetForm(false);
		}
	};
	const handleCancelClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		resetForm(true);
	};
	const resetForm = (isModelHide: Boolean) => {
		setFormData({});
		setIsError({});
		isModelHide && handleClose();
	};

	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};

	const uploadFeatureFile = async (file: any) => {
		if (file.fileName) {
			const formDataValue = new FormData();
			formDataValue.append('UploadFiles', file.filePath);
			const queryParams = `?featureListType=${formData?.featureType}`;
			const response: any = await featureDocumentUpload(formDataValue, queryParams);
			if (response && response?.data) {
				const { entity } = response?.data;
				const fileDetail = {
					fileName: file?.fileName || '',
					filePath: entity || '',
				};
				setUploadedFileName(fileDetail);
				// enqueueSnackbar('Document uploaded successfully', {
				// 	variant: 'success',
				// 	autoHideDuration: 5000,
				// });
				apiCallToSaveFeature(fileDetail);
			} else {
				const responseAPI = response?.data?.messageList;
				const keys = responseAPI ? Object.keys(responseAPI) : [];
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('errorUploadingFile');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
	};

	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		e.target.value = '';
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = ['application/pdf']; // formData?.featureType === 1 ? ['application/pdf'] : ['image/gif', 'image/jpeg', 'image/png'];
		if (size > 5120) {
			enqueueSnackbar(t('fileSize5MB'), { variant: 'error', autoHideDuration: 5000 });
		} else if (!format.includes(fileType.toLowerCase())) {
			// formData?.featureType === 1 ? t('fileType4FeatureUpload') : t('fileType4FeatureUpload2')
			enqueueSnackbar(t('fileType4FeatureUpload'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		} else {
			// const fileObj = { filePath: file, fileName: file.name, fileUploaded: true };
			const fileDetail = {
				fileName: file.name || '',
				filePath: file,
			};
			setUploadedFileName(fileDetail);
			setSelectedFileName(fileDetail);
			// uploadFeatureFile(fileObj);
		}
	};
	const handleUserManualDelete = async (event: any) => {
		event?.preventDefault();
		event?.stopPropagation();
		if (type === 'Edit') {
			const { featureUserManual, featureId, featureListType } = editableData || {};
			if (featureUserManual && featureId && featureListType) {
				const queryParams = `?featureId=${featureId}&featureListType=${featureListType}`;
				const response: any = await dispatch(deleteFeatureDocument(queryParams));
				if (response && response.data && response.data.isSuccess) {
					const message = 'File';
					enqueueSnackbar(t('deletedMessage', { message: `${message}` }), {
						variant: 'success',
						autoHideDuration: 5000,
					});
					setUploadedFileName({});
					setSelectedFileName({});
				} else {
					const responseAPI = response?.data?.messageList;
					const keys = responseAPI ? Object.keys(responseAPI) : [];
					const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('errorUploadingFile');
					enqueueSnackbar(messages, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			} else {
				setUploadedFileName({});
				setSelectedFileName({});
			}
		} else {
			setUploadedFileName({});
			setSelectedFileName({});
		}
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className='version-modal'
			disableEscapeKeyDown
			disableEnforceFocus
		>
			<Box className='version-modal-inner'>
				<Box className='modal-content'>
					<form className='drawer-form-fields' noValidate autoComplete='off'>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<TextField
								id='version-name'
								className='field-col2'
								label={<>{t('versionLabel')}</>}
								placeholder={t('versionLabel')}
								disabled
								value={selectedVersion?.version}
								name='versionName'
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('versionLabel'), disableUnderline: true, readOnly: true }}
							/>
						</Box>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<TextField
								fullWidth
								id='title'
								required
								className='field-col2 helperText-bottom'
								label={<>{t('titleLabel')}</>}
								placeholder={t('titleLabel')}
								name='title'
								value={formData?.title || ''}
								error={isError?.title}
								helperText={
									isError?.title && (
										<>
											<ErrorIcon />
											{t('featureTitleRequired')}
										</>
									)
								}
								onChange={handleChangeTextValue}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('titleLabel'), disableUnderline: true }}
							/>

							<Box className='field-col2 field-col2-split'>
								<Box className='select-dropdown helperText-bottom'>
									<DropDown
										buttonIcon={<DownFilledArrow />}
										buttonText={formData?.featureTypeName || t('selectType')}
										required={Boolean(isError?.featureType)}
										setOpenDropdown={setOpenDropdown}
										openDropdown={openDropdown}
									>
										<List>
											{featureTypeList && featureTypeList.length ? (
												featureTypeList.map((item) => (
													<ListItem
														className={item?.name === formData?.featureTypeName ? 'active' : ''}
														id={`item-${item?.value}`}
														key={item?.value}
														onClick={() => handleFeatureChange(item)}
													>
														<Typography variant='h5'>{item?.name}</Typography>
													</ListItem>
												))
											) : (
												<></>
											)}
										</List>
									</DropDown>
								</Box>
								<Box className='user-mannual helperText-bottom'>
									<input
										ref={inputFileRef}
										type='file'
										style={{ display: 'none' }}
										onChange={handleFileChange}
										accept={'.pdf'}
										// accept={formData?.featureType === 1 ? '.pdf' : 'image/*'}
									/>
									{/* {!uploadedFileName?.fileName && ( */}
									<OKRButton
										className='btn-link'
										id={'user-mannual'}
										icon={<DownloadIcon />}
										text={t('attachUserManual')}
										handleClick={uploadFile}
									></OKRButton>
									{/* )} */}
									{uploadedFileName?.fileName ? (
										<Box className='pdf-file-name'>
											<Tooltip arrow title={uploadedFileName?.fileName || ''}>
												<Typography className='file-name'>{uploadedFileName?.fileName || ''}</Typography>
											</Tooltip>
											<OKRButton
												className='del-pdf-icon '
												icon={<LinkDeleteIcon />}
												//title={t('deleteSelectedVersion')}
												id={`del-icon`}
												handleClick={handleUserManualDelete}
											/>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</Box>
						</Box>
						<Box className='drawer-input-field'>
							<TextField
								fullWidth
								id='subTitle'
								required
								className='helperText-bottom'
								label={<>{t('subHeading')}</>}
								placeholder={t('subHeading')}
								name='subTitle'
								value={formData?.subTitle || ''}
								error={isError?.subTitle}
								helperText={
									isError?.subTitle && (
										<>
											<ErrorIcon />
											{t('featureSubTitleRequired')}
										</>
									)
								}
								onChange={handleChangeTextValue}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('subHeading'), disableUnderline: true }}
							/>
						</Box>
						<Box className='drawer-input-field'>
							<CkEditor
								{...props}
								// placeholder={
								// 	<>
								// 		Email Content <sup>*</sup>
								// 	</>
								// }
								uploadImage={true}
								value={formData?.description || ''}
								handleEditorChange={(value: any) => {
									setFormData({ ...formData, description: value });
								}}
								setImageList={setImageList}
								imageList={imageList}
							/>
						</Box>
					</form>
				</Box>
				<Box className='modal-footer'>
					<Box>
						<OKRButton className='btn-link' text={t('cancelBtn')} id='cancel-btn' handleClick={handleCancelClick} />
					</Box>
					<Box>
						<OKRButton
							className='btn-link'
							text={t('addAnotherLabel')}
							id='add-another-btn'
							handleClick={handleFeatureAddAnotherClick}
						/>
						<OKRButton className='btn-primary' text={t('saveBtn')} id='save-btn' handleClick={handleFeatureSaveClick} />
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

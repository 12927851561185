import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide } from '@material-ui/core';
import { AlertUnsavedIcon, AlertDeleteIcon, OneToOneIcon, AlertSavedIcon } from '../../config/svg/GlobalSvg';
import { useTranslation } from 'react-i18next';
import { DrawerTransitionSetting } from '../../config/utils';

export default function AlertDialog(props) {
	const { message, handleCloseModal, modalOpen, module, isCancel } = props;
	const { t } = useTranslation();
	const getButtonText = (type) => {
		if (module === 'createMyGoal') {
			return type === 1 ? t('yesLabel') : t('noLabel');
		} else if (module === 'changePassword') {
			return type === 1 ? t('cancelBtn') : t('proceedBtn');
		} else if (module === 'nudgeTeam') {
			return type === 1 ? t('noLabel') : t('yesLabel');
		} else if (module === 'goalAttribute') {
			return type === 1 ? t('cancelBtn') : t('acceptLabel');
		} else if (module === 'accept') {
			return type === 1 ? t('closeLabel') : t('okLabel');
		} else if (module === 'deleteMyObjective') {
			return type === 1 ? t('delete') : t('ignore');
		} else if (module === 'deleteKR') {
			return type === 1 ? t('delete') : t('ignore');
		} else if (module === 'calloutOnboarding') {
			return type === 1 ? t('noLabel') : t('yesLabel');
		} else if (module === 'relogin') {
			return type === 1 ? '' : t('reloginLabel');
		} else if (module === 'checkinUnsaved') {
			return type === 1 ? t('stay') : t('leave');
		} else {
			return type === 1 ? t('noLabel') : t('yesLabel');
		}
	};

	const Transition = React.forwardRef(function Transition(props, ref) {
		return React.createElement(Slide, Object.assign({ direction: 'down', ref: ref }, props));
	});

	return (
		<Dialog
			className={`default-dialog ${module === 'relogin' ? 'relogin-dialog' : ''}`}
			open={modalOpen}
			keepMounted
			transitionDuration={DrawerTransitionSetting}
			TransitionComponent={Transition}
			onClose={(e) => handleCloseModal(e, module === 'deleteMyObjective' ? 1 : 2)}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<DialogContent>
				{module === 'oneToOne' ? (
					<OneToOneIcon />
				) : module === 'delete' ? (
					<AlertDeleteIcon />
				) : module === 'saveAndAdd' ? (
					<AlertSavedIcon />
				) : (
					<AlertUnsavedIcon />
				)}
				<DialogContentText
					id='alert-dialog-slide-description'
					dangerouslySetInnerHTML={{ __html: message }}
				></DialogContentText>
			</DialogContent>
			<DialogActions>
				{!isCancel ? (
					<Fragment>
						<Button className='btn-link' onClick={(e) => handleCloseModal(e, 2)} color='primary'>
							{getButtonText(1)}
						</Button>
						<Button className='btn-primary' onClick={(e) => handleCloseModal(e, 1)} color='primary'>
							{getButtonText(2)}
						</Button>
					</Fragment>
				) : (
					<Button className='btn-primary' onClick={(e) => handleCloseModal(e, 2)} color='primary'>
						{t('okLabel')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, ButtonGroup } from '@material-ui/core';
import { TeamSelection } from './TeamSelection';
import { AssignContributors } from './AssignContributors';
import { AddContributorDetail } from '../../Goals/KeyResult/AddContributorDetail';
import { OKRButton } from '../OKRButton';
import moment from 'moment';
import { Enums } from '../../../config/enums';
import { AssignmentSelection } from './AssignmentSelection';
import { UserAvatar } from '../UserAvatar';
import { getUserDetails, getFullName } from '../../../config/utils';
import AlertDialog from '../Dialog';
import { OKRDrawer } from '../OKRDrawer';
import { useSnackbar } from 'notistack';

export const BecomeContributor: React.FC<any> = (props) => {
	const {
		becomeAContributor,
		myGoalList,
		//krContributorDetailDashboard,
		//setKRContributorDetailDashboard,
		krAttrDateError,
		handleAddContributor,
		onSaveContributorDetailClick,
		onSaveBecomeContributorClick,
		getCurrencyIcon,
		selectedContributorDetails,
		objectiveKRDetails,
		setAssignmentTypeId,
		t,
		setKRAttrDateError,
		defaultKRDateError,
		okrMasterData,
		durationCycleDetail,
		type,
		openDrawer,
		loader,
		alignmentMap,
		alignedStatusData,
	} = props;
	const [krContributorDetailDashboard, setKRContributorDetailDashboard] = useState<any>(selectedContributorDetails);
	const [parentData, setParentData] = useState<any>({});
	const loggedInUserDetail = getUserDetails();
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		data: null,
		message: '',
	});
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
	useEffect(() => {
		const objectiveData =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? objectiveKRDetails.okrDetails
				: objectiveKRDetails.krDetails;
		const myObj =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? {
						objectiveName: objectiveData?.objectiveName || '',
						goalObjectiveId: objectiveData?.goalObjectiveId,
						goalTypeId: objectiveData.goalTypeId,
				  }
				: {};
		setKRContributorDetailDashboard({ ...krContributorDetailDashboard, ...myObj });
		let contributorsList =
			objectiveKRDetails &&
			objectiveKRDetails.krDetails &&
			objectiveKRDetails.krDetails.contributors &&
			objectiveKRDetails.krDetails.contributors.length
				? objectiveKRDetails.krDetails.contributors
						.slice(0, 1)
						.filter((items: any) => items.goalStatusId === Enums.KR_STATUS_ACCEPTED || items.employeeId === 0)
				: [];
		if (contributorsList.length > 0) {
			setParentData(contributorsList[0]);
		}
		isUserAlreadyAligned();
	}, []);

	const isUserAlreadyAligned = async () => {
		const sourceId =
			alignedStatusData.source && alignedStatusData.source > 0
				? alignedStatusData.source
				: alignedStatusData.goalKeyId || 0;
		const apiData = `sourceType=${alignedStatusData.sourceType}&sourceId=${sourceId}&employeeId=${selectedContributorDetails.employeeId}`;
		const resp = await props.checkAlignStatus(apiData);
		const { entity } = resp.data;
		if (entity && entity?.isAligned === false) {
		} else if (entity?.isAligned && entity?.alignStatus) {
			if (entity?.alignStatus === Enums.ONE) {
				//Pending
				enqueueSnackbar(`${t('pendingUserMessage')} ${getFullName(krContributorDetailDashboard)}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} else if (entity?.alignStatus === Enums.THREE) {
				//Declined
				enqueueSnackbar(`${getFullName(krContributorDetailDashboard)} ${t('declinedUserMessage')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} else {
				enqueueSnackbar(`${getFullName(krContributorDetailDashboard)} ${t('alreadyAlign')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			}
			setSaveEnabled(true);
		} else {
			enqueueSnackbar(`${getFullName(krContributorDetailDashboard)} ${t('alreadyAlign')}`, {
				variant: 'info',
				autoHideDuration: 3000,
			});
			setSaveEnabled(true);
		}
	};
	const validateAddContributorForm = (formData: any) => {
		if (formData.assignmentTypeId === Enums.TWO) {
			if (!formData.objectiveName) {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				return false;
			} else if (formData.objectiveName.trim() === '') {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				return false;
			}
		}
		if (!formData.keyResult) {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		} else if (formData.keyResult.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		}
		if (formData.metricName !== '' && formData.metricName !== 'Boolean' && formData.metricName !== 'NoUnits') {
			formData.startValue = !formData.startValue ? 0 : formData.startValue;
			formData.targetValue = !formData.targetValue ? 0 : formData.targetValue;
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				return false;
			}
		}

		if (becomeAContributor === true && goalTypeId() === Enums.ONE && !formData.GoalTypeId) {
			setKRAttrDateError({
				error: t('selectionTypeError'),
				helperText: t('selectionTypeError'),
				type: 'assignment_error',
			});
			return false;
		}
		if (formData.GoalTypeId && formData.GoalTypeId !== 0 && formData.GoalTypeId === 1 && !formData.TeamId) {
			setKRAttrDateError({
				error: t('errorTeamRequired'),
				helperText: t('errorTeamRequired'),
				type: 'assignment_error',
			});
			return false;
		}
		return true;
	};

	const goalTypeId = () => {
		let okrData =
			objectiveKRDetails && objectiveKRDetails.okrDetails
				? objectiveKRDetails.okrDetails
				: objectiveKRDetails.krDetails;
		return okrData.goalTypeId;
	};

	const updateData = (data: any) => {
		let teamId = 0;
		if (data.selectedTeams && data.selectedTeams.length > 0) {
			teamId = data.selectedTeams[0].id;
		}
		setKRAttrDateError({
			error: '',
			helperText: '',
			type: '',
		});
		setKRContributorDetailDashboard({
			...krContributorDetailDashboard,
			GoalTypeId: data.currentGoalId,
			IsSelf: data.currentGoalId === 2,
			TeamId: teamId,
			Contributors: data.contributors ? data.contributors : [],
		});
	};

	const handleCloseModal = (event: any, action: any) => {
		if (action === 1) {
			onSaveBecomeContributorClick && onSaveBecomeContributorClick(event, krContributorDetailDashboard);
		}
		setModalProps({ open: false, data: '', type: '' });
	};

	const handleDrawerCloseClick = (event: any) => {
		if (validateAddContributorForm(krContributorDetailDashboard)) {
			setKRAttrDateError(defaultKRDateError);
			onSaveBecomeContributorClick && onSaveBecomeContributorClick(event, krContributorDetailDashboard);
		}
	};

	return (
		<OKRDrawer
			drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
			open={openDrawer}
			transitionDuration={{ enter: 500, exit: 1000 }}
			headerTitle={becomeAContributor ? t('becomeAContributor') : t('addAContributor')}
			moreButton={<></>}
			handleDrawerClose={(event: any) => handleAddContributor(event, 'close')}
			handleSaveClick={(event: any) => {
				if (!becomeAContributor) {
					const datesDifferenceInDays = moment(new Date(krContributorDetailDashboard.startDate || new Date())).isAfter(
						new Date(krContributorDetailDashboard.dueDate || new Date()),
						'days'
					);
					if (Boolean(datesDifferenceInDays)) {
						setKRAttrDateError({
							error: t('startDateDueDateError'),
							helperText: t('startDateDueDateError'),
							type: 'Date',
						});
					} else {
						if (validateAddContributorForm(krContributorDetailDashboard)) {
							setKRAttrDateError(defaultKRDateError);
							onSaveContributorDetailClick && onSaveContributorDetailClick(event, krContributorDetailDashboard);
						}
					}
				} else {
					const datesDifferenceInDays = moment(new Date(krContributorDetailDashboard.startDate || new Date())).isAfter(
						new Date(krContributorDetailDashboard.dueDate || new Date()),
						'days'
					);
					if (Boolean(datesDifferenceInDays)) {
						setKRAttrDateError({
							error: t('startDateDueDateError'),
							helperText: t('startDateDueDateError'),
							type: 'Date',
						});
					} else if (validateAddContributorForm(krContributorDetailDashboard)) {
						handleDrawerCloseClick(event);
					}
				}
			}}
			handleCancelClick={(event: any) => handleAddContributor(event, 'close')}
			loader={loader}
			isAnyDrawerOpened={props.isAnyDrawerOpened}
			appDrawerInfo={props.appDrawerInfo}
			isSaveButtonDisabled={saveEnabled}
			{...(!becomeAContributor && { saveButtonText: t('send') })}
		>
			<Box className='become-contributor-assignment'>
				<Box className='drawer-inner-content'>
					<Box className='drawer-content-panel'>
						{becomeAContributor && goalTypeId() === Enums.ONE && (
							<Box className='drawer-field-row'>
								<UserAvatar userDetails={parentData} showParentTag={true} t={t} />
							</Box>
						)}
						<AddContributorDetail
							{...props}
							type={type}
							goalList={myGoalList}
							krContributorDetailDashboard={krContributorDetailDashboard}
							setKRContributorDetailDashboard={setKRContributorDetailDashboard}
							defaultKRDashboardDateError={krAttrDateError}
							onHandleCancelClick={(event: any) => handleAddContributor(event, 'close')}
							onSaveContributorDetailClick={onSaveContributorDetailClick}
							onSaveBecomeContributorClick={onSaveBecomeContributorClick}
							selectedContributorDetails={selectedContributorDetails}
							getCurrencyIcon={getCurrencyIcon}
							objectiveDetail={objectiveKRDetails.okrDetails}
							setAssignmentTypeId={setAssignmentTypeId}
							keyAttribute={
								objectiveKRDetails && objectiveKRDetails.krDetails
									? objectiveKRDetails.krDetails
									: objectiveKRDetails.okrDetails
							}
							keyData={
								objectiveKRDetails && objectiveKRDetails.krDetails
									? objectiveKRDetails.krDetails
									: objectiveKRDetails.okrDetails
							}
							setCallOut={() => {}}
							setAnchorElCallouts={() => {}}
							hideKrConfidence={!becomeAContributor}
						/>
						{becomeAContributor && goalTypeId() === Enums.ONE && (
							<Box className='drawer-field-row'>
								<AssignmentSelection
									{...props}
									keyData={
										krContributorDetailDashboard
									}
									kr={
										objectiveKRDetails && objectiveKRDetails.krDetails
											? objectiveKRDetails.krDetails
											: objectiveKRDetails.okrDetails
									}
									okr={
										objectiveKRDetails && objectiveKRDetails.okrDetails
											? objectiveKRDetails.okrDetails
											: objectiveKRDetails.krDetails
									}
									okrMasterData={okrMasterData}
									durationCycleDetail={durationCycleDetail}
									krAttrDateError={krAttrDateError}
									updateData={updateData}
									currentUser={props.currentUser}
								/>
							</Box>
						)}
					</Box>
				</Box>
				{modalProps.open && (
					<AlertDialog
						module={'accept'}
						message={modalProps.message}
						handleCloseModal={handleCloseModal}
						modalOpen={modalProps.open}
						isCancel={false}
					/>
				)}
			</Box>
		</OKRDrawer>
	);
};

import React, { Fragment, useState } from 'react';
import {
	Badge,
	ClickAwayListener,
	Grow,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@material-ui/core';
import {
	KebabIcon,
	AcceptIcon,
	DeclineIcon,
	DeleteIcon,
	ViewPeopleOkrIcon,
	NudgeTeamIcon,
	AlignmentMapIcon,
	ContributorIcon,
} from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import { Enums } from '../../config/enums';
import Feedback from '../Feedback';
import { getCurrentUserDetails, getTeamFilterQueryString, getUserDetails } from '../../config/utils';
import {
	AskFeedbackIcon,
	GiveFeedbackIcon,
	RequestOneOnOneIcon,
	ViewFeedback,
} from '../../config/svg/PersonalFeedbackSvg';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { ALIGNMENTMAPS, PEOPLEMAPS, USER_VIEW } from '../../config/app-url';
import { DollarIcon, EuroIcon, PoundIcon, RupeeIcon, SettingsIcon, YenIcon } from '../../config/svg/MyGoalSvg';
import { KeyResultAttributes } from '../Goals/KeyResult/KeyResultAttributes';
import AlertDialog from '../Common/Dialog';
import { useSnackbar } from 'notistack';
import PersonalFeedbackDrawer from '../PersonalFeedback/PersonalFeedbackDrawer';
import { BecomeContributor } from '../Common/assignment/BecomeContributor';
import { checkPermission } from '../../config/rolePermission';
import { editOkrs, feedbackModule, OneToOneModule } from '../Admin/AdminConstant';

export const OkrPopperMenu: React.FC<any> = (props) => {
	const {
		t,
		okr,
		kr,
		type,
		handleDeleteOkr,
		handleAcceptDeclineClick,
		KRType,
		isLocked,
		poperClassName,
		poperPlacement,
		peopleMap = false,
		alignmentMap = false,
		poperContainer,
		goalDetail,
		keyRes,
		keyAttribute,
		handleKRClick,
		handleDrawerClose,
		handleKebabClick,
		directReports,
		openSettingsDrawer,
		tabValue,
		getPermissionResult,
		teamOkrFilterData,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const year = getLocalStorageItem('currentYear') || null;
	const selectedYear = getLocalStorageItem('year') || year;
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [feedback, setFeedback] = useState<any>({ type: 0, open: false });
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const keyData = type === 2 || type === 4 ? kr : okr;
	const goalId = type === 1 ? okr?.goalObjectiveId || okr?.objectiveId : keyData?.goalKeyId || keyData?.krId;
	const goalType = type === 2 || type === 4 ? 'kr' : 'okr';

	const userDetail: any = getUserDetails();
	const [currentUser, setCurrentUser] = useState<any>(getCurrentUserDetails());
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [modalOpen, setModalOpen] = useState(false);
	const [scaleValue, setScaleValue] = useState<any>(1);
	const loggedInUserDetail = getUserDetails();
	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenMenu(anchorEl ? false : true);
		if (handleKebabClick) {
			handleKebabClick(kr, type);
		}
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};

	const handleClose = (event: any) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		setAnchorEl(null);
		setOpenMenu(false);
		setDrawer({
			viewPersonalFeedback: false,
			askPersonalFeedback: false,
			givePersonalFeedback: false,
			requestOneOnOne: false,
			employeeId: 0,
			okrData: {},
		});
	};

	const redirectViewOkr = async (employeeId: Number, tabName: string) => {
		props.resetOkr();
		const data = 'empId=' + employeeId;
		const resp = await props.getUserById(data);
		if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
			if (resp.data.entityList) {
				setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
				setCurrentUser(resp.data.entityList[0]);
			}
			setLocalStorageItem(
				'backUrl',
				JSON.stringify({
					url: props.panelUsed || ALIGNMENTMAPS,
					tabToOpen: tabName,
				})
			);
			props.history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
		}
	};

	const nudgePerson = (selectedPersonDetails: any) => {
		const { employeeId } = selectedPersonDetails;
		props
			.updateNudgePerson(`empId=${employeeId}`)
			.then((response: any) => {
				if (response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data;
					if (messageList) {
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						showApiMsgs(messages, 'success');
					}
				} else {
					const { messageList } = response?.data;
					if (messageList) {
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						showApiMsgs(messages, 'error');
					}
				}
			})
			.catch((_error: any) => {
				showApiMsgs(t('internalServerError'), 'error');
			});
	};

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 2000,
		});
	};

	const [drawer, setDrawer] = useState<any>({
		viewPersonalFeedback: false,
		askPersonalFeedback: false,
		givePersonalFeedback: false,
		requestOneOnOne: false,
		employeeId: 0,
		okrData: {},
	});

	const openDrawer = (e: any, type: any, employeeId: number, okrData?: any) => {
		handleClose(e);
		switch (type) {
			case 'askPersonalFeedback':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: true,
					givePersonalFeedback: false,
					requestOneOnOne: false,
					employeeId: employeeId,
					okrData: {},
				});
				break;
			case 'givePersonalFeedback':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: true,
					requestOneOnOne: false,
					employeeId: employeeId,
					okrData: {},
				});
				break;
			case 'viewPersonalFeedback':
				setDrawer({
					viewPersonalFeedback: true,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
					employeeId: employeeId,
					okrData: okrData,
				});
				break;
			case 'requestOneOnOnePF':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: true,
					employeeId: employeeId,
					okrData: {},
				});
				setModalOpen(true);
				break;
			default:
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
					employeeId: employeeId,
					okrData: {},
				});
				handleClose(e);
				break;
		}
	};

	/** request 1:1 api calling */
	const handleCloseModalRequestOneOnOne = (e: any, type: any) => {
		e.preventDefault();
		e.stopPropagation();
		const { userDetail } = props;
		if (type === 1) {
			const data = {
				requestType: 4,
				requestedTo: [drawer.employeeId],
				requestedFrom: userDetail.employeeId,
				onetoOneRemark: t('thankyouFeedback'),
			};
			setModalOpen(false);
			props
				.requestOneToOnePersonalFeedback(data)
				.then((response: any) => {
					if (Boolean(response) && response.data && response.data.status === 200) {
						enqueueSnackbar(t('requestRaisedSuccessfully'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					} else {
						const responseAPI = Boolean(response) && response.data && response.data.messageList;
						const keys: any = Boolean(responseAPI) && Object.keys(responseAPI);
						const messages = Boolean(keys) && keys.length && keys.map((item: any) => responseAPI[item]);
						enqueueSnackbar(messages, {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				})
				.catch((error: any) => {
					enqueueSnackbar(t('errorRaisingRequest'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		}
		setModalOpen(false);
	};

	// ======= Add contributor  =============//
	const contributorObject = {
		employeeId: 0,
		designation: '',
		firstName: '',
		lastName: '',
		fullName: '',
		imagePath: '',
		krStatusId: 1,
		krStatusName: 'Pending',
		assignmentTypeId: 0,
		keyResult: '',
		score: 0,
		startDate: '',
		dueDate: '',
		startValue: 0,
		targetValue: 0,
		krAssignerMessage: '',
		metricName: '',
		currencyName: '',
		objectiveName: '',
	};

	// const [snackbarProps, setSnackbarProps] = useState<any>({});
	const [loader, setLoader] = useState<boolean>(false);
	const [openAddContributorDrawer, setOpenAddContributorDrawer] = useState<boolean>(false);
	const [selectedContributorDetails, setSelectedContributorDetails] = useState<any>(contributorObject);
	const [krContributorDetailDashboard, setKRContributorDetailDashboard] = useState<any>(selectedContributorDetails);
	const [objectiveKRDetails, setObjectiveKRDetails] = useState<any>({ krDetails: null, okrDetails: null });
	const [alignedStatusData, setAlignedStatusData] = useState<any>({
		source: 0,
		goalKeyId: 0,
		sourceType: 0,
	});

	const handleBecomeAContributor = (kr: any, okr: any) => {
		if (okr.objectiveId > 0) {
			Promise.allSettled([props.getOkrDetail(okr.objectiveId), props.getKrDetail(kr.krId)]).then((response: any) => {
				if (response) {
					let getOKRDetail =
						Boolean(response) && response.length && response[0] && response[0].value ? response[0].value : '';
					let getKRDetail =
						Boolean(response) && response.length && response[1] && response[1].value ? response[1].value : '';

					let isAlreadyAlignedContributor = okr.okrViewKeyResults.filter((kr: any) => {
						let hasContributor = kr?.okrViewKeyContributors.filter(
							(items: any) =>
								items.employeeId === loggedInUserDetail.employeeId && items.krStatusId === Enums.KR_STATUS_ACCEPTED
						);
						return hasContributor.length > 0;
					});
					if (getOKRDetail.status === 200 && getKRDetail.status === 200) {
						if (getKRDetail.data.entity.krStatusId === Enums.KR_STATUS_PENDING) {
						} else {
							_handleBecomeAContributor(getKRDetail.data.entity, getOKRDetail.data.entity, isAlreadyAlignedContributor);
						}
					}
				}
			});
		}
	};
	const _handleBecomeAContributor = async (krDetails: any, okrDetails: any, isAlreadyAlignedContributor: any) => {
		setObjectiveKRDetails({
			krDetails: krDetails || null,
			okrDetails: { ...okrDetails, okrContributors: isAlreadyAlignedContributor } || null,
		});

		const { objectiveName } = okrDetails;
		const {
			assignmentTypeId,
			startDate,
			dueDate,
			keyDescription,
			score,
			startValue,
			targetValue,
			currencyId,
			metricId,
			goalKeyId,
			source,
		} = Boolean(krDetails) ? krDetails : okrDetails;
		const selectedUser = getUserDetails();
		let sourceType = Enums.TWO;
		let goalId = okrDetails?.goalObjectiveId ? okrDetails?.goalObjectiveId : 0;
		setAlignedStatusData({
			source: source,
			goalKeyId: goalKeyId,
			sourceType: sourceType,
		});
		setSelectedContributorDetails({
			...selectedContributorDetails,
			...selectedUser,
			goalObjectiveId: goalId,
			goalStatusId: okrDetails?.goalStatusId || Enums.TWO,
			goalTypeId: okrDetails?.goalTypeId,
			krStatusId: Enums.TWO,
			krStatusName: 'Pending',
			assignmentTypeId: assignmentTypeId || Enums.TWO,
			keyResult: keyDescription || '',
			keyDescription: keyDescription || '',
			score: score || Enums.ZERO,
			startDate: startDate || '',
			dueDate: dueDate || '',
			startValue: startValue || Enums.ZERO,
			targetValue: targetValue || Enums.ZERO,
			message: '',
			metricName: '',
			currencyName: '',
			objectiveName: objectiveName || '',
			currencyId: currencyId || Enums.ZERO,
			metricId: metricId || Enums.ZERO,
			goalKeyId: goalKeyId || Enums.ZERO,
			isAddedNew: true,
		});
		setOpenAddContributorDrawer(true);
	};

	const handleAddContributor = (event: any, type: String) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'close') {
			setKRAttrDateError(defaultKRDateError);
			setOpenAddContributorDrawer(false);
		}
	};

	const getCurrencyIcon = (currencyName: String) => {
		switch (currencyName) {
			case 'Dollar':
				return <DollarIcon />;
			case 'Euro':
				return <EuroIcon />;
			case 'Rupee':
				return <RupeeIcon />;
			case 'Yen':
				return <YenIcon />;
			case 'Pound':
				return <PoundIcon />;
			default:
				break;
		}
	};

	const onSaveBecomeContributorClick = async (event: any, contributorDetail: any) => {
		event.preventDefault();
		event.stopPropagation();
		setLoader(true);
		//setSnackbarProps({});
		if (contributorDetail) {
			const krDetails = {
				goalKeyId: contributorDetail.goalKeyId,
				goalObjectiveId: contributorDetail.goalObjectiveId || Enums.ZERO,
				employeeId: contributorDetail.employeeId,
				startDate: contributorDetail.startDate,
				goalStatusId: contributorDetail.goalStatusId,
				goalTypeId: Enums.TWO,
				score: contributorDetail.score,
				keyDescription: contributorDetail.keyResult,
				dueDate: contributorDetail.dueDate,
				krStatusId: contributorDetail.krStatusId,
				currentValue: 0,
				targetValue: contributorDetail.targetValue,
				assignmentTypeId: contributorDetail.assignmentTypeId,
				krAssignerMessage: contributorDetail.krAssignerMessage,
				startValue: contributorDetail.startValue,
				metricId: contributorDetail.metricId,
				currencyId: contributorDetail.currencyId,
				ObjectiveCycleId: cycleId || currentCycleId,
				objectiveName: contributorDetail.objectiveName,
				TeamId: contributorDetail?.TeamId ? contributorDetail?.TeamId : 0,
				IsSelf: true,
				Contributors: contributorDetail?.Contributors ? contributorDetail?.Contributors : [],
				GoalTypeId: contributorDetail?.GoalTypeId
					? contributorDetail?.GoalTypeId
					: contributorDetail.goalTypeId
					? contributorDetail.goalTypeId
					: 2,
				confidenceId: contributorDetail.confidenceId,
			};
			if (contributorDetail?.GoalTypeId === 1) {
				krDetails.IsSelf = false;
			}
			krDetails.GoalTypeId = contributorDetail.goalTypeId ? contributorDetail.goalTypeId : 2;
			const response = await props.becomeContributor(krDetails);
			if (response && response.data && response.data.status === Enums.STATUS_SUCCESS) {
				props.fetchOkr();
				if (props.tabValue === 0) {
					props.getAlignmentData();
				} else if (props.tabValue === 1) {
					const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
					const data = `empId=${userDetail && userDetail.employeeId}&cycle=${
						cycleId || currentCycleId
					}&year=${selectedYear}${teamFilter}`;
					props.fetchTeamsAlignmentMap(data);
				}
				setLoader(false);
				setOpenAddContributorDrawer(false);
				setObjectiveKRDetails({ krDetails: null, okrDetails: null });
			}
		}
	};

	const onSaveContributorDetailClick = async (event: any, contributorDetail: any) => {
		event.preventDefault();
		event.stopPropagation();
		setLoader(true);
		// setSnackbarProps({});
		if (contributorDetail) {
			let krDetails =
				objectiveKRDetails && objectiveKRDetails.krDetails
					? objectiveKRDetails.krDetails
					: objectiveKRDetails.okrDetails;
			krDetails = { ...krDetails, contributors: [contributorDetail] };
			const response = await props.updateKrAttributes(krDetails);
			if (response && response.data && response.data.status === Enums.STATUS_SUCCESS) {
				// setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
				setLoader(false);
				setOpenAddContributorDrawer(false);
				setKRAttrDateError(defaultKRDateError);
				setObjectiveKRDetails({ krDetails: null, okrDetails: null });
			}
		}
	};
	const scaleStyle = {
		transform: `scale(${scaleValue})`,
	};
	return (
		<Fragment key={props.key}>
			{alignmentMap && okr.isMyOkr && okr.employeeId && userDetail.employeeId === okr.employeeId ? (
				checkPermission(getPermissionResult?.employeePermissions, feedbackModule) ? (
					<Badge
						variant='dot'
						className='chat-bubble'
						color='secondary'
						invisible={(goalType === 'okr' && !okr?.isUnreadFeedback) || (goalType === 'kr' && !kr?.isUnreadFeedback)}
					>
						<OKRButton
							title={t('moreOptions')}
							className='kebab-icon'
							icon={<KebabIcon />}
							handleClick={handleToggle}
							id={`kebab-btn-${goalId}`}
						/>
					</Badge>
				) : (
					<OKRButton
						title={t('moreOptions')}
						className='kebab-icon'
						icon={<KebabIcon />}
						handleClick={handleToggle}
						id={`kebab-btn-${goalId}`}
					/>
				)
			) : currentUser && userDetail.employeeId !== currentUser.employeeId ? (
				checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
					<OKRButton
						title={t('moreOptions')}
						className='kebab-icon'
						icon={<KebabIcon />}
						handleClick={handleToggle}
						id={`kebab-btn-${goalId}`}
					/>
				) : (
					<></>
				)
			) : props.readOnlyView === true ? (
				<></>
			) : (
				<OKRButton
					title={t('moreOptions')}
					className='kebab-icon'
					icon={<KebabIcon />}
					handleClick={handleToggle}
					id={`kebab-btn-${goalId || okr?.employeeId}`}
				/>
			)}
			<Popper
				className={poperClassName || 'popper-menu-list okr-list-popper-menu'}
				open={openMenu}
				anchorEl={anchorEl}
				placement={poperPlacement || 'bottom-end'}
				transition
				container={poperContainer === true ? anchorEl : document.body}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper style={scaleStyle}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='menu-list-grow'>
									{peopleMap && [
										<MenuItem
											id={'view-people-okr'}
											onClick={() => {
												setAnchorEl(null);
												setOpenMenu(false);
												redirectViewOkr(okr.employeeId, 'peopleView');
											}}
										>
											<ListItemIcon>
												<ViewPeopleOkrIcon />
											</ListItemIcon>
											<ListItemText primary={t('viewOkr')} />
										</MenuItem>,
										<MenuItem
											id={'people-alignmentmap-link'}
											onClick={(event: any) => {
												event.preventDefault();
												event.stopPropagation();
												setAnchorEl(null);
												setOpenMenu(false);
												setLocalStorageItem(
													'backUrl',
													JSON.stringify({
														url: ALIGNMENTMAPS,
														tabToOpen: 'peopleView',
													})
												);
												setLocalStorageItem('currentUser', JSON.stringify(okr));

												props.history.push(PEOPLEMAPS);
												props.updateRoute(PEOPLEMAPS);
											}}
										>
											<ListItemIcon>
												<AlignmentMapIcon />
											</ListItemIcon>
											<ListItemText primary={t('alignmentMap')} />
										</MenuItem>,
										checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
											<MenuItem
												id={'request-one-toonepf'}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													openDrawer(e, 'requestOneOnOnePF', okr.employeeId);
												}}
											>
												<ListItemIcon>
													<RequestOneOnOneIcon />
												</ListItemIcon>
												<ListItemText primary={t('requestOnetoOne')} />
											</MenuItem>
										) : (
											<></>
										),
										okr.actionLevel > 0 && props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && (
											<MenuItem
												id={'nudge-person'}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setAnchorEl(null);
													setOpenMenu(false);
													nudgePerson(okr);
												}}
											>
												<ListItemIcon>
													<NudgeTeamIcon />
												</ListItemIcon>
												<ListItemText primary={t('nudgePerson')} />
											</MenuItem>
										),
									]}

									{directReports && [
										okr.objectivesCount > 0 && okr.keyResultCount > 0 && (
											<MenuItem
												key={`viewOkrDirectReport`}
												id={`view-okr-direct-report`}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setAnchorEl(null);
													setOpenMenu(false);
													redirectViewOkr(okr.employeeId, 'directReport');
												}}
											>
												<ListItemIcon>
													<ViewPeopleOkrIcon />
												</ListItemIcon>
												<ListItemText primary={t('viewOkr')} />
											</MenuItem>
										),
										!props.peopleAlignmentMap && (
											<MenuItem
												id={`ppl-alignment-map`}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setAnchorEl(null);
													setOpenMenu(false);
													setLocalStorageItem(
														'backUrl',
														JSON.stringify({
															url: props.panelUsed,
															tabToOpen: 'directReport',
														})
													);
													setLocalStorageItem('currentUser', JSON.stringify(okr));

													props.history.push(PEOPLEMAPS);
													props.updateRoute(PEOPLEMAPS);
												}}
											>
												<ListItemIcon>
													<AlignmentMapIcon />
												</ListItemIcon>
												<ListItemText primary={t('alignmentMap')} />
											</MenuItem>
										),
										props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && (
											<MenuItem
												key={`nudgeDirectReport`}
												id={`nudge-direct-report`}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setAnchorEl(null);
													setOpenMenu(false);
													nudgePerson(okr);
												}}
											>
												<ListItemIcon>
													<NudgeTeamIcon />
												</ListItemIcon>
												<ListItemText primary={t('nudgePerson')} />
											</MenuItem>
										),
										checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
											[
												<MenuItem
													key={`askDirectReport`}
													id={`ask-direct-report`}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														openDrawer(e, 'askPersonalFeedback', okr.employeeId);
													}}
												>
													<ListItemIcon>
														<AskFeedbackIcon />
													</ListItemIcon>
													<ListItemText primary={t('askFeedbackPF')} />
												</MenuItem>,
												<MenuItem
													key={`giveDirectReport`}
													id={`give-direct-report`}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														openDrawer(e, 'givePersonalFeedback', okr.employeeId);
													}}
												>
													<ListItemIcon>
														<GiveFeedbackIcon />
													</ListItemIcon>
													<ListItemText primary={t('giveFeedbackPF')} />
												</MenuItem>,
												<MenuItem
													key={`giveDirectReport`}
													id={`view-direct-report`}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														openDrawer(e, 'viewPersonalFeedback', okr.employeeId, okr);
													}}
												>
													<ListItemIcon>
														<ViewFeedback />
													</ListItemIcon>
													<ListItemText primary={t('viewFeedbackPF')} />
												</MenuItem>,
											]
										) : (
											<></>
										),
										checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
											<MenuItem
												key={`requestDirectReport`}
												id={`request-direct-report`}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													openDrawer(e, 'requestOneOnOnePF', okr.employeeId);
												}}
											>
												<ListItemIcon>
													<RequestOneOnOneIcon />
												</ListItemIcon>
												<ListItemText primary={t('requestOnetoOne')} />
											</MenuItem>
										) : (
											<></>
										),
									]}

									{alignmentMap &&
										okr.employeeId >= 0 &&
										userDetail.employeeId !== okr.employeeId && [
											// checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
											// 	<MenuItem
											// 		key='menuItemGiveFeedback'
											// 		onClick={() => {
											// 			setAnchorEl(null);
											// 			setOpenMenu(false);
											// 			setFeedback({ open: true, reqType: 5, okr, kr, goalType, goalId });
											// 		}}
											// 	>
											// 		<ListItemIcon>
											// 			<GiveFeedbackIcon />
											// 		</ListItemIcon>
											// 		<ListItemText primary={t('giveFeedback')} />
											// 	</MenuItem>
											// ) : (
											// 	<></>
											// ),
											getPermissionResult &&
											getPermissionResult.employeePermissions &&
											checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
												<MenuItem
													key={'req-one-on-one-okr'}
													id={'req-one-on-one-okr'}
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														setFeedback({
															open: true,
															reqType: 4,
															okr,
															kr,
															goalType,
															goalId,
															sourceDetails: { employeeId: kr ? kr.employeeId : okr.employeeId },
														});
													}}
												>
													<ListItemIcon>
														<RequestOneOnOneIcon />
													</ListItemIcon>
													<ListItemText primary={t('requestOnetoOne')} />
												</MenuItem>
											) : (
												<></>
											),
											(okr.isVirtualLink || okr.isParentVirtualLink) && !okr.isMyOkr && (
												<MenuItem
													id={'view-okr-link'}
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														redirectViewOkr(okr.employeeId, 'okrView');
													}}
												>
													<ListItemIcon>
														<ViewPeopleOkrIcon />
													</ListItemIcon>
													<ListItemText primary={t('viewOkr')} />
												</MenuItem>
											),
											goalType === 'kr' &&
												!kr.isVirtualLink &&
												!kr.isParentVirtualLink &&
												// (kr.teamName === '' || kr.isCollaborator) &&  // discussed with Pragya
												!kr.isContributor && (
													<MenuItem
														key={'become-a-contributor'}
														id={'become-a-contributor'}
														onClick={(e) => {
															setAnchorEl(null);
															setOpenMenu(false);
															handleBecomeAContributor(kr, okr);
														}}
													>
														<ListItemIcon>
															<ContributorIcon />
														</ListItemIcon>
														<ListItemText primary={t('becomeAContributor')} />
													</MenuItem>
												),
										]}

									{alignmentMap &&
										okr.employeeId >= 0 &&
										userDetail.employeeId === okr.employeeId && [
											!isLocked &&
												goalType === 'okr' &&
												!currentUser &&
												okr.objectiveType === 1 &&
												((okr.teamId &&
													tabValue === 1 &&
													okr.owner === userDetail.employeeId &&
													okr.owner === okr.employeeId) ||
													(okr.teamId && tabValue !== 1) ||
													(!okr.teamId && userDetail.employeeId === okr.employeeId)) &&
												keyData?.krStatusId !== Enums.KR_STATUS_PENDING && [
													checkPermission(props.getPermissionResult?.employeePermissions, editOkrs) ? (
														<MenuItem
															key={'key-result-attribute'}
															id={'key-result-attribute'}
															onClick={() => {
																setAnchorEl(null);
																setOpenMenu(false);
																openSettingsDrawer(okr);
															}}
														>
															<ListItemIcon>
																<SettingsIcon />
															</ListItemIcon>
															<ListItemText primary={t('goalAttributeText')} />
														</MenuItem>
													) : (
														<></>
													),
												],
											((!currentUser && okr?.krStatusId === Enums.KR_STATUS_PENDING) ||
												keyData?.krStatusId === Enums.KR_STATUS_PENDING) && [
												<MenuItem
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														handleAcceptDeclineClick(true, okr, kr, 'accept', KRType);
													}}
													key={'accept-okr'}
													id={`accept-okr-${okr?.goalKeyId || keyData?.goalKeyId}`}
												>
													<ListItemIcon>
														<AcceptIcon />
													</ListItemIcon>
													<ListItemText primary={t('acceptLabel')} />
												</MenuItem>,
												<MenuItem
													key={'decline-okr'}
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														handleAcceptDeclineClick(true, okr, kr, 'decline', KRType);
													}}
													id={`decline-okr-${okr?.goalKeyId || keyData?.goalKeyId}`}
												>
													<ListItemIcon>
														<DeclineIcon />
													</ListItemIcon>
													<ListItemText primary={t('decline')} />
												</MenuItem>,
											],

											goalType === 'kr' &&
												!isLocked &&
												!kr?.isLastStatusDraft &&
												!currentUser &&
												((kr.teamId &&
													tabValue === 1 &&
													kr.owner === userDetail.employeeId &&
													kr.owner === kr.employeeId) ||
													(kr.teamId && tabValue !== 1) ||
													(!kr.teamId && userDetail.employeeId === kr.employeeId)) &&
												keyData?.krStatusId !== Enums.KR_STATUS_PENDING && [
													checkPermission(props.getPermissionResult?.employeePermissions, editOkrs) ? (
														<MenuItem
															key={'req-feedback-okr2'}
															id={'req-feedback-okr2'}
															onClick={() => {
																setAnchorEl(null);
																if (handleKRClick) {
																	handleKRClick(kr, okr);
																}
																setOpenMenu(false);
															}}
														>
															<ListItemIcon>
																<SettingsIcon />
															</ListItemIcon>
															<ListItemText primary={t('KrAttributeText')} />
														</MenuItem>
													) : (
														<></>
													),
												],

											// ((goalType === 'okr' && !okr?.isLastStatusDraft) ||
											// 	(goalType === 'kr' && !kr?.isLastStatusDraft)) &&
											// keyData?.krStatusId !== Enums.KR_STATUS_PENDING &&
											// checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
											// 	[
											// 		<MenuItem
											// 			key={'req-feedback-okr'}
											// 			onClick={() => {
											// 				setAnchorEl(null);
											// 				setOpenMenu(false);
											// 				setFeedback({ open: true, reqType: 1, okr, kr, goalType, goalId });
											// 			}}
											// 		>
											// 			<ListItemIcon>
											// 				<AskFeedbackIcon />
											// 			</ListItemIcon>
											// 			<ListItemText primary={t('requestFeedback')} />
											// 		</MenuItem>,
											// 	]
											// ) : (
											// 	<></>
											// ),
											((goalType === 'okr' && okr?.isAnyFeedback) || (goalType === 'kr' && kr?.isAnyFeedback)) && [
												checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
													<MenuItem
														key={'read-feedback-okr'}
														id={'read-feedback-okr'}
														onClick={() => {
															setAnchorEl(null);
															setOpenMenu(false);
															setFeedback({ open: true, reqType: 2, okr, kr, goalType, goalId });
														}}
													>
														<ListItemIcon>
															<ViewFeedback />
														</ListItemIcon>
														<Badge
															variant='dot'
															className='chat-bubble'
															color='secondary'
															overlap='rectangle'
															invisible={
																(goalType === 'okr' && !okr?.isUnreadFeedback) ||
																(goalType === 'kr' && !kr?.isUnreadFeedback)
															}
														>
															<ListItemText primary={t('menuItemReadFeedback')} />
														</Badge>
													</MenuItem>
												) : (
													<></>
												),
											],

											checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
												<MenuItem
													key={'req-one-on-one-okr'}
													id={'req-one-on-one-okr'}
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														setFeedback({ open: true, reqType: 3, okr, kr, goalType, goalId });
													}}
												>
													<ListItemIcon>
														<RequestOneOnOneIcon />
													</ListItemIcon>
													<ListItemText primary={t('requestOnetoOne')} />
												</MenuItem>
											) : (
												<></>
											),
											!isLocked &&
												keyData?.krStatusId !== Enums.KR_STATUS_PENDING && [
													<MenuItem
														key={'delete-all'}
														id={'delete-all'}
														onClick={(e: any) => {
															setAnchorEl(null);
															setOpenMenu(false);
															handleDeleteOkr(e, okr, kr, type);
														}}
													>
														<ListItemIcon>
															<DeleteIcon />
														</ListItemIcon>
														<ListItemText primary={t('delete')} />
													</MenuItem>,
												],
										]}

									{currentUser &&
										!directReports &&
										!peopleMap &&
										!alignmentMap &&
										userDetail.employeeId !== currentUser.employeeId && [
											// checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
											// 	<MenuItem
											// 		onClick={() => {
											// 			setAnchorEl(null);
											// 			setOpenMenu(false);
											// 			setFeedback({ open: true, reqType: 5, okr, kr, goalType, goalId });
											// 		}}
											// 	>
											// 		<ListItemIcon>
											// 			<GiveFeedbackIcon />
											// 		</ListItemIcon>
											// 		<ListItemText primary={t('giveFeedback')} />
											// 	</MenuItem>
											// ) : (
											// 	<></>
											// ),
											checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
												<MenuItem
													id={'req-one-to-one'}
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														setFeedback({ open: true, reqType: 4, okr, kr, goalType, goalId });
													}}
												>
													<ListItemIcon>
														<RequestOneOnOneIcon />
													</ListItemIcon>
													<ListItemText primary={t('requestOnetoOne')} />
												</MenuItem>
											) : (
												<></>
											),
										]}

									{!isLocked &&
									!currentUser &&
									!alignmentMap &&
									!peopleMap &&
									(okr?.krStatusId === Enums.KR_STATUS_PENDING || keyData?.krStatusId === Enums.KR_STATUS_PENDING)
										? [
												<MenuItem
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														handleAcceptDeclineClick(true, okr, kr, 'accept', KRType);
													}}
													key={'accept-okr'}
													id={'accept-okr'}
												>
													<ListItemIcon>
														<AcceptIcon />
													</ListItemIcon>
													<ListItemText primary={t('acceptLabel')} />
												</MenuItem>,
												<MenuItem
													key={'decline-okr'}
													id={'decline-okr'}
													onClick={() => {
														setAnchorEl(null);
														setOpenMenu(false);
														handleAcceptDeclineClick(true, okr, kr, 'decline', KRType);
													}}
												>
													<ListItemIcon>
														<DeclineIcon />
													</ListItemIcon>
													<ListItemText primary={t('decline')} />
												</MenuItem>,
												checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
													<MenuItem
														key={'req-one-on-one-okr'}
														id={'req-one-to-one'}
														onClick={() => {
															setAnchorEl(null);
															setOpenMenu(false);
															setFeedback({ open: true, reqType: 3, okr, kr, goalType, goalId });
														}}
													>
														<ListItemIcon>
															<RequestOneOnOneIcon />
														</ListItemIcon>
														<ListItemText primary={t('requestOnetoOne')} />
													</MenuItem>
												) : (
													<></>
												),
										  ]
										: !directReports &&
										  !currentUser &&
										  !peopleMap &&
										  !alignmentMap &&
										  okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT
										? [
												// ((goalType === 'okr' && !okr?.isLastStatusDraft) ||
												// 	(goalType === 'kr' && !kr?.isLastStatusDraft)) &&
												// ((goalType === 'kr' && !kr?.isCollaborator) || (goalType === 'okr' && !okr?.isCollaborator)) &&
												// checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
												// 	<MenuItem
												// 		key={'req-feedback-okr'}
												// 		onClick={() => {
												// 			setAnchorEl(null);
												// 			setOpenMenu(false);
												// 			setFeedback({ open: true, reqType: 1, okr, kr, goalType, goalId });
												// 		}}
												// 	>
												// 		<ListItemIcon>
												// 			<AskFeedbackIcon />
												// 		</ListItemIcon>
												// 		<ListItemText primary={t('requestFeedback')} />
												// 	</MenuItem>
												// ) : (
												// 	<></>
												// ),
												((goalType === 'okr' && okr?.isAnyFeedback) || (goalType === 'kr' && kr.isAnyFeedback)) &&
												checkPermission(getPermissionResult.employeePermissions, feedbackModule) ? (
													<MenuItem
														key={'read-feedback-okr'}
														id={'read-feedback-okr'}
														onClick={() => {
															setAnchorEl(null);
															setOpenMenu(false);
															setFeedback({ open: true, reqType: 2, okr, kr, goalType, goalId });
														}}
													>
														<ListItemIcon>
															<ViewFeedback />
														</ListItemIcon>

														<ListItemText primary={t('menuItemReadFeedback')} />
													</MenuItem>
												) : (
													<></>
												),
												((goalType === 'okr' && !okr?.isLastStatusDraft) ||
													(goalType === 'kr' && !kr?.isLastStatusDraft)) &&
												getPermissionResult &&
												getPermissionResult.employeePermissions &&
												checkPermission(getPermissionResult.employeePermissions, OneToOneModule) ? (
													<MenuItem
														key={'req-one-on-one-kr-okr'}
														id={'req-one-on-one-kr-okr'}
														onClick={() => {
															setAnchorEl(null);
															setOpenMenu(false);
															setFeedback({ open: true, reqType: 3, okr, kr, goalType, goalId });
														}}
													>
														<ListItemIcon>
															<RequestOneOnOneIcon />
														</ListItemIcon>
														<ListItemText primary={t('requestOnetoOne')} />
													</MenuItem>
												) : (
													<></>
												),
												!isLocked && okr.teamId < 1 && (
													<MenuItem
														key={'delete-okr'}
														id={'delete-okr'}
														onClick={(e: any) => {
															setAnchorEl(null);
															setOpenMenu(false);
															handleDeleteOkr(e, okr, kr, type);
														}}
													>
														<ListItemIcon>
															<DeleteIcon />
														</ListItemIcon>
														<ListItemText primary={t('delete')} />
													</MenuItem>
												),
												!isLocked &&
													okr.teamId > 0 &&
													(props.dashboardCurrentTab === 0 || userDetail.employeeId === okr.owner) && (
														<MenuItem
															key={'delete-okr'}
															id={'delete-okr'}
															onClick={(e: any) => {
																setAnchorEl(null);
																setOpenMenu(false);
																handleDeleteOkr(e, okr, kr, type);
															}}
														>
															<ListItemIcon>
																<DeleteIcon />
															</ListItemIcon>
															<ListItemText primary={t('delete')} />
														</MenuItem>
													),
										  ]
										: !isLocked &&
										  !peopleMap &&
										  !directReports &&
										  !alignmentMap &&
										  !currentUser && (
												<MenuItem
													key={'delete-all'}
													id={'delete-all'}
													onClick={(e: any) => {
														setAnchorEl(null);
														setOpenMenu(false);
														handleDeleteOkr(e, okr, kr, type);
													}}
												>
													<ListItemIcon>
														<DeleteIcon />
													</ListItemIcon>
													<ListItemText primary={t('delete')} />
												</MenuItem>
										  )}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			{feedback.open && (
				<Feedback
					feedbackOld={true}
					openFeedbackType={feedback.reqType}
					objective={feedback.okr}
					objId={feedback.goalId}
					setFeedback={setFeedback}
					userDetail={userDetail}
					currentUser={currentUser}
					sourceDetails={feedback.sourceDetails}
					alignmentMap={alignmentMap}
					{...{ ...props, type: feedback.goalType }}
				/>
			)}
			{keyRes && keyData && (
				<KeyResultAttributes
					{...props}
					open={keyRes}
					keyData={keyData}
					handleDrawerClose={handleDrawerClose}
					isKRAttributeUpdated={true}
					krAttrDateError={krAttrDateError}
					keyAttribute={keyAttribute}
					setKRAttrDateError={setKRAttrDateError}
					goalDetail={goalDetail}
					objectiveDetail={okr}
					setCallOut={() => {}}
					setAnchorElCallouts={() => {}}
				/>
			)}

			{drawer.viewPersonalFeedback && (
				<PersonalFeedbackDrawer
					{...props}
					type={'viewPersonalFeedback'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.viewPersonalFeedback}
					loggedInUser={false}
					currentUser={{ employeeId: drawer.employeeId, firstName: drawer.okrData.firstName }}
					showPersonalFeedback={false}
					setFeedbackData={() => {}}
					isPeoplePage={true}
				/>
			)}

			{drawer.askPersonalFeedback && (
				<PersonalFeedbackDrawer
					{...props}
					type={'askPersonalFeedback'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.askPersonalFeedback}
					loggedInUser={false}
					currentUser={{ employeeId: drawer.employeeId }}
					showPersonalFeedback={false}
					setFeedbackData={() => {}}
				/>
			)}

			{drawer.givePersonalFeedback && (
				<PersonalFeedbackDrawer
					{...props}
					type={'givePersonalFeedback'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.givePersonalFeedback}
					loggedInUser={false}
					currentUser={{ employeeId: drawer.employeeId }}
					showPersonalFeedback={false}
					setFeedbackData={() => {}}
				/>
			)}

			{drawer.requestOneOnOne && modalOpen && (
				<AlertDialog
					module='oneToOne'
					message={t('requestOnToOneMsg')}
					handleCloseModal={handleCloseModalRequestOneOnOne}
					modalOpen={modalOpen}
				/>
			)}

			{openAddContributorDrawer && (
				<BecomeContributor
					{...props}
					type={3}
					becomeAContributor={openAddContributorDrawer}
					myGoalList={props.myGoalList}
					krContributorDetailDashboard={krContributorDetailDashboard}
					setKRContributorDetailDashboard={setKRContributorDetailDashboard}
					krAttrDateError={krAttrDateError}
					handleAddContributor={handleAddContributor}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					onSaveBecomeContributorClick={onSaveBecomeContributorClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedContributorDetails={selectedContributorDetails}
					objectiveKRDetails={objectiveKRDetails}
					t={t}
					setKRAttrDateError={setKRAttrDateError}
					defaultKRDateError={defaultKRDateError}
					okrMasterData={props.okrMasterData}
					openDrawer={openAddContributorDrawer}
					loader={loader}
					alignmentMap={alignmentMap}
					alignedStatusData={alignedStatusData}
				/>
			)}
		</Fragment>
	);
};

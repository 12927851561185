import React from 'react';
import {
	HtmlEditor,
	Image,
	Inject,
	Link,
	NodeSelection,
	QuickToolbar,
	RichTextEditorComponent,
	Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import { toolbarDefaultSettings, smileys, animals } from './SfEditorEnum';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { SampleBase } from './sample-base';
import '../../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import Tribute from 'tributejs';
import { getRandomBackgroundColor, getUserName, getRandomColor } from '../../../../config/utils';
import { getHeaders } from '../../../../config/auth';
import { UPLOAD_NOTES_FILES, UPLOAD_CONVERSATION_FILES } from '../../../../config/api-url';

export default class SfEditor extends React.Component {
	constructor(props) {
		super(props);
		this.selection = new NodeSelection();
		this.header = 'Insert Emoticons';
		this.target = this.rteSectionEle;
		this.height = 'auto';
		this.rteSectionEle = null;
		this.rteSectionRef = (element) => {
			this.rteSectionEle = element;
		};

		this.insertImageSettings = {
			saveUrl: UPLOAD_NOTES_FILES,
		};
		if (this.props.uploadCase === 'CONVERSATION') {
			this.insertImageSettings = {
				saveUrl: UPLOAD_CONVERSATION_FILES,
			};
		}
		this.imagePath = {
			path: '',
			name: '',
		};
	}
	shouldComponentUpdate(newProps, newState) {
		return (
			(this.props.value !== newProps.value && (newProps.value != '' || this.props.skipBlankCheck)) || this.props.isDisabled !== newProps.isDisabled
		);
	}
	onCreate() {
		this.customBtn = document.getElementById('custom_tbar');
		if (this.customBtn) {
			this.customBtn.onclick = (e) => {
				e.preventDefault();
				this.rteObj.contentModule.getEditPanel().focus();
				this.dialogObj.element.style.display = '';
				this.range = this.selection.getRange(document);
				this.saveSelection = this.selection.save(this.range, document);
				this.dialogObj.content = this.tabObj.element;
				this.dialogObj.show();
			};
		}
		if (this.props.focusOnLoad) {
			this.rteObj.focusIn();
		}
		if (this.props.enableTagging) {
			var tribute = new Tribute({
				values: (text, cb) => {
					this.remoteSearch(text, (users) => cb(users));
				},
				lookup: 'name',
				selectTemplate: function (item) {
					return (
						'<span class="tagged-name" contenteditable="false" rel = "' +
						item.original.key +
						'" id= "user-id-' +
						item.original.key +
						'">@' +
						item.original.value +
						'</span>'
					);
				},
				menuItemTemplate: function (item) {
					let avatar = item.original.imagePath
						? '<div class="avatar-default"><img src="' + item.original.imagePath + '" /></div>'
						: '<div class="avatar-default" style="backgroundColor: ' +
						  getRandomBackgroundColor(item.original.backGroundColorCode) +
						  '; color: ' +
						  getRandomColor(item.original.colorCode) +
						  '">' +
						  getUserName(item.original) +
						  '</div>';
					return (
						avatar +
						'<div class="search-user-info">' +
						item.string +
						'<p class="email-text">' +
						item.original.emailId +
						'</p></div>'
					);
				},
			});
			tribute.attach(this.rteObj.inputElement);
		}
	}
	dialogCreate() {
		this.dialogCtn = this.tabObj.element;
		this.dialogCtn.onclick = (e) => {
			let activeEle = e.target;
			if (activeEle.classList.contains('char_block')) {
				this.onInsert(activeEle);
			}
		};
	}
	onInsert(activeEle) {
		if (activeEle) {
			if (this.rteObj.formatter.getUndoRedoStack().length === 0) {
				this.rteObj.formatter.saveData();
			}
			this.saveSelection.restore();
			this.rteObj.executeCommand('insertText', activeEle.textContent);
			this.rteObj.formatter.saveData();
			this.rteObj.formatter.enableUndo(this.rteObj);
		}
		this.dialogOverlay();
	}
	dialogOverlay() {
		let activeEle = this.dialogObj.element.querySelector('.char_block.e-active');
		if (activeEle) {
			activeEle.classList.remove('e-active');
		}
		this.dialogObj.hide();
	}
	onOpen() {
		this.tabObj.refresh();
	}
	onCancel() {
		let activeEle = this.element.querySelector('.char_block.e-active');
		if (activeEle) {
			activeEle.classList.remove('e-active');
		}
		this.hide();
	}
	actionCompleteHandler(e) {
		if (e.requestType === 'SourceCode') {
			this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.add('e-overlay');
		} else if (e.requestType === 'Preview') {
			this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.remove('e-overlay');
		} else if (e.requestType === 'Images') {
			if (this.imagePath.path !== '') {
				this.props.imageList.push({
					fileName: e.elements[0].getAttribute('src'),
					filePath: this.imagePath.path,
					fileName: this.imagePath.name,
				});
				e.elements[0].setAttribute('src', this.imagePath.path);
				this.imagePath = {
					path: '',
					name: '',
				};
			}
		}
	}
	remoteSearch = async (text, cb) => {
		if (text.length > 2) {
			let resp = await this.props.globalSearchAPIWithTeam(text, 1, 15, 1);
			let entityList = resp.data.entityList;
			if (entityList) {
				entityList = entityList.map((data) => {
					return {
						...data,
						key: data.employeeId,
						name: data.firstName + ' ' + data.lastName,
						value: data.firstName + ' ' + data.lastName,
					};
				});
				cb(entityList);
			} else {
				cb([]);
			}
		}
	};

	actionBeginEvent(args) {
		if (args.requestType === 'EnterAction') {
			args.cancel = true;
		}
	}
	onToolbarClick(e) {
		let nodeObj = new NodeSelection();
		let range = nodeObj.getRange(this.rteObj.contentModule.getDocument());
		let imgEle = nodeObj.getNodeCollection(range)[0];
		if (e.item.tooltipText === 'Rotate Right') {
			let transform =
				imgEle.style.transform === '' ? 0 : parseInt(imgEle.style.transform.split('(')[1].split(')')[0], 10);
			imgEle.style.transform = 'rotate(' + (transform + 90) + 'deg)';
			this.rteObj.formatter.saveData();
			this.rteObj.formatter.enableUndo(this.rteObj);
		} else if (e.item.tooltipText === 'Rotate Left') {
			let transform =
				imgEle.style.transform === '' ? 0 : Math.abs(parseInt(imgEle.style.transform.split('(')[1].split(')')[0], 10));
			imgEle.style.transform = 'rotate(-' + (transform + 90) + 'deg)';
			this.rteObj.formatter.saveData();
			this.rteObj.formatter.enableUndo(this.rteObj);
		}
	}
	onImageUploadSuccess = (args) => {
		let response = JSON.parse(args.e.currentTarget.response);
		if (response && response.status == 200) {
			this.imagePath = {
				path: response.entity,
				name: args.file.name,
			};
			args.file.name = response.entity;
			let filename = document.querySelectorAll('.e-file-name')[0];
			filename.innerHTML = args.file.name.replace(document.querySelectorAll('.e-file-type')[0].innerHTML, '');
			filename.title = args.file.name;
		}
	};
	imageUploading = (args) => {
		let headers = getHeaders();
		Object.keys(headers).forEach((value) => {
			args.currentRequest.setRequestHeader(value, headers[value]);
		});
	};
	render() {
		let headerText = [{ text: '&#128578;' }, { text: '&#128053;' }];
		var smileyItems = smileys.map(function (smiley) {
			return (
				<div className='char_block' title={smiley.title} dangerouslySetInnerHTML={{ __html: smiley.content }}></div>
			);
		});
		var animalItems = animals.map(function (animal) {
			return (
				<div className='char_block' title={animal.title} dangerouslySetInnerHTML={{ __html: animal.content }}></div>
			);
		});
		return (
			<div className='control-pane'>
				<div className='control-section e-rte-custom-tbar-section' id='rteCustomTool'>
					<div className='rte-control-section' ref={this.rteSectionRef} id='rteSection'>
						<RichTextEditorComponent
							id={this.props.editorId ? this.props.editorId :'EmotionIconstRTE'}
							enabled={!this.props.isDisabled}
							ref={(scope) => {
								this.rteObj = scope;
							}}
							//delayUpdate={true}
							toolbarSettings={this.props.toolbarSettings ? this.props.toolbarSettings : toolbarDefaultSettings}
							actionComplete={this.actionCompleteHandler.bind(this)}
							created={this.onCreate.bind(this)}
							value={this.props.value}
							placeholder={this.props.placeholder}
							blur={(data) => {
								//this.props.handleEditorChange(data.event.target.innerHTML);
							}}
							change={(data) => {
								this.props.handleEditorChange(data.value);
							}}
							quickToolbarSettings={{
								image: [],
							}}
							saveInterval={500}
							onImageUploadSuccess={this.onImageUploadSuccess.bind(this)}
							insertImageSettings={this.insertImageSettings}
							imageUploadSuccess={this.onImageUploadSuccess.bind(this)}
							imageUploading={this.imageUploading.bind(this)}
							//toolbarClick={this.onToolbarClick.bind(this)}
						>
							<Inject services={[HtmlEditor, Toolbar, Link, Image, QuickToolbar]} />
						</RichTextEditorComponent>
						<DialogComponent
							id='customTbarDlg'
							ref={(scope) => {
								this.dialogObj = scope;
							}}
							overlayClick={this.dialogOverlay.bind(this)}
							header={this.header}
							visible={false}
							showCloseIcon={false}
							width='43%'
							target={'#rteSection'}
							height={this.height}
							open={this.onOpen.bind(this)}
							isModal={true}
						></DialogComponent>
						<div id='tabControl' style={{ display: 'none' }}>
							<TabComponent
								id='defaultTab'
								ref={(tab) => {
									this.tabObj = tab;
								}}
								created={this.dialogCreate.bind(this)}
							>
								<TabItemsDirective>
									<TabItemDirective header={headerText[0]} content='#rteEmoticons-smiley' />
									<TabItemDirective header={headerText[1]} content='#rteEmoticons-animal' />
								</TabItemsDirective>
							</TabComponent>
						</div>
						<div id='rteSpecial_char' style={{ display: 'none' }}>
							<div id='rteEmoticons-smiley'>{smileyItems}</div>

							<div id='rteEmoticons-animal'>{animalItems}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

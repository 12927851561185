import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { CompactIcon, RelaxedIcon, UltraCompactIcon } from '../../../config/svg/MyGoalSvg';
import { OKRButton } from '../../Common/OKRButton';
import OrganisationFilters from '../AlignmentMapFilter/organisationFilters';
import { CancelIcon } from '../../../config/svg/Action';
import { setLocalStorageItem } from '../../../services/StorageService';

export const AlignmentMapDirectReportsSecondaryTabs: React.FC<any> = (props) => {
	const { setTabFilter, tabFilter, directReportResult, setTabButtonFilter, t } = props;
	const [getCount, setGetCount] = useState<any>(0);

	useEffect(() => {
		setTabButtonFilter('directReport');
	}, []);

	useEffect(() => {
		const filterData = directReportResult.filter((item: any) => item.progress === 1 || item.progress === 2);
		if (filterData && filterData.length === 0) {
			setGetCount(false);
		}
		setGetCount((filterData && filterData.length) || 0);
	}, [directReportResult]);

	return (
		<Box className='tabs-secondary secondary-tab-direct-report' id='secondary-tab'>
			<Box className='secondary-tab-team-left'>
				<List className='secondary-filters'>
					<ListItem className={tabFilter === 'relaxed' ? 'active' : ''} key={'relaxed'}>
						<OKRButton
							icon={<RelaxedIcon />}
							id={'direct-report-relaxed'}
							title={t('relaxed')}
							handleClick={(e) => {
								setTabFilter('relaxed');
								setLocalStorageItem('activeTab', 'directReport_relaxed');
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'compact' ? 'active' : ''} key={'compact'}>
						<OKRButton
							icon={<CompactIcon />}
							id={'direct-report-compact'}
							title={t('compact')}
							handleClick={() => {
								setTabFilter('compact');
								setLocalStorageItem('activeTab', 'directReport_compact');
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'ultracompact' ? 'active' : ''} key={'ultracompact'}>
						<OKRButton
							icon={<UltraCompactIcon />}
							id={'direct-report-ultracompact'}
							title={t('ultraCompact')}
							handleClick={() => {
								setTabFilter('ultracompact');
								setLocalStorageItem('activeTab', 'directReport_ultracompact');
							}}
						></OKRButton>
					</ListItem>
					<ListItem className='active-color'></ListItem>
				</List>
				<Box className='teams-filter team-filter-no-percentage'>
					<OrganisationFilters {...props} />
				</Box>
			</Box>
			{getCount > 0 && (
				<Box className='nudge-message-box'>
					<Box className='nudge-bar'>
						<Typography variant='body2'>
							{getCount} {t('NudgeOrTalk')}
						</Typography>
						<OKRButton
							id={'nudge-cancel-btn'}
							className='cancel-btn'
							icon={<CancelIcon />}
							handleClick={() => setGetCount(0)}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};

import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	teamProgress: [],
	leadingTeam: [],
	orgReport: null,
	orgReportError: [],
	leadingTeamsReport: [],
	leadingTeamsReportStatus: '',
	myLeadingTeams: [],
};

export default function reportsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_TEAM_PROGRESS_PENDING:
			return {
				...state,
				teamProgress: [],
			};
		case actionTypes.FETCH_TEAM_PROGRESS_SUCCESS:
			return {
				...state,
				teamProgress: action.payload,
			};
		case actionTypes.FETCH_TEAM_PROGRESS_FAILED:
			return {
				...state,
				teamProgress: action.error,
			};
		case actionTypes.FETCH_LEADING_TEAM_PENDING:
			return {
				...state,
			};
		case actionTypes.FETCH_LEADING_TEAM_SUCCESS:
			return {
				...state,
				leadingTeam: action.payload.entityList,
			};
		case actionTypes.FETCH_LEADING_TEAM_FAILED:
			return {
				...state,
				leadingTeam: action.error,
			};
		case actionTypes.RESET_LEADING_TEAM:
			return {
				...state,
				leadingTeam: [],
			};
		case actionTypes.FETCH_MY_LEADING_TEAM_PENDING:
			return {
				...state,
				myLeadingTeams: [],
			};
		case actionTypes.FETCH_MY_LEADING_TEAM_SUCCESS:
			return {
				...state,
				myLeadingTeams: action.payload.entityList,
			};
		case actionTypes.FETCH_MY_LEADING_TEAM_FAILED:
			return {
				...state,
				myLeadingTeams: action.error,
			};
		case actionTypes.FETCH_ORG_REPORTS_PENDING:
			return {
				...state,
				orgReport: null,
				orgReportStatus: 'pending',
			};
		case actionTypes.FETCH_ORG_REPORTS_SUCCESS:
			return {
				...state,
				orgReport: action.payload,
				orgReportStatus: 'success',
			};
		case actionTypes.FETCH_ORG_REPORTS_FAILED:
			return {
				...state,
				orgReportError: action.error,
				orgReportStatus: 'failed',
			};
		case actionTypes.FETCH_LEADING_TEAM_REPORTS_PENDING:
			return {
				...state,
				leadingTeamsReportStatus: 'pending',
			};
		case actionTypes.FETCH_LEADING_TEAM_REPORTS_SUCCESS:
			return {
				...state,
				leadingTeamsReport: action.payload.entityList,
				leadingTeamsReportStatus: 'success',
			};
		case actionTypes.FETCH_LEADING_TEAM_REPORTS_FAILED:
			return {
				...state,
				leadingTeamsReportStatus: 'failed',
			};
		default:
			return state;
	}
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, FormLabel, Modal, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { getAllVersionForUser, createVersionDetail, updateVersionDetail } from '../../action/okrUpdate';
import { useSnackbar } from 'notistack';
import { Enums } from '../../config/enums';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { formatOneOnOneRequestDate } from '../../config/utils';
import moment from 'moment';

export const AddNewVersionPopup: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { buttonIcon, buttonClassName, buttonTitle, LabelText, type, selectedVersion, placeHolderText } = props;
	const { enqueueSnackbar } = useSnackbar();
	const currentDate = new Date();
	currentDate.setMonth(currentDate.getMonth() + 12);
	const startDateValue = new Date();
	startDateValue.setMonth(startDateValue.getMonth() - 12);

	const [formData, setFormData] = useState<any>({});
	const [open, setOpen] = useState<boolean>(false);
	const [isError, setIsError] = useState<any>({});
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);

	const handleOpen = () => {
		setOpen(true);
		if (type === 'Edit') {
			if (selectedVersion && selectedVersion?.version) {
				setFormData({ versionName: selectedVersion?.version });
				setSelectedDateRange(
					moment(selectedVersion?.plannedPublishedDate ? new Date(selectedVersion?.plannedPublishedDate) : new Date())
				);
			}
		}
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleChangeValue = (event: any) => {
		const { value, name } = event.target;
		if (name === 'versionName' && value) {
			setIsError({});
		}
		setFormData({ ...formData, [name]: value });
	};

	const handleCancelClick = (event: any) => {
		handleClose();
		setFormData({});
		setIsError({});
	};
	const handleSaveClick = async (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		// let reg = new RegExp(/^(\d+\.)?(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)$/);
		const startDate = selectedDateRange?._d || new Date();
		if (formData?.versionName?.trim()) {
			//  && reg.test(formData?.versionName)
			let response: any = {};
			let versionSuccessMsg = t('versionSuccessMsg');
			if (type === 'Edit') {
				versionSuccessMsg = t('versionUpdateSuccessMsg');
				const requestPayload = {
					versionId: selectedVersion?.versionId || 0,
					versionName: formData?.versionName?.trim() || selectedVersion?.version,
					plannedPublishedDate: formatOneOnOneRequestDate(startDate),
				};
				response = await dispatch(updateVersionDetail(requestPayload));
			} else {
				response = await dispatch(
					createVersionDetail({ ...formData, plannedPublishedDate: formatOneOnOneRequestDate(startDate) })
				);
			}
			if (response && response.data && response.data.isSuccess) {
				handleClose();
				setFormData({});
				setIsError({});
				const reqPostPayload = {
					publishType: 1,
					pageIndex: 1,
					pageSize: Enums.TEN,
				};
				dispatch(getAllVersionForUser(reqPostPayload));
				enqueueSnackbar(versionSuccessMsg, {
					variant: 'success',
					autoHideDuration: 5000,
				});
			} else {
				const { messageList } = response?.data || {};
				enqueueSnackbar(messageList?.versionName, {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		} else {
			// if (!formData?.versionName?.trim()) {
			setIsError({ ...isError, versionName: true });
			setFormData({ ...formData, versionName: '' });
		}
		// else {
		// 	setIsError({ ...isError, versionName: false, stringError: true });
		// 	setFormData({ ...formData, versionName: '' });
		// }
	};
	// const handleAddTaskDueDateChange = (value: any) => {
	// 	setFormData({ ...formData, plannedPublishedDate: value });
	// };

	return (
		<>
			{selectedVersion && !selectedVersion?.isPublished && type === 'Edit' ? (
				<OKRButton
					className={buttonClassName}
					icon={buttonIcon}
					title={t(buttonTitle)}
					id={`edit-icon`}
					handleClick={handleOpen}
				/>
			) : (
				type === 'Add' && (
					<OKRButton
						className={buttonClassName}
						icon={buttonIcon}
						title={t(buttonTitle)}
						id={`edit-icon`}
						handleClick={handleOpen}
					/>
				)
			)}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
				className='version-modal add-version-modal'
				disableEscapeKeyDown
			>
				<Box className='version-modal-inner'>
					<Box className='modal-content'>
						<form className='drawer-form-fields' noValidate autoComplete='off'>
							<Box className='drawer-input-field drawer-input-field-first'>
								<TextField
									fullWidth
									autoFocus
									id='version-name'
									className='helperText-bottom'
									label={<>{t(LabelText)}</>}
									placeholder={t(placeHolderText)}
									error={isError?.versionName || isError?.stringError}
									name='versionName'
									value={formData?.versionName}
									helperText={
										isError?.versionName ? (
											<>
												<ErrorIcon />
												{t('versionNameRequired')}
											</>
										) : (
											isError?.stringError && (
												<>
													<ErrorIcon />
													{t('versionNumberRequired')}
												</>
											)
										)
									}
									onChange={handleChangeValue}
									inputProps={{ maxLength: 150 }}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t(LabelText), disableUnderline: true }}
									onKeyPress={(e) => {
										e.key === 'Enter' && e.preventDefault();
									}}
								/>
							</Box>
							<Box className='drawer-input-field'>
								<FormLabel component='legend'>Publish Date</FormLabel>
								<Box className='range-selector-calender'>
									<ReactDateRangePicker
										type={'ContributorList'}
										selectedCycleDetails={{}}
										startDateValue={type === 'Edit' ? selectedDateRange : new Date()}
										minimumDate={startDateValue ? new Date(startDateValue) : new Date()}
										maximumDate={currentDate ? new Date(currentDate) : new Date()}
										handleDateSelection={(
											range: any,
											start: any,
											end: any,
											selectionType: any,
											isCycleChanged: any
										) => {
											range && setSelectedDateRange(range);
											// start && handleAddTaskDueDateChange(start);
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={selectedDateRange}
										setSelectedDateRange={setSelectedDateRange}
										isDatePickerDisable={false}
										placeholderText={'Publish Date'}
										singleDateRangeTitle={'Set: Publish Date'}
									/>
								</Box>
							</Box>
						</form>
					</Box>
					<Box className='modal-footer'>
						<Box>
							<OKRButton className='btn-link' text={t('cancelBtn')} id='cancel-btn' handleClick={handleCancelClick} />
						</Box>
						<Box>
							<OKRButton className='btn-primary' text={t('saveBtn')} id='save-btn' handleClick={handleSaveClick} />
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

import * as React from 'react';
import { Box, IconButton, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import { IndividualSmallIcon } from '../../../config/svg/CommonSvg';
import CircularProgress from '../../OkrAction/CircularProgress';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { AlignmentOkrListPopover } from '../../Common/AlignmentOkrListPopover';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { getOkrProgressDetails } from '../../../config/utils';

export interface PeopleCardProps {
	isOnHoverTrue?: Boolean;
}

const PeopleCard: React.FC<PeopleCardProps> = (props: any) => {
	const { currentUser, data, t } = props;
	const okr = data.data;
	const [anchorEl, setAnchorEl] = React.useState<any>(null);
	const [open, setOpen] = React.useState<boolean>(false);
	const [scaleValue, setScaleValue] = React.useState<any>(1);
	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const { color } = getOkrProgressDetails({
		value: okr.score || okr.krScore,
		dueDate: okr.cycleEndDate || okr.cycleEndDate,
	});

	return (
		<React.Fragment>
			<Box
				className={`people-view-card ${
					props.tabFilter === 'peoplecompact'
						? 'people-compact-view'
						: props.tabFilter === 'peopleultracompact'
						? 'people-ultra-view'
						: 'people-relax-view'
				}`}
				id={`ppl-card-${data?.data.employeeUniqueId}`}
			>
				<Box className='people-view-card-inner'>
					<Box className='people-header'>
						{data?.data.isContributorExist === true && data?.data.isParent === false && (
							<Tooltip title={t('contributorsLabel')} arrow>
								<IconButton className='connect-icon connect-icon-first'>
									<IndividualSmallIcon />
								</IconButton>
							</Tooltip>
						)}
						{data?.data.isContributorExist === false && data?.data.isParent === true && (
							<Tooltip title={t('contributorsLabel')} arrow>
								<IconButton className='connect-icon connect-icon-first'>
									<IndividualSmallIcon />
								</IconButton>
							</Tooltip>
						)}
						{data?.data.isSourceExist === true && data?.data.isParent === true && (
							<Tooltip title={t('parentLabel')} arrow>
								<IconButton className='connect-icon connect-icon-arrow'>
									<RightArrowIcon />
								</IconButton>
							</Tooltip>
						)}
						{data?.data.isSourceExist === false && data?.data.isParent === false && (
							<Tooltip title={t('parentLabel')} arrow>
								<IconButton className='connect-icon connect-icon-arrow'>
									<RightArrowIcon />
								</IconButton>
							</Tooltip>
						)}
						<Box className='people-header-col1'>
							<UserAvatarGroup
								avatarClassName=''
								isButtonVisible={false}
								isButtonDisable={true}
								avatarGroupClassName='contributor-user-avatar'
								loginUserAvatar={
									okr.okrViewContributors && okr.okrViewContributors.length
										? okr.okrViewContributors.find((itemKR: any) => itemKR.userType === 'Owner')
											? okr.okrViewContributors.find((itemKR: any) => itemKR.userType === 'Owner')
											: okr
										: okr
								}
								max={1}
							/>

							<Box className='contributor-people-info'>
								<Typography variant='h4'>{`${data?.data.firstName} ${data?.data.lastName}`}</Typography>
								{props.tabFilter !== 'peopleultracompact' && (
									<Typography variant='subtitle2'>{data?.data.designation}</Typography>
								)}
							</Box>
						</Box>
						<Box className='people-header-col2'>
							{props.tabFilter === 'peopleultracompact' ? (
								<Box className='progressText'>
									<Typography variant='body1' style={{ color: color }}>
										{okr?.score}%
									</Typography>
								</Box>
							) : (
								<Box className='progressChart'>
									<CircularProgress
										percentage={data?.data.score}
										score={data?.data.score}
										dueDate={data?.data?.cycleEndDate}
									/>
								</Box>
							)}
							<OkrPopperMenu
								poperClassName={'alignment-popper-menu popper-menu-list okr-list-popper-menu'}
								okr={okr}
								type={1}
								t={t}
								currentUser={currentUser}
								handleDeleteOkr={() => {}}
								//poperPlacement={'bottom-start'}
								peopleMap={true}
								poperContainer={false}
								diagramInstance={props.diagramInstance}
								{...props}
							/>
						</Box>
					</Box>
					{props.tabFilter !== 'peopleultracompact' && (
						<Box className='card-toggle'>
							{props.tabFilter === 'peoplerelaxed' && (
								<React.Fragment>
									{data?.data.actionLevel < 0 ? (
										<Typography variant='body2'>
											{data?.data.nameList.length > 0 &&
												data?.data.nameList.map((item: any, index: any) => (
													<>
														{data?.data.nameList.length === 1 ? (
															<> {item}</>
														) : data?.data.nameList.length > 1 && data?.data.nameList.length - 1 !== index ? (
															<> {item} ,</>
														) : data?.data.nameList.length - 1 === index ? (
															<> {item}</>
														) : (
															<></>
														)}
													</>
												))}
											&nbsp;& {t('youconnectedThrough')}
										</Typography>
									) : data?.data.actionLevel > 0 ? (
										<>
											<Typography variant='body2'>
												{t('youLabel')}
												{data?.data.nameList.length > 0 &&
													data?.data.nameList.map((item: any, index: any) => (
														<>
															{data?.data.nameList.length === 1 ? (
																<> & {item}</>
															) : data?.data.nameList.length > 1 && data?.data.nameList.length - 1 !== index ? (
																<> , {item}</>
															) : data?.data.nameList.length - 1 === index ? (
																<> & {item}</>
															) : (
																<></>
															)}
														</>
													))}
												&nbsp;{t('connectedThrough')}
											</Typography>
										</>
									) : (
										<></>
									)}
								</React.Fragment>
							)}
							<Box
								className='alignment-okrlist-hover'
								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup='true'
								onMouseOver={handleOpen}
								onMouseLeave={handleClose}
							>
								<List className='card-key-result'>
									<ListItem>
										<Typography variant='body2'>{`${data?.data.objectiveCount || 0} ${t('objectives')}`}</Typography>
									</ListItem>
									<ListItem>
										<Typography variant='body2'>{`${data?.data.keyResultCount || 0} ${t('keyResults')}`}</Typography>
									</ListItem>
								</List>

								<AlignmentOkrListPopover
									{...props}
									data={data?.data}
									open={open}
									setOpen={setOpen}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									okrListKey={data?.data.peopleViewObjectives}
									scaleValue={scaleValue}
									popoverClassName={'okr-popover-dropdown scale-popover-dropdown'}
									alignmentMapPopover={true}
								/>
							</Box>
						</Box>
					)}
				</Box>
				{props.tabFilter === 'peoplerelaxed' && (
					<Box className='people-card-tag'>
						<Box className='people-tag'>
							<Typography variant='body2' className='tags-one-txt'>
								{data?.data.teamName}
							</Typography>
						</Box>
						<UserAvatarGroup
							{...props}
							contributorDetails={okr.peopleViewContributors}
							addButtonClassName='add-btn'
							avatarClassName=''
							isButtonVisible={false}
							max={3}
							loginUserAvatar={false}
							showLoginUserAvatar={false}
							currentUser={currentUser}
							contributorDetailsType={true}
							poperContainer={false}
							contributorClassName={'contributor-details-popover alignmentmap-contributorPopup'}
							diagramInstance={props.diagramInstance}
							alignmentMapTab={true}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
};

export default PeopleCard;

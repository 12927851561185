import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CustomLogin from './../../../components/Guest/CustomDomain/index';
import { userValidation } from '../../../action/signin';

const mapStateToProps = (state) => {
	return {
		signInStatus: state.signReducer.signInStatus,
		pending: state.signReducer.pending,
		messageList: state.signReducer.messageList,
		entity: state.signReducer.entity,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			userValidation,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLogin));

import * as React from 'react';
import { useCallback } from 'react';
import Select, { components, StylesConfig } from 'react-select';
import _ from 'lodash';
import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../config/utils';
import { LeaderIcon, TeamCountIcon } from '../../../config/svg/formElementIcons';
import { DisableIconSmall, StarIcon } from '../../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';

export const MultiSelect: React.FC<any> = (props) => {
	const {
		optionsList,
		selectedOptions,
		customFilter,
		key,
		onSelectOption,
		fetchAsyncData,
		performSearch,
		placeHolder,
		noOptionsMessage,
		isSingleSelection,
		closeMenuOnSelect,
		labelTemplate,
		showNoOptions,
		selectClassName,
		selectClassNamePrefix,
		isMulti,
		helperText,
		blurCalled,
		disableSearch,
		id,
		disabled,
	} = props;
	const { t } = useTranslation();

	const formatOptionLabel = (props: any, context: any) => {
		const {
			value,
			label,
			emailId,
			imagePath,
			secondaryText,
			count,
			isIndividualPermission,
			backGroundColorCode,
			colorCode,
		} = props;
		if (context && context.context === 'value' && labelTemplate === 'teamAvatarLabel') {
			return (
				<Box className='search-user-result' id={`${'option-' + value}`}>
					<Box className='search-user-img'>
						{imagePath ? (
							<Avatar src={imagePath} />
						) : (
							<Avatar
								style={{
									backgroundColor: getRandomBackgroundColor(backGroundColorCode),
									color: getRandomColor(colorCode),
								}}
							>
								{getUserName({ fullName: label, firstName: '', lastName: '' })}
							</Avatar>
						)}
					</Box>
					<Box className='search-user-info'>
						{label?.length > 25 ? (
							<Tooltip arrow title={label} aria-label='Team Name'>
								<Typography
									variant='h4'
									style={{
										color: getRandomColor(colorCode),
									}}
								>
									{label.substring(0, 25)}...
								</Typography>
							</Tooltip>
						) : (
								<Typography
									variant='h4'
									style={{
										color: getRandomColor(colorCode),
									}}
								>
									{label}
								</Typography>
						)}
					</Box>
				</Box>
			);
		}
		switch (labelTemplate) {
			case 'onlyLabel':
				return (
					// add class here 'chip-star when star icon will be show'
					<Box className={isIndividualPermission ? 'chip-star role-info' : 'role-info'}>
						<Typography variant='h4'>{label}</Typography>
						{isIndividualPermission && (
							<sup>
								<StarIcon />
							</sup>
						)}
					</Box>
				);
			case 'avatarLabel':
				return (
					<Box className='search-user-result'>
						<Box className='search-user-img'>
							{imagePath ? (
								<Avatar src={imagePath} />
							) : (
								<Avatar>{getUserName({ fullName: label, firstName: '', lastName: '' })}</Avatar>
							)}
						</Box>
						<Box className='search-user-info'>
							<Typography variant='h4'>{label}</Typography>
							{secondaryText ? <Typography variant='subtitle2'>{secondaryText}</Typography> : <></>}
						</Box>
					</Box>
				);
			case 'teamAvatarLabel':
				return (
					<Box className='search-user-result'>
						<Box className='search-user-img'>
							{imagePath ? (
								<Avatar src={imagePath} />
							) : (
								<Avatar
									style={{
										backgroundColor: getRandomBackgroundColor(backGroundColorCode),
										color: getRandomColor(colorCode),
									}}
								>
									{getUserName({ fullName: label, firstName: '', lastName: '' })}
								</Avatar>
							)}
						</Box>
						<Box className='search-user-info'>
							<Typography variant='h4'>{label}</Typography>
							{secondaryText ? (
								<List component='ul' disablePadding>
									<ListItem>
										<Typography variant='subtitle2'>
											<TeamCountIcon />
											{count}
										</Typography>
									</ListItem>
									<ListItem>
										<Typography variant='subtitle2'>
											<LeaderIcon />
											{secondaryText}
										</Typography>
									</ListItem>
								</List>
							) : (
								<></>
							)}
						</Box>
					</Box>
				);
			default:
				return (
					<div>
						<div className='primary-text'>{label}</div>
						<div className='secondary-text'>{emailId}</div>
					</div>
				);
		}
	};

	const handleSearchThrottled = (value: any) => {
		delayedQuery(value);
	};
	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);
	const _handleSearch = async (value: string) => {
		if (value.trim() !== '') {
			performSearch(value);
		}
	};

	const updateSelection = (selectedVal: any, actionMeta: any) => {
		if (actionMeta.action === 'remove-value' && actionMeta.removedValue?.isNotDeletable) {
			return false;
		}
		if (isSingleSelection === true) {
			if (selectedOptions && selectedOptions.length > 0) {
				onSelectOption(selectedVal.filter((value: any) => value.value !== selectedOptions[0].value));
			} else {
				onSelectOption(selectedVal);
			}
		} else {
			if (isMulti === false) {
				onSelectOption([selectedVal]);
			} else {
				onSelectOption(selectedVal);
			}
		}
	};

	const colourStyles: StylesConfig<any, true> = {
		multiValue: (styles: any, { data }: any) => {
			return {
				...styles,
				borderRadius: '24px',
				color: getRandomColor(data.colorCode),
				backgroundColor: `${getRandomBackgroundColor(data.backGroundColorCode)} !important`,
			};
		},

		multiValueLabel: (styles: any, { data }: any) => ({
			...styles,
			color: getRandomColor(data.colorCode),
		}),

		multiValueRemove: (styles: any, { data }: any) => ({
			...styles,
			color: getRandomColor(data.colorCode),
			':hover': {
				color: `${getRandomColor(data.colorCode)} !important`,
			},
		}),
	};
	const MultiValueRemove = (props1: any) => {
		const isNotDeletable = props1?.data?.isNotDeletable;
		return isNotDeletable ? (
			<Tooltip arrow title={`${t('disableUserDeleteMsg')}`} className='delete-icon-select'>
				<span
					{...props1}
					styles={{
						color: getRandomColor(props1?.data?.colorCode),
					}}
				>
					<DisableIconSmall />
				</span>
			</Tooltip>
		) : (
			<components.MultiValueRemove {...props1}></components.MultiValueRemove>
		);
	};
	return (
		<Box className={`width-100 `}>
			<Select
				components={{ MultiValueRemove }}
				key={key}
				styles={labelTemplate === 'teamAvatarLabel' ? colourStyles : undefined}
				id={id || ''}
				isMulti={isMulti}
				isDisabled={disabled || false}
				closeMenuOnSelect={closeMenuOnSelect === true}
				options={optionsList}
				value={selectedOptions}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeHolder}
				onChange={updateSelection}
				onInputChange={(value) => {
					handleSearchThrottled(value);
				}}
				filterOption={(option: any, searchText: any) => {
					if (fetchAsyncData === true) {
						return true;
					} else {
						return customFilter(option, searchText);
					}
				}}
				onBlur={() => {
					blurCalled && blurCalled();
				}}
				noOptionsMessage={() => (showNoOptions === true ? noOptionsMessage : null)}
				className={`${selectClassName} ${optionsList.length > 0 ? 'menu-open' : ''}`}
				classNamePrefix={selectClassNamePrefix}
				isClearable={false}
				isSearchable={!disableSearch}
				inputId={`${id || 'input-id'}`}
				instanceId={`${id || 'input-placeholder'}`}

			//menuIsOpen={true}
			/>
			{helperText && helperText !== '' ? <p className='MuiFormHelperText-root Mui-error'>{helperText}</p> : <></>}
		</Box>
	);
};

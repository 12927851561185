import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { HOME, LOGIN_INITIATOR_URL, ORGANIZATIONS } from '../../config/app-url';
import { useTranslation } from 'react-i18next';

export default function SsoLogin(props) {
	const { t } = useTranslation();
	let history = useHistory();
	useEffect(() => {
		//clearLocalStorage();
		removeLocalStorageItem('currentUser');
		let queryString = props.location.search.split('&appid=');
		let appId = queryString[1] ? queryString[1] : '';
		let qsessionId = queryString[0].split('?sessionid=');
		let sessionId = qsessionId[1] ? qsessionId[1] : '';

		if (sessionId && appId) {
			let data = {
				sessionId: sessionId,
				appId: appId,
			};
			ssoLogin(data);
		} else {
			window.location = LOGIN_INITIATOR_URL;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, props.location.search]);

	const ssoLogin = async (data) => {
		try {
			const resp = await props.ssoLoginAction(data);
			if (resp.status === 200 && resp.data.status === 200) {
				setLocalStorageItem('signInStatus', resp.data.status);
				setLocalStorageItem('userDetail', JSON.stringify(resp.data.entity));
				setLocalStorageItem('tokenId', JSON.stringify(resp.data.entity.tokenId));
				setLocalStorageItem('accessTokenId', JSON.stringify(resp.accessToken));
				setLocalStorageItem('isAbleToDesign', false);

				let redirectUrl = getLocalStorageItem('redirectUrl');
				if (Boolean(redirectUrl)) {
					redirectUrl = JSON.parse(redirectUrl);
					if (redirectUrl.url && redirectUrl.url.includes('/')) {
						if (
							redirectUrl.url.includes('Organization') &&
							resp.data.entity.loggedInAs &&
							resp.data.entity.loggedInAs === 'Admin'
						) {
							setLocalStorageItem('LoginAs', 'admin');
							props.history.push(ORGANIZATIONS);
						} else {
							//== if redirect url is set and redirection
							//== is not for login user
							let empId = getLocalStorageItem('empId');
							if (empId && parseInt(empId) !== resp.data.entity.employeeId) {
								removeLocalStorageItem('redirectUrl');
								removeLocalStorageItem('empId');
							}

							setLocalStorageItem('LoginAs', 'user');

							//== if Contributors is set in the redirect url
							if (redirectUrl.url.includes('Contributors')) {
								const itemArray = redirectUrl.url.split('/');
								const goalId = itemArray[1] ? itemArray[1] : 0;
								setLocalStorageItem('highlightGoal', goalId);
								removeLocalStorageItem('redirectUrl');
							}
							props.history.push(HOME);
						}
					} else {
						setLocalStorageItem('LoginAs', 'user');
						props.history.push(redirectUrl.url);
					}
					removeLocalStorageItem('empId');
				} else {
					setLocalStorageItem('LoginAs', 'user');
					props.history.push(HOME);
				}
				window.location.reload();
			} else {
				window.location = LOGIN_INITIATOR_URL;
			}
		} catch (err) {
			window.location = LOGIN_INITIATOR_URL;
		}
	};

	return <Fragment>{t('ssoLoginRedirectingMsg')}</Fragment>;
}

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, FormLabel, Typography, TextField, ButtonGroup, Avatar, Tooltip } from '@material-ui/core';
import {
	AspirationalIcon,
	IndividualIcon,
	CommittedIcon,
	TeamIcon,
	VirtualDeleteIcon,
	VirtualLinkContributorIcon,
} from '../../../config/svg/CommonSvg';
import { Enums } from '../../../config/enums';
import {
	getDueDays,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import { LinkObjectivePopover } from '../LinkObjectivePopover';
import { TeamsOkr } from '../TeamsOkr/TeamsOkr';
import { CallOutTooltip } from '../../OkrAction/CallOutTooltip';
import { calloutsDataForObjective, defaultCalloutProps } from '../../../config/constant';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';

export const GoalAttributeDetail: React.FC<any> = (props) => {
	const {
		t,
		okrMasterData,
		durationCycleDetail,
		currentCycleEndDate,
		objectiveDetail,
		goalAttrDateError,
		setIsSaveDisabled,
		goalsAttribute,
		setGoalsAttribute,
		goalDetail,
		updateGoalDueDate,
		updateGoalStartDate,
		updateStartDate,
		updateDueDate,
		updateModalProps,
		setUpdateModalProps,
		isCoachValue,
		setIsCoachValue,
		activeOnboardingPanel,
		showPrevForSubmitCallouts,
		setShowPrevForSubmitCallouts,
		isCoachAccess,
		isNewOkr,
		setGaolAttrDateError,
		resToUseInCalendar,
		editModee,
		setEditModee,
		updateGoalDateRangeCycleChangeUpdate,
		currentCycleId,
	} = props;

	const { goalTypes } = Boolean(okrMasterData) && okrMasterData;
	const [showTeamsOkr, setShowTeamsOkr] = useState<boolean>(false);
	const [currentGoalId, setCurrentGoalId] = useState<number>(0);
	const [teamDetails, setTeamDetails] = useState<any>(null);
	const [selectedTeams, setSelectedTeams] = React.useState<any>([]);
	const [treeNodesData, setTreeNodesData] = React.useState<any[] | null>(null);
	const loggedInUserDetail = getUserDetails();
	const [showChips, setShowChips] = useState<boolean>(false);
	const [teamsLoading, setTeamsLoading] = useState<boolean>(true);
	const [apiCalled, setApiCalled] = useState<boolean>(false);
	const [dataUpdated, setDataUpdated] = useState<boolean>(false);
	const [isReset, setIsReset] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>(null);

	const [linkObjectiveOpen, setLinkObjectiveOpen] = useState<boolean>(false);
	const [linkedObjectiveData, setLinkedObjectiveData] = useState<any[]>([]);
	const [linkObjectiveLoading, setlinkObjectiveLoading] = useState<boolean>(false);
	const [linkObjectiveUserAnchorEl, setLinkObjectiveUserAnchorEl] = useState<HTMLElement | null>(null);
	const [linkObjectiveUserOpen, setLinkObjectiveUserOpen] = useState<boolean>(false);
	const [isLinkObjectiveInitiated, setIsLinkObjectiveInitiated] = useState<boolean>(false);
	const [nature, setNature] = useState<number>(0);
	const [otherTeams, setOtherTeams] = useState<number>(0);
	const [myTeams, setMyTeams] = useState<number>(0);
	//callout popup
	const [showCallouts, setShowCallouts] = useState<boolean>(false);

	// Added below code for Date Range Picker ==> Start
	const cycleId = getLocalStorageItem('cycleId') && parseInt(getLocalStorageItem('cycleId') || '0');
	const cycleDetail = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [dueDaysDiff, setDueDaysDiff] = useState<any>(0);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [allCycleDetails, setAllCycleDetails] = useState<any>(cycleDetail);
	const [selectionType, setSelectionType] = useState<any>('range');

	useEffect(() => {
		if (allCycleDetails && allCycleDetails.cycleDetails && allCycleDetails.cycleDetails.length) {
			allCycleDetails.cycleDetails.forEach((cycleDetails: any) => {
				const currentQuarterDetails = cycleDetails.quarterDetails.find(
					(item: any) => item.organisationCycleId === cycleId
				);
				if (currentQuarterDetails) {
					setSelectedCycleDetails(
						objectiveDetail?.cycleDetailData || { ...currentQuarterDetails, year: cycleDetails?.year }
					);
					setIsCurrentCycle({ ...currentQuarterDetails, year: cycleDetails?.year });
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allCycleDetails]);
	useEffect(() => {
		if (selectionType === 'range') {
			const startDateValue = goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate;
			const endDateValue = goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate;
			updateDueDays(startDateValue, endDateValue, selectionType);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// Added below code for Date Range Picker ==> End

	useEffect(() => {
		if (getLocalStorageItem('showCallouts')) {
			setShowCallouts(true);
		}
	}, [props.onboardingCount]);
	const initialCallout = defaultCalloutProps;
	const calloutsData = calloutsDataForObjective;
	const [callOut, setCallOut] = useState(initialCallout);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const inputRefOkrTitle: any = useRef();
	const inputRefOkrDescription: any = useRef();
	const inputRefOkrGoalType: any = useRef();
	const inputRefOkrOwner: any = useRef();
	const inputRefOkrDate: any = useRef();
	const virtualLinkRef: any = useRef();

	useEffect(() => {
		if (
			activeOnboardingPanel === 'okrDrawer' &&
			getLocalStorageItem('okrDrawercalloutDone') === null &&
			getLocalStorageItem('showCallouts') === 'true'
		) {
			setShowCallouts(true);
			const timeout = setTimeout(() => {
				inputRefOkrTitle.current.focus();
				//Callout for OKR Title Field
				setAnchorElCallouts(inputRefOkrTitle.current);
				setCallOut(calloutsData[0]);
			}, 1000);
			return () => {
				clearTimeout(timeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOnboardingPanel]);

	useEffect(() => {
		if (showPrevForSubmitCallouts) {
			setAnchorElCallouts(virtualLinkRef.current);
			setCallOut(calloutsData[5]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPrevForSubmitCallouts]);

	const nextCallout = (currentCalloutIndex: Number) => {
		if (currentCalloutIndex === 0) {
			setTimeout(() => {
				//Callout for OKR Title Field
				setAnchorElCallouts(inputRefOkrDescription.current);
				setCallOut(calloutsData[1]);
			}, 100);
		} else if (currentCalloutIndex === 1) {
			if ((loggedInUserDetail.isTeamLeader || isCoachAccess) && nature !== 0) {
				if (inputRefOkrGoalType.current) {
					if (loggedInUserDetail.isTeamLeader) {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('teamAlignGuidedText')}`,
							currentCallout: 'teamAlign',
						});
					} else {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('individualAlignGuidedText')}`,
							currentCallout: 'individualAlign',
						});
					}
				}
			} else {
				const element = document.getElementById('inputRefAVirtualLink');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				setAnchorElCallouts(inputRefOkrDate.current);
				setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 2) {
			const element = document.getElementById('inputRefAVirtualLink');
			if (element && getLocalStorageItem('showCallouts')) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
			if (selectedTeams.length > 0 && selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId) {
				setAnchorElCallouts(inputRefOkrOwner.current);
				setCallOut(calloutsData[3]);
			} else {
				setAnchorElCallouts(inputRefOkrDate.current);
				setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 3) {
			if (selectedTeams.length > 0 && selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId) {
				const element = document.getElementById('inputRefAVirtualLink');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				setAnchorElCallouts(inputRefOkrDate.current);
				setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 4) {
			setAnchorElCallouts(virtualLinkRef.current);
			setCallOut(calloutsData[5]);
		} else if (currentCalloutIndex === 5) {
			props.setShowSubmitCallouts(true);
			setShowPrevForSubmitCallouts(false);
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	const prevCallout = (currentCalloutIndex: Number) => {
		if (currentCalloutIndex === 0) {
		} else if (currentCalloutIndex === 1) {
			setAnchorElCallouts(inputRefOkrTitle.current);
			setCallOut(calloutsData[0]);
		} else if (currentCalloutIndex === 2) {
			setAnchorElCallouts(inputRefOkrDescription.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 3) {
			if ((loggedInUserDetail.isTeamLeader || isCoachAccess) && nature !== 0) {
				const element = document.getElementById('obj-name');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				if (loggedInUserDetail.isTeamLeader) {
					setAnchorElCallouts(inputRefOkrGoalType.current);
					setCallOut({
						...calloutsData[2],
						text: `${t('teamAlignGuidedText')}`,
						currentCallout: 'teamAlign',
					});
				} else {
					setAnchorElCallouts(inputRefOkrGoalType.current);
					setCallOut({
						...calloutsData[2],
						text: `${t('individualAlignGuidedText')}`,
						currentCallout: 'individualAlign',
					});
				}
			} else {
				setAnchorElCallouts(inputRefOkrDescription.current);
				setCallOut(calloutsData[1]);
			}
		} else if (currentCalloutIndex === 4) {
			if (selectedTeams.length > 0 && selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId) {
				if (inputRefOkrGoalType.current) {
					setAnchorElCallouts(inputRefOkrOwner.current);
					setCallOut(calloutsData[3]);
				}
			} else {
				const element = document.getElementById('obj-name');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				if ((loggedInUserDetail.isTeamLeader || isCoachAccess) && nature !== 0) {
					if (loggedInUserDetail.isTeamLeader) {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('teamAlignGuidedText')}`,
							currentCallout: 'teamAlign',
						});
					} else {
						setAnchorElCallouts(inputRefOkrGoalType.current);
						setCallOut({
							...calloutsData[2],
							text: `${t('individualAlignGuidedText')}`,
							currentCallout: 'individualAlign',
						});
					}
				} else {
					setAnchorElCallouts(inputRefOkrDescription.current);
					setCallOut(calloutsData[1]);
				}
			}
		} else if (currentCalloutIndex === 5) {
			setAnchorElCallouts(inputRefOkrDate.current);
			setCallOut(calloutsData[4]);
		} else if (currentCalloutIndex === 6) {
			setAnchorElCallouts(virtualLinkRef.current);
			setCallOut(calloutsData[5]);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	useEffect(() => {
		if (updateModalProps === true) {
			let callReset = resetObjectiveContributor();
			if (isReset === false && (callReset === true || goalDetail.actionType === 'editGoal')) {
				setIsReset(true);
				setModalProps({
					open: true,
					message: t('resetTeamsOkrAlertMessage'),
					data: [],
					type: 'coach',
					callReset: goalDetail.actionType === 'editGoal' ? false : callReset,
				});
			} else {
				resetTeam();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModalProps]);

	const resetTeam = async () => {
		let goalAttributeData = Object.assign({}, goalsAttribute);
		await setUpdateModalProps(false);
		goalAttributeData?.myGoalsDetails?.forEach((kr: any) => {
			kr.contributors = [];
		});
		objectiveDetail.teamOkrRequests = [];
		objectiveDetail.teamId = 0;
		objectiveDetail.teamName = '';
		objectiveDetail.isPrivate = false;
		await setGoalsAttribute({
			...goalsAttribute,
			isCoach: isCoachValue,
			isPrivate: false,
			teamOkrRequests: [],
			teamId: 0,
			teamName: '',
			goalTypeId: isCoachValue === true ? 1 : 2,
			myGoalsDetails: goalAttributeData?.myGoalsDetails,
			owner: loggedInUserDetail.employeeId,
			ownerDesignation: loggedInUserDetail.designation,
			ownerEmailId: loggedInUserDetail.employeeId,
			ownerFirstName: loggedInUserDetail.firstName,
			ownerLastName: loggedInUserDetail.lastName,
			ownerImagePath: loggedInUserDetail.imagePath,
			ownerEmployeeCode: loggedInUserDetail.employeeCode,
		});
		setSelectedTeams([]);
	};

	useEffect(() => {
		if (goalDetail.okr && goalDetail.okr.goalObjectiveId !== 0 && goalDetail.okr.teamId !== 0) {
			setSelectedTeams([
				{
					id: goalDetail.okr.teamId,
					fullname: goalDetail.okr.teamName,
					backGroundColorCode: goalDetail.okr.backGroundColorCode,
					colorCode: goalDetail.okr.colorCode,
					teamLogo: goalDetail.okr.imagePath,
					teamMembersCount: goalDetail.okr.membersCount ? goalDetail.okr.membersCount : goalDetail.okr.teamMembersCount,
					// organisationHead: goalDetail.okr.employeeId,
					organisationHead:
						goalsAttribute.owner !== loggedInUserDetail.employeeId ? goalsAttribute.owner : goalDetail.okr.employeeId,
					leaderLastName: goalsAttribute.ownerLastName,
					leaderImagePath: goalsAttribute.ownerImagePath,
					leaderFirstName: goalsAttribute.ownerFirstName,
					leaderDesignation: goalsAttribute.ownerDesignation,
				},
			]);
			setShowChips(true);
		}
	}, [goalDetail]);

	const setCoachOwner = (coachTeam: any, selectedTeamData: any) => {
		setGoalsAttribute({
			...goalsAttribute,
			teamOkrRequests: selectedTeamData,
			teamId: selectedTeamData[0].id,
			teamName: selectedTeamData[0].fullname,
			teamLogo: selectedTeamData[0].imagePath,
			teamMembersCount: selectedTeamData[0].membersCount
				? selectedTeamData[0].membersCount
				: selectedTeamData[0].teamMembersCount,
			selectedTeamBackgroundColor: selectedTeamData[0].backGroundColorCode,
			selectedTeamTextColor: selectedTeamData[0].colorCode,
			owner: coachTeam[0].organisationHead,
			ownerDesignation: coachTeam[0].leaderDesignation,
			ownerFirstName: coachTeam[0].leaderFirstName,
			ownerLastName: coachTeam[0].leaderLastName,
			ownerImagePath: coachTeam[0].leaderImagePath,
			ownerEmployeeCode: coachTeam[0].organisationHead,
			ownerEmailId: '',
			isCoach: true,
		});
	};

	const setTeamOwner = (selectedTeamData: any) => {
		setGoalsAttribute({
			...goalsAttribute,
			teamOkrRequests: selectedTeamData,
			teamId: selectedTeamData[0].id,
			teamName: selectedTeamData[0].fullname,
			teamLogo: selectedTeamData[0].imagePath,
			teamMembersCount: selectedTeamData[0].membersCount
				? selectedTeamData[0].membersCount
				: selectedTeamData[0].teamMembersCount,
			selectedTeamBackgroundColor: selectedTeamData[0].backGroundColorCode,
			selectedTeamTextColor: selectedTeamData[0].colorCode,
			owner: loggedInUserDetail.employeeId,
			ownerDesignation: loggedInUserDetail.designation,
			ownerEmailId: loggedInUserDetail.employeeId,
			ownerFirstName: loggedInUserDetail.firstName,
			ownerLastName: loggedInUserDetail.lastName,
			ownerImagePath: loggedInUserDetail.imagePath,
			ownerEmployeeCode: loggedInUserDetail.employeeCode,
			isCoach: isCoachValue,
		});
	};

	useEffect(() => {
		if (selectedTeams && selectedTeams.length > 0) {
			let coachTeam = [];
			if (isCoachValue === true && teamDetails) {
				coachTeam = teamDetails.filter((item: any) => item.organisationId === selectedTeams[0].id);
			}
			if (selectedTeams) {
				if (selectedTeams[0].id !== goalsAttribute.teamId && goalsAttribute.teamId) {
					if (isCoachValue === true && coachTeam.length > 0) {
						setCoachOwner(coachTeam, selectedTeams);
						setGoalsAttribute({
							...goalsAttribute,
							teamOkrRequests: selectedTeams,
							teamId: selectedTeams[0].id,
							teamName: selectedTeams[0].fullname,
							teamLogo: selectedTeams[0].imagePath,
							teamMembersCount: selectedTeams[0].membersCount
								? selectedTeams[0].membersCount
								: selectedTeams[0].teamMembersCount,
							selectedTeamBackgroundColor: selectedTeams[0].backGroundColorCode,
							selectedTeamTextColor: selectedTeams[0].colorCode,
							isCoach: !selectedTeams[0].isMyTeam ? true : false,
							owner: selectedTeams[0].organisationHead,
							ownerFirstName: selectedTeams[0].leaderFirstName,
							ownerLastName: selectedTeams[0].leaderLastName,
							ownerImagePath: selectedTeams[0].leaderImagePath,
						});
					} else {
						setTeamOwner(selectedTeams);
					}
				} else {
					if (isCoachValue === true && coachTeam.length > 0) {
						setCoachOwner(coachTeam, selectedTeams);
					} else {
						setGoalsAttribute({
							...goalsAttribute,
							teamOkrRequests: selectedTeams,
							teamId: selectedTeams[0].id,
							teamName: selectedTeams[0].fullname,
							teamLogo: selectedTeams[0].imagePath,
							teamMembersCount: selectedTeams[0].membersCount
								? selectedTeams[0].membersCount
								: selectedTeams[0].teamMembersCount,
							selectedTeamBackgroundColor: selectedTeams[0].backGroundColorCode,
							selectedTeamTextColor: selectedTeams[0].colorCode,
							isCoach: !selectedTeams[0].isMyTeam ? true : false,
							owner: selectedTeams[0].organisationHead,
							ownerFirstName: selectedTeams[0].leaderFirstName,
							ownerLastName: selectedTeams[0].leaderLastName,
							ownerImagePath: selectedTeams[0].leaderImagePath,
						});
					}
				}
			}
		} else {
			setGoalsAttribute({
				...goalsAttribute,
				teamId: 0,
				teamName: '',
				teamOkrRequests: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTeams]);

	useEffect(() => {
		if (objectiveDetail.teamOkrRequests?.length > 0) {
			goalsAttribute.goalTypeId = 1;
		}
		if (
			objectiveDetail.linkedObjective &&
			objectiveDetail.linkedObjective.objectiveId &&
			!goalsAttribute.linkedObjective
		) {
			goalsAttribute.linkedObjective = objectiveDetail.linkedObjective;
			goalsAttribute.linkedObjectiveId = objectiveDetail.linkedObjectiveId;
		}

		if (
			objectiveDetail.isCoach !== undefined &&
			goalsAttribute.isCoach === undefined &&
			objectiveDetail.isCoach !== goalsAttribute.isCoach
		) {
			goalsAttribute.isCoach = objectiveDetail.isCoach;
			setGoalsAttribute({
				...goalsAttribute,
				isCoach: objectiveDetail.isCoach,
			});
		}
		if (goalsAttribute && goalsAttribute.goalTypeId) {
			setCurrentGoalId(goalsAttribute.goalTypeId);
			setGaolAttrDateError({});
			if (goalsAttribute.goalTypeId === 1) {
				if (!apiCalled) {
					if (
						!dataUpdated &&
						objectiveDetail.teamOkrRequests.length > 0 &&
						(!goalsAttribute.teamOkrRequests || goalsAttribute.teamOkrRequests.length === 0)
					) {
						let selected: any = [];
						objectiveDetail.teamOkrRequests.forEach((element: any) => {
							selected.push(element);
						});
						setSelectedTeams(selected);
						if (selected.length > 0) {
							setShowChips(true);
						}
					} else if (
						goalsAttribute.teamOkrRequests &&
						goalsAttribute.teamOkrRequests.length !== 0 &&
						objectiveDetail.teamOkrRequests.length !== 0
					) {
						if (goalsAttribute.teamOkrRequests.length > 0) {
							let selected: any = [];
							goalsAttribute.teamOkrRequests.forEach((element: any) => {
								selected.push(element);
							});
							setSelectedTeams(selected);
							if (selected.length > 0) {
								setShowChips(true);
							}
						}
					}
					getUserTeams();
				}
			}
		} else if (objectiveDetail.goalTypeId !== goalsAttribute.goalTypeId) {
			setCurrentGoalId(objectiveDetail.goalTypeId);
			setGoalsAttribute({
				...goalsAttribute,
				goalTypeId: objectiveDetail.goalTypeId,
			});
		}

		if (objectiveDetail?.goalNatureId && !goalsAttribute?.goalNatureId) {
			setNature(objectiveDetail.goalNatureId);
			setGoalsAttribute({
				...goalsAttribute,
				goalNatureId: objectiveDetail.goalNatureId,
			});
		} else if (goalsAttribute?.goalNatureId) {
			setNature(goalsAttribute.goalNatureId);
		}

		if (!isCoachAccess && !loggedInUserDetail.isTeamLeader && !goalsAttribute.goalTypeId) {
			// setCurrentGoalId(objectiveDetail.goalTypeId);
			setGoalsAttribute({
				...goalsAttribute,
				goalTypeId: 2,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalsAttribute]);

	useEffect(() => {
		if (currentGoalId === 2) {
			setSelectedTeams([]);
		}
	}, [currentGoalId]);

	useEffect(() => {
		if (goalDetail.actionType === 'editGoal') {
			setIsCoachValue(goalsAttribute.isCoach);
		}
	}, [goalsAttribute.isCoach]);

	useEffect(() => {
		objectiveDetail.objectiveName && objectiveDetail.isCoach
			? setGoalsAttribute({
					...goalsAttribute,
					isPrivate: objectiveDetail.isPrivate,
					objectiveName: objectiveDetail.objectiveName,
					isCoach: objectiveDetail.isCoach,
			  })
			: objectiveDetail.objectiveName &&
			  setGoalsAttribute({
					...goalsAttribute,
					isPrivate: objectiveDetail.isPrivate,
					objectiveName: objectiveDetail.objectiveName,
			  });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveDetail]);

	const onHandleAssignmentTypeClick = (event: any, item: any) => {
		if (
			isReset === false &&
			//goalsAttribute.goalTypeId !== 0 &&
			//goalsAttribute.goalStatusId !== Enums.GOAL_STATUS_DRAFT &&
			goalsAttribute.goalTypeId !== item.goalTypeId
		) {
			if (goalDetail.actionType === 'editGoal') {
				setIsReset(true);
				if (goalsAttribute?.goalStatusId === Enums.GOAL_STATUS_DRAFT) {
					const data = { open: false, message: '', data: item, type: '' };
					setModalProps(data);
					resetObjectiveId(data);
				} else {
					setModalProps({ open: true, message: t('resetTeamsOkrAlertMessage'), data: item, type: '' });
				}
			} else {
				let callReset = resetObjectiveContributor();
				if (callReset === false) {
					assignmentSetter(item);
				} else {
					setIsReset(true);
					setModalProps({
						open: true,
						message: t('resetTeamsOkrAlertMessage'),
						data: item,
						type: '',
						callReset: callReset,
					});
				}
			}
		} else {
			if (parseInt(item.goalTypeId) === 1 && (treeNodesData === null || treeNodesData.length === 0)) {
				assignmentSetter(item);
			} else if (parseInt(item.goalTypeId) === 1) {
				setCurrentGoalId(item.goalTypeId);
				setShowTeamsOkr(true);
				if (goalsAttribute.goalTypeId !== item.goalTypeId) {
					setGoalsAttribute({
						...goalsAttribute,
						contributors: [],
						score: 0,
						goalTypeId: item.goalTypeId,
					});
				}
			} else {
				assignmentSetter(item);
			}
		}
	};

	const resetObjectiveContributor = () => {
		let callReset: boolean = false;
		if (goalsAttribute.goalObjectiveId) {
			if (goalsAttribute && goalsAttribute.myGoalsDetails) {
				goalsAttribute.myGoalsDetails.forEach((kr: any) => {
					if (kr.contributors && kr.contributors.length > 0) {
						callReset = true;
					}
				});
			}
		} else if (objectiveDetail && objectiveDetail.myGoalsDetails) {
			objectiveDetail.myGoalsDetails.forEach((kr: any) => {
				if (kr.contributors && kr.contributors.length > 0) {
					callReset = true;
				}
			});
		}
		return callReset;
	};

	const assignmentSetter = (item: any, callReset = false) => {
		setCurrentGoalId(item.goalTypeId);
		if (item?.goalTypeId && parseInt(item.goalTypeId) === 1) {
			setShowTeamsOkr(true);
			if (goalsAttribute.goalTypeId !== item.goalTypeId) {
				getUserTeams();
				setGoalsAttribute({
					...goalsAttribute,
					contributors: [],
					score: 0,
					goalTypeId: item.goalTypeId,
					owner: loggedInUserDetail.employeeId,
					ownerDesignation: loggedInUserDetail.designation,
					ownerEmailId: loggedInUserDetail.employeeId,
					ownerFirstName: loggedInUserDetail.firstName,
					ownerLastName: loggedInUserDetail.lastName,
					ownerImagePath: loggedInUserDetail.imagePath,
					ownerEmployeeCode: loggedInUserDetail.employeeCode,
				});
				if (callReset === true || goalDetail.actionType === 'editGoal') {
					resetCon();
				}
			} else {
				setGoalsAttribute({
					...goalsAttribute,
					goalTypeId: item.goalTypeId,
				});
			}
		} else {
			setShowChips(false);
			objectiveDetail.teamOkrRequests = [];
			objectiveDetail.teamId = 0;
			objectiveDetail.teamName = '';
			objectiveDetail.isCoach = false;
			if (goalsAttribute.goalTypeId && goalsAttribute.goalTypeId !== item.goalTypeId) {
				setGoalsAttribute({
					...goalsAttribute,
					goalTypeId: item.goalTypeId,
					teamId: 0,
					teamName: '',
					score: 0,
					contributors: [],
					teamOkrRequests: [],
					owner: loggedInUserDetail.employeeId,
					ownerDesignation: loggedInUserDetail.designation,
					ownerEmailId: loggedInUserDetail.employeeId,
					ownerFirstName: loggedInUserDetail.firstName,
					ownerLastName: loggedInUserDetail.lastName,
					ownerImagePath: loggedInUserDetail.imagePath,
					isCoach: false,
					ownerEmployeeCode: loggedInUserDetail.employeeCode,
				});
				if (callReset === true) {
					resetCon();
				}
			} else {
				setGoalsAttribute({
					...goalsAttribute,
					goalTypeId: item.goalTypeId,
					teamId: 0,
					teamName: '',
					isCoach: false,
					teamOkrRequests: [],
				});
			}
		}
	};
	const resetCon = async () => {
		await props.resetContributor();
	};
	const callTreeNodes = () => {
		getUserTeams();
		setShowTeamsOkr(true);
	};

	const getUserTeams = async () => {
		setApiCalled(true);
		// let isCoach = goalsAttribute.isCoach ? goalsAttribute.isCoach : false;
		const apiRes = await props.getUserTeamsData(
			'?goalType=1&empId=' + loggedInUserDetail?.employeeId + '&isCoach=' + Boolean(isCoachAccess)
		);
		setTreeNodesData(null);
		setApiCalled(false);
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			setTeamsLoading(false);
			const filteredDataset = apiRes?.data?.entityList;
			const otherTeams =
				filteredDataset && filteredDataset.length ? filteredDataset.filter((item: any) => !item.isMyTeam) : [];
			const myTeams =
				filteredDataset && filteredDataset.length ? filteredDataset.filter((item: any) => item.isMyTeam) : [];
			setTeamDetails(filteredDataset || []);
			prepareTeamData(filteredDataset || []);
			setOtherTeams(otherTeams);
			setMyTeams(myTeams);
		}
	};
	const prepareTeamNodeData = (orgData: any, secondaryText?: string) => {
		return {
			id: orgData.organisationId,
			fullname: orgData.organisationName,
			membersCount: orgData.membersCount,
			children: [],
			secondaryText: secondaryText,
			imagePath: orgData.imagePath,
			isParent: true,
			backGroundColorCode: orgData.backGroundColorCode,
			colorCode: orgData.colorCode,
			parentTeamBackGroundColorCode: orgData.parentTeamBackGroundColorCode,
			parentTeamColorCode: orgData.parentTeamColorCode,
			organisationHead: orgData.organisationHead,
			isMyTeam: orgData.isMyTeam,
			leaderDesignation: orgData.leaderDesignation,
			leaderFirstName: orgData.leaderFirstName,
			leaderImagePath: orgData.leaderImagePath,
			leaderLastName: orgData.leaderLastName,
		};
	};
	const prepareTeamData = (data: any) => {
		let completeOrgData: any = [];
		for (let ii = 0; ii < data?.length; ii++) {
			let orgData = data[ii];
			let teamObj = prepareTeamNodeData(orgData, orgData.parentName);
			completeOrgData.push(teamObj);
		}
		let selVal: string[] = [];
		selectedTeams.forEach((selectedT: any) => {
			completeOrgData.forEach((orgData: any) => {
				if (orgData.id === selectedT.id) {
					orgData.isChecked = true;
					orgData.selected = true;
				}
			});
		});
		setTreeNodesData(completeOrgData);
	};

	const setDeletedNodes = (nodes: any) => {
		setGoalsAttribute({
			...goalsAttribute,
			teamOkrRequests: nodes,
			owner: loggedInUserDetail.employeeId,
			ownerDesignation: loggedInUserDetail.designation,
			ownerEmailId: loggedInUserDetail.employeeId,
			ownerFirstName: loggedInUserDetail.firstName,
			ownerLastName: loggedInUserDetail.lastName,
			ownerImagePath: loggedInUserDetail.imagePath,
			ownerEmployeeCode: loggedInUserDetail.employeeCode,
		});
		objectiveDetail.teamOkrRequests = nodes;
		objectiveDetail.owner = loggedInUserDetail.employeeId;
		objectiveDetail.ownerDesignation = loggedInUserDetail.designation;
		objectiveDetail.ownerEmailId = loggedInUserDetail.employeeId;
		objectiveDetail.ownerFirstName = loggedInUserDetail.firstName;
		objectiveDetail.ownerLastName = loggedInUserDetail.lastName;
		objectiveDetail.ownerImagePath = loggedInUserDetail.imagePath;
		objectiveDetail.ownerEmployeeCode = loggedInUserDetail.employeeCode;
		setSelectedTeams(nodes);
	};

	const linkSelectedOwner = (selectedLink: any) => {
		setOwnerInGoalAttribute(selectedLink);
	};

	const setOwnerInGoalAttribute = (selectedLink: any) => {
		let goalAttributeData = Object.assign({}, goalsAttribute);
		goalAttributeData?.myGoalsDetails?.forEach((kr: any) => {
			kr.owner = selectedLink.employeeId;
			kr.ownerDesignation = selectedLink.designation;
			kr.ownerEmailId = selectedLink.employeeId;
			kr.ownerFirstName = selectedLink.firstName;
			kr.ownerLastName = selectedLink.lastName;
			kr.ownerImagePath = selectedLink.imagePath ? selectedLink.imagePath : '';
			kr.ownerEmployeeCode = selectedLink.employeeCode;
			kr.contributors =
				kr.contributors && kr.contributors.length
					? kr.contributors.filter((item: any) => item.employeeId !== selectedLink.employeeId)
					: [];
		});
		setGoalsAttribute({
			...goalsAttribute,
			owner: selectedLink.employeeId,
			ownerDesignation: selectedLink.designation,
			ownerEmailId: selectedLink.employeeId,
			ownerFirstName: selectedLink.firstName,
			ownerLastName: selectedLink.lastName,
			ownerImagePath: selectedLink.imagePath ? selectedLink.imagePath : '',
			ownerEmployeeCode: selectedLink.employeeCode,
			myGoalsDetails: goalAttributeData?.myGoalsDetails,
		});
		setAddContributorOpen(false);
	};

	const onHandleLinkOwnerClick = async () => {
		let tId = goalsAttribute?.teamId;
		const apiRes = await props.getLinkOwnerData(`teamId=${tId}`);
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			let userExists = false;
			apiRes?.data?.entity?.teamEmployees.map((teamItem: any) => {
				teamItem.organisationName = apiRes.data.entity.organisationName;
				teamItem.backGroundColorCode = apiRes.data.entity.backGroundColorCode;
				teamItem.colorCode = apiRes.data.entity.colorCode;
				if (teamItem?.employeeId === loggedInUserDetail?.employeeId) {
					userExists = true;
				}
			});
			if (!userExists) {
				const userData = {
					employeeId: loggedInUserDetail.employeeId,
					employeeCode: loggedInUserDetail.employeeCode,
					firstName: loggedInUserDetail.firstName,
					lastName: loggedInUserDetail.lastName,
					designation: loggedInUserDetail.designation,
					imagePath: loggedInUserDetail.imagePath,
					organisationId: loggedInUserDetail.organisationId,
					organisationName: loggedInUserDetail.organisationName,
					backGroundColorCode: loggedInUserDetail.backGroundColorCode,
					colorCode: loggedInUserDetail.colorCode,
					self: true,
				};
				apiRes?.data?.entity?.teamEmployees.unshift(userData);
			}
		}
	};

	useEffect(() => {
		if (!goalsAttribute?.linkedObjective?.objectiveId) {
			setLinkObjectiveOpen(true);
		}
	}, [goalsAttribute.linkedObjective]);

	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const [addContributorOpen, setAddContributorOpen] = useState<boolean>(false);
	const [addContributorAnchorEl, setAddContributorAnchorEl] = useState<HTMLElement | null>(null);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);

	const handleCloseModal = (e: any, action: any) => {
		if (action === 1) {
			if (modalProps.type === 'updateDateForKR') {
				const { dateDetails } = modalProps.data;
				if (dateDetails) {
					updateStartDate(dateDetails?.startDate.date || modalProps.data.date);
					updateDueDate(
						dateDetails?.endDate.date || modalProps.data.date,
						dateDetails?.startDate.date || modalProps.data.date
					);
					dateDetails?.range && setSelectedDateRange(dateDetails?.range);
					updateDueDays(dateDetails?.startDate?.date, dateDetails?.endDate?.date, selectionType);
				}
				// if (modalProps.data.type === 'startDate') {
				// 	updateStartDate(modalProps.data.date);
				// } else if (modalProps.data.type === 'dueDate') {
				// 	updateDueDate(modalProps.data.date);
				// }
			} else {
				setModalProps({ ...modalProps, open: false });
				resetObjectiveId({ ...modalProps, open: false });
			}
		} else {
			if (modalProps.type === 'private') {
				objectiveDetail.isPrivate = !modalProps.data;
				setGoalsAttribute({ ...goalsAttribute, isPrivate: !modalProps.data });
			}
			if (modalProps.type === 'updateDateForKR') {
				selectedDateRange && setSelectedDateRange(selectedDateRange);
			}
			setIsReset(false);
		}
		setModalProps({ open: false, data: '', type: '' });
	};
	const resetObjectiveId = async (modalProps: any) => {
		if (modalProps.type !== 'private' && modalProps.type !== 'teams' && modalProps.type !== 'coach') {
			if (goalsAttribute.goalObjectiveId) {
				const apiRes = await props.resetObjective(
					'employeeId=' +
						goalsAttribute.employeeId +
						'&goalObjectiveId=' +
						goalsAttribute.goalObjectiveId +
						'&goalType=' +
						modalProps.data.goalTypeId
				);
			}
			assignmentSetter(modalProps.data, modalProps.callReset);
		} else if (modalProps.type === 'teams') {
			if (goalsAttribute.goalObjectiveId) {
				const apiRes = await props.resetObjective(
					'employeeId=' +
						goalsAttribute.employeeId +
						'&goalObjectiveId=' +
						goalsAttribute.goalObjectiveId +
						'&goalType=' +
						goalsAttribute.goalTypeId
				);
			}
			if (modalProps.callReset === true) {
				await resetCon();
			}
			setSelectedTeams(modalProps.data);
		} else if (modalProps.type === 'coach') {
			if (goalsAttribute.goalObjectiveId) {
				const apiRes = await props.resetObjective(
					'employeeId=' +
						goalsAttribute.employeeId +
						'&goalObjectiveId=' +
						goalsAttribute.goalObjectiveId +
						'&goalType=1'
				);
			}
			setUpdateModalProps(false);
			if (modalProps.callReset === true) {
				resetCon();
			}
			objectiveDetail.teamOkrRequests = [];
			objectiveDetail.teamId = 0;
			objectiveDetail.teamName = '';
			objectiveDetail.isPrivate = false;
			await setGoalsAttribute({
				...goalsAttribute,
				isCoach: isCoachValue,
				goalTypeId: isCoachValue === true ? 1 : 2,
				isPrivate: false,
				owner: loggedInUserDetail.employeeId,
				ownerDesignation: loggedInUserDetail.designation,
				ownerEmailId: loggedInUserDetail.employeeId,
				ownerFirstName: loggedInUserDetail.firstName,
				ownerLastName: loggedInUserDetail.lastName,
				ownerImagePath: loggedInUserDetail.imagePath,
				ownerEmployeeCode: loggedInUserDetail.employeeCode,
			});
			setSelectedTeams([]);
		} else {
			setIsSaveDisabled(false);
			if (modalProps.data) {
				if (goalsAttribute.goalObjectiveId) {
					const apiRes = await props.resetObjective(
						'employeeId=' +
							goalsAttribute.employeeId +
							'&goalObjectiveId=' +
							goalsAttribute.goalObjectiveId +
							'&goalType=' +
							2
					);
				}
				setCurrentGoalId(2);
				objectiveDetail.teamOkrRequests = [];
				objectiveDetail.teamId = 0;
				objectiveDetail.teamName = '';
				objectiveDetail.isPrivate = modalProps.data;
				await setGoalsAttribute({
					...goalsAttribute,
					isPrivate: modalProps.data,
					goalTypeId: 2,
					teamId: 0,
					teamName: '',
					teamOkrRequests: [],
					owner: loggedInUserDetail.employeeId,
					ownerDesignation: loggedInUserDetail.designation,
					ownerEmailId: loggedInUserDetail.employeeId,
					ownerFirstName: loggedInUserDetail.firstName,
					ownerLastName: loggedInUserDetail.lastName,
					ownerImagePath: loggedInUserDetail.imagePath,
					ownerEmployeeCode: loggedInUserDetail.employeeCode,
				});

				if (modalProps.callReset === true) {
					await resetCon();
				}
				setShowChips(false);
				setSelectedTeams([]);
			} else {
				if (goalsAttribute.goalObjectiveId) {
					const apiRes = await props.resetObjective(
						'employeeId=' +
							goalsAttribute.employeeId +
							'&goalObjectiveId=' +
							goalsAttribute.goalObjectiveId +
							'&goalType=' +
							1
					);
				}
				await setGoalsAttribute({
					...goalsAttribute,
					isPrivate: modalProps.data,
					owner: loggedInUserDetail.employeeId,
					ownerDesignation: loggedInUserDetail.designation,
					ownerEmailId: loggedInUserDetail.employeeId,
					ownerFirstName: loggedInUserDetail.firstName,
					ownerLastName: loggedInUserDetail.lastName,
					ownerImagePath: loggedInUserDetail.imagePath,
					ownerEmployeeCode: loggedInUserDetail.employeeCode,
				});
				if (modalProps.callReset === true) {
					await resetCon();
				}
				setShowChips(false);
				setSelectedTeams([]);
				objectiveDetail.teamOkrRequests = [];
				objectiveDetail.teamId = 0;
				objectiveDetail.teamName = '';
				objectiveDetail.isPrivate = modalProps.data;
			}
		}
		setModalProps({ open: false, data: '', type: '' });
	};

	const onHandleLinkObjectiveClick = async (event: any) => {
		if (
			goalsAttribute.goalObjectiveId &&
			goalsAttribute?.goalObjectiveId !== 0 &&
			goalsAttribute.createdBy !== loggedInUserDetail.employeeId &&
			goalsAttribute.owner !== loggedInUserDetail.employeeId
		) {
		} else {
			setLinkObjectiveUserAnchorEl(linkObjectiveUserAnchorEl ? null : event.currentTarget);
			setLinkObjectiveUserOpen(true);
			scrollTo();
			setLinkObjectiveOpen(true);
		}
	};
	const fetchUserObjectiveList = async (userData: any) => {
		setlinkObjectiveLoading(true);
		const apiRes = await props.getLinkObjectiveData(
			'?searchEmployeeId=' + userData.employeeId + '&searchEmployeeCycleId=' + userData.cycleId
		);
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			setLinkedObjectiveData(apiRes.data.entityList);
		}
		setlinkObjectiveLoading(false);
		setLinkObjectiveOpen(true);
		scrollTo();
	};
	const linkSelected = (selectedLink: any) => {
		setGoalsAttribute({
			...goalsAttribute,
			linkedObjective: selectedLink,
			linkedObjectiveId: selectedLink ? selectedLink.objectiveId : 0,
		});
	};

	const scrollTo = () => {
		setTimeout(function () {
			let element = document.getElementById('objective-link');
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 200);
	};

	const checkTeamUpdated = (teams: any) => {
		if (teams && teams.length > 0) {
			let callReset = resetObjectiveContributor();
			if (goalsAttribute.goalObjectiveId && goalsAttribute.goalObjectiveId !== 0) {
				if (goalsAttribute.teamOkrRequests && goalsAttribute.teamOkrRequests.length > 0) {
					if (goalsAttribute.teamOkrRequests[0]['id'] !== teams[0]['id']) {
						if (isReset === false && (callReset === true || goalDetail.actionType === 'editGoal')) {
							setIsReset(true);
							setModalProps({
								open: true,
								message: t('resetTeamsOkrAlertMessage'),
								data: teams,
								type: 'teams',
								callReset: goalDetail.actionType === 'editGoal' ? false : callReset,
							});
						} else {
							setSelectedTeams(teams);
						}
					}
				} else {
					setSelectedTeams(teams);
				}
			} else {
				if (goalsAttribute.teamOkrRequests && goalsAttribute.teamOkrRequests.length > 0) {
					if (goalsAttribute.teamOkrRequests[0]['id'] !== teams[0]['id']) {
						if (isReset === false && (callReset === true || goalDetail.actionType === 'editGoal')) {
							setIsReset(true);
							setModalProps({
								open: true,
								message: t('resetTeamsOkrAlertMessage'),
								data: teams,
								type: 'teams',
								callReset: goalDetail.actionType === 'editGoal' ? false : callReset,
							});
						} else {
							setSelectedTeams(teams);
						}
					}
				} else {
					setSelectedTeams(teams);
				}
			}
		} else {
			setSelectedTeams(teams);
		}
	};
	const hasContributor = (goalsAttribute: any) => {
		const krWithContributor =
			goalsAttribute &&
			goalsAttribute.myGoalsDetails &&
			goalsAttribute.myGoalsDetails.filter((item: any) => item.contributors && item.contributors.length > 0);
		if (krWithContributor && krWithContributor.length > 0) {
			return true;
		}
		return false;
	};

	const handleNatureOfOkr = (value: any) => {
		setNature(value);
		setGaolAttrDateError({});
		setGoalsAttribute({
			...goalsAttribute,
			goalNatureId: value,
		});
	};

	const shouldDateChangePopup = (okrData: any, date: any) => {
		return (
			new Date(date).toDateString() !== new Date(okrData.endDate).toDateString() &&
			okrData?.myGoalsDetails?.filter((item: any) => item.keyDescription.length > 0).length > 0
		);
	};
	// Added below code for Date Range Picker ==> Start
	const dateChangeHandler = (
		range: any,
		start: any,
		end: any,
		selectionType: any,
		isCycleChanged: Boolean,
		selectedQuarterData: any
	) => {
		if (isCycleChanged) {
			updateGoalStartDate(start, false);
			updateGoalDueDate(end, false, start);
			range && setSelectedDateRange(range);
			updateDueDays(start, end, selectionType);
			updateGoalDateRangeCycleChangeUpdate(selectedQuarterData);
		} else {
			const dateDetails = { startDate: { date: start }, endDate: { date: end }, range };
			if (start) {
				if (goalDetail && goalDetail.showAttributeDrawer && goalDetail.actionType === 'editGoal') {
					// setModalProps({
					// 	open: true,
					// 	type: 'updateDateForKR',
					// 	message:
					// 		goalDetail?.contributors?.length > 1 ? t('updateObjectiveAttribute') : t('updateObjAttrNoContributor'),
					// 	module: 'user',
					// 	event: null,
					// 	data: { metricId: '', date: start, type: 'startDate', dateDetails },
					// });
				} else if (!Boolean(goalsAttribute?.goalObjectiveId)) {
					// setModalProps({
					// 	open: true,
					// 	type: 'updateDateForKR',
					// 	message: hasContributor(objectiveDetail) ? t('updateObjectiveAttribute') : t('updateObjAttrNoContributor'),
					// 	module: 'user',
					// 	event: null,
					// 	data: { metricId: '', date: start, type: 'startDate', dateDetails },
					// });
				} else {
					updateGoalStartDate(start, false);
					range && setSelectedDateRange(range);
					updateDueDays(start, end, selectionType);
				}
			}
			if (start && end) {
				setIsSaveDisabled(false);
				if (goalsAttribute?.myGoalsDetails && goalsAttribute?.myGoalsDetails.length > 0) {
					if (
						goalDetail &&
						goalDetail.showAttributeDrawer &&
						goalDetail.actionType === 'editGoal' &&
						shouldDateChangePopup(goalsAttribute, end)
					) {
						//updateGoalDueDate(date, true, start);
						setModalProps({
							open: true,
							type: 'updateDateForKR',
							message:
								goalDetail?.contributors?.length > 1 ? t('updateObjectiveAttribute') : t('updateObjAttrNoContributor'),
							module: 'user',
							event: null,
							data: { metricId: '', date: end, type: 'dueDate', dateDetails },
						});
					} else if (!Boolean(goalsAttribute?.goalObjectiveId) && shouldDateChangePopup(objectiveDetail, end)) {
						setModalProps({
							open: true,
							type: 'updateDateForKR',
							message: hasContributor(objectiveDetail)
								? t('updateObjectiveAttribute')
								: t('updateObjAttrNoContributor'),
							module: 'user',
							event: null,
							data: { metricId: '', date: end, type: 'dueDate', dateDetails },
						});
					} else {
						updateGoalStartDate(start, false);
						updateGoalDueDate(end, false, start);
						range && setSelectedDateRange(range);
						updateDueDays(start, end, selectionType);
					}
				} else {
					updateGoalStartDate(start, false);
					updateGoalDueDate(end, false, start);
					range && setSelectedDateRange(range);
					updateDueDays(start, end, selectionType);
				}
			}
		}
	};

	const updateDueDays = (startDt: any, endDt: any, rangeSelectionType: String) => {
		if (rangeSelectionType === 'range') {
			const diffInDays = getDueDays(
				startDt,
				endDt,
				selectedCycleDetails ? selectedCycleDetails : props.userSelectedCycle
			);
			setDueDaysDiff(diffInDays);
		}
	};
	// Added below code for Date Range Picker ==> End

	return (
		<Fragment>
			<Box className='drawer-inner-content'>
				<Box className='drawer-content-panel'>
					<form className='drawer-form-fields' noValidate autoComplete='off'>
						{!goalsAttribute.isPrivate && (
							<Box className='drawer-input-field link-objective pos-rel'>
								<Box
									component='div'
									className='okr-assign-btn-grp linking-objective'
									id='inputRefAVirtualLink'
									{...({ ref: virtualLinkRef } as any)}
								>
									<Box className='kr-field-row assigned-btn'>
										{goalsAttribute.linkedObjective && goalsAttribute.linkedObjective.objectiveId ? (
											<ul className='objective-ul assigned-link'>
												<li className={`objective-li `}>
													<Box className='left-content' onClick={onHandleLinkObjectiveClick}>
														<Box className='link-objective-label'>
															<VirtualLinkContributorIcon />
															{t('linkObjectiveAttrTitle')}
														</Box>
														<FormLabel component='legend' className='link_objective_desc'>
															{t('linkObjectiveAttrDescription')}
														</FormLabel>
														<Typography className='objective-label'>
															{goalsAttribute.linkedObjective.objectiveName}
														</Typography>
														<Typography className='objective-chips'>
															{goalsAttribute.linkedObjective.dueCycle !== '' && (
																<span
																	className='objective-chip quarter-chip'
																	// title={goalsAttribute.linkedObjective.dueCycle}
																>
																	{' '}
																	{goalsAttribute.linkedObjective.dueCycle}
																</span>
															)}
															{goalsAttribute.linkedObjective.teamName !== '' && (
																<Tooltip arrow title={goalsAttribute.linkedObjective.teamName}>
																	<span
																		style={{
																			backgroundColor: getRandomBackgroundColor(
																				goalsAttribute.linkedObjective?.backGroundColorCode
																			),
																			color: getRandomColor(goalsAttribute.linkedObjective?.colorCode),
																		}}
																		className='objective-chip performance-chip'
																	>
																		{' '}
																		{goalsAttribute.linkedObjective.teamName}
																	</span>
																</Tooltip>
															)}
															{/*<span className='objective-chip management-chip'>	{goalsAttribute.linkedObjective.performance}</span>*/}
														</Typography>
													</Box>
													<Box className='right-content'>
														{(goalDetail.actionType !== 'editGoal' ||
															(goalDetail.actionType === 'editGoal' &&
																(goalsAttribute.createdBy === loggedInUserDetail.employeeId ||
																	goalsAttribute.owner === loggedInUserDetail.employeeId))) && (
															<span
																className='virtualdeleteicon'
																id='virtual-delete-icon'
																onClick={(e) => {
																	linkSelected({ objectiveId: 0 });
																}}
															>
																<VirtualDeleteIcon />
															</span>
														)}
													</Box>
												</li>
											</ul>
										) : (
											(goalDetail.actionType !== 'editGoal' ||
												(goalDetail.actionType === 'editGoal' &&
													(goalsAttribute.createdBy === loggedInUserDetail.employeeId ||
														goalsAttribute.owner === loggedInUserDetail.employeeId))) && (
												<Box className='left-content' onClick={onHandleLinkObjectiveClick}>
													<Box className='link-objective-label'>
														<VirtualLinkContributorIcon />
														{t('linkObjectiveAttrTitle')}
													</Box>
													<FormLabel component='legend' className='link_objective_desc'>
														{t('linkObjectiveAttrDescription')}
													</FormLabel>
												</Box>
											)
										)}
									</Box>
								</Box>
								<LinkObjectivePopover
									{...props}
									popperAddClassName={'link-objective-popover'}
									linkObjectiveOpen={linkObjectiveOpen}
									linkObjectiveData={linkedObjectiveData}
									setLinkObjectiveOpen={setLinkObjectiveOpen}
									linkSelected={linkSelected}
									selectedLink={goalsAttribute.linkedObjective ? goalsAttribute.linkedObjective.objectiveId : ''}
									t={t}
									linkObjectiveLoading={linkObjectiveLoading}
									linkObjectiveUserOpen={linkObjectiveUserOpen}
									setLinkObjectiveUserOpen={setLinkObjectiveUserOpen}
									linkObjectiveUserAnchorEl={linkObjectiveUserAnchorEl}
									setLinkObjectiveUserAnchorEl={setLinkObjectiveUserAnchorEl}
									fetchUserObjectiveList={fetchUserObjectiveList}
									scrollTo={scrollTo}
									loggedInUserDetail={loggedInUserDetail}
									isLinkObjectiveInitiated={isLinkObjectiveInitiated}
									setIsLinkObjectiveInitiated={setIsLinkObjectiveInitiated}
									selectedCycleDetails={selectedCycleDetails}
								></LinkObjectivePopover>
							</Box>
						)}

						<Box className='drawer-input-field helperText-bottom' id='obj-name'>
							<TextField
								id='objective-field'
								label={t('objectiveName')}
								placeholder={t('giveNameGoalAttributeText')}
								InputProps={{ 'aria-label': t('objective'), disableUnderline: true, autoFocus: true }}
								fullWidth
								multiline
								rows={2}
								InputLabelProps={{
									shrink: true,
								}}
								error={(goalAttrDateError.type === 'objectiveName' && goalAttrDateError?.error) || ''}
								helperText={
									(goalAttrDateError.type === 'objectiveName' && goalAttrDateError?.helperText && (
										<>
											<ErrorIcon />
											{goalAttrDateError?.helperText}
										</>
									)) ||
									''
								}
								value={goalsAttribute.objectiveName}
								onChange={(event: any) => {
									setIsSaveDisabled(false);
									setGoalsAttribute({ ...goalsAttribute, objectiveName: event.target.value });
								}}
								inputRef={inputRefOkrTitle}
							/>
						</Box>

						{okrMasterData && okrMasterData.okrNature && okrMasterData.okrNature.length > 0 && (
							<Box className='drawer-input-field'>
								<Box className='okr-assign-btn-grp helperText-bottom'>
									<Box className='assigned-btn'>
										<FormLabel component='legend'>{t('natureOfOkr')}</FormLabel>
									</Box>
									<ButtonGroup className='kr-btn-group' disableElevation variant='contained' color='primary'>
										{okrMasterData.okrNature.map((item: any, index: any) => (
											<OKRButton
												icon={item.okrNatureId === 1 ? <AspirationalIcon /> : <CommittedIcon />}
												text={item.name}
												title={item.description}
												handleClick={() => handleNatureOfOkr(item.okrNatureId)}
												className={`${item.okrNatureId === nature ? 'active' : ''}`}
												id={item.okrNatureId === 1 ? 'aspirational-btn' : 'committed-btn'}
												tooltipClassName='tooltip-layout4'
											/>
										))}
									</ButtonGroup>
									{goalAttrDateError.type === 'natureError' && (
										<span className='error-field'>
											<ErrorIcon />
											{goalAttrDateError.error}
										</span>
									)}
								</Box>
							</Box>
						)}
						{nature !== 0 || loggedInUserDetail.isTeamLeader || isCoachAccess ? (
							<Box className='drawer-input-field'>
								{/* Creation flow and if its user okr */}
								{(loggedInUserDetail.isTeamLeader || isCoachAccess) &&
								(isNewOkr ||
									goalsAttribute.goalStatusId === 1 ||
									goalsAttribute.owner === loggedInUserDetail.employeeId ||
									goalsAttribute.isCoach) &&
								(!goalsAttribute.importedId || goalsAttribute.importedId === Enums.ZERO) ? (
									<Box>
										{
											/*!objectiveDetail.isPrivate && (*/
											<Box className='okr-assign-btn-grp '>
												<Box className='assigned-btn helperText-bottom'>
													<FormLabel component='legend' ref={inputRefOkrGoalType}>
														{t('ObjectiveFor')}
													</FormLabel>
													{goalTypes && goalTypes.length ? (
														<Fragment>
															<ButtonGroup
																className='kr-btn-group'
																disableElevation
																variant='contained'
																color='primary'
															>
																{goalTypes.map((item: any) => (
																	<OKRButton
																		className={`teams-btn assign-${item?.name?.toLowerCase()} ${
																			item.goalTypeId === currentGoalId ? 'active' : ''
																		}`}
																		handleClick={(event: any) => {
																			onHandleAssignmentTypeClick(event, item);
																		}}
																		text={item.name}
																		id={item.goalTypeId === Enums.ONE ? 'teamBtn' : 'individualBtn'}
																		icon={item.goalTypeId === Enums.ONE ? <TeamIcon /> : <IndividualIcon />}
																		title={
																			!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																				? item.goalTypeId === Enums.ONE
																					? t('teamsToggleBtn')
																					: t('indvToggleBtn')
																				: ''
																		}
																		disabled={
																			!goalsAttribute.isCoachCreated
																				? !(
																						!goalsAttribute?.goalObjectiveId ||
																						(goalsAttribute.createdBy === loggedInUserDetail.employeeId &&
																							!goalsAttribute.importedId)
																				  )
																				: item.goalTypeId !== Enums.ONE
																		}
																		tooltipClassName='tooltip-layout4'
																	/>
																))}
															</ButtonGroup>
															{goalAttrDateError.type === 'objectiveTypeError' && (
																<span className='error-field'>
																	<ErrorIcon />
																	{goalAttrDateError.error}
																</span>
															)}
														</Fragment>
													) : (
														<></>
													)}
													{!showTeamsOkr &&
													goalsAttribute.goalTypeId === 1 &&
													(selectedTeams === null || selectedTeams.length === 0) ? (
														<span className='error-field'>
															<ErrorIcon />
															{t('selectTeam')}
														</span>
													) : (
														<></>
													)}
												</Box>
											</Box>
											/*)*/
										}
									</Box>
								) : (
									<></>
								)}

								{/* if Contributor */}
								{goalDetail?.actionType === 'editGoal' &&
									goalsAttribute.importedId > 0 &&
									goalsAttribute?.goalTypeId !== 2 && (
										<Box>
											{
												<Box className='okr-assign-btn-grp'>
													<Box className='assigned-btn helperText-bottom'>
														<FormLabel component='legend' ref={inputRefOkrGoalType}>
															{t('objectiveTypeLabel')}
														</FormLabel>
														{goalTypes && goalTypes.length ? (
															<Fragment>
																<ButtonGroup
																	className='kr-btn-group'
																	disableElevation
																	variant='contained'
																	color='primary'
																>
																	{goalTypes.map((item: any) => (
																		<OKRButton
																			className={`teams-btn assign-${item?.name?.toLowerCase()} ${
																				item.goalTypeId === currentGoalId ? 'active' : ''
																			}`}
																			handleClick={(event: any) => {
																				onHandleAssignmentTypeClick(event, item);
																			}}
																			text={item.name}
																			id={item.goalTypeId === Enums.ONE ? 'teamBtn' : 'individualBtn'}
																			icon={item.goalTypeId === Enums.ONE ? <TeamIcon /> : <IndividualIcon />}
																			title={
																				!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																					? item.goalTypeId === Enums.ONE
																						? t('teamsToggleBtn')
																						: t('indvToggleBtn')
																					: ''
																			}
																			disabled={
																				!(
																					!goalsAttribute?.goalObjectiveId ||
																					(goalsAttribute.createdBy === loggedInUserDetail.employeeId &&
																						!goalsAttribute.importedId)
																				)
																			}
																			tooltipClassName='tooltip-layout4'
																		/>
																	))}
																</ButtonGroup>
																{goalAttrDateError.type === 'objectiveTypeError' && (
																	<span className='error-field'>
																		<ErrorIcon />
																		{goalAttrDateError.error}
																	</span>
																)}
															</Fragment>
														) : (
															<></>
														)}
														{goalsAttribute.goalTypeId === 1 &&
														(selectedTeams === null || selectedTeams.length === 0) ? (
															<span className='error-field'>
																<ErrorIcon />
																{t('selectTeam')}
															</span>
														) : (
															<></>
														)}
													</Box>
												</Box>
											}
										</Box>
									)}
								<TeamsOkr
									{...props}
									open={showTeamsOkr}
									setOpen={setShowTeamsOkr}
									treeNodesData={treeNodesData}
									t={t}
									chartType={'okr'}
									setSelectedTeams={checkTeamUpdated}
									selectedTeams={selectedTeams}
									showChips={showChips}
									setShowChips={setShowChips}
									teamsLoading={teamsLoading}
									editEnabled={
										!goalsAttribute?.goalObjectiveId ||
										(goalsAttribute.createdBy === loggedInUserDetail.employeeId && !goalsAttribute.importedId) ||
										goalsAttribute.isCoachCreated
									}
									callTreeNodes={callTreeNodes}
									setDeletedNodes={setDeletedNodes}
									teamDetails={teamDetails}
									setCallOut={setCallOut}
									anchorElCallouts={anchorElCallouts}
									setAnchorElCallouts={setAnchorElCallouts}
									initialCallout={initialCallout}
									otherTeams={otherTeams}
									myTeams={myTeams}
								></TeamsOkr>
							</Box>
						) : (
							<></>
						)}

						<Box>
							{selectedTeams.length > 0 &&
								selectedTeams[0].organisationHead !== loggedInUserDetail.employeeId &&
								!goalsAttribute.importedId && (
									<Box className='drawer-input-field owner-box'>
										{
											/*!objectiveDetail.isPrivate && (*/
											<Box className='okr-assign-btn-grp'>
												<Box className='assigned-btn'>
													<FormLabel component='legend' ref={inputRefOkrOwner}>
														{!selectedTeams[0].organisationHead ? t('withoutOwnerText') : t('behalfOwner')}
													</FormLabel>
													<Box className='owner-main'>
														<Box className='owner-details'>
															<Fragment>
																<Box
																	className={`teams-listing ${
																		goalsAttribute.goalTypeId === 1 && selectedTeams.length > 0 ? 'dropdown-active' : ''
																	}`}
																>
																	<Box className='user-info'>
																		<Box className='user-img'>
																			{selectedTeams[0].leaderImagePath ? (
																				<Avatar
																					className='avatar-default'
																					alt={`${selectedTeams[0].leaderFirstName} ${selectedTeams[0].leaderLastName}`}
																					src={selectedTeams[0].leaderImagePath}
																				/>
																			) : (
																				<Avatar
																					style={{
																						backgroundColor: getRandomBackgroundColor(
																							selectedTeams[0].backGroundColorCode
																						),
																						color: getRandomColor(selectedTeams[0].colorCode),
																					}}
																				>
																					{getUserName({
																						firstName: selectedTeams[0].leaderFirstName,
																						lastName: selectedTeams[0].leaderLastName,
																						fullName: '',
																					})}
																				</Avatar>
																			)}
																		</Box>
																		<Box className='user-info-details'>
																			<Typography variant='h4'>
																				{selectedTeams[0].leaderFirstName} {selectedTeams[0].leaderLastName}
																			</Typography>
																			<Typography variant='subtitle2'>{selectedTeams[0].leaderDesignation}</Typography>
																		</Box>
																	</Box>
																</Box>
															</Fragment>
														</Box>
													</Box>
												</Box>
											</Box>
										}
									</Box>
								)}
						</Box>
						<Box className='drawer-input-field drawer-date-field helperText-bottom'>
							<FormLabel component='legend'>{t('setATimeframe')}</FormLabel>
							<ReactDateRangePicker
								type={'GoalAttribute'}
								currentCycleEndDate={currentCycleEndDate}
								durationCycleDetail={durationCycleDetail}
								startDateValue={goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate}
								endDateValue={goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate}
								minimumDate={
									selectedCycleDetails?.cycleStatus === Enums.CURRENT_CYCLE_ID
										? new Date()
										: selectedCycleDetails?.startDate
								}
								maximumDate={selectedCycleDetails?.endDate}
								handleDateSelection={(
									range: any,
									start: any,
									end: any,
									selectionType: String,
									isCycleChanged: Boolean,
									selectedQuarterData: any
								) => {
									dateChangeHandler(range, start, end, selectionType, isCycleChanged, selectedQuarterData);
								}}
								bottomChildComponent={<></>}
								allCycleDetails={allCycleDetails}
								selectedCycleDetails={selectedCycleDetails}
								setSelectedCycleDetails={setSelectedCycleDetails}
								isCurrentCycle={isCurrentCycle}
								isCycleVisible={true}
								numberOfCalendars={Enums.TWO}
								selectionType={selectionType}
								selectedDateRange={selectedDateRange}
								setSelectedDateRange={setSelectedDateRange}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								// isCycleDisable={false}
								isCycleDisable={goalsAttribute?.importedId > 0 ? true : false}
								resToUseInCalendar={resToUseInCalendar}
								editModee={editModee}
								setEditModee={setEditModee}
								showBottomText={true}
							/>
						</Box>
					</form>
					{modalProps && modalProps.open && (
						<AlertDialog
							module={modalProps.type === 'contibutor' ? 'organization' : 'goalAttribute'}
							message={modalProps.message}
							handleCloseModal={handleCloseModal}
							modalOpen={modalProps.open}
						/>
					)}
				</Box>
			</Box>
			{showCallouts && (
				<CallOutTooltip
					open={callOut.open}
					anchorEl={anchorElCallouts}
					setAnchorEl={setAnchorElCallouts}
					setCallOut={setCallOut}
					text={t(callOut.text)}
					placement={callOut.placement}
					classname={callOut.classname}
					handlePrev={() => prevCallout(callOut.currentCalloutIndex)}
					handleNext={() => nextCallout(callOut.currentCalloutIndex)}
					showNext={callOut.next}
					showPrevious={callOut.prev}
				/>
			)}
		</Fragment>
	);
};

import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import AppRoutes from './config/routes';
import ResponsiveImg from '../src/images/responsive-img.svg';
import './styles/syncfusion.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/azureAuthConfig';
// import { GAinitialize, GApageView } from './config/utils';
import './styles/base.scss';
import AlertDialog from './components/Common/Dialog';
import { clearLocalStorage } from './services/StorageService';
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

const msalInstance = new PublicClientApplication(msalConfig);
// GAinitialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
const App = (props) => {
	const isSessionExpire = useSelector((state) => state?.commonReducer?.isSessionExpire);
	// useEffect(() => {
	// 	GApageView(window.location.pathname + window.location.search);
	// 	//console.log = console.warn = console.error = () => {};
	// }, []);
	useEffect(() => {
		if (window.location.origin.includes('.unlockokr.com')) {
			window.location.replace(window.location.origin.replaceAll('.unlockokr.com', '.unlocku.com'));
		}
	}, []);
	const theme = createTheme({
		typography: {
			fontFamily: ['Lato', 'sans-serif'].join(','),
		},
	});
	const { t } = useTranslation();

	const handleCloseModal = (e, _type) => {
		e.preventDefault();
		e.stopPropagation();
		reloadApplication();
	};

	const reloadApplication = () => {
		clearLocalStorage();
		sessionStorage.clear();
		window.location.href = window.location.origin;
	};

	return (
		<Fragment>
			<MsalProvider instance={msalInstance}>
				<ThemeProvider theme={theme}>
					{!(window !== window.parent && !window.opener) && <AppRoutes {...props} t={t} />}

					<div className='screenlock-message'>
						<Box className='screenlock-img'>
							<img src={ResponsiveImg} alt='responsive' />
						</Box>
						<Typography>
							Oops! We are not compatible with mobile or tablet devices yet. We will get there soon.
							<br />
							However, if you are observing this issue in your desktop or laptop, then it could be because your browser
							zoom setting may have got expanded to more than what the current resolution is.
						</Typography>

						<Typography>
							We recommend using desktop or laptops with display settings set to greater than 1024 pixels.
						</Typography>
					</div>
				</ThemeProvider>
			</MsalProvider>
			{isSessionExpire && (
				<AlertDialog
					module='relogin'
					message={t('reloginMessage')}
					handleCloseModal={handleCloseModal}
					modalOpen={isSessionExpire}
				/>
			)}
		</Fragment>
	);
};

export default App;

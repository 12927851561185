import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
	Button,
	MenuList,
	MenuItem,
	ListItemIcon,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	ListItemText,
} from '@material-ui/core';
import { GiveFeedbackIcon, AskFeedbackIcon, RequestOneOnOneIcon, ViewFeedback } from './../../config/svg/PersonalFeedbackSvg';
import { setLocalStorageItem } from '../../services/StorageService';
import { DownFilledArrow } from '../../config/svg/ArrowSvg';
import PersonalFeedbackDrawer from './PersonalFeedbackDrawer';
import AlertDialog from '../Common/Dialog';
import { AlignmentMapIcon, NudgeTeamIcon, ViewPeopleOkrIcon } from '../../config/svg/CommonSvg';
import { Enums } from '../../config/enums';
import { getCurrentUserDetails } from '../../config/utils';
import { ALIGNMENTMAPS, PEOPLEMAPS, USER_VIEW } from '../../config/app-url';

export const PersonalFeedbackAction: React.FC<any> = (props) => {
	const { loggedInUser, feedbackRedirection, showPersonalFeedback, resetPFData, setFeedbackData } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const currentUser = getCurrentUserDetails();
	const [anchorEl, setAnchorEl] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const open = Boolean(anchorEl);
	const [drawer, setDrawer] = useState({
		viewPersonalFeedback: false,
		askPersonalFeedback: false,
		givePersonalFeedback: false,
		requestOneOnOne: false,
	});
	const handlePopoverOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const openDrawer = (e: any, type: any) => {
		handlePopoverClose();

		switch (type) {
			case 'viewPersonalFeedback':
				setDrawer({
					viewPersonalFeedback: true,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				break;
			case 'askPersonalFeedback':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: true,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				break;
			case 'givePersonalFeedback':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: true,
					requestOneOnOne: false,
				});
				break;
				case 'peopleViewFeedback':
				setDrawer({
					viewPersonalFeedback: true,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				break;
			case 'requestOneOnOne':
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: true,
				});
				Boolean(currentUser) && currentUser.employeeId && setModalOpen(true);
				break;
			default:
				setDrawer({
					viewPersonalFeedback: false,
					askPersonalFeedback: false,
					givePersonalFeedback: false,
					requestOneOnOne: false,
				});
				resetPersonalFeedbackData();
				break;
		}
	};
	/** request 1:1 api calling */
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		e.stopPropagation();
		const { userDetail } = props;
		if (type === 1) {
			const data = {
				requestType: 4,
				requestedTo: [Boolean(currentUser) && currentUser.employeeId],
				requestedFrom: userDetail.employeeId,
				onetoOneRemark: t('thankyouFeedback'),
			};
			setModalOpen(false);
			props
				.requestOneToOnePersonalFeedback(data)
				.then((response: any) => {
					if (Boolean(response) && response.data && response.data.status === 200) {
						enqueueSnackbar(t('requestRaisedSuccessfully'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					} else {
						const responseAPI = Boolean(response) && response.data && response.data.messageList;
						const keys: any = Boolean(responseAPI) && Object.keys(responseAPI);
						const messages = Boolean(keys) && keys.length && keys.map((item: any) => responseAPI[item]);
						enqueueSnackbar(messages, {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				})
				.catch((error: any) => {
					enqueueSnackbar(t('errorRaisingRequest'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		}
		setModalOpen(false);
		resetPersonalFeedbackData();
	};
	const resetPersonalFeedbackData = () => {
		Boolean(showPersonalFeedback) && resetPFData();
	};

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 2000,
		});
	};

	const nudgePerson = () => {
		const { employeeId } = currentUser;
		props
			.updateNudgePerson(`empId=${employeeId}`)
			.then((response: any) => {
				if (response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data;
					if (messageList) {
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						showApiMsgs(messages, 'success');
					}
				} else {
					const { messageList } = response?.data;
					if (messageList) {
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						showApiMsgs(messages, 'error');
					}
				}
			})
			.catch((_error: any) => {
				showApiMsgs(t('internalServerError'), 'error');
			});
	};

	const redirectViewOkr = async (employeeId: Number, tabName: string) => {
		props.resetOkr();
		const data = 'empId=' + employeeId;
		const resp = await props.getUserById(data);
		if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
			if (resp.data.entityList) {
				setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList));
			}
			setLocalStorageItem(
				'backUrl',
				JSON.stringify({
					url: props.panelUsed || ALIGNMENTMAPS,
					tabToOpen: tabName,
				})
			);
			props.history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
		}
	};

	return (
		<Fragment>
			{!feedbackRedirection && (
				<Fragment>
					<Button onClick={handlePopoverOpen} className='people-feedback-btn' id='feedback-more-btn'>
						{t('more')}
						<DownFilledArrow />
					</Button>

					<Popper
						className='popper-menu-list people-feedback-menu'
						open={open}
						anchorEl={anchorEl}
						transition
						placement='bottom-end'
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handlePopoverClose}>
										<MenuList>
											{props.peopleAlignmentMap && [
												currentUser && currentUser.objectivesCount > 0 && currentUser.keyResultCount > 0 && (
													<MenuItem
														id='ppl-view-okr-btn'
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setAnchorEl(null);
															redirectViewOkr(currentUser.employeeId, 'directReport');
														}}
													>
														<ListItemIcon>
															<ViewPeopleOkrIcon />
														</ListItemIcon>
														<ListItemText primary={t('viewOkr')} />
													</MenuItem>
												),
												props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && (
													<MenuItem
														id='ppl-nudge-person-btn'
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setAnchorEl(null);
															nudgePerson();
														}}
													>
														<ListItemIcon>
															<NudgeTeamIcon />
														</ListItemIcon>
														<ListItemText primary={t('nudgePerson')} />
													</MenuItem>
												),
											]}
											{!props.peopleAlignmentMap && (
												<MenuItem
													id='ppl-alignmentMaps'
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														setLocalStorageItem(
															'backUrlAlignmentMaps',
															JSON.stringify({
																url: USER_VIEW,
																tabToOpen: 'peopleView',
																isPeopleView: true,
															})
														);
														props.history.push(PEOPLEMAPS);
														props.updateRoute(PEOPLEMAPS);
													}}
												>
													<ListItemIcon>
														<AlignmentMapIcon />
													</ListItemIcon>
													<ListItemText primary={t('viewAlignmentMaps')} />
												</MenuItem>
											)}
											<MenuItem id='ppl-ask-pf-btn' onClick={(e) => openDrawer(e, 'askPersonalFeedback')}>
												<ListItemIcon>
													<AskFeedbackIcon />
												</ListItemIcon>
												<ListItemText primary={t('askFeedbackPF')} />
											</MenuItem>
											<MenuItem id='ppl-give-pf-btn' onClick={(e) => openDrawer(e, 'givePersonalFeedback')}>
												<ListItemIcon>
													<GiveFeedbackIcon />
												</ListItemIcon>
												<ListItemText primary={t('giveFeedbackPF')} />
											</MenuItem>
											{(currentUser && props.listOkrResult?.isReportingTo) && (
											<MenuItem id='ppl-req-one-btn' onClick={(e) => openDrawer(e, 'peopleViewFeedback')}>
												<ListItemIcon>
												<ViewFeedback />
												</ListItemIcon>
												<ListItemText primary={t('viewFeedbackPF')} />
											</MenuItem>)}
											
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Fragment>
			)}
			{drawer.viewPersonalFeedback && (
				<PersonalFeedbackDrawer
					{...props}
					type={'viewPersonalFeedback'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.viewPersonalFeedback}
					feedbackData={props.feedbackData}
					showPersonalFeedback={showPersonalFeedback}
					setFeedbackData={setFeedbackData}
	                currentUser={currentUser}
						 
				/>
			)}
			{drawer.askPersonalFeedback && (
				<PersonalFeedbackDrawer
					{...props}
					type={'askPersonalFeedback'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.askPersonalFeedback}
					loggedInUser={loggedInUser}
					currentUser={currentUser}
					showPersonalFeedback={showPersonalFeedback}
					setFeedbackData={setFeedbackData}
				/>
			)}
			{drawer.givePersonalFeedback && (
				<PersonalFeedbackDrawer
					{...props}
					type={'givePersonalFeedback'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.givePersonalFeedback}
					currentUser={currentUser}
					feedbackData={props.feedbackData}
					showPersonalFeedback={showPersonalFeedback}
					setFeedbackData={setFeedbackData}
				/>
			)}
			{drawer.requestOneOnOne && !Boolean(currentUser) && (
				<PersonalFeedbackDrawer
					{...props}
					type={'requestOneOnOne'}
					openDrawer={openDrawer}
					setDrawer={setDrawer}
					open={drawer.requestOneOnOne}
					currentUser={currentUser}
					showPersonalFeedback={showPersonalFeedback}
					setFeedbackData={setFeedbackData}
				/>
			)}
			{Boolean(currentUser) && currentUser.employeeId && drawer.requestOneOnOne && modalOpen && (
				<AlertDialog
					module='oneToOne'
					message={t('requestOnToOneMsg')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { Box } from '@material-ui/core';
import { Header } from './Header';
import { ReportTabPanel } from './HeaderTabs';
import { useTranslation } from 'react-i18next';
import '../../styles/pages/reports/reportLayout.scss';
import MyTeamsProgress from './MyTeamProgress';
import OrgReports from './OrgReports';
import TeamReports from './TeamReports';
import MyEngagementReport from './MyEngagementReport';
import NoReports from './NoReports';
import { Enums } from '../../config/enums';
// import { ReportsPart2 } from './ReportsPart2';

const Reports = (props: any) => {
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const [tabSelectedTeam, setTabSelectedTeam] = useState<any>(0);
	const { t } = useTranslation();
	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};
	const [isReportDataAvailable, setIsReportDataAvailable] = useState<boolean>(true);

	useEffect(() => {
		return () => {
			props.resetLeadingTeam();
		};
	}, []);

	return (
		<>
			{isReportDataAvailable && props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID ? (
				<Fragment>
					<Header
						tabSelected={tabSelected}
						setTabSelectedTeam={setTabSelectedTeam}
						handleTabChange={handleTabChange}
						{...props}
					/>
					<Box className='page-content-wrapper'>
						<Box className='okr-reports-area'>
							<ReportTabPanel value={tabSelected} index={0}>
								<MyTeamsProgress {...props} setIsReportDataAvailable={setIsReportDataAvailable} />
								<MyEngagementReport
									{...props}
									headTitle={t('myEngagementReportLabel')}
									headTitleTooltip={t('engagementProgressReportTooltipText')}
									color1={'#6C5BFA'}
									color2={'#8FC3F6'}
									color3={'#FA989D'}
									isEngagement={true}
								/>
							</ReportTabPanel>
							<ReportTabPanel value={tabSelected} index={1}>
								<TeamReports
									{...props}
									tabSelectedTeam={tabSelectedTeam}
									setIsReportDataAvailable={setIsReportDataAvailable}
								/>
							</ReportTabPanel>
							<ReportTabPanel value={tabSelected} index={2}>
								<OrgReports {...props} />
							</ReportTabPanel>
							{/* <ReportTabPanel value={tabSelected} index={3}>
								<ReportsPart2 {...props} />
							</ReportTabPanel> */}
						</Box>
					</Box>
				</Fragment>
			) : (
				<NoReports t={t} />
			)}
		</>
	);
};

export default Reports;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, List, ListItem, TextField, Typography } from '@material-ui/core';
import { OKRButton } from '../../../Common/OKRButton';
import { DeleteIcon } from '../../../../config/svg/CommonSvg';
import { CheckedIcon, BorderCheckboxIcon } from '../../../../config/svg/formElementIcons';
import { CancelIcon, TickIcon } from '../../../../config/svg/Action';
import { NoRecord } from '../NoRecord';
import NotesImg from '../../../../images/no-found-notes.svg';
import AlertDialog from '../../../Common/Dialog';
import { getCurrentUserDetails, getTime } from '../../../../config/utils';
import { AddText } from '../AddText';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../../services/StorageService';
import { Enums } from '../../../../config/enums';

export const Tasks: React.FC<any> = (props) => {
	const {
		setIsCfrUpdated,
		getTasks,
		type,
		okr,
		kr,
		tabSelected,
		taskStatus,
		tasks,
		setTaskCount,
		setLoader,
		postNewTask,
		showApiMsgs,
		taskCount,
		setIsTaskEdited,
		isTaskEdited,
		setShowDialog,
		isPastCycle,
	} = props;
	const { t } = useTranslation();
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
	const [deleteSelected, setDeleteSelected] = React.useState<any>({});
	const [allTasks, setAllTasks] = React.useState<any>([]);
	const [taskText, setTaskText] = React.useState<string>('');
	const currentUser = getCurrentUserDetails();

	React.useEffect(() => {
		if (isTaskEdited) {
			setShowDialog(true);
			setTaskText('');
		}
	}, [taskText]);

	const handleListClick = (e: any) => {
		setShowDialog(true);
	};

	React.useEffect(() => {
		return () => {
			props.resetTasks();
		};
	}, []);

	React.useEffect(() => {
		if (tabSelected === 2 && taskStatus !== 'success') {
			getAllTask();
		}
	}, [tabSelected]);

	React.useEffect(() => {
		if (taskStatus === 'pending') {
			setLoader(true);
		} else {
			setLoader(false);
		}
	}, [taskStatus]);

	React.useEffect(() => {
		if (tasks && tasks.length) {
			setTaskCount({
				...taskCount,
				totalTask: tasks.length,
				taskCompleted: tasks.filter((item: any) => item.isCompleted).length,
			});
			let addHisotryKey = tasks.map((item: any) => {
				return {
					...item,
					history: item.taskName,
				};
			});
			setAllTasks(addHisotryKey);
			setIsTaskEdited(false);
		} else if (!tasks && taskStatus === 'success') {
			setTaskCount({
				...taskCount,
				totalTask: 0,
				taskCompleted: 0,
			});
			setAllTasks([]);
			setIsTaskEdited(false);
		}
	}, [tasks]);

	const handleCancelTask = () => {
		setTaskText('');
		setIsTaskEdited(false);
	};

	const handleCompleteTask = async (e: any, task: any) => {
		const currentTasks = [...allTasks];
		const { checked } = e.target;

		let updateTask = currentTasks.map((item: any) => {
			if (item.taskId === task.taskId) {
				return {
					...item,
					isCompleted: checked,
					isUpdatingMode: true,
				};
			} else {
				return {
					...item,
				};
			}
		});

		let postData = { taskId: task.taskId, isCompleted: checked };
		setLoader(true);
		setAllTasks(updateTask);
		const taskCompleteResponse = await props.taskCompleted(postData);
		if (Boolean(taskCompleteResponse) && taskCompleteResponse.data.status === 200) {
			showApiMsgs(t('taskUpdate'), 'success');
			setIsCfrUpdated(true);
			getAllTask();
		}
	};

	const canEditOrModify = () => {
		if (currentUser) {
			return false;
		}
		return true;
	};
	const handleTaskUpdate = async (task: any) => {
		let tasksCopy = [...allTasks];
		let data = { taskId: task.taskId, taskName: task?.taskName.trim() };
		setLoader(true);
		let updateTaskResponse = await props.updateTask(data);
		if (Boolean(updateTaskResponse) && updateTaskResponse.data.status === 200) {
			showApiMsgs(t('taskUpdate'), 'success');
			getAllTask();
			let updateTask = tasksCopy.map((item: any) => {
				if (item.taskId === task.taskId) {
					item.isEditMode = false;
				}
				return item;
			});
			setAllTasks(updateTask);
		} else {
			const responseAPI = updateTaskResponse.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages} `, 'error');
			setLoader(false);
		}
	};

	const handleTaskOnEnter = (e: any, task: any) => {
		if (e.keyCode === 13 && task && task.taskName.trim() !== '') {
			handleTaskUpdate(task);
		}
	};

	const handleTaskChange = (e: any, index: number) => {
		const tasksCopy = [...allTasks];
		const { value } = e.target;
		tasksCopy[index].taskName = value;
		tasksCopy[index].isEditMode = true;
		tasksCopy.map((task: any) => {
			if (!task.isEditMode) {
				task.disabled = true;
			}
		});
		setAllTasks(tasksCopy);
		setIsTaskEdited(true);
	};

	const handleCancelUpdate = (e: any, index: number) => {
		const tasksCopy = [...allTasks];
		tasksCopy[index].taskName = tasksCopy[index].history;
		tasksCopy[index].isEditMode = false;
		tasksCopy[index].openEditMode = false;
		tasksCopy.map((task: any) => {
			task.disabled = false;
		});
		setAllTasks(tasksCopy);
		setIsTaskEdited(false);
	};

	const handleAddNewTask = async () => {
		let newTask = {
			goalTypeId: type,
			goalId: type === 1 ? okr.goalObjectiveId : kr.goalKeyId,
			taskName: taskText,
			isCompleted: false,
		};
		setLoader(true);
		const response = await postNewTask(newTask);
		if (Boolean(response) && response.data.status === 200) {
			showApiMsgs(t('taskAdded'), 'success');
			setTaskText('');
			getAllTask();
			setIsCfrUpdated(true);
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages} `, 'error');
			setLoader(false);
		}
	};

	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			handleConfirmClick();
		}
		setOpenDeleteDialog(!openDeleteDialog);
	};

	const handleConfirmClick = async () => {
		let data = `taskId=${deleteSelected.taskId}`;
		setLoader(true);
		const deleteTaskResponse = await props.deleteTask(data);
		if (Boolean(deleteTaskResponse) && deleteTaskResponse.data.status === 200) {
			setDeleteSelected({});
			setIsCfrUpdated(true);
			setOpenDeleteDialog(!openDeleteDialog);
			showApiMsgs(t('taskDeleted'), 'success');
			getAllTask();
		} else {
			showApiMsgs(t('someErrorOccurred'), 'error');
		}
	};

	const getAllTask = () => {
		let data = {};
		if (type === 1) {
			data = `goalId=${okr.goalObjectiveId}&goalTypeId=${type}`;
		} else if (type === 2) {
			data = `goalId=${kr.goalKeyId}&goalTypeId=${type}`;
		}
		getTasks(data);
	};

	const handleDeleteTask = (e: any, task: any) => {
		setDeleteSelected(task);
		setOpenDeleteDialog(!openDeleteDialog);
	};

	const handleEditMode = (e: any, index: number) => {
		const tasksCopy = [...allTasks];
		if (!tasksCopy[index].isCompleted) {
			tasksCopy[index].openEditMode = true;
			setAllTasks(tasksCopy);
		}
	};

	const handleBlurTask = (e: any, index: any) => {
		const tasksCopy = [...allTasks];
		if (tasksCopy[index].openEditMode && !tasksCopy[index].isEditMode) {
			tasksCopy[index].openEditMode = false;
		}
		setAllTasks(tasksCopy);
	};

	const handleEnterClick = (e: any) => {
		if (e.keyCode === 13 && taskText !== '') {
			handleAddNewTask();
		}
	};

	return (
		<>
			<Box className='cfr-tab-inner-head'>
				{canEditOrModify() && !isPastCycle ? (
					<>
						<AddText
							{...props}
							setId={'tasks-tab'}
							handleSave={handleAddNewTask}
							handleCancel={handleCancelTask}
							showAvatar={false}
							placeholderText={t('addTaskPlaceholdarText')}
							setEditorText={setTaskText}
							editorText={taskText}
							showEditor={false}
							setShowEditor={() => {}}
							maxLength={150}
							isAdded={true}
							handleEnterClick={handleEnterClick}
						/>
						<Box className='task-help-text'>
							<Typography className='helper-text'>
								<strong>{t('note')}</strong>
								{t('tasksHelpText')}
							</Typography>
						</Box>
					</>
				) : isPastCycle ? (
					<Box className='task-help-text'>
						<Typography className='helper-text'>{t('tasksNotPermitted')}</Typography>
					</Box>
				) : null}

				<Box className='count-head'>
					<Typography variant='body2'>
						{taskCount.totalTask === 0
							? t('zeroTask')
							: t('taskCompletedText', { currentTask: taskCount.taskCompleted, totalTask: taskCount.totalTask })}
					</Typography>
				</Box>
			</Box>
			{allTasks && allTasks.length > 0 && (
				<Box className='tab-panel-inner'>
					<List className='task-lists'>
						{taskText && <Box className='overlayTask' onClick={handleListClick}></Box>}
						{allTasks.map((task: any, index: number) => (
							<ListItem key={index} className={task.isCompleted ? 'completed-task' : task.disabled ? 'disabled' : ''}>
								<Box
									className={`task-box ${
										!canEditOrModify() ? 'task-view-only' : task.isEditMode ? 'task-is-active' : ''
									}`}
								>
									<Box className={`task-text-field ${!isPastCycle && 'quick-hover'}`}>
										{!isPastCycle && (
											<Checkbox
												className='task-checkbox'
												icon={<BorderCheckboxIcon />}
												checkedIcon={<CheckedIcon />}
												aria-label='Task Checkbox'
												checked={task.isCompleted}
												disabled={!canEditOrModify() || task.isEditMode || task.disabled}
												onChange={(e) => handleCompleteTask(e, task)}
											/>
										)}
										{!task.openEditMode && (
											<Typography onClick={(e) => handleEditMode(e, index)}>{task.taskName}</Typography>
										)}
										{task.openEditMode && (
											<>
												{!isPastCycle ? (
													<TextField
														disabled={!canEditOrModify() || task.isCompleted}
														onChange={(e) => handleTaskChange(e, index)}
														value={task.taskName}
														autoFocus
														multiline
														rows={2}
														inputProps={{ maxLength: 150 }}
														onBlur={(e) => handleBlurTask(e, index)}
														onKeyDown={(e) => handleTaskOnEnter(e, task)}
													/>
												) : (
													<Box>
														<Typography>{task.taskName}</Typography>
													</Box>
												)}
												{canEditOrModify() && !isPastCycle ? (
													<Box className='task-edit-actions'>
														<OKRButton
															className='task-edit-cancel'
															id='task-edit-cancel'
															icon={<CancelIcon />}
															handleClick={(e) => {
																handleCancelUpdate(e, index);
															}}
														/>
														<OKRButton
															className='task-edit-save'
															id='task-edit-save'
															icon={<TickIcon />}
															handleClick={(e) => {
																handleTaskUpdate(task);
															}}
														/>
													</Box>
												) : isPastCycle ? (
													<></>
												) : null}
											</>
										)}
									</Box>
									<Box className='timestamp'>
										<Typography variant='body2'>
											{/* {taskStatus === 'success' && */}
											<>
												{task.isCompleted ? (
													<>Completed {task.isUpdatingMode ? '...' : getTime(task.completedDate)}</>
												) : (
													<>Created {getTime(task.createdOn)}</>
												)}
											</>
											{/* } */}
										</Typography>
									</Box>
								</Box>
								{canEditOrModify() && !task.disabled && !isPastCycle ? (
									<OKRButton
										handleClick={(e) => handleDeleteTask(e, task)}
										className='delete-btn'
										icon={<DeleteIcon />}
									/>
								) : isPastCycle ? (
									<></>
								) : null}
							</ListItem>
						))}
					</List>
				</Box>
			)}
			{props.taskStatus === 'success' && allTasks.length === 0 && (
				<Box className='tab-panel-inner no-record-available'>
					<NoRecord
						{...props}
						noRecordHead={t('noTaskHead')}
						noRecordMsg={canEditOrModify() ? t('noTaskMessage') : ''}
						noRecordImg={NotesImg}
						noImgWidth={148}
					/>
				</Box>
			)}

			{openDeleteDialog && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('taskDeleteMessage')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}
		</>
	);
};

import { Box, Chip, Collapse, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import OKRFlag from '../../../images/okrFlag.svg';
import { IndividualSmallIcon, InfoIcon, PrivateIcon } from '../../../config/svg/CommonSvg';
import CircularProgress from '../../OkrAction/CircularProgress';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { Enums } from '../../../config/enums';
import {
	formatDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getTeamFilterQueryString,
	getMinDate,
} from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { SettingsIcon } from '../../../config/svg/MyGoalSvg';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { GoalAttributes } from '../../Goals/CreateObjective/GoalAttributes';
import { getMonthDate } from '../../../config/utils';
import moment from 'moment';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import { ListTooltip } from '../../OkrAction/ListTooltip';
import DatePicker from '../../Common/DatePicker';
import { useSnackbar } from 'notistack';
import KrResultItemCard from './KrResultItemCard';
import UltraCompactObjectiveCard from './UltraCompactObjectiveCard';
import { checkPermission } from '../../../config/rolePermission';
import { editOkrs } from '../../Admin/AdminConstant';
import { keyDetailObject, goalDetailObject } from '../../Common/OkrUtil';

export interface RelaxedCardProps {}

const ObjectiveCard: React.FC<any> = (props: any) => {
	const {
		t,
		currentUser,
		data,
		tabFilter,
		isRelaxedCard,
		currentCycleEndDate,
		handleDeleteOkr,
		goalDetail,
		setGoalDetail,
		fetchOkr,
		listOkrResult,
		isLocked,
		updateGoalDueDate,
		teamOkrFilterData,
	} = props;
	const okr = data.data;
	const [openObjectAttribute, setOpenObjectAttribute] = useState(false);
	// const [showKr, setShowKr] = useState(false);
	const [modalPropsKR, setModalPropsKR] = useState<any>({ open: false, type: '', message: '' });
	const [modalDate, setmodalDate] = useState<any>({ open: false, type: '', message: '' });

	const [objectiveDetail, setObjectiveDetail] = useState<any>({
		...goalDetailObject,
		myGoalsDetails: [{ ...keyDetailObject }],
	});
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	// const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);

	const defaultGaolDateError = { error: '', helperText: '', type: '' };
	const [isSaveDisabled, setIsSaveDisabled] = useState<Boolean>(true);
	const [goalsAttribute, setGoalsAttribute] = useState<any>({});
	const [loader, setLoader] = useState<Boolean>(false);
	const [goalAttrDateError, setGaolAttrDateError] = useState<any>(defaultGaolDateError);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	// const [keyAttribute, setKeyAttribute] = useState<any>({});
	const [keyRes, setKeyRes] = useState<boolean>(false);
	const [dataUpdated, setdataUpdated] = useState<any>({});

	// const year = getLocalStorageItem('currentYear');
	const [krLoader, setKrLoader] = useState<Boolean>(false);
	// const [keyData, setKeyData] = useState<any>({});
	const userDetail: any = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();

	const year = getLocalStorageItem('currentYear') || null;
	const selectedYear = getLocalStorageItem('year') || year;
	const cycleId = getLocalStorageItem('cycleId') || 0;

	const handleCloseObjectiveAttribute = async (event: any, type: String) => {
		// setShowKr(false);
		setGaolAttrDateError(defaultGaolDateError);
		if (type === 'save') {
			setSnackbarProps({});
			const datesDifferenceInDays = moment(
				new Date(goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate || new Date())
			).isAfter(
				new Date(goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate || new Date()),
				'days'
			);
			if (Boolean(datesDifferenceInDays)) {
				setGaolAttrDateError({
					error: t('startDateDueDateError'),
					helperText: t('startDateDueDateError'),
					type: 'dueDate',
				});
			} else {
				if (goalDetail && goalDetail.showAttributeDrawer && goalDetail.actionType === 'editGoal') {
					if (validGoalAttributeAlignmentForm(goalsAttribute)) {
						setKrLoader(true);
						const data = { ...goalsAttribute };
						if (data.linkedObjective && data.linkedObjective.objectiveId && !data.linkedObjectiveId) {
							data.linkedObjectiveId = data.linkedObjective.objectiveId;
						}
						const response = await props.updateGoalAttributes(data);
						if (response && response.data && response.data.status === 200) {
							setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
							setKrLoader(false);
							// setIsSaveDisabled(true);
							setGaolAttrDateError(defaultGaolDateError);
							// setOpenDrawer(false);
							setOpenObjectAttribute(false);
							setGoalsAttribute({});
							// setGoalDetail({});
							setdataUpdated({});
							//fetchOkr();
							if (props.tabValue === 0) {
								props.getAlignmentData();
							} else if (props.tabValue === 1) {
								const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
								const data = `empId=${
									userDetail && userDetail.employeeId
								}&cycle=${cycleId}&year=${selectedYear}${teamFilter}`;
								props.fetchTeamsAlignmentMap(data);
							}
						} else if (response && response.data && response.data.status === 400) {
							setKrLoader(false);
							const responseAPI = response.data.messageList;
							const keys = Object.keys(responseAPI);
							const messages = keys.map((item) => responseAPI[item]);
							enqueueSnackbar(`${messages} `, {
								variant: 'error',
								autoHideDuration: 5000,
								TransitionComponent: Collapse,
							});
						}
					}
				} else {
					if (validGoalAttributeAlignmentForm(goalsAttribute)) {
						if (goalsAttribute.teamOkrRequests?.length > 0) {
							objectiveDetail.goalTypeId = 1;
						}
						setObjectiveDetail({
							...objectiveDetail,
							objectiveName: goalsAttribute.objectiveName
								? goalsAttribute.objectiveName
								: objectiveDetail.objectiveName,
							isPrivate:
								goalsAttribute.isPrivate === false
									? goalsAttribute.isPrivate
									: objectiveDetail.isPrivate
									? objectiveDetail.isPrivate
									: goalsAttribute.isPrivate,
							objectiveDescription: goalsAttribute.objectiveDescription
								? goalsAttribute.objectiveDescription
								: objectiveDetail.objectiveDescription,
							startDate: goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate,
							endDate: goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate,
							myGoalsDetails: goalsAttribute.myGoalsDetails
								? goalsAttribute.myGoalsDetails
								: objectiveDetail.myGoalsDetails,
							teamOkrRequests: goalsAttribute.teamOkrRequests
								? goalsAttribute.teamOkrRequests
								: objectiveDetail.teamOkrRequests,
						});
						// setIsSaveDisabled(true);
						setGaolAttrDateError(defaultGaolDateError);
						setOpenObjectAttribute(false);
						setGoalsAttribute({});
					}
				}
			}
		} else if (type === 'close') {
			// setShowKr(false);
			// setGoalDetail({});
			// if (!isGoalAttributeUpdated()) {
			// 	setModalProps({
			// 		open: true,
			// 		type: 'GoalAttr',
			// 		message: t('unSavedItemAlert'),
			// 		module: 'user',
			// 		details: { id: '' },
			// 	});
			// } else {
			setGoalsAttribute({});
			setOpenObjectAttribute(false);
			// }
		}
	};

	const validGoalAttributeAlignmentForm = (formAlignData: any) => {
		if (!formAlignData.objectiveName) {
			setGaolAttrDateError({
				helperText: t('objectiveNameError'),
				type: 'objectiveName',
				error: t('objectiveNameError'),
			});
			return false;
		} else if (formAlignData.objectiveName?.trim() === '') {
			setGaolAttrDateError({
				type: 'objectiveName',
				helperText: t('objectiveNameError'),
				error: t('objectiveNameError'),
			});
			return false;
		}
		return true;
	};

	useEffect(() => {
		setGoalsAttribute(okr);
		// setGoalDetail({ ...goalDetail, actionType: 'editGoal', showAttributeDrawer: true });
	}, []);

	const isTeamArraySame = (goal: any, objective: any) => {
		//This check is here for only 1 team as of now;
		let isSame = true;
		if (goal.teamOkrRequests.length !== objective.teamOkrRequests.length) {
			isSame = false;
		} else if (goal.teamOkrRequests.length === 0 && objective.teamOkrRequests.length === 0) {
			isSame = true;
		} else if (!goal.teamOkrRequests[0].id && objective.teamOkrRequests[0].id) {
			isSame = false;
		} else if (goal.teamOkrRequests[0].id !== objective.teamOkrRequests[0].id) {
			isSame = false;
		}
		return isSame;
	};

	const isGoalAttributeUpdated = (isPopup: boolean = false) => {
		let goalPrev = goalsAttribute;
		let goalNew = objectiveDetail;
		if (isPopup === true) {
			goalNew = dataUpdated.okr;
			if (goalNew.goalTypeId === 1 && goalNew.teamOkrRequests && goalNew.teamOkrRequests.length > 0) {
				goalNew.teamOkrRequests[0].id = goalNew.teamOkrRequests[0].teamId;
			}
		}
		let isUpdated =
			(goalPrev.objectiveDescription && goalNew.objectiveDescription !== goalPrev.objectiveDescription) ||
			goalNew.objectiveName !== goalPrev.objectiveName ||
			goalNew.isPrivate !== goalPrev?.isPrivate ||
			(goalPrev?.owner && goalNew.owner !== goalPrev?.owner);

		if (!isUpdated) {
			if (isPopup === true) {
				isUpdated =
					goalNew?.linkedObjective?.objectiveId !== goalPrev?.linkedObjective?.objectiveId ||
					(goalPrev.startDate && formatDate(goalPrev.startDate) !== formatDate(goalNew.startDate)) ||
					(goalPrev.endDate && formatDate(goalPrev.endDate) !== formatDate(goalNew.endDate));
			} else {
				isUpdated =
					(goalPrev?.linkedObjective && goalNew.linkedObjectiveId !== goalPrev?.linkedObjectiveId) ||
					(goalPrev.startDate && goalNew.startDate !== goalPrev.startDate) ||
					(goalPrev.endDate && goalNew.endDate !== goalPrev.endDate);
			}
		}
		if (!isUpdated && goalPrev.goalTypeId && goalPrev.goalTypeId !== goalNew.goalTypeId) {
			isUpdated =
				goalPrev.goalTypeId !== 1 ||
				(goalPrev.teamOkrRequests !== undefined &&
					goalPrev.teamOkrRequests !== null &&
					goalPrev.teamOkrRequests.length !== 0 &&
					!isTeamArraySame(goalPrev, goalNew));
		} else if (
			!isUpdated &&
			goalPrev.goalTypeId === 1 &&
			goalNew.goalTypeId === 1 &&
			goalPrev.teamOkrRequests !== undefined &&
			goalNew.teamOkrRequests !== undefined
		) {
			isUpdated = !isTeamArraySame(goalPrev, goalNew);
		}
		if (
			(isUpdated || isUpdated === undefined) &&
			!isPopup &&
			(goalPrev.goalTypeId === 2 || (goalPrev.goalTypeId === undefined && goalNew.goalTypeId === 2))
		) {
			isUpdated = true;
		}
		return !isUpdated;
	};

	const openSettingsDrawer = async (okrObj: any) => {
		// let okrs = JSON.parse(JSON.stringify(listOkrResult));
		// setObjectiveDetail({ ...objectiveDetail, owner: okrObj.owner });
		// if (okrs && okrs.myGoalOkrResponses && okrs.myGoalOkrResponses.length) {
		// 	let currentORK = okrs.myGoalOkrResponses.filter((item: any) => item.goalObjectiveId === okrObj.objectiveId);
		// 	let getKR = currentORK[0].myGoalsDetails;

		// 	currentORK[0] = {
		// 		...currentORK[0],
		// 		myGoalsDetails: currentORK[0].myGoalsDetails.map((i: any) => ({
		// 			...i,
		// 			contributors: i.contributors.filter(
		// 				(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
		// 			),
		// 		})),
		// 	};
		Promise.allSettled([props.getOkrDetail(okrObj.objectiveId)]).then((response: any) => {
			const okrData = response[0].value.data.entity;
			let obj = {
				kr: okrData.myGoalsDetails,
				okr: okrData,
				actionType: 'editGoal',
				goalDetail: {},
				showAttributeDrawer: true,
			};
			setGoalsAttribute(okrData);
			setOpenObjectAttribute(true);
			setdataUpdated(obj);
		});

		// }
	};

	const handleCloseModal = async (event: any, type: Number) => {
		if (type === 1) {
			if (modalPropsKR.type === 'updateDateForOKR') {
				setModalPropsKR({ open: false, type: '', message: '', module: '', data: {} });
				updateAlignmentDueDate(modalPropsKR.data.date, modalPropsKR.data.okr);
			} else {
				setModalPropsKR({ open: false, type: '', message: '', module: '', data: {} });
				setKeyRes(false);
			}
		}
		setModalPropsKR({ open: false, type: '', message: '', module: '' });
		setmodalDate({ open: false, type: '', message: '', module: '' });
	};

	const updateAlignmentDueDate = async (dueDate: any, okr: any) => {
		const data = {
			goalType: 1,
			goalId: okr.objectiveId,
			endDate: formatDate(dueDate),
			startDate: formatDate(okr.startDate),
		};
		const response = await props.updateObjetciveDueDate(data);
		if (response && response.data && response.data.status === 200) {
			//fetchOkr();
			if (props.tabValue === 0) {
				props.getAlignmentData();
			} else if (props.tabValue === 1) {
				const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
				const data = `empId=${userDetail && userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}${teamFilter}`;
				props.fetchTeamsAlignmentMap(data);
			}
		} else if (response && response.data && response.data.status === 400) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
		}

		//setmodalDate({ open: true, type: '', message: 'dsdsd', module: '' });
	};

	const getTabFilter = () => {
		if (isRelaxedCard) return 'relaxed';
		return tabFilter;
	};

	return (
		<React.Fragment>
			<Box className={'bg'}>
				{(getTabFilter() === 'compact' || getTabFilter() === 'relaxed') && (
					<Box id={`alignment-card-${okr?.objectiveId}`}>
						<Box
							className={`alignment-card ${
								getTabFilter() === 'compact' && okr?.activeNode !== true
									? 'compact-view no-active'
									: getTabFilter() === 'compact' && okr?.activeNode === true
									? 'relaxed-card relaxed-card-active'
									: 'relaxed-card'
							}`}
						>
							<Box className='objective-section'>
								<Box className='alignment-card-top'>
									<Box className='cardHeader'>
										<Box className='cardHeader-col1'>
											<Box className='okr-nature-type-tag'>
												{okr?.goalNatureId === 1 ? (
													<Typography className='operational'>{t('aspirationalLevel')}</Typography>
												) : (
													<Typography className='aspirational'>{t('operationalLevel')}</Typography>
												)}
											</Box>
											<Box display='flex'>
												<Box className='cardFlag'>
													<img src={OKRFlag} alt={'Okr flag'} />
												</Box>
												<Box className='okr-after-edit'>
													<ListTooltip
														objName={okr?.name || ''}
														description={okr?.objectiveDescription || ''}
														alignmentMapDescription={true}
														characterCount={getTabFilter() === 'compact' && okr?.activeNode !== true ? 80 : 102}
														titleClassName={'cardTitle'}
														currentUser={currentUser}
														diagramInstance={props.diagramInstance}
													/>
												</Box>
											</Box>
										</Box>
										<Box className='cardHeader-col2'>
											{getTabFilter() === 'compact' && (
												<Box
													className={`progressChart ${
														okr.okrViewKeyResults &&
														okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2)
															? ' '
															: 'no-kabab'
													}`}
												>
													<CircularProgress percentage={okr?.score} score={okr.score} dueDate={okr.dueDate} />
												</Box>
											)}
											{okr.okrViewKeyResults &&
												// ((!okr.isMyOkr && !okr.isVirtualLink) || (okr.isMyOkr && okr.isVirtualLink)) &&
												okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2) && (
													<OkrPopperMenu
														{...props}
														poperClassName={'alignment-popper-menu popper-menu-list okr-list-popper-menu'}
														okr={okr}
														type={1}
														t={t}
														currentUser={currentUser}
														handleDeleteOkr={handleDeleteOkr}
														alignmentMap={true}
														//poperPlacement={'bottom-start'}
														poperContainer={false}
														openSettingsDrawer={openSettingsDrawer}
														dataUpdated={dataUpdated}
														setdataUpdated={setdataUpdated}
														goalDetail={goalDetail}
														setGoalDetail={setGoalDetail}
														isLocked={isLocked}
														diagramInstance={props.diagramInstance}
													/>
												)}
										</Box>
									</Box>
									{getTabFilter() === 'compact' && (
										<Box className='card-toggle card-toggle-compact'>
											<List className='card-key-result'>
												<ListItem>
													<Typography variant='body2'>{`${
														(okr.okrViewKeyResults && okr.okrViewKeyResults.length) || 0
													} ${t('keyResults')}`}</Typography>
												</ListItem>
												<ListItem>
													<IndividualSmallIcon /> <Typography variant='body2'>{okr?.contributorCount}</Typography>
												</ListItem>
											</List>
											{okr.okrViewKeyResults &&
												okr.okrViewKeyResults.find((item: any) => item.krStatusId === Enums.KR_STATUS_PENDING) && (
													<Box className='action-message-wrap'>
														<Box className='action-message'>
															<InfoIcon />
															<Typography variant='body2'>{t('actionRequired')}</Typography>
														</Box>
													</Box>
												)}
										</Box>
									)}
									<Box className='card-toggle card-toggle-relaxed'>
										{okr?.teamName && okr?.teamName.length > 0 ? (
											<Box className='teams-chips'>
												<Tooltip title={okr.teamName} arrow>
													<Chip
														style={{
															backgroundColor: getRandomBackgroundColor(okr.backGroundColorCode),
															color: getRandomColor(okr.colorCode),
														}}
														label={okr.teamName}
														//title={okr.teamName}
													/>
												</Tooltip>
												{okr.parentTeamDetails && okr.parentTeamDetails.teamName && (
													<Tooltip title={okr.parentTeamDetails.teamName} arrow>
														<Chip
															style={{
																backgroundColor: getRandomBackgroundColor(okr.parentTeamDetails.backGroundColorCode),
																color: getRandomColor(okr.parentTeamDetails.colorCode),
															}}
															icon={
																<svg
																	width='11'
																	height='11'
																	viewBox='0 0 11 11'
																	fill='none'
																	xmlns='http://www.w3.org/2000/svg'
																>
																	<path
																		d='M6.71854 6.18852L4.52411 3.9941C4.35506 3.82488 4.08076 3.82488 3.91168 3.9941C3.74249 4.16315 3.74249 4.43745 3.91168 4.60652L6.10611 6.80094C6.19072 6.88555 6.30144 6.92784 6.41233 6.92784C6.52321 6.92784 6.63393 6.88555 6.71854 6.80094C6.88773 6.63187 6.88773 6.35757 6.71854 6.18852Z'
																		fill={getRandomColor(okr.parentTeamDetails.colorCode)}
																		stroke={getRandomColor(okr.parentTeamDetails.colorCode)}
																		strokeWidth='0.5'
																	/>
																	<path
																		d='M9.30956 5.10748L8.50042 4.2982C8.33967 4.13758 8.07932 4.13758 7.91856 4.2982C7.75779 4.45895 7.75779 4.71958 7.91856 4.8802L8.72769 5.68948C9.58252 6.54444 9.5817 7.94559 8.72769 8.81111C7.873 9.6658 6.47184 9.66512 5.60605 8.81111L4.79676 8.00198C4.63614 7.84123 4.37552 7.84123 4.21475 8.00198C4.05398 8.16275 4.05398 8.42321 4.21475 8.58385L5.02595 9.39504C5.61947 9.9806 6.39611 10.2736 7.1711 10.2736C7.94773 10.2736 8.72301 9.97936 9.31158 9.39106C10.4841 8.20251 10.4833 6.28108 9.30956 5.10748Z'
																		fill={getRandomColor(okr.parentTeamDetails.colorCode)}
																		stroke={getRandomColor(okr.parentTeamDetails.colorCode)}
																		strokeWidth='0.5'
																	/>
																	<path
																		d='M6.41524 2.2132L5.60405 1.40185C4.41579 0.229494 2.49423 0.230447 1.31857 1.40583C0.146083 2.59424 0.147035 4.51566 1.32063 5.68925L2.12992 6.49854C2.21017 6.57892 2.3155 6.6191 2.42085 6.6191C2.52605 6.6191 2.6314 6.57892 2.71178 6.4984C2.87253 6.33779 2.87253 6.07717 2.71178 5.91654L1.9025 5.10725C1.04768 4.25243 1.04849 2.85128 1.9025 1.98576C2.75745 1.13107 4.1586 1.13191 5.02413 1.98576L5.83341 2.79504C5.99418 2.95581 6.25451 2.95581 6.41528 2.79504C6.57601 2.63443 6.57601 2.37381 6.41524 2.2132Z'
																		fill={getRandomColor(okr.parentTeamDetails.colorCode)}
																		stroke={getRandomColor(okr.parentTeamDetails.colorCode)}
																		strokeWidth='0.5'
																	/>
																</svg>
															}
															label={okr.parentTeamDetails.teamName}
															//title={okr.parentTeamDetails.teamName}
														/>
													</Tooltip>
												)}
											</Box>
										) : (
											<Fragment>
												{okr?.isPrivate === false ? (
													<OKRButton
														className='btn-gray-chip '
														text={t('individualLabel')}
														icon={<IndividualSmallIcon />}
													/>
												) : (
													<OKRButton className='btn-gray-chip ' text={t('privateLabel')} icon={<PrivateIcon />} />
												)}
											</Fragment>
										)}

										{okr.okrViewKeyResults &&
											okr.okrViewKeyResults.find((item: any) => item.krStatusId === Enums.KR_STATUS_PENDING) && (
												<Box className='action-message-wrap'>
													<Box className='action-message'>
														<InfoIcon />
														<Typography variant='body2'>{t('actionRequired')}</Typography>
													</Box>
												</Box>
											)}
										{!isLocked &&
											!currentUser &&
											((okr.teamId &&
												props.tabValue === 1 &&
												okr.owner === userDetail.employeeId &&
												okr.owner === okr.employeeId) ||
												(okr.teamId && props.tabValue !== 1) ||
												(!okr.teamId && userDetail.employeeId === okr.employeeId)) &&
											okr.okrViewKeyResults &&
											okr.isMyOkr &&
											okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2) &&
											(checkPermission(props.getPermissionResult?.employeePermissions, editOkrs) ? (
												<Box className='okr-field-actions' onClick={() => openSettingsDrawer(okr)}>
													<OKRButton
														className='settings-btn'
														icon={<SettingsIcon />}
														title={t('attributesLabel')}
														id={`setting-btn-${okr?.objectiveId}`}
													/>
												</Box>
											) : (
												<></>
											))}
									</Box>
								</Box>
								<Box className='alignedRow'>
									<Box className='usersAligned'>
										<UserAvatarGroup
											{...props}
											loginUserAvatar={okr.okrViewContributors && okr.okrViewContributors[0]}
											contributorDetails={
												okr.okrViewContributors && okr.okrViewContributors.filter((item: any, idx: any) => idx !== 0)
											}
											addButtonClassName='add-btn'
											avatarClassName=''
											isButtonVisible={false}
											max={3}
											currentUser={currentUser}
											contributorDetailsType={true}
											isOnHoverVisible={true}
											poperContainer={false}
											contributorClassName={'contributor-details-popover alignmentmap-contributorPopup'}
											diagramInstance={props.diagramInstance}
											alignmentMapTab={true}
										/>
									</Box>

									<Box className='cycleProgress'>
										<Box 
											className={`quaterCycle ${props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'disable'}`}
											// className='quaterCycle'
										>
											<Box className='date-settings alignment-date-settings'>
												<Box
													className={`calender-date ${okr?.isMyOkr !== true ? 'no-click' : ''}`}
													id={`cal-date-${okr?.objectiveId}`}
												>
													{!isLocked &&
													!currentUser &&
													((okr.teamId &&
														props.tabValue === 1 &&
														okr.owner === userDetail.employeeId &&
														okr.owner === okr.employeeId) ||
														(okr.teamId && props.tabValue !== 1) ||
														(!okr.teamId && userDetail.employeeId === okr.employeeId)) &&
													okr.okrViewKeyResults &&
													okr.isMyOkr &&
													okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2) ? (
														<DatePicker
															currentCycleEndDate={currentCycleEndDate}
															durationCycleDetail={props.durationCycleDetail}
															value={okr.dueDate}
															handleDateChange={(date: any) => {
																setModalPropsKR({
																	open: true,
																	type: 'updateDateForOKR',
																	message:
																		okr?.contributorCount > 0
																			? t('updateObjAttrNoContributor')
																			: t('updateObjectiveAttribute'),
																	module: 'user',
																	data: { date: date, okr: okr },
																});
															}}
															name={''}
															showLabel={false}
															minDate={getMinDate(
																okr?.importedId > 0 && okr.parentStartDate ? okr.parentStartDate : okr?.startDate
															)}
															maxDate={
																okr?.importedId > 0 && okr.parentDueDate
																	? okr.parentDueDate
																	: new Date(currentCycleEndDate)
															}
															error={''}
															helperText={''}
															format='MMM d'
															handleKeyboardButtonClick={() => {}}
															disableToolbar={false}
															handleEndOfMonthDateSelection={null}
														/>
													) : (
														getMonthDate(okr?.dueDate)
													)}
												</Box>
												<Typography variant='h6'>
													{t('cycle')}: {okr?.cycle}
												</Typography>
											</Box>
										</Box>

										<Box className='progressChart'>
											<CircularProgress percentage={okr?.score} score={okr.score} dueDate={okr.dueDate} />
										</Box>
									</Box>
								</Box>
							</Box>

							<Box>
								<GoalAttributes
									currentCycleEndDate={currentCycleEndDate}
									open={openObjectAttribute}
									handleDrawerClose={handleCloseObjectiveAttribute}
									objectiveDetail={objectiveDetail}
									t={t}
									{...props}
									isGoalAttributeUpdated={isGoalAttributeUpdated}
									setIsSaveDisabled={setIsSaveDisabled}
									setGoalsAttribute={setGoalsAttribute}
									goalsAttribute={goalsAttribute}
									loader={krLoader}
									goalAttrDateError={goalAttrDateError}
									setGaolAttrDateError={setGaolAttrDateError}
									setdataUpdated={setdataUpdated}
									goalDetail={dataUpdated}
									setGoalDetail={setdataUpdated}
								/>
							</Box>
							{getTabFilter() === 'relaxed' && okr && okr.okrViewKeyResults && okr.okrViewKeyResults.length > 0 && (
								<Box className='kr-list'>
									{okr.okrViewKeyResults.map((krItem: any) => (
										<KrResultItemCard {...props} krItem={krItem} />
									))}
								</Box>
							)}
						</Box>
					</Box>
				)}
				{getTabFilter() === 'ultracompact' && <UltraCompactObjectiveCard {...props} />}
				{modalPropsKR.open && (
					<AlertDialog
						module={modalPropsKR.module ? modalPropsKR.module : 'user'}
						message={modalPropsKR.message}
						handleCloseModal={handleCloseModal}
						modalOpen={modalPropsKR.open}
					/>
				)}
				{modalDate.open && (
					<AlertDialog
						module={modalDate.module ? modalDate.module : 'user'}
						message={modalDate.message}
						handleCloseModal={handleCloseModal}
						modalOpen={modalDate.open}
					/>
				)}
			</Box>
		</React.Fragment>
	);
};

export default ObjectiveCard;

import React from 'react';
import { Avatar, Box, ButtonGroup, ClickAwayListener, List, ListItem, Typography } from '@material-ui/core';
import { CompactIcon, RelaxedIcon, UltraCompactIcon } from '../../../config/svg/MyGoalSvg';
import { OKRButton } from '../../Common/OKRButton';
import { OKRTabPanelCard } from '../../Common/OKRTabPanelCard';
import { getUserName } from '../../../config/utils';
import { ALIGNMENTMAPS } from '../../../config/app-url';
import { setLocalStorageItem } from '../../../services/StorageService';
import { useTranslation } from 'react-i18next';

export const AlignmentMapSecondaryTabs: React.FC<any> = (props) => {
	const {
		setTabFilter,
		tabFilter,
		setTabButtonFilter,
		tabButtonFilter,
		addAlignmentFilter,
		currentUser,
		addTeamOkrFilter,
	} = props;
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation();

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	const handleClickAway = (e: any) => {
		if (
			!e.target.closest('.dashboard-direct-menu') &&
			!e.target.closest('.okr-drawer') &&
			!e.target.closest('.default-dialog') &&
			!e.target.closest('.MuiRating-label') &&
			!e.target.closest('.MuiRating-visuallyhidden')
		) {
			setOpen(false);
		}
	};
	return (
		<Box className='tabs-secondary' id='secondary-tab'>
			{tabButtonFilter === 'okrView' ? (
				<List className='secondary-filters'>
					<ListItem className={tabFilter === 'relaxed' ? 'active' : ''} key={'relaxed'}>
						<OKRButton
							icon={<RelaxedIcon />}
							id={'okr-view-relaxed'}
							title={t('relaxed')}
							handleClick={(e) => {
								setTabFilter('relaxed');
								if (currentUser) {
									setLocalStorageItem('activeTab', 'peoplemap_relaxed');
								} else {
									setLocalStorageItem('activeTab', 'okr_relaxed');
								}
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'compact' ? 'active' : ''} key={'compact'}>
						<OKRButton
							icon={<CompactIcon />}
							id={'okr-view-compact'}
							title={t('compact')}
							handleClick={() => {
								setTabFilter('compact');
								if (currentUser) {
									setLocalStorageItem('activeTab', 'peoplemap_compact');
								} else {
									setLocalStorageItem('activeTab', 'okr_compact');
								}
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'ultracompact' ? 'active' : ''} key={'ultracompact'}>
						<OKRButton
							icon={<UltraCompactIcon />}
							id={'okr-view-ultra-compact'}
							title={t('ultraCompact')}
							handleClick={() => {
								setTabFilter('ultracompact');
								if (currentUser) {
									setLocalStorageItem('activeTab', 'peoplemap_ultracompact');
								} else {
									setLocalStorageItem('activeTab', 'okr_ultracompact');
								}
							}}
						></OKRButton>
					</ListItem>
					<ListItem className='active-color'></ListItem>
				</List>
			) : (
				<List className='secondary-filters'>
					<ListItem className={tabFilter === 'peoplerelaxed' ? 'active' : ''} key={'peoplerelaxed'}>
						<OKRButton
							icon={<RelaxedIcon />}
							id={'ppl-view-relaxed'}
							title={t('relaxed')}
							handleClick={(e) => {
								setTabFilter('peoplerelaxed');
								setLocalStorageItem('activeTab', 'people_relaxed');
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'peoplecompact' ? 'active' : ''} key={'peoplecompact'}>
						<OKRButton
							icon={<CompactIcon />}
							id={'ppl-view-compact'}
							title={t('compact')}
							handleClick={() => {
								setTabFilter('peoplecompact');
								setLocalStorageItem('activeTab', 'people_compact');
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'peopleultracompact' ? 'active' : ''} key={'peopleultracompact'}>
						<OKRButton
							icon={<UltraCompactIcon />}
							id={'ppl-view-ultracompact'}
							title={t('ultraCompact')}
							handleClick={() => {
								setTabFilter('peopleultracompact');
								setLocalStorageItem('activeTab', 'people_ultracompact');
							}}
						></OKRButton>
					</ListItem>
					<ListItem className='active-color'></ListItem>
				</List>
			)}

			{currentUser ? (
				<>
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box className='search-people-user-info'>
							<Box className='user-info' onClick={handleClick}>
								<Box className='user-img'>
									{currentUser && currentUser.imagePath ? (
										<Avatar
											className='avatar-default'
											alt={`${currentUser.firstName} ${currentUser.lastName}`}
											src={currentUser.imagePath}
										/>
									) : (
										<Avatar className='avatar-default'>
											{getUserName({
												firstName: currentUser.firstName,
												lastName: currentUser.lastName,
												fullName: null,
											})}
										</Avatar>
									)}
								</Box>
								<Box className='user-info-details'>
									<Typography variant='h4'>{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
								</Box>
							</Box>
							{open ? (
								<OKRTabPanelCard
									{...props}
									data={currentUser}
									panelUsed={ALIGNMENTMAPS}
									userDetail={props.userDetail}
								/>
							) : null}
						</Box>
					</ClickAwayListener>
				</>
			) : (
				<>
					<ButtonGroup className='tab-btn-group' disableElevation variant='contained' color='primary'>
						<OKRButton
							key='okrView'
							className={tabButtonFilter === 'okrView' ? 'active' : ''}
							id={'okr-view-btn'}
							text={t('oKRView')}
							handleClick={() => {
								setTabButtonFilter('okrView');
								setTabFilter('relaxed');
								addAlignmentFilter([]);
								addTeamOkrFilter(null);
								setLocalStorageItem('activeTab', 'okr_relaxed');
							}}
						/>
						<OKRButton
							key='peopleView'
							className={tabButtonFilter !== 'okrView' ? 'active' : ''}
							id={'ppl-view-btn'}
							text={t('peopleView')}
							handleClick={() => {
								setTabButtonFilter('peopleView');
								setTabFilter('peoplerelaxed');
								addAlignmentFilter([]);
								addTeamOkrFilter(null);
								setLocalStorageItem('activeTab', 'people_relaxed');

								setLocalStorageItem(
									'globalBackUrl',
									JSON.stringify({
										url: ALIGNMENTMAPS,
										tabToOpen: 'peopleView',
									})
								);
							}}
						/>
					</ButtonGroup>
				</>
			)}
		</Box>
	);
};

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Avatar,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Popper,
	Grow,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	Link as MaterialLink,
} from '@material-ui/core';
import Profile from '../Profile';
import { LogoutIcon } from '../../config/svg/ProfileSvg';
import { clearLocalStorage, getLocalStorageItem } from '../../services/StorageService';
import { getUserName } from '../../config/utils';
import PersonalFeedbackMenu from '../PersonalFeedback';
import { PersonalFeedbackIcon } from '../../config/svg/PersonalFeedbackSvg';
import { ProfileIcon } from '../../config/svg/CommonSvg';
import { AZURE_LOGOUTURI } from '../../config/constant';
import { ProfileInfoDrawer } from '../Common/ProfileInfoDrawer';

export default function MyAccount(props) {
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const { profileOpen, setProfileOpen } = props;
	const anchorRef = useRef(null);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [feedbackRedirection, setFeedbackRedirection] = useState(false);
	const [showPersonalFeedback, setShowPersonalFeedback] = useState('');

	// useEffect(() => {
	// 	if (!props.isFederationUserSuccess) {
	// 		props.isFederationUser(userDetail.emailId);
	// 	}
	// }, []);

	const handleToggle = () => {
		setOpen((prev) => !prev);
	};
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = (event) => {
		if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const openPersonalFeedback = () => {
		setFeedbackRedirection(true);
		setShowPersonalFeedback('view');
	};

	const logoutUser = () => {
		const isAzureLogout = getLocalStorageItem('isAzureLogout');
		clearLocalStorage();
		sessionStorage.clear();
		window.location.href = isAzureLogout === 'false' ? window.location.origin + '/logout' : AZURE_LOGOUTURI;
	};

	const resetPFData = () => {
		setFeedbackRedirection(false);
		setShowPersonalFeedback('');
	};

	return (
		<Box className='user-tab'>
			<Tooltip
				title={userDetail?.isImpersonatedUser ? `${userDetail.firstName} ${userDetail.lastName}` : t('meLabel')}
				arrow
			>
				<IconButton
					className='profile-btn'
					type='button'
					ref={anchorRef}
					aria-controls={open ? 'menu-list-grow' : undefined}
					onClick={handleOpen}
					id='profile-btn'
				>
					{userDetail && userDetail.imagePath && userDetail.imagePath.trim() !== '' ? (
						<Avatar className='avatar-type3' src={userDetail.imagePath} />
					) : userDetail && userDetail.firstName ? (
						<Avatar className='avatar-type3'>{getUserName({
							firstName: userDetail?.firstName,
							lastName: userDetail?.lastName,
							fullName: userDetail?.firstName,
						})}</Avatar>
					) : (
						<Avatar></Avatar>
					)}
				</IconButton>
			</Tooltip>
			<ProfileInfoDrawer
				{...props}
				open={open}
				handleClose={handleClose}
				profileOpen={profileOpen}
				setProfileOpen={setProfileOpen}
			/>

			{/* <Popper className='my-account' open={open} anchorEl={anchorRef.current} transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper className=''>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='menu-list-grow'>
									<MenuItem className='profile-btn' onClick={handleClose} id='profile-link'>
										<MaterialLink underline='none' to={'#'} onClick={() => setProfileOpen(true)}>
											<ProfileIcon />
											<Typography>{t('profile')}</Typography>
										</MaterialLink>
									</MenuItem>

									{getLocalStorageItem('LoginAs') === 'user' && (
										<MenuItem className='logout-btn pfeedback-btn' id='feedback-link' onClick={openPersonalFeedback}>
											<PersonalFeedbackIcon />
											<Typography>{t('myPF')}</Typography>
										</MenuItem>
									)}
									<MenuItem className='logout-btn' onClick={logoutUser} id='logout-link'>
										<LogoutIcon />
										<Typography>{t('logout')}</Typography>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper> */}
			{feedbackRedirection && Boolean(showPersonalFeedback) && (
				<PersonalFeedbackMenu
					{...props}
					loggedInUser={true}
					feedbackRedirection={feedbackRedirection}
					showPersonalFeedback={showPersonalFeedback}
					userDetail={userDetail}
					resetPFData={resetPFData}
					setFeedbackData={() => { }}
				/>
			)}
			{profileOpen && <Profile {...props} setProfileOpen={setProfileOpen} open={profileOpen} />}
		</Box>
	);
}

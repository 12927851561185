import React, { useEffect, useState } from 'react';
import { Paper, Popper, Grow, ClickAwayListener, List, Box } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { KababIcon } from '../../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
export const PopperMenu: React.FC<any> = (props) => {
	const {
		anchorEl,
		handleClose,
		open,
		children,
		connectorIcon = false,
		className,
		includeKebabMenu,
		popperPlacement,
		popperClassName,
		btnId,
	} = props;

	const [anchorKebabEl, setAnchorKebabEl] = useState(null);
	const [openKebabMenu, setOpenKebabMenu] = useState<boolean>(false);
	const { t } = useTranslation();
	const handleClick = (event: any) => {
		setAnchorKebabEl(anchorKebabEl ? null : event.currentTarget);
		setOpenKebabMenu(true);
	};

	const handleKebabMenuClose = () => {
		setAnchorKebabEl(null);
		setOpenKebabMenu(false);
	};

	useEffect(() => {
		if (props.menuClicked) {
			handleKebabMenuClose();
			props.setPopperMenuClicked(false);
		}
	}, [props.menuClicked]);

	return (
		<>
			{includeKebabMenu && (
				<OKRButton
					id={btnId}
					title={t('moreOptions')}
					className={className}
					handleClick={handleClick}
					icon={<KababIcon />}
				/>
			)}
			<Popper
				className={popperClassName || `popper-menu ${connectorIcon ? '' : 'popper-menu-type1'}`}
				open={includeKebabMenu ? openKebabMenu : open}
				anchorEl={includeKebabMenu ? anchorKebabEl : anchorEl}
				placement={popperPlacement || 'bottom-end'}
				transition
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener
								onClickAway={() => {
									includeKebabMenu ? handleKebabMenuClose() : handleClose();
								}}
							>
								<Box className='popper-menu-inner'>
									{connectorIcon && <Box className='connnector-icon'></Box>}
									<List>
										{/* Need to render children */}
										{children}
									</List>
								</Box>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

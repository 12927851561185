/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Avatar, Typography, ClickAwayListener, Box, Chip } from '@material-ui/core';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import noRecordFound from '../../images/search-placeholder.svg';
import '../../styles/components/globalSearch.scss';
import { getLocalStorageItem } from '../../services/StorageService';
import { useTranslation } from 'react-i18next';
import { AnimatedIcon } from './AnimatedIcon';
import { getRandomBackgroundColor, getRandomColor } from '../../config/utils';
import _ from 'lodash';

export const SearchUsers: React.FC<any> = (props) => {
	const {
		module,
		defaultValue,
		getUserById,
		defaultFocus,
		placeHolderText,
		customClass,
		globalSearchPage,
		searchEmployee,
		updatePage,
		onSelectedUser,
		multiple,
		text,
		onInputChange,
		linkObjectiveData,
		showTeamsUsersOnly,
		linkSelectedOwner,
		isTeamSearch,
		globalSearchAPIWithTeam,
		searchEmployeeInRole,
		customId,
	} = props;
	const { t } = useTranslation();
	const userDetail = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const ref: any = React.createRef();
	const [defaultId, setDefaultId] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchTyped, setSearchTyped] = useState(false);
	const [resultResponse, setResultResponse] = useState<any>([]);
	const [initial, setInitial] = useState<any>({
		isLoading: false,
		options: [],
		query: '',
		page: 1,
		selected: [],
		totalCount: 0,
	});
	const [isLeaderClicked, setIsLeaderClicked] = useState(false);

	useEffect(() => {
		if (defaultValue && !defaultId) {
			getUserById(`empId=${defaultValue}`).then((resp: any) => {
				if (resp && resp.status === 200) {
					const { data } = resp;
					const { entityList } = data;
					setInitial({
						isLoading: false,
						options: [{ ...entityList }],
						query: '',
						page: 1,
						selected: [{ ...entityList }],
						totalCount: 1,
					});
				}
			});
		}
	}, [defaultValue]);

	useEffect(() => {
		if (defaultFocus) {
			ref.current.focus();
		}
	}, [defaultFocus]);

	useEffect(() => {
		if (showTeamsUsersOnly === true) {
			const options = linkObjectiveData;
			setInitial({
				...initial,
				isLoading: false,
				options,
				totalCount: 10000,
			});
		} else {
			if (ref.current && ref.current.getInput().value !== '') {
				makeAndHandleRequest(ref.current.getInput().value, globalSearchPage).then((resp) => {
					const options = initial.options.concat(resp.options);
					setInitial({
						...initial,
						isLoading: false,
						options,
						totalCount: resp.totalCount,
					});
				});
			}
		}
	}, [globalSearchPage]);

	useEffect(() => {
		if (showTeamsUsersOnly === true) {
			const options = linkObjectiveData;
			setInitial({
				...initial,
				isLoading: false,
				options,
				totalCount: 10000,
			});
		}
	}, [showTeamsUsersOnly]);

	const makeAndHandleRequest = async (query: any, page = 1) => {
		setDefaultId(true);
		setLoading(true);
		setInitial({ ...initial, isLoading: true });
		if (!query) {
			query = ref.current.getInput().value;
		}
		let resp;
		if (props.currentObjective?.teamId && props.currentObjective?.teamId > 0) {
			// resp = await globalSearchAPIWithTeam(query, page, 6);
			resp = await globalSearchAPIWithTeam(query, page, 6, 0);
			//resp = await props.searchTeamEmpAPI(query, props.currentObjective?.teamId, 1, 5);
		} else if (isTeamSearch) {
			resp = await globalSearchAPIWithTeam(query, page, 6, 0);
		} else if (props.addUserInRole) {
			resp = await searchEmployeeInRole(query, page, 6);
		} else {
			//resp = await searchEmployee(query, page, 6);
			resp = await globalSearchAPIWithTeam(query, page, 6, 1);
		}
		const { entityList, paggingInfo } = resp.data;
		let list = entityList;
		if (module === 'myGoal' && !props.addUserInRole) {
			list =
				entityList &&
				entityList.filter((item: any) => item.searchType === 2 || item.employeeId !== userDetail.employeeId);
		}
		setLoading(false);
		setSearchTyped(false);
		setResultResponse(list);
		if (list) {
			const options = list;
			return { options, totalCount: paggingInfo?.totalRecords };
		} else {
			return { options: [], totalCount: 0 };
		}
	};

	const getUserName = (data: any) => {
		if (data.searchType === 2) {
			const orgName = data.organisationName.split(' ');
			return `${orgName[0].charAt(0).toUpperCase()}${orgName[1] ? orgName[1].charAt(0).toUpperCase() : ''}`;
		} else {
			const { firstName, lastName } = data;
			if (firstName && lastName) {
				return `${firstName.charAt(0)}${lastName.charAt(0)}`;
			}
			return firstName ? firstName.slice(2) : '';
		}
	};

	const NoRecordFound = (detail: any) => {
		const { text } = detail;

		return (
			<Box className='search-no-record'>
				<Box className='no-record-message'>
					<Typography variant='h2'>{t('CommonOOPS')}</Typography>
					<Typography variant='h6' className='no-sentence-case'>
						{t('globalSearchNoRecord')}
					</Typography>
					<Typography>{text}</Typography>
					<AnimatedIcon
						className=''
						width='250'
						type='image/svg+xml'
						alt={t('globalSearchNoRecord')}
						imagePath={noRecordFound}
					/>
				</Box>
			</Box>
		);
	};

	const _handlePagination = (e: any, shownResults: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (initial.options.length > shownResults || initial.options.length === initial.totalCount) {
			return;
		} else {
			updatePage(globalSearchPage + 1);
			const page = initial.page + 1;
			setInitial({ ...initial, isLoading: true, page });
		}
	};

	const _handleSearch = async (query: any) => {
		if (showTeamsUsersOnly === true) {
			let filteredLink: any[] = [];
			if (query?.trim() === '') {
				filteredLink = linkObjectiveData;
			} else {
				query = query?.trim();
				linkObjectiveData.forEach((element: any) => {
					if (element.firstName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
						filteredLink.push(element);
					}
				});
			}
			const options = filteredLink;
			setInitial({
				...initial,
				isLoading: false,
				options,
				totalCount: 10000,
			});
		} else {
			const resp = await makeAndHandleRequest(query);
			setInitial({
				...initial,
				isLoading: false,
				options: resp.options,
				totalCount: resp.totalCount,
			});
		}
	};

	const _handleInputChange = (input: any) => {
		setSearchTyped(true);
		onInputChange(input, ref);
		if (showTeamsUsersOnly === true) {
			const options = linkObjectiveData;
			setInitial({
				...initial,
				isLoading: false,
				options,
				totalCount: 10000,
			});
		} else {
			if (input === '') {
				ref.current.clear();
				setInitial({
					isLoading: false,
					options: [],
					query: '',
					page: 1,
					selected: [],
				});
				//	setDefaultId(0);
			}
			if (globalSearchPage > 1) {
				updatePage(1);
			}
		}
	};

	const handleInputThrottled = (value: any) => {
		setSearchTyped(true);
		delayedQuery(value);
	};
	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);

	const _handleOnChange = (selected: any) => {
		if (showTeamsUsersOnly === true) {
			linkSelectedOwner(selected[0]);
			ref.current.clear();
			setInitial({
				isLoading: false,
				options: [],
				query: null,
				page: 1,
				selected: [],
			});
		} else {
			updatePage(1);

			if (module === 'myGoal') {
				if (
					!selected.find(
						(item: any) => (item.searchType === 1 || !item.searchType) && item.employeeId === userDetail.employeeId
					) &&
					!props.addUserInRole
				) {
					if (isLeaderClicked === true) {
						selected.isLeaderClicked = true;
						setIsLeaderClicked(false);
					}
					onSelectedUser(selected, ref);
				} else if (props.addUserInRole) {
					if (isLeaderClicked === true) {
						selected.isLeaderClicked = true;
						setIsLeaderClicked(false);
					}
					onSelectedUser(selected, ref);
				}
				setInitial({ ...initial, selected });
				if (module === 'myGoal') {
					try {
						ref?.current?.blur();
					} catch (err) {}
					ref?.current?.clear();
					setInitial({
						isLoading: false,
						options: [],
						query: null,
						page: 1,
						selected: [],
					});
				}
			} else {
				try {
					ref.current.clear();
					setInitial({
						isLoading: false,
						options: [],
						query: null,
						page: 1,
						selected: [],
					});
				} catch (err) {}
			}
		}
	};

	const handleClickAway = (e: any) => {
		try {
			if (module === 'myGoal' || initial.selected.length === 0) {
				ref.current.blur();
				ref.current.clear();
				setInitial({
					isLoading: false,
					options: [],
					query: null,
					page: 1,
					selected: [],
				});
			}
		} catch (err) {}
	};

	const getLabelKey = (option: any) => {
		return option.searchType === 2 ? `${option.organisationName}` : `${option.firstName} ${option.lastName}`;
	};

	const leaderClicked = () => {
		setIsLeaderClicked(true);
	};

	const handleEnterClick = (e: any) => {
		if (e.keyCode === 13 && resultResponse.length === 1) {
			_handleOnChange([resultResponse[0]]);
		}
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Box>
				<AsyncTypeahead
					{...initial}
					className={Boolean(customClass) ? customClass : ''}
					id='async-pagination'
					clearButton
					multiple={multiple}
					isLoading={loading}
					paginate
					ref={ref}
					delay={100}
					minLength={showTeamsUsersOnly ? 0 : 3}
					maxResults={5}
					onPaginate={_handlePagination}
					onChange={_handleOnChange}
					onSearch={handleInputThrottled}
					onInputChange={_handleInputChange}
					placeholder={placeHolderText ? placeHolderText : t('searchPeople')}
					linkObjectiveData={linkObjectiveData}
					showTeamsUsersOnly={showTeamsUsersOnly}
					linkSelectedOwner={linkSelectedOwner}
					emptyLabel={!searchTyped ? <NoRecordFound text={''} /> : t('apiLoading')}
					selected={initial.selected}
					labelKey={(option: any) => getLabelKey(option)}
					inputProps={{ id: customId ? customId : 'src-input-box' }}
					renderMenuItemChildren={(option: any, props: any) => {
						if (option.length === 0) {
							return null;
						}
						return (
							<Fragment>
								<Box className={`user-info ${option.searchType === 2 ? 'user-team-info' : ''}`}>
									<Box className='user-img' id={`src-user-img-${option.employeeId}`}>
										{option.imagePath ? (
											<Avatar alt={`${option?.firstName} ${option?.lastName}`} src={option.imagePath} />
										) : (
											<Avatar
												style={{
													backgroundColor: getRandomBackgroundColor(option?.backGroundColorCode),
													color: getRandomColor(option?.colorCode),
												}}
											>
												{getUserName(option)}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Box className='user-info-label'>
											<Typography variant='h4' key={option.employeeId} id={`src-user-name-${option.employeeId}`}>
												<Highlighter search={text}>{getLabelKey(option)}</Highlighter>
												{option.self && (
													<Typography component='span' className='myself-txt' variant='body2'>
														{t('mySelfLabel')}
													</Typography>
												)}
											</Typography>
											<Typography variant='subtitle2' id={`src-user-info-${option.employeeId}`}>
												{option.searchType === 2 ? (
													option.firstName ? (
														<span>
															{t('leaderLabelText')}&nbsp;-&nbsp;
															<span
																className='organization-leader-name'
																// onClick={leaderClicked}
															>
																{option.firstName} {option.lastName}
															</span>
														</span>
													) : (
														''
													)
												) : (
													`${option.designation}`
												)}
											</Typography>
											{showTeamsUsersOnly && option?.self !== true && option.organisationName && (
												<Box className='teams-chips'>
													<Chip
														className='chip'
														style={{
															backgroundColor: getRandomBackgroundColor(option?.backGroundColorCode),
															color: getRandomColor(option?.colorCode),
														}}
														label={option.organisationName}
														title={option.organisationName}
													/>
												</Box>
											)}
										</Box>
									</Box>
								</Box>
							</Fragment>
						);
					}}
					useCache={false}
					onKeyDown={handleEnterClick}
				/>
			</Box>
		</ClickAwayListener>
	);
};

import { getRequest } from '../config/auth';
import { GLOBAL_SEARCH, GLOBAL_SEARCH_WITH_TEAM, SEARCH_IN_ROLE, SEARCH_IN_TEAM } from '../config/api-url';

export const globalSearchAPI = (data, page, pageSize) =>
	getRequest(`${GLOBAL_SEARCH}?finder=${data}&page=${page}&pagesize=${pageSize}`, data);

export const globalSearchAPIWithTeamAPI = (data, page, pageSize, type = 1) =>
	getRequest(`${GLOBAL_SEARCH_WITH_TEAM}?finder=${data}&page=${page}&pagesize=${pageSize}&searchType=${type}`, data);

export const searchTeamEmployeeAPI = (data, teamId, page, pageSize) =>
	getRequest(`${SEARCH_IN_TEAM}?finder=${data}&teamId=${teamId}&page=${page}&pagesize=${pageSize}`, data);

export const searchEmployeeRoleAPI = (data, page, pageSize) =>
	getRequest(`${SEARCH_IN_ROLE}?finder=${data}&pageIndex=${page}&pageSize=${pageSize}`, data);

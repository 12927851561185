import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	loaderStatus: false,
	currentRoute: '/unlock-me',
	prevRoute: '',
	globalSearchPage: 1,
	listMasterDataPending: false,
	listMasterDataSuccess: false,
	listMasterDataError: {},
	showNotifier: false,
	okrType: 'goal',
	guideTourVisible: null,
	guidedTour: [],
	dashboardCurrentTab: 0,
	isClosedByUser: false,
	cycleObj: {
		cycleChangedStatus: true,
	},
	isFederationUserSuccess: false,
	isFederationUserResult: {},
	isSignalRConnected: false,
	currentCycleId: {},
	cycleIdDetails: {},
	userSelectedCycle: {},
	isUserAdded: false,
	teamCycleDetails: [],
	isSessionExpire: false,
	userImpersonated: {
		redirection: true,
		tab: 0,
		userDetails: {},
	},
};

export default function commonReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.SHOW_LOADER:
			return { ...state, loaderStatus: action.payload };
		case actionTypes.UPDATE_ROUTE:
			return { ...state, currentRoute: action.payload };
		case actionTypes.UPDATE_PREV_ROUTE:
			return { ...state, prevRoute: action.payload };
		case actionTypes.UPDATE_PAGE:
			return { ...state, globalSearchPage: action.payload };
		case actionTypes.SHOW_GUIDED_TOUR:
			return { ...state, guideTourVisible: action.guideTourVisible };
		case actionTypes.USER_CLOSED_GUIDED_TOUR:
			return { ...state, isClosedByUser: action.isClosedByUser };
		case actionTypes.CURRENT_DASHBOARD_TAB:
			return { ...state, dashboardCurrentTab: action.currentTab };
		case actionTypes.SHOW_HIDE_SNACKBAR:
			return { ...state, showNotifier: action.payload };
		case actionTypes.UPDATE_GOAT_TYPE:
			return { ...state, okrType: action.payload };
		case actionTypes.FETCH_GUIDED_DATA_SUCCESS:
			return {
				...state,
				guidedTour: action.payload,
			};
		case actionTypes.CHANGE_CYCLE:
			return {
				...state,
				cycleObj: action.data,
			};
		case actionTypes.IS_FEDERATION_USER_PENDING:
			return {
				...state,
				isFederationUserSuccess: false,
				isFederationUserResult: {},
			};
		case actionTypes.IS_FEDERATION_USER_SUCCESS:
			return {
				...state,
				isFederationUserSuccess: true,
				isFederationUserResult: action.payload.entity,
			};
		case actionTypes.IS_FEDERATION_USER_FAILED:
			return {
				...state,
				isFederationUserSuccess: false,
				isFederationUserResult: {},
			};
		case actionTypes.SIGNAL_R_CONNECTION_STATUS:
			return {
				...state,
				isSignalRConnected: action.payload,
			};
		case actionTypes.CURRENT_ORG_CYCLE_ID:
			return {
				...state,
				currentCycleId: action.payload,
			};
		case actionTypes.CYCLE_ID_DETAILS:
			return {
				...state,
				cycleIdDetails: action.payload,
			};
		case actionTypes.USER_SELECTED_CYCLE:
			return {
				...state,
				userSelectedCycle: action.payload,
			};
		case actionTypes.TEAM_CYCLE_DETAILS:
			return {
				...state,
				teamCycleDetails: action.payload,
			};
		case actionTypes.IDLE_STATE_SESSION_EXPIRE:
			return {
				...state,
				isSessionExpire: action.payload || false,
			};
		case actionTypes.IS_PERSONATED_USER:
			return {
				...state,
				userImpersonated: action.payload,
			};
		default:
			return { ...state };
	}
}

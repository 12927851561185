import React from 'react';
import { Badge } from '@material-ui/core';
import { ConversationIcon } from '../../../../config/svg/CommonSvg';
import { OKRButton } from '../../../Common/OKRButton';
import '../../style.scss';
import { isConversationDisabled, isConversationUnread } from '../../../../config/utils';
import { useTranslation } from 'react-i18next';

export const Conversation: React.FC<any> = (props) => {
	const { handleCfrOpen, kr, okr, type, selectTab, currentUser } = props;
	const { t } = useTranslation();
	const handleOpen = (event: any) => {
		handleCfrOpen(event, type, kr, okr, selectTab);
	};
	const keyData = type === 2 ? kr : okr;
	const id = type === 1 ? okr.goalObjectiveId : keyData.goalKeyId;
	return (
		<>
			<OKRButton
				handleClick={(e) => handleOpen(e)}
				className={`conversation-btn`}
				id={`conv-btn-${id}`}
				disabled={isConversationDisabled(type, okr, kr, currentUser)}
				icon={
					<Badge
						variant='dot'
						className='dot-bubble'
						overlap='circular'
						invisible={isConversationUnread(type, okr, kr) ? false : true}
					>
						<ConversationIcon />
					</Badge>
				}
				buttonType={'disableButtonTooltip'}
				title={isConversationDisabled(type, okr, kr) ? t('conversationDisabled') : t('conversationLabel')}
			/>
		</>
	);
};

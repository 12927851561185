import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Drawer, Tab, Tabs, Box } from '@material-ui/core';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import ChangePassword from './ChangePassword';
import UpdateProfile from './UpdateProfile';
import '../../styles/pages/profile/profile.scss';
import LinearLoader from '../Common/Loader';
import AlertDialog from '../Common/Dialog';
import { ImageEditor } from './ImageEditor';

export default function Profile(props) {
	const { open, setProfileOpen, userData } = props;
	const [value, setValue] = useState(0);
	const [loader, setLoader] = useState(false);
	const [profileDetail, setProfileDetail] = useState({});
	const [formDataUpdated, setFormDataUpdated] = useState(false);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const [isImageEditor, setIsImageEditor] = useState(false);
	const { t } = useTranslation();
	const [changePasswordVisible, setChangePasswordVisible] = useState(false);

	const handleCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			setProfileOpen(false);
			showImageEditor(e, false);
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	useEffect(() => {
		isChangePasswordEnable();
		setLoader(true);
		props.getProfile('').then((response) => {
			if (response && response.data && response.data.entity) {
				setProfileDetail(response.data.entity);
				setLoader(false);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDrawerConfirmation = (event) => {
		if (formDataUpdated) {
			setModalProps({
				open: true,
				type: 'closeDrawer',
				message: t('unSavedItemAlert'),
				module: 'profile',
			});
		} else {
			setProfileOpen(false);
			showImageEditor(event, false);
		}
	};

	const showImageEditor = (event, open) => {
		setIsImageEditor(open);
	};

	const isChangePasswordEnable = () => {
		if (props.isFederationUserSuccess) {
			setChangePasswordVisible(!props.isFederationUserResult);
		}
	};

	return (
		<Fragment>
			<Drawer
				open={open}
				anchor='right'
				className='main-drawer-panel main-drawer-titlepanel'
				onEscapeKeyDown={handleDrawerConfirmation}
				onBackdropClick={handleDrawerConfirmation}
			>
				{isImageEditor ? (
					<Box className='okr-drawer-area'>
						<ImageEditor
							{...props}
							setModalProps={setModalProps}
							handleDrawerConfirmation={handleDrawerConfirmation}
							setFormDataUpdated={setFormDataUpdated}
							showImageEditor={showImageEditor}
							profileDetail={profileDetail}
							setProfileDetail={setProfileDetail}
						/>
					</Box>
				) : (
					<Fragment>
						<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={() => handleDrawerConfirmation()}>
							<RightArrowIcon />
						</IconButton>

						<Box className='drawer-area'>
							<Box className='drawer-head drawer-head-tabs'>
								<Tabs
									value={value}
									onChange={(e, value) => setValue(value)}
									TabIndicatorProps={{ style: { background: '#39A3FA' } }}
									textColor='primary'
									className='okr-tabs drawer-tabs'
									centered
								>
									<Tab key={'UpdateProfile'} label={t('updateProfile')} />
									{changePasswordVisible ? <Tab key={'ChangePassword'} label={t('changePassword')} /> : <></>}
								</Tabs>
							</Box>
							{loader && <LinearLoader />}
							<>
								{value === 0 ? (
									profileDetail &&
									profileDetail.firstName && (
										<UpdateProfile
											{...props}
											{...profileDetail}
											setProfileOpen={setProfileOpen}
											userData={userData}
											setLoader={setLoader}
											setModalProps={setModalProps}
											setFormDataUpdated={setFormDataUpdated}
											setProfileDetail={setProfileDetail}
											showImageEditor={showImageEditor}
										/>
									)
								) : (
									<ChangePassword
										{...props}
										setProfileOpen={setProfileOpen}
										setLoader={setLoader}
										setModalProps={setModalProps}
										setFormDataUpdated={setFormDataUpdated}
									/>
								)}
							</>
						</Box>
					</Fragment>
				)}
			</Drawer>

			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
}

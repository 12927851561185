import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { getLocalStorageItem } from '../../services/StorageService';
import { getCurrentUserDetails, getQuarterStartEnd, getUserDetails } from '../../config/utils';
import { AlignmentHeader } from './AlignmentHeader';
import './style.scss';

export const AlignmentMap: React.FC<any> = (props) => {
	const { resetAlignment, updateStatus, getOkrStatusList } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	let userDetail = getUserDetails();
	const currentUser = getCurrentUserDetails();
	const history = useHistory();
	if (currentUser) {
		userDetail = currentUser;
	}

	let { currentYear } = getQuarterStartEnd();
	const selectedYear = getLocalStorageItem('year') || currentYear;
	const cycleId = getLocalStorageItem('cycleId') || 0;

	useEffect(() => {
		document.body.classList.add('alignment-body-class');
		if (!Boolean(props?.okrMasterData)) {
			props.getOkrMasterData();
		}
		getOkrStatusList(`organisationId=${userDetail && userDetail.organisationId}`);
		if (cycleId !== 0) {
			fetchOkr();
		} else {
			enqueueSnackbar(t('orgCycleInvalid'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
		return () => {
			resetAlignment();
			updateStatus();
			document.body.classList.remove('alignment-body-class');
		};
	}, [history.location]);

	const fetchOkr = () => {
		if (!currentUser && userDetail && userDetail.employeeId && cycleId !== 0) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			// props.getDashboardDetail(data);
		}
	};

	return (
		<Fragment>
			<AlignmentHeader {...props} t={t} fetchOkr={fetchOkr} />
		</Fragment>
	);
};

import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OKRTabPanelCard } from '../Common/OKRTabPanelCard';
import { OKRButton } from '../Common/OKRButton';
import TabsFilter from '../Common/TabFilters';
import { CancelIcon } from '../../config/svg/Action';
import { getUserDetails } from '../../config/utils';
import { HOME } from '../../config/app-url';
import { removeLocalStorageItem } from '../../services/StorageService';
import noRecordFound from '../../images/search-placeholder.svg';

export const DirectReportCard: React.FC<any> = (props) => {
	const {
		directReportResult,
		directReportResultStatus,
		fetchDirectsReportsMap,
		userDetail,
		cycleId,
		year,
		t,
		alignmentFilterData,
		directReportFilter,
	} = props;
	const [filters, setfilters] = useState<any>([]);
	const [organizations, setOrganizations] = useState<any>([]);
	const [allSelected, setAllSelected] = useState<boolean>(true);
	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [copyOriginalData, setCopyOriginalData] = useState<any>([]);
	const [attentionCount, setAttentionCount] = useState<Number>(0);
	const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
	const [currentFilter, setCurrentFilter] = useState<any>({});

	useEffect(() => {
		if (directReportResult && directReportResult.length > 0) {
			let cloneReports = JSON.parse(JSON.stringify(directReportResult));
			// removed loggedIn user from the list
			let filterItem = cloneReports.filter((item: any) => item.employeeId !== userDetail.employeeId);
			let sortData = filterItem;
			let setFilters = filterItem;
			if (!isFilterApplied) {
				sortData = filterItem.sort(
					(a: any, b: any) => a.progress - b.progress || a.firstName.localeCompare(b.firstName)
				);
				setFilters = createFilters(sortData);
				setCopyOriginalData(sortData);
				setOrganizations(sortData);
				setfilters(setFilters);
				let getAttentionData = sortData.filter((item: any) => item.progress === 1 || item.progress === 2);
				setAttentionCount(getAttentionData.length);
			} else {
				let filterSelected = createFilters(sortData);
				let activeSelectedFilter = currentFilter?.organizationName
					? filterSelected.map((item: any) => {
							if (item.organizationName === currentFilter.organizationName) {
								return {
									...item,
									isActive: true,
								};
							}
							return {
								...item,
								isActive: false,
							};
					  })
					: filterSelected;

				setFilters = activeSelectedFilter.sort((a: any, b: any) => b.isActive - a.isActive);
				setfilters(setFilters);

				setCopyOriginalData(sortData);
				//filter
				let getAttentionData;
				if (currentFilter?.organizationName) {
					let filteredCardsData = sortData.filter((list: any) => list.organisationId === setFilters[0].organisationId);
					getAttentionData = sortData.filter((item: any) => item.progress === 1 || item.progress === 2);
					setOrganizations(filteredCardsData);
					setAttentionCount(getAttentionData.length);
				} else {
					getAttentionData = sortData.filter((item: any) => item.progress === 1 || item.progress === 2);
					setAttentionCount(getAttentionData.length);
					setOrganizations(sortData);
				}
			}
		}
	}, [directReportResult]);

	/**
	 *
	 * @param data Array list of reports data
	 * @returns Array
	 */
	const createFilters = (data: any) => {
		let flags: any = {};
		let allTeams: any = [];
		let newFilters = data.map((item: any) => {
			item?.directReportsTeamResponse.filter((team: any) => {
				if (flags[team?.teamName]) {
					return false;
				} else {
					allTeams.push(team);
					flags[team?.teamName] = true;
					return true;
				}
			});
		});
		return allTeams;
	};

	/**
	 * fetch Directs Reports one time
	 */
	useEffect(() => {
		const data = `empId=${userDetail && userDetail.employeeId}&cycle=${cycleId}&year=${year}`;
		if (fetchDirectsReportsMap && !alignmentFilterData.length && !directReportFilter) {
			fetchDirectsReportsMap(data);
			setCurrentFilter({});
			setIsFilterApplied(false);
			removeLocalStorageItem('backUrl');
		}
	}, [alignmentFilterData]);

	/**
	 * return request data based on filters selected
	 * @returns String
	 */
	const makeFilter = () => {
		let filters = alignmentFilterData.length
			? alignmentFilterData
					.filter((item: any) => item.isChecked)
					.map((item: any) => `searchTexts=${item.code}`)
					.join('&')
			: '';
		return (filters = filters ? `&${filters}` : '');
	};

	/**
	 * get filter sort by filter value
	 */
	useEffect(() => {
		if (alignmentFilterData.length || directReportFilter) {
			const filters = makeFilter();
			const sortBy = directReportFilter ? `&sortBy=${directReportFilter}` : '';
			const data = `empId=${userDetail && userDetail.employeeId}&cycle=${cycleId}&year=${year}${filters}${sortBy}`;
			fetchDirectsReportsMap(data);
			setIsFilterApplied(true);
		}
	}, [directReportFilter, alignmentFilterData]);

	/**
	 * Method for handling filters click
	 * @param item Object
	 * @param orgId Number
	 * @param type String
	 * @returns
	 */
	const handleFilterClick = (item: any, orgId?: string, type?: string) => {
		if (type === 'all') {
			let filterdata = [...filters];
			filterdata.map((item: any) => (item.isActive = false));
			let getAttentionData = copyOriginalData.filter((item: any) => item.progress === 1 || item.progress === 2);
			setAttentionCount(getAttentionData.length);
			setAllSelected(true);
			setOrganizations(copyOriginalData);
			setfilters(filterdata);
			setCurrentFilter({});
			return;
		}
		let filterdata = [...filters];
		// let filteredCardsData = copyOriginalData.filter((list: any) => list.organisationId === item.organisationId);
		let filteredCardsData = copyOriginalData.filter((list: any) => {
			if (list.directReportsTeamResponse.filter((team: any) => team.teamId === item.teamId).length) {
				return list;
			}
		});

		filterdata.map((item: any) => (item.isActive = false));
		filterdata.map((orgs: any) => {
			if (orgs.teamId === orgId) {
				orgs.isActive = true;
				setCurrentFilter(orgs);
			}
		});

		let getAttentionData = filteredCardsData.filter((item: any) => item.progress === 1 || item.progress === 2);
		setAttentionCount(getAttentionData.length);
		// setfilters(filterdata);
		setAllSelected(false);
		setOrganizations(filteredCardsData);
		const copy = [...filters];
		const index = filters.indexOf(item);
		const selected = filters[index];
		copy.splice(index, 1);
		copy.unshift(selected);
		setfilters(copy);
	};

	const handleGiveFeedBack = () => {
		setShowDrawer(!showDrawer);
	};

	/**
	 * set attention number to user when user click on close button
	 */
	const handleAttentionMsg = () => {
		setAttentionCount(0);
	};

	return (
		<Fragment>
			{/* Filters */}
			<Box className='okr-tabpanel-actions'>
				{directReportResultStatus === 'success' && filters && filters.length > 0 && (
					<>
						<Box className='teams-filter'>
							<TabsFilter showAll={allSelected} filters={filters} handleFilterClick={handleFilterClick} />
						</Box>

						{attentionCount > 0 && (
							<Box className='nudge-message-box'>
								<Box className='nudge-bar'>
									<Typography variant='body2'>{`${attentionCount} ${t('NudgeOrTalk')}`}</Typography>
									<OKRButton handleClick={handleAttentionMsg} className='cancel-btn' icon={<CancelIcon />} />
								</Box>
							</Box>
						)}
					</>
				)}
			</Box>

			{/* Cards listing */}
			{directReportResultStatus &&
				directReportResultStatus === 'success' &&
				(organizations && organizations.length > 0 ? (
					<Box className='okr-card-main okr-directReport'>
						{organizations.map((item: any, index: any) => (
							<OKRTabPanelCard
								handleGiveFeedBack={handleGiveFeedBack}
								panelUsed={HOME}
								{...props}
								data={item}
								userDetail={userDetail}
								t={t}
								cardId={`card-${index}`}
							/>
						))}
					</Box>
				) : (
					<Box display='flex' justifyContent='center'>
						<Box className='no-record-message' textAlign='center'>
							<Typography variant='h2' component='h2'>
								{t('NoRecord')}
							</Typography>
							{/* <Typography variant='h3'>{t('allDone')}</Typography> */}
							{/* <Typography>{t('noOkr')}</Typography> */}
							<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
						</Box>
					</Box>
				))}
		</Fragment>
	);
};

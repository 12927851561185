import * as actionTypes from '../action/actionTypes';
import { getEncryptString } from '../config/utils';
import { setLocalStorageItem } from '../services/StorageService';

const INITIAL_STATE = {
	listRolesPending: false,
	listRolesSuccess: false,
	listRolesResult: [],
	listRolesError: '',
	addRolesSuccess: false,
	addRolesPending: false,
	addRolesError: {},
	addRolesStatus: '',
	editRolesSuccess: false,
	editRolesPending: false,
	editRolesError: {},
	editRolesStatus: '',
	assignRolesSuccess: false,
	assignRolesPending: false,
	assignRolesError: {},
	assignRolesStatus: '',
	permissions: [],
	getPermissionStatus: '',
	getPermissionResult: [],
	currentRole: {},
	currentRoleStatus: '',
	updateRoleNameError: {},
	updateRoleNamePending: false,
	updateRoleNameSuccess: false,
	updateRoleNameFailed: false,
	roleNameData: {},
	updateRoleResponse: {},
	updateRoleStatus: '',
	rolesDataPending: false,
	rolesDataSuccess: false,
	rolesDataResult: [],
	rolesDataError: '',
	userPermissions: [],
	getUserPermissionsStatus: [],
	// getRolePermission: [],
};

export default function rolesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_ROLES_PENDING:
			return {
				...state,
				listRolesSuccess: 'pending',
			};
		case actionTypes.FETCH_ROLES_SUCCESS:
			return {
				...state,
				listRolesSuccess: 'success',
				listRolesResult: action.payload.entityList,
			};
		case actionTypes.FETCH_ROLES_FAILED:
			return {
				...state,
				listRolesSuccess: 'failed',
				listRolesError: action.error,
			};
		case actionTypes.ADD_ROLES_PENDING:
			return {
				...state,
				addRolesError: {},
				addRolesPending: true,
				addRolesSuccess: false,
				addRolesStatus: 'pending',
			};
		case actionTypes.ADD_ROLES_SUCCESS:
			return {
				...state,
				addRolesError: {},
				addRolesPending: false,
				addRolesSuccess: true,
				addRolesStatus: 'success',
			};
		case actionTypes.ADD_ROLES_FAILED:
			return {
				...state,
				addRolesError: action.error.messageList ? action.error.messageList : {},
				addRolesPending: false,
				addRolesSuccess: false,
				addRolesStatus: 'error',
			};

		case actionTypes.EDIT_ROLES_PENDING:
			return {
				...state,
				editRolesError: {},
				editRolesPending: true,
				editRolesSuccess: false,
				editRolesStatus: 'pending',
			};
		case actionTypes.EDIT_ROLES_SUCCESS:
			return {
				...state,
				editRolesError: {},
				editRolesPending: false,
				editRolesSuccess: true,
				editRolesStatus: 'success',
			};
		case actionTypes.EDIT_ROLES_FAILED:
			return {
				...state,
				editRolesError: action.error.messageList ? action.error.messageList : {},
				editRolesPending: false,
				editRolesSuccess: false,
				editRolesStatus: 'error',
			};
		case actionTypes.ASSIGN_ROLES_PENDING:
			return {
				...state,
				assignRolesError: {},
				assignRolesPending: true,
				assignRolesSuccess: false,
				assignRolesStatus: 'pending',
			};
		case actionTypes.ASSIGN_ROLES_SUCCESS:
			return {
				...state,
				assignRolesError: {},
				assignRolesPending: false,
				assignRolesSuccess: true,
				assignRolesStatus: 'success',
			};
		case actionTypes.ASSIGN_ROLES_FAILED:
			return {
				...state,
				assignRolesError: action.error.messageList ? action.error.messageList : {},
				assignRolesPending: false,
				assignRolesSuccess: false,
				assignRolesStatus: 'error',
			};
		case actionTypes.GET_PERMISSION_PENDING:
			return {
				...state,
				getPermissionStatus: 'pending',
				getPermissionResult: [],
			};
		case actionTypes.GET_PERMISSION_SUCCESS:
			setLocalStorageItem(
				'employeePermissions',
				getEncryptString(JSON.stringify(action.payload.entity.employeePermissions))
			);
			return {
				...state,
				getPermissionStatus: 'success',
				getPermissionResult: action.payload.entity,
			};
		case actionTypes.GET_PERMISSION_FAILED:
			return {
				...state,
				getPermissionStatus: 'error',
				getPermissionResult: [],
			};

		case actionTypes.UPDATE_ROLE_STATUS: {
			return INITIAL_STATE;
		}
		case actionTypes.GET_PERMISSIONS_SUCCESS: {
			return {
				...state,
				permissions: action.payload.entityList,
			};
		}
		case actionTypes.GET_PERMISSIONS_FAILED: {
			return {
				...state,
				permissions: action.payload.entityList,
			};
		}
		case actionTypes.GET_ROLE_PENDING:
			return {
				...state,
				currentRoleStatus: 'pending',
				currentRole: [],
				updateRoleStatus: '',
			};
		case actionTypes.GET_ROLE_SUCCESS:
			return {
				...state,
				currentRoleStatus: 'success',
				currentRole: { ...action.payload.entity, ...action.payload.pagingInfo },
			};
		case actionTypes.GET_ROLE_FAILED:
			return {
				...state,
				currentRoleStatus: 'failed',
				currentRole: [],
			};
		case actionTypes.RESET_CURRENT_ROLE:
			return {
				...state,
				currentRole: [],
			};
		case actionTypes.UPDATE_ROLENAME_PENDING:
			return {
				...state,
				updateRoleNameError: {},
				updateRoleNamePending: true,
				updateRoleNameSuccess: false,
				updateRoleNameFailed: false,
			};
		case actionTypes.UPDATE_ROLENAME_SUCCESS:
			return {
				...state,
				updateRoleNameError: {},
				updateRoleNamePending: false,
				updateRoleNameSuccess: true,
				updateRoleNameFailed: false,
				roleNameData: action.payload.entity,
			};
		case actionTypes.UPDATE_ROLENAME_FAILED:
			return {
				...state,
				updateRoleNameError: action.error.messageList ? action.error.messageList : {},
				updateRoleNamePending: false,
				updateRoleNameSuccess: false,
				updateRoleNameFailed: true,
			};
		case actionTypes.UPDATE_ROLE_PENDING:
			return {
				...state,
				updateRoleStatus: 'pending',
				updateRoleResponse: [],
			};
		case actionTypes.UPDATE_ROLE_STATUS_RESET:
			return {
				...state,
				updateRoleNamePending: false,
				updateRoleNameSuccess: false,
				updateRoleNameFailed: false,
			};
		case actionTypes.UPDATE_ROLE_SUCCESS:
			return {
				...state,
				updateRoleStatus: 'success',
				updateRoleResponse: action.payload.entity,
			};
		case actionTypes.UPDATE_ROLE_FAILED:
			return {
				...state,
				updateRoleStatus: 'failed',
				updateRoleResponse: action.error.messageList ? action.error.messageList : {},
			};
		case actionTypes.UPDATE_ROLE_RESET:
			return {
				...state,
				updateRoleStatus: '',
			};
		case actionTypes.FETCH_ROLES_LIST_PENDING:
			return {
				...state,
				rolesDataPending: true,
				rolesDataSuccess: false,
				//rolesDataResult: [],
				rolesDataError: '',
			};
		case actionTypes.FETCH_ROLES_LIST_SUCCESS:
			return {
				...state,
				rolesDataPending: false,
				rolesDataSuccess: true,
				rolesDataResult: action.payload.entityList,
				rolesDataError: '',
			};
		case actionTypes.FETCH_ROLES_LIST_FAILED:
			return {
				...state,
				rolesDataPending: false,
				rolesDataSuccess: false,
				//rolesDataResult: [],
				rolesDataError: action.error.messageList ? action.error.messageList : {},
			};
		case actionTypes.UPDATE_ROLES_LIST:
			return {
				...state,
				rolesDataPending: false,
				rolesDataSuccess: false,
				rolesDataResult: [],
				rolesDataError: {},
			};
		case actionTypes.GET_USER_PERMISSION_PENDING:
			return {
				...state,
				userPermissions: [],
				getUserPermissionsStatus: 'pending',
			};
		case actionTypes.GET_USER_PERMISSION_SUCCESS:
			return {
				...state,
				userPermissions: action.payload.entityList,
				getUserPermissionsStatus: 'success',
			};
		case actionTypes.GET_USER_PERMISSION_FAILED:
			return {
				...state,
				userPermissions: [],
				getUserPermissionsStatus: 'failed',
			};
		default:
			return state;
	}
}

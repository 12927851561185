import * as React from 'react';
import { useCallback, useState } from 'react';
import Select, { components, StylesConfig } from 'react-select';
import _ from 'lodash';
import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { getRandomBackgroundColor, getUserName, getRandomColor } from '../../../config/utils';
import { LeaderIcon, TeamCountIcon } from '../../../config/svg/formElementIcons';
import { NoSearchRecord } from './NoSearchRecord';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';

export const MultiSelectLoadMore: React.FC<any> = (props) => {
	const {
		selectedOptions,
		key,
		onSelectOption,
		placeHolder,
		isSingleSelection,
		closeMenuOnSelect,
		selectClassName,
		selectClassNamePrefix,
		isMulti,
		helperText,
		id,
		fieldMap,
		getOptions,
		disabled,
		isMenuOpen,
		hideLoadMore,
	} = props;
	const { t } = useTranslation();

	const [optionsList, setOptionsList] = useState<any>([]);
	const [paggingInfo, setPaggingInfo] = useState<any>({ pageIndex: 1, pageSize: 15, totalRecords: 0, totalPages: 1 });
	const [searchText, setSearchText] = useState<string>('');
	const [apiLoading, setApiLoading] = useState<boolean>(false);

	const performSearch = async (finder = '', pageIndex = 1, pageSize = 15, append = false) => {
		setApiLoading(true);
		if (hideLoadMore) {
			pageSize = 200;
		}
		const response = await getOptions('finder=' + finder + '&pageIndex=' + pageIndex + '&pageSize=' + pageSize);
		if (response.data.status === 200) {
			let options = Object.assign([], optionsList);
			let optionId = 0;
			let finalOptions = response.data.entityList.map((fieldValue: any) => {
				if (optionId === 0) {
					optionId = fieldValue[fieldMap['value']];
				}
				let fieldData: any = {};
				if (fieldMap['label']) {
					fieldData.label = fieldValue[fieldMap['label']];
				}
				if (fieldMap['value']) {
					fieldData.value = fieldValue[fieldMap['value']];
				}
				if (fieldMap['imagePath']) {
					fieldData.imagePath = fieldValue[fieldMap['imagePath']];
				}
				if (fieldMap['secondaryText']) {
					fieldData.secondaryText = fieldValue[fieldMap['secondaryText']];
				}
				if (fieldMap['count']) {
					fieldData.count = fieldValue[fieldMap['count']];
				}
				if (fieldValue['colorcode']) {
					fieldData.colorCode = fieldValue['colorcode'];
				}
				if (fieldValue['backGroundColorCode']) {
					fieldData.backGroundColorCode = fieldValue['backGroundColorCode'];
				}
				return fieldData;
			});
			if (append === true) {
				finalOptions = options.concat(finalOptions);
			}
			setOptionsList(finalOptions);
			setPaggingInfo(response.data.paggingInfo);
			setTimeout(() => {
				const selectedEl = document.getElementById('option-' + optionId);
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}, 15);
			setApiLoading(false);
		} else if (response.data.status === 204) {
			setApiLoading(false);
			setPaggingInfo({ pageIndex: 1, pageSize: 15, totalRecords: 0, totalPages: 1 });
		}
	};

	const formatOptionLabel = (props: any, context: any) => {
		const { value, label, imagePath, secondaryText, count, colorCode, backGroundColorCode } = props;
		if (context && context.context === 'value') {
			return (
				<Box className='search-user-result' id={`${'option-' + value}`}>
					<Box className='search-user-img'>
						{imagePath ? (
							<Avatar src={imagePath} />
						) : (
							<Avatar
								style={{
									backgroundColor: getRandomBackgroundColor(backGroundColorCode),
									color: getRandomColor(colorCode),
								}}
							>
								{getUserName({ fullName: label, firstName: '', lastName: '' })}
							</Avatar>
						)}
					</Box>
					<Box className='search-user-info'>
						{label.length > 25 ? (
							<Tooltip arrow title={label} aria-label='Team Name'>
								<Typography
									variant='h4'
									style={{
										color: getRandomColor(colorCode),
									}}
								>
									{label.substring(0, 25)}...
								</Typography>
							</Tooltip>
						) : (
							<Typography
								variant='h4'
								style={{
									color: getRandomColor(colorCode),
								}}
							>
								{label}
							</Typography>
						)}
					</Box>
				</Box>
			);
		}
		return (
			<Box className='search-user-result' id={`${'option-' + value}`}>
				<Box className='search-user-img'>
					{imagePath ? (
						<Avatar src={imagePath} />
					) : (
						<Avatar
							style={{
								backgroundColor: getRandomBackgroundColor(backGroundColorCode),
								color: getRandomColor(colorCode),
							}}
						>
							{getUserName({ fullName: label, firstName: '', lastName: '' })}
						</Avatar>
					)}
				</Box>
				<Box className='search-user-info'>
					{label.length > 25 ? (
						<Tooltip arrow title={label} aria-label='Team Name'>
							<Typography variant='h4'>{label.substring(0, 25)}...</Typography>
						</Tooltip>
					) : (
						<Typography variant='h4'>{label}</Typography>
					)}
					{secondaryText ? (
						<List component='ul' disablePadding>
							<ListItem>
								<Typography variant='subtitle2'>
									<TeamCountIcon />
									{count}
								</Typography>
							</ListItem>
							{secondaryText.trim() !== '' ? (
								<ListItem>
									<Typography variant='subtitle2'>
										<LeaderIcon />
										{secondaryText}
									</Typography>
								</ListItem>
							) : (
								<></>
							)}
						</List>
					) : (
						<></>
					)}
				</Box>
			</Box>
		);
	};

	const handleSearchThrottled = (value: any) => {
		delayedQuery(value);
	};

	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);

	const _handleSearch = async (value: string) => {
		if (value !== searchText) {
			setSearchText(value);
			performSearch(value, 1, paggingInfo.pageSize);
		}
	};

	const updateSelection = (selectedVal: any) => {
		if (isSingleSelection === true) {
			if (selectedOptions && selectedOptions.length > 0) {
				onSelectOption(selectedVal.filter((value: any) => value.value !== selectedOptions[0].value));
			} else {
				onSelectOption(selectedVal);
			}
		} else {
			if (isMulti === false) {
				onSelectOption([selectedVal]);
			} else {
				onSelectOption(selectedVal);
			}
		}
	};

	const loadMore = () => {
		performSearch(searchText, paggingInfo.pageIndex + 1, paggingInfo.pageSize, true);
	};

	const MenuList = (props: any) => {
		return (
			<components.MenuList {...props}>
				<Box className='menu-list'>{props.children}</Box>
				{!hideLoadMore && !apiLoading && paggingInfo.totalPages > 1 && paggingInfo.pageIndex !== paggingInfo.totalPages ? (
					<Box className='dropdown-footer'>
						<OKRButton className='btn-link' handleClick={loadMore} text={t('loadMore')}></OKRButton>
					</Box>
				) : (
					<></>
				)}
			</components.MenuList>
		);
	};
	const colourStyles: StylesConfig<any, true> = {
		multiValue: (styles: any, { data }: any) => {
			return {
				...styles,
				borderRadius: '24px',
				color: getRandomColor(data.colorCode),
				backgroundColor: `${getRandomBackgroundColor(data.backGroundColorCode)} !important`,
			};
		},
		multiValueLabel: (styles: any, { data }: any) => ({
			...styles,
			//	borderRadius: '24px 0px 0px 24px',
			color: getRandomColor(data.colorCode),
			//backgroundColor: getRandomBackgroundColor(data.backGroundColorCode),
		}),
		multiValueRemove: (styles: any, { data }: any) => ({
			...styles,
			color: getRandomColor(data.colorCode),
			//borderRadius: '0px 24px 24px 0px',
			//backgroundColor: getRandomBackgroundColor(data.backGroundColorCode),
			':hover': {
				//	backgroundColor: getRandomBackgroundColor(data.backGroundColorCode),
				color: `${getRandomColor(data.colorCode)} !important`,
			},
		}),
	};
	return (
		<Box className={`width-100 `}>
			<Select
				styles={colourStyles}
				key={key}
				id={id || ''}
				isMulti={isMulti}
				closeMenuOnSelect={closeMenuOnSelect === true}
				options={optionsList}
				value={selectedOptions}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeHolder}
				onChange={updateSelection}
				onInputChange={(value) => {
					handleSearchThrottled(value);
				}}
				noOptionsMessage={() => (apiLoading === true ? t('loading') : <NoSearchRecord />)}
				className={`${selectClassName} ${optionsList.length > 0 ? 'menu-open' : ''}`}
				classNamePrefix={selectClassNamePrefix}
				isClearable={false}
				isSearchable={true}
				components={{
					MenuList,
				}}
				onMenuOpen={() => {
					performSearch();
					isMenuOpen(true);
				}}
				onMenuClose={() => {
					isMenuOpen(false);
				}}
				isDisabled={disabled === true}
				inputId={`${id || 'input-id'}`}
				instanceId={`${id || 'input-placeholder'}`}
			/>
			{helperText && helperText !== '' ? <p className='MuiFormHelperText-root Mui-error'>{helperText}</p> : <></>}
		</Box>
	);
};

import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, ButtonGroup } from '@material-ui/core';
import { OKRButton } from '../OKRButton';
import { useTranslation } from 'react-i18next';
import { getUserDetails } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { TeamsOkr } from '../../Goals/TeamsOkr/TeamsOkr';

export const TeamSelection: React.FC<any> = (props) => {
	const { kr, okr, currentGoalId, setCurrentGoalId, contributors, setContributors, selectedTeams, setSelectedTeams } =
		props;
	const { t } = useTranslation();
	const loggedInUserDetail = getUserDetails();
	const goalTypes: any = [
		{ goalTypeId: 1, name: t('myTeamOkrTab'), toolTip: t('myTeamOkrTabToolTip') },
		{ goalTypeId: 2, name: t('mySelfTab'), toolTip: t('mySelfTabToolTip') },
	];
	const [showTeamsOkr, setShowTeamsOkr] = useState<boolean>(false);
	const [treeNodesData, setTreeNodesData] = React.useState<any[] | null>(null);
	const [showChips, setShowChips] = useState<boolean>(false);
	const [teamsLoading, setTeamsLoading] = useState<boolean>(true);
	const [teamDetails, setTeamDetails] = useState<any>(null);
	const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
	const [completeOrgData, setCompleteOrgData] = React.useState<any>([]);

	const onHandleAssignmentTypeClick = (event: any, item: any) => {
		if (item.goalTypeId !== currentGoalId) {
			setContributors([]);
		}
		setCurrentGoalId(item.goalTypeId);
		if (item.goalTypeId === 1) {
			getUserTeams();
		} else {
			setShowTeamsOkr(false);
			setSelectedTeams([]);
		}
	};
	const getUserTeams = async () => {
		setShowTeamsOkr(true);
		if (completeOrgData.length === 0) {
			setTeamsLoading(true);
			const apiRes = await props.getUserTeamsData('?goalType=1&empId=' + loggedInUserDetail?.employeeId);
			setTreeNodesData(null);
			if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
				setTeamsLoading(false);
				const filteredDataset = apiRes?.data?.entityList;
				setTeamDetails(filteredDataset || []);
				prepareTeamData(filteredDataset || []);
			}
		}
	};
	const prepareTeamNodeData = (orgData: any, secondaryText?: string) => {
		return {
			id: orgData.organisationId,
			fullname: orgData.organisationName,
			membersCount: orgData.membersCount,
			children: [],
			secondaryText: secondaryText,
			imagePath: orgData.imagePath,
			isParent: true,
			backGroundColorCode: orgData.backGroundColorCode,
			colorCode: orgData.colorCode,
			parentTeamBackGroundColorCode: orgData.parentTeamBackGroundColorCode,
			parentTeamColorCode: orgData.parentTeamColorCode,
			isMyTeam: true,
		};
	};
	const prepareTeamData = (data: any) => {
		let completeOrgData: any = [];
		for (let ii = 0; ii < data?.length; ii++) {
			let orgData = data[ii];
			let teamObj = prepareTeamNodeData(orgData, orgData.parentName);
			completeOrgData.push(teamObj);
		}
		let selVal: string[] = [];
		selectedTeams.forEach((selectedT: any) => {
			completeOrgData.forEach((orgData: any) => {
				if (orgData.id === selectedT.id) {
					orgData.isChecked = true;
					orgData.selected = true;
				}
			});
		});
		// console.log('completeOrgData', completeOrgData);
		setTreeNodesData(completeOrgData);
		setCompleteOrgData(completeOrgData);
		setSelectedValues(selVal);
	};

	const checkTeamUpdated = (teams: any) => {
		setSelectedTeams(teams);
	};
	return (
		<Box className='okr-assign-btn-grp'>
			<Box className='assigned-btn'>
				<FormLabel component='legend'>{t('assignmentFor')}</FormLabel>
				{goalTypes && goalTypes.length ? (
					<ButtonGroup className='kr-btn-group' disableElevation variant='contained' color='primary'>
						{goalTypes.map((item: any, index: any) =>
							loggedInUserDetail.isTeamLeader === false && item.goalTypeId === 1 ? (
								''
							) : (
								<OKRButton
									className={`teams-btn assign-${item?.name?.toLowerCase()} ${
										item.goalTypeId === currentGoalId ? 'active' : ''
									}`}
									id={`btn-${index}`}
									handleClick={(event: any) => {
										onHandleAssignmentTypeClick(event, item);
									}}
									title={item.toolTip}
									text={item.name}
									tooltipClassName='tooltip-layout5'
								/>
							)
						)}
					</ButtonGroup>
				) : (
					<></>
				)}
				<TeamsOkr
					{...props}
					open={showTeamsOkr}
					setOpen={setShowTeamsOkr}
					treeNodesData={treeNodesData}
					t={t}
					chartType={'okr'}
					setSelectedTeams={checkTeamUpdated}
					selectedTeams={selectedTeams}
					showChips={showChips}
					setShowChips={setShowChips}
					teamsLoading={teamsLoading}
					editEnabled={true}
					callTreeNodes={() => {
						setShowTeamsOkr(true);
					}}
					setDeletedNodes={(nodes: any) => {
						setSelectedTeams(nodes);
					}}
					teamDetails={teamDetails}
					setCompleteOrgData={setCompleteOrgData}
					completeOrgData={completeOrgData}
					setCallOut={() => {}}
					setAnchorElCallouts={() => {}}
				/>
			</Box>
		</Box>
	);
};

import React, { Fragment, useEffect, useState } from 'react';
import { Box, Slide, Dialog, DialogContent, DialogContentText, Typography } from '@material-ui/core';
import { PageHeader } from '../PageHeader';
import { OkrListing } from './OkrListing';
import { useTranslation } from 'react-i18next';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import {
	getEndDateForEveryMonthForQuarter,
	getUserDetails,
	getCurrentUserDetails,
	getOkrProgressDetails,
	getSelectedCycle,
} from '../../config/utils';
import { useSnackbar } from 'notistack';
import clone from 'clone';
import _ from 'lodash';
import { Enums } from '../../config/enums';
import './style.scss';
import { CancelIcon } from '../../config/svg/Action';
import { OKRButton } from '../Common/OKRButton';
import GuidedTour from '../Common/GuidedTour';

export const Goals: React.FC<any> = (props) => {
	const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const {
		isDashboard,
		listOkrResult,
		okrMasterData,
		addUpdateObjective,
		alignmentFilterData,
		recentContributorData,
		recentContibutorSuccess,
	} = props;

	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [showAddGoal, setShowAddGoal] = useState<Boolean>(false);
	const [durationCycleDetail, setDurationCycleDetail] = useState<any>(null);

	const [myGoalList, setMyGoalList] = useState<any>([]);
	const [myArchiveGoalList, setArchiveMyGoalList] = useState<any>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [originalGoalList, setOriginalGoalList] = useState<any>([]); //== for filter
	const [filterCondition, setFilterCondition] = useState<any>({
		score: [],
		type: [],
	});
	// const [dashboardDetails, setDashboardDetails] = useState<any>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// const [isUnLockRequested, setIsUnLockRequested] = useState<Boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [clearDashboardFilter, setClearDashboardFilter] = useState<Boolean>(false);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [maxObjectiveId, setMaxObjectiveId] = useState<Number>(0);
	const [formSubmit, setFormSubmit] = useState<Boolean>(false);
	const [isGoalCreated, setIsGoalCreated] = useState<Boolean>(false);
	const [goalDetail, setGoalDetail] = useState<any>({ actionType: '', goalDetail: {} });
	const [showArchiveList, setShowArchiveList] = useState<boolean>(false);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [alreadyShown, setAlreadyShown] = useState<boolean>(false);
	const [highlightGoalId, setHighlightGoalId] = useState<Number>(0);
	const [highlightKR, setHighlightKR] = useState<Number>(0);
	const year = getLocalStorageItem('currentYear') || null;
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || null;
	const currentCycleEndDate = getLocalStorageItem('currentCycleEndDate') || null;
	const currentCycleId: any = getLocalStorageItem('currentCycleId') || 0;
	const selectedYear = getLocalStorageItem('year') || year;
	const cycleId: any = getLocalStorageItem('cycleId') || 0;
	const isPeopleViewRedirectionInProgress: any = getLocalStorageItem('isPeopleViewRedirectionInProgress') || 0;
	const userDetail: any = getUserDetails();
	const currentUser: any = getCurrentUserDetails();
	const [tabZeroOnTeamCreateObjecive, setTabZeroOnTeamCreateObjecive] = useState<Boolean>(false);
	let getCycle = JSON.parse(getLocalStorageItem('otherCycleObjective') || '{}');

	useEffect(() => {
		if (!currentUser) {
			//== remove ActiveTab for alignment-map
			removeLocalStorageItem('activeTab');
		}

		removeLocalStorageItem('showCallouts');
		props.updateCalloutPanel('');
		if (getCycle && getCycle.cycleId && getCycle.year) {
			if (getCycle.cycleId === cycleId && props.listOkrSuccess === '') {
				// props.getOnboardingCount();
				if (!Boolean(props?.okrMasterData)) {
					props.getOkrMasterData();
				}
				fetchOkr(true);
				// props.getOkrStatusList(`organisationId=${userDetail?.organisationId}`);
				// fetchOkr(true);
			}
		} else if (!getCycle?.cycleId || !getCycle) {
			// props.getOnboardingCount();
			if (!Boolean(props?.okrMasterData)) {
				props.getOkrMasterData();
			}
			fetchOkr(true);
		}

		if (userDetail.employeeId && cycleId && selectedYear) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getDashboardProgress(data);
		}
		return () => {
			setShowAddGoal(false);
			clearAllFilter();
			setGoalDetail({});
		};
	}, [cycleId, selectedYear, props.location]);

	useEffect(() => {
		if (goalDetail && !goalDetail.showAttributeDrawer) {
			props.isAnyDrawerOpened({
				...props.appDrawerInfo,
				isDrawerOpened: false,
			});
		}
	}, [goalDetail]);

	useEffect(() => {
		props.isAnyDrawerOpened({
			...props.appDrawerInfo,
			isNewObjectiveFormOpened: showAddGoal,
		});
	}, [showAddGoal]);

	const fetchDashboardProgress = () => {
		if (userDetail.employeeId && cycleId && selectedYear) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getDashboardProgress(data);
		}
	};

	useEffect(() => {
		const goalListUpdated = updateExpandOption(false);
		setMyGoalList(goalListUpdated);
		if (props.listOkrSuccess === 'success') {
			removeLocalStorageItem('confidenceOkr');
			if (props.listOkrResult) {
				setMyGoalList(goalListUpdated);
				updateCheckedOption(false);
				// setDashboardDetails(props.listOkrResult);
				// setIsUnLockRequested(props.listOkrResult.isUnLockRequested);
				const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
				// if (props.listOkrResult.isDeltaVisible) {
				// 	props.getDeltaScore(data, false);
				// }
				if (!getLocalStorageItem('recentCalled')) {
					props.getRecentContributordDetail(data, false);
				}
				!currentUser && props.isCheckinSubmitted();
				props.getOkrStatusList(`organisationId=${userDetail?.organisationId}`);

				//==== check is highlightGoal is set and goal is exits in the list
				removeLocalStorageItem('reloadComplete');
				let highlightGoalId: any = getLocalStorageItem('highlightGoal');
				let highlightKR: any = getLocalStorageItem('goalType');
				if (getLocalStorageItem('highlightGoal')) {
					if (getLocalStorageItem('goalType')) {
						highlightKR = parseInt(highlightKR);
						setHighlightKR(highlightKR);
					}
					highlightGoalId = parseInt(highlightGoalId);
					setHighlightGoalId(highlightGoalId);

					setTimeout(() => {
						var element = document.getElementById('myGoal' + highlightGoalId);
						if (element) {
							element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
						}
					}, 2000);

					if (getLocalStorageItem('goalType') && getLocalStorageItem('goalType') === '2') {
						setTimeout(() => {
							setHighlightGoalId(0);
							setHighlightKR(0);
							removeLocalStorageItem('highlightGoal');
							removeLocalStorageItem('goalType');
							removeLocalStorageItem('highlightGoalKey');
							setMaxObjectiveId(0);
						}, 20000);
					} else {
						setTimeout(() => {
							setHighlightGoalId(0);
							setHighlightKR(0);
							removeLocalStorageItem('highlightGoal');
							removeLocalStorageItem('goalType');
							removeLocalStorageItem('highlightGoalKey');
							setMaxObjectiveId(0);
						}, 7000);
					}
				}

				const maxObjectiveIdLocal: any = getLocalStorageItem('maxGoalObjectiveId') || 0;
				if (maxObjectiveIdLocal) {
					setMaxObjectiveId(maxObjectiveIdLocal);
					setTimeout(() => {
						if (maxObjectiveId === 0 || maxObjectiveId === maxObjectiveIdLocal) {
							removeLocalStorageItem('maxGoalObjectiveId');
						}

						setMaxObjectiveId(0);
					}, 5000);
				}

				if (cycleId) {
					const data = `cycle=${cycleId}`;
					props.getConfidenceAlertDetails(data);
				}
				setRecentContributor(goalListUpdated);
			}

			if (currentUser && !alreadyShown) {
				setOpenDialog(true);
				setAlreadyShown(true);
				setTimeout(() => {
					setOpenDialog(false);
				}, 3000);
			}
		}
		return () => clearTimeout(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.listOkrSuccess, props.listOkrResult]);

	useEffect(() => {
		setRecentContributor();
	}, [recentContributorData, recentContibutorSuccess]);

	useEffect(() => {
		const durationForQuarter = getEndDateForEveryMonthForQuarter(
			cycleStartDate,
			currentCycleEndDate,
			year,
			cycleDuration
		);
		setDurationCycleDetail(durationForQuarter);
	}, [cycleStartDate, currentCycleEndDate, year, cycleDuration]);

	useEffect(() => {
		//check if user update any okrs
		if (alignmentFilterData && alignmentFilterData.length > 0) {
			setCardDashboard({});
			const filteredArray = originalGoalList.filter((item: any) => {
				const scoreArray = alignmentFilterData;
				const checkedProgress = scoreArray?.filter((it: any) => it.isChecked);
				if (checkedProgress.length > 0) {
					let scoreObject = checkedProgress.find((scoreItem: any) => {
						if (scoreItem.type === 'progress') {
							return item.okrProgress === scoreItem.code;
						}
						if (scoreItem.type === 'type') {
							if (scoreItem.code === 'Assigned') {
								return item.isAssigned;
							}
							if (scoreItem.code === 'Aligned') {
								return item.isAligned;
							}
							if (scoreItem.code === 'Standalone') {
								return item.assignmentTypeId === 1;
							}
							if (scoreItem.code === 'Individual') {
								return item.teamId === 0;
							}
							if (scoreItem.code === 'Aspirational') {
								return item.goalNatureId === 1;
							}
							if (scoreItem.code === 'Committed') {
								return item.goalNatureId === 2;
							}
						}
						if (scoreItem.type === 'private') {
							return item.isPrivate;
						}
					});
					if (typeof scoreObject === 'undefined') {
						return false;
					}
				}
				return true;
			});
			setMyGoalList(filteredArray);
		} else {
			setMyGoalList(originalGoalList);
		}

		if (alignmentFilterData && alignmentFilterData?.length) {
			props.isAnyDrawerOpened({
				...props.appDrawerInfo,
				isFilterApplied: true,
			});
		} else {
			props.isAnyDrawerOpened({
				...props.appDrawerInfo,
				isFilterApplied: false,
			});
		}
	}, [alignmentFilterData, listOkrResult, originalGoalList]);

	const setRecentContributor = (goalListUpdated: any = null) => {
		let recentData = recentContributorData
			? recentContributorData
			: JSON.parse(getLocalStorageItem('recentContributorData') || '{}');
		if ((recentContibutorSuccess === true || getLocalStorageItem('recentCalled') === 'true') && recentData) {
			let recentContributorList: any = {};
			recentData?.forEach((element: any) => {
				recentContributorList[element.goalKeyId] = element;
			});
			let goalData = goalListUpdated !== null ? goalListUpdated : myGoalList;
			if (goalData) {
				let recentL = Object.assign([], goalData);
				recentL.forEach((element: any) => {
					if (element.goalObjectiveId === 0) {
						if (recentContributorList[element.goalKeyId]) {
							element.hasRecent = recentContributorList[element.goalKeyId].recentContribution !== 0;
							element.recentContributorData = recentContributorList[element.goalKeyId];
						}
					} else if (element.goalObjectiveId !== 0 && element.myGoalsDetails && element.myGoalsDetails.length > 0) {
						element.myGoalsDetails.forEach((krElement: any) => {
							if (recentContributorList[krElement.goalKeyId]) {
								krElement.hasRecent = recentContributorList[krElement.goalKeyId].recentContribution !== 0;
								krElement.recentContributorData = recentContributorList[krElement.goalKeyId];
							}
						});
					}
				});
				setMyGoalList(recentL);
			}
			if (recentContributorData) {
				setLocalStorageItem('recentCalled', true);
				setLocalStorageItem('recentContributorData', JSON.stringify(recentContributorData));
			}
		}
	};
	/** fetch Okr */
	const fetchOkr = (isLoader: boolean) => {
		setGoalDetail({});
		setShowAddGoal(false);
		if (props.showRefreshSnackBar) {
			props.showRefreshSnackBar(false);
		}
		if (
			!currentUser &&
			userDetail &&
			userDetail.employeeId &&
			!isDashboard &&
			cycleId !== 0 &&
			!isPeopleViewRedirectionInProgress
		) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getDashboardDetail(data, isLoader);
		} else if (currentUser && currentUser.employeeId && cycleId !== 0 && !isPeopleViewRedirectionInProgress) {
			const data = `empId=${currentUser.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getEmployeeViewGoals(data);
		} else if (isDashboard && cycleId !== 0 && !currentUser && !isPeopleViewRedirectionInProgress) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			// added CASE: team - so that api can triggered when team-okr progress updated.
			if (
				props.okrType === 'goal' ||
				props.okrType === 'team' ||
				props.okrType === 'directReports' ||
				props.okrType === 'checkIns' ||
				props.okrType === 'organisation'
			) {
				props.getDashboardDetail(data, isLoader);
			}
		}
	};

	const showAddGoalForm = (show: Boolean) => {
		setFormSubmit(false);
		setGoalDetail({});
		setShowAddGoal(show);
	};

	const showAddGoalFormAndUpdateList = (show: boolean) => {
		setFormSubmit(false);
		setGoalDetail({});
		setShowAddGoal(show);
		setTabZeroOnTeamCreateObjecive(true);
	};

	const getUpdatedMainList = (objectiveData: any, objective: any, isExpand: boolean) => {
		const confidenceOkr = getLocalStorageItem('confidenceOkr');
		return objectiveData.map((item: any) => {
			item.myGoalsDetails = item.myGoalsDetails
				? item.myGoalsDetails.map((subItem: any) => ({
						...subItem,
						noOfContributor: subItem.contributors.length + 1,
				  }))
				: [];

			if (item.myGoalsDetails.find((sub: any) => sub.krStatusId === Enums.KR_STATUS_PENDING)) {
				item.expand = true;
			} else {
				if (objective && parseInt(objective) === item.goalObjectiveId) {
					item.expand = true;
				} else if (confidenceOkr && parseInt(confidenceOkr) === item.goalObjectiveId) {
					item.expand = true;
				} else {
					item.expand = isExpand;
				}
			}
			item.noOfContributor = item.contributors && item.contributors?.length ? item.contributors?.length + 1 : 0;
			let { okrProgress } = getOkrProgressDetails({
				value: item.score,
				dueDate: item.dueDate,
			});
			item.okrProgress = okrProgress;
			return item;
		});
	};

	const fetchArchiveList = () => {
		setArchiveMyGoalList([]);
		const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
		props.getArchiveDetail(data);
	};

	useEffect(() => {
		if (showArchiveList && props.listOkrSuccess === 'success') {
			fetchArchiveList();
		}
	}, [showArchiveList, props.listOkrSuccess]);

	useEffect(() => {
		if (props.archiveList?.myGoalOkrResponses?.length) {
			const objective = getLocalStorageItem('currentKey');
			setArchiveMyGoalList(getUpdatedMainList(props.archiveList.myGoalOkrResponses, objective, false));
		}
	}, [props.archiveList]);

	const updateExpandOption = (isExpand: boolean) => {
		const objective = getLocalStorageItem('currentKey');
		removeLocalStorageItem('currentKey');
		const allListData = (props.listOkrResult.myGoalOkrResponses && clone(props.listOkrResult.myGoalOkrResponses)) || [];
		// const allArchiveData = clone(allListData).filter((data: any) => data.goalStatusId === Enums.GOAL_STATUS_ARCHIVE);
		// setArchiveMyGoalList(getUpdatedMainList(allArchiveData, objective, isExpand));
		const allObjectiveData = clone(allListData).filter((data: any) => data.goalStatusId !== Enums.GOAL_STATUS_ARCHIVE);
		let goalList = getUpdatedMainList(allObjectiveData, objective, isExpand);
		setOriginalGoalList(goalList);
		return goalList;
	};

	const updateCheckedOption = (checked: boolean) => {
		let okrStatusDetails =
			props.listOkrStatusResult.okrStatusDetails &&
			props.listOkrStatusResult.okrStatusDetails.map((item: any) => {
				item.checked = checked;
				return item;
			});
		let objectiveDetails =
			props.listOkrStatusResult.objectiveDetails &&
			props.listOkrStatusResult.objectiveDetails.map((item: any) => {
				item.checked = checked;
				return item;
			});
		setFilterCondition({
			...filterCondition,
			score: okrStatusDetails,
			type: objectiveDetails,
		});
	};

	const scrollTo = (e: any) => {
		let element = document.getElementById('myObjective');
		let topPos = element?.offsetTop;
		topPos = topPos ? topPos - 50 : 0;
		if (element) {
			setTimeout(function () {
				window.scroll({ top: topPos, left: 0, behavior: 'smooth' });
			}, 200);
		}
	};

	//======= Sorting in listing
	const sortGoalList = (e: any, column: any) => {
		let sortClass: any = 'desc';
		if (column === sortingColumn) {
			sortClass = sortOrder === 'asc' ? 'desc' : 'asc';
		}

		let list = myGoalList.map((subArray: any) => ({
			...subArray,
			myGoalsDetails: _.orderBy(subArray.myGoalsDetails, column, sortClass),
		}));
		list = _.orderBy(list, column, sortClass);
		setMyGoalList(list);
		setSortOrder(sortClass);
		setSortingColumn(column);
	};

	//========= Filter condistion
	const filterList = (event: any, type: any, obj: any) => {
		setClearDashboardFilter(true);
		event.stopPropagation();
		if (event.target.checked) {
			if (type === 'score') {
				const updatedFilterCond = filterCondition.score.map((item: any) => {
					if (item.id === obj.id) {
						item.checked = true;
					}
					return item;
				});
				setFilterCondition({ ...filterCondition, score: updatedFilterCond });
			} else if (type === 'type') {
				const updatedFilterCond = okrMasterData.okrTypes.map((item: any) => {
					if (item.id === obj.id) {
						item.checked = true;
					}
					return item;
				});

				setFilterCondition({ ...filterCondition, type: updatedFilterCond });
			}

			filterGoalList();
		} else {
			uncheckFilterCondition(type, obj);
			filterGoalList();
		}
	};

	const uncheckFilterCondition = (type: any, obj: any) => {
		if (type === 'score') {
			const updatedFilterCond = filterCondition.score.map((item: any) => {
				if (item.id === obj.id) {
					item.checked = false;
				}
				return item;
			});
			setFilterCondition({ ...filterCondition, score: updatedFilterCond });
		} else if (type === 'type') {
			const updatedFilterCond = filterCondition.type.map((item: any) => {
				if (item.objectiveId === obj.objectiveId) {
					item.checked = false;
				}
				return item;
			});
			setFilterCondition({ ...filterCondition, type: updatedFilterCond });
		}

		filterGoalList();
	};

	const filterGoalList = () => {
		const filteredArray = originalGoalList.filter((item: any) => {
			const scoreArray = filterCondition.score;
			const checkedProgress = scoreArray?.filter((it: any) => it.checked);
			if (checkedProgress && checkedProgress.length > 0) {
				const scoreObject = checkedProgress.find((scoreItem: any) => item.okrProgress === scoreItem.code);
				if (typeof scoreObject === 'undefined') {
					return false;
				}
			}
			const privateArray = okrMasterData?.okrTypes;
			const checkedType = privateArray?.filter((it: any) => it.checked);
			if (checkedType && checkedType.length > 0) {
				const privateObject = checkedType.find((privateItem: any) => {
					if (privateItem.statusName === 'Assigned') {
						return item.isAssigned;
					}
					if (privateItem.statusName === 'Aligned') {
						return item.isAssigned;
					}

					// if (privateItem.objectiveId === 1) {
					// 	return item.isPrivate === false;
					// } else if (privateItem.objectiveId === 2) {
					// 	return item.isPrivate === true;
					// }
					// return false;
				});
				if (typeof privateObject === 'undefined') {
					return false;
				}
			}

			return true;
		});
		setMyGoalList(filteredArray);
	};

	const clearAllFilter = () => {
		setClearDashboardFilter(false);
		updateCheckedOption(false);
		filterGoalList();
	};

	const setDefaultData = (data: any, masterData: any, type: number) => {
		const { goalTypes, goalStatus } = okrMasterData;
		// if (data.goalTypeId === Enums.ZERO) {
		// 	const { goalTypeId } = goalTypes.find((item: any) => item.isDefault);
		// 	data.goalTypeId = goalTypeId;
		// }
		if (data.goalStatusId === Enums.ZERO) {
			const { goalStatusId } = goalStatus.find((item: any) => item.isDefault);
			data.goalStatusId = goalStatusId;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = goalStatusId || item.goalStatusId;
					return item;
				});
			}
		}
		setGoalStatusId(type, data);
	};

	const setGoalStatusId = (type: number, data: any) => {
		if (type === Enums.TWO && data.goalStatusId === Enums.GOAL_STATUS_DRAFT) {
			data.goalStatusId = type;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = type || item.goalStatusId;
					return item;
				});
			}
		}

		if (type === Enums.THREE || type === Enums.FOUR) {
			data.goalStatusId = Enums.GOAL_STATUS_DRAFT;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = Enums.GOAL_STATUS_DRAFT || item.goalStatusId;
					return item;
				});
			}
		}
	};

	const saveAndEditObjectiveAndKr = async (objectiveDetail: any, type: number, isAdd: Boolean) => {
		let maxGoalObjectiveId = 0;
		if (listOkrResult.myGoalOkrResponses) {
			maxGoalObjectiveId = Math.max(...listOkrResult.myGoalOkrResponses.map((o: any) => o.goalObjectiveId), 0);
		}
		if (parseInt(objectiveDetail.objectiveCycleId) === parseInt(cycleId)) {
			setLocalStorageItem('maxGoalObjectiveId', maxGoalObjectiveId);
		}
		/**---Max Id ------------ */
		setFormSubmit(true);
		const updatedGoalDetail = objectiveDetail.myGoalsDetails.map((item: any) => {
			item.keyDescription = item?.keyDescription?.trim();
			setGoalStatusId(type, item);
			item.goalStatusId = objectiveDetail?.goalStatusId || item.goalStatusId;
			item.contributors = objectiveDetail?.isPrivate
				? []
				: item.contributors.map((contributor: any) => {
						setGoalStatusId(type, contributor);
						return contributor;
				  });

			return item;
		});
		const filterData = updatedGoalDetail.filter((elem: any) => !elem.isNewKey);

		let data = {
			...objectiveDetail,
			objectiveName: objectiveDetail?.objectiveName.trim(),
			endDate: objectiveDetail?.endDate ? objectiveDetail?.endDate : objectiveDetail?.dueDate,
			myGoalsDetails: filterData,
			isSavedAsDraft: type === 3 ? true : false,
		};
		/** set default data to save goals */
		setDefaultData(data, okrMasterData, type);

		try {
			const dataToSave = isAdd ? [{ ...data }] : { ...data };
			const response = await addUpdateObjective(dataToSave, isAdd);
			if (response && response.data.status === Enums.STATUS_SUCCESS) {
				if (
					objectiveDetail.objectiveCycleId !== parseInt(props.userSelectedCycle.organisationCycleId) &&
					!objectiveDetail?.isCoach
				) {
					props.resetOkr();
					setShowArchiveList(false);
					setFormSubmit(false);
					showAddGoalForm(false);
					setLocalStorageItem('goalType', objectiveDetail.goalTypeId);
					setLocalStorageItem('highlightGoal', response.data.entityList[0].goalObjectiveId);
					redirectToCycle(objectiveDetail.objectiveCycleId);
					setTimeout(() => {
						enqueueSnackbar(
							type === Enums.THREE
								? t('successMessageForOkrDraft')
								: Boolean(response) && response.data && response.data.messageList
								? response.data.messageList.Result
								: t('successMessageForOkrCreation'),
							{
								variant: type === Enums.THREE ? 'info' : 'success',
								autoHideDuration: 3000,
							}
						);
					}, 1200);
				} else if (objectiveDetail?.isCoach && type === 3) {
					redirectToCycle(objectiveDetail.objectiveCycleId);
				} else {
					setShowArchiveList(false);
					setFormSubmit(false);
					if (type === Enums.ONE) {
						showAddGoalForm(true);
						fetchOkr(true);
						fetchDashboardProgress();
					} else if (type === Enums.TWO || type === Enums.THREE) {
						showAddGoalForm(false);
						fetchOkr(true);
						fetchDashboardProgress();
					} else if (type === Enums.FOUR) {
						const responseModal = isAdd
							? response?.data?.entityList && response?.data?.entityList.length
								? response?.data?.entityList[0]
								: {}
							: response?.data?.entity || {};
						return responseModal || {};
					}

					setTimeout(() => {
						enqueueSnackbar(
							type === Enums.THREE
								? t('successMessageForOkrDraft')
								: Boolean(response) && response.data && response.data.messageList
								? response.data.messageList.Result
								: t('successMessageForOkrCreation'),
							{
								variant: type === Enums.THREE ? 'info' : 'success',
								autoHideDuration: 3000,
							}
						);
					}, 1200);
					return 'success';
				}
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				setFormSubmit(false);
				const errorMessages = Object.values(response?.data?.messageList);
				if (errorMessages && errorMessages.length > 0) {
					errorMessages.forEach((item: any) =>
						enqueueSnackbar(item, {
							variant: 'error',
							autoHideDuration: 5000,
						})
					);
				}
				return 'error';
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				setFormSubmit(false);
				enqueueSnackbar(t('goalAddedFail'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
				return 'error';
			}
		} catch (e) {
			setFormSubmit(false);
			console.error(e);
		}
	};

	const showHideArchiveList = (show: number) => {
		if (show === 0) {
			setShowArchiveList(false);
		} else {
			setShowArchiveList(true);
		}
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	const [cardDashboard, setCardDashboard] = useState<any>({});

	/**
	 * Handle card on all dashboard
	 * @param e HTML Event
	 * @param cardType String
	 */
	const handleCardClick = (e: any, cardType: string) => {
		e.preventDefault();
		let dashboardData = { ...cardDashboard };
		props.addAlignmentFilter([]);
		setCardDashboard({
			...dashboardData,
			[cardType]: !dashboardData[cardType],
		});
	};

	useEffect(() => {
		if (Object.keys(cardDashboard).length > 0) {
			if (Object.values(cardDashboard).every((card: any) => card === false)) {
				props.isAnyDrawerOpened({
					...props.appDrawerInfo,
					isFilterApplied: false,
				});
			} else {
				props.isAnyDrawerOpened({
					...props.appDrawerInfo,
					isFilterApplied: true,
				});
			}
		}
	}, [cardDashboard]);

	useEffect(() => {
		if (props.showRefreshOptionSnackBar) {
			setTimeout(() => {
				props.showRefreshSnackBar && props.showRefreshSnackBar(false);
			}, 4000);
		}
	}, [props.showRefreshOptionSnackBar]);

	useEffect(() => {
		filterMyOkr(originalGoalList);
	}, [cardDashboard, originalGoalList]);

	useEffect(() => {
		if (props.showRefreshOptionSnackBar) {
			setTimeout(() => {
				props.showRefreshSnackBar && props.showRefreshSnackBar(false);
			}, 4000);
		}
	}, [props.showRefreshOptionSnackBar]);

	const filterMyOkr = (myOkrs: any) => {
		let isFilter = 0;
		if (Object.keys(cardDashboard).length) {
			const filteredArray = myOkrs.filter((item: any) => {
				const filterObj = cardDashboard;
				for (const property in filterObj) {
					isFilter += filterObj[property] ? 1 : 0;
					if (filterObj[property] && property === item.okrProgress) {
						return item;
					}
				}
			});
			setMyGoalList(isFilter === 0 ? myOkrs : filteredArray);
		}
	};
	useEffect(() => {
		if (props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && !currentUser && !alreadyShown) {
			setOpenDialog(true);
			setAlreadyShown(true);
			setTimeout(() => {
				setOpenDialog(false);
			}, 3000);
		}
	}, [props.userSelectedCycle]);

	const redirectToCycle = (cycleId: number) => {
		const allCycleDetails = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
		const cycleDetails = props.teamCycleDetails || allCycleDetails?.cycleDetails;
		if (cycleDetails?.length) {
			let cycleObj = getSelectedCycle(cycleId, cycleDetails);
			if (cycleObj?.quarter) {
				props.updateCurrentCycleId(cycleObj?.quarter);
			}
		}
	};

	return (
		<Fragment>
			{(Boolean(currentUser) ||
				(props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID &&
					props.userSelectedCycle?.organisationCycleId !== parseInt(cycleId))) && (
				<Dialog
					className='search-dialog'
					open={openDialog}
					onClose={handleDialogClose}
					aria-labelledby='responsive-dialog-title'
					TransitionComponent={Slide}
					transitionDuration={{ enter: 500, exit: 800 }}
				>
					<DialogContent>
						<DialogContentText>
							<Typography variant='h4'>
								{currentUser
									? t('watchingObjective', { name: `${currentUser.firstName} ${currentUser.lastName}` })
									: props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
									? t('watchingOKRS', { name: `${props.userSelectedCycle.label}` })
									: ''}
							</Typography>
							<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleDialogClose} />
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)}

			<PageHeader
				{...props}
				showAddGoalForm={showAddGoalFormAndUpdateList}
				showAddGoal={showAddGoal}
				t={t}
				scrollTo={scrollTo}
				year={year}
				currentCycleId={currentCycleId}
				showArchiveList={showArchiveList}
			/>
			<Box className={`wrapper-container ${showAddGoal ? 'create-form-display' : ''}`} id='wrap-container'>
				<Box className='my-goals-area' id='myObjective'>
					<OkrListing
						{...props}
						showAddGoal={showAddGoal}
						showAddGoalForm={showAddGoalForm}
						currentCycleId={currentCycleId}
						currentCycleEndDate={currentCycleEndDate}
						fetchOkr={fetchOkr}
						setMyGoalList={setMyGoalList}
						myGoalList={showArchiveList ? myArchiveGoalList : myGoalList}
						showArchiveList={showArchiveList}
						showHideArchiveList={showHideArchiveList}
						setArchiveMyGoalList={setArchiveMyGoalList}
						t={t}
						currentUser={currentUser}
						durationCycleDetail={durationCycleDetail}
						year={year}
						filterCondition={filterCondition}
						clearAllFilter={clearAllFilter}
						uncheckFilterCondition={uncheckFilterCondition}
						filterList={filterList}
						sortGoalList={sortGoalList}
						sortingColumn={sortingColumn}
						sortOrder={sortOrder}
						maxObjectiveId={maxObjectiveId}
						highlightGoalId={highlightGoalId}
						setHighlightGoalId={setHighlightGoalId}
						highlightKR={highlightKR}
						setIsGoalCreated={setIsGoalCreated}
						saveAndEditObjectiveAndKr={saveAndEditObjectiveAndKr}
						setFormSubmit={setFormSubmit}
						formSubmit={formSubmit}
						isGoalCreated={isGoalCreated}
						setGoalDetail={setGoalDetail}
						goalDetail={goalDetail}
						myArchiveGoalList={myArchiveGoalList}
						isLocked={props.listOkrResult && props.listOkrResult.isLocked}
						scrollTo={scrollTo}
						handleCardClick={handleCardClick}
						cardDashboard={cardDashboard}
						setCardDashboard={setCardDashboard}
						filterMyOkr={filterMyOkr}
						tabZeroOnTeamCreateObjecive={tabZeroOnTeamCreateObjecive}
						setTabZeroOnTeamCreateObjecive={setTabZeroOnTeamCreateObjecive}
						isFutureCycle={
							(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID) ||
							(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.CURRENT_CYCLE_ID)
								? false
								: true
						}
						isPastCycle={props?.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID}
						redirectToCycleId={redirectToCycle}
					/>
				</Box>
			</Box>

			{isGuidedTourShow === 'true' && <GuidedTour {...props} />}
		</Fragment>
	);
};

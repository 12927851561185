import React from 'react';

export const PersonalFeedbackIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M28.6921 15.0536C27.4101 13.6675 25.1958 13.6255 23.875 14.9486C22.5542 13.6255 20.3496 13.6675 19.0579 15.0536C17.7079 16.5133 17.629 19.051 19.0579 20.3567L23.875 24.5312L28.6921 20.3567C30.121 19.051 30.0421 16.5133 28.6921 15.0536Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0.75 10C0.75 5.99594 3.99594 2.75 8 2.75H20.4437C24.4477 2.75 27.6937 5.99594 27.6937 10V11C27.6937 11.6904 27.134 12.25 26.4437 12.25C25.7533 12.25 25.1937 11.6904 25.1937 11V10C25.1937 7.37665 23.067 5.25 20.4437 5.25H8C5.37665 5.25 3.25 7.37665 3.25 10V22.6087C3.25 23.6018 4.53604 23.9943 5.09059 23.1706C6.16133 21.58 7.95311 20.6262 9.87051 20.6262H14.8906C15.581 20.6262 16.1406 21.1859 16.1406 21.8762C16.1406 22.5666 15.581 23.1262 14.8906 23.1262H9.87051C8.78502 23.1262 7.77064 23.6662 7.16446 24.5666C5.23186 27.4375 0.75 26.0695 0.75 22.6087V10Z'
			fill='#292929'
		/>
	</svg>
);
export const ViewFeedback = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M27.1578 15.2385C25.9681 13.957 23.9132 13.9182 22.6875 15.1414C21.4618 13.9182 19.4159 13.957 18.2172 15.2385C16.9645 16.5878 16.8912 18.9338 18.2172 20.1409L22.6875 24L27.1578 20.1409C28.4838 18.9338 28.4105 16.5878 27.1578 15.2385Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.60922 3.4573C4.54845 3.52126 4.5 3.62145 4.5 3.75V26.25C4.5 26.3785 4.54845 26.4787 4.60922 26.5427C4.66871 26.6053 4.72916 26.625 4.78125 26.625H23.7188C24.5472 26.625 25.2188 27.2966 25.2188 28.125C25.2188 28.9534 24.5472 29.625 23.7188 29.625H4.78125C2.89749 29.625 1.5 28.0405 1.5 26.25V3.75C1.5 1.95947 2.89749 0.375 4.78125 0.375H19.0313C19.4424 0.375 19.8356 0.54378 20.1187 0.841876L25.4625 6.46688C25.7273 6.74565 25.875 7.11548 25.875 7.5V9.5625C25.875 10.3909 25.2034 11.0625 24.375 11.0625C23.5466 11.0625 22.875 10.3909 22.875 9.5625V8.09891L18.3873 3.375H4.78125C4.72916 3.375 4.66871 3.39468 4.60922 3.4573ZM7.21875 10.5C7.21875 9.67157 7.89032 9 8.71875 9H16.6875C17.5159 9 18.1875 9.67157 18.1875 10.5C18.1875 11.3284 17.5159 12 16.6875 12H8.71875C7.89032 12 7.21875 11.3284 7.21875 10.5ZM7.59375 17.625C7.59375 16.7966 8.26532 16.125 9.09375 16.125H12.1875C13.0159 16.125 13.6875 16.7966 13.6875 17.625C13.6875 18.4534 13.0159 19.125 12.1875 19.125H9.09375C8.26532 19.125 7.59375 18.4534 7.59375 17.625Z'
			fill='#292929'
		/>
	</svg>
);
export const GiveFeedbackIcon = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M13.2103 3.1227C11.8642 1.66724 9.53921 1.62314 8.15235 3.01244C6.76549 1.62314 4.45065 1.66724 3.09439 3.1227C1.67693 4.65534 1.59406 7.31997 3.09439 8.69093L8.15235 13.0742L13.2103 8.69093C14.7106 7.31997 14.6278 4.65534 13.2103 3.1227Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M19.5615 12.0479C19.4082 11.955 19.2162 11.9726 19.0817 12.0889C19.035 12.1292 18.9737 12.1937 18.862 12.328C18.8447 12.3488 18.8243 12.3736 18.8015 12.4015C18.707 12.5166 18.5699 12.6837 18.433 12.8299L14.2109 17.339V25.4983C14.2228 25.514 14.2399 25.5348 14.2639 25.5607C14.3929 25.6998 14.6268 25.8786 14.9671 26.059C15.6748 26.4341 16.4697 26.625 16.8558 26.625H23.1165C23.4266 26.625 23.7237 26.4776 23.9157 26.2191L26.625 22.5717V19.2749C26.625 18.6932 26.1617 18.2464 25.6207 18.2464H19.36C18.8412 18.2464 18.3592 17.9782 18.0856 17.5374C17.812 17.0966 17.7856 16.5457 18.0158 16.0807L19.7244 12.6303C19.8305 12.4161 19.7505 12.1624 19.5615 12.0479ZM14.1865 25.4614C14.1867 25.4614 14.1877 25.4631 14.1892 25.4666C14.1872 25.4631 14.1864 25.4614 14.1865 25.4614ZM17.1196 9.81949C18.2089 8.87766 19.8176 8.69544 21.1159 9.482C22.6487 10.4106 23.2047 12.3625 22.4129 13.9616L21.7767 15.2464H25.6207C27.8458 15.2464 29.625 17.0637 29.625 19.2749V23.0678C29.625 23.39 29.5213 23.7036 29.3292 23.9622L26.324 28.008C25.5702 29.0228 24.3828 29.625 23.1165 29.625H16.8558C15.8588 29.625 14.5812 29.2498 13.5622 28.7097C13.0392 28.4325 12.4961 28.0664 12.0641 27.6006C11.6373 27.1402 11.2109 26.4602 11.2109 25.5964V16.7464C11.2109 16.3656 11.3557 15.9991 11.6159 15.7211L16.2432 10.7794C16.3071 10.7112 16.3593 10.6476 16.4398 10.5497C16.4728 10.5096 16.5106 10.4636 16.5559 10.4092C16.6884 10.2499 16.8783 10.0281 17.1196 9.81949ZM8.02734 15.2464C8.85577 15.2464 9.52734 15.9179 9.52734 16.7464V27.739C9.52734 28.5674 8.85577 29.239 8.02734 29.239C7.19892 29.239 6.52734 28.5674 6.52734 27.739V16.7464C6.52734 15.9179 7.19892 15.2464 8.02734 15.2464Z'
			fill='#292929'
		/>
	</svg>
);
export const AskFeedbackIcon = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M17.5312 14.9211C17.5312 12.4866 19.4968 10.5 21.9375 10.5C24.3782 10.5 26.3438 12.4866 26.3438 14.9211C26.3438 17.3555 24.3782 19.3421 21.9375 19.3421C19.4968 19.3421 17.5312 17.3555 17.5312 14.9211ZM21.9375 13.5C21.168 13.5 20.5312 14.129 20.5312 14.9211C20.5312 15.7131 21.168 16.3421 21.9375 16.3421C22.707 16.3421 23.3438 15.7131 23.3438 14.9211C23.3438 14.129 22.707 13.5 21.9375 13.5ZM17.7171 25.125H26.1473C26.0521 24.7775 25.894 24.4572 25.686 24.1758C24.9864 23.2294 24.1033 22.6382 23.3906 22.6382H20.4844C19.2777 22.6382 18.0561 23.719 17.7171 25.125ZM14.625 25.8945C14.625 22.8157 17.0885 19.6382 20.4844 19.6382H23.3906C25.547 19.6382 27.2092 21.1896 28.0984 22.3926C28.822 23.3714 29.25 24.5853 29.25 25.8945V26.625C29.25 27.4534 28.5784 28.125 27.75 28.125H16.125C15.2966 28.125 14.625 27.4534 14.625 26.625V25.8945Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.25 4.875C7.13687 4.875 3.75 8.2875 3.75 12.5625C3.75 14.783 4.4432 16.4647 5.46384 17.5753C6.47973 18.6808 7.9009 19.3125 9.5625 19.3125H13.3125C14.1409 19.3125 14.8125 19.9841 14.8125 20.8125C14.8125 21.6409 14.1409 22.3125 13.3125 22.3125H9.5625C7.0991 22.3125 4.86402 21.3563 3.25491 19.6053C1.65055 17.8594 0.75 15.4162 0.75 12.5625C0.75 6.68927 5.422 1.875 11.25 1.875C13.8755 1.875 16.0012 2.26949 17.7415 3.13282C19.5083 4.00927 20.7607 5.31063 21.7237 6.91576C22.15 7.62613 21.9196 8.54752 21.2092 8.97374C20.4989 9.39996 19.5775 9.16961 19.1513 8.45924C18.4268 7.25187 17.5708 6.39698 16.4083 5.82031C15.2194 5.23051 13.5951 4.875 11.25 4.875Z'
			fill='#292929'
		/>
		<path
			d='M14.2996 9.76035C13.4381 8.77464 11.9501 8.74477 11.0625 9.68567C10.1749 8.74477 8.69342 8.77464 7.82541 9.76035C6.91824 10.7983 6.8652 12.6029 7.82541 13.5314L11.0625 16.5L14.2996 13.5314C15.2598 12.6029 15.2068 10.7983 14.2996 9.76035Z'
			fill='#292929'
		/>
	</svg>
);

export const RequestOneOnOneIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			d='M8.25 5.25C8.25 4.42157 8.92157 3.75 9.75 3.75C10.5784 3.75 11.25 4.42157 11.25 5.25C11.25 6.07843 10.5784 6.75 9.75 6.75C8.92157 6.75 8.25 6.07843 8.25 5.25Z'
			fill='#292929'
		/>
		<path
			d='M13.5 5.25C13.5 4.42157 14.1716 3.75 15 3.75C15.8284 3.75 16.5 4.42157 16.5 5.25C16.5 6.07843 15.8284 6.75 15 6.75C14.1716 6.75 13.5 6.07843 13.5 5.25Z'
			fill='#292929'
		/>
		<path
			d='M18.75 5.25C18.75 4.42157 19.4216 3.75 20.25 3.75C21.0784 3.75 21.75 4.42157 21.75 5.25C21.75 6.07843 21.0784 6.75 20.25 6.75C19.4216 6.75 18.75 6.07843 18.75 5.25Z'
			fill='#292929'
		/>
	</svg>
);

export const RequestOneOnOneIcon2 = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			d='M8.25 5.25C8.25 4.42157 8.92157 3.75 9.75 3.75C10.5784 3.75 11.25 4.42157 11.25 5.25C11.25 6.07843 10.5784 6.75 9.75 6.75C8.92157 6.75 8.25 6.07843 8.25 5.25Z'
			fill='#292929'
		/>
		<path
			d='M13.5 5.25C13.5 4.42157 14.1716 3.75 15 3.75C15.8284 3.75 16.5 4.42157 16.5 5.25C16.5 6.07843 15.8284 6.75 15 6.75C14.1716 6.75 13.5 6.07843 13.5 5.25Z'
			fill='#292929'
		/>
		<path
			d='M18.75 5.25C18.75 4.42157 19.4216 3.75 20.25 3.75C21.0784 3.75 21.75 4.42157 21.75 5.25C21.75 6.07843 21.0784 6.75 20.25 6.75C19.4216 6.75 18.75 6.07843 18.75 5.25Z'
			fill='#292929'
		/>
	</svg>
);
export const CancelRequest = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.375 5.57349C9.375 4.74506 10.0466 4.07349 10.875 4.07349H19.3125C20.1409 4.07349 20.8125 4.74506 20.8125 5.57349C20.8125 6.40191 20.1409 7.07349 19.3125 7.07349H10.875C10.0466 7.07349 9.375 6.40191 9.375 5.57349Z'
			fill='#292929'
		/>
	</svg>
);
export const RequestFeedbackAgain = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.84926 14.8026C3.84926 12.0439 6.04495 9.75 8.82353 9.75C11.6021 9.75 13.7978 12.0439 13.7978 14.8026C13.7978 17.5614 11.6021 19.8553 8.82353 19.8553C6.04495 19.8553 3.84926 17.5614 3.84926 14.8026ZM8.82353 12.75C7.76454 12.75 6.84926 13.6373 6.84926 14.8026C6.84926 15.968 7.76454 16.8553 8.82353 16.8553C9.88252 16.8553 10.7978 15.968 10.7978 14.8026C10.7978 13.6373 9.88252 12.75 8.82353 12.75ZM15.9191 14.8026C15.9191 12.0058 18.1948 9.75 20.9877 9.75C23.7807 9.75 26.0564 12.0058 26.0564 14.8026C26.0564 17.5995 23.7807 19.8553 20.9877 19.8553C18.1948 19.8553 15.9191 17.5995 15.9191 14.8026ZM20.9877 12.75C19.8389 12.75 18.9191 13.6753 18.9191 14.8026C18.9191 15.9299 19.8389 16.8553 20.9877 16.8553C22.1366 16.8553 23.0564 15.9299 23.0564 14.8026C23.0564 13.6753 22.1366 12.75 20.9877 12.75ZM3.42173 26.625H14.2252C14.128 25.9992 13.8843 25.4269 13.5331 24.9436C12.8485 24.0013 11.7669 23.4079 10.5607 23.4079H7.0864C5.26412 23.4079 3.70718 24.7791 3.42173 26.625ZM1.875 29.625C1.04657 29.625 0.375 28.9534 0.375 28.125V27.2366C0.375 23.4967 3.34852 20.4079 7.0864 20.4079H10.5607C12.2097 20.4079 13.7144 21.0131 14.8774 22.0078C16.0107 20.9834 17.462 20.4079 19.2034 20.4079H22.7721C26.5504 20.4079 29.625 23.4586 29.625 27.2366V28.125C29.625 28.9534 28.9534 29.625 28.125 29.625H1.875ZM17.2454 26.625H26.576C26.2817 24.8055 24.6958 23.4079 22.7721 23.4079H19.2034C18.1269 23.4079 17.3185 23.7582 16.6827 24.4351C16.9833 25.1143 17.1778 25.8519 17.2454 26.625Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15.136 0C15.9645 0 16.636 0.671573 16.636 1.5V4.07353H19.3125C20.1409 4.07353 20.8125 4.7451 20.8125 5.57353C20.8125 6.40196 20.1409 7.07353 19.3125 7.07353H16.636V9.75C16.636 10.5784 15.9645 11.25 15.136 11.25C14.3076 11.25 13.636 10.5784 13.636 9.75V7.07353H10.875C10.0466 7.07353 9.375 6.40196 9.375 5.57353C9.375 4.7451 10.0466 4.07353 10.875 4.07353H13.636V1.5C13.636 0.671573 14.3076 0 15.136 0Z'
			fill='#292929'
		/>
	</svg>
);

export const PraiseFeedIcon = () => (
	<svg width='25' height='20' viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.27429 6.68002L1.27179 6.66238L1.26805 6.64496C0.905505 4.95767 1.50586 3.26738 2.84798 2.13169L2.86826 2.11453L2.88661 2.09532C3.8597 1.07634 5.33712 0.5 6.68806 0.5C8.14795 0.5 9.48746 1.1827 10.5037 1.93341C11.0073 2.30549 11.4194 2.68539 11.7122 2.98574C11.8585 3.13582 11.9734 3.26433 12.0537 3.35998C12.1091 3.426 12.1389 3.46571 12.1516 3.48264C12.1603 3.49418 12.161 3.49513 12.1564 3.48661L12.5362 4.19351L13.0034 3.54114C13.1087 3.39414 13.7427 2.6211 14.7534 1.88938C15.7629 1.15854 17.1049 0.5 18.6401 0.5C20.1191 0.5 21.4643 1.07206 22.4415 2.09532L22.4599 2.11453L22.4801 2.13169C23.8223 3.26738 24.4226 4.95767 24.0601 6.64496L24.0563 6.66238L24.0538 6.68002C23.7389 8.9079 22.2063 11.4416 20.0999 13.7573C18.0063 16.059 15.4044 18.0808 13.0479 19.3011L12.7098 19.4585L12.2627 19.292C9.91087 18.0708 7.31669 16.0533 5.22821 13.7573C3.12179 11.4416 1.58922 8.9079 1.27429 6.68002Z'
			fill='#E3E6EA'
			stroke='#A1ABB9'
		/>
	</svg>
);
export const OkrIcon = () => (
	<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M5.4172 0.566408C5.14734 0.296549 4.70981 0.296549 4.43995 0.566408L0.984859 4.0215C0.715 4.29136 0.715 4.72889 0.984859 4.99875L4.43995 8.45384C4.70981 8.7237 5.14734 8.7237 5.4172 8.45384C5.68706 8.18398 5.68706 7.74645 5.4172 7.47659L3.14175 5.20114H12.7025C13.0842 5.20114 13.3936 4.89176 13.3936 4.51013C13.3936 4.12849 13.0842 3.81911 12.7025 3.81911H3.14175L5.4172 1.54366C5.68706 1.2738 5.68706 0.836268 5.4172 0.566408Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.79072 8.84722C8.79072 7.54111 9.84507 6.47656 11.1527 6.47656C12.4604 6.47656 13.5147 7.54111 13.5147 8.84722C13.5147 10.1533 12.4604 11.2179 11.1527 11.2179C9.84507 11.2179 8.79072 10.1533 8.79072 8.84722ZM11.1527 7.79488C10.5795 7.79488 10.109 8.26287 10.109 8.84722C10.109 9.43158 10.5795 9.89957 11.1527 9.89957C11.726 9.89957 12.1964 9.43158 12.1964 8.84722C12.1964 8.26287 11.726 7.79488 11.1527 7.79488ZM8.41902 15.0456H13.8855C13.8451 14.7079 13.7174 14.3984 13.5257 14.1392C13.1019 13.5658 12.5274 13.1491 12.0041 13.1491H10.3013C9.39222 13.1491 8.53312 14.0155 8.41902 15.0456ZM7.08789 15.2768C7.08789 13.5694 8.45632 11.8308 10.3013 11.8308H12.0041C13.1618 11.8308 14.0787 12.6695 14.5858 13.3555C14.9828 13.8925 15.2175 14.5582 15.2175 15.2768V15.7048C15.2175 16.0688 14.9224 16.364 14.5584 16.364H7.74705C7.38301 16.364 7.08789 16.0688 7.08789 15.7048V15.2768Z'
			fill='#292929'
		/>
	</svg>
);

import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { AddIconSvg } from '../../config/svg/CommonSvg';
import { AddNewVersionPopup } from './AddNewVersionPopup';

interface WhatsNewTabPanelInterface {
	children: any;
	index: any;
	value: any;
}

export const WhatsNewTabPanel: React.FC<WhatsNewTabPanelInterface> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`admin-tab-${index}`}
			aria-labelledby={`whats-new-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

function a11yProps(index: number) {
	return {
		id: `whats-new-tabpanel-${index}`,
		'aria-controls': `whats-new-tabpanel-${index}`,
	};
}

export const WhatsNewTabs: React.FC<any> = (props) => {
	const { handleTabChange = {}, tabSelected = 0, isUserLoggedIn } = props;
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeValue = (event: any) => {
		const { value, name } = event.target;
	};

	return (
		<Box className='whats-tabs-wrap'>
			<Tabs
				className='okr-tabs whats-new-tab'
				value={tabSelected}
				onChange={handleTabChange}
				aria-label='WhatsNew Tabs'
			>
				<Tab label={t('updatesLabel')} value={0} {...a11yProps(0)} />
				{/* <Tab label={t('comingSoon')} value={1} {...a11yProps(1)} /> */}
			</Tabs>

			{isUserLoggedIn ? (
				<AddNewVersionPopup
					buttonIcon={<AddIconSvg />}
					buttonClassName={'add-btn'}
					buttonTitle={'addNewVersion'}
					LabelText={'addVersionName'}
					placeHolderText={'versionNumberPlaceholder'}
					type={'Add'}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};

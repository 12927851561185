import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	Avatar,
	Box,
	Typography,
	ListItem,
	List,
	TextField,
	InputAdornment,
	Button,
	Chip,
	Popover,
	Tooltip,
} from '@material-ui/core';
import {
	getUserName,
	getRandomBackgroundColor,
	getRandomColor,
	formatDate,
	abbreviateNumber,
	getCurrentQuarterAndYear,
} from '../../../config/utils';
import { AddIconSvg, EditIcon, ExternalContributorIcon } from '../../../config/svg/CommonSvg';
import { Enums } from '../../../config/enums';
import { NumberFormatComponent } from '../../Common/NumberFormat';
import { NumbersIcon, PercentageIcon } from '../../../config/svg/MyGoalSvg';
import moment from 'moment';
import { OKRDrawer } from '../../Common/OKRDrawer';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { OKRButton } from '../../Common/OKRButton';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import '../../../styles/pages/drawer/assignTargetPanel.scss';

export const ContributorList: React.FC<any> = (props) => {
	const {
		open,
		t,
		keyAttribute,
		contributorList,
		completeOrgData,
		getCurrencyIcon,
		selectedMetricDetail,
		handleBack,
		handleNext,
		handleCancel,
		targetValue,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		showAssignKrDateCallouts,
		setShowAssignKrDateCallouts,
		showAssignKrTargetCallouts,
		setShowAssignKrTargetCallouts,
		setShowAssignKrTitleCallouts,
		showAssignKrTitleCallouts,
		calloutsData,
		saveButtonText,
	} = props;
	const [selectedUsersData, setSelectedUsersData] = useState<any[]>([]);
	const [openTarget, setOpenTarget] = useState<boolean>(false);
	const [openId, setOpenId] = useState<any>(0);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [prevValue, setPrevValue] = useState<any>({});
	const [hasError, setHasError] = useState<boolean>(false);
	const { year } = getCurrentQuarterAndYear();
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [defaultSelectedUsersData, setDefaultSelectedUsersData] = useState([]);

	useEffect(() => {
		if (contributorList && contributorList.length > 0 && completeOrgData.length > 0) {
			setSelectedUsersData(contributorList);
			isSaveButtonActive();
		}
	}, [completeOrgData, contributorList]);

	useEffect(() => {
		isSaveButtonActive();
	}, [selectedUsersData]);

	const checkNumber = (value: any, oldValue: any) => {
		const regexp = /^[0-9\b]+$/;
		const regexpWithDecimal = /^(\d*\.)?\d+$/;
		if (selectedMetricDetail.selectedMetric.metricId && selectedMetricDetail.selectedMetric.metricId === 2) {
			// this is for decimal
			if (value === '') {
				return '';
			} else if (value !== '' || regexpWithDecimal.test(value)) {
				return parseFloat(!Boolean(value) ? 0 : value);
			} else {
				return oldValue;
			}
		} else {
			// this is for number only
			if (value === '') {
				return '';
			} else if (value !== '' || regexp.test(value)) {
				return parseInt(!Boolean(value) ? 0 : value);
			} else {
				return oldValue;
			}
		}
	};
	const getDateDifference = (date: any, otherDate: any, keyName: string) => {
		const datesDifferenceInDays = moment(new Date(otherDate || new Date())).isAfter(
			new Date(formatDate(date) || new Date()),
			'days'
		);
		const daysDiff = moment(new Date(formatDate(date) || new Date())).diff(
			moment(new Date(otherDate || new Date())),
			'days'
		);
		return keyName === 'dueDate' ? (daysDiff > 0 ? date : '') : datesDifferenceInDays ? date : '';
	};
	const getValidField = (field: string, fieldType: string, value: any, treeData: any) => {
		if (fieldType === 'date') {
			let otherKey = 'dueDate';
			let errorMsg = 'Start date should be greater than due date';
			if (field === 'dueDate') {
				otherKey = 'startDate';
				errorMsg = 'Due date should not be lesser than start date';
			}
			if (treeData[otherKey]) {
				treeData[field] = getDateDifference(value, treeData[otherKey], field);
			} else {
				treeData[field] = value;
			}
			if (treeData[field] === '') {
				if (treeData['invalid']) {
					treeData['invalid'][field] = errorMsg;
				} else {
					treeData['invalid'] = {};
					treeData['invalid'][field] = errorMsg;
				}
			} else if (treeData['invalid']) {
				delete treeData['invalid'][field];
			}
		} else {
			treeData[field] = checkNumber(value, treeData[field]);
			if (treeData['targetValue'] && treeData['startValue'] && treeData['targetValue'] === treeData['startValue']) {
				if (treeData['invalid']) {
					treeData['invalid']['targetValue'] = 'Current and Target value is same';
				} else {
					treeData['invalid'] = { targetValue: 'Current and Target value is same' };
				}
			} else if (
				treeData['targetValue'] &&
				treeData['startValue'] &&
				treeData['targetValue'] !== treeData['startValue'] &&
				treeData['invalid']
			) {
				delete treeData['invalid']['targetValue'];
			}
		}
		return treeData;
	};
	const valueChanged = (field: any, fieldType: any, value: any, treeData: any) => {
		if (fieldType === 'date') {
			value = formatDate(value);
		}
		let nodeData: any = getValidField(field, fieldType, value, treeData);
		let treeData1 = Object.assign([], selectedUsersData);
		treeData1.forEach((element: any) => {
			if (element.id === nodeData.id) {
				element = nodeData;
			}
		});
		setSelectedUsersData(treeData1);
	};

	const getCurrencyIconSpan = () => {
		let icon: any = '';
		if (props.selectedMetricDetail.selectedMetric.name === 'Percentage') {
			icon = <PercentageIcon />;
		} else if (props.selectedMetricDetail.selectedMetric.name === 'Numbers') {
			icon = <NumbersIcon />;
		} else {
			icon = props.getCurrencyIcon(
				props.selectedMetricDetail.selectedCurrencyDetail?.name
					? props.selectedMetricDetail.selectedCurrencyDetail.name
					: 'Dollar'
			);
		}
		return <span className='currency-span'>{icon}</span>;
	};
	const handleTarget = (e: any, selectedUser: any) => {
		e.preventDefault();
		e.stopPropagation();
		let data = Object.assign({}, selectedUser);
		setPrevValue(data);
		setAnchorEl(e.currentTarget);
		setOpenTarget(anchorEl ? false : true);
		setOpenId(data.id);
	};
	const handleClosePopup = () => {
		setAnchorEl(null);
		setOpenTarget(false);
		setOpenId(0);
		setPrevValue({});
	};
	const isSaveButtonActive = () => {
		let isError: boolean = false;
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			selectedUsersData.forEach((userData: any) => {
				//if (userData.targetValue === 0 || userData.targetValue === '') {
				if (userData.targetValue === userData.startValue) {
					isError = true;
				}
			});
		}
		setHasError(isError);
	};
	//callouts
	const assignTarget: any = useRef();
	const inputRefcommonDueDate: any = useRef();
	const inputRefcommonDueDateOther: any = useRef();
	const inputRefstartTarget: any = useRef();
	const inputRefstartTargetOther: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel('assignTargetDrawer');
		}, 500);
		let listContributors = JSON.parse(JSON.stringify(contributorList));
		setDefaultSelectedUsersData(listContributors);

		return () => {
			props.updateCalloutPanel && props.updateCalloutPanel('');
		};
	}, []);
	useEffect(() => {
		setShowAssignKrTargetCallouts && setShowAssignKrTargetCallouts(false);
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		if (
			open &&
			props.activeOnboardingPanel === 'assignTargetDrawer' &&
			getLocalStorageItem('showCallouts') &&
			getLocalStorageItem('assignTargetDrawercalloutDone') === null &&
			assignTarget.current
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(assignTarget.current);
				setCallOut && setCallOut(calloutsData[6]);
			}, 700);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [props.activeOnboardingPanel && open]);
	useEffect(() => {
		//Callout for prev Assign Kr Title
		if (showAssignKrTitleCallouts && assignTarget) {
			setAnchorElCallouts && setAnchorElCallouts(assignTarget.current);
			setCallOut && setCallOut(calloutsData[6]);
			setShowAssignKrTargetCallouts(false);
		}
	}, [showAssignKrTitleCallouts]);
	useEffect(() => {
		//Callout for Assign Due Date
		if (showAssignKrDateCallouts && inputRefcommonDueDate && inputRefcommonDueDate.current) {
			setAnchorElCallouts && setAnchorElCallouts(inputRefcommonDueDate.current);
			setCallOut && setCallOut(calloutsData[8]);
			setShowAssignKrTargetCallouts(false);
		}
	}, [showAssignKrDateCallouts]);
	useEffect(() => {
		//Callout for Assign Target
		if (showAssignKrTargetCallouts && inputRefstartTarget && inputRefstartTarget.current) {
			setShowAssignKrDateCallouts(false);
			setShowAssignKrTitleCallouts(false);
			setAnchorElCallouts && setAnchorElCallouts(inputRefstartTarget.current);
			if (selectedMetricDetail.selectedMetric.name === 'NoUnits') {
				setCallOut && setCallOut({ ...calloutsData[7], text: 'No Unit does not require targets' });
			} else if (selectedMetricDetail.selectedMetric.name === 'Boolean') {
				setCallOut && setCallOut({ ...calloutsData[7], text: 'Bullion Unit is simply measured in Y/N' });
			} else {
				setCallOut && setCallOut(calloutsData[7]);
			}
		}
	}, [showAssignKrTargetCallouts]);

	const checkAssignContributorUpdated = (e: any) => {
		let isUpdated: boolean = false;
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			contributorList.forEach((userData: any, index: any) => {
				if (
					userData.dueDate !== defaultSelectedUsersData[index]['dueDate'] ||
					userData.startValue !== defaultSelectedUsersData[index]['startValue'] ||
					userData.targetValue !== defaultSelectedUsersData[index]['targetValue']
				) {
					isUpdated = true;
				}
			});
		} else {
			contributorList.forEach((userData: any, index: any) => {
				if (userData.dueDate !== defaultSelectedUsersData[index]['dueDate']) {
					isUpdated = true;
				}
			});
		}
		if (isUpdated === false) {
			handleCancel(e);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		} else {
			setSubModalProps(true);
		}
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
			handleCancel(event);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
		setSubModalProps(false);
	};

	return (
		<Fragment>
			{open ? (
				<Box
					className={
						keyAttribute && !keyAttribute.goalKeyId ? 'createdPopover' : 'contributor-details-popover teams-section-kr'
					}
				>
					<OKRDrawer
						open={open}
						transitionDuration={{ enter: 500, exit: 1000 }}
						headerTitle={t('AssignTarget')}
						headerPostFix={targetValue === 0 ? '' : { value: targetValue, icon: getCurrencyIconSpan() }}
						children={
							<Box className='drawer-inner-content'>
								<Box className='drawer-content-subpanel'>
									<Box className='assign-target-panel' {...({ ref: assignTarget } as any)}>
										<Box className='kr-contributor-listing' onClick={() => {}}>
											<Box className='kr-contributor-list-head'>
												<List component='ul'>
													<ListItem className='user-col1'>
														<Typography variant='body2'>{t('nameLabel')}</Typography>
													</ListItem>
													<ListItem className='user-col2'>
														<Typography variant='body2'>{t('startTarget')}</Typography>
													</ListItem>
													<ListItem className='user-col3'>
														<Typography variant='body2'>{t('dueDate')}</Typography>
													</ListItem>
												</List>
											</Box>

											<Box className='kr-contributor-list-content'>
												{selectedUsersData.length > 0 &&
													selectedUsersData.map((selectedUser: any, index: number) => (
														<Box className='kr-contributor-list-row' id={`kr-contributor-list-${index}`}>
															<Box>
																<List component='ul'>
																	<ListItem className='user-col1' id={`kr-contributor-list-${index}`}>
																		<Box className='user-info'>
																			<Box className='user-img'>
																				{selectedUser && selectedUser.imagePath ? (
																					<Avatar alt={`${selectedUser.fullname}`} src={selectedUser.imagePath} />
																				) : (
																					<Avatar
																						style={{
																							backgroundColor: getRandomBackgroundColor(
																								selectedUser.backGroundColorCode
																							),
																							color: getRandomColor(selectedUser.colorCode),
																						}}
																					>
																						{getUserName({
																							firstName: '',
																							lastName: '',
																							fullName: selectedUser.fullName,
																						})}
																					</Avatar>
																				)}
																			</Box>

																			<Box className='user-info-details'>
																				<Typography variant='h4'>{selectedUser.fullName}</Typography>
																				<Typography variant='subtitle2'>
																					{selectedUser.secondaryText ? (
																						<span className='secondary-text-span'>{selectedUser.secondaryText}</span>
																					) : (
																						''
																					)}
																				</Typography>
																				{selectedUser && selectedUser?.teamName && (
																					<Box className='teams-chips'>
																						{selectedUser?.isExternal && (
																							<Tooltip
																								title={
																									!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																										? t('externalContributorTooltip')
																										: ''
																								}
																								arrow
																							>
																								<span className='external-link'>
																									{' '}
																									<ExternalContributorIcon />{' '}
																								</span>
																							</Tooltip>
																						)}
																						<Tooltip
																							title={
																								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																									? selectedUser?.teamName
																									: ''
																							}
																							arrow
																						>
																							<Chip
																								className='chip'
																								style={{
																									backgroundColor: getRandomBackgroundColor(
																										selectedUser?.teambackGroundColorCode
																									),
																									color: getRandomColor(selectedUser?.teamcolorCode),
																								}}
																								label={selectedUser?.teamName}
																							/>
																						</Tooltip>
																					</Box>
																				)}
																			</Box>
																		</Box>
																	</ListItem>
																	<ListItem
																		className='user-col2'
																		ref={index === 0 ? inputRefstartTarget : inputRefstartTargetOther}
																	>
																		{selectedMetricDetail.selectedMetric.metricId &&
																			selectedMetricDetail.selectedMetric.metricId !== 5 &&
																			selectedMetricDetail.selectedMetric.metricId !== 4 && (
																				<Box className='display-flex'>
																					{openTarget && openId === selectedUser.id ? (
																						<Box className='edit_icon-target'>
																							{prevValue &&
																								((prevValue.startValue !== '' && prevValue.startValue !== 0) ||
																									(prevValue.targetValue !== 0 && prevValue.targetValue !== '')) &&
																								((selectedUser.startValue !== 0 && selectedUser.startValue !== '') ||
																									(selectedUser.targetValue !== 0 &&
																										selectedUser.targetValue !== '')) && (
																									<Box className='display-flex'>
																										<Typography variant='body1'>
																											{getCurrencyIconSpan()}
																											{prevValue.startValue === ''
																												? 0
																												: abbreviateNumber(prevValue.startValue)}{' '}
																											/{getCurrencyIconSpan()}
																											{prevValue.targetValue === ''
																												? 0
																												: abbreviateNumber(prevValue.targetValue)}
																										</Typography>
																									</Box>
																								)}
																						</Box>
																					) : (
																						((selectedUser.startValue !== 0 && selectedUser.startValue !== '') ||
																							(selectedUser.targetValue !== 0 && selectedUser.targetValue !== '')) && (
																							<Box className='edit_icon-target'>
																								<Box className='display-flex'>
																									<Typography variant='body1'>
																										{getCurrencyIconSpan()}
																										{selectedUser.startValue === ''
																											? 0
																											: abbreviateNumber(selectedUser.startValue)}{' '}
																										/ {getCurrencyIconSpan()}
																										{selectedUser.targetValue === ''
																											? 0
																											: abbreviateNumber(selectedUser.targetValue)}
																									</Typography>
																								</Box>
																							</Box>
																						)
																					)}
																					{(openTarget &&
																						openId === selectedUser.id &&
																						prevValue &&
																						(prevValue.startValue === 0 || prevValue.startValue === '') &&
																						(prevValue.targetValue === 0 || prevValue.targetValue === '')) ||
																					((selectedUser.startValue === 0 || selectedUser.startValue === '') &&
																						(selectedUser.targetValue === 0 || selectedUser.targetValue === '')) ? (
																						<OKRButton
																							className='target-btn'
																							id='target-btn'
																							disabled={false}
																							icon={<AddIconSvg />}
																							text={t('target')}
																							handleClick={(e) => {
																								handleTarget(e, selectedUser);
																							}}
																						/>
																					) : (
																						<OKRButton
																							className={'edit-btn'}
																							id='edit-btn'
																							icon={<EditIcon />}
																							title={
																								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																									? t('editLabel')
																									: ''
																							}
																							handleClick={(e) => {
																								handleTarget(e, selectedUser);
																							}}
																						/>
																					)}
																					<Popover
																						className='popper-contributor-progress'
																						open={openTarget && openId === selectedUser.id}
																						anchorEl={anchorEl}
																						onClose={handleClosePopup}
																						anchorOrigin={{
																							vertical: 'bottom',
																							horizontal: 'center',
																						}}
																						transformOrigin={{
																							vertical: 'top',
																							horizontal: 'center',
																						}}
																					>
																						<Box className='kr-metrics-fileds kr-metrics-full-width'>
																							<Box className='kr-metrics-fileds-inner'>
																								<Box display='flex'>
																									<CurrentInputValue
																										keyAttribute={keyAttribute}
																										selectedMetricDetail={selectedMetricDetail}
																										data={selectedUser}
																										valueChanged={valueChanged}
																										getCurrencyIcon={getCurrencyIcon}
																									/>
																									<Box className='matrics-field-seprator' component='span'>
																										{'/'}
																									</Box>
																									<TargetInputValue
																										keyAttribute={keyAttribute}
																										selectedMetricDetail={selectedMetricDetail}
																										data={selectedUser}
																										valueChanged={valueChanged}
																										getCurrencyIcon={getCurrencyIcon}
																									/>
																								</Box>
																							</Box>
																						</Box>
																					</Popover>
																				</Box>
																			)}
																		{selectedMetricDetail.selectedMetric.metricId &&
																			selectedMetricDetail.selectedMetric.metricId === 5 &&
																			selectedMetricDetail.selectedMetric.description}
																		{selectedMetricDetail.selectedMetric.metricId &&
																			selectedMetricDetail.selectedMetric.metricId === 4 &&
																			selectedMetricDetail.selectedMetric.description}
																	</ListItem>
																	<ListItem
																		className='user-col3'
																		ref={index === 0 ? inputRefcommonDueDate : inputRefcommonDueDateOther}
																	>
																		<Box className='set-timeline-area'>
																			<Box className='calender-date helperText-bottom'>
																				{/*getMonthDate(selectedUser.dueDate)*/}
																				<DueDateInput
																					data={selectedUser}
																					selectedCycleDetails={props.userSelectedCycle}
																					okrDueDate={keyAttribute?.dueDate}
																					durationCycleDetail={props.durationCycleDetail}
																					valueChanged={valueChanged}
																				/>
																			</Box>
																		</Box>
																	</ListItem>
																</List>
															</Box>
														</Box>
													))}
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						}
						moreButton={false}
						handleDrawerClose={(e: any) => {
							checkAssignContributorUpdated(e);
						}}
						handleSaveClick={(event: any) => {
							handleNext(2);
							/*setAnchorElCallouts && setAnchorElCallouts(null);
							setCallOut && setCallOut(initialCallout);
							props.updateCalloutPanel && props.updateCalloutPanel('');
							if (saveButtonText !== 'Next') {
								setTimeout(() => {
									props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
								}, 500);
							}*/
						}}
						handleCancelClick={(e: any) => {
							checkAssignContributorUpdated(e);
						}}
						hideDefaultButton={false}
						isSaveButtonDisabled={hasError}
						saveButtonText={saveButtonText}
						showBackButton={true}
						backButton={
							<Button
								className='btn-link'
								onClick={() => {
									handleBack(0);
									setAnchorElCallouts && setAnchorElCallouts(null);
									setCallOut && setCallOut(initialCallout);
								}}
							>
								{<KeyboardBackspaceIcon />}
								{t('back')}
							</Button>
						}
						isAnyDrawerOpened={props.isAnyDrawerOpened}
						appDrawerInfo={props.appDrawerInfo}
						drawerClassName={'main-drawer-panel main-drawer-subpanel'}
						// hideCloseIcon={true}
					/>
				</Box>
			) : (
				<span></span>
			)}
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};

export const CurrentInputValue = (props: any) => {
	return (
		<TextField
			className='current-value'
			id='current-value'
			label={'Start'}
			placeholder={props.selectedMetricDetail.selectedMetric.metricId === 2 ? '0' : '0'}
			value={props.data['startValue']}
			fullWidth
			onChange={(e) => {
				props.valueChanged('startValue', 'text', e.target.value, props.data);
			}}
			inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
			InputProps={{
				...(props.selectedMetricDetail.selectedMetric.name !== 'Percentage' && {
					inputComponent: NumberFormatComponent,
				}),
				startAdornment: (
					<InputAdornment position='start'>
						{props.selectedMetricDetail.selectedMetric.name === 'Percentage' ? (
							<PercentageIcon />
						) : props.selectedMetricDetail.selectedMetric.name === 'Numbers' ? (
							<NumbersIcon />
						) : (
							props.getCurrencyIcon(
								props.selectedMetricDetail.selectedCurrencyDetail?.name
									? props.selectedMetricDetail.selectedCurrencyDetail.name
									: 'Dollar'
							)
						)}
					</InputAdornment>
				),
			}}
			error={props.data['invalid'] && props.data['invalid']['startValue'] ? true : false}
			helperText={
				props.data['invalid'] && props.data['invalid']['startValue'] ? props.data['invalid']['startValue'] : ''
			}
		/>
	);
};

export const TargetInputValue = (props: any) => {
	return (
		<TextField
			className='target-value'
			id='target-value'
			label={'Target'}
			placeholder={props.selectedMetricDetail.selectedMetric.metricId === 2 ? '0' : '0'}
			value={props.data['targetValue']}
			fullWidth
			onChange={(e: any) => {
				props.valueChanged('targetValue', 'text', e.target.value, props.data);
			}}
			inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
			InputProps={{
				...(props.selectedMetricDetail.selectedMetric.name !== 'Percentage' && {
					inputComponent: NumberFormatComponent,
				}),
				startAdornment: (
					<InputAdornment position='start'>
						{props.selectedMetricDetail.selectedMetric.name === 'Percentage' ? (
							<PercentageIcon />
						) : props.selectedMetricDetail.selectedMetric.name === 'Numbers' ? (
							<NumbersIcon />
						) : (
							props.getCurrencyIcon(
								props.selectedMetricDetail.selectedCurrencyDetail?.name
									? props.selectedMetricDetail.selectedCurrencyDetail.name
									: 'Dollar'
							)
						)}
					</InputAdornment>
				),
			}}
			error={props.data['invalid'] && props.data['invalid']['targetValue'] ? true : false}
			helperText={
				props.data['invalid'] && props.data['invalid']['targetValue'] ? props.data['invalid']['targetValue'] : ''
			}
		/>
	);
};

export const DueDateInput = (props: any) => {
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	useEffect(() => {
		props.data['okrDueDate'] && setSelectedDateRange(moment(new Date(props.data['okrDueDate'])));
	}, [props]);
	const valueChanged = (value: any, field: any, type: any) => {
		props.valueChanged(field, type, value, props.data);
	};

	return (
		<ReactDateRangePicker
			type={'ContributorList'}
			selectedCycleDetails={props.selectedCycleDetails}
			startDateValue={props.data['dueDate'] || new Date()}
			minimumDate={props.data['startDate'] || new Date()}
			maximumDate={props['okrDueDate'] || props.data['dueDate'] || new Date()}
			handleDateSelection={(range: any, start: any, end: any, selectionType: String, isCycleChanged: Boolean) => {
				range && setSelectedDateRange(range);
				valueChanged(new Date(formatDate(start)), 'dueDate', 'date');
			}}
			isDateRangeTextFieldVisible={false}
			isOnlyDueDateVisible={true}
			allCycleDetails={[]}
			isCycleVisible={false}
			isEndOfMonthVisible={false}
			numberOfCalendars={Enums.ONE}
			selectionType={'single'}
			selectedDateRange={selectedDateRange}
			setSelectedDateRange={setSelectedDateRange}
		/>
	);
};

import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderTabs } from './HeaderTabs';

export const Header: React.FC<any> = (props) => {
	const { tabSelected, setTabSelectedTeam } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	// const [open, setOpen] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (tabSelected) {
			setAnchorEl(null);
			// setOpen(false);
		}
	}, [tabSelected]);
	useEffect(() => {
		document.body.classList.add('admin-body-class');
		document.body.classList.remove('user-body-class');
		return () => {
			document.body.classList.remove('admin-body-class');
		};
	}, [history.location]);
	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-report-subheader');
		if (window.pageYOffset >= 88) {
			objElement?.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement?.classList.remove('sticky-header');
		}
	};
	return (
		<Box className='page-subheader' id='page-report-subheader'>
			<Box className='page-subheader-inner'>
				<HeaderTabs
					tabSelected={props.tabSelected}
					handleTabChange={props.handleTabChange}
					setTabSelectedTeam={setTabSelectedTeam}
					{...props}
				/>
			</Box>
		</Box>
	);
};

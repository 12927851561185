import { Box, Chip, IconButton, Tooltip, List, ListItem, Typography } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import { IndividualSmallIcon, InfoIcon, LinkIcon } from '../../../config/svg/CommonSvg';
import krFlag from '../../../images/krFlag.svg';
import { Enums } from '../../../config/enums';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import {
	formatDate,
	getMonthDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getTeamFilterQueryString,
	getMinDate,
} from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import { ListTooltip } from '../../OkrAction/ListTooltip';
import DatePicker from '../../Common/DatePicker';
import AlertDialog from '../../Common/Dialog';
import { useSnackbar } from 'notistack';
import { SettingsIcon } from '../../../config/svg/MyGoalSvg';
import { getLocalStorageItem } from '../../../services/StorageService';
// import { GoalAttributes } from '../../../components/V2/Goals/GoalAttributes';
import { KeyResultAttributes } from '../../Goals/KeyResult/KeyResultAttributes';
import moment from 'moment';
import { getDifferenceBetweenDates } from '../../../config/utils';
import { getOkrProgressDetails } from '../../../config/utils';
import { keyDetailObject, goalDetailObject } from '../../Common/OkrUtil';

const year = getLocalStorageItem('currentYear') || null;
const selectedYear = getLocalStorageItem('year') || year;
const cycleId = getLocalStorageItem('cycleId') || 0;

const StandaloneCard: React.FC<any> = (props: any) => {
	const {
		currentCycleEndDate,
		t,
		data,
		currentUser,
		setOpenDrawer,
		setModalProps,
		handleDeleteOkr,
		listOkrResult,
		isLocked,
		tabFilter,
		teamOkrFilterData,
	} = props;
	// const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	// const okr = data.data;
	// const keyData = okr;
	// const { t, data, currentUser, setOpenDrawer, setModalProps, handleDeleteOkr, currentCycleEndDate, isLocked } = props;
	const okr = data.data;
	const [modalDate, setmodalDate] = useState<any>({ open: false, type: '', message: '' });
	const userDetail: any = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();

	// const [openObjectAttribute, setOpenObjectAttribute] = useState(false);
	const [dataUpdated, setdataUpdated] = useState<any>({});
	const [objectiveDetail, setObjectiveDetail] = useState<any>({
		...goalDetailObject,
		myGoalsDetails: [{ ...keyDetailObject }],
	});
	const defaultGaolDateError = { error: '', helperText: '', type: '' };
	// const [isSaveDisabled, setIsSaveDisabled] = useState<Boolean>(true);
	const [goalsAttribute, setGoalsAttribute] = useState<any>({});
	// const [loader, setLoader] = useState<Boolean>(false);
	const [goalAttrDateError, setGaolAttrDateError] = useState<any>(defaultGaolDateError);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	const [krLoader, setKrLoader] = useState<Boolean>(false);
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [keyAttribute, setKeyAttribute] = useState<any>({});
	const [keyData, setKeyData] = useState<any>(okr);
	const [keyRes, setKeyRes] = useState<boolean>(false);
	const [showKr, setShowKr] = useState(false);
	const [modalPropsKR, setModalPropsKR] = useState<any>({ open: false, type: '', message: '' });
	const [apiCallComplete, setApiCallComplete] = useState<any>(false);

	const handleAcceptDeclineClick = async (open: Boolean, okr: any, kr: any, type: String, KRType: String) => {
		const krDetail = await props.getKrDetail(okr.objectiveId);
		if (krDetail.status === 200) {
			if (krDetail.data.entity.krStatusId === Enums.KR_STATUS_PENDING) {
				setOpenDrawer({
					open: true,
					okr: krDetail.data.entity,
					kr: {},
					type: type,
					KRType: 'Standalone',
				});
			} else {
				setModalProps({
					open: true,
					type: 'AcceptKR',
					message: t('actionAlreadyTaken'),
					module: 'user',
					isCancel: true,
				});
			}
		}
	};
	const updateAlignmentDueDate = async (dueDate: any, okr: any) => {
		const data = {
			goalType: 2,
			goalId: okr.objectiveId,
			endDate: formatDate(dueDate),
			startDate: formatDate(okr.startDate),
		};
		const response = await props.updateObjetciveDueDate(data);
		if (response && response.data && response.data.status === 200) {
			props.fetchOkr();
			if (props.tabValue === 0) {
				props.getAlignmentData();
			} else if (props.tabValue === 1) {
				const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
				const data = `empId=${userDetail && userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}${teamFilter}`;
				props.fetchTeamsAlignmentMap(data);
			}
		} else if (response && response.data && response.data.status === 400) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
		}

		//setmodalDate({ open: true, type: '', message: 'dsdsd', module: '' });
	};
	const handleCloseModal1 = async (event: any, type: Number) => {
		setmodalDate({ open: false, type: '', message: '', module: '' });
	};
	const openSettingsDrawer = async (okrObj: any) => {
		let okrs = JSON.parse(JSON.stringify(props.listOkrResult));
		setObjectiveDetail({ ...objectiveDetail, owner: okrObj.owner });
		if (okrs && okrs.myGoalOkrResponses && okrs.myGoalOkrResponses.length) {
			let currentORK = okrs.myGoalOkrResponses.filter((item: any) => item.goalKeyId === okrObj.objectiveId);
			let getKR = currentORK[0].myGoalsDetails;
			currentORK[0].goalTypeId = currentORK[0].goalTypeId || 2;
			currentORK[0] = {
				...currentORK[0],
				contributors: currentORK[0].contributors.filter(
					(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
				),
			};

			let obj = {
				okr: currentORK[0],
				kr: getKR,
				actionType: 'editKR',
				goalDetail: {},
				showAttributeDrawer: true,
			};
			setGoalsAttribute(currentORK[0]);
			setKeyRes(true);
			setKeyAttribute(currentORK[0]);
			setdataUpdated(obj);
		}
	};

	const handleCloseObjectiveAttribute = async (event: any, type: String) => {
		// setShowKr(false);
		setGaolAttrDateError(defaultGaolDateError);
		if (type === 'save') {
			setSnackbarProps({});
			const datesDifferenceInDays = moment(
				new Date(keyAttribute.startDate ? keyAttribute.startDate : keyData?.startDate || new Date())
			).isAfter(new Date(keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate || new Date()), 'days');
			if (Boolean(datesDifferenceInDays)) {
				setGaolAttrDateError({
					error: t('startDateDueDateError'),
					helperText: t('startDateDueDateError'),
					type: 'dueDate',
				});
			} else {
				const { showAttributeDrawer, actionType } = dataUpdated || {};
				if (showAttributeDrawer && actionType === 'editKR') {
					const data = { ...keyAttribute, year };
					if (validateAddKRForm(data)) {
						setApiCallComplete(true);
						const response = await props.updateKrAttributes(data);
						if (response && response.data && response.data.status === 200) {
							setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
							setApiCallComplete(false);
							props.fetchOkr();
							if (props.tabValue === 0) {
								props.getAlignmentData();
							} else if (props.tabValue === 1) {
								const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
								const data = `empId=${
									userDetail && userDetail.employeeId
								}&cycle=${cycleId}&year=${selectedYear}${teamFilter}`;
								props.fetchTeamsAlignmentMap(data);
							}
						} else {
							setSnackbarProps({ show: true, message: response?.data?.messageList, variant: 'error' });
							setKrLoader(false);
						}
						setKRAttrDateError(defaultKRDateError);
						// setOpenDrawer(false);
						// setKeyAttribute({});
					}
				} else {
					if (validateAddKRForm(keyAttribute)) {
						if (showAttributeDrawer && actionType === 'addNewKr') {
							setdataUpdated({ ...dataUpdated, kr: { ...dataUpdated.kr, ...keyAttribute }, saved: true });
							setKRAttrDateError(defaultKRDateError);
							setOpenDrawer(false);
							setKeyAttribute({});
							setShowKr(false);
						} else {
							updateFormDetail(keyData, '', '', true, keyAttribute);
							setKRAttrDateError(defaultKRDateError);
							setOpenDrawer(false);
							setKeyAttribute({});
							setShowKr(false);
						}
					}
				}
			}
		} else if (type === 'close') {
			if (!isKRAttributeUpdated()) {
				setModalPropsKR({
					open: true,
					type: 'editKR',
					message: t('unSavedItemAlert'),
					module: 'user',
					details: { id: '' },
				});
			} else {
				setKeyRes(false);
				setGoalsAttribute({});
				setOpenDrawer(false);
			}
		}
	};

	const isKRAttributeUpdated = () => {
		if (dataUpdated && dataUpdated.actionType === 'editKR') {
			const keyDetailsRes = dataUpdated.okr; //dataUpdated?.kr.assignmentTypeId === 1 ? dataUpdated.kr : dataUpdated.okr;
			return checkFormValues(keyDetailsRes);
		} else {
			return checkFormValues(keyData);
		}
	};

	const checkFormValues = (keyData: any) => {
		return (keyAttribute.startDate && !getDifferenceBetweenDates(keyData.startDate, keyAttribute.startDate)) ||
			(keyAttribute.dueDate && !getDifferenceBetweenDates(keyAttribute.dueDate, keyData.dueDate)) ||
			(keyAttribute.metricId && keyAttribute.metricId !== keyData.metricId) ||
			(keyAttribute.currencyId && keyAttribute.currencyId !== keyData.currencyId) ||
			(keyAttribute.startValue && keyAttribute.startValue !== keyData.startValue) ||
			(keyAttribute.targetValue && keyAttribute.targetValue !== keyData.targetValue) ||
			(keyAttribute?.keyNotes && keyAttribute?.keyNotes !== keyData.keyNotes) ||
			(keyAttribute?.keyDescription && keyAttribute?.keyDescription !== keyData.keyDescription) ||
			(keyAttribute.contributors && keyAttribute.contributors.length !== keyData.contributors.length)
			? false
			: true;
	};

	const validateAddKRForm = (formData: any) => {
		if (formData?.metricId === Enums.ONE || formData?.metricId === Enums.TWO || formData?.metricId === Enums.THREE) {
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				return false;
			}
		}
		if (formData.keyDescription === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyDescription',
			});
			return false;
		} else if (formData.keyDescription.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyDescription',
			});
			return false;
		}
		return true;
	};

	const updateFormDetail = (item: any, key: any, value: any, isKeyData: Boolean, keyAttribute: any) => {
		const { myGoalsDetails } = objectiveDetail;
		let updatedDetail = [];
		if (isKeyData) {
			updatedDetail = myGoalsDetails.map((element: any) => {
				if (element.id === item.id) {
					element.startDate = keyAttribute.startDate ? keyAttribute.startDate : element.startDate;
					element.dueDate = keyAttribute.dueDate ? keyAttribute.dueDate : element.dueDate;
					element.startValue = keyAttribute.startValue ? keyAttribute.startValue : element.startValue;
					element.targetValue = keyAttribute.targetValue ? keyAttribute.targetValue : element.targetValue;
					element.metricId = keyAttribute.metricId ? keyAttribute.metricId : element.metricId;
					element.currencyId = keyAttribute.currencyId ? keyAttribute.currencyId : element.currencyId;
					element.showCurrentTarget = keyAttribute.showCurrentTarget
						? keyAttribute.showCurrentTarget
						: element.showCurrentTarget;
					element.metricName = keyAttribute.metricName ? keyAttribute.metricName : element.metricName;
					element.contributors = keyAttribute.contributors ? keyAttribute.contributors : element.contributors;
					element.keyNotes = keyAttribute.keyNotes ? keyAttribute.keyNotes : element.keyNotes;
					element.keyDescription = keyAttribute.keyDescription ? keyAttribute.keyDescription : element.keyDescription;
					element.isNewKey = false;
				}
				return element;
			});
		} else {
			updatedDetail = myGoalsDetails.map((element: any) => {
				if (element.id === item.id) {
					if (key === 'keyDescription' || key === 'dueDate') {
						element.isTyped = true;
						element.isNewKey = false;
					}
					element[key] = value;
				} else {
					element.isTyped = false;
				}
				return element;
			});
		}

		setObjectiveDetail({ ...objectiveDetail, isTyped: false, myGoalsDetails: updatedDetail });
		// if (key === 'keyDescription' || key === 'dueDate') {
		// 	getCallAutoSave({ ...objectiveDetail, isTyped: false, myGoalsDetails: updatedDetail });
		// }
	};

	const handleCloseModal = async (event: any, type: Number) => {
		if (type === 1) {
			if (modalPropsKR.type === 'updateDateForOKR') {
				setModalPropsKR({ open: false, type: '', message: '', module: '', data: {} });
				updateAlignmentDueDate(modalPropsKR.data.date, modalPropsKR.data.okr);
			} else {
				setModalPropsKR({ open: false, type: '', message: '', module: '' });
				setKeyRes(false);
			}
		} else {
			setModalPropsKR({ open: false, type: '', message: '', module: '' });
		}
	};

	const { color } = getOkrProgressDetails({
		value: okr.score || okr.krScore,
		dueDate: okr.dueDate || okr.krDueDate,
	});

	const popperMenu = () => {
		return (
			<OkrPopperMenu
				{...props}
				poperClassName={'alignment-popper-menu popper-menu-list okr-list-popper-menu'}
				okr={okr}
				type={3}
				t={t}
				handleDeleteOkr={handleDeleteOkr}
				KRType={'Standalone'}
				handleAcceptDeclineClick={handleAcceptDeclineClick}
				alignmentMap={true}
				currentUser={currentUser}
				//poperPlacement={'bottom-start'}
				poperContainer={false}
				diagramInstance={props.diagramInstance}
				handleKRClick={openSettingsDrawer}
				keyAttribute={keyAttribute}
				setKeyAttribute={setKeyAttribute}
				keyRes={keyRes}
				// setKeyRes={setKeyRes}
				dataUpdated={dataUpdated}
				setdataUpdated={setdataUpdated}
				goalDetail={dataUpdated}
				setGoalDetail={setdataUpdated}
				handleDrawerClose={handleCloseObjectiveAttribute}
				keyData={dataUpdated.kr}
			/>
		);
	};

	const ultraCompactView = () => {
		return (
			<>
				<Box
					className={
						okr?.krStatusId === Enums.KR_STATUS_PENDING
							? 'alignment-card ultra-compact-view ultra-view-orphan orphan-btn-active'
							: 'alignment-card ultra-compact-view ultra-view-orphan '
					}
				>
					<Box className='objective-section'>
						<Box className='cardHeader'>
							<Box className='cardHeader-col1'>
								{/* <Box className='okr-nature-type-tag'>
									{okr?.goalNatureId === 1 ? (
										<Typography className='operational'>{t('aspirationalLevel')}</Typography>
									) : (
										<Typography className='aspirational'>{t('operationalLevel')}</Typography>
									)}
								</Box> */}
								<Box display='flex'>
									<Box className='cardFlag'>
										<img src={krFlag} alt='Kr Flag' />
									</Box>
									<Box className='okr-after-edit'>
										<ListTooltip
											objName={okr?.name || ''}
											description={okr?.keyNotes || ''}
											alignmentMapDescription={true}
											characterCount={18}
											titleClassName={'cardTitle'}
											diagramInstance={props.diagramInstance}
										/>
									</Box>
								</Box>
							</Box>
							<Box className='cardHeader-col2'>
								{!currentUser && okr?.krStatusId === Enums.KR_STATUS_PENDING && (
									<Box className='card-toggle'>
										<Box className='action-message-wrap'>
											<Box className='orphen-action-btn'>
												<OKRButton
													className='btn-accept'
													text={t('acceptLabel')}
													handleClick={() => handleAcceptDeclineClick(true, okr, {}, 'accept', 'Standalone')}
												/>
												<OKRButton
													className='btn-decline'
													text={t('decline')}
													handleClick={() => handleAcceptDeclineClick(true, okr, {}, 'decline', 'Standalone')}
												/>
											</Box>
											<Box className='action-message'>
												<InfoIcon />
											</Box>
										</Box>
									</Box>
								)}
								{okr?.krStatusId === Enums.KR_STATUS_ACCEPTED && (
									<Box className='progressText'>
										<Typography variant='body1' style={{ color: color }}>
											{okr?.score}%
										</Typography>
									</Box>
								)}
								{popperMenu()}
							</Box>
						</Box>
					</Box>
				</Box>
			</>
		);
	};

	return (
		<>
			{tabFilter === 'ultracompact' ? (
				ultraCompactView()
			) : (
				<React.Fragment>
					<Box
						className={`alignment-card ${tabFilter === 'compact' ? 'compact-view compact-view-orphan' : 'orphan-card'}`}
					>
						<Box className='objective-section wrapper-grey'>
							<Box className='alignment-card-top'>
								<Box className='cardHeader'>
									<Box className='cardHeader-col1'>
										{/* <Box className='okr-nature-type-tag'>
											{okr?.goalNatureId === 1 ? (
												<Typography className='operational'>{t('aspirationalLevel')}</Typography>
											) : (
												<Typography className='aspirational'>{t('operationalLevel')}</Typography>
											)}
										</Box> */}
										<Box display='flex'>
											<Box className='cardFlag'>
												<img alt='' src={krFlag} />
											</Box>
											<Box className='okr-after-edit'>
												<ListTooltip
													objName={okr?.name || ''}
													description={okr?.keyNotes || ''}
													alignmentMapDescription={true}
													characterCount={tabFilter === 'compact' ? 78 : 92}
													titleClassName={'cardTitle'}
													diagramInstance={props.diagramInstance}
												/>
											</Box>
										</Box>
									</Box>
									<Box className='cardHeader-col2'>
										{tabFilter === 'compact' && (
											<Box className='progressChart'>
												<OkrProgressBar
													parentOkr={[]}
													okr={okr}
													type={'krAlignmentMap'}
													t={t}
													updateProgress={props.updateProgress}
													showEditLoader={props.showEditLoader}
													currentUser={currentUser}
													okrMasterData={props.okrMasterData}
													diagramInstance={props.diagramInstance}
													isScoreLocked={props.listOkrResult.isScoreLocked}
												/>
											</Box>
										)}
										{popperMenu()}
									</Box>
								</Box>
								<Box className='card-toggle'>
									{tabFilter === 'relaxed' && (
										<OKRButton className='btn-gray-chip' icon={<LinkIcon />} text={t('keyResult')} />
									)}
									{okr?.teamName && okr?.teamName.length > 0 ? (
										<Box className='teams-chips'>
											<Tooltip title={okr.teamName} arrow>
												<Chip
													style={{
														backgroundColor: getRandomBackgroundColor(okr.backGroundColorCode),
														color: getRandomColor(okr.colorCode),
													}}
													label={okr.teamName}
													//title={okr.teamName}
												/>
											</Tooltip>
											{okr.parentTeamDetails && okr.parentTeamDetails.teamName && (
												<Tooltip title={okr.parentTeamDetails.teamName} arrow>
													<Chip
														style={{
															backgroundColor: getRandomBackgroundColor(okr.parentTeamDetails.backGroundColorCode),
															color: getRandomColor(okr.parentTeamDetails.colorCode),
														}}
														icon={
															<svg
																width='11'
																height='11'
																viewBox='0 0 11 11'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M6.71854 6.18852L4.52411 3.9941C4.35506 3.82488 4.08076 3.82488 3.91168 3.9941C3.74249 4.16315 3.74249 4.43745 3.91168 4.60652L6.10611 6.80094C6.19072 6.88555 6.30144 6.92784 6.41233 6.92784C6.52321 6.92784 6.63393 6.88555 6.71854 6.80094C6.88773 6.63187 6.88773 6.35757 6.71854 6.18852Z'
																	fill={getRandomColor(okr.parentTeamDetails.colorCode)}
																	stroke={getRandomColor(okr.parentTeamDetails.colorCode)}
																	strokeWidth='0.5'
																/>
																<path
																	d='M9.30956 5.10748L8.50042 4.2982C8.33967 4.13758 8.07932 4.13758 7.91856 4.2982C7.75779 4.45895 7.75779 4.71958 7.91856 4.8802L8.72769 5.68948C9.58252 6.54444 9.5817 7.94559 8.72769 8.81111C7.873 9.6658 6.47184 9.66512 5.60605 8.81111L4.79676 8.00198C4.63614 7.84123 4.37552 7.84123 4.21475 8.00198C4.05398 8.16275 4.05398 8.42321 4.21475 8.58385L5.02595 9.39504C5.61947 9.9806 6.39611 10.2736 7.1711 10.2736C7.94773 10.2736 8.72301 9.97936 9.31158 9.39106C10.4841 8.20251 10.4833 6.28108 9.30956 5.10748Z'
																	fill={getRandomColor(okr.parentTeamDetails.colorCode)}
																	stroke={getRandomColor(okr.parentTeamDetails.colorCode)}
																	strokeWidth='0.5'
																/>
																<path
																	d='M6.41524 2.2132L5.60405 1.40185C4.41579 0.229494 2.49423 0.230447 1.31857 1.40583C0.146083 2.59424 0.147035 4.51566 1.32063 5.68925L2.12992 6.49854C2.21017 6.57892 2.3155 6.6191 2.42085 6.6191C2.52605 6.6191 2.6314 6.57892 2.71178 6.4984C2.87253 6.33779 2.87253 6.07717 2.71178 5.91654L1.9025 5.10725C1.04768 4.25243 1.04849 2.85128 1.9025 1.98576C2.75745 1.13107 4.1586 1.13191 5.02413 1.98576L5.83341 2.79504C5.99418 2.95581 6.25451 2.95581 6.41528 2.79504C6.57601 2.63443 6.57601 2.37381 6.41524 2.2132Z'
																	fill={getRandomColor(okr.parentTeamDetails.colorCode)}
																	stroke={getRandomColor(okr.parentTeamDetails.colorCode)}
																	strokeWidth='0.5'
																/>
															</svg>
														}
														label={okr.parentTeamDetails.teamName}
														//title={okr.parentTeamDetails.teamName}
													/>
												</Tooltip>
											)}
										</Box>
									) : (
										<></>
									)}

									{!isLocked &&
										tabFilter === 'relaxed' &&
										!currentUser &&
										((okr.teamId &&
											props.tabValue === 1 &&
											okr.owner === userDetail.employeeId &&
											okr.owner === okr.employeeId) ||
											(okr.teamId && props.tabValue !== 1) ||
											(!okr.teamId && userDetail.employeeId === okr.employeeId)) &&
										okr.isMyOkr &&
										okr.krStatusId !== 1 && (
											<Box className='okr-field-actions' onClick={() => openSettingsDrawer(okr)}>
												<OKRButton className='settings-btn' icon={<SettingsIcon />} title={t('attributesLabel')} />
											</Box>
										)}
									{!currentUser && okr?.krStatusId === Enums.KR_STATUS_PENDING && (
										<Box className='action-message-wrap'>
											<Box className='action-message'>
												<InfoIcon />
												<Typography variant='body2'>{t('actionRequired')}</Typography>
											</Box>

											<Box className='orphen-action-btn'>
												<OKRButton
													className='btn-accept'
													text={t('acceptLabel')}
													handleClick={() => handleAcceptDeclineClick(true, okr, {}, 'accept', 'Standalone')}
												/>
												<OKRButton
													className='btn-decline'
													text={t('decline')}
													handleClick={() => handleAcceptDeclineClick(true, okr, {}, 'decline', 'Standalone')}
												/>
											</Box>
										</Box>
									)}
									{tabFilter === 'compact' && okr?.krStatusId === Enums.KR_STATUS_ACCEPTED && (
										<List className='card-key-result'>
											<ListItem>
												<IndividualSmallIcon /> <Typography variant='body2'>{okr?.contributorCount}</Typography>
											</ListItem>
										</List>
									)}
								</Box>
							</Box>
							{tabFilter === 'relaxed' && (
								<>
									<Box className='alignedRow'>
										<Box className='usersAligned'>
											{!okr.isVirtualLink && !okr.isParentVirtualLink && (
												<UserAvatarGroup
													{...props}
													loginUserAvatar={
														okr && okr.okrViewStandAloneContributors && okr.okrViewStandAloneContributors.length
															? okr.okrViewStandAloneContributors.find((itemKR: any) => itemKR.isSource)
															: okr
													}
													contributorDetails={
														okr && okr.okrViewStandAloneContributors && okr.okrViewStandAloneContributors.length
															? okr.okrViewStandAloneContributors.filter(
																	(itemKR: any) => itemKR.krStatusId === Enums.KR_STATUS_ACCEPTED && !itemKR.isSource
															  )
															: []
													}
													addButtonClassName='add-btn'
													avatarClassName=''
													isButtonVisible={false}
													handleAddUserClick={() => {}}
													max={3}
													currentUser={currentUser}
													contributorDetailsType={true}
													poperContainer={false}
													contributorClassName={
														'contributor-details-popover alignmentmap-contributorPopup contributorPopup-krLevel'
													}
													diagramInstance={props.diagramInstance}
													alignmentMapTab={true}
													showTarget={true}
													popoverNoModifiers={true}
													actionLevel={okr?.actionLevel}
												/>
											)}
										</Box>

										<Box className='cycleProgress'>
											<Box className='quaterCycle'>
												<Box className='date-settings alignment-date-settings'>
													<Box className='calender-date'>
														{!isLocked &&
														tabFilter === 'relaxed' &&
														!currentUser &&
														((okr.teamId &&
															props.tabValue === 1 &&
															okr.owner === userDetail.employeeId &&
															okr.owner === okr.employeeId) ||
															(okr.teamId && props.tabValue !== 1) ||
															(!okr.teamId && userDetail.employeeId === okr.employeeId)) &&
														okr.isMyOkr &&
														okr.krStatusId !== 1 ? (
															<DatePicker
																currentCycleEndDate={currentCycleEndDate}
																durationCycleDetail={props.durationCycleDetail}
																value={okr.dueDate}
																handleDateChange={(date: any) => {
																	if (okr?.contributorCount > 0) {
																		setModalPropsKR({
																			open: true,
																			type: 'updateDateForOKR',
																			message: t('updateKRAttribute'),
																			module: 'user',
																			data: { date: date, okr: okr },
																		});
																	} else {
																		updateAlignmentDueDate(date, okr);
																	}
																}}
																name={''}
																showLabel={false}
																minDate={getMinDate(
																	okr?.importedId > 0 && okr.parentStartDate ? okr.parentStartDate : okr?.startDate
																)}
																maxDate={
																	okr?.importedId > 0 && okr.parentDueDate
																		? okr.parentDueDate
																		: new Date(currentCycleEndDate)
																}
																error={''}
																helperText={''}
																format='MMM d'
																handleKeyboardButtonClick={() => {}}
																disableToolbar={false}
																handleEndOfMonthDateSelection={null}
															/>
														) : (
															getMonthDate(okr?.dueDate)
														)}
													</Box>
													<Typography variant='h6'>
														{t('cycle')}: {okr?.cycle}
													</Typography>
												</Box>
											</Box>

											<Box className='progressChart'>
												<OkrProgressBar
													parentOkr={[]}
													okr={okr}
													type={'krAlignmentMap'}
													t={t}
													updateProgress={props.updateProgress}
													showEditLoader={props.showEditLoader}
													currentUser={currentUser}
													okrMasterData={props.okrMasterData}
													diagramInstance={props.diagramInstance}
													isScoreLocked={props.listOkrResult.isScoreLocked}
												/>
											</Box>
										</Box>
									</Box>
									<Box>
										{keyRes && keyData && (
											<KeyResultAttributes
												{...props}
												open={keyRes}
												// keyData={keyData}
												handleDrawerClose={handleCloseObjectiveAttribute}
												isKRAttributeUpdated={true}
												krAttrDateError={krAttrDateError}
												keyAttribute={keyAttribute}
												setKRAttrDateError={setKRAttrDateError}
												goalDetail={dataUpdated}
												objectiveDetail={dataUpdated.okr}
												setKeyAttribute={setKeyAttribute}
												dataUpdated={dataUpdated}
												setdataUpdated={setdataUpdated}
												// setGoalDetail={setdataUpdated}
												keyData={dataUpdated.okr}
												setCallOut={() => {}}
												setAnchorElCallouts={() => {}}
												setApiCallComplete={setApiCallComplete}
												apiCallComplete={apiCallComplete}
											/>
										)}
									</Box>
								</>
							)}
						</Box>
					</Box>
				</React.Fragment>
			)}
			{modalPropsKR.open && (
				<AlertDialog
					module={modalPropsKR.module ? modalPropsKR.module : 'user'}
					message={modalPropsKR.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalPropsKR.open}
				/>
			)}
		</>
	);
};
export default StandaloneCard;

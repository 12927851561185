import React from 'react';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const KrWeight: React.FC<any> = (props) => {
	const { viewOnly, okrMasterData, handleWeightChange, keyAttribute } = props;
	const { weights } = okrMasterData;
	const { t } = useTranslation();

	return (
		<Box className='weight-selection'>
			<FormLabel id='weight-label' className='label-des'>{`${
				viewOnly ? t('weightLabel') : t('selectWeight')
			}`}</FormLabel>
			<Typography className='short-des-text'>{t('weightDes')}</Typography>
			<RadioGroup
				row
				className='weight-group'
				aria-labelledby='weight-selection'
				name='weight-selection'
				value={keyAttribute.weightId}
				onChange={handleWeightChange}
			>
				{weights &&
					weights.length > 0 &&
					weights.map((item: any, index: number) => (
						<Tooltip
							arrow
							title={viewOnly ? 'Weight can be updated only by the owner of the OKR' : ''}
							classes={{ popper: 'tooltip-layout4' }}
						>
							<FormControlLabel
								key={item.weightId}
								disabled={viewOnly}
								id={item.weightId}
								value={item.weightId}
								control={<Radio />}
								label={item.weightValue}
							/>
						</Tooltip>
					))}
			</RadioGroup>
		</Box>
	);
};

import { Enums } from '../../config/enums';
import { createUUID, formatDate, getUserDetails } from '../../config/utils';
import { getLocalStorageItem } from '../../services/StorageService';

export const getEmployeeId = () => {
	let userDetails = getUserDetails();
	return userDetails ? userDetails.employeeId : Enums.ZERO;
};

export const keyDetailObject = {
	id: createUUID(),
	goalKeyId: Enums.ZERO,
	keyDescription: '',
	keyNotes: '',
	score: Enums.ZERO,
	startDate: formatDate(new Date()),
	dueDate: formatDate(getLocalStorageItem('currentCycleEndDate') || null),
	importedType: Enums.ZERO,
	importedId: Enums.ZERO,
	progress: Enums.ZERO,
	source: Enums.ZERO,
	contributors: [],
	metricId: Enums.ZERO,
	currencyId: Enums.ZERO,
	startValue: Enums.ZERO,
	targetValue: Enums.ZERO,
	assignmentTypeId: Enums.TWO,
	krStatusId: Enums.TWO,
	goalStatusId: Enums.TWO,
	isNewKey: true,
	owner: 0 || Enums.ZERO,
	krMsg: '',
	weightId: 1,
};

export const goalDetailObject = {
	goalObjectiveId: Enums.ZERO,
	employeeId: getEmployeeId(),
	year: getLocalStorageItem('currentYear') || null,
	isPrivate: false,
	objectiveName: '',
	objectiveDescription: '',
	objectiveCycleId: getLocalStorageItem('currentCycleId') || 0,
	startDate: formatDate(new Date()),
	endDate: formatDate(getLocalStorageItem('currentCycleEndDate') || null),
	progress: Enums.ZERO,
	source: Enums.ZERO,
	sequence: Enums.ZERO,
	goalStatusId: Enums.TWO,
	goalTypeId: 0, //default unselected
	myGoalsDetails: [],
	showCurrentTarget: false,
	metricName: '',
	owner: getEmployeeId(),
	teamOkrRequests: [],
	ownerDesignation: '',
	ownerEmailId: '',
	ownerFirstName: '',
	ownerLastName: '',
	ownerImagePath: '',
	ownerEmployeeCode: '',
	linkedObjective: null,
	linkedObjectiveId: 0,
};

export const getKeyDetailObject = () => {
	return {
		id: createUUID(),
		goalKeyId: Enums.ZERO,
		keyDescription: '',
		keyNotes: '',
		score: Enums.ZERO,
		startDate: formatDate(new Date()),
		dueDate: formatDate(getLocalStorageItem('currentCycleEndDate') || null),
		importedType: Enums.ZERO,
		importedId: Enums.ZERO,
		progress: Enums.ZERO,
		source: Enums.ZERO,
		contributors: [],
		metricId: Enums.ZERO,
		currencyId: Enums.ZERO,
		startValue: Enums.ZERO,
		targetValue: Enums.ZERO,
		assignmentTypeId: Enums.TWO,
		krStatusId: Enums.TWO,
		goalStatusId: Enums.TWO,
		isNewKey: true,
		owner: 0 || Enums.ZERO,
		krMsg: '',
		weightId: 1,
	};
};

export const getGoalDetailObject = () => {
	return {
		goalObjectiveId: Enums.ZERO,
		employeeId: getEmployeeId(),
		year: getLocalStorageItem('currentYear') || null,
		isPrivate: false,
		objectiveName: '',
		objectiveDescription: '',
		objectiveCycleId: getLocalStorageItem('currentCycleId') || 0,
		startDate: formatDate(new Date()),
		endDate: formatDate(getLocalStorageItem('currentCycleEndDate') || null),
		progress: Enums.ZERO,
		source: Enums.ZERO,
		sequence: Enums.ZERO,
		goalStatusId: Enums.TWO,
		goalTypeId: 0, //default unselected
		myGoalsDetails: [],
		showCurrentTarget: false,
		metricName: '',
		owner: getEmployeeId(),
		teamOkrRequests: [],
		ownerDesignation: '',
		ownerEmailId: '',
		ownerFirstName: '',
		ownerLastName: '',
		ownerImagePath: '',
		ownerEmployeeCode: '',
		linkedObjective: null,
		linkedObjectiveId: 0,
	};
};

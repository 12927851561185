import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography, Link } from '@material-ui/core';
import clone from 'clone';
import { useSnackbar } from 'notistack';
import { OkrListingHead } from './OkrListHead';
import { DurationCycleDetailProps } from '../dataTypes';
import { CreateObjective } from '../../OkrAction/CreateAlignOkr/CreateObjective';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NoListFound } from '../NoListFound';
import WelcomeImage from '../../../images/my-goal-bg.svg';
import { ObjectiveList } from './ObjectiveList';
import { Enums } from '../../../config/enums';
import { KrAcceptAttributes } from '../../OkrAction/KrAcceptAttributes';
import { SearchUserPopover } from '../../Common/SearchUserPopover';
import AlertDialog from '../../Common/Dialog';
import { KrDeclineAttributes } from '../../OkrAction/KrDeclineAttributes';
import {
	getFullName,
	getUserDetails,
	getOkrProgressDetails,
	getCurrencyIcon,
	contributorObject,
	nextOKRObjectToEdit,
} from '../../../config/utils';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { AddIconSvg, LockIcon } from '../../../config/svg/CommonSvg';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import PersonalFeedbackMenu from '../../PersonalFeedback';
import { CreateAlignOkr } from '../../OkrAction/CreateAlignOkr';
import './style.scss';
import { CommonMessages } from '../../Common/CommonMessages';
import Feedback from '../../Feedback';
import { OkrListingTabs, OkrTabPanel } from './OkrListingTabs';
import { Dashboard } from '../../Dashboard';
import { OkrCard } from '../OkrCard';
import { DirectReportCard } from '../DirectReportCard';
import { HOME, USER_VIEW } from '../../../config/app-url';
import { AlignmentMapFilter } from '../../AlignmentMap/AlignmentMapFilter';
import { BecomeContributor } from '../../Common/assignment/BecomeContributor';
import { checkPermission } from '../../../config/rolePermission';
import { createOkrs } from '../../Admin/AdminConstant';
import { WeeklyCheckIns } from '../WeeklyCheckIns';
import OrganisationChart from '../OrganisationChart';
import { AlignObjectiveIcon, ImportOkrsIcon, ObjectiveIcon } from '../../../config/svg/MyGoalSvg';
import { OKRButton } from '../../Common/OKRButton';
import noRecordFound from '../../../images/search-placeholder.svg';
import { BackArrow } from '../../../config/svg/GlobalSvg';

export const OkrListing: React.FC<any> = (props) => {
	const {
		durationCycleDetail,
		showAddGoal,
		showAddGoalForm,
		currentCycleEndDate,
		currentCycleId,
		year,
		t,
		fetchOkr,
		myGoalList,
		setMyGoalList,
		currentUser,
		setIsGoalCreated,
		saveAndEditObjectiveAndKr,
		setFormSubmit,
		formSubmit,
		isGoalCreated,
		deleteContributor,
		setGoalDetail,
		goalDetail,
		myArchiveGoalList,
		showArchiveList,
		showHideArchiveList,
		setArchiveMyGoalList,
		setHighlightGoalId,
		addAlignmentFilter,
		listOkrResult,
		cardDashboard,
		handleCardClick,
		setCardDashboard,
		currentDashboardTab,
		currentRoute,
		tabZeroOnTeamCreateObjecive,
		setTabZeroOnTeamCreateObjecive,
		getPermissionResult,
		readOnlyView,
		isFutureCycle = true,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const [openCalender, setOpenCalender] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<any>({});
	const [dataBeforeEdit, setDataBeforeEdit] = useState<any>();
	const [showEditLoader, setShowEditLoader] = useState<any>({ status: '', id: 0 });
	const [progressUpdated, setProgressUpdated] = useState<boolean>(false);
	const [addContributorAnchorEl, setAddContributorAnchorEl] = useState<HTMLElement | null>(null);
	const [addContributorAnchorEl1, setAddContributorAnchorEl1] = useState<HTMLElement | null>(null);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [customStyle1, setCustomStyle1] = useState<Boolean>(false);
	const [addContributorOpen, setAddContributorOpen] = useState<boolean>(false);
	const [addContributorOpen1, setAddContributorOpen1] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openAddContributorDrawer, setOpenAddContributorDrawer] = useState<boolean>(false);
	const [selectedContributorDetails, setSelectedContributorDetails] = useState<any>(contributorObject);
	const [objectiveKRDetails, setObjectiveKRDetails] = useState<any>({ krDetails: null, okrDetails: null });
	const [loader, setLoader] = useState<boolean>(false);
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		isCancel: false,
		id: 0,
		moduleType: 1,
	});
	const [isAcceptKRFormUpdated, setIsAcceptKRFormUpdated] = useState<boolean>(false);
	const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	// const [isUnLockRequested, setIsUnLockRequested] = useState<Boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [okrType, setOkrType] = useState<string>('0');
	const [openOkrType, setOpenOkrType] = useState<boolean>(false);
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [krContributorDetailDashboard, setKRContributorDetailDashboard] = useState<any>(selectedContributorDetails);
	const [becomeAContributor, setBecomeAContributor] = useState<boolean>(false);
	const [feedback, setFeedback] = useState<any>({ type: 0, open: false });
	const [isTabChanged, setIsTabChanged] = useState<boolean>(false);
	const userDetail: any = getUserDetails();
	const currentYear: any = getLocalStorageItem('year') || null;
	const [tabValue, setTabValue] = useState<Number>(0);
	const [openTeamDetails, setOpenTeamDetails] = useState<any>(null);
	const [saveActionType, setSaveActionType] = useState<any>(null);
	const [currentObjective, setCurrentObjective] = useState<any>({});
	const [selectedAssignmentTypeId, setAssignmentTypeId] = useState<any>(null);
	const [tabButtonFilter, setTabButtonFilter] = useState<String>('');
	const [lastProgressUpdate, setLastProgressUpdate] = useState<any>({});
	const [directReportFilter, setDirectReportFilter] = useState<any>('');
	const [currentEditedObjective, setCurrentEditedObjective] = useState<any>({
		okr: null,
		kr: null,
		type: null,
		updatedDescription: null,
	});
	const [showProgressCard, setShowProgressCard] = useState<boolean>(false);
	const [nextObjectToEdit, setNextEditedObjective] = useState<any>(nextOKRObjectToEdit);

	const [resToUseInCalendar, setResToUseInCalendar] = useState<any>();
	const [alignedStatusData, setAlignedStatusData] = useState<any>({
		source: 0,
		goalKeyId: 0,
		sourceType: 0,
	});
	const [prevCycleMsg, setPrevCycleMsg] = useState<boolean>(false);
	//For Pop-ups if cycle change using cycle selector inside calendar (Editing Future and current Cycle OKRs OP-7349) (not during creating)
	const [editModee, setEditModee] = useState(false);
	const [openImportDrawer, setOpenImportDrawer] = useState<any>(false);

	useEffect(() => {
		setTimeout(() => {
			checkRedirectToUrl();
		}, 2000);

		//window.addEventListener('scroll', scrollList);
		addClassWelcome();

		const currentSearchedTeam = JSON.parse(getLocalStorageItem('currentSearchedTeam') || '{}');
		if (currentSearchedTeam && Object.keys(currentSearchedTeam).length > 0) {
			setTabValue(1);
			setSaveActionType(1);
			setLocalStorageItem('currentSearchedTeam', '{}');
		}

		return () => {
			//const objHeadClass = document.getElementById('obj-header');
			//objHeadClass?.classList.remove('sticky');
			//window.removeEventListener('scroll', scrollList);
		};
	});

	useEffect(() => {
		backUrlNavigation();
	}, []);

	useEffect(() => {
		const notificationRedirection = JSON.parse(getLocalStorageItem('notificationRedirection') || '{}');
		if (notificationRedirection && notificationRedirection.url) {
			if (notificationRedirection.tabToOpen && notificationRedirection.tabToOpen === 'TEAMSOKR') {
				addAlignmentFilter([]);
				setTabValue(1);
			}
		}
	}, [props.location, addAlignmentFilter]);

	//close progress if route not 'unlock-me'
	useEffect(() => {
		if (currentRoute !== 'unlock-me') {
			setLastProgressUpdate({});
			removeLocalStorageItem('lastSavedGoal');
		}
	}, [currentRoute]);

	useEffect(() => {
		if (tabZeroOnTeamCreateObjecive === true) {
			setTabValue(0);
			setTabZeroOnTeamCreateObjecive(false);
			setTimeout(function () {
				let element = document.getElementById('create-okr-card');
				if (element) {
					let topPos = element?.offsetTop;
					topPos = topPos ? topPos - 150 : 0;
					setTimeout(function () {
						window.scroll({ top: topPos, left: 0, behavior: 'smooth' });
					}, 200);
				}
			}, 200);
		}
	}, [tabZeroOnTeamCreateObjecive]);

	useEffect(() => {
		if (currentCycleId === cycleId) {
			setPrevCycleMsg(false);
		} else if (currentCycleId !== cycleId && props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID) {
			setPrevCycleMsg(false);
		} else if (currentCycleId !== cycleId) {
			setPrevCycleMsg(true);
		}
	}, [currentCycleId, cycleId, props.userSelectedCycle]);

	const backUrlNavigation = () => {
		const backUrl = JSON.parse(getLocalStorageItem('backUrl') || '{}');
		const cUrl = window.location.href.split('/');
		if (backUrl && backUrl.url && cUrl.indexOf(backUrl.url.substr(1)) > -1) {
			if (backUrl.tabToOpen && backUrl.tabToOpen === 'TEAMSOKR') {
				addAlignmentFilter([]);
				setTabValue(1);
				// execute when back to teams deatails view
				const teamDetails = JSON.parse(getLocalStorageItem('teamId') || '{}');
				if (backUrl.teamDetails && teamDetails) {
					setOpenTeamDetails(teamDetails);
				}
				removeLocalStorageItem('backUrl');
				removeLocalStorageItem('backUrlAlignmentMaps');
			} else if (
				backUrl.tabToOpen &&
				backUrl.tabToOpen === 'directReport' &&
				props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID
			) {
				addAlignmentFilter([]);
				setTabValue(2);
			} else if (
				backUrl.tabToOpen &&
				backUrl.tabToOpen === 'orgChart' &&
				props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID
			) {
				removeLocalStorageItem('backUrl');
				removeLocalStorageItem('backUrlAlignmentMaps');
				setTabValue(4);
			} else if (
				backUrl.tabToOpen &&
				backUrl.tabToOpen === 'checkIn' &&
				props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID
			) {
				removeLocalStorageItem('backUrl');
				removeLocalStorageItem('backUrlAlignmentMaps');
				setTabValue(3);
			}
		}
	};

	const showActionButton = (e: any, goal: any, key: any) => {
		if (!showArchiveList) {
			const updatedList = myGoalList.map((item: any) => {
				if (lastProgressUpdate && lastProgressUpdate.goalObjectiveId === item.goalObjectiveId) {
					item[key] = false;
				} else if (item.goalObjectiveId === goal.goalObjectiveId) {
					if (item[key]) {
						item[key] = false;
					} else {
						item[key] = true;
					}
				}
				return item;
			});
			setMyGoalList(updatedList);
			if (lastProgressUpdate && lastProgressUpdate.goalObjectiveId) {
				setLastProgressUpdate({});
				removeLocalStorageItem('lastSavedGoal');
			}
		} else {
			const updatedList = myArchiveGoalList.map((item: any) => {
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					if (item[key]) {
						item[key] = false;
					} else {
						item[key] = true;
					}
				}
				return item;
			});
			setArchiveMyGoalList(updatedList);
		}
	};

	const enableEdit = (goal: any, keyData: any, type: any) => {
		if (type === 1) {
			const updatedList = myGoalList.map((item: any) => {
				item.edit = false;
				item.expand = false;
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					setDataBeforeEdit(clone(goal));
					item.edit = true;
					item.expand = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (type === 2) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					item.edit = false;
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						keys.edit = false;
						keys.expand = false;
						if (keys.goalKeyId === keyData.goalKeyId) {
							setDataBeforeEdit(clone(keyData));
							keys.edit = true;
							keys.expand = true;
						}
						return keys;
					});
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (type === 3) {
			const updatedList = myGoalList.map((item: any) => {
				item.edit = false;
				item.expand = false;
				if (item.goalKeyId === goal.goalKeyId) {
					setDataBeforeEdit(clone(goal));
					item.edit = true;
					item.expand = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		}
	};

	const handleListAction = (e: any, goal: any, keyData: any, type: any) => {
		let editMode = myGoalList.filter((okr: any) => okr.edit || okr.myGoalsDetails.some((kr: any) => kr.edit));
		if (editMode.length) {
			setNextEditedObjective({
				okr: goal,
				kr: keyData,
				type,
			});
			return false;
		}
		enableEdit(goal, keyData, type);
		props.isAnyDrawerOpened({
			...props.appDrawerInfo,
			isOKREdit: true,
		});
	};

	const handleKREndOfMonthDateSelection = (details: DurationCycleDetailProps) => {
		setOpenCalender(false);
	};

	const handleKeyboardButtonClick = (_event: React.ButtonHTMLAttributes<HTMLBaseElement>) => {
		setOpenCalender(true);
	};

	const handleDateChange = (date: any, _name: any) => {
		setOpenCalender(false);
	};

	const resetDecline = () => {
		setFeedback({ type: 0, open: false });
		setOpenDrawer({ open: false });
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	/** Start method for Add to Objective */
	const handleCloseModal = async (event: any, type: Number) => {
		event.preventDefault();
		if (modalProps.moduleType === 1) {
			if (type === 1) {
				if (modalProps.type === 'AddToObjective') {
					setLoader(false);
				} else if (modalProps.type === 'DeclineKR') {
					setModalProps({ open: false, type: '', message: '', module: '' });
					setOpenDrawer({ open: false });
				} else if (modalProps.type === 'AcceptKR') {
					setModalProps({ open: false, type: '', message: '', module: '' });
					setOpenDrawer({ open: false });
					setIsAcceptKRFormUpdated(false);
				} else if (modalProps.type === 'unsavedGoalDescription') {
					// when user click on Yes
					if (nextObjectToEdit.okr || nextObjectToEdit.kr) {
						enableEdit(nextObjectToEdit.okr, nextObjectToEdit.kr, nextObjectToEdit.type);
					}
					setModalProps({ open: false, type: '', message: '', module: '' });
				}
			} else if (type === 2) {
				// when user click on Cancel
				openInEditMode();
				setModalProps({ open: false, type: '', message: '', module: '' });
			} else {
				setModalProps({ open: false, type: '', message: '', module: '' });
			}
		} else {
			handleCloseObjectiveModal(event, type);
		}
	};

	const reorder = (list: any, startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		// setHighlightGoalId(removed.goalObjectiveId);

		// setTimeout(() => {
		// 	setHighlightGoalId(0);
		// }, 3000);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const getItemStyle = (isDragging: any, draggableStyle: any) => ({
		// styles we need to apply on draggables
		...draggableStyle,
		...(isDragging && {
			background: 'rgb(235,235,235)',
		}),
	});

	const onDragEnd = (result: any) => {
		try {
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}

			const items = reorder(myGoalList, result.source.index, result.destination.index);
			const data = items.map((ele: any, index: number) => {
				const goalType = ele.assignmentTypeId === 1 ? 2 : 1;
				const goalId = ele.assignmentTypeId === 1 ? ele.goalKeyId : ele.goalObjectiveId;
				return { goalId, sequence: index, goalType, goalCycleId: cycleId };
			});
			props.updateSequence(data);
			setMyGoalList(items);
		} catch (e) { }
	};

	const updateTextValue = (e: any, data: any, type: number, keyData: any) => {
		const { value } = e.target;
		let updatedList = [];

		if (type === 3) {
			updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === data.goalKeyId) {
					item.keyDescription = value;
				}
				return item;
			});
		} else if (type === 2) {
			updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === data.goalObjectiveId) {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === keyData.goalKeyId) {
							keys.keyDescription = value;
						}
						return keys;
					});
				}
				return item;
			});
		} else if (type === 1) {
			// data.objectiveName = value;
			updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === data.goalObjectiveId) {
					item.objectiveName = value;
				}
				return item;
			});
		}

		setMyGoalList(updatedList);
	};

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 2000,
		});
	};

	const showErrorMsgWithObject = (response: any) => {
		try {
			const errorMessages = Object.values(response?.data?.messageList);
			if (errorMessages && errorMessages.length > 0) {
				errorMessages.forEach((item: any) =>
					enqueueSnackbar(item, {
						variant: 'error',
						autoHideDuration: 5000,
					})
				);
			}
		} catch (e) { }
	};

	/** edit text for objective, key result and standalone */
	const updateName = async (e: any, okr: any, type: number, kr: any, value: any, showSnackbar = true) => {
		if (value && value.trim() !== '') {
			if (type === 1) {
				setShowEditLoader({ status: 'pending', id: okr.goalObjectiveId });
				const data = { goalId: okr.goalObjectiveId, goalType: 1, description: value, dueDate: okr.dueDate };
				const resp = await props.updateGoalAndKrName(data);
				if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
					if (showSnackbar) {
						showApiMsgs(resp?.data?.messageList?.goal, 'success');
					}
					const updatedList = myGoalList.map((item: any) => {
						if (item.goalObjectiveId === okr.goalObjectiveId) {
							item.edit = false;
							item.objectiveName = value;
						}
						return item;
					});

					setMyGoalList(updatedList);
					setShowEditLoader({ status: '', id: 0 });
					props.isAnyDrawerOpened({
						...props.appDrawerInfo,
						isOKREdit: false,
					});
				}
			} else if (type === 2) {
				setShowEditLoader({ status: 'pending', id: kr.goalKeyId });
				const data = { goalId: kr.goalKeyId, goalType: 2, description: value, dueDate: kr.dueDate };
				const resp = await props.updateGoalAndKrName(data);
				if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
					if (showSnackbar) {
						showApiMsgs(resp?.data?.messageList?.goal, 'success');
					}
					const updatedList = myGoalList.map((item: any) => {
						if (item.goalObjectiveId === okr.goalObjectiveId) {
							item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
								if (keys.goalKeyId === kr.goalKeyId) {
									keys.edit = false;
									keys.keyDescription = value;
								}
								return keys;
							});
						}
						return item;
					});
					setMyGoalList(updatedList);
					setShowEditLoader({ status: '', id: 0 });
					props.isAnyDrawerOpened({
						...props.appDrawerInfo,
						isOKREdit: false,
					});
				}
			} else if (type === 3) {
				setShowEditLoader({ status: 'pending', id: okr.goalKeyId });
				const data = { goalId: okr.goalKeyId, goalType: 2, description: value, dueDate: okr.dueDate };
				const resp = await props.updateGoalAndKrName(data);
				if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
					if (showSnackbar) {
						showApiMsgs(resp?.data?.messageList?.goal, 'success');
					}
					const updatedList = myGoalList.map((item: any) => {
						if (item.goalKeyId === okr.goalKeyId) {
							item.edit = false;
							item.keyDescription = value;
						}
						return item;
					});

					setMyGoalList(updatedList);
					setShowEditLoader({ status: '', id: 0 });
				}
			}
		} else {
			showApiMsgs(t('blankValueText'), 'error');
		}
	};

	useEffect(() => {
		if (props.isAnyDrawerOpened && currentRoute === '/unlock-me') {
			if (props.isProgressUpdated) {
				if (!props.appDrawerInfo.isDrawerOpened) {
					const selectedYear = getLocalStorageItem('year') || year;
					const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
					props.getDashboardProgress(data, true);
					props.updateDashboardProgress(false);
					props.fetchOkr();
					showApiMsgs(t('dashboardUpdated'), 'success');
				} else {
					props.isAnyDrawerOpened({
						...props.appDrawerInfo,
						showRefreshOption: true,
					});
				}
			}
		}
	}, [props.isProgressUpdated]);

	const updateProgress = async (
		goalKeyId: number,
		assignmentTypeId: number,
		value: number,
		year: number,
		okr?: any
	) => {
		const selectedYear = getLocalStorageItem('year') || year;
		setShowEditLoader({ status: 'pending', id: goalKeyId });
		const data = { goalKeyId: goalKeyId, currentValue: value, year: selectedYear };
		const resp = await props.updateKrProgressValue(data);
		if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
			const respData = resp?.data?.entity;
			const { employeeId, score, updatedOn, currentValue, metricId, currencyId, okrScore } = respData;
			let newScore = 0;
			if (respData?.score) {
				newScore = respData.score < 0 ? 0 : respData.score > 100 ? 100 : respData.score;
			}
			//==== update Progress
			let goalData = { goalObjectiveId: 0, myGoalsDetails: [] };
			let updatedList = [];
			if (assignmentTypeId === 1) {
				updatedList = myGoalList.map((item: any) => {
					if (item.goalKeyId === goalKeyId) {
						goalData = item;
						item.currentValue = value;
						item.isScored = true;
						item.krStatusId = Enums.KR_STATUS_ACCEPTED;
						item.keyProgressTime = Boolean(respData.updatedOn) ? respData.updatedOn : item.keyProgressTime;
						item.score = newScore;
						// item.goalStatusId = respData.goalStatusId;
						// item.okrProgress = 4;
						item.contributors =
							item?.contributors && item?.contributors.length > 0
								? item.contributors.map((member: any) => {
									if (member.employeeId === employeeId) {
										member.contributorsContribution = score;
										member.updatedOn = updatedOn;
									}
									return member;
								})
								: [];
					}
					return item;
				});
			} else {
				const updatedKrList = myGoalList.map((item: any) => {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === goalKeyId) {
							goalData = item;
							keys.currentValue = value;
							keys.isScored = true;
							item.krStatusId = Enums.KR_STATUS_ACCEPTED;
							keys.keyProgressTime = Boolean(respData.updatedOn) ? respData.updatedOn : keys.keyProgressTime;
							keys.score = newScore;
							// item.goalStatusId = respData.goalStatusId;
							keys.contributors =
								keys?.contributors && keys?.contributors.length > 0
									? keys.contributors.map((member: any) => {
										if (member.employeeId === employeeId) {
											member.contributorsContribution = score;
											member.updatedOn = updatedOn;
											member.currentValue = currentValue;
											member.metricId = metricId;
											member.currencyId = currencyId;
										}
										return member;
									})
									: [];
						}
						return keys;
					});
					return item;
				});
				// const getKeyPercentage = goalData.myGoalsDetails.map((keys: any) => keys.score);
				// const percentageKeys = getKeyPercentage.reduce((sum, keys) => sum + keys);
				// const progressionScore = parseInt(percentageKeys) / goalData.myGoalsDetails.length;
				const progressionScore = okrScore;

				updatedList = updatedKrList.map((item: any) => {
					if (item.goalObjectiveId === goalData.goalObjectiveId) {
						item.score = Math.round(progressionScore);
						let { okrProgress } = getOkrProgressDetails({
							value: item.score,
							dueDate: item.dueDate,
						});
						item.okrProgress = okrProgress;
						item.goalProgressTime = Boolean(respData.updatedOn) ? respData.updatedOn : item.goalProgressTime;
					}
					return item;
				});
			}

			props.filterMyOkr(updatedList);
			setShowEditLoader({ status: '', id: 0 });

			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getDashboardProgress(data, true);
			// props.getDeltaScore(data, false);
			setProgressUpdated(true);
		} else {
			setShowEditLoader({ status: '', id: 0 });
		}
	};

	const openInEditMode = (closeMode: boolean = false) => {
		let okr = currentEditedObjective.okr;
		let kr = currentEditedObjective.kr;
		let objType = currentEditedObjective.type;
		let updatedDescription = currentEditedObjective.updatedDescription;
		if (objType === 1) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === dataBeforeEdit.goalObjectiveId) {
					item.objectiveName = (!closeMode && updatedDescription) || dataBeforeEdit.objectiveName;
					item.edit = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (objType === 2) {
			const updatedList = myGoalList.map((item: any) => {
				if (okr && item.goalObjectiveId === okr.goalObjectiveId) {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === kr.goalKeyId) {
							keys.keyDescription = updatedDescription || dataBeforeEdit.keyDescription;
							keys.edit = true;
						}
						// setDataBeforeEdit({ ...keys });
						return keys;
					});
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (objType === 3) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalKeyId === dataBeforeEdit.goalKeyId) {
					item.keyDescription = (!closeMode && updatedDescription) || dataBeforeEdit.keyDescription;
					item.edit = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		}
	};

	const handleClickInputAway = (e: any, okr: any, type: number, kr: any, textValue: any, showAlert: boolean = true) => {
		props.isAnyDrawerOpened({
			...props.appDrawerInfo,
			isOKREdit: false,
		});
		if (
			showAlert &&
			((type === 1 && textValue !== dataBeforeEdit.objectiveName) ||
				(type === 2 && textValue !== dataBeforeEdit.keyDescription))
		) {
			setCurrentEditedObjective({ okr, kr, type, updatedDescription: textValue });
			let showSnackbar = true;
			if (e?.target?.classList?.contains('MuiButton-label') === true) {
				showSnackbar = false;
			}
			updateName(e, okr, type, kr, textValue, showSnackbar);
			setNextEditedObjective({
				okr: null,
				kr: null,
				type: null,
			});
		}
		if (nextObjectToEdit.okr || nextObjectToEdit.kr) {
			enableEdit(nextObjectToEdit.okr, nextObjectToEdit.kr, nextObjectToEdit.type);
			// return;
		}
		if (type === 1) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === dataBeforeEdit.goalObjectiveId) {
					item.objectiveName = dataBeforeEdit.objectiveName;
					item.edit = false;
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (type === 2) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === okr.goalObjectiveId) {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === kr.goalKeyId) {
							keys.keyDescription = dataBeforeEdit.keyDescription;
							keys.edit = false;
						}
						return keys;
					});
				}
				return item;
			});

			setMyGoalList(updatedList);
		} else if (type === 3) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalKeyId === dataBeforeEdit.goalKeyId) {
					item.keyDescription = dataBeforeEdit.keyDescription;
					item.edit = false;
				}
				return item;
			});
			setMyGoalList(updatedList);
		}
	};

	const showGoalAndKrAttribute = (e: any, okr: any, kr: any, type: any, key: number) => {
		showAddGoalForm(false);
		if (type === 1) {
			setResToUseInCalendar(okr);
			setEditModee(true);
			const updatedOkr =
				okr && okr?.contributors && okr?.myGoalsDetails
					? {
						...okr,
						contributors: okr.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
						myGoalsDetails:
							okr?.myGoalsDetails.length > 0
								? okr?.myGoalsDetails.map((item: any) => {
									let isContributor = false;
									if (item.contributors && item.contributors.length > 0) {
										item.contributors.forEach((item: any) => {
											if (item.employeeId === userDetail?.employeeId) {
												isContributor = true;
											}
										});
									}
									return {
										...item,
										isContributorOnly:
											okr.owner !== userDetail?.employeeId && okr.goalTypeId === 1 && isContributor,
										contributors: item.contributors.filter(
											(items: any) => !items.isSource && items.employeeId !== userDetail.employeeId
										),
									};
								})
								: [],
					}
					: okr;
			setGoalDetail({ actionType: 'editGoal', okr: updatedOkr, showAttributeDrawer: true });
		} else {
			const updatedOkr =
				okr && okr?.contributors && okr?.myGoalsDetails
					? {
						...okr,
						contributors: okr.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
						myGoalsDetails:
							okr?.myGoalsDetails.length > 0
								? okr?.myGoalsDetails.map((item: any) => {
									let isContributor = false;
									if (item.contributors && item.contributors.length > 0) {
										item.contributors.forEach((item: any) => {
											if (item.employeeId === userDetail?.employeeId) {
												isContributor = true;
											}
										});
									}
									return {
										...item,
										isContributorOnly:
											okr.owner !== userDetail?.employeeId && okr.goalTypeId === 1 && isContributor,
										contributors: item.contributors.filter(
											(items: any) => !items.isSource && items.employeeId !== userDetail.employeeId
										),
									};
								})
								: [],
					}
					: okr;

			let isContributor = false;
			if (kr && kr.contributors && kr.contributors.length > 0) {
				kr.contributors.forEach((item: any) => {
					if (item.employeeId === userDetail?.employeeId) {
						isContributor = true;
					}
				});
			}
			const updatedKr =
				kr && kr?.contributors && kr?.contributors.length
					? {
						...kr,
						isContributorOnly: okr.owner !== userDetail?.employeeId && isContributor,
						contributors: kr.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
					}
					: kr;
			setGoalDetail({
				actionType: key === 1 ? 'addNewKr' : 'editKR',
				okr: updatedOkr,
				kr: updatedKr,
				showAttributeDrawer: true,
			});
		}
	};

	const handleBecomeAContributorClick = async (event: any, krDetails: any, okrDetails: any) => {
		setObjectiveKRDetails({ krDetails: krDetails || null, okrDetails: okrDetails || null });
		setCustomStyle(false);
		setCustomStyle1(false);
		const { objectiveName } = okrDetails;
		const {
			assignmentTypeId,
			startDate,
			dueDate,
			keyDescription,
			score,
			startValue,
			targetValue,
			currencyId,
			metricId,
			goalKeyId,
			source,
			weightId,
		} = Boolean(krDetails) ? krDetails : okrDetails;
		const selectedUser = getUserDetails();
		let sourceType = Enums.TWO;
		let goalId = okrDetails?.goalObjectiveId ? okrDetails?.goalObjectiveId : 0;
		setAlignedStatusData({
			source: source,
			goalKeyId: goalKeyId,
			sourceType: sourceType,
		});
		setSelectedContributorDetails({
			...selectedContributorDetails,
			...selectedUser,
			cycleId: cycleId,
			goalObjectiveId: goalId,
			goalStatusId: okrDetails?.goalStatusId || Enums.TWO,
			goalTypeId: okrDetails?.goalTypeId,
			krStatusId: Enums.TWO,
			krStatusName: 'Pending',
			assignmentTypeId: assignmentTypeId || Enums.TWO,
			keyResult: keyDescription || '',
			keyDescription: keyDescription || '',
			score: score || Enums.ZERO,
			startDate: startDate || '',
			dueDate: dueDate || '',
			startValue: startValue || Enums.ZERO,
			targetValue: targetValue || Enums.ZERO,
			message: '',
			metricName: '',
			currencyName: '',
			objectiveName: objectiveName || '',
			currencyId: currencyId || Enums.ZERO,
			metricId: metricId || Enums.ZERO,
			goalKeyId: goalKeyId || Enums.ZERO,
			isAddedNew: true,
			weightId: weightId ? weightId : 1,
		});
		setAddContributorAnchorEl(null);
		setAddContributorAnchorEl1(null);
		setAddContributorOpen(false);
		setAddContributorOpen1(false);
		setOpenAddContributorDrawer(true);
		setBecomeAContributor(true);
	};

	const handleAddUserClick = (event: any, krDetails: any, okrDetails: any) => {
		setObjectiveKRDetails({ krDetails: krDetails || null, okrDetails: okrDetails || null });
		setCustomStyle(false);
		setCustomStyle1(false);
		setAddContributorAnchorEl(addContributorAnchorEl ? null : event.currentTarget);
		setAddContributorAnchorEl1(null);
		setAddContributorOpen(true);
		setAddContributorOpen1(false);
		setBecomeAContributor(false);
		setCurrentObjective(krDetails);
	};

	const onHandleAddUserClick = (event: any) => {
		setCustomStyle(false);
		setCustomStyle1(false);
		setAddContributorAnchorEl(null);
		setAddContributorAnchorEl1(addContributorAnchorEl ? null : anchorEl || event.currentTarget);
		setAddContributorOpen(false);
		setAddContributorOpen1(true);
	};
	const handleOpenImportDrawer = (event: any) => {
		setOpenImportDrawer(true);
	};
	
	const getListing = () => {
		//let okrList = showArchiveList ? myArchiveGoalList : myGoalList;
		return (
			<>
				{showArchiveList && myArchiveGoalList.length > 0
					? getListHeader()
					: props.listOkrSuccess === 'success' &&
						props.listOkrResult &&
						props.listOkrResult.myGoalOkrResponses &&
						props.listOkrResult.myGoalOkrResponses.length > 0
						? getListHeader()
						: (showAddGoal && Object.keys(cardDashboard).length > 0) ||
							(props.alignmentFilterData && props.alignmentFilterData.length > 0)
							? getListHeader()
							: ''}
				{myGoalList.length > 0 &&
					((props.listOkrResult &&
						props.listOkrResult.myGoalOkrResponses &&
						props.listOkrResult.myGoalOkrResponses.length > 0) ||
						(showArchiveList && myArchiveGoalList && myArchiveGoalList.length > 0)) ? (
					<Fragment>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId='list'>
								{(provided: any) => (
									<div ref={provided.innerRef} className='pad-container' {...provided.droppableProps}>
										<Box className='my-obj-listing'>
											{myGoalList && myGoalList.length > 0 ? (
												myGoalList.map((okr: any, index: number) => (
													<Draggable
														key={okr.assignmentTypeId === 1 ? okr.goalKeyId : okr.goalObjectiveId}
														draggableId={`draggable_${okr.goalObjectiveId}`}
														index={index}
														isDragDisabled={currentUser ? true : false}
													>
														{(provided: any, snapshot: any) => (
															<Fragment>
																<ObjectiveList
																	{...props}
																	provided={provided}
																	showActionButton={showActionButton}
																	getItemStyle={getItemStyle}
																	snapshot={snapshot}
																	okr={okr}
																	handleKREndOfMonthDateSelection={handleKREndOfMonthDateSelection}
																	handleDateChange={handleDateChange}
																	handleKeyboardButtonClick={handleKeyboardButtonClick}
																	openCalender={openCalender}
																	updateTextValue={updateTextValue}
																	handleClickInputAway={handleClickInputAway}
																	handleListAction={handleListAction}
																	updateName={updateName}
																	showGoalAndKrAttribute={showGoalAndKrAttribute}
																	showEditLoader={showEditLoader}
																	t={t}
																	updateProgress={updateProgress}
																	handleAddUserClick={handleAddUserClick}
																	handleBecomeAContributorClick={handleBecomeAContributorClick}
																	maxObjectiveId={props.maxObjectiveId}
																	highlightGoalId={props.highlightGoalId}
																	setHighlightGoalId={setHighlightGoalId}
																	addNewKeyResult={addNewKeyResult}
																	currentCycleId={currentCycleId}
																	saveAndEditObjectiveAndKr={saveAndEditObjectiveAndKr}
																	handleDeleteOkr={handleDeleteOkr}
																	openDrawer={openDrawer}
																	setOpenDrawer={setOpenDrawer}
																	isLocked={props.listOkrResult && props.listOkrResult.isLocked}
																	showArchiveList={showArchiveList}
																	ObjectiveList={ObjectiveList}
																	currentCycleEndDate={currentCycleEndDate}
																	progressUpdated={progressUpdated}
																	setProgressUpdated={setProgressUpdated}
																	goalDetail={goalDetail}
																	setGoalDetail={setGoalDetail}
																	currentUser={currentUser}
																	lastProgressUpdate={lastProgressUpdate}
																	setLastProgressUpdate={setLastProgressUpdate}
																	myArchiveGoalList={myArchiveGoalList}
																	setArchiveMyGoalList={setArchiveMyGoalList}
																	myGoalList={myGoalList}
																	setMyGoalList={setMyGoalList}
																	showApiMsgs={showApiMsgs}
																	updateConfidence={updateConfidence}
																/>
															</Fragment>
														)}
													</Draggable>
												))
											) : (
												<Fragment>{showArchiveList && <NoListFound showSkeleton={false} />}</Fragment>
											)}
										</Box>
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</Fragment>
				) : props.listOkrSuccess === 'success' ? (
					<>
						{/* {(showAddGoal && Object.keys(cardDashboard).length > 0) ||
							(props.alignmentFilterData && props.alignmentFilterData.length > 0 && getListHeader())} */}
						<Box className='welcome-content dashboard' id='welcome-id'>
							{showAddGoal ? (
								<NoListFound showSkeleton={true} />
							) : currentUser ? (
								<Box textAlign='center' className='pad-container'>
									<AnimatedIcon
										className=''
										width='516'
										type='image/svg+xml'
										alt='WelcomeImage'
										imagePath={WelcomeImage}
									/>
									<Typography variant='h2'>{t('usersGoalNoDataMsg1')}</Typography>
									<Typography>{t('usersGoalNoDataMsg2')}</Typography>
								</Box>
							) : prevCycleMsg ? (
								<Box textAlign='center' className='pad-container'>
									<AnimatedIcon
										className=''
										width='516'
										type='image/svg+xml'
										alt='WelcomeImage'
										imagePath={WelcomeImage}
									/>
									<Typography variant='h2'>{t('NoRecord')}</Typography>
									<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
								</Box>
							) : currentRoute === '/team-view' && listOkrResult.myGoalOkrResponses.length === 0 ? (
								<Box textAlign='center' className='pad-container'>
									<AnimatedIcon
										className=''
										width='516'
										type='image/svg+xml'
										alt='WelcomeImage'
										imagePath={WelcomeImage}
									/>
									<Typography variant='h2'>{t('usersGoalNoDataMsg1')}</Typography>
									<Typography>{t('usersGoalNoDataMsg3')}</Typography>
								</Box>
							) : (
								<Box
									className={`pad-container ${Object.keys(cardDashboard).length > 0 ||
										(props.alignmentFilterData && props.alignmentFilterData.length > 0)
										? 'no-okr-area'
										: 'no-records-area'
										}`}
								>
									<Box className='no-record-message'>
										{Object.keys(cardDashboard).length > 0 ||
											(props.alignmentFilterData && props.alignmentFilterData.length > 0) ? (
											<>
												<Typography variant='h2' component='h2'>
													{t('noOkrFound')}
												</Typography>
												<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
											</>
										) : (
											<Box className='create-align-link-area'>
												<Box
													className='create-align-card create-new-obj-card'
													onClick={(e) => {
														showAddGoalForm(true);
														setShowProgressCard(true);
														scrollToTop(e);
													}}
												>
													<Box className='card-left'>
														<ObjectiveIcon />
														<Box>
															<Typography variant='h2'>{t('createGoalLabel')}</Typography>
															<Typography>{t('letsStartOKRMsg')}</Typography>
														</Box>
													</Box>
													<Box className='card-right'>
														<OKRButton
															title={t('createGoalLabel')}
															className='add-btn-white'
															id='create-new-obj'
															icon={<AddIconSvg />}
														/>
													</Box>
												</Box>
												<Box
													className={`create-align-card contribute-to-colleague-card ${addContributorAnchorEl1 ? 'active' : ''
														}`}
													onClick={(e) => {
														onHandleAddUserClick(e);
													}}
												>
													<Box className='card-left'>
														<AlignObjectiveIcon />
														<Box>
															<Typography variant='h2'>{t('contributeToColleague')}</Typography>
															<Typography>{t('startAlignOKRMsg')}</Typography>
														</Box>
													</Box>
													<Box className='card-right'>
														<OKRButton
															title={t('contributeToColleague')}
															className='add-btn-white'
															id='contri-to-collegue'
															icon={<AddIconSvg />}
														/>
													</Box>
												</Box>
												<Box
													className={`create-align-card import-okr-card ${addContributorAnchorEl1 ? 'active' : ''}`}
													onClick={(e) => {
														handleOpenImportDrawer(e);
													}}
												>
													<Box className='card-left'>
														<ImportOkrsIcon />
														<Box>
															<Typography variant='h2'>{t('importOkrsLevel')}</Typography>
															<Typography>{t('startImportOKRMsg')}</Typography>
														</Box>
													</Box>
													<Box className='card-right'>
														<OKRButton
															title={t('importOkrsLevel')}
															className='add-btn-white'
															id='contri-to-collegue'
															icon={<AddIconSvg />}
														/>
													</Box>
												</Box>

												<SearchUserPopover
													{...props}
													popperAddClassName={'popperPosition'}
													addContributorAnchorEl={addContributorAnchorEl1}
													addContributorOpen={addContributorOpen1}
													setAddContributorOpen={setAddContributorOpen1}
													setAddContributorAnchorEl={setAddContributorAnchorEl1}
													isCustomStyle={customStyle1}
													onSelectedUser={onSelectedUser1}
													popperAnchorOrigin={{
														vertical: 'center',
														horizontal: 'left',
													}}
													searchPlaceHolderText={t('SearchTeamColleague')}
													handleSearchUserInputChange={handleSearchUserInputChange1}
													isTeamSearch={true}
													customId={'align-okr-src-field1'}
												/>
											</Box>
										)}
									</Box>
								</Box>
							)}
						</Box>
					</>
				) : (
					<></>
				)}
			</>
		);
	};

	const onSelectedUser1 = (selected: any, ref?: any) => {
		if (Boolean(selected) && selected.length) {
			if (selected.isLeaderClicked === true) {
				setLocalStorageItem('currentUser', JSON.stringify(selected[0]));
				props.resetOkr();
				props.history.push(USER_VIEW);
				props.updateRoute(USER_VIEW);
			} else {
				if (selected[0]?.searchType && selected[0]?.searchType === 2) {
					setLocalStorageItem('currentSearchedTeam', JSON.stringify(selected[0]));
					setLocalStorageItem('currentSearchedTeamId', JSON.stringify(selected[0].organisationId));
					setLocalStorageItem('currentSearchedTeamData', JSON.stringify(selected[0]));
					props.resetOkr();
					props.history.push(HOME);
					props.updateRoute(HOME);
				} else {
					setLocalStorageItem('currentUser', JSON.stringify(selected[0]));
					props.resetOkr();
					props.history.push(USER_VIEW);
					props.updateRoute(USER_VIEW);
				}
			}
		}
	};

	const onSelectedUser = async (selected: any) => {
		if (Boolean(selected) && selected.length) {
			const { objectiveName } = objectiveKRDetails && objectiveKRDetails.okrDetails;
			const { assignmentTypeId, startDate, dueDate, keyDescription, score, startValue, targetValue } =
				objectiveKRDetails && objectiveKRDetails.krDetails
					? objectiveKRDetails.krDetails
					: objectiveKRDetails.okrDetails;
			const { contributors } =
				objectiveKRDetails && objectiveKRDetails.krDetails ? objectiveKRDetails.krDetails : { contributors: [] };
			const selectedUser = selected[0];
			const isExist =
				contributors && contributors.length
					? contributors.find((item: any) => item.employeeId === selectedUser.employeeId)
					: false;
			if (isExist) {
				setSelectedContributorDetails(contributorObject);
				setAddContributorAnchorEl(null);
				setAddContributorAnchorEl1(null);
				setAddContributorOpen(false);
				setAddContributorOpen1(false);
				if (isExist.krStatusId === Enums.ONE) {
					//Pending
					enqueueSnackbar(`${t('pendingUserMessage')} ${getFullName(selectedUser)}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				} else if (isExist.krStatusId === Enums.THREE) {
					//Declined
					enqueueSnackbar(`${getFullName(selectedUser)} ${t('declinedUserMessage')}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				} else {
					enqueueSnackbar(`${getFullName(selectedUser)} ${t('alreadyAlign')}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				}
			} else {
				const { okrDetails, krDetails } = objectiveKRDetails;
				let sourceType = Enums.TWO;
				const details = krDetails ? krDetails : okrDetails;
				const goalKeyId = details?.goalKeyId ? details?.goalKeyId : 0;
				setAlignedStatusData({
					source: details?.source,
					goalKeyId: goalKeyId,
					sourceType: sourceType,
				});
				setSelectedContributorDetails({
					...selectedContributorDetails,
					...selectedUser,
					cycleId: cycleId,
					year: okrDetails.year,
					goalStatusId: okrDetails?.goalStatusId || Enums.TWO,
					goalTypeId: okrDetails?.goalTypeId,
					krStatusId: Enums.ONE,
					krStatusName: 'Pending',
					assignmentTypeId: assignmentTypeId || Enums.TWO,
					keyResult: keyDescription || '',
					score: score || Enums.ZERO,
					startDate: startDate || '',
					dueDate: dueDate || '',
					startValue: startValue || Enums.ZERO,
					targetValue: targetValue || Enums.ZERO,
					message: '',
					metricName: '',
					currencyName: '',
					objectiveName: objectiveName || '',
					isAddedNew: true,
				});
				setAddContributorAnchorEl(null);
				setAddContributorAnchorEl1(null);
				setAddContributorOpen(false);
				setAddContributorOpen1(false);
				setOpenAddContributorDrawer(true);
				setKRAttrDateError(defaultKRDateError);
			}
		} else {
			setSelectedContributorDetails(contributorObject);
		}
	};

	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const handleSearchUserInputChange1 = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle1(false);
		} else {
			setCustomStyle1(true);
		}
	};

	const handleAddContributor = (event: any, type: String) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'close') {
			setKRAttrDateError(defaultKRDateError);
			setOpenAddContributorDrawer(false);
		}
	};

	const onSaveBecomeContributorClick = async (event: any, contributorDetail: any) => {
		event.preventDefault();
		event.stopPropagation();
		setLoader(true);
		setSnackbarProps({});
		if (contributorDetail) {
			const krDetails = {
				goalKeyId: contributorDetail.goalKeyId,
				goalObjectiveId: contributorDetail.goalObjectiveId || Enums.ZERO,
				employeeId: contributorDetail.employeeId,
				startDate: contributorDetail.startDate,
				goalStatusId: contributorDetail.goalStatusId,
				goalTypeId: contributorDetail.goalTypeId ? contributorDetail.goalTypeId : Enums.TWO,
				score: contributorDetail.score,
				keyDescription: contributorDetail.keyResult,
				dueDate: contributorDetail.dueDate,
				krStatusId: contributorDetail.krStatusId,
				currentValue: 0,
				targetValue: contributorDetail.targetValue,
				assignmentTypeId: contributorDetail.assignmentTypeId,
				krAssignerMessage: contributorDetail.krAssignerMessage,
				startValue: contributorDetail.startValue,
				metricId: contributorDetail.metricId,
				currencyId: contributorDetail.currencyId,
				ObjectiveCycleId: props.userSelectedCycle?.organisationCycleId,
				objectiveName: contributorDetail.objectiveName,
				TeamId: contributorDetail?.TeamId ? contributorDetail?.TeamId : 0,
				IsSelf: true,
				Contributors: contributorDetail?.Contributors ? contributorDetail?.Contributors : [],
				GoalTypeId: contributorDetail?.GoalTypeId
					? contributorDetail?.GoalTypeId
					: contributorDetail.goalTypeId
						? contributorDetail.goalTypeId
						: 2,
				confidenceId: contributorDetail.confidenceId,
			};
			if (contributorDetail.GoalTypeId === 1) {
				krDetails.IsSelf = false;
			}
			krDetails.GoalTypeId = contributorDetail.goalTypeId ? contributorDetail.goalTypeId : 2;
			const response = await props.becomeContributor(krDetails);
			if (response && response.data && response.data.status === Enums.STATUS_SUCCESS) {
				setSnackbarProps({ show: true, message: t('addedAsContributor'), variant: 'success' });
				setLoader(false);
				setOpenAddContributorDrawer(false);
				setBecomeAContributor(false);
				setObjectiveKRDetails({ krDetails: null, okrDetails: null });
				props.fetchOkr();
			}
		}
	};

	const onSaveContributorDetailClick = async (event: any, contributorDetail: any) => {
		event.preventDefault();
		event.stopPropagation();
		setLoader(true);
		setSnackbarProps({});
		if (contributorDetail) {
			let krDetails =
				objectiveKRDetails && objectiveKRDetails.krDetails
					? objectiveKRDetails.krDetails
					: objectiveKRDetails.okrDetails;
			krDetails = { ...krDetails, contributors: [contributorDetail] };
			if (selectedAssignmentTypeId !== null) {
				krDetails = { ...krDetails, assignmentTypeId: selectedAssignmentTypeId };
				if (krDetails && krDetails.contributors.length > 0) {
					krDetails.contributors[0].assignmentTypeId = selectedAssignmentTypeId;
				}
			}
			const response = await props.updateKrAttributes(krDetails);
			if (response && response.data && response.data.status === Enums.STATUS_SUCCESS) {
				setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
				setLoader(false);
				setOpenAddContributorDrawer(false);
				setKRAttrDateError(defaultKRDateError);
				setObjectiveKRDetails({ krDetails: null, okrDetails: null });
				props.fetchOkr();
			}
		}
	};

	const addNewKeyResult = (event: any, okr: any) => {
		const newList = [...myGoalList];
		const goalListIndex = myGoalList.findIndex((item: any) => item.goalObjectiveId === okr.goalObjectiveId);
		newList[goalListIndex] = { ...newList[goalListIndex], isNewKey: !newList[goalListIndex].isNewKey };
		setMyGoalList(newList);
	};

	const handleDeleteOkr = (e: any, okr: any, kr: any, type: any) => {
		const keyData = type === 2 ? kr : okr;
		const id = type === 1 || type === 5 ? okr.goalObjectiveId : keyData.goalKeyId;
		let goalType = type === 1 || type === 5 ? 1 : 2;

		let module = 'goals';
		let message = t('deleteConfirmationForAlignment', {
			type: `${type === 5 ? t('draft') : t('keyResult')}`,
		});
		if (type === 1) {
			message = t('deleteOkrAlertMessage');
			module = 'deleteMyObjective';
		}
		if (type === 1 && okr.myGoalsDetails?.length > 0) {
			message = t('deleteOkrAlertMessageNew');
			module = 'deleteMyObjective';
		}

		if (goalType === 2) {
			const findKrLength = okr && okr.myGoalsDetails ? okr.myGoalsDetails?.length : 0;
			let hasContributor = keyData?.contributors.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (findKrLength === 1 && Boolean(hasContributor) && hasContributor.length > 0) {
				message = t('deleteConfirmationWithKrcontributor');
			} else if (Boolean(hasContributor) && hasContributor.length > 0) {
				message = t('deleteConfirmationWithKr');
			} else {
				message = t('deleteConfirmationForAlignment', {
					type: t('keyResult'),
				});
			}
		}

		setModalProps({
			open: true,
			type: goalType,
			id,
			message,
			module: module,
			moduleType: 2,
		});
	};

	useEffect(() => {
		if (props.isAnyDrawerOpened) {
			if (modalProps.open) {
				props.isAnyDrawerOpened({
					...props.appDrawerInfo,
					isDrawerOpened: true,
				});
			} else {
				props.isAnyDrawerOpened({
					...props.appDrawerInfo,
					isDrawerOpened: false,
				});
			}
		}
	}, [modalProps]);

	const fetchDashboardProgress = () => {
		const selectedYear = getLocalStorageItem('year') || year;
		if (userDetail.employeeId && currentCycleId && selectedYear) {
			const data = `empId=${userDetail.employeeId}&cycle=${currentCycleId}&year=${selectedYear}`;
			props.getDashboardProgress(data);
		}
	};

	/** Delete objective and kr api call */
	const handleCloseObjectiveModal = async (event: any, type: Number) => {
		const userDetail: any = getUserDetails();
		const dataProps = modalProps;
		const { id } = dataProps;
		const { employeeId } = userDetail;
		let message = '';
		if (type === 1) {
			if (dataProps.type !== 1) {
				message = 'Key Result';
				const data = `goalKeyId=${id}&employeeId=${employeeId}`;
				const response = await deleteContributor(data);
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					showApiMsgs(t('deletedMessage', { message: `${message}` }), 'success');
					fetchOkr();

					// get objetiveId of kr which is being deleted
					let cokr = myGoalList
						.map((okr: any) => {
							return {
								...okr,
								myGoalsDetails: okr.myGoalsDetails.filter((kr: any) => kr.goalKeyId === id),
							};
						})
						.filter((i: any) => i.myGoalsDetails.length);

					// createing object for okr / kr to extract new scores from
					let okrObj: any = {};
					const qstring = `goalObjectiveId=${cokr[0].goalObjectiveId}&goalType=1`;
					props.getObjectiveDetails(qstring).then((res: any) => {
						if (res.data.entity) {
							okrObj[res.data.entity.goalObjectiveId] = {
								objectiveName: res.data.entity.objectiveName,
								progress: res.data.entity.progress,
								score: res.data.entity.score,
								krData: {},
							};
							res.data.entity.keyDetails.forEach((kr: any) => {
								okrObj[res.data.entity.goalObjectiveId].krData[kr.goalKeyId] = kr;
							});

							// update new score in existing data and rerender
							let updatedOkr = myGoalList.map((okr: any) => {
								if (okrObj[okr.goalObjectiveId]) {
									return {
										...okr,
										score: okrObj[okr.goalObjectiveId]?.score || okr.score,
										progress: okrObj[okr.goalObjectiveId]?.progress || okr.progress,
										myGoalsDetails: okr.myGoalsDetails.filter((kr: any) => {
											if (okrObj[okr.goalObjectiveId] && okrObj[okr.goalObjectiveId].krData[kr.goalKeyId]) {
												return {
													...kr,
													score: okrObj[okr.goalObjectiveId].krData[kr.goalKeyId]?.score,
													progress: okrObj[okr.goalObjectiveId].krData[kr.goalKeyId]?.score,
												};
											}
										}),
									};
								} else {
									return {
										...okr,
									};
								}
							});
							setMyGoalList(updatedOkr);
						} else {
							// case : when there is only single kr to delete
							let updatedOkr = myGoalList.filter((okrData: any) => okrData.goalObjectiveId !== cokr[0].goalObjectiveId);
							setMyGoalList(updatedOkr);
						}
						const data = `empId=${userDetail.employeeId}&cycle=${currentCycleId}&year=${currentYear}`;
						props.getDashboardProgress(data, true);
					});
				} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
					showErrorMsgWithObject(response);
				}
			}
		} else if (type === 2) {
			if (dataProps.type === 1) {
				message = 'Objective';
				const data = `employeeId=${employeeId}&goalObjectiveId=${id}&goalType=${dataProps.type}`;
				const response = await props.deleteObjective(data);
				if (response.data.status === 200) {
					fetchDashboardProgress();
					let updatedOkr = myGoalList.filter((okr: any) => okr.goalObjectiveId !== id);

					if (updatedOkr.length === 0) {
						setShowProgressCard(false);
					}
					setMyGoalList(updatedOkr);
					showApiMsgs(response?.data?.messageList?.Result, 'success');
				} else {
					showApiMsgs(t('deletedMessageFail', { message: `${message}` }), 'error');
				}
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	useEffect(() => {
		if (myGoalList.length) {
			let expandLength = myGoalList.filter((item: any) => item.expand).length;
			if (expandLength === myGoalList.length) {
				setIsExpandAll(true);
			} else {
				setIsExpandAll(false);
			}
		}
	}, [myGoalList]);

	const expandCollapseAll = (type: number) => {
		if (type === 1) {
			setIsExpandAll(!isExpandAll);
			const updatedList = myGoalList.map((item: any) => {
				if (isExpandAll) {
					item.expand = false;
				} else {
					item.expand = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else {
			setIsExpandAll(!isExpandAll);
			const updatedList = myArchiveGoalList.map((item: any) => {
				if (isExpandAll) {
					item.expand = false;
				} else {
					item.expand = true;
				}
				return item;
			});
			setArchiveMyGoalList(updatedList);
		}
	};
	const addClassWelcome = () => {
		const welcomeDiv = document.getElementById('welcome-id');
		let welcomeContainer = document.getElementById('wrap-container');
		if (welcomeDiv?.classList.contains('welcome-content')) {
			welcomeContainer?.classList.add('welcome-wrap-content');
		} else {
			welcomeContainer?.classList.remove('welcome-wrap-content');
		}
	};
	const scrollList = (e: any) => {
		let wrapperContainer = document.getElementById('wrap-container');
		let objElement = document.getElementById('obj-header');
		let topPos = objElement?.offsetTop;
		topPos = topPos ? topPos - 70 : 0;
		if (topPos > 58) {
			if (window.pageYOffset > topPos) {
				objElement?.classList.add('sticky');
				wrapperContainer?.classList.add('sticky-obj-shadow');
			} else {
				objElement?.classList.remove('sticky');
				wrapperContainer?.classList.remove('sticky-obj-shadow');
			}
		} else {
			wrapperContainer?.classList.remove('sticky-obj-shadow');
		}
	};
	const sendUnlockRequest = async () => {
		// setModalProps({
		// 	open: true,
		// 	message: isUnLockRequested ? t('request4AlreadyRaised') : t('request4Editing', { emailID: userDetail.emailId }),
		// 	unlockRequest: true,
		// 	module: 'myGoal',
		// 	moduleType: 1,
		// });
		console.log(props?.listOkrResult);
		if (!props?.listOkrResult?.isScoreLocked) {
			enqueueSnackbar(t('duringGracePeriod'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		} else {
			enqueueSnackbar(t('afterGracePeriod'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	useEffect(() => {
		if (showArchiveList) {
			setOkrType('0');
		} else {
			setOkrType('1');
		}
	}, [showArchiveList]);
	const handleOkrTypeChange = (tabValue: any) => {
		setTabValue(0);
		setOkrType(tabValue);
		currentDashboardTab(tabValue);
	};

	const handleOkrTypeClose = () => {
		setOpenOkrType(false);
	};

	const handleOkrTypeOpen = () => {
		setOpenOkrType(true);
	};

	useEffect(() => {
		if (tabValue === 0) {
			setTabButtonFilter('okrView');
		} else if (tabValue === 1) {
			setTabButtonFilter('teamView');
		} else {
			setTabButtonFilter('directReport');
		}
		setShowProgressCard(false);
	}, [tabValue]);

	const addDirectReportSortBy = (value: any) => {
		setDirectReportFilter(value);
	};

	const getFilter = () => {
		if (props.alignmentFilterData) {
			return (
				<>
					{tabValue !== 3 && tabValue !== 4 && (
						<AlignmentMapFilter
							setCardDashboard={setCardDashboard}
							viewName={tabButtonFilter}
							{...props}
							addDirectReportSortBy={addDirectReportSortBy}
							dashboardListing={true}
							tabValue={tabValue}
						/>
					)}
				</>
			);
		}
	};

	const getListHeader = () => (
		<OkrListingHead
			sortGoalList={props.sortGoalList}
			sortingColumn={props.sortingColumn}
			sortOrder={props.sortOrder}
			showArchiveList={showArchiveList}
			expandCollapseAll={expandCollapseAll}
			isExpandAll={isExpandAll}
			showAddGoal={showAddGoal}
			isFutureCycle={isFutureCycle}
			myArchiveGoalList={myArchiveGoalList}
			getFilter={getFilter}
			myGoalList={myGoalList}
		/>
	);

	const checkRedirectToUrl = async () => {
		let redirectUrl: any = getLocalStorageItem('redirectUrl');
		if (Boolean(redirectUrl)) {
			removeLocalStorageItem('redirectUrl');
			redirectUrl = JSON.parse(redirectUrl);
			if (redirectUrl.url && redirectUrl.url.includes('krAssignmentOpenDrawer')) {
				const itemArray = redirectUrl.url.split('/');
				const krId = itemArray[1] ? parseInt(itemArray[1]) : 0;
				const okrId = itemArray[2] ? parseInt(itemArray[2]) : 0;
				const acceptDecline = itemArray[3] ? itemArray[3] : 0;
				if (okrId > 0) {
					Promise.allSettled([props.getOkrDetail(okrId), props.getKrDetail(krId)]).then((response: any) => {
						if (response) {
							let getOKRDetail =
								Boolean(response) && response.length && response[0] && response[0].value ? response[0].value : '';
							let getKRDetail =
								Boolean(response) && response.length && response[1] && response[1].value ? response[1].value : '';
							if (getOKRDetail.data.status === 200 && getKRDetail.data.status === 200) {
								if (getKRDetail.data.entity.krStatusId === Enums.KR_STATUS_PENDING) {
									setOpenDrawer({
										open: true,
										okr: getOKRDetail.data.entity,
										kr: getKRDetail.data.entity,
										type: acceptDecline,
										KRType: 'WithParent',
										moduleType: 1,
									});
								} else {
									setModalProps({
										open: true,
										type: 'AcceptKR',
										message: t('actionAlreadyTaken'),
										module: 'user',
										isCancel: true,
										moduleType: 1,
									});
								}
							} else {
								const getOKRDetailresponseAPI = getOKRDetail.data.messageList;
								const getKRDetailresponseAPI = getKRDetail.data.messageList;
								const messagesGoal = Object.keys(getOKRDetailresponseAPI).map((item) => getOKRDetailresponseAPI[item]);
								const messagesKR = Object.keys(getKRDetailresponseAPI).map((item) => getKRDetailresponseAPI[item]);
								let errorMessage = [];
								if (messagesGoal.length > 0) {
									errorMessage = messagesGoal;
								} else if (messagesKR.length > 0) {
									errorMessage = messagesKR;
								}
								setModalProps({
									open: true,
									type: 'AcceptKR',
									message: errorMessage,
									module: 'user',
									isCancel: true,
									moduleType: 1,
								});
							}
						}
					});
				} else {
					const krDetail = await props.getKrDetail(krId);
					if (krDetail.status === 200) {
						if (krDetail.data.entity.krStatusId === Enums.KR_STATUS_PENDING) {
							setOpenDrawer({
								open: true,
								okr: krDetail.data.entity,
								kr: {},
								type: acceptDecline,
								KRType: 'Standalone',
								moduleType: 1,
							});
						} else {
							setModalProps({
								open: true,
								type: 'AcceptKR',
								message: t('actionAlreadyTaken'),
								module: 'user',
								isCancel: true,
								moduleType: 1,
							});
						}
					}
				}
			}
		}
	};

	useEffect(() => {
		if (currentDashboardTab) {
			currentDashboardTab(tabValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabValue]);

	useEffect(() => {
		if (props.userImpersonated?.redirection && tabValue !== 0) {
			setTabValue(0);
			props.isImpersonated({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.userImpersonated]);

	const handleTabChange = (event: any, newValue: Number) => {
		setLastProgressUpdate({});
		removeLocalStorageItem('lastSavedGoal');
		removeLocalStorageItem('teamId');
		setOpenTeamDetails(null);
		setTabValue(newValue);
		addAlignmentFilter([]);
		setIsTabChanged(true);
		showAddGoalForm(false);
		setCardDashboard({});
		if (newValue === 0) {
			fetchOkr();
			props.updateGoalType('goal');
		} else if (newValue === 1) {
			window.scrollTo({ top: 0 });
			props.updateGoalType('team');
		} else if (newValue === 2) {
			props.updateGoalType('directReports');
			window.scrollTo({ top: 0 });
			props.resetAlignment();
		} else if (newValue === 3) {
			props.updateGoalType('checkIns');
		} else if (newValue === 4) {
			props.updateGoalType('organisation');
		}
		const tabToOpen =
			newValue === 2
				? 'directReport'
				: newValue === 1
					? 'TEAMSOKR'
					: newValue === 3
						? 'checkIn'
						: newValue === 4
							? 'orgChart'
							: newValue === 0;
		setLocalStorageItem(
			'globalBackUrl',
			JSON.stringify({
				url: HOME,
				tabToOpen,
			})
		);
		if (newValue !== 0) {
			showHideArchiveList(0);
		}
	};

	// based on save action type calling okrTeamDetails api
	const addObjectiveCallback = (type: any) => {
		setSaveActionType(type);
	};

	const showAddGoalFormAndUpdateList = (type: boolean) => {
		if (tabValue !== 0) {
			setTabValue(0);
			setIsTabChanged(true);
			setLastProgressUpdate({});
			removeLocalStorageItem('lastSavedGoal');
			removeLocalStorageItem('teamId');
			setOpenTeamDetails(null);
			currentDashboardTab(0);
		}
		setShowProgressCard(true);
		showAddGoalForm(type);
	};

	useEffect(() => {
		if (showAddGoal) {
			props.isAnyDrawerOpened({
				...props.appDrawerInfo,
				isNewObjectiveFormOpened: true,
			});
		} else {
			props.isAnyDrawerOpened({
				...props.appDrawerInfo,
				isNewObjectiveFormOpened: false,
			});
		}
	}, [showAddGoal]);

	useEffect(() => {
		props.isAnyDrawerOpened({
			...props.appDrawerInfo,
			isDrawerOpened: openAddContributorDrawer,
		});
	}, [openAddContributorDrawer]);

	const handleBackBtn = () => {
		props.resetOkr();
		let cycleObj = JSON.parse(JSON.stringify(props.cycleIdDetails));
		props.updateCurrentCycleId(cycleObj);
	};

	const scrollToTop = (e: any) => {
		if (props.isFutureCycle === true) {
			setTimeout(function () {
				window.scroll({ top: 90, left: 0, behavior: 'smooth' });
			}, 500);
		} else {
			setTimeout(function () {
				window.scroll({ top: 280, left: 0, behavior: 'smooth' });
			}, 500);
		}
	};
	const updateConfidence = (okr: any, index: number, value: number) => {
		const updatedKrList = myGoalList.map((item: any) => {
			if (item.goalObjectiveId === okr.goalObjectiveId) {
				item.myGoalsDetails[index].confidenceId = value;
				item.myGoalsDetails[index].isConfidenceAlert = false;
				let okrConfidence = false;
				item.myGoalsDetails.forEach((krItem: any, krIndex: number) => {
					if (krIndex !== index && krItem.isConfidenceAlert) {
						okrConfidence = true;
					}
				});
				item.isConfidenceAlert = okrConfidence;
			}
			return item;
		});
		setMyGoalList(updatedKrList);
		if (cycleId) {
			const data = `cycle=${cycleId}`;
			props.getConfidenceAlertDetails(data);
		}
	};
	return (
		<Fragment>
			{snackbarProps.show && <CommonMessages snackbarProps={snackbarProps} setSnackbarProps={setSnackbarProps} />}
			{addContributorOpen && (
				<SearchUserPopover
					{...props}
					popperAddClassName={'quick-add-contributor-popup'}
					addContributorAnchorEl={addContributorAnchorEl}
					addContributorOpen={addContributorOpen}
					setAddContributorOpen={setAddContributorOpen}
					setAddContributorAnchorEl={setAddContributorAnchorEl}
					isCustomStyle={customStyle}
					onSelectedUser={onSelectedUser}
					handleSearchUserInputChange={handleSearchUserInputChange}
					currentObjective={currentObjective}
					customId={'add-contri-src-field'}
				/>
			)}
			{openAddContributorDrawer && (
				<BecomeContributor
					{...props}
					type={becomeAContributor ? 3 : 1}
					becomeAContributor={becomeAContributor}
					myGoalList={myGoalList}
					krContributorDetailDashboard={krContributorDetailDashboard}
					setKRContributorDetailDashboard={setKRContributorDetailDashboard}
					krAttrDateError={krAttrDateError}
					handleAddContributor={handleAddContributor}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					onSaveBecomeContributorClick={onSaveBecomeContributorClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedContributorDetails={selectedContributorDetails}
					objectiveKRDetails={objectiveKRDetails}
					setAssignmentTypeId={setAssignmentTypeId}
					t={t}
					setKRAttrDateError={setKRAttrDateError}
					defaultKRDateError={defaultKRDateError}
					okrMasterData={props.okrMasterData}
					openDrawer={openAddContributorDrawer}
					loader={loader}
					alignedStatusData={alignedStatusData}
				/>
			)}

			{props.userSelectedCycle.label && isFutureCycle === true && !currentUser && (
				<>
					<Box className='back-to-main cycle-back'>
						<Typography variant='body2'>
							<Link id='back-btn' onClick={handleBackBtn}>
								<BackArrow />
								{t('back')}
							</Link>

							{t('watchingOKRS', { name: `${props.userSelectedCycle?.label}` })}
						</Typography>
					</Box>
				</>
			)}
			{props.dashboardProgressData && props.dashboardProgressData.hasOwnProperty('avgScore') && (
				<Box
					className='page-subheader page-subheader-home'
					id='obj-header'
					style={{
						visibility:
							readOnlyView === true ? 'hidden' : !currentUser && props.listOkrSuccess !== '' ? 'visible' : 'hidden',
					}}
				>
					<Box className='page-subheader-inner'>
						<Box className='page-subheader-left'>
							<OkrListingTabs
								t={t}
								okrType={okrType}
								handleTabChange={handleTabChange}
								tabValue={tabValue}
								userDetail={userDetail}
								showHideArchiveList={showHideArchiveList}
								showArchiveList={showArchiveList}
								handleOkrTypeChange={handleOkrTypeChange}
								{...props}
							/>
							{!showAddGoal && isFutureCycle !== true && tabValue === 2 && (
								<Box className='goal-okr-filter alignment-map-filter'>
									{showArchiveList && myArchiveGoalList.length > 0 ? getFilter() : !showArchiveList && getFilter()}
								</Box>
							)}
						</Box>
						<Box className='page-subheader-right'>
							{props.listOkrResult && props.listOkrResult.isLocked && !currentUser && (
								<Tooltip title={`${t('lockTooltipText')}`} arrow>
									<Button
										disabled={currentUser ? true : false}
										className='btn-circle-white lockPurple'
										onClick={() => sendUnlockRequest()}
									>
										<LockIcon />
									</Button>
								</Tooltip>
							)}

							<PersonalFeedbackMenu {...props} loggedInUser={!currentUser} userDetail={userDetail} />
							{getPermissionResult && checkPermission(getPermissionResult?.employeePermissions, createOkrs) ? (
								<CreateAlignOkr
									{...props}
									setOpenImportDrawer={setOpenImportDrawer}
									year={year}
									cycleId={cycleId}
									showApiMsgs={showApiMsgs}
									setLoader={setLoader}
									handleOpenImportDrawer={handleOpenImportDrawer}
									openImportDrawer={openImportDrawer}
									showAddGoalForm={showAddGoalFormAndUpdateList}
									panelOpenFrom={HOME}
								/>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</Box>
			)}
			{props.dashboardProgressData &&
				props.dashboardProgressData.hasOwnProperty('avgScore') &&
				!currentUser &&
				!showArchiveList &&
				isFutureCycle != true &&
				tabValue === 0 &&
				((myGoalList.length > 0 &&
					((props.listOkrResult &&
						props.listOkrResult.myGoalOkrResponses &&
						props.listOkrResult.myGoalOkrResponses.length > 0) ||
						(showArchiveList && myArchiveGoalList && myArchiveGoalList.length > 0))) ||
					showProgressCard ||
					Object.keys(cardDashboard).length > 0 ||
					(props.alignmentFilterData && props.alignmentFilterData.length > 0)) && (
					<Dashboard cardDashboard={cardDashboard} handleCardClick={handleCardClick} {...props} />
				)}
			{((!goalDetail.showAttributeDrawer &&
				props.listOkrSuccess !== 'pending' &&
				(tabValue === 0 || tabValue === 2) &&
				showAddGoal) ||
				(goalDetail.showAttributeDrawer && props.listOkrSuccess !== 'pending')) && (
					<Fragment>
						{props.okrMasterData && (
							<>
								<CreateObjective
									{...props}
									showAddGoalForm={showAddGoalForm}
									showAddGoal={showAddGoal}
									durationCycleDetail={durationCycleDetail}
									currentCycleEndDate={currentCycleEndDate}
									currentCycleId={currentCycleId}
									year={year}
									t={t}
									showApiMsgs={showApiMsgs}
									fetchOkr={fetchOkr}
									goalDetail={goalDetail}
									setGoalDetail={setGoalDetail}
									setIsGoalCreated={setIsGoalCreated}
									saveAndEditObjectiveAndKr={saveAndEditObjectiveAndKr}
									setFormSubmit={setFormSubmit}
									formSubmit={formSubmit}
									isGoalCreated={isGoalCreated}
									isTabChanged={isTabChanged}
									setIsTabChanged={setIsTabChanged}
									addObjectiveCallback={addObjectiveCallback}
									isNewOkr={
										!goalDetail.showAttributeDrawer &&
										props.listOkrSuccess !== 'pending' &&
										(tabValue === 0 || tabValue === 2) &&
										showAddGoal
									}
									resToUseInCalendar={resToUseInCalendar}
									editModee={editModee}
									setEditModee={setEditModee}
								/>
							</>
						)}
					</Fragment>
				)}
			<Box className='okr-tab-panel'>
				{currentUser ? (
					getListing()
				) : (
					<>
						<OkrTabPanel value={tabValue} index={0}>
							{getListing()}
						</OkrTabPanel>
						<OkrTabPanel value={tabValue} index={1}>
							<OkrCard
								{...props}
								userDetail={userDetail}
								fetchOkr={fetchOkr}
								openTeamDetails={openTeamDetails}
								saveActionType={saveActionType}
								setSaveActionType={setSaveActionType}
								tabValue={tabValue}
								isListOkrResult={listOkrResult}
								goalDetail={goalDetail}
								showAddGoalForm={showAddGoalForm}
								setGoalDetail={setGoalDetail}
								setIsGoalCreated={setIsGoalCreated}
								saveAndEditObjectiveAndKr={saveAndEditObjectiveAndKr}
								setFormSubmit={setFormSubmit}
								formSubmit={formSubmit}
								isGoalCreated={isGoalCreated}
								isTabChanged={isTabChanged}
								setIsTabChanged={setIsTabChanged}
								addObjectiveCallback={addObjectiveCallback}
								cardDashboard={cardDashboard}
								showApiMsgs={showApiMsgs}
								getFilter={getFilter}
							/>
						</OkrTabPanel>
						<OkrTabPanel value={tabValue} index={2}>
							<Box className='pad-container'>
								<DirectReportCard
									directReportFilter={directReportFilter}
									userDetail={userDetail}
									year={year}
									cycleId={cycleId}
									{...props}
								/>
							</Box>
						</OkrTabPanel>
						<OkrTabPanel value={tabValue} index={3}>
							<Box className='pad-container'>
								<WeeklyCheckIns
									{...props}
									userDetail={userDetail}
									year={year}
									cycleId={currentCycleId}
									showApiMsgs={showApiMsgs}
								/>
							</Box>
						</OkrTabPanel>
						<OkrTabPanel value={tabValue} index={4}>
							<Box className='pad-container'>
								<OrganisationChart {...props} isOrgDashboard={true} />
							</Box>
						</OkrTabPanel>
					</>
				)}
			</Box>
			{openDrawer.open && openDrawer.type === 'accept' && (
				<KrAcceptAttributes
					{...props}
					loader={loader}
					goalList={myGoalList}
					open={openDrawer.open}
					okr={openDrawer.okr}
					KRType={openDrawer?.KRType}
					kr={openDrawer?.kr || {}}
					isAcceptKRFormUpdated={isAcceptKRFormUpdated}
					setIsAcceptKRFormUpdated={setIsAcceptKRFormUpdated}
					setFeedback={setFeedback}
					openDrawer={openDrawer}
					setOpenDrawer={setOpenDrawer}
					setLoader={setLoader}
					fetchOkr={fetchOkr}
				/>
			)}
			{openDrawer.open && openDrawer.type === 'decline' && (
				<KrDeclineAttributes
					{...props}
					loader={loader}
					goalList={myGoalList}
					open={openDrawer.open}
					okr={openDrawer.okr}
					KRType={openDrawer?.KRType}
					kr={openDrawer?.kr || {}}
					setFeedback={setFeedback}
					setModalProps={setModalProps}
					setOpenDrawer={setOpenDrawer}
					setLoader={setLoader}
					fetchOkr={fetchOkr}
				/>
			)}
			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
					isCancel={modalProps.isCancel || false}
				/>
			)}
			{feedback.open && (
				<Feedback
					feedbackOld={true}
					openFeedbackType={feedback.reqType}
					objective={feedback.okr}
					objId={feedback.goalId}
					krId={feedback.krId}
					setFeedback={setFeedback}
					userDetail={userDetail}
					currentUser={feedback.sourceContributor}
					sourceDetails={feedback.sourceContributor}
					resetDecline={resetDecline}
					{...{ ...props, type: feedback.goalType }}
				/>
			)}
		</Fragment>
	);
};

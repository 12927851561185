import { AnnotationConstraints, NodeModel, PortVisibility } from '@syncfusion/ej2-react-diagrams';
import { Enums } from '../../../config/enums';
import { abbreviateNumber } from '../../../config/utils';
import TermsOfUse from '../../TermsOfUse';
import { DiagramHelperUtil, MapTypes } from './diagram-helper-util';
import { DiagramJsonHelper, TemplateType } from './diagram-json-helper';
let nodeSelectedStyle = {
	strokeColor: 'rgba(57,158,247,255)',
	fill: 'white',
	strokeWidth: 3,
};
let nodeDefaultStyle = DiagramHelperUtil.nodeSelectedStyle;
let parentConnectorDefaultStyle = DiagramHelperUtil.parentConnectorDefaultStyle;
let parentConnectorHiddenStyle = DiagramHelperUtil.parentConnectorHiddenStyle;
let connectorDefaultStyle = DiagramHelperUtil.connectorDefaultStyle;
let connectorSelectedStyle = DiagramHelperUtil.connectorSelectedStyle;

let krResIdPrefix = 'krResItem';
let staIdPrefix = 'sta';
export class DiagramSelectionHelper {
	//#region Node Selection
	diagramIns: any;
	diagramOkrHelper: any;
	diagramLayoutHelper: any;
	allKrHelperLink: any = [];
	allKrHelperNodes: any = [];
	staHiddenIcons: any = [];
	reachedMyOkr = true;
	helperNodeModel: any;
	laterVisibleObj: any = [];
	bindProps = DiagramJsonHelper.bindProps;
	dynamicKrInfo: any = { krNodes: [], parentNode: null, parentOffsetY: 0, parentHeight: 0 };

	constructor() {
		this.helperNodeModel = { top: 0, left: 0, width: 60, height: 20 };
	}

	SetHelpers(diagramInstance: any, diagramLayoutHelper?: any, diagramOkrHelper?: any) {
		this.diagramIns = diagramInstance;
		if (diagramOkrHelper) this.diagramOkrHelper = diagramOkrHelper;
		if (diagramLayoutHelper) this.diagramLayoutHelper = diagramLayoutHelper;
	}
	//#region  - Events
	OnDiagramMouseClick(e: any, diagramIns: any, diagramLayoutHelper: any, diagramOkrHelper: any) {
		this.SetHelpers(diagramIns, diagramLayoutHelper, diagramOkrHelper);
		this.RemoveAllSelection();
		// this.RefreshLayout();
		let nodeId = this.GetNodeIdFromElement(e.target, diagramIns);
		let node = diagramIns.nodes.find((n: any) => n.id === nodeId);
		if (node) {
			this.OnNodeClick(node, e);
		} else {
			this.ClearDynamicKrResults();
		}
	}
	OnDiagramMouseMove(e: any, diagramIns: any) {
		this.ToggleNodeShadow(e, diagramIns);
	}
	GetNodeIdFromElement(element: any, diagramInstance: any) {
		let nodeId;
		let krItem = element.closest('.kr-item');
		if (krItem) {
			nodeId = 'krResItem_' + krItem.id.substring(krItem.id.indexOf('_') + 1);
		} else {
			let nodeTemplateElement = element.closest('.foreign-object');
			if (nodeTemplateElement) {
				let suffixIndex = nodeTemplateElement.id.indexOf('_content_html_element');
				if (suffixIndex >= 0) {
					nodeId = nodeTemplateElement.id.substring(0, suffixIndex);
				}
			} else if (element.id.indexOf('v-left-port') >= 0) {
				nodeId = element.id.substring(0, element.id.indexOf('_')) + '_sourceIcon_virtual';
			} else if (element.id.indexOf('left-port') >= 0) {
				nodeId = element.id.substring(0, element.id.indexOf('_')) + '_sourceIcon';
			} else if (element.id.indexOf('v-right-port') >= 0) {
				nodeId = element.id.substring(0, element.id.indexOf('_')) + '_contributorIcon_virtual';
			} else if (element.id.indexOf('right-port') >= 0) {
				nodeId = element.id.substring(0, element.id.indexOf('_')) + '_contributorIcon';
			} else {
				let g = element.closest('g');
				if (g) {
					let gElement = this.GetParentGroupElement(g);
					if (gElement) return gElement.id.replace('_groupElement', '');
				}
				nodeId = element.id.substring(0, element.id.indexOf('_'));
			}
		}
		return nodeId;
	}
	GetParentGroupElement(g: any) {
		let parentNode = g;
		if (parentNode && parentNode.id.indexOf('_groupElement') >= 0) {
			let tempParentNode = this.GetParentGroupElement(g.parentNode);
			if (tempParentNode) {
				parentNode = tempParentNode;
			}
		} else {
			return null;
		}
		return parentNode;
	}
	//#endregion
	//#region - Node Shadow Effect
	ToggleNodeShadow(e: any, diagramInstance: any) {
		let isClearAllShadow = true;
		if (e.target) {
			let nodeId = this.GetNodeIdFromElement(e.target, diagramInstance);
			if (nodeId) {
				let gElement;
				if (nodeId.indexOf('krResItem') >= 0) {
					let childNode = diagramInstance.nodes.find((n: any) => n.id === nodeId);
					if (childNode && childNode.data) nodeId = childNode.data.diagramParentNodeId;
				}
				if (DiagramHelperUtil.isNullOrUndefined(nodeId)) {
					if (nodeId.indexOf('_') >= 0) nodeId = nodeId.substring(0, nodeId.indexOf('_'));
				}
				gElement = document.getElementById(nodeId + '_groupElement');
				if (gElement) {
					gElement?.classList.add('svg-hover-shadow');
					isClearAllShadow = false;
				}
			}
		}
		if (isClearAllShadow) {
			document.querySelectorAll('.svg-hover-shadow').forEach((element) => {
				element.classList.remove('svg-hover-shadow');
			});
		}
	}
	//#endregion

	//#region - Reverse Connection
	DoReverseConnection(diagramInstance: any) {
		let ins = this;
		diagramInstance.connectors.forEach((c: any) => {
			if (!this.IsSelectionHelper(c)) {
				let sourceNode = diagramInstance.nodes.find((n: any) => n.id === c.sourceID);
				let targetNode = diagramInstance.nodes.find((n: any) => n.id === c.targetID);
				if (sourceNode && targetNode) {
					if (sourceNode.ports.length > 1) {
						c.sourcePortID = sourceNode.ports[1].id;
						c.targetPortID = targetNode.ports[0].id;
						if (sourceNode.data && sourceNode.data.isParentOkr) {
							c.sourceDecorator.shape = 'Arrow';
							c.sourceDecorator.style.strokeColor = parentConnectorDefaultStyle.decorator.strokeColor;
							c.sourceDecorator.style.fill = parentConnectorDefaultStyle.decorator.fill;
							c.sourceDecorator.style.strokeWidth = parentConnectorDefaultStyle.strokeWidth;
							c.targetDecorator.shape = 'none';
						}
					}
				}
				DiagramHelperUtil.SetVisibility(c, true);
				DiagramHelperUtil.RefreshUI(c);
			}
		});
	}
	//#endregion

	//#region - Distance Info node click
	OnDistInfoNodeClick(distInfoNode: any) {
		if (distInfoNode) {
			let okrNodeId = distInfoNode.id.substring(distInfoNode.id.indexOf('_') + 1);
			let okrNode = this.diagramIns.nodes.find((n: any) => n.id === okrNodeId);
			if (okrNode && okrNode.data) {
				let nextOkr = this.diagramIns.nodes.find(
					(n: any) => n.data && n.data.isMyOkr && n.data.sIndex === okrNode.data.sIndex + 1
				);
				if (nextOkr) {
					this.diagramIns.scrollSettings.verticalOffset = nextOkr.offsetY;
				}
			}
		}
	}
	//#endregion

	//#region  - Kr result item selection
	OnNodeClick(actualObject: any, e: any) {
		if (actualObject) {
			if (actualObject.id.indexOf('_contributorIcon') >= 0) {
				if (actualObject.id.indexOf('_virtual') >= 0) {
					// this.ClearDynamicKrResults();
					this.OnOkrIconClick(actualObject, 'virtualcontributor');
				} else {
					this.OnOkrIconClick(actualObject, 'contributor');
				}
			} else if (actualObject.id.indexOf('_sourceIcon') >= 0) {
				if (actualObject.id.indexOf('_virtual') >= 0) {
					// this.ClearDynamicKrResults();
					this.OnOkrIconClick(actualObject, 'virtualparent');
				} else {
					this.OnOkrIconClick(actualObject, 'source');
				}
			} else if (actualObject.id.indexOf('topDistNode_') >= 0) {
				this.ClearDynamicKrResults();
				this.OnDistInfoNodeClick(actualObject);
			} else {
				// check if clicked okr has any pending node then enable click
				let pendingNode =
					actualObject?.data?.okrViewKeyResults &&
					actualObject?.data?.okrViewKeyResults?.find((item: any) => item.krStatusId === 1);
				if (
					(DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
						DiagramHelperUtil.MapType === MapTypes.CompactViewTeam) &&
					this.IsOkrNode(actualObject)
				) {
					if (pendingNode && !e.target.closest('.kebab-icon') && !e.target.closest('.connect-icon')) {
						this.ShowDynamicKrResults(actualObject, this.diagramIns, this.diagramOkrHelper);
					} else if (!e.target.closest('.kebab-icon') && !e.target.closest('.connect-icon')) {
						this.ClearDynamicKrResults();
					}
				} else if (DiagramHelperUtil.MapType === MapTypes.Relax || DiagramHelperUtil.MapType === MapTypes.RelaxTeam) {
					let krItemNode = this.GetKrResultItem(actualObject);

					if (krItemNode && krItemNode.data) {
						let rootKrNode = this.GetRootKrNode(krItemNode);

						if (rootKrNode && rootKrNode.data) {
							let selected = this.SelectKrResult(rootKrNode, e, krItemNode.data);
							this.ToggleParentConnectorStyle(parentConnectorHiddenStyle);
						}
					}
				}
			}
		}
	}
	IsOkrNode(node: any) {
		if (node && node.id && (node.data.templateType === TemplateType.Okr || node.id.indexOf('_okrItem') >= 0)) {
			return true;
		}
		return false;
	}
	GetKrResultItem(actualObject: any) {
		if (
			actualObject &&
			(actualObject.id.indexOf(krResIdPrefix + '_') >= 0 || actualObject.id.indexOf(staIdPrefix + '-') >= 0)
		) {
			return actualObject;
		}
		return null;
	}
	GetKrData(krItemNode: any) {
		let topNode = this.diagramIns.nodes.find((n: any) => n.id === krItemNode.data.diagramParentNodeId);
		let okrData = topNode && topNode.data ? topNode.data : krItemNode.data;
		if (okrData) {
			if (okrData.objectiveType === 2) {
				return okrData;
			} else {
				let krItemId = krItemNode.data.krItemId;
				if (okrData && krItemId && okrData.okrViewKeyResults && okrData.okrViewKeyResults.length > 0) {
					return okrData.okrViewKeyResults.find((kr: any) => kr[this.bindProps.krId] === krItemId);
				}
			}
		}
		return null;
	}
	OnOkrIconClick(node: any, iconType: string) {
		if (iconType === 'virtualcontributor' || iconType === 'contributor') {
			this.SwapRightPort(node, iconType);
		}
		if (iconType === 'virtualparent' || iconType === 'source') {
			this.SwapLeftPort(node, iconType);
		}
		this.ToggleExpand(node, iconType);
	}
	SwapRightPort(iconNode: any, iconType: string) {
		let topNode = this.GetNode(iconNode.data.diagramParentId);
		let oEdges = topNode.outEdges;
		if (topNode.ports && topNode.ports.length > 3 && oEdges && oEdges.length > 0 && topNode.data) {
			for (let i = 0; i < oEdges.length; i++) {
				let link = this.GetConnector(oEdges[i]);
				if (iconType === 'virtualcontributor') {
					link.sourcePortID = topNode.ports[3].id;
					DiagramHelperUtil.RefreshUI(link);
					if (i === 0 && topNode.data.isContributorExist) {
						link.targetPortID = this.GetNode(link.targetID).ports[2].id;
						DiagramHelperUtil.RefreshUI(link);
					}
				} else {
					link.sourcePortID = topNode.ports[1].id;
					DiagramHelperUtil.RefreshUI(link);
					if (i === 0) {
						link.targetPortID = this.GetNode(link.targetID).ports[0].id;
						DiagramHelperUtil.RefreshUI(link);
					}
				}
			}
		}
	}
	SwapLeftPort(iconNode: any, iconType: string) {
		let topNode = this.diagramIns.getNodeObject(iconNode.data.diagramParentId);
		let edges = topNode.inEdges;
		if (topNode.ports && topNode.ports.length > 3 && edges && edges.length > 0 && topNode.data) {
			for (let i = 0; i < edges.length; i++) {
				let link = this.GetConnector(edges[i]);
				if (iconType === 'virtualparent') {
					if (topNode.data.isSourceExist) {
						link.targetPortID = topNode.ports[2].id;
						if (i === 0) {
							link.sourcePortID = this.GetNode(link.sourceID).ports[3].id;
						}
					}
				} else {
					link.targetPortID = topNode.ports[0].id;
					if (i === 0) {
						link.sourcePortID = this.GetNode(link.sourceID).ports[1].id;
					}
				}
				setTimeout(() => {
					DiagramHelperUtil.RefreshUI(link);
				}, 0);
			}
		}
	}
	RefreshLayout(node: any, isContributor: any) {
		let ins = this;
		// if (this.laterVisibleObj && this.laterVisibleObj.length > 0) {
		// 	setTimeout(() => {
		// 		while (ins.laterVisibleObj.length > 0) {
		// 			let con = ins.laterVisibleObj.pop()
		// 			ins.SetElementVisibility(con, true);
		// 			setTimeout(() => {
		// 				con.dataBind();
		// 			}, 0.01);
		// 		}
		// 	}, 0);
		// }
		//this.diagramLayoutHelper.DoCustomOkrLayout(this.diagramIns, this.diagramOkrHelper, false);
		this.diagramLayoutHelper.LayoutOnExpOrCOl(this.diagramIns, this.diagramOkrHelper, node, isContributor);
		DiagramHelperUtil.TimerStart('diagram Databind');
		DiagramHelperUtil.RefreshUI(this.diagramIns);
		DiagramHelperUtil.TimerEnd('diagram Databind over');
		// 	setTimeout(() => {
		// 		while (ins.laterVisibleObj.length > 0) {
		// 			ins.SetObjVisibility(ins.laterVisibleObj.pop(), true);
		// 		}
		// 		setTimeout(() => {
		// 			ins.diagramOkrHelper.updateOverview();
		// 		}, 20);
		// 	}, 20);
		// }
	}
	GetToggleValue(diagramIns: any, edges: any, nodeTypeName: string, isVirtual: boolean) {
		let virtualPropName = nodeTypeName === 'sourceID' ? 'isParentVirtualLink' : 'isVirtualLink';
		for (let i = 0; i < edges.length; i++) {
			let line = diagramIns.connectors.find((n: any) => n.id === edges[i]);
			let node = diagramIns.nodes.find((n: any) => n.id === line[nodeTypeName]);
			if (node.data[virtualPropName] === isVirtual) {
				return node.visible;
			} else if (!node.data[virtualPropName] && !isVirtual) {
				return node.visible;
			}
		}
		return false;
	}
	ToggleExpand(iconNode: any, type: string) {
		if (iconNode && iconNode.data) {
			var actualNodeId = iconNode.id.substring(0, iconNode.id.indexOf('_'));
			var actualNode = this.diagramIns.nodes.find((n: any) => n.id === actualNodeId);

			if (!actualNode || !actualNode.data) return;
			if (
				type === 'contributor' &&
				!actualNode.data.isParentOkr &&
				actualNode.outEdges &&
				actualNode.outEdges.length > 0
			) {
				iconNode.data.expanded = !iconNode.data.expanded;
				if (iconNode.data.expanded) {
					let conIconNode = this.GetNode(actualNode.id + '_contributorIcon_virtual');
					if (conIconNode && conIconNode.data) {
						conIconNode.data.expanded = false;
						this.ToggleNodeVisibility(actualNode, true, false, false, true);
					}
				}
				this.OnExpandOrCollapse(actualNode, true, iconNode.data.expanded, false, false);
				this.RefreshLayout(actualNode, true);
				return;
			}
			if (
				type === 'source' &&
				(actualNode.data.isParentOkr || actualNode.data.isMyOkr) &&
				actualNode.inEdges &&
				actualNode.inEdges.length > 0
			) {
				iconNode.data.expanded = !iconNode.data.expanded;
				if (iconNode.data.expanded) {
					let conIconNode = this.GetNode(actualNode.id + '_sourceIcon_virtual');
					if (conIconNode && conIconNode.data) {
						conIconNode.data.expanded = false;
						this.ToggleNodeVisibility(actualNode, true, false, true, true);
					}
				}
				this.OnExpandOrCollapse(actualNode, true, iconNode.data.expanded, true, false);
				this.RefreshLayout(actualNode, false);

				return;
			}
			if (type === 'virtualcontributor' && actualNode.data.isMyOkr) {
				iconNode.data.expanded = !iconNode.data.expanded;
				if (iconNode.data.expanded) {
					let conIconNode = this.GetNode(actualNode.id + '_contributorIcon');
					if (conIconNode && conIconNode.data) {
						conIconNode.data.expanded = false;
						this.ToggleNodeVisibility(actualNode, true, false, false, false);
					}
				}
				this.OnExpandOrCollapse(actualNode, true, iconNode.data.expanded, false, true);
				this.RefreshLayout(actualNode, true);
				return;
			}
			if (type === 'virtualparent' && actualNode.data.isMyOkr) {
				iconNode.data.expanded = !iconNode.data.expanded;
				if (iconNode.data.expanded) {
					let conIconNode = this.GetNode(actualNode.id + '_sourceIcon');
					if (conIconNode && conIconNode.data) {
						conIconNode.data.expanded = false;
						this.ToggleNodeVisibility(actualNode, true, false, true, false);
					}
				}
				this.OnExpandOrCollapse(actualNode, true, iconNode.data.expanded, true, true);
				this.RefreshLayout(actualNode, false);
				return;
			}
		}
	}

	OnExpandOrCollapse(
		node: any,
		isStartNode: boolean,
		toggleVisibility: boolean,
		isParent: boolean,
		isVirtual: boolean
	) {
		if (toggleVisibility) {
			this.ToggleVisibilityTrue(node, isStartNode, isParent, isVirtual);
		} else {
			this.ToggleNodeVisibility(node, isStartNode, toggleVisibility, isParent, isVirtual);
		}
	}

	RemoveVirualLink() {
		let nodeList = this.diagramLayoutHelper.GetLayoutNodes(this.diagramIns);
		let ins = this;
		let myOkrNodes = nodeList.filter((j: any) => {
			if (j.data && ins.IsVisible(j) && j.data.isMyOkr) return true;
		});
		for (let i = 0; i < myOkrNodes.length; i++) {
			let iconNode;
			if (myOkrNodes[i] && myOkrNodes[i].data && myOkrNodes[i].data.isVirtualLink) {
				iconNode = this.GetNode(myOkrNodes[i].id + '_contributorIcon_virtual');
				if (iconNode.data.expanded) this.ToggleNodeVisibility(myOkrNodes[i], true, false, false, true);
			} else if (myOkrNodes[i] && myOkrNodes[i].data && myOkrNodes[i].data.isParentVirtualLink) {
				iconNode = this.GetNode(myOkrNodes[i].id + '_sourceIcon_virtual');
				if (iconNode.data.expanded) this.ToggleNodeVisibility(myOkrNodes[i], true, false, true, true);
			}
			if (iconNode && iconNode.data) {
				iconNode.visible = false;
				iconNode.data.expanded = false;
			}
		}
	}

	ToggleVisibilityTrue(node: any, isStartNode: boolean, isParent: boolean, isVirtual: boolean) {
		let edgePropName = isParent ? 'inEdges' : 'outEdges';
		let edges = node[edgePropName];
		for (let i = 0; edges && i < edges.length; i++) {
			var cId = edges[i];
			let edgeNodePropName = isParent ? 'sourceID' : 'targetID';
			let virtualPropName = isParent ? 'isParentVirtualLink' : 'isVirtualLink';
			let con = this.diagramIns.connectors.find((c: any) => c.id === cId);
			let connectedNode = this.diagramIns.nodes.find((n: any) => n.id === con[edgeNodePropName]);
			if (isVirtual && connectedNode.data[virtualPropName]) {
				DiagramHelperUtil.SetVisibility(connectedNode, true);
				DiagramHelperUtil.connectorCollections[connectedNode.id] = con;
				con.style = { strokeColor: '#5B708B', strokeWidth: 3, strokeDashArray: '10,10' };
				con.sourceDecorator.shape = 'None';
				con.targetDecorator.shape = 'None';
			} else if (!isVirtual && !connectedNode.data[virtualPropName]) {
				DiagramHelperUtil.SetVisibility(connectedNode, true);
				DiagramHelperUtil.connectorCollections[connectedNode.id] = con;
			}
		}
		DiagramHelperUtil.RefreshDiagram(this.diagramIns);
	}

	SwapVirtualAndContributor() {}

	SetChildsVisibility(node: any, visibility: boolean, isClearExpandProp: boolean = false) {
		//if (node.data) {
		let childs = ['_contributorIcon', '_sourceIcon', '_contributorIcon_virtual', '_sourceIcon_virtual'];
		for (let i = 0; i < childs.length; i++) {
			let n1 = this.diagramIns.nodes.find((n: any) => n.id === node.id + childs[i]);
			if (n1) {
				this.SetElementVisibility(n1, visibility);
				DiagramHelperUtil.RefreshUI(n1);
				if (isClearExpandProp || (!visibility && n1.data && n1.data.expanded)) {
					n1.data.expanded = false;
				}
			}
		}
	}
	SetElementVisibility(n: any, visibility: boolean) {
		DiagramHelperUtil.SetVisibility(n, visibility);
	}
	SetNodeVisibility(node: any, visibility: boolean) {
		DiagramHelperUtil.SetVisibility(node, visibility);
		this.SetChildsVisibility(node, visibility);

		DiagramHelperUtil.RefreshUI(node);
	}
	ToggleNodeVisibility(node: any, isStartNode: boolean, visibility: boolean, isParent: boolean, isVirtual: boolean) {
		// let skipEdges = false;
		// if (!isStartNode && !node.data.isExpanded) {
		// 	skipEdges = true;
		// }
		// if (!skipEdges) {
		if (isStartNode || !visibility) {
			let edgePropName = isParent ? 'inEdges' : 'outEdges';
			let edges = node[edgePropName];
			for (let i = 0; edges && i < edges.length; i++) {
				this.ToggleEdges(edges[i], visibility, isParent, isVirtual);
			}
		}
		// }
		if (!isStartNode) {
			if (isVirtual) {
				var VIconNode = DiagramHelperUtil.GetNode(node.id + '_sourceIcon_virtual', this.diagramIns);
				if (VIconNode && VIconNode.data) VIconNode.data.expanded = false;
			} else {
				var IconNode = DiagramHelperUtil.GetNode(node.id + '_sourceIcon', this.diagramIns);
				if (IconNode && IconNode.data) IconNode.data.expanded = false;
			}
			this.SetNodeVisibility(node, visibility);
		}
	}
	IsSiblingExpanded(node: any, con: any, isParent: boolean, isVirtual: boolean) {
		if (isParent) {
			let edgePropName = isParent ? 'outEdges' : 'inEdges';
			let edgeLinePropName = isParent ? 'targetID' : 'sourceID';
			let iconNode;
			let icSuffix = isVirtual ? '_sourceIcon_virtual' : '_sourceIcon';
			for (let c = 0; node[edgePropName] && c < node[edgePropName].length; c++) {
				if (node[edgePropName][c] !== con.id) {
					let line = this.GetConnector(node[edgePropName][c]);
					iconNode = this.GetNode(line[edgeLinePropName] + icSuffix);
					if (iconNode && iconNode.data && iconNode.data.expanded) {
						return true;
					}
				}
			}
		}
		return false;
	}
	ToggleEdges(cId: string, visibility: boolean, isParent: boolean, isVirtual: boolean) {
		let edgeNodePropName = isParent ? 'sourceID' : 'targetID';
		let virtualPropName = isParent ? 'isParentVirtualLink' : 'isVirtualLink';
		let con = this.diagramIns.connectors.find((c: any) => c.id === cId);
		let connectedNode = this.diagramIns.nodes.find((n: any) => n.id === con[edgeNodePropName]);

		if (!isVirtual && !connectedNode.data[virtualPropName]) {
			let canToggle = visibility || !this.IsSiblingExpanded(connectedNode, con, isParent, isVirtual);
			if (canToggle) {
				this.ToggleNodeVisibility(connectedNode, false, visibility, isParent, isVirtual);
			}
			this.SetConVisibility(con, visibility);
		} else if (
			(isVirtual && !connectedNode.data[virtualPropName]) ||
			(!isVirtual && connectedNode.data[virtualPropName])
		) {
			let canToggle = visibility || !this.IsSiblingExpanded(connectedNode, con, isParent, isVirtual);
			if (canToggle) {
				this.ToggleNodeVisibility(connectedNode, false, visibility, isParent, isVirtual);
			}
			this.SetConVisibility(con, visibility);
		} else if (isVirtual && connectedNode.data[virtualPropName]) {
			this.SetNodeVisibility(connectedNode, visibility);
			this.SetConVisibility(con, visibility);
		}
	}
	SetConVisibility(con: any, visibility: boolean) {
		this.SetElementVisibility(con, visibility);
		DiagramHelperUtil.RefreshUI(con);
	}
	HideExceptMyOkrs(diagramInstance: any) {
		this.SetHelpers(diagramInstance);
		let ins = this;
		diagramInstance.nodes.forEach((n: any) => {
			if (n.data && !DiagramHelperUtil.IsHelperNode(n) && n.data.jsonNode) {
				let visible = n.data.isMyOkr;
				DiagramHelperUtil.SetVisibility(n, visible);
				ins.SetChildsVisibility(n, visible, true);
			}
		});

		diagramInstance.connectors.forEach((c: any) => {
			if (c.id.indexOf('_lbl_link_helper') < 0) {
				ins.SetElementVisibility(c, false);
			}
		});
	}

	GetRootKrNode(krItemNode: any) {
		let parentNode = krItemNode;
		let isParentKrFound = true;
		while (isParentKrFound) {
			let krData = this.GetKrData(parentNode);
			let parentKrNode;
			if (krData.objectiveType === 2) {
				let krParentId = krData[this.bindProps.staKrParent];
				let tempParentKrNode = this.GetKrNodeById(staIdPrefix + ':' + krParentId);
				if (tempParentKrNode !== null && tempParentKrNode !== undefined) {
					krParentId = staIdPrefix + ':' + krParentId;
				}
				parentKrNode = this.GetKrNodeById(krParentId);
			} else {
				parentKrNode = this.GetKrNodeById(krData[this.bindProps.krParent]);
			}
			if (parentKrNode && parentKrNode.data) {
				let parentOkrNode = this.GetNode(parentKrNode.data.diagramParentNodeId);
				if (this.IsVisible(parentOkrNode)) parentNode = parentKrNode;
				else isParentKrFound = false;
			} else {
				isParentKrFound = false;
			}
		}
		return parentNode;
	}
	IsNullOrUndefined(obj: any) {
		return obj === null || obj === undefined;
	}
	IsSelectionHelper(obj: any) {
		return obj && obj.id.indexOf('_link_helper') >= 0;
	}
	GetOrCreateHelperLink(srcHelperNode: any, targetHelperNode: any) {
		let linkId = srcHelperNode.id + '_' + targetHelperNode.id + '_link_helper';
		let link = this.GetConnector(linkId);
		if (!link || !link.id) {
			link = {
				sourceID: srcHelperNode.id,
				targetID: targetHelperNode.id,
				sourcePortID: srcHelperNode.id + '-right-mid-port',
				targetPortID: targetHelperNode.id + '-left-mid-port',
				id: srcHelperNode.id + '_' + targetHelperNode.id + '_link_helper',
				sourceDecorator: { shape: 'None' },
				targetDecorator: { shape: 'None' },
				type: 'Bezier',
			};
			link.style = {
				strokeColor: connectorSelectedStyle.strokeColor,
				strokeWidth: connectorSelectedStyle.strokeWidth,
			};
			this.diagramIns.add(link);
			this.allKrHelperLink.push(link);
		}
		return link;
	}
	CanHighlight(krNode: any) {
		if (krNode && krNode.data && krNode.data.diagramParentNodeId) {
			let parentNodeId = krNode.data.diagramParentNodeId;
			let parentNode = this.GetNode(parentNodeId);
			return this.IsVisible(parentNode);
		}
		return false;
	}
	IsVisible(obj: any) {
		if (obj) {
			if (obj.visible) return true;
			if (obj.data && obj.data.visible) return true;
		}
		return false;
	}
	SetNodeProperty(node: any, name: string, value: any) {
		let curNode = this.diagramIns.nodes.find((n: any) => n.id === node.id);
		if (curNode) {
			curNode[name] = value;
		}
		node[name] = value;
	}
	GetKrId(krData: any) {
		if (krData.objectiveType === 2) {
			let krId = krData[this.bindProps.krId];
			if (krId.indexOf(staIdPrefix + ':') < 0) {
				krId = staIdPrefix + ':' + krId;
			}
			return krId;
		}
		return krData[this.bindProps.krId];
	}
	GetKrNodeById(krId: string) {
		krId = this.GetActualKrNodeId(krId);
		if (krId) return this.GetNode(krId);
		return null;
	}
	GetActualKrNodeId(krId: string) {
		if (krId) {
			krId = krId.toString();
			if (krId.indexOf(staIdPrefix + ':') >= 0) {
				return staIdPrefix + '-' + krId.substring(4, krId.length);
			}
			return krResIdPrefix + '_' + krId;
		}
		return '';
	}
	GetActualKrElement(krId: string): any {
		let elementId = '';
		if (krId) {
			krId = krId.toString();
			if (krId.indexOf(staIdPrefix + ':') >= 0) {
				let eleId = staIdPrefix + '-' + krId.substring(4, krId.length) + '_content_html_element';
				return document.querySelector('#' + eleId + ' .orphan-card')!;
			} else {
				return document.getElementById((elementId = 'kr_' + krId))!;
			}
		}
	}
	CreateConnections(krNode: any, e: any) {
		let krData = this.GetKrData(krNode);
		let srcKrId = this.GetKrId(krData);
		let targetElementIds = krData.childOkrs;
		let srcKrNode = this.GetKrNodeById(srcKrId);
		let srcHelperNode = null;
		if (this.CanHighlight(srcKrNode)) {
			srcKrId = 'src';
			for (let i = 0; i < targetElementIds.length; i++) {
				let targetKrNode = this.GetKrNodeById(targetElementIds[i]);
				if (this.CanHighlight(targetKrNode)) {
					if (srcHelperNode === null)
						srcHelperNode = this.GetOrCreateHelperNode(krResIdPrefix + '_' + srcKrNode.id, 'source', srcKrNode, krData);
					let targetKrData = this.GetKrData(targetKrNode);
					if (targetKrData && targetKrData.childOkrs) {
						this.SelectKrResult(targetKrNode, e, targetKrNode);
					}

					let targetHelperNode = this.GetOrCreateHelperNode(
						krResIdPrefix + '_' + targetKrNode.id + '_' + [i],
						'target',
						targetKrNode,
						targetKrData
					);
					this.SetNodeProperty(srcHelperNode, 'visible', true);
					this.SetNodeProperty(targetHelperNode, 'visible', true);

					let link = this.GetOrCreateHelperLink(srcHelperNode, targetHelperNode);
					DiagramHelperUtil.SetVisibility(link, true);
				}
			}
		}
	}
	GetKrSelectionText(krData: any, type: string) {
		let krText: string = '';
		if (type === 'source') {
			krText = krData.inValue?.toString();
			if (krData.metricId === 4) {
				krText = krData.inValue > 0 ? 'True' : 'False';
			} else if (krData.metricId === 2) {
				let currencySymbol = krData.currencyInValue.slice(0, 1);
				krText = currencySymbol + abbreviateNumber(krData.currencyInValue.substring(1));
			} else if (krData.metricId === 3 || krData.metricId === 1) {
				krText = abbreviateNumber(krText);
			}
		} else if (type === 'target') {
			krText = krData.outValue?.toString();
			if (krData.metricId === 4) {
				krText = krData.outValue > 0 ? 'True' : 'False';
			} else if (krData.metricId === 2) {
				let currencySymbol = krData.currencyOutValue.slice(0, 1);
				krText = currencySymbol + abbreviateNumber(krData.currencyOutValue.substring(1));
			} else if (krData.metricId === 3 || krData.metricId === 1) {
				krText = abbreviateNumber(krText);
			}
		}
		return krText;
	}
	CreateSourceHelperNode(nodeId: string, krNode: any, krData: any) {
		let parentNodeId = krNode.data.diagramParentNodeId;
		//let parentNode = this.GetNode(parentNodeId);
		let krInText: string = this.GetKrSelectionText(krData, 'source');
		let helperNode: any = {
			id: nodeId,
			width: this.helperNodeModel.width,
			height: this.helperNodeModel.height,
			style: {
				strokeColor: nodeSelectedStyle.strokeColor,
				fill: nodeSelectedStyle.strokeColor,
				strokeWidth: 1,
			},
			annotations: [
				{
					constraints: AnnotationConstraints.ReadOnly,
					style: {
						color: 'white',
						opacity: 1,
					},
					content: krInText,
				},
			],
			shape: {
				type: 'HTML',
				content:
					'<div class="source-helper" style="' +
					'background:' +
					nodeSelectedStyle.strokeColor +
					';border:1px solid ' +
					nodeSelectedStyle.strokeColor +
					'">' +
					krInText +
					'</div>',
			},
			// pivot: { x: 0, y: 0 },
			ports: [
				{
					style: {
						strokeColor: '#366F8C',
						fill: '#366F8C',
					},
					shape: 'Square',
					id: nodeId + '-right-mid-port',
					visibility: PortVisibility.Hidden,
					offset: {
						x: 1,
						y: 0.5,
					},
				},
			],
			visible: false,
		};
		helperNode.offsetX = krNode.offsetX + krNode.width / 2 + this.helperNodeModel.width / 2 - this.helperNodeModel.left;
		helperNode.offsetY = krNode.offsetY;
		if (
			DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam ||
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr
		) {
			helperNode.offsetY! += 1;
		}
		if (krData.objectiveType && krData.objectiveType === 2) {
			helperNode.offsetY = helperNode.offsetY - krNode.height / 2 + helperNode.height + helperNode.height / 2 - 1;
			var krsourceid = 'sta-' + krNode.data.krUniqueId + '_contributorIcon';
			let srcNode = this.diagramIns.nodes.find((n: any) => n.id === krsourceid);
			if (srcNode) {
				DiagramHelperUtil.SetVisibility(srcNode, false);
				this.staHiddenIcons.push(krsourceid);
			}
		}
		this.diagramIns.add(helperNode);
		this.allKrHelperNodes.push(helperNode);
		return helperNode;
	}
	getTopPt(node: any) {
		return { x: node.offsetX - node.pivot.x * node.width, y: node.offsetY - node.pivot.y * node.height };
	}
	CreateTargetHelperNode(nodeId: string, krNode: any, krData: any) {
		let krOutText: string = this.GetKrSelectionText(krData, 'target');
		let helperNode: any = {
			id: nodeId,
			width: krData && krData.krStatusId !== 1 ? this.helperNodeModel.width : this.helperNodeModel.width - 60,
			height: this.helperNodeModel.height,
			// pivot: { x: 0, y: 0 },
			visible: false,
			// shape: {
			// 	type: 'Path',
			// 	data:
			// 		krData && krData.krStatusId !== 1 ? 'M 0 5 L 5 0 L 20 0 L 20 10 L 5 10 z' : 'M 0 0 L 0 0 L 0 0 L 0 0 L 5 0 z',
			// },
			shape: {
				type: 'HTML',
				content:
					'<div class="target-helper">' +
					'<div class="text-div">' +
					krOutText +
					'</div>' +
					'<svg width="60" height="22" fill="white" xmlns="http://www.w3.org/2000/svg">' +
					'<g>' +
					'<path d="M0,10 L15,1 L60,1 L60,20 L15,20 Z " visibility="visible" opacity="1" aria-label="0" stroke="rgba(57,158,247,255)" stroke-width="1" stroke-dasharray="none" fill="white"></path>' +
					'</g>' +
					'</svg> ' +
					'</div> ',
			},
			style: {
				strokeColor: nodeSelectedStyle.strokeColor,
				fill: 'white',
				strokeWidth: 1,
			},
			annotations: [
				{
					constraints: AnnotationConstraints.ReadOnly,
					style: {
						color: nodeSelectedStyle.strokeColor,
						textAlign: 'Center',
					},
					//horizontalAlignment: 'Center',
					content: krData && krData.krStatusId !== 1 ? krOutText : '',
					offset: {
						x: 0.5,
						y: 0.525,
					},
					margin: {
						left: 5,
					},
				},
			],
			ports: [
				{
					style: {
						strokeColor: '#366F8C',
						fill: '#366F8C',
					},
					shape: 'Square',
					id: nodeId + '-left-mid-port',
					visibility: PortVisibility.Hidden,
					offset: {
						x: 0,
						y: 0.5,
					},
				},
			],
		};
		let helperNodeDiv = krData && krData.krStatusId !== 1 ? 2 : 6;
		helperNode.offsetX = krNode.offsetX - krNode.width / 2 - this.helperNodeModel.width / helperNodeDiv;
		helperNode.offsetY = krNode.offsetY;
		// helperNode.offsetX += 1;
		if (
			DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.CompactViewTeam ||
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
		) {
			helperNode.offsetY += 1;
		}
		if (krData.objectiveType && krData.objectiveType === 2) {
			helperNode.offsetY = helperNode.offsetY - krNode.height / 2 + helperNode.height + helperNode.height / 2 - 1;
			var krcontributorid = 'sta-' + krNode.data.krUniqueId + '_sourceIcon';
			let srcNode = this.diagramIns.nodes.find((n: any) => n.id === krcontributorid);
			if (srcNode) {
				DiagramHelperUtil.SetVisibility(srcNode, false);
				this.staHiddenIcons.push(krcontributorid);
			}
		}
		this.diagramIns.add(helperNode);
		this.allKrHelperNodes.push(helperNode);
		return helperNode;
	}
	GetOrCreateHelperNode(krId: string, type: string, krNode: any, krData: any) {
		let nodeId = type === 'source' ? krId + '_srcNode_link_helper' : krId + '_targetNode_link_helper';
		let helperNode = this.diagramIns.getNodeObject(nodeId);
		if (!helperNode || !helperNode.id) {
			if (type === 'source') {
				helperNode = this.CreateSourceHelperNode(nodeId, krNode, krData);
			} else {
				helperNode = this.CreateTargetHelperNode(nodeId, krNode, krData);
			}
		}
		DiagramHelperUtil.SetVisibility(helperNode, false);
		return helperNode;
	}
	RemoveAllSelection() {
		this.RemoveClass(['.kr-item', '.orphan-card'], 'selected');
		this.RevertStaCardIconVisibility();
		for (let i = 0; i < this.allKrHelperNodes.length; i++) {
			this.diagramIns.remove(this.allKrHelperNodes[i]);
		}
		for (let i = 0; i < this.allKrHelperLink.length; i++) {
			this.diagramIns.remove(this.allKrHelperLink[i]);
		}
		this.ToggleParentConnectorStyle(parentConnectorDefaultStyle);
	}
	RevertStaCardIconVisibility() {
		for (let i = 0; i < this.staHiddenIcons.length; i++) {
			let iconnode = this.diagramIns.nodes.find((n: any) => n.id === this.staHiddenIcons[i]);
			DiagramHelperUtil.SetVisibility(iconnode, true);
			DiagramHelperUtil.RefreshUI(iconnode);
		}
		this.staHiddenIcons = [];
	}
	ToggleParentConnectorStyle(style: any) {
		let ins = this;
		this.diagramIns.connectors.forEach((c: any) => {
			if (!ins.IsSelectionHelper(c)) {
				c.style.strokeColor = style.strokeColor;
				c.style.opacity = style.opacity;
				if (c.targetDecorator) {
					c.targetDecorator.style.strokeColor = style.decorator.strokeColor;
					c.targetDecorator.style.fill = style.decorator.fill;
					c.targetDecorator.style.opacity = style.decorator.opacity;
				}
				if (c.sourceDecorator) {
					c.sourceDecorator.style.strokeColor = style.decorator.strokeColor;
					c.sourceDecorator.style.fill = style.decorator.fill;
					c.sourceDecorator.style.opacity = style.decorator.opacity;
				}
			}
		});
	}
	GetNode(nodeId: string, diagramInstance?: any) {
		let nodeModel;
		let diaIns = diagramInstance ? diagramInstance : this.diagramIns;
		if (diaIns) {
			nodeModel = diaIns.nodes.find((n: any) => n.id === nodeId);
		}
		return nodeModel;
	}
	GetConnector(connectorId: string) {
		let connModel = this.diagramIns.connectors.find((c: any) => c.id === connectorId);
		return connModel;
	}
	SelectKrResult(krRootNode: any, e: any, krNode: any) {
		if (krRootNode.visible) {
			let krData = this.GetKrData(krRootNode);
			const pendingAction =
				krNode.okrViewKeyResults &&
				krNode.okrViewKeyResults.find((item: any) => {
					if (krData.childOkrs && krData.childOkrs[0]) {
						return item.krStatusId === Enums.KR_STATUS_PENDING && item.krUniqueId === krData.childOkrs[0];
					}
					return false;
				});
			if (e.target.closest('.kr-percentage') || e.target.closest('.kebab-icon') || e.target.closest('.progressChart')) {
			} /* else if (pendingAction) {
			}*/ else {
				this.AddSelectionStyleToKr(krData);
				if (krData && krData.childOkrs) {
					let ins = this;
					krData.childOkrs.forEach((childId: string) => {
						ins.AddClassToElement(childId, 'selected');
					});
					this.CreateConnections(krRootNode, e);
					return true;
				}
			}
		}
	}
	AddSelectionStyleToKr(krData: any) {
		this.AddClassToElement(this.GetKrId(krData), 'selected');
	}
	RemoveClass(selectorList: string[], className: string) {
		for (let i = 0; i < selectorList.length; i++) {
			document.querySelectorAll(selectorList[i] + '.' + className).forEach((element: any) => {
				element.classList.remove(className);
				element.style.border = '1px solid #cde4ef';
				element.style['box-shadow'] = 'none';
			});
		}
	}
	AddClassToElement(krId: string, className: string) {
		let element = this.GetActualKrElement(krId);
		if (element) {
			element.classList.add(className);
			element.style['border'] = '2px solid ' + nodeSelectedStyle.strokeColor;
			element.style['box-shadow'] = '0px 10px 30px rgba(41, 41, 41, 0.2)';
		}
	}
	//#endregion

	//#region - Dynamic Kr Toggling
	ShowDynamicKrResults(actualObject: any, diagramIns: any, diagramOkrHelper: any) {
		let parentNode = actualObject; //this.GetNode(actualObject.id.substring(0, actualObject.id.indexOf('_')), diagramIns);
		if (parentNode && parentNode !== this.dynamicKrInfo.parentNode) {
			this.ClearDynamicKrResults(false);
			this.dynamicKrInfo.parentNode = parentNode;
			this.dynamicKrInfo.krNodes = diagramOkrHelper.CreateKrResultItems(parentNode, diagramIns);
			this.dynamicKrInfo.firstRowHeight = this.diagramOkrHelper.firstRowHeight;
			this.dynamicKrInfo.parentHeight = parentNode.height;
			this.diagramOkrHelper.firstRowHeight += 69;
			let krFullHeight = this.diagramOkrHelper.UpdateKrResultNodeOffsets(actualObject, diagramIns);
			this.dynamicKrInfo.parentOffsetY = parentNode.offsetY;
			krFullHeight += 89;
			parentNode.height += krFullHeight;
			diagramOkrHelper.UpdatePorts(parentNode);
			this.SetAsRelaxedCard(parentNode, true);
			DiagramHelperUtil.RefreshDiagram(diagramIns);
			this.diagramLayoutHelper.DoCustomOkrLayout(diagramIns, diagramOkrHelper, true);
		}
	}
	SetAsRelaxedCard(node: any, value: boolean) {
		if (node && node.reactProps) {
			let prop = node.reactProps['setAsRelaxedCard'];
			if (prop && prop[0]) {
				prop[0](value);
			}
		}
	}
	ClearDynamicKrResults(isLayout: boolean = true) {
		if (this.dynamicKrInfo.parentNode) {
			let parentNode = this.dynamicKrInfo.parentNode;
			parentNode.height = this.dynamicKrInfo.parentHeight;
			parentNode.offsetY = this.dynamicKrInfo.parentOffsetY;
			let dynamicKrNodes = this.dynamicKrInfo.krNodes;
			if (dynamicKrNodes && dynamicKrNodes.length > 0) {
				//parentNode.reactProps['setKrData'] = null;
				for (let i = 0; i < dynamicKrNodes.length; i++) {
					this.diagramIns.remove(dynamicKrNodes[i]);
				}
			}
			this.diagramOkrHelper.UpdatePorts(parentNode);
			this.SetAsRelaxedCard(parentNode, false);
			DiagramHelperUtil.RefreshUI(parentNode);
			if (isLayout) {
				this.diagramLayoutHelper.DoCustomOkrLayout(this.diagramIns, this.diagramOkrHelper, true);
			}
			this.diagramOkrHelper.firstRowHeight = this.dynamicKrInfo.firstRowHeight;
			this.dynamicKrInfo.parentNode = null;
		}
	}

	//#endregion
}

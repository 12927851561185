import * as React from 'react';
import {
	// LayoutAnimation,
	DataBinding,
	DiagramComponent,
	SnapConstraints,
	NodeConstraints,
	Inject,
	DiagramTools,
	ComplexHierarchicalTree,
	ChildArrangement,
	LineDistribution,
	ConnectionPointOrigin,
	ConnectorConstraints,
} from '@syncfusion/ej2-react-diagrams';
import { Box, IconButton, Typography, Tooltip } from '@material-ui/core';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { IndividualSmallIcon, VirtualLinkContributorIcon, LinkIcon } from '../../../config/svg/CommonSvg';
import { SampleBase } from './sample-base';
import { DataManager } from '@syncfusion/ej2-data';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
// import { getLocalStorageItem } from '../../../services/StorageService';
import ObjectiveCard from '../AlignmentListing/ObjectiveCard';
import KrResultItemCard from '../AlignmentListing/KrResultItemCard';
import StandaloneCard from '../AlignmentListing/StandaloneCard';
import { DiagramLayoutHelper } from '../DiagramHelper/diagram-layout-helper';
import { DiagramSelectionHelper } from '../DiagramHelper/diagram-selection-helper';
import { DiagramOkrHelper } from '../DiagramHelper/diagram-okr-helper';
import { DiagramJsonHelper, TemplateType } from '../DiagramHelper/diagram-json-helper';
import okrJsonData from '../DiagramHelper/okr_data';
import DiagramOverview from '../DiagramHelper/diagram-overview-component';
import { DiagramHelperUtil, MapTypes } from '../DiagramHelper/diagram-helper-util';
import { getUserDetails } from '../../../config/utils';

let diagramInstance;
let template;
let diagramTimer;
let diagramLayoutHelper;
let diagramSelectionHelper;
let diagramOkrHelper;
let alignmentData = [];
let ref_overview;
let diagramRendered = false;
let scaleValue;
let userDetail;
let setStateList = [];
let overviewTimer;
export default class RelaxedMap extends SampleBase {
	constructor(props) {
		super(props);
		diagramRendered = false;
		this.state = {
			overviewProps: { diagramId: 'diagram' },
		};
		userDetail = getUserDetails();
		template = this.diagramTemplate.bind(this);
		DiagramHelperUtil.MapType = MapTypes.Relax;
		diagramLayoutHelper = new DiagramLayoutHelper();
		diagramSelectionHelper = new DiagramSelectionHelper();
		diagramOkrHelper = new DiagramOkrHelper(userDetail);
		ref_overview = React.createRef();
	}

	updateNodeData(nextProps) {
		// let nodes = diagramLayoutHelper.GetJsonNodes(diagramInstance);
		let okrObj = {};
		let krObj = {};
		nextProps.alignmentMapAction.value.forEach((item) => {
			if (item.okrId) {
				okrObj[item.okrId] = item;
			}
			if (item.krId) {
				krObj[item.krId] = item;
			}
		});
		//console.log('objs', okrObj, krObj);
		let nodeToUpdate = nextProps.alignmentMapAction.value
			.map((item) =>
				alignmentData.find(
					(node) => (item.okrId && node.objectiveId === item.okrId) || (!item.okrId && node.objectiveId === item.krId)
				)
			)
			.filter((i) => i)
			.map((j) => ({
				...j,
				score:
					j.objectiveType === 1
						? okrObj[j.objectiveId]
							? okrObj[j.objectiveId].okrScore
							: j.score
						: krObj[j.objectiveId]
						? krObj[j.objectiveId].krScore
						: j.score,
				krCurrentValue: krObj[j.objectiveId] ? krObj[j.objectiveId].krCurrentValue : j.krCurrentValue,
				inValue: krObj[j.objectiveId] ? krObj[j.objectiveId].inValue : j.inValue,
				outValue: krObj[j.objectiveId] ? krObj[j.objectiveId].outValue : j.outValue,
				currencyInValue: krObj[j.objectiveId] ? krObj[j.objectiveId].currencyInValue : j.currencyInValue,
				currencyOutValue: krObj[j.objectiveId] ? krObj[j.objectiveId].currencyOutValue : j.currencyOutValue,

				okrViewKeyResults: j.okrViewKeyResults.map((kr) => ({
					...kr,
					krCurrentValue: krObj[kr.krId] ? krObj[kr.krId].krCurrentValue : kr.krCurrentValue,
					krScore: krObj[kr.krId] ? krObj[kr.krId].krScore : kr.krScore,
					inValue: krObj[kr.krId] ? krObj[kr.krId].inValue : kr.inValue,
					outValue: krObj[kr.krId] ? krObj[kr.krId].outValue : kr.outValue,
					currencyInValue: krObj[kr.krId] ? krObj[kr.krId].currencyInValue : kr.currencyInValue,
					currencyOutValue: krObj[kr.krId] ? krObj[kr.krId].currencyOutValue : kr.currencyOutValue,
					okrViewKeyContributors: kr.okrViewKeyContributors.map((item) => {
						if (item.employeeId === this.props.userDetail.employeeId) {
							item.contributorsContribution = krObj[kr.krId] ? krObj[kr.krId].krScore : item.contributorsContribution;
							item.updatedOn = krObj[kr.krId] ? krObj[kr.krId].updatedOn : item.updatedOn;
							item.currentValue = krObj[kr.krId] ? krObj[kr.krId].currentValue : item.currentValue;
							item.metricId = krObj[kr.krId] ? krObj[kr.krId].metricId : item.metricId;
							item.currencyId = krObj[kr.krId] ? krObj[kr.krId].currencyId : item.currencyId;
						}
						return item;
					}),
				})),
			}));

		// console.log('nodeToUpdate', nodeToUpdate);

		alignmentData.forEach((item) => {
			nodeToUpdate.forEach((i) => {
				if (i.objectiveId === item.objectiveId) {
					item.score = i.score;
					item.krCurrentValue = i.krCurrentValue;
					item.inValue = i.inValue;
					item.outValue = i.outValue;
					item.currencyInValue = i.currencyInValue;
					item.currencyOutValue = i.currencyOutValue;
					item.okrViewKeyResults = [...i.okrViewKeyResults];
				}
			});
		});

		// console.log('updated .........', alignmentData);

		nodeToUpdate.forEach((item) => {
			// console.log('...update happens here...', item);
			setTimeout(() => {
				this.refreshNodeData(item);
			}, 0);
		});

		// console.log('nodeToUpdate', nodeToUpdate);
	}

	checkUnreadFeedback(data) {
		const allUnread = data.filter(
			(item) => item.isMyOkr && (item.isUnreadFeedback || item.okrViewKeyResults.some((i) => i.isUnreadFeedback))
		);
		const teamUnread = allUnread && allUnread.length && allUnread.filter((item) => item.teamId);

		return {
			allUnread: allUnread.length,
			teamUnread: teamUnread.length,
		};
	}

	viewFeedbackUpdate(nextProps) {
		if (nextProps.alignmentMapAction.value && nextProps.alignmentMapAction.value.length) {
			let objectiveId = nextProps.alignmentMapAction.value[0].objectiveId;
			let krId = nextProps.alignmentMapAction.value[0].krId;
			alignmentData.forEach((item) => {
				if (item.objectiveId === objectiveId) {
					if (krId && krId !== objectiveId) {
						item.okrViewKeyResults.forEach((i) => {
							if (i.krId === krId) {
								i.isUnreadFeedback = false;
							}
							setTimeout(() => {
								this.refreshNodeData(item);
							}, 0);
						});
					} else {
						item.isUnreadFeedback = false;
						setTimeout(() => {
							this.refreshNodeData(item);
						}, 0);
					}
				}
			});
			this.props.setIsUnreadFeedback(this.checkUnreadFeedback(alignmentData));
		}
	}
	updateBeforeSwitchMap(nextProps) {
		if (this.props.tabFilter === 'relaxed') {
			diagramSelectionHelper.SetHelpers(diagramInstance, diagramLayoutHelper, diagramOkrHelper);
			diagramSelectionHelper.RemoveAllSelection();
			diagramSelectionHelper.RemoveVirualLink();
		}
	}
	switchMap(nextProps, nextState) {
		if (nextProps.tabFilter !== this.props.tabFilter) {
			this.setMapType(nextProps.tabFilter);
			this.updateBeforeSwitchMap(nextProps);
			this.executeNodeState(nextProps);
			let isUpdateTopLabels = false;
			if (this.props.tabFilter === 'ultracompact' || nextProps.tabFilter === 'ultracompact') {
				isUpdateTopLabels = true;
			}
			DiagramHelperUtil.TimerStart('DoCustomOkrLayout Start');
			diagramLayoutHelper.DoCustomOkrLayout(diagramInstance, diagramOkrHelper, false, isUpdateTopLabels);
			DiagramHelperUtil.TimerEnd('DoCustomOkrLayout End');
			this.updateOverview();
			return false;
		}
	}
	executeNodeState(nextProps) {
		DiagramHelperUtil.TimerStart('new bind');
		let nodes = diagramLayoutHelper.GetJsonNodes(diagramInstance);
		for (let i = 0; i < nodes.length; i++) {
			let n = nodes[i];
			updateSizeOnTabSwitch(n);
			let nodeSetState = setStateList.pop();
			if (nodeSetState) {
				nodeSetState(nextProps.tabFilter);
			}
			if (n && n.reactProps && n.reactProps['setAsRelaxedCard']) {
				let prop = n.reactProps['setAsRelaxedCard'];
				if (prop && prop[0]) {
					prop[0](false);
				}
			}
		}
		diagramSelectionHelper.dynamicKrInfo.parentNode = null;
		while (setStateList && setStateList.length > 0) {
			let nodeSetState = setStateList.pop();
			if (nodeSetState) {
				nodeSetState(nextProps.tabFilter);
			}
		}
		DiagramHelperUtil.TimerEnd('new bind');
	}
	shouldComponentUpdate(nextProps, nextState) {
		this.switchMap(nextProps, nextState);
		// console.log('update', nextProps.alignmentMapAction, this.props.alignmentMapAction);
		if (
			diagramInstance &&
			diagramInstance.nodes &&
			nextProps &&
			nextProps.alignmentMapAction &&
			nextProps.alignmentMapAction.value &&
			nextProps.alignmentMapAction.value.length &&
			// this.props.alignmentMapAction.value &&
			// this.props.alignmentMapAction.value.length &&
			nextProps.alignmentMapAction.status &&
			nextProps.alignmentMapAction.status === 'PENDING'
		) {
			let alignmentMapAction = {
				...nextProps.alignmentMapAction,
				status: 'PROCESSING',
			};
			this.props.setAlignmentMapAction(alignmentMapAction);

			// update node and diagram
			if (nextProps.alignmentMapAction.actionType === 'UPDATE_PROGRESS') {
				this.updateNodeData(nextProps);
			} else if (nextProps.alignmentMapAction.actionType === 'VIEW_FEEDBACK') {
				this.viewFeedbackUpdate(nextProps);
			}

			alignmentMapAction = {
				...nextProps.alignmentMapAction,
				status: 'COMPLETED',
			};
			this.props.setAlignmentMapAction(alignmentMapAction);
		}
		if(nextProps.okrMasterData !== this.props.okrMasterData){
            diagramInstance.refresh();
			diagramTimer = setTimeout(() => {
				this.Reset(); 
			}, 100);
			return true;
		}
		return false;
	}
	onItemClick(args) {
		switch (args.item.text) {
			case 'Zoom In':
				let zoomin = { type: 'ZoomIn', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomin);
				this.updateOverview();
				//console.log('zoom', diagramInstance.scrollSettings.currentZoom);
				//this.setScaleValuePopper(diagramInstance);
				scaleValue = diagramInstance.scrollSettings.currentZoom;
				break;
			case 'Zoom Out':
				let zoomout = { type: 'ZoomOut', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomout);
				this.updateOverview();
				scaleValue = diagramInstance.scrollSettings.currentZoom;
				break;
			case 'Reset':
				this.Reset();
				break;
			case 'ToggleExpand':
				expandAll();
				break;
			case 'Map':
				this.ToggleOverview();
				break;
			default:
				break;
		}
	}
	Reset() {
		diagramInstance.reset();
		diagramSelectionHelper.RemoveAllSelection();
		this.layoutDiagram();
	}
	ToggleOverview() {
		let element = document.querySelectorAll("[aria-label^='Map']");
		let x = document.getElementById('overview-panel');
		if (x.style.visibility === 'hidden') {
			x.style.visibility = 'visible';
			x.style.opacity = 1;
			element.forEach(function (tile) {
				tile.classList.add('active');
			});
			this.updateOverview();
		} else {
			x.style.visibility = 'hidden';
			x.style.opacity = 0;
			element.forEach(function (tile) {
				tile.classList.remove('active');
			});
		}
	}

	componentWillUnmount() {
		let diagramContainer = document.getElementById('diagram-container');
		if (diagramContainer) {
			//diagramContainer.removeEventListener("mousemove", this.handleDiagramAreaMouseMove);
			diagramContainer.removeEventListener('click', this.handleDiagramAreaMouseClick);
		}
	}
	componentDidMount() {
		let diagramContainer = document.getElementById('diagram-container');
		if (diagramContainer) {
			//diagramContainer.addEventListener('mousemove', this.handleDiagramAreaMouseMove);
			diagramContainer.addEventListener('click', this.handleDiagramAreaMouseClick.bind(this));
		}
	}
	handleDiagramAreaMouseClick(e) {
		diagramSelectionHelper.OnDiagramMouseClick(e, diagramInstance, diagramLayoutHelper, diagramOkrHelper);
	}
	handleDiagramAreaMouseMove(e) {
		diagramSelectionHelper.OnDiagramMouseMove(e, diagramInstance);
	}
	refreshNodeData(data) {
		let objectiveUniqueId = data.objectiveUniqueId;
		if (diagramInstance && objectiveUniqueId) {
			let templateNodes = diagramInstance.nodes.filter((n) => n.reactProps);
			let nodeToUpdate = templateNodes.find((n) => n.data && n.data.objectiveUniqueId === objectiveUniqueId);
			this.refreshNode(nodeToUpdate, data);
		}
	}
	refreshNode(nodeToUpdate, data) {
		if (nodeToUpdate) {
			nodeToUpdate.data = data;
			let reactProps = nodeToUpdate.reactProps;
			reactProps.setOkrData.forEach((f) => {
				// console.log('react props');
				f({ data: {} });
				f(nodeToUpdate);
			});
			// if (reactProps.setKrData) {
			// 	reactProps.setKrData.forEach((f) => {
			// 		f({ data: {} });
			// 		f(nodeToUpdate);
			// 	});
			// }
			this.refreshKrHelpers(nodeToUpdate.data);
		}
	}
	refreshKrHelpers(data) {
		if (data && diagramSelectionHelper && diagramInstance) {
			let krItems = [];
			if (data.objectiveType === 2) {
				krItems = [data];
			} else {
				krItems = data.okrViewKeyResults && data.okrViewKeyResults.length > 0 ? data.okrViewKeyResults : [];
			}
			let helperNode;
			let krNodeId = 'krResItem_';
			for (let i = 0; i < krItems.length; i++) {
				if (krItems[i].objectiveType === 2) {
					krNodeId += 'sta:';
				}
				krNodeId += krItems[i].krUniqueId;
				helperNode = diagramInstance.nodes.find((n) => n.id === krNodeId + '_srcNode_link_helper');
				if (helperNode && helperNode.annotations && helperNode.annotations.length > 0) {
					helperNode.annotations[0].content = diagramSelectionHelper.GetKrSelectionText(krItems[i], 'source');
					DiagramHelperUtil.RefreshUI(helperNode);
					diagramSelectionHelper.AddSelectionStyleToKr(krItems[i]);
				}
				helperNode = diagramInstance.nodes.find((n) => n.id === krNodeId + '_targetNode_link_helper');
				if (helperNode && helperNode.annotations && helperNode.annotations.length > 0) {
					helperNode.annotations[0].content = diagramSelectionHelper.GetKrSelectionText(krItems[i], 'target');
					DiagramHelperUtil.RefreshUI(helperNode);
					diagramSelectionHelper.AddSelectionStyleToKr(krItems[i]);
				}
			}
		}
	}
	setReactProps(nodeId, propName, propValue) {
		if (diagramInstance && !diagramRendered) {
			let actualNode = diagramInstance.nodes.find((n) => n.id === nodeId);
			if (actualNode) {
				actualNode['reactProps'] = actualNode['reactProps'] ? actualNode['reactProps'] : [];
				actualNode['reactProps'][propName] = actualNode['reactProps'][propName]
					? actualNode['reactProps'][propName]
					: [];
				actualNode['reactProps'][propName].push(propValue);

				if (!actualNode['refreshMe']) {
					actualNode['refreshMe'] = this.refreshNodeData.bind(this);
				}
			}
		}
	}
	diagramTemplate(node) {
		if (node.data) {
			if (node.data.objectiveType === 2) {
				const [tabFilter, setTabFiter] = React.useState(this.props.tabFilter);
				setStateList.push(setTabFiter);
				const [nodeObj, setOkrData] = React.useState(node);
				this.setReactProps(node.data.diagramParentNodeId, 'setOkrData', setOkrData);
				return (
					<StandaloneCard
						{...this.props}
						data={nodeObj}
						userDetail={this.props.userDetail}
						handleDeleteOkr={this.props.handleDeleteOkr}
						setOpenDrawer={this.props.setOpenDrawer}
						setModalProps={this.props.setModalProps}
						updateProgress={this.props.updateProgress}
						showEditLoader={this.props.showEditLoader}
						isLocked={this.props.isLocked}
						diagramInstance={diagramInstance}
						tabFilter={tabFilter}
					/>
				);
			}
			if (node.id.indexOf('_sourceIcon_virtual') >= 0 && node.data.isParentVirtualIcon && node.data.isMyOkr) {
				return (
					<IconButton className='connect-icon connect-icon-first virtual-link-button'>
						<VirtualLinkContributorIcon />
					</IconButton>
				);
			}
			if (node.id.indexOf('_contributorIcon_virtual') >= 0 && node.data.isMyOkr && node.data.isVirtualLink) {
				return (
					<IconButton className='connect-icon connect-icon-first virtual-link-button'>
						<VirtualLinkContributorIcon />
					</IconButton>
				);
			}
			if (node.id.indexOf('contributorIcon') >= 0 && node.data.isContributorExist && !node.data.isParentOkr) {
				return (
					<Tooltip title={'Contributors'} arrow>
						<IconButton className='connect-icon connect-icon-first'>
							<IndividualSmallIcon />
						</IconButton>
					</Tooltip>
				);
			}
			if (node.id.indexOf('sourceIcon') >= 0 && node.data.isSourceExist) {
				if (node.data.isobjectiveType === 2) {
					return (
						<IconButton
							className={`connect-icon connect-icon-link ${node.data.isactionLevel > 0 ? 'default-icon' : ''}`}
						>
							<LinkIcon />
						</IconButton>
					);
				} else {
					return (
						<Tooltip title={'Parent'} arrow>
							<IconButton
								className={`connect-icon connect-icon-arrow ${node.data.isactionLevel > 0 ? 'default-icon' : ''}`}
							>
								<RightArrowIcon />
							</IconButton>
						</Tooltip>
					);
				}
			}
			if (node.data.templateType === TemplateType.Okr) {
				const [tabFilter, setTabFiter] = React.useState(this.props.tabFilter);
				setStateList.push(setTabFiter);
				const [nodeObj, setOkrData] = React.useState(node);
				this.setReactProps(node.id, 'setOkrData', setOkrData);

				const [relaxedCard, setAsRelaxedCard] = React.useState(false);
				this.setReactProps(node.id, 'setAsRelaxedCard', setAsRelaxedCard);

				return (
					<ObjectiveCard
						{...this.props}
						setCount={this.props.setCount}
						tabFilter={tabFilter}
						isRelaxedCard={relaxedCard}
						data={nodeObj}
						userDetail={this.props.userDetail}
						handleDeleteOkr={this.props.handleDeleteOkr}
						setModalProps={this.props.setModalProps}
						updateProgress={this.props.updateProgress}
						showEditLoader={this.props.showEditLoader}
						showAddGoalForm={this.props.showAddGoalForm}
						showAddGoal={this.props.showAddGoal}
						durationCycleDetail={this.props.durationCycleDetail}
						currentCycleEndDate={this.props.currentCycleEndDate}
						currentCycleId={this.props.currentCycleId}
						year={this.props.year}
						t={this.props.t}
						fetchOkr={this.props.fetchOkr}
						setGoalDetail={this.props.setGoalDetail}
						setIsGoalCreated={this.props.setIsGoalCreated}
						saveAndEditObjectiveAndKr={this.props.saveAndEditObjectiveAndKr}
						setFormSubmit={this.props.setFormSubmit}
						formSubmit={this.props.formSubmit}
						isGoalCreated={this.props.isGoalCreated}
						isLocked={this.props.isLocked}
						scaleValue={scaleValue}
						diagramInstance={diagramInstance}
					/>
				);
			}
		}
		return '';
	}
	setDiagramTimer() {
		if (diagramTimer) {
			clearTimeout(diagramTimer);
			this.toggleDiagramLoading(true);
		}
		diagramTimer = setTimeout(() => {
			this.toggleDiagramLoading(false);
			this.onAfterDiagramCreated();
			diagramRendered = true;
		}, 10);
	}
	setOverviewTimer(isUpdateContent) {
		if (overviewTimer) {
			clearTimeout(overviewTimer);
		}
		let ins = this;
		overviewTimer = setTimeout(() => {
			// console.log('overviewTimer Called: ' + overviewTimer.toString());
			this.updateOverview(isUpdateContent);
		}, 200);
	}
	onAfterDiagramCreated() {
		if (diagramInstance && diagramInstance.nodes) {
			diagramInstance.layout.type = 'None';
			diagramSelectionHelper.DoReverseConnection(diagramInstance);
			diagramOkrHelper.CreateOkrNodes(diagramInstance, diagramSelectionHelper);
			this.layoutDiagram();
			DiagramHelperUtil.RefreshDiagram(diagramInstance);
		}
	}
	layoutDiagram() {
		if (diagramInstance) {
			diagramSelectionHelper.HideExceptMyOkrs(diagramInstance);
			diagramOkrHelper['ref_overview'] = ref_overview;
			diagramLayoutHelper.DoCustomOkrLayout(diagramInstance, diagramOkrHelper, true, true);
			diagramLayoutHelper.FocusMyOkrs(diagramInstance);
			//this.updateOverview();
		}
	}
	updateOverview(isUpdateContent) {
		if (ref_overview && ref_overview.current) ref_overview.current.updateOverview(isUpdateContent);
	}
	isOverviewVisible() {
		if (
			ref_overview &&
			ref_overview.current &&
			ref_overview.current.ref_parent &&
			ref_overview.current.ref_parent.current
		) {
			let overviewContainer = ref_overview.current.ref_parent.current;
			return overviewContainer.style.visibility === 'visible';
		}
		return false;
	}
	toggleDiagramLoading(isShow) {
		let wText = document.querySelector('.control-section .welcome-content');
		if (wText) {
			wText.style.display = isShow ? '' : 'none';
		}
	}
	setMapType(tabFilter) {
		tabFilter = tabFilter ? tabFilter : this.props.tabFilter;
		switch (tabFilter) {
			case 'relaxed':
				DiagramHelperUtil.MapType = MapTypes.Relax;
				break;
			case 'compact':
				DiagramHelperUtil.MapType = MapTypes.CompactViewOkr;
				break;
			case 'ultracompact':
				DiagramHelperUtil.MapType = MapTypes.UltraCompactViewOkr;
				break;
		}
		diagramOkrHelper.Reset();
	}
	render() {
		this.setMapType();
		const { alignResult } = this.props;
		this.setDiagramTimer();
		if (alignResult.okrViewResponses.length <= 0) {
			return (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography>{this.props.t('loading')}</Typography>
					</Box>
				</Box>
			);
		} else {
			alignmentData = alignResult.okrViewResponses; //okrJsonData; //!alignmentData.length ? alignResult : alignmentData;
			alignmentData =
				alignmentData &&
				alignmentData.length > 0 &&
				alignmentData.map((item, i) => {
					//item.index = i + 1;
					item.tabFilter = this.props.tabFilter;
					if (item.parent.length === 0) {
						item.parent = '';
					}
					return item;
				});
			DiagramJsonHelper.UpdateHelperProperties(alignmentData);
			this.props.setIsUnreadFeedback(this.checkUnreadFeedback(alignmentData));
		}

		return (
			<Box className='diagram-control-pane okr-view-relaxed'>
				<MenuComponent items={this.menuItems} />
				<Box className='control-panel'>
					<Box className='control-section'>
						<Box className='content-wrapper'>
							<ToolbarComponent
								id='toolbar_diagram'
								clicked={this.onItemClick.bind(this)}
								items={[
									{
										type: 'Button',
										//tooltipText: 'ZoomIn',
										text: 'Zoom In',
										//prefixIcon: 'e-diagram-icons e-diagram-zoomin',
									},
									{
										type: 'Button',
										//tooltipText: 'ZoomOut',
										text: 'Zoom Out',
										//prefixIcon: 'e-diagram-icons e-diagram-zoomout',
									},
									{
										type: 'Button',
										text: 'Map',
									},
									{
										type: 'Button',
										//tooltipText: 'Reset',
										text: 'Reset',
										//prefixIcon: 'e-diagram-icons e-diagram-reset',
									},

									/*{
										type: 'Button',
										//tooltipText: 'Reset',
										text: 'ToggleExpand',`
										//prefixIcon: 'e-diagram-icons e-diagram-reset',
									},*/
								]}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='control-section'>
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography>{this.props.t('loading')}</Typography>
						</Box>
					</Box>
					<Box id='diagram-container' className='content-wrapper'>
						<DiagramComponent
							id='diagram'
							ref={(diagram) => (diagramInstance = diagram)}
							width={'100%'}
							//height={'1500px'}
							height={'calc(100vh - 224px)'}
							snapSettings={{ constraints: SnapConstraints.None }}
							nodeTemplate={template}
							//configures data source settings
							dataSourceSettings={{
								id: 'objectiveUniqueId',
								parentId: 'parent',
								dataSource: new DataManager(alignmentData),
								doBinding: (nodeModel, data, diagram) => {
									//nodeModel.excludeFromLayout = true;
									nodeDefaults(nodeModel, diagram);
								},
							}}
							layout={{
								connectionPointOrigin: ConnectionPointOrigin.SamePoint,
								type: 'ComplexHierarchicalTree',
								arrangement: ChildArrangement.Linear,
								horizontalSpacing: 200,
								verticalSpacing: 130,
							}}
							//Disables all interactions except zoom/pan
							tool={DiagramTools.ZoomPan}
							//Defines the default node and connector properties
							getNodeDefaults={(obj, diagram) => {
								/* tslint:disable:no-string-literal */
								obj.constraints = NodeConstraints.Default & ~NodeConstraints.Select;
							}}
							getConnectorDefaults={(connector, diagram) => {
								return connectorDefaults(connector, diagram);
							}}
							pageSettings={{
								// Sets the PageOrientation for the diagram to page
								orientation: 'Landscape',
								// Sets the Page Break for diagram
								showPageBreaks: false,
								// Defines the background color and image  of diagram
								background: {
									color: 'none',
								},
								scrollLimit: 'Infinity',
							}}
							scrollChange={() => {
								if (this.isOverviewVisible() && !ref_overview.current.state.dragging) {
									this.setOverviewTimer();
								}
							}}
						>
							<Inject services={[DataBinding, ComplexHierarchicalTree, LineDistribution]} />
						</DiagramComponent>
						<DiagramOverview ref={ref_overview} {...this.state.overviewProps}></DiagramOverview>
					</Box>
				</Box>
				{/* <button onClick={() => expandAll()}>Expand All</button> */}
			</Box>
		);
	}
}

function updateSizeOnTabSwitch(obj) {
	obj.height = diagramOkrHelper.GetOkrHeight(obj);
	obj.width = 500;
	if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
		obj.width = 300;
	}
	if (!obj.ports || obj.ports.length === 0) {
		obj.ports = [
			diagramOkrHelper.getLeftPort(obj),
			diagramOkrHelper.getRightPort(obj),
			diagramOkrHelper.getVLeftPort(obj),
			diagramOkrHelper.getVRightPort(obj),
		];
	} else {
		let portTyes = ['left', 'right', 'v-left', 'v-right'];
		for (let i = 0; i < obj.ports.length; i++) {
			diagramOkrHelper.getPort(obj, portTyes[i]);
		}
	}
}
//sets default value for Node.
function nodeDefaults(obj, diagram) {
	obj.constraints = NodeConstraints.Default & ~NodeConstraints.Select;
	if (!diagramSelectionHelper.IsSelectionHelper(obj)) {
		obj.visible = false;
		obj.data.templateType = TemplateType.Okr;
		obj.style = {
			fill: 'none',
			strokeColor: 'none',
			strokeWidth: 1,
			color: 'white',
		};
		obj.shape = {
			type: 'HTML',
			cornerRadius: 6,
		};
		obj.width = 500;
		if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
			obj.width = 300;
		}
		obj.data.width = obj.width;
		if (obj.data.objectiveType === 2) {
			if (obj.data.krUniqueId) {
				obj.id = 'sta-' + obj.data.krUniqueId;
			} else {
				obj.id = 'sta-' + obj.data.objectiveUniqueId;
			}
		}
		obj.height = obj.data.height = diagramOkrHelper.GetOkrHeight(obj);
		obj.ports = [
			diagramOkrHelper.getLeftPort(obj),
			diagramOkrHelper.getRightPort(obj),
			diagramOkrHelper.getVLeftPort(obj),
			diagramOkrHelper.getVRightPort(obj),
		];
	}
	return obj;
}
//sets default value for Connector.
function connectorDefaults(connector, diagram) {
	connector.constraints = ConnectorConstraints.Default & ~ConnectorConstraints.Select;
	if (!diagramSelectionHelper.IsSelectionHelper(connector)) {
		connector.targetDecorator.shape = 'Arrow';
		connector.type = 'Orthogonal'; // "Bezier";
		// connector.type = "Bezier";
		connector.visible = false;
		connector.cornerRadius = 100;
		connector.targetDecorator.height = 10;
		connector.targetDecorator.width = 10;
		connector.style = { strokeColor: '#5B708B', strokeWidth: 3 };
		connector.targetDecorator.style = {
			fill: '#5B708B',
			strokeColor: '#5B708B',
			strokeWidth: 3,
		};
		//connector.targetPadding = 10;
		return connector;
	}
}
function expandAll(isOnLoad = false) {
	//fits the diagram content within the viewport
	// if (diagramInstance) {
	diagramInstance.fitToPage();
	if (diagramInstance) {
		setTimeout(
			() => {
				for (let i = 0; i < diagramInstance.nodes.length; i++) {
					diagramInstance.nodes[i].isExpanded = isOnLoad ? false : !diagramInstance.nodes[i].isExpanded;
				}
			},
			isOnLoad ? 1000 : 100
		);
	}
	// }
}

import React, { Fragment } from 'react';
import { polyfill } from 'react-lifecycles-compat';
import { Button, Tooltip, Typography } from '@material-ui/core';
import T from 'prop-types';
import { layout, select, behavior, event } from 'd3';
import clone from 'clone';
import deepEqual from 'deep-equal';
import { v4 as uuid } from 'uuid';
import { ReCenterIcon, FitToScreenIcon } from '../../../config/svg/ActionSvg';

import NodeWrapper from './NodeWrapper';
import Node from './Node';
import Link from './Link';
import './style.css';
import { getFullName } from '../../../config/utils';
export let prevData = [];
export let defaultScale = 0;
export let d3 = { translate: { x: 0, y: 0 }, scale: 0.6 };

class Tree extends React.Component {
	state = {
		// eslint-disable-next-line react/no-unused-state
		dataRef: this.props.data,
		data: Tree.assignInternalProperties(this.props.data),
		d3: Tree.calculateD3Geometry(this.props),
		rd3tSvgClassName: `_${uuid()}`,
		rd3tGClassName: `_${uuid()}`,
	};

	internalState = {
		initialRender: true,
		targetNode: null,
		isTransitioning: false,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		let derivedState = null;

		// Clone new data & assign internal properties if `data` object reference changed.
		if (nextProps.data !== prevState.dataRef) {
			derivedState = {
				// eslint-disable-next-line react/no-unused-state
				dataRef: nextProps.data,
				data: Tree.assignInternalProperties(nextProps.data),
			};
		}

		return derivedState;
	}

	componentDidMount() {
		this.bindZoomListener(this.props);
		this.internalState.initialRender = false;
	}

	componentDidUpdate(prevProps) {
		// If zoom-specific props change -> rebind listener with new values
		// Or: rebind zoom listeners to new DOM nodes in case NodeWrapper switched <TransitionGroup> <-> <g>
		if (
			!deepEqual(this.props.translate, prevProps.translate) ||
			!deepEqual(this.props.scaleExtent, prevProps.scaleExtent) ||
			this.props.zoom !== prevProps.zoom ||
			this.props.transitionDuration !== prevProps.transitionDuration
		) {
			this.bindZoomListener(this.props);
		}

		if (typeof this.props.onUpdate === 'function') {
			this.props.onUpdate({
				node: this.internalState.targetNode ? clone(this.internalState.targetNode) : null,
				zoom: this.state.d3.scale,
				translate: this.state.d3.translate,
			});
		}
		// Reset the last target node after we've flushed it to `onUpdate`.
		this.internalState.targetNode = null;
	}

	/**
	 * setInitialTreeDepth - Description
	 *
	 * @param {array} nodeSet Array of nodes generated by `generateTree`
	 * @param {number} initialDepth Maximum initial depth the tree should render
	 *
	 * @return {void}
	 */
	setInitialTreeDepth(nodeSet, initialDepth) {
		nodeSet.forEach((n) => {
			n._collapsed = n.alignLevel >= 0;
		});
	}

	clickHandler = (nodeId, event) => {
		const { rd3tGClassName } = this.state;
		const g = select(`.${rd3tGClassName}`);
		const data = clone(this.state.data);
		const matches = this.findNodesById(nodeId, data, []);
		let targetNode = matches[0];

		let nodes = [];
		try {
			if (targetNode && matches[0].type !== 'kr') {
				nodes.push(targetNode);

				while (targetNode.parent) {
					targetNode = targetNode.parent;
					nodes.push(targetNode);
				}
				const node = g.selectAll('.node')[0];
				const link = g.selectAll('.link')[0];
				const parentLinks = g.selectAll('.parentLinks')[0];
				if (matches[0] && matches[0].currentUser) {
					const parentNode = g.selectAll('.parentNodes')[0];

					if (parentNode[0].childNodes && parentLinks[0].childNodes) {
						parentNode[0].childNodes.forEach((el) => {
							if (el && el.querySelector('.oc-node')) {
								el.querySelector('.oc-node').classList.add('highlight-node');
							}
						});
						parentLinks[0].childNodes.forEach((el) => {
							if (el && el.querySelector('.linkBase')) {
								el.querySelector('.linkBase').style.stroke = 'red';
								el.querySelector('.linkBase').style.strokeWidth = '3.0px';
							}
						});
					}
				}
				// ||	(item.sourceAlign && item.sourceAlign.split(',').includes(d.getAttribute('sourceId')))
				const filterNode = node.filter((d) => nodes.find((item) => item?.id === d?.getAttribute('id')));
				const filterTopNode = g
					.selectAll('.node')[0]
					.filter(
						(d) =>
							d.getAttribute('level') === '0' &&
							matches[0].sourceAlign &&
							matches[0].sourceAlign.split(',').includes(d.getAttribute('sourceId'))
					);
				const filterLinks = link.filter((d) => nodes.find((item) => item?.id === d?.getAttribute('target')));

				[...filterNode, ...filterTopNode].forEach((el) => {
					if (el && el.querySelector('.oc-node')) {
						el.querySelector('.oc-node').classList.add('highlight-node');
					}
				});
				const filterTopLink = g
					.selectAll('.link')[0]
					.filter(
						(d) =>
							d.getAttribute('level') === '0' &&
							matches[0].sourceAlign &&
							matches[0].sourceAlign.split(',').includes(d.getAttribute('sourceId'))
					);
				[...filterLinks, ...filterTopLink].forEach((el) => {
					if (el) {
						el.querySelector('.linkBase').style.stroke = 'red';
						el.querySelector('.linkBase').style.strokeWidth = '3.0px';
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	removeHandler = () => {
		const element = document.querySelectorAll('.highlight-node');

		const element1 = document.querySelectorAll('.linkBase');
		if (element) {
			element.forEach((el) => {
				el.classList.remove('highlight-node');
			});
			element1.forEach((el) => {
				el.style.stroke = '#39A3FA';
				el.style.strokeWidth = '2.0px';
			});
		}
	};

	checkForNodeLength = (node) => {
		const myGoalResp = node.myGoalOkrResponses;
		if (
			myGoalResp &&
			myGoalResp.length > 0 &&
			clone(myGoalResp).filter((item) => item.rootNode && item.parentOpen).length === 0 &&
			myGoalResp.find((item) => item.children && item.children.length > 7 && !item._collapsed)
		) {
			return true;
		}
		return false;
	};

	zoomFit = (paddingPercent, transitionDuration, top, node, type, nodeType) => {
		try {
			if (type !== 1 && this.checkForNodeLength(this.props.alignResult.okrViewResponses)) {
				paddingPercent = 0.95;
				top = 150;
			}

			const { scaleExtent } = this.props;
			const { rd3tSvgClassName, rd3tGClassName } = this.state;
			const svg = select(`.${rd3tSvgClassName}`);
			const root = select(`.${rd3tGClassName}`);
			var bounds = root.node().getBBox();
			var parent = root.node().parentElement;
			var fullWidth = parent.clientWidth,
				fullHeight = parent.clientHeight;
			var width = bounds.width,
				height = bounds.height;
			var midX = bounds.x + width / 2,
				midY = bounds.y + height / 2;
			if (width === 0 || height === 0) return; // nothing to fit

			var scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
			let x = fullWidth / 2 - scale * midX;
			let y = fullHeight / 2 - scale * midY;
			let xx = x;
			let yy = y;

			if (type === 1) {
				scale = 0.55;
				xx = -node.x;
				yy = -node.y;

				xx = xx * scale + fullWidth / 2;
				yy = yy * scale + fullHeight / 2;
				xx = xx - scale * 170;
				if (nodeType !== 'parent' || node.parentOpen === false) {
					yy = yy - 180;
				}
				// d3.scale
				//xx = d3.translate.xx === 0 ? this.state.d3.translate.x : d3.translate.x;
				//yy = d3.translate.yy === 0 ? this.state.d3.translate.y : d3.translate.y;
			}

			var translate = [xx, yy - top];
			svg
				.transition()
				.duration(3000)
				.call(
					behavior
						.zoom()
						.scaleExtent([scaleExtent.min, scaleExtent.max])
						.on('zoom', () => {
							// eslint-disable-next-line react/no-direct-mutation-state
							this.state.d3.scale = scale;
							// eslint-disable-next-line react/no-direct-mutation-state
							this.state.d3.translate = { x: translate[0], y: translate[1] };
							this.bindZoomListener(this.props);

							root.attr('transform', `translate(${event.translate}) scale(${event.scale})`);
						})

						.scale(scale)
						.translate(translate).event
				);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * bindZoomListener - If `props.zoomable`, binds a listener for
	 * "zoom" events to the SVG and sets scaleExtent to min/max
	 * specified in `props.scaleExtent`.
	 *
	 * @return {void}
	 */
	bindZoomListener(props) {
		const { zoomable, scaleExtent, onUpdate } = props;
		const { rd3tSvgClassName, rd3tGClassName } = this.state;
		const svg = select(`.${rd3tSvgClassName}`);
		const g = select(`.${rd3tGClassName}`);
		// this.zoomFit(0.6, 0, 80);
		if (zoomable) {
			svg.call(
				behavior
					.zoom()
					.scaleExtent([scaleExtent.min, scaleExtent.max])
					.on('zoom', () => {
						if (event.scale === 1 && this.state.d3.scale !== 1) {
							event.scale = this.state.d3.scale;
						}
						g.attr('transform', `translate(${event.translate}) scale(${event.scale})`);
						if (typeof onUpdate === 'function') {
							// This callback is magically called not only on "zoom", but on "drag", as well,
							// even though event.type === "zoom".
							// Taking advantage of this and not writing a "drag" handler.
							onUpdate({
								node: null,
								zoom: event.scale,
								translate: { x: event.translate[0], y: event.translate[1] },
							});
						}
						// eslint-disable-next-line react/no-direct-mutation-state
						this.state.d3.scale = event.scale;
						// eslint-disable-next-line react/no-direct-mutation-state
						this.state.d3.translate = { x: event.translate[0], y: event.translate[1] };
						d3 = this.state.d3;
					})
					// Offset so that first pan and zoom does not jump back to [0,0] coords
					.scale(this.state.d3.scale)
					.translate([this.state.d3.translate.x, this.state.d3.translate.y])
			);
		}
	}

	/**
	 * assignInternalProperties - Assigns internal properties to each node in the
	 * `data` set that are required for tree manipulation and returns
	 * a new `data` array.
	 *
	 * @static
	 * @param {array} data Hierarchical tree data
	 *
	 * @return {array} `data` array with internal properties added
	 */
	static assignInternalProperties(data) {
		// Wrap the root node into an array for recursive transformations if it wasn't in one already.
		const d = Array.isArray(data) ? data : [data];
		return d.map((node) => {
			node.id = uuid();
			// If the node's `_collapsed` state wasn't defined by the data set -> default to `false`.
			if (node._collapsed === undefined) {
				node._collapsed = false;
			}
			// If there are children, recursively assign properties to them too
			if (node.children && node.children.length > 0) {
				node.children = Tree.assignInternalProperties(node.children);
				node._children = node.children;
				if (node.type === 'okr' && !node.showKr) {
					node._children_okr = node.children;
				}
			}
			return node;
		});
	}

	/**
	 * findNodesById - Recursively walks the nested `nodeSet` until a node matching `nodeId` is found.
	 *
	 * @param {string} nodeId The `node.id` being searched for
	 * @param {array} nodeSet Array of nested `node` objects
	 * @param {array} hits Accumulator for matches, passed between recursive calls
	 *
	 * @return {array} Set of nodes matching `nodeId`
	 */
	// TODO: Refactor this into a more readable/reasonable recursive depth-first walk.
	findNodesById(nodeId, nodeSet, hits) {
		if (hits.length > 0) {
			return hits;
		}

		hits = hits.concat(nodeSet.filter((node) => node.id === nodeId));

		nodeSet.forEach((node) => {
			if (node._children && node._children.length > 0) {
				hits = this.findNodesById(nodeId, node._children, hits);
			}
		});

		return hits;
	}

	/**
	 * findNodesAtDepth - Recursively walks the nested `nodeSet` until all nodes at `depth` have been found.
	 *
	 * @param {number} depth Target depth for which nodes should be returned
	 * @param {array} nodeSet Array of nested `node` objects
	 * @param {array} accumulator Accumulator for matches, passed between recursive calls
	 * @return
	 */
	findNodesAtDepth(depth, nodeSet, accumulator) {
		accumulator = accumulator.concat(nodeSet.filter((node) => node.depth === depth));

		nodeSet.forEach((node) => {
			if (node._children && node._children.length > 0) {
				accumulator = this.findNodesAtDepth(depth, node._children, accumulator);
			}
		});

		return accumulator;
	}

	/**
	 * collapseNode - Recursively sets the `_collapsed` property of
	 * the passed `node` object and its children to `true`.
	 *
	 * @param {Node} node Node object with custom properties
	 *
	 * @return {void}
	 */
	static checkForParentNode = (node) => {
		if (node.source === 0 && node.children && node.children.find((item) => item.parentItem && !item.hide)) {
			return true;
		}
		return false;
	};

	static collapseNode(node, type) {
		if (type === 'parent') {
			if (node.rootNode) {
				node.parentOpen = false;
			}
			node.hide = node.rootNode || Tree.checkForParentNode(node) ? false : true;
			if (node.parent) {
				Tree.collapseNode(node.parent, type);
			}
		} else {
			node._collapsed = true;
			if (node._children && node._children.length > 0) {
				node._children.forEach((child) => {
					Tree.collapseNode(child, type);
				});
			}
		}
	}

	/**
	 * expandNode - Sets the `_collapsed` property of
	 * the passed `node` object to `false`.
	 *
	 * @param {object} node Node object with custom properties
	 *
	 * @return {void}
	 */
	static expandNode(node, type) {
		if (type === 'parent') {
			if (node.rootNode) {
				node.parentOpen = true;
			}
			node.hide = false;
			if (node.parent) {
				Tree.expandNode(node.parent, type);
			}
		} else {
			node._collapsed = false;
		}
	}

	/**
	 * collapseNodeNeighbors - Collapses all nodes in `nodeSet` that are neighbors (same depth) of `targetNode`.
	 *
	 * @param {object} targetNode
	 * @param {array} nodeSet
	 *
	 * @return {void}
	 */
	collapseNeighborNodes(targetNode, nodeSet) {
		const neighbors = this.findNodesAtDepth(targetNode.depth, nodeSet, []).filter((node) => node.id !== targetNode.id);
		neighbors.forEach((neighbor) => Tree.collapseNode(neighbor));
	}

	/**
	 * handleNodeToggle - Finds the node matching `nodeId` and
	 * expands/collapses it, depending on the current state of
	 * its `_collapsed` property.
	 * `setState` callback receives targetNode and handles
	 * `props.onClick` if defined.
	 *
	 * @param {string} nodeId A node object's `id` field.
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleNodeToggle = (nodeId, evt, type) => {
		const data = this.state.data;
		const matches = this.findNodesById(nodeId, data, []);
		const targetNode = matches[0];
		this.props.setShowText(false);

		// Persist the SyntheticEvent for downstream handling by users.
		//evt.persist();

		if (this.props.collapsible && !this.state.isTransitioning) {
			if (type === 'parent') {
				if (!targetNode.parentOpen) {
					targetNode.highlight = true;
					Tree.expandNode(targetNode, type);
					setTimeout(() => {
						this.zoomFit(0.65, 750, 100, targetNode, 1, type);
						this.props.updatePrevData(this.state.data[0].children, targetNode);
					}, 550);
				} else {
					Tree.collapseNode(targetNode, type);
					targetNode.highlight = false;
					setTimeout(() => {
						this.zoomFit(0.65, 750, 100, targetNode, 1, type);
						this.props.updatePrevData(this.state.data[0].children, targetNode);
					}, 550);
				}
			} else {
				if (targetNode._collapsed) {
					targetNode.highlight = true;
					Tree.expandNode(targetNode, type);
					setTimeout(() => {
						this.zoomFit(0.65, 750, 100, targetNode, 1, type);
						this.props.updatePrevData(this.state.data[0].children, targetNode);
					}, 550);
				} else {
					Tree.collapseNode(targetNode, type);
					targetNode.highlight = false;
					setTimeout(() => this.zoomFit(0.65, 750, 100, targetNode, 1, type), 550);
					this.props.updatePrevData(this.state.data[0].children, targetNode);
				}
			}
			// Lock node toggling while transition takes place
			this.setState({ data: clone(this.state.data), isTransitioning: true }, () =>
				this.handleOnClickCb(targetNode, evt)
			);
			// Await transitionDuration + 10 ms before unlocking node toggling again
			setTimeout(() => this.setState({ isTransitioning: false }), 750 + 10);
			this.internalState.targetNode = targetNode;
		} else {
			this.handleOnClickCb(targetNode, evt);
		}
	};

	/**
	 * handleOnClickCb - Handles the user-defined `onClick` function
	 *
	 * @param {object} targetNode Description
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnClickCb = (targetNode, evt, type) => {
		const { onClick } = this.props;
		if (onClick && typeof onClick === 'function') {
		}
	};

	/**
	 * handleOnLinkClickCb - Handles the user-defined `onLinkClick` function
	 *
	 * @param {object} linkSource Description
	 *
	 * @param {object} linkTarget Description
	 *
	 *  @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnLinkClickCb = (linkSource, linkTarget, evt) => {
		const { onLinkClick } = this.props;
		if (onLinkClick && typeof onLinkClick === 'function') {
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onLinkClick(clone(linkSource), clone(linkTarget), evt);
		}
	};

	/**
	 * handleOnMouseOverCb - Handles the user-defined `onMouseOver` function
	 *
	 * @param {string} nodeId
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnMouseOverCb = (nodeId, evt) => {
		this.clickHandler(nodeId, evt);
		const { onMouseOver } = this.props;
		if (onMouseOver && typeof onMouseOver === 'function') {
			const data = clone(this.state.data);
			const matches = this.findNodesById(nodeId, data, []);
			const targetNode = matches[0];

			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onMouseOver(clone(targetNode), evt);
		}
	};

	/**
	 * handleOnLinkMouseOverCb - Handles the user-defined `onLinkMouseOver` function
	 *
	 * @param {object} linkSource Description
	 *
	 * @param {object} linkTarget Description
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnLinkMouseOverCb = (linkSource, linkTarget, evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		const { onLinkMouseOver } = this.props;
		if (onLinkMouseOver && typeof onLinkMouseOver === 'function') {
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onLinkMouseOver(clone(linkSource), clone(linkTarget), evt);
		}
	};

	/**
	 * handleOnMouseOutCb - Handles the user-defined `onMouseOut` function
	 *
	 * @param {string} nodeId
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnMouseOutCb = (nodeId, evt) => {
		this.removeHandler(evt);
		const { onMouseOut } = this.props;
		if (onMouseOut && typeof onMouseOut === 'function') {
			const data = clone(this.state.data);
			const matches = this.findNodesById(nodeId, data, []);
			const targetNode = matches[0];
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onMouseOut(clone(targetNode), evt);
		}
	};

	/**
	 * handleOnLinkMouseOutCb - Handles the user-defined `onLinkMouseOut` function
	 *
	 * @param {string} linkSource
	 *
	 * @param {string} linkTarget
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnLinkMouseOutCb = (linkSource, linkTarget, evt) => {
		const { onLinkMouseOut } = this.props;
		if (onLinkMouseOut && typeof onLinkMouseOut === 'function') {
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onLinkMouseOut(clone(linkSource), clone(linkTarget), evt);
		}
	};

	/**
	 * generateTree - Generates tree elements (`nodes` and `links`) by
	 * grabbing the rootNode from `this.state.data[0]`.
	 * Restricts tree depth to `props.initialDepth` if defined and if this is
	 * the initial render of the tree.
	 *
	 * @return {object} Object containing `nodes` and `links`.
	 */
	generateTree() {
		const { separation, nodeSize, orientation } = this.props;

		const tree = layout
			.tree()
			.nodeSize(orientation === 'horizontal' ? [nodeSize.y, nodeSize.x] : [nodeSize.x, nodeSize.y])
			.separation((a, b) => (a.parent.id === b.parent.id ? separation.siblings : separation.nonSiblings))
			.children((d) => (d._collapsed ? null : d._children));
		const rootNode = this.state.data[0];
		let nodes = tree.nodes(rootNode).filter(function (d) {
			return !d.hide;
		});

		//if (depthFactor) {
		nodes.forEach((node) => {
			if (node.type === 'okr') {
				node.y = node.alignLevel * 350;
			} else {
				if (node.parent) {
					node.y = (node.parent.alignLevel + 1) * 350;
				}
			}
		});
		//}
		const links = tree.links(nodes);
		return { nodes, links };
	}
	/**
	 * calculateD3Geometry - Set initial zoom and position.
	 * Also limit zoom level according to `scaleExtent` on initial display. This is necessary,
	 * because the first time we are setting it as an SVG property, instead of going
	 * through D3's scaling mechanism, which would have picked up both properties.
	 *
	 * @param  {object} nextProps
	 * @return {object} {translate: {x: number, y: number}, zoom: number}
	 */
	static calculateD3Geometry(nextProps) {
		let scale;

		if (nextProps.zoom > nextProps.scaleExtent.max) {
			scale = nextProps.scaleExtent.max;
		} else if (nextProps.zoom < nextProps.scaleExtent.min) {
			scale = nextProps.scaleExtent.min;
		} else {
			scale = nextProps.zoom;
		}

		return {
			translate: nextProps.translate,
			scale,
		};
	}

	locateMe = () => {
		this.props.locateMe();
	};

	render() {
		const { nodes, links } = this.generateTree();

		const { rd3tSvgClassName, rd3tGClassName } = this.state;
		const {
			nodeSvgShape,
			nodeLabelComponent,
			orientation,
			pathFunc,
			transitionDuration,
			zoomable,
			textLayout,
			nodeSize,
			depthFactor,
			initialDepth,
			separation,
			circleRadius,
			allowForeignObjects,
			styles,
		} = this.props;
		const { translate, scale } = this.state.d3;
		const subscriptions = { ...nodeSize, ...separation, depthFactor, initialDepth };
		return (
			<Fragment>
				<div className='global-header-info'>
					<Fragment>
						<div className='alignment-top-text'>
							{this.props.showText && (
								<Fragment>
									{this.props.currentUser ? (
										<Typography variant='body1'>
											{this.props.t('seeAlignedToUser', { name: getFullName(this.props.currentUser) })}
										</Typography>
									) : (
										<Typography variant='body1'>{this.props.t('seeAlignedToYou')}</Typography>
									)}
								</Fragment>
							)}
						</div>
					</Fragment>
					<div className='fit-screen-action'>
						<Tooltip title={'Locate Me'} arrow>
							<Button className='recenter-icon' onClick={() => this.locateMe()}>
								<ReCenterIcon />
							</Button>
						</Tooltip>
						<Tooltip title={'Zoom to Fit'} arrow>
							<Button id='fit-to-screen-btn' className='fit-to-screen-icon' onClick={() => this.zoomFit(0.6, 0, 80)}>
								<FitToScreenIcon />
							</Button>
						</Tooltip>
					</div>
				</div>
				<div className={`rd3t-tree-container ${zoomable ? 'rd3t-grabbable' : undefined}`} style={{ height: '100vh' }}>
					<svg className={rd3tSvgClassName} width='100%' height='100%'>
						<NodeWrapper
							transitionDuration={transitionDuration}
							component='g'
							className={rd3tGClassName + ' main-node'}
							transform={`translate(${translate.x},${translate.y}) scale(${scale})`}
						>
							<g className='links'>
								{links.map((linkData) => (
									<Link
										key={uuid()}
										orientation={orientation}
										pathFunc={linkData.target.type === 'kr' ? 'step' : pathFunc}
										linkData={linkData}
										onClick={this.handleOnLinkClickCb}
										onMouseOver={this.handleOnLinkMouseOverCb}
										onMouseOut={this.handleOnLinkMouseOutCb}
										transitionDuration={transitionDuration}
										styles={
											linkData.source.showKr
												? {
														stroke: '#39a3fa',
														strokeWidth: 1,
												  }
												: styles.links
										}
										nodeSize={nodeSize}
										linkSize={linkData.source.showKr ? 220 : linkData.source.parentItem ? 220 : 205}
										type={linkData.target.showKr ? 'topLink' : ''}
									/>
								))}
							</g>
							<g className='nodes'>
								{nodes.map((nodeData) => (
									<Node
										key={nodeData.id}
										nodeSvgShape={{ ...nodeSvgShape, ...nodeData.nodeSvgShape }}
										nodeLabelComponent={nodeLabelComponent}
										nodeSize={nodeSize}
										orientation={orientation}
										transitionDuration={transitionDuration}
										nodeData={nodeData}
										name={nodeData.name}
										attributes={nodeData.attributes}
										onClick={this.handleNodeToggle}
										onMouseOver={this.handleOnMouseOverCb}
										onMouseOut={this.handleOnMouseOutCb}
										textLayout={nodeData.textLayout || textLayout}
										circleRadius={circleRadius}
										subscriptions={subscriptions}
										allowForeignObjects={allowForeignObjects}
										styles={styles.nodes}
										handleToggle={this.handleToggle}
										handleDeleteOrganization={this.handleDeleteOrganization}
										chartType='alignment'
										alignResult={this.props.alignResult}
										handleDeleteObjective={this.props.handleDeleteObjective}
										openAddKeyResult={this.props.openAddKeyResult}
										handleChange={this.props.handleChange}
										checked={this.props.checked}
										updateObjectiveSlider={this.props.updateObjectiveSlider}
										getObjectiveByObjectiveId={this.props.getObjectiveByObjectiveId}
										resetFeedback={this.props.resetFeedback}
										zoomFit={this.zoomFit}
										setTransitionDuration={this.props.setTransitionDuration}
									/>
								))}
							</g>
						</NodeWrapper>
					</svg>
				</div>
			</Fragment>
		);
	}
}

Tree.defaultProps = {
	nodeSvgShape: {
		shape: 'circle',
		shapeProps: {
			r: 10,
		},
	},
	nodeLabelComponent: null,
	onClick: undefined,
	onMouseOver: undefined,
	onMouseOut: undefined,
	onLinkClick: undefined,
	onLinkMouseOver: undefined,
	onLinkMouseOut: undefined,
	onUpdate: undefined,
	orientation: 'horizontal',
	translate: { x: 0, y: 0 },
	pathFunc: 'diagonal',
	transitionDuration: 500,
	depthFactor: undefined,
	collapsible: true,
	useCollapseData: false,
	initialDepth: undefined,
	zoomable: true,
	zoom: 1,
	scaleExtent: { min: 0.1, max: 1 },
	nodeSize: { x: 140, y: 140 },
	separation: { siblings: 1, nonSiblings: 2 },
	textLayout: {
		textAnchor: 'start',
		x: 10,
		y: -10,
		transform: undefined,
	},
	allowForeignObjects: false,
	shouldCollapseNeighborNodes: false,
	circleRadius: undefined, // TODO: DEPRECATE
	styles: {},
};

Tree.propTypes = {
	data: T.oneOfType([T.array, T.object]).isRequired,
	nodeSvgShape: T.shape({
		shape: T.string,
		shapeProps: T.object,
	}),
	nodeLabelComponent: T.object,
	onClick: T.func,
	onMouseOver: T.func,
	onMouseOut: T.func,
	onLinkClick: T.func,
	onLinkMouseOver: T.func,
	onLinkMouseOut: T.func,
	onUpdate: T.func,
	orientation: T.oneOf(['horizontal', 'vertical']),
	translate: T.shape({
		x: T.number,
		y: T.number,
	}),
	pathFunc: T.oneOfType([T.oneOf(['diagonal', 'elbow', 'straight', 'step']), T.func]),
	transitionDuration: T.number,
	depthFactor: T.number,
	collapsible: T.bool,
	useCollapseData: T.bool,
	initialDepth: T.number,
	zoomable: T.bool,
	zoom: T.number,
	scaleExtent: T.shape({
		min: T.number,
		max: T.number,
	}),
	nodeSize: T.shape({
		x: T.number,
		y: T.number,
	}),
	separation: T.shape({
		siblings: T.number,
		nonSiblings: T.number,
	}),
	textLayout: T.object,
	allowForeignObjects: T.bool,
	shouldCollapseNeighborNodes: T.bool,
	circleRadius: T.number,
	styles: T.shape({
		nodes: T.object,
		links: T.object,
	}),
};

// Polyfill React 16 lifecycle methods for compat with React 15.
polyfill(Tree);

export default Tree;

import React, { useEffect, Fragment, useState } from 'react';
import { Box, Typography, List, ListItem, TextField } from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { removeLocalStorageItem } from '../../services/StorageService';
import { SettingsIcon } from '../../config/svg/MyGoalSvg';
import { DeleteIcon } from '../../config/svg/CommonSvg';
import { UserAvatarGroup } from './UserAvatarGroup';
import SkeletonAvatar from './SkeletonAvatar';
import { getDueDaysText, getMonthDate, getMonthDateYear } from '../../config/utils';
import { OkrProgressBar } from '../OkrAction/OkrProgressBar';
import { Enums } from '../../config/enums';
import { KrConfidencePopper } from './KrConfidencePopper';

const AddNewKr: React.FC<any> = (props: any) => {
	const {
		okrMasterData,
		okr,
		newKeyList,
		handleKRFocus,
		handleKRBlur,
		handleUserClickOnCard,
		handleNewKeyResultChange,
		checkMandatoryFieldforKR,
		showGoalAndKrAttribute,
		isAddClicked,
		finalSubmitted,
		deleteNewKeyResult,
		krFocus,
		loginUserAvatar,
		checkIfOkrList,
		userDetail,
		checkTeamIdAndCreatedBy,
		checkCanAddKr,
		updateProgress,
		t,
		showEditLoader,
		currentUser,
		showArchiveList,
		listOkrResult,
		enqueueSnackbar,
		setNewKeyList,
		selectedCycleDetails,
	} = props;

	const [updatedNewKRList, setUpdatedNewKRList] = useState<any>(newKeyList);

	useEffect(() => {
		// This will use to update Add new KR Start and End date if we updated OKR date

		const updatedList =
			newKeyList && newKeyList.length > 0
				? newKeyList.map((item: any) => {
						return {
							...item,
							dateRange: item?.dateRange || null,
						};
				  })
				: newKeyList;
		setUpdatedNewKRList(updatedList);
		setTimeout(() => {
			removeLocalStorageItem('updatedGoalObjId');
		}, 2000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newKeyList]);

	return (
		<>
			{updatedNewKRList &&
				updatedNewKRList.length > 0 &&
				updatedNewKRList.map((item: any) => (
					<Box className=''>
						<Box
							className='my-obj-listing-content kr-listing-content orphen-kr-content'
							key={`newly_added_key_${item.id}`}
							onClick={(e) => handleUserClickOnCard(e)}
						>
							<Box className='my-obj-listing-inner'>
								<List component='ul'>
									<ListItem className='okr-col1'>
										<Box
											className='add-kr-textfield'
											key={`newly_added_key_${item.id}`}
											onClick={handleUserClickOnCard}
										>
											<Box className='kr-field' display='flex' alignItems='center' justifyContent='space-between'>
												<TextField
													value={item.keyDescription}
													className='add-kr'
													multiline
													rows={2}
													placeholder={t('addKeyRes')}
													InputProps={{ 'aria-label': 'Add Key Result', disableUnderline: true }}
													onChange={(e: any) => handleNewKeyResultChange(e.target.value, 'keyDescription', item)}
													error={isAddClicked && !Boolean(item.keyDescription)}
													autoFocus
													onFocus={handleKRFocus}
													onBlur={handleKRBlur}
												/>
											</Box>
											{item.keyDescription && item.keyDescription.trim() !== '' && (
												<Box className='listing-add-kr card-toggle'>
													<OKRButton
														title={t('weightHover')}
														className='btn-oval-shape'
														text={`${item.weightId}x`}
														buttonType={'disableButtonTooltip'}
														wrapClassName='btn-weight'
														handleClick={(e) => showGoalAndKrAttribute(e, okr, item, 2, 1, setNewKeyList)}
													/>
													<Box className='set-timeline-area'>
														<Box className='calender-date'>
															{checkIfOkrList() &&
															item.keyDescription &&
															item.keyDescription.trim() !== '' &&
															(item?.owner === userDetail?.employeeId ||
																checkTeamIdAndCreatedBy(okr) ||
																checkCanAddKr(okr, item)) ? (
																<Box className='timeline-field'>
																	<Box className='calendar-btn'>
																		<OKRButton
																			tooltipClassName='tooltip-layout3'
																			text={`${getMonthDate(item.dueDate)} (${props.userSelectedCycle.label})`}
																			handleClick={(e: any) => {
																				props.updateCalloutPanel && props.updateCalloutPanel('');
																				setTimeout(() => {
																					props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
																				}, 500);

																				showGoalAndKrAttribute(e, okr, item, 2, 1, setNewKeyList);
																				removeLocalStorageItem('showCallouts');
																			}}
																			title={
																				<>
																					<Typography variant='h6'>
																						{`${getMonthDateYear(item.startDate)} - ${getMonthDateYear(item.dueDate)}`}
																					</Typography>
																					<Typography variant='h6'>
																					{getDueDaysText(item.startDate, item.dueDate, selectedCycleDetails)}
																					</Typography>
																				</>
																			}
																		/>
																	</Box>
																</Box>
															) : (
																<Box className='timeline-field'>
																	<Box className='calendar-btn'>
																		<OKRButton
																			tooltipClassName='tooltip-layout3'
																			text={`${getMonthDate(item.dueDate)} (${props.userSelectedCycle.label})`}
																			title={
																				<>
																					<Typography variant='h6'>
																						{`${getMonthDateYear(item.startDate)} - ${getMonthDateYear(item.dueDate)}`}
																					</Typography>
																					<Typography variant='h6'>
																					{getDueDaysText(item.startDate, item.dueDate, selectedCycleDetails)}
																					</Typography>
																				</>
																			}
																		/>
																	</Box>
																</Box>
															)}
														</Box>
													</Box>
													<Box className='okr-field-actions'>
														<OKRButton
															title={t('KrAttributeText')}
															className={
																!checkMandatoryFieldforKR(item) && finalSubmitted
																	? 'settings-btn settings-btn-error'
																	: 'settings-btn'
															}
															handleClick={(e: any) => {
																props.updateCalloutPanel && props.updateCalloutPanel('');
																setTimeout(() => {
																	props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
																}, 500);

																showGoalAndKrAttribute(e, okr, item, 2, 1, setNewKeyList);
																removeLocalStorageItem('showCallouts');
															}}
															icon={<SettingsIcon />}
														/>

														<OKRButton
															title={t('delete')}
															handleClick={() => deleteNewKeyResult(okr, item)}
															className='delete-btn'
															icon={<DeleteIcon />}
														/>
													</Box>
												</Box>
											)}
										</Box>
									</ListItem>

									{(item.keyDescription && item.keyDescription.trim() !== '') || krFocus ? (
										<React.Fragment>
											<ListItem className='okr-col2 contributors newKrList'>
												<Box className='okr-col2'>
													<Box className='contributors-avatar-group'>
														<UserAvatarGroup
															{...props}
															contributorDetails={item?.contributors}
															avatarClassName=''
															isButtonVisible={false}
															max={3}
															handleAddUserClick={() => {}}
															loginUserAvatar={
																okr?.isCoach === true
																	? {
																			firstName: okr?.ownerFirstName,
																			lastName: okr?.ownerLastName,
																			imagePath: okr?.ownerImagePath,
																			employeeId: okr?.employeeId,
																	  }
																	: loginUserAvatar
															}
															currentUser={false}
															contributorDetailsType={true}
														/>
														{item?.contributors.length <= 0 && <SkeletonAvatar />}
													</Box>
												</Box>
											</ListItem>

											<ListItem className='okr-col3 newKrList'>
												{item.keyDescription && item.keyDescription.trim() !== '' && (
													<Box className='confidence-btn-wrap'>
														<KrConfidencePopper
															kr={item}
															type={2}
															canUpdateConfidence={false}
															currentUser={currentUser}
															confidenceValue={item.confidenceId}
															showWeekelyTrend={false}
															isTeamView={props.isTeamView}
															updateKrData={(confidenceId: number) =>
																handleNewKeyResultChange(confidenceId, 'confidenceId', item)
															}
															{...props}
														/>
													</Box>
												)}
											</ListItem>
											<ListItem className='okr-col4 newKrList'>
												<OkrProgressBar
													{...props}
													parentOkr={okr}
													okr={item}
													type={'kr'}
													t={t}
													updateProgress={updateProgress}
													showEditLoader={showEditLoader}
													currentUser={currentUser}
													okrMasterData={okrMasterData}
													teamsOkr={props.teamsOkr}
													showArchiveList={showArchiveList}
													newKr={true}
													isScoreLocked={listOkrResult?.isScoreLocked}
													enqueueSnackbar={enqueueSnackbar}
													isAnyDrawerOpened={props.isAnyDrawerOpened}
													appDrawerInfo={props.appDrawerInfo}
												/>
											</ListItem>
										</React.Fragment>
									) : (
										''
									)}
								</List>
							</Box>
						</Box>
					</Box>
				))}
		</>
	);
};

export default AddNewKr;

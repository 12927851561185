import {
	getAvgOkrReportApi,
	getMostWeeklyKrReportApi,
	getMostFeedbackReportApi,
	getWeeklyKrGrowthReportApi,
	getLeastRiskOkrReportApi,
	getLeastRiskKrReportApi,
	getTeamsPerformanceApi,
	getTeamsProgressApi,
	getLeadingTeamsApi,
	getOrgReportsApi,
	getMyOkrProgressReportsApi,
	getEngagementOverallPerformance,
	getTeamOkrProgressReportsApi,
	getTeamEngagementOverallProgressApi,
	getLeadingTeamsReportsApi,
	getMyLeadingTeamsApi,
} from '../services/ReportService';
import * as actionTypes from './actionTypes';
import { showLoader } from './common';
export const getAvgOkrReport = (data) => {
	return () => {
		return getAvgOkrReportApi(data);
	};
};

export const getMostWeeklyKrReport = (data) => {
	return () => {
		return getMostWeeklyKrReportApi(data);
	};
};

export const getMostFeedbackReport = (data) => {
	return () => {
		return getMostFeedbackReportApi(data);
	};
};

export const getWeeklyKrGrowthReport = (data) => {
	return () => {
		return getWeeklyKrGrowthReportApi(data);
	};
};

export const getLeastRiskOkrReport = (data) => {
	return () => {
		return getLeastRiskOkrReportApi(data);
	};
};

export const getLeastRiskKrReport = (data) => {
	return () => {
		return getLeastRiskKrReportApi(data);
	};
};

export const getTeamPerformance = (data) => {
	return () => {
		return getTeamsPerformanceApi(data);
	};
};

/** Get Team Progress */

const fetchTeamProgressPending = () => ({
	type: actionTypes.FETCH_TEAM_PROGRESS_PENDING,
});
const fetchTeamProgressCompleted = (payload) => ({
	type: actionTypes.FETCH_TEAM_PROGRESS_SUCCESS,
	payload,
});
const fetchTeamProgressFailed = (error) => ({
	type: actionTypes.FETCH_TEAM_PROGRESS_FAILED,
	error,
});

export const getTeamProgressDetails = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchTeamProgressPending());
		return getTeamsProgressApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchTeamProgressCompleted(response.data));
				} else {
					dispatch(fetchTeamProgressFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchTeamProgressFailed(error));
				dispatch(showLoader(false));
			});
	};
};

/** Get Leading team  */
const fetchLeadingTeamPending = () => ({
	type: actionTypes.FETCH_LEADING_TEAM_PENDING,
});
const fetchLeadingTeamCompleted = (payload) => ({
	type: actionTypes.FETCH_LEADING_TEAM_SUCCESS,
	payload,
});
const fetchLeadingTeamFailed = (error) => ({
	type: actionTypes.FETCH_LEADING_TEAM_FAILED,
	error,
});

export const getLeadingTeamsDetails = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchLeadingTeamPending());
		return getLeadingTeamsApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchLeadingTeamCompleted(response.data));
				} else {
					dispatch(fetchLeadingTeamFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchLeadingTeamFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const resetLeadingTeam = (data) => {
	return {
		type: actionTypes.RESET_LEADING_TEAM,
	};
};

/** Get Org Report  */
const fetchOrgReportsPending = () => ({
	type: actionTypes.FETCH_ORG_REPORTS_PENDING,
});
const fetchOrgReportsCompleted = (payload) => ({
	type: actionTypes.FETCH_ORG_REPORTS_SUCCESS,
	payload,
});
const fetchOrgReportsFailed = (error) => ({
	type: actionTypes.FETCH_ORG_REPORTS_FAILED,
	error,
});

export const getOrgReportsDetails = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchOrgReportsPending());
		return getOrgReportsApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchOrgReportsCompleted(response.data));
				} else {
					dispatch(fetchOrgReportsFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchOrgReportsFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getOkrProgressDetails = (data) => {
	return () => {
		return getMyOkrProgressReportsApi(data);
	};
};

export const getEngagementOverallProgress = (data) => {
	return () => {
		return getEngagementOverallPerformance(data);
	};
};
export const getTeamOkrProgressDetails = (data) => {
	return () => {
		return getTeamOkrProgressReportsApi(data);
	};
};

export const getTeamEngagementOverallProgress = (data) => {
	return () => {
		return getTeamEngagementOverallProgressApi(data);
	};
};

/** Get Org Report  */
const fetchLeadingTeamReportsPending = () => ({
	type: actionTypes.FETCH_LEADING_TEAM_REPORTS_PENDING,
});
const fetchLeadingTeamReportsCompleted = (payload) => ({
	type: actionTypes.FETCH_LEADING_TEAM_REPORTS_SUCCESS,
	payload,
});
const fetchLeadingTeamReportsFailed = (error) => ({
	type: actionTypes.FETCH_LEADING_TEAM_REPORTS_FAILED,
	error,
});

export const getLeadingTeamsReports = (data) => {
	return (dispatch) => {
		dispatch(fetchLeadingTeamReportsPending());
		return getLeadingTeamsReportsApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchLeadingTeamReportsCompleted(response.data));
				} else {
					dispatch(fetchLeadingTeamReportsFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchLeadingTeamReportsFailed(error));
			});
	};
};

/** Get my Leading team  */
const fetchMyLeadingTeamPending = () => ({
	type: actionTypes.FETCH_MY_LEADING_TEAM_PENDING,
});
const fetchMyLeadingTeamCompleted = (payload) => ({
	type: actionTypes.FETCH_MY_LEADING_TEAM_SUCCESS,
	payload,
});
const fetchMyLeadingTeamFailed = (error) => ({
	type: actionTypes.FETCH_MY_LEADING_TEAM_FAILED,
	error,
});

export const getMyLeadingTeamsDetails = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchMyLeadingTeamPending());
		return getMyLeadingTeamsApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchMyLeadingTeamCompleted(response.data));
				} else {
					dispatch(fetchMyLeadingTeamFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchMyLeadingTeamFailed(error));
				dispatch(showLoader(false));
			});
	};
};

import React, { useState } from 'react';
import { MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../Common/Dialog';
import { checkPermission } from '../../../config/rolePermission';
import { createTeams, editMainOrganization, editTeams } from '../AdminConstant';
import { OkrIcon } from '../../../config/svg/CommonSvg';
export default function OrganizationMenu(props) {
	const {
		open,
		handleClose,
		anchorEl,
		currentOrg,
		openDrawer,
		getPermissionResult,
		openEditOrgDrawer,
		handleViewOrganisation,
	} = props;
	const { t } = useTranslation();
	const openOrgDrawer = (type, header, item) => {
		handleClose();
		openDrawer(type, header, item);
	};
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '' });

	const handleAddNewUser = (e) => {
		if (props.licenseDetails && props.licenseDetails.isAddUserAllow) {
			openOrgDrawer('addUser', 'Add User', currentOrg);
		} else {
			setModalProps({
				open: true,
				type: 'addUser',
				isCancel: true,
				message: props.licenseDetails && props.licenseDetails.note,
			});
		}
	};
	const handleCloseModal = () => {
		setModalProps({ open: false, type: '', message: '' });
	};
	return (
		<>
			<Popper
				className='popper-menu-list'
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				anchorEl={anchorEl}
				transition
				placement='bottom-end'
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<ClickAwayListener onClickAway={handleClose}>
							<Paper>
								{props.isOrgDashboard ? (
									<MenuList className='org-menu-list' autoFocusItem={open} id='org-menu-list-grow'>
										<MenuItem id={'view-okr-list'} onClick={(e) => handleViewOrganisation(e, currentOrg)}>
											<OkrIcon /> {t('viewOkr')}
										</MenuItem>
									</MenuList>
								) : (
									<MenuList autoFocusItem={open} id='menu-list-grow'>
										{checkPermission(getPermissionResult.employeePermissions, editMainOrganization) &&
										currentOrg.parentId === 0 ? (
											<MenuItem id={'edit-org'} onClick={(e) => openEditOrgDrawer(e, 'editOrg')}>
												{t('editLabel')}
											</MenuItem>
										) : checkPermission(getPermissionResult.employeePermissions, editTeams) &&
										  currentOrg.parentId !== 0 ? (
											<MenuItem id={'edit-org'} onClick={() => openOrgDrawer('editChild', 'Edit Child', currentOrg)}>
												{t('editLabel')}
											</MenuItem>
										) : (
											<></>
										)}
										{checkPermission(getPermissionResult.employeePermissions, createTeams) ? (
											<MenuItem id={'add-team'} onClick={() => openOrgDrawer('addChild', 'Add Child', currentOrg)}>
												{t('addTeamLabel')}
											</MenuItem>
										) : (
											<></>
										)}
										{/* <MenuItem onClick={(e) => handleAddNewUser(e)}>{t('addUsers')}</MenuItem> */}
									</MenuList>
								)}
							</Paper>
						</ClickAwayListener>
					</Grow>
				)}
			</Popper>
			{modalProps.open && (
				<AlertDialog
					module='user'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
					isCancel={true}
				/>
			)}
		</>
	);
}

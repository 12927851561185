import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import store from './store/configureStore';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import i18n from './config/i18n';
import App from './App.js';
import { SnackbarProvider } from 'notistack';
import { CancelIcon } from './config/svg/Action';
import { Button } from '@material-ui/core';

const customHistory = createBrowserHistory(); //This maintains custom history
const notiStackRef = React.createRef();
const onClickDismiss = (key) => () => {
	notiStackRef.current.closeSnackbar(key);
};

render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<Router history={customHistory}>
				{/* <NewRelic licenseKey='0e7f46013c7a61d4d7d9f3f8285023eab375NRAL' applicationID='unlockokr-dev' /> */}
				<SnackbarProvider
					hideIconVariant
					className='custom-snackbar'
					maxSnack={3}
					ref={notiStackRef}
					classes={{
						containerRoot: 'custom-snackbar',
						variantSuccess: 'custom-snackbar-success',
						variantError: 'custom-snackbar-error',
						variantWarning: 'custom-snackbar-warning',
						variantInfo: 'custom-snackbar-info',
					}}
					action={(key) => (
						<Button className='cancel-btn' onClick={onClickDismiss(key)}>
							<CancelIcon />
						</Button>
					)}
				>
					<App />
				</SnackbarProvider>
			</Router>
		</I18nextProvider>
	</Provider>,
	document.getElementById('okr-app')
);

import {
	Box,
	Tabs,
	Tab,
	Typography,
	FormLabel,
	Avatar,
	Chip,
	TextField,
	InputAdornment,
	Switch,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { ManageUserDrawerForm } from '../../AddRolePermission/ManageUserDrawerForm';
import { UserAvatarGroup } from '../../CommonComponent/UserAvatarGroup';
import { OKRButton } from '../../../Common/OKRButton';
import { TextInlineEdit } from '../../CommonComponent/TextInlineEdit';
import { CameraIcon, CrossIcon, EditIcon, NoOrgImgIcon } from '../../../../config/svg/CommonSvgIcon';
import { CalendarIcon, CheckInReminderIcon, ErrorIcon, TimerIcon } from '../../../../config/svg/formElementIcons';
import '../../../../styles/pages/admin/textInlineEdit.scss';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../../config/utils';
import { DatePickerField } from '../../CommonComponent/DatePickerField';
import { QuickSearchPopover } from '../../CommonComponent/QuickSearchPopover';
import { Enums } from '../../../../config/enums';
import { EmailTriggerIcon } from '../../../../config/svg/formElementIcons';

export const EditOrganizationForm: React.FC<any> = (props) => {
	const {
		selectedAddNewUserTab,
		handleTabChange,
		listOrgResult,
		fetchOrganizationsList,
		handleDeleteUser,
		searchUser,
		handleAssignModal,
		searchEmployeeText,
		assignedUser,
		showImageEditor,
		handleOrganisationFieldsChange,
		onCycleSelect,
		organisationDetails,
		cycleList,
		selectedCycle,
		handleRemoveOrgHead,
		handleOrgHead,
		errors,
		parentOrganisation,
		zeroNotAllowed,
		cadenceDaysError,
		setZeroNotAllowed,
		emailPlanningPeriodError,
		emailExecutionPeriodError,
		handleOrganisationToggleChange,
	} = props;
	const { t } = useTranslation();
	const [inlineEdit, setInlineEdit] = React.useState(false);

	function a11yProps(index: number) {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	}
	const handleDelete = () => {};
	const handleClick = () => {
		setInlineEdit(true);
	};
	useEffect(() => {
		if (!listOrgResult || listOrgResult.length === 0) {
			fetchOrganizationsList();
		}
	}, [listOrgResult]);

	return (
		<Fragment>
			<Tabs
				className='okr-tabs drawer-tabs'
				value={selectedAddNewUserTab}
				onChange={handleTabChange}
				aria-label='Admin Tabs'
			>
				<Tab label={t('editOrgDetailsLabel')} value={0} {...a11yProps(0)} />
				{/* {assignedUser.length > -1 && <Tab label={`${t('manageMembers')}`} value={1} {...a11yProps(1)} />} */}
				{(assignedUser?.length > 0 || (parentOrganisation?.employeeCount && parentOrganisation?.employeeCount > 0)) && (
					<Tab
						label={`${t('manageMembers')} 
							${parentOrganisation?.employeeCount > 0 ? '(' : ''}${
							parentOrganisation?.employeeCount > 0 ? parentOrganisation?.employeeCount : ''
						}${parentOrganisation?.employeeCount > 0 ? ')' : ''}`}
						value={1}
						{...a11yProps(1)}
					/>
				)}
			</Tabs>

			<Box className='drawer-tabs-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{selectedAddNewUserTab === 0 && (
						<>
							<Box className='drawer-input-field'>
								<Box className='user-team-detail'>
									<div className='team-logo-wrapper' id='team-avatar' onClick={() => showImageEditor(true)}>
										{organisationDetails.teamName && organisationDetails.logoImagePath ? (
											<Avatar
												id='team-logo'
												className='team-logo'
												key={`user-icon-${organisationDetails.teamId}`}
												src={organisationDetails.logoImagePath}
											>
												{organisationDetails.teamName
													? getUserName({
															firstName: '',
															lastName: '',
															fullName: organisationDetails.teamName,
													  })
													: ''}
											</Avatar>
										) : (
											<>
												{props.uploadedImage ? (
													<img
														className='org-logo'
														src={props.uploadedImage ? props.uploadedImage : ''}
														// alt={orgData.logoName ? orgData.logoName : ''}
													/>
												) : (
													<Avatar id='team-logo' className='team-logo no-org-image' key={`user-icon-`} src={''}>
														{organisationDetails.teamName ? (
															getUserName({
																firstName: '',
																lastName: '',
																fullName: organisationDetails.teamName,
															})
														) : (
															<NoOrgImgIcon />
														)}
													</Avatar>
												)}
											</>
										)}

										<div className='camera-icon' onClick={() => showImageEditor(true)}>
											<label htmlFor='file-input' className='upload-image-label'>
												<CameraIcon />
											</label>
										</div>
									</div>
									<Box className='team-info'>
										<Box className='title-inline-field'>
											{!inlineEdit && (
												<Box className='title-field' id='title-field'>
													<Typography variant='h4'>
														{organisationDetails.teamName}
														<OKRButton
															handleClick={(e) => handleClick()}
															className='editIcon'
															id='edit-btn'
															icon={<EditIcon />}
															title={t('editTitleLabel')}
															tooltipPlacement={'top'}
														/>
													</Typography>
												</Box>
											)}
											{inlineEdit && (
												<TextInlineEdit
													id='team-name-inline'
													{...props}
													inlineEdit={inlineEdit}
													setInlineEdit={setInlineEdit}
													errorText={t('organisationFieldValidationText')}
													parentName={organisationDetails.teamName}
													updateValue={(value: any) => {
														props.handleOrgNameChange(value);
													}}
												/>
											)}
										</Box>
										<Box className='leader-detail'>
											{organisationDetails.teamHeadName ? (
												<Chip
													avatar={
														<Avatar src={organisationDetails.teamHeadImage || ''}>
															{getUserName({
																firstName: '',
																lastName: '',
																fullName: organisationDetails.teamHeadName,
															})}
														</Avatar>
													}
													label={organisationDetails.teamHeadName}
													className='chip-avatar'
													deleteIcon={
														<span>
															<CrossIcon />
														</span>
													}
													onDelete={handleRemoveOrgHead}
												/>
											) : (
												<QuickSearchPopover
													{...props}
													leader={organisationDetails.teamHeadName}
													onSelectedUser={(user: any) => {
														handleOrgHead(user);
													}}
												/>
											)}
											{errors.organisationHead && (
												<Typography className='search-error'>
													<ErrorIcon />
													{errors.organisationHead}
												</Typography>
											)}
											{errors.leader && (
												<Typography className='search-error'>
													{' '}
													<ErrorIcon />
													{errors.leader}
												</Typography>
											)}
										</Box>
									</Box>
								</Box>
							</Box>

							<Box className='drawer-blue-bg-full'>
								<Box className='drawer-form-fields-group'>
									<Box className='drawer-input-field drawer-input-field-col2'>
										<Box className='field-col2 helperText-bottom'>
											<FormLabel className='label-disabled'>
												<CalendarIcon />
												{t('timeFrameLabel')}
											</FormLabel>
											<MultiSelect
												key={'rolesIdDropdown'}
												disabled={true}
												id='roles-id-dropdown'
												selectedOptions={selectedCycle}
												optionsList={cycleList}
												onSelectOption={(value: any) => {
													onCycleSelect(value);
												}}
												customFilter={(option: any, searchText: any) => {
													if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
														return true;
													}
												}}
												placeHolder={t('timeFramePlaceholdar')}
												noOptionsMessage={<NoSearchRecord />}
												//showNoOptions={emptyRecords.roleId === true}
												fetchAsyncData={false}
												isSingleSelection={false}
												performSearch={() => {}}
												closeMenuOnSelect={true}
												labelTemplate={'onlyLabel'}
												isMulti={false}
												selectClassName={`dropdown-default-wrapper`}
												selectClassNamePrefix={'dropdown-default'}
												disableSearch={true}
											/>
										</Box>
										<Box className='field-col2 helperText-bottom'>
											<DatePickerField {...props} label={t('okrStartFrom')} disabled={true} />
										</Box>
									</Box>
									<Box className='drawer-input-field drawer-input-field-col2'>
										<Box className='field-col2 helperText-bottom'>
											<TextField
												id='gracePeriod'
												className=''
												fullWidth
												label={
													<Fragment>
														<TimerIcon />
														{t('gracePeriodLabel')}
													</Fragment>
												}
												placeholder={t('gracePeriodPlaceholdar')}
												value={organisationDetails.gracePeriod}
												name='gracePeriod'
												InputLabelProps={{
													shrink: true,
												}}
												type='number'
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															{
																<>
																	{t('daysLabel')}
																	<span className='days-short-text'>{t('max90Days')}</span>
																</>
															}
														</InputAdornment>
													),
												}}
												onChange={(e) => {
													let value = parseInt(e.target.value) || 0;
													value >= 0 && value <= Enums.GRACE_PERIOD && handleOrganisationFieldsChange(e);
												}}
												onBlur={(e) => {
													props.handleOrganisationFieldsBlur(e);
												}}
											/>
											<Typography className='short-des-text'>{t('gracePeriodShortText')}</Typography>
										</Box>
										<Box className='field-col2 helperText-bottom'>
											<TextField
												id='cadencsNotification'
												fullWidth
												className=''
												label={
													<Fragment>
														<CheckInReminderIcon />
														{t('cadencsNotificationLabel')}
													</Fragment>
												}
												placeholder={t('cadencsNotificationPlaceholdar')}
												value={organisationDetails?.cadenceDays}
												name='cadenceDays'
												InputLabelProps={{
													shrink: true,
												}}
												type='number'
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<>
																{t('daysLabel')}
																<span className='days-short-text'>{t('max30Days')}</span>
															</>
														</InputAdornment>
													),
												}}
												onChange={(e) => {
													let value = parseInt(e.target.value) || 0;
													value >= 0 &&
														value <= Enums.MAX_CADENCE_FOR_CHECKIN_NOTIFICATON &&
														handleOrganisationFieldsChange(e);
												}}
												onBlur={(e) => {
													props.handleOrganisationFieldsBlur(e);
												}}
												error={
													(cadenceDaysError?.type === 'cadenceDays' &&
														zeroNotAllowed === true &&
														cadenceDaysError?.error) ||
													''
												}
												helperText={
													(cadenceDaysError?.type === 'cadenceDays' &&
														zeroNotAllowed === true &&
														cadenceDaysError?.helperText && (
															<>
																<ErrorIcon />
																{cadenceDaysError?.helperText}
															</>
														)) ||
													''
												}
											/>
											<Typography className='short-des-text'>{t('cadencsNotificationShortText')}</Typography>
										</Box>
									</Box>
								</Box>
								<Box className='drawer-form-fields-group'>
									<Box className='drawer-input-field drawer-input-field-col2'>
										<Box className='field-col2 helperText-bottom'>
											<TextField
												id='emailPlanningPeriod'
												fullWidth
												className='field-label-twocol'
												label={
													<Fragment>
														<Box>
															<EmailTriggerIcon />
															{t('planningPeriodLabel')}
														</Box>
														<Switch
															id='isEmailPlanningPeriod'
															name='isEmailPlanningPeriod'
															checked={Boolean(organisationDetails?.isEmailPlanningPeriod)}
															onChange={handleOrganisationToggleChange}
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</Fragment>
												}
												placeholder={t('planningPeriodLabel')}
												value={organisationDetails?.emailPlanningPeriod || Enums.ZERO}
												name='emailPlanningPeriod'
												InputLabelProps={{
													shrink: true,
												}}
												type='number'
												InputProps={{
													disabled: !organisationDetails?.isEmailPlanningPeriod,
													endAdornment: (
														<InputAdornment position='end'>
															<>
																{t('daysLabel')}
																<span className='days-short-text'>{t('max30Days')}</span>
															</>
														</InputAdornment>
													),
												}}
												onChange={(e) => {
													let value = parseInt(e.target.value) || Enums.ZERO;
													value >= Enums.ZERO &&
														value <= Enums.MAX_PERIOD_FOR_EMAIL_TRIGGER &&
														handleOrganisationFieldsChange(e);
												}}
												onBlur={(e) => {
													props.handleOrganisationFieldsBlur(e);
												}}
												error={
													(emailPlanningPeriodError?.type === 'emailPlanningPeriod' &&
														zeroNotAllowed === true &&
														emailPlanningPeriodError?.error) ||
													''
												}
												helperText={
													(emailPlanningPeriodError?.type === 'emailPlanningPeriod' &&
														zeroNotAllowed === true &&
														emailPlanningPeriodError?.helperText && (
															<>
																<ErrorIcon />
																{emailPlanningPeriodError?.helperText}
															</>
														)) ||
													''
												}
											/>
											<Typography className='short-des-text'>{t('planningPeriodDes')}</Typography>
										</Box>
										<Box className='field-col2 helperText-bottom'>
											<TextField
												id='emailExecutionPeriod'
												fullWidth
												className='field-label-twocol'
												label={
													<Fragment>
														<Box>
															<EmailTriggerIcon />
															{t('ExecutionPeriodLabel')}
														</Box>
														<Switch
															id='isEmailExecutionPeriod'
															name='isEmailExecutionPeriod'
															checked={Boolean(organisationDetails?.isEmailExecutionPeriod)}
															onChange={handleOrganisationToggleChange}
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</Fragment>
												}
												placeholder={t('ExecutionPeriodLabel')}
												value={organisationDetails?.emailExecutionPeriod || Enums.ONE}
												name='emailExecutionPeriod'
												InputLabelProps={{
													shrink: true,
												}}
												type='number'
												InputProps={{
													disabled: !organisationDetails?.isEmailExecutionPeriod,
													endAdornment: (
														<InputAdornment position='end'>
															<>
																{t('daysLabel')}
																<span className='days-short-text'>{t('max30Days')}</span>
															</>
														</InputAdornment>
													),
												}}
												onChange={(e) => {
													let value = parseInt(e.target.value) || Enums.ONE;
													value >= Enums.ONE &&
														value <= Enums.MAX_PERIOD_FOR_EMAIL_TRIGGER &&
														handleOrganisationFieldsChange(e);
												}}
												onBlur={(e) => {
													props.handleOrganisationFieldsBlur(e);
												}}
												error={
													(emailExecutionPeriodError?.type === 'emailExecutionPeriod' &&
														zeroNotAllowed === true &&
														emailExecutionPeriodError?.error) ||
													''
												}
												helperText={
													(emailExecutionPeriodError?.type === 'emailExecutionPeriod' &&
														zeroNotAllowed === true &&
														emailExecutionPeriodError?.helperText && (
															<>
																<ErrorIcon />
																{emailExecutionPeriodError?.helperText}
															</>
														)) ||
													''
												}
											/>
											<Typography className='short-des-text'>{t('ExecutionPeriodDes')}</Typography>
										</Box>
									</Box>
								</Box>
								<Box className='drawer-form-fields-group'>
									<Box className='drawer-input-field'>
										<UserAvatarGroup
											{...props}
											subTitle={t('addMembersLabel')}
											helpText={t('addMembersShortText')}
											handleAddUserClick={handleAssignModal}
											assignedUserDetails={
												assignedUser.length > 0 && assignedUser.map((item: any, index: number) => item)
											}
											max={6}
										/>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{selectedAddNewUserTab === 1 && (
						<ManageUserDrawerForm
							t={t}
							{...props}
							searchEmployeeText={searchEmployeeText}
							searchUser={searchUser}
							handleAssignModal={handleAssignModal}
							handleDeleteUser={handleDeleteUser}
							view={'editOrganisation'}
						/>
					)}
				</form>
			</Box>
		</Fragment>
	);
};

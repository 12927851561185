import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NouserImg from '../../../images/search-no-colleagues.svg';

export const NoRecord: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { noRecordMessage } = props;

	return (
		<Box className='user-no-record'>
			{noRecordMessage ? (
				<Typography variant='h4'>{noRecordMessage}</Typography>
			) : (
				<Typography variant='h4'>{t('SearchText1')}</Typography>
			)}
			{/* <Typography variant='h6' className='no-sentence-case'>
				{t('SearchText2')}
			</Typography> */}
			<AnimatedIcon className='' width='250' type='image/svg+xml' alt='No user' imagePath={NouserImg} />
		</Box>
	);
};

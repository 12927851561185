import React, { Fragment, useState } from 'react';
import moment from 'moment';
import '../style.scss';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { GoalAttributeDetail } from './GoalAttributeDetail';
import AlertDialog from '../../Common/Dialog';
import { formatDate, getUserDetails } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { checkPermission } from '../../../config/rolePermission';
import { allowCreateOkrsOnBehalfOfAnotherPerson } from '../../Admin/AdminConstant';
interface GoalAttributeModalProps {
	open: boolean;
	type: String;
	message: String;
	module: String;
	date: any;
	isCancel?: any;
	data?: any;
}

export const GoalAttributes: React.FC<any> = (props) => {
	const {
		open,
		handleDrawerClose,
		okrMasterData,
		t,
		durationCycleDetail,
		currentCycleEndDate,
		objectiveDetail,
		isGoalAttributeUpdated,
		goalAttrDateError,
		setIsSaveDisabled,
		setGoalsAttribute,
		goalsAttribute,
		setGaolAttrDateError,
		loader,
		goalDetail,
		keyData,
		parentKeyAttribute,
		getPermissionResult,
		resToUseInCalendar,
		editModee,
		setEditModee,
	} = props;
	const [goalAttributeModalProps, setGoalAttributeModalProps] = useState<GoalAttributeModalProps>({
		open: false,
		type: '',
		message: '',
		module: '',
		date: '',
		isCancel: false,
	});
	const [updateModalProps, setUpdateModalProps] = useState<boolean>(false);
	const [isCoachValue, setIsCoachValue] = useState<boolean>(
		goalDetail.actionType === 'editGoal' && goalsAttribute && goalsAttribute.isCoach
			? goalsAttribute.isCoach
			: goalDetail.actionType !== 'editGoal' && objectiveDetail?.isCoach
			? objectiveDetail.isCoach
			: false
	);
	const resetContributor = () => {
		props.updateFormDetail && props.updateFormDetail(keyData, '', '', true, parentKeyAttribute, true);
	};
	const changeOwnerApi = async () => {
		const apiRes: any = await props.changeOwnerData(
			'?goalObjectiveId=' + goalsAttribute.goalObjectiveId + '&newOwner=' + goalsAttribute.owner
		);
		if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
			setGoalAttributeModalProps({ open: false, type: '', message: '', module: '', date: '' });
			handleDrawerClose({ ownerChanged: true }, 'save');
		}
	};

	const handleCloseGoalAttributeModal = (_event: any, type: Number) => {
		if (type === 1) {
			if (goalAttributeModalProps.type === 'owner') {
				changeOwnerApi();
			} else if (goalAttributeModalProps.type === 'KRAttr') {
				handleDrawerClose({}, 'close');
			} else if (goalAttributeModalProps.type === '1') {
				updateStartDate(goalAttributeModalProps.date);
			} else if (goalAttributeModalProps.type === '2') {
				updateDueDate(goalAttributeModalProps.date, '');
			}
		}
		if (goalAttributeModalProps.type !== 'owner' || type !== 1) {
			setGoalAttributeModalProps({ open: false, type: '', message: '', module: '', date: '' });
		}
	};
	const updateGoalDueDate = (date: any, type: Boolean, start: any) => {
		if (type) {
			const { okr } = goalDetail;
			const updatedData = okr.myGoalsDetails.find((item: any) => {
				const datesDifferenceInDays = moment(new Date(item.dueDate || new Date())).isAfter(
					new Date(formatDate(date) || new Date()),
					'days'
				);
				return datesDifferenceInDays ? item : null;
			});

			let contributerDatesMismatch = false;
			for (let ii = 0; ii < okr?.myGoalsDetails?.length; ii++) {
				for (let jj = 0; jj < okr?.myGoalsDetails[ii]?.contributors?.length; jj++) {
					let datesDifference = moment(
						new Date(okr?.myGoalsDetails[ii]?.contributors[jj]?.dueDate || new Date())
					).isAfter(new Date(formatDate(date) || new Date()), 'days');
					if (datesDifference) {
						contributerDatesMismatch = true;
						break;
					}
				}
			}

			if (contributerDatesMismatch) {
				setGoalAttributeModalProps({
					open: true,
					type: '',
					message: t('contributerDateMismatch'),
					module: '',
					date,
					isCancel: true,
				});
				return false;
			}

			if (updatedData) {
				setGoalAttributeModalProps({
					open: true,
					type: '2',
					message: t('goalDueDateUpdate'),
					module: '',
					date,
				});
			} else {
				updateDueDate(date, start);
			}
		} else {
			updateDueDate(date, start);
			setGoalAttributeModalProps({ open: false, type: '', message: '', module: '', date: '' });
		}
	};
	const updateGoalDateRangeCycleChangeUpdate = (cycleDetailData: any) => {
		setIsSaveDisabled(false);
		setGaolAttrDateError({});
		const updatedData = objectiveDetail.myGoalsDetails.map((item: any) => {
			return {
				...item,
				isTyped: false,
				startDate: formatDate(cycleDetailData?.startDate),
				dueDate: formatDate(cycleDetailData?.endDate),
			};
		});

		setGoalsAttribute({
			...goalsAttribute,
			myGoalsDetails: [...updatedData],
			isTyped: true,
			cycleDetailData: cycleDetailData || goalsAttribute.cycleDetailData,
			startDate: cycleDetailData?.startDate,
			objectiveCycleId: cycleDetailData?.organisationCycleId
				? cycleDetailData.organisationCycleId
				: objectiveDetail.objectiveCycleId,
			year: cycleDetailData?.year,
			symbol: cycleDetailData?.symbol,
			endDate: cycleDetailData?.endDate,
			dueDate: cycleDetailData?.endDate,
		});
	};
	const updateDueDate = (date: any, start: any) => {
		setIsSaveDisabled(false);
		setGaolAttrDateError({});

		const updatedData = objectiveDetail.myGoalsDetails.map((item: any) => {
			return {
				...item,
				isTyped: false,
				startDate: formatDate(start),
				dueDate: formatDate(date),
			};
		});
		setGoalsAttribute({
			...goalsAttribute,
			startDate: formatDate(start),
			endDate: formatDate(date),
			dueDate: formatDate(date),
			isTyped: true,
			myGoalsDetails: [...updatedData],
		});
	};
	const updateGoalStartDate = (date: any, type: Boolean) => {
		if (type) {
			const { okr } = goalDetail;
			const updatedData = okr.myGoalsDetails.find((item: any) => {
				const datesDifferenceInDays = moment(new Date(item.startDate || new Date())).isBefore(
					new Date(formatDate(date) || new Date()),
					'days'
				);
				return datesDifferenceInDays ? item : null;
			});

			let contributerDatesMismatch = false;
			for (let ii = 0; ii < okr?.myGoalsDetails?.length; ii++) {
				for (let jj = 0; jj < okr?.myGoalsDetails[ii]?.contributors?.length; jj++) {
					let datesDifference = moment(
						new Date(okr?.myGoalsDetails[ii]?.contributors[jj]?.startDate || new Date())
					).isBefore(new Date(formatDate(date) || new Date()), 'days');

					if (datesDifference) {
						contributerDatesMismatch = true;
						break;
					}
				}
			}

			if (contributerDatesMismatch) {
				setGoalAttributeModalProps({
					open: true,
					type: '',
					message: t('contributerDateMismatch'),
					module: '',
					date,
					isCancel: true,
				});
				return false;
			}

			if (updatedData) {
				setGoalAttributeModalProps({
					open: true,
					type: '1',
					message: t('goalStartDateUpdate'),
					module: '',
					date,
				});
			} else {
				updateStartDate(date);
			}
		} else {
			updateStartDate(date);
			setGoalAttributeModalProps({ open: false, type: '', message: '', module: '', date: '' });
		}
	};
	const updateStartDate = (date: any) => {
		setIsSaveDisabled(false);
		setGaolAttrDateError({});

		const updatedData = objectiveDetail.myGoalsDetails.map((item: any) => {
			return {
				...item,
				startDate: formatDate(date),
			};
		});
		setGoalsAttribute({ ...goalsAttribute, startDate: formatDate(date), myGoalsDetails: [...updatedData] });
	};

	const checkIfOwnerChanged = (event: any) => {
		handleDrawerClose(event, 'save');
	};

	const isOKRUpdated = (event: any) => {
		if (isGoalAttributeUpdated(true) === false) {
			setGoalAttributeModalProps({
				open: true,
				type: 'KRAttr',
				message: t('unSavedItemAlert'),
				module: 'user',
				date: '',
			});
		} else {
			handleDrawerClose(event, 'close');
		}
	};

	const closeCalled = (event: any) => {
		if (!goalsAttribute.goalObjectiveId) {
			handleDrawerClose(event, 'close');
		} else {
			isOKRUpdated(event);
		}
	};

	const toggleClicked = (event: any) => {
		setIsCoachValue(event.target.checked);
		setUpdateModalProps(true);
	};
	const [showSubmitCallouts, setShowSubmitCallouts] = useState(false);
	const [showPrevForSubmitCallouts, setShowPrevForSubmitCallouts] = useState(false);

	const handlePrevForSubmit = () => {
		setShowPrevForSubmitCallouts(true);
	};
	return (
		<Fragment>
			<OKRDrawer
				open={open}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('goalAttributeText')}
				loader={loader}
				showToggle={
					(!goalsAttribute.goalStatusId || goalsAttribute.goalStatusId === Enums.GOAL_STATUS_DRAFT) &&
					checkPermission(getPermissionResult.employeePermissions, allowCreateOkrsOnBehalfOfAnotherPerson)
				}
				toggleText={{
					mainLabel: t('okrCreation'),
					label: t('coach'),
				}}
				toggleValue={goalsAttribute.isCoach}
				toggleClicked={toggleClicked}
				children={
					<GoalAttributeDetail
						okrMasterData={okrMasterData}
						t={t}
						durationCycleDetail={durationCycleDetail}
						currentCycleEndDate={currentCycleEndDate}
						objectiveDetail={objectiveDetail}
						goalAttrDateError={goalAttrDateError}
						setIsSaveDisabled={setIsSaveDisabled}
						setGoalsAttribute={setGoalsAttribute}
						goalsAttribute={goalsAttribute}
						setGaolAttrDateError={setGaolAttrDateError}
						setGoalAttributeModalProps={setGoalAttributeModalProps}
						updateGoalDueDate={updateGoalDueDate}
						updateGoalStartDate={updateGoalStartDate}
						updateStartDate={updateStartDate}
						updateDueDate={updateDueDate}
						resetContributor={resetContributor}
						updateModalProps={updateModalProps}
						setUpdateModalProps={setUpdateModalProps}
						isCoachValue={isCoachValue}
						setIsCoachValue={setIsCoachValue}
						setShowSubmitCallouts={setShowSubmitCallouts}
						showPrevForSubmitCallouts={showPrevForSubmitCallouts}
						setShowPrevForSubmitCallouts={setShowPrevForSubmitCallouts}
						{...props}
						isCoachAccess={checkPermission(
							getPermissionResult.employeePermissions,
							allowCreateOkrsOnBehalfOfAnotherPerson
						)}
						resToUseInCalendar={resToUseInCalendar}
						editModee={editModee}
						setEditModee={setEditModee}
						updateGoalDateRangeCycleChangeUpdate={updateGoalDateRangeCycleChangeUpdate}
					/>
				}
				moreButton={<></>}
				handleDrawerClose={(event: any) => {
					props.updateCalloutPanel('');
					closeCalled(event);
				}}
				handleSaveClick={(event: any) => {
					props.updateCalloutPanel('');
					checkIfOwnerChanged(event);
				}}
				handleCancelClick={(event: any) => {
					props.updateCalloutPanel('');
					handleDrawerClose(event, 'close');
				}}
				hideDefaultButton={false}
				isSaveButtonDisabled={isGoalAttributeUpdated()}
				showSubmitCallouts={showSubmitCallouts}
				setShowSubmitCallouts={setShowSubmitCallouts}
				handlePrevForSubmit={handlePrevForSubmit}
				activeOnboardingPanel={props.activeOnboardingPanel}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{goalAttributeModalProps.open && (
				<AlertDialog
					module={goalAttributeModalProps.module ? goalAttributeModalProps.module : 'goals'}
					message={goalAttributeModalProps.message}
					handleCloseModal={handleCloseGoalAttributeModal}
					modalOpen={goalAttributeModalProps.open}
					isCancel={goalAttributeModalProps?.isCancel || false}
				/>
			)}
		</Fragment>
	);
};

import * as actionTypes from './actionTypes';
import {
	alignmentMapAPI,
	getChildByObjective,
	getAllParentByObjective,
	alignmentMapGraphAPI,
	peopleMapAPI,
	alignmentTeamMapAPI,
	alignmentDirectsReportsMapAPI,
	updateDueDateAPI,
} from '../services/OkrService';
import { showLoader } from './common';

const fetchAlignmentPending = () => ({
	type: actionTypes.FETCH_ALIGN_PENDING,
});
const fetchAlignmentCompleted = (payload) => ({
	type: actionTypes.FETCH_ALIGN_SUCCESS,
	payload,
});
const fetchAlignmentFailed = (error) => ({
	type: actionTypes.FETCH_ALIGN_FAILED,
	error,
});

export const getAlignmentList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchAlignmentPending());
		return alignmentMapAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchAlignmentCompleted(response.data));
				} else {
					dispatch(fetchAlignmentFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchAlignmentFailed(error));
				dispatch(showLoader(false));
			});
	};
};

const fetchTeamAlignmentPending = () => ({
	type: actionTypes.FETCH_TEAM_ALIGN_PENDING,
});
const fetchTeamAlignmentCompleted = (payload) => ({
	type: actionTypes.FETCH_TEAM_ALIGN_SUCCESS,
	payload,
});
const fetchTeamAlignmentFailed = (error) => ({
	type: actionTypes.FETCH_TEAM_ALIGN_FAILED,
	error,
});
export const fetchTeamsAlignmentMap = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchTeamAlignmentPending());
		return alignmentTeamMapAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchTeamAlignmentCompleted(response.data));
				} else {
					dispatch(fetchTeamAlignmentFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchTeamAlignmentFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getAlignmentGraphList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchAlignmentPending());
		return alignmentMapGraphAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchAlignmentCompleted(response.data));
				} else {
					dispatch(fetchAlignmentFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchAlignmentFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getAlignmentObjectivesList = (data, type) => {
	return () => {
		if (type === 'parent') {
			return getAllParentByObjective(data);
		}
		return getChildByObjective(data);
	};
};

export const resetAlignment = () => {
	return {
		type: actionTypes.RESET_ALIGNMENT,
	};
};

export const updateAlignmentStatus = () => {
	return {
		type: actionTypes.UPDATE_ALIGNMENT,
	};
};

//Fetch people map data

const fetchPeopleMapPending = () => ({
	type: actionTypes.FETCH_PEOPLE_PENDING,
});
const fetchPeopleMapCompleted = (payload) => ({
	type: actionTypes.FETCH_PEOPLE_SUCCESS,
	payload,
});
const fetchPeopleMapFailed = (error) => ({
	type: actionTypes.FETCH_PEOPLE_FAILED,
	error,
});

export const getPeopleMapList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchPeopleMapPending());
		return peopleMapAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchPeopleMapCompleted(response.data));
				} else {
					dispatch(fetchAlignmentFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchPeopleMapFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const addAlignmentFilter = (filter) => ({
	type: actionTypes.ADD_ALIGNMENT_FILTER,
	filter,
});

export const addOrganisationFilter = (filter) => ({
	type: actionTypes.ADD_ORGANISATION_FILTER,
	filter,
});

export const addTeamOkrFilter = (filter) => ({
	type: actionTypes.ADD_TEAMOKR_FILTER,
	filter,
});

//Direct Reports
const fetchDirectsReportsPending = () => ({
	type: actionTypes.FETCH_DIRECT_REPORTS_PENDING,
});
const fetchDirectsReportsCompleted = (payload) => ({
	type: actionTypes.FETCH_DIRECT_REPORTS_SUCCESS,
	payload,
});
const fetchDirectsReportsFailed = (error) => ({
	type: actionTypes.FETCH_DIRECT_REPORTS_FAILED,
	error,
});
export const fetchDirectsReportsMap = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchDirectsReportsPending());
		return alignmentDirectsReportsMapAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchDirectsReportsCompleted(response.data));
				} else {
					dispatch(fetchDirectsReportsFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchDirectsReportsFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const addDirectReportSortBy = (sortBy) => ({
	type: actionTypes.ADD_DIRECTREPORT_SORTBY,
	sortBy,
});

export const setAlignmentMapAction = (actionData) => ({
	type: actionTypes.ALIGNMENT_MAP_ACTION,
	actionType: actionData.actionType,
	status: actionData.status,
	value: actionData.value,
});

export const updateObjetciveDueDate = (data, type) => {
	return () => {
		return updateDueDateAPI(data);
	};
};

import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Button, TextField, Tooltip, Chip } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getLocalStorageItem } from '../../services/StorageService';
import { CalenderIcon } from '../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import { createUUID, formatDate } from '../../config/utils';
// import { handleCalendarOpen } from '../../action/signalR';
// import store from '../../store/configureStore';

export default function DatePicker(props) {
	const {
		value,
		handleDateChange,
		name,
		minDate,
		maxDate,
		error,
		helperText,
		format,
		disableToolbar,
		showLabel,
		durationCycleDetail,
		module,
		type,
	} = props;
	const { t } = useTranslation();
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || 0;
	const TextFieldComponent = (props) => {
		return (
			<Fragment>
				{type ? (
					<Tooltip
						arrow
						title={
							type === 'createGoal'
								? t('okrDuedateTooltip')
								: type === 'createKr'
									? t('krDuedateTooltip')
									: type === 'goal' && props.name === 'dueDate'
										? t('dueDateHelpText')
										: t('startDateHelpText')
						}
						classes={type === 'createKr' ? { popper: 'tooltip-layout3' } : {}}
					>
						<TextField {...props} inputProps={{ readOnly: true }} />
					</Tooltip>
				) : (
					<TextField {...props} inputProps={{ readOnly: true }} />
				)}
			</Fragment>
		);
	};

	const ToolbarComponent = (props) => {
		return Boolean(durationCycleDetail) && durationCycleDetail.length ? (
			<Box className='calender-toolbar' {...props}>
				{durationCycleDetail.map((item, index) =>
					item.isActive && module === 'goal' ? (
						<Button
							key={`objective-date-${index}`}
							disabled={!item.isActive}
							onClick={() => props.onChange(new Date(item.endOfMonthDate))}
						>
							{item.title}
						</Button>
					) : (
						item.isActive &&
						module !== 'goal' &&
						moment(new Date(formatDate(maxDate))).diff(new Date(formatDate(item.endOfMonthDate))) >= 0 && (
							<Button
								key={`objective-date-${index}`}
								disabled={
									!item.isActive ||
									(module !== 'goal' &&
										moment(new Date(formatDate(maxDate))).diff(new Date(formatDate(item.endOfMonthDate))) < 0)
								}
								onClick={() => props.onChange(new Date(item.endOfMonthDate))}
							>
								{item.title}
							</Button>
						)
					)
				)}
			</Box>
		) : (
			<Fragment />
		);
	};

	// const handleCalendar = () => {
	// 	store.dispatch(handleCalendarOpen(true));
	// };

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				disableToolbar={Boolean(disableToolbar)}
				autoOk
				variant='inline'
				format={format}
				id={createUUID()}
				key={createUUID()}
				label={showLabel === false ? t('demoExpiryDate') : t('dueDate')}
				value={value ? value : null}
				onChange={(e) => handleDateChange(e, name)}
				keyboardIcon={<CalenderIcon />}
				KeyboardButtonProps={{
					'aria-label': t('CommonChangeDate'),
				}}
				// onOpen={() =>
				// 	props.isAnyDrawerOpened({
				// 		...props.appDrawerInfo,
				// 		isCalendarOpen: true,
				// 	})
				// }
				// onClose={() =>
				//     props.isAnyDrawerOpened({
				//         ...props.appDrawerInfo,
				//         isCalendarOpen: false,
				//     })
				// }
				minDate={minDate ? minDate : new Date()}
				// maxDate={maxDate ? maxDate : new Date(cycleEndDate)}
				error={error}
				helperText={helperText}
				TextFieldComponent={TextFieldComponent}
				ToolbarComponent={ToolbarComponent}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					// props.isAnyDrawerOpened({
					//     ...props.appDrawerInfo,
					//     isCalendarOpen: true,
					// });
				}}
				name={name}
			/>
		</MuiPickersUtilsProvider>
	);
}

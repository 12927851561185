import React, { Fragment } from 'react';
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Popper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	abbreviateNumber,
	getOkrProgressDetails,
	getRandomBackgroundColor,
	getRandomColor,
	getSignByType,
	getTime,
	getUserDetails,
	getUserName,
} from '../../config/utils';
import { EditIcon, ExternalContributorIcon, LinkIcon, VirtualLinkContributorIcon } from '../../config/svg/CommonSvg';
import { USER_VIEW } from '../../config/app-url';
import { setLocalStorageItem } from '../../services/StorageService';
import { OKRButton } from './OKRButton';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { Enums } from '../../config/enums';
import { start } from 'repl';
interface ContributorDetailsProps {
	employeeId: String | Number;
	firstName?: any;
	lastName?: any;
	imagePath?: string;
	image?: string;
	fullName?: String;
	designation?: String;
	contributorsContribution?: String | Number;
	updatedOn?: any;
	isSource?: boolean;
	userType?: String;
	scaleValue?: any;
	alignmentMapPopover?: any;
	isExternal?: boolean;
	teamName?: string;
	backGroundColorCode?: any;
	colorCode?: any;
	targetValue?: string;
	metricId?: any;
	currencyId?: any;
	popoverNoModifiers?: any;
	actionLevel?: any;
}

export const ContributorsListPopover: React.FC<any> = (props) => {
	const {
		contributorDetails,
		open,
		anchorEl,
		setOpen,
		setAnchorEl,
		loginUserAvatar,
		currentUser,
		poperContainer,
		contributorClassName,
		scaleValue,
		alignmentMapPopover,
		popupView,
		handleTabChange,
		okr,
		handleViewAllVirtualAlignments,
		virtualAlignmentListPopUp,
		t,
		hideLinks,
		hideScore,
		doNotAllowUserSelection,
		showTeamDetails,
		popoverNoModifiers,
		actionLevel,
	} = props;

	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleListKeyDown = (event: any) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};
	const userDetail = getUserDetails();
	const loggedInUserDetail = currentUser ? currentUser : userDetail;
	const okrOwnerInfo = okr?.contributors && okr?.contributors.length > 0 ? okr?.contributors[0]?.designation : '';

	const getScoreDetails = (kr: any) => {
		const { color } = getOkrProgressDetails({
			value: kr.contributorsContribution,
			dueDate: kr.dueDate,
		});
		const { okrProgress } = getOkrProgressDetails({
			value: kr.contributorsContribution,
			dueDate: kr.dueDate,
		});
		const currencyClass = kr.currencyId === 1 ? 'dollarCurrency' : '';
		const krUpdate = props.showTarget ? kr.updatedOn && kr.contributorsContribution !== 0 : kr.updatedOn;
		return (
			<Box className='contributordetails-right'>
				{props.showTarget && kr.krStatusId === Enums.KR_STATUS_PENDING ? (
					<Typography variant='body2' className='pending-text'>
						{`${kr.krStatusName}`}
					</Typography>
				) : props.showTarget && kr.krStatusId === Enums.KR_STATUS_DECLINED ? (
					<Typography variant='body2' className='declined-text'>
						{`${kr.krStatusName}`}
					</Typography>
				) : (
					<>
						{krUpdate ? (
							<Fragment>
								<Typography variant='body2' style={{ color }} className={okrProgress + ' ' + currencyClass}>
									{`${
										props.showTarget && kr.metricId === Enums.FOUR ? t('achieved') : kr.contributorsContribution + '%'
									}`}
									{(props.showTarget && kr.metricId === Enums.ONE) ||
									(props.showTarget && kr.metricId === Enums.TWO) ||
									(props.showTarget && kr.metricId === Enums.THREE)
										? getKrContributionValueFormated(kr.currentValue, kr.metricId, kr.currencyId)
										: ''}
								</Typography>
								<Typography variant='body2'>{getTime(kr.updatedOn)}</Typography>
							</Fragment>
						) : kr.contributorsContribution === 0 ? (
							<Fragment>
								<Typography variant='body2' style={{ color }} className={props.showTarget ? 'not-achieved' : ''}>
									{`${
										props.showTarget && kr.metricId === Enums.FOUR
											? t('notAchieved')
											: props.showTarget && kr.metricId === Enums.FIVE
											? t('noUnits')
											: kr.contributorsContribution + '%'
									}`}
								</Typography>
								<Typography variant='body2'>{t('MyGoalPdfNotStarted')}</Typography>
							</Fragment>
						) : null}
					</>
				)}
			</Box>
		);
	};

	const onSelectedUser = (selected: any) => {
		if (
			Boolean(selected) &&
			loggedInUserDetail.employeeId !== selected.employeeId &&
			doNotAllowUserSelection !== true
		) {
			setLocalStorageItem('currentUser', JSON.stringify(selected));
			props.resetOkr();
			props.history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
		}
	};
	const scaleStyle = {
		transform: `scale(${scaleValue})`,
	};

	const getTargetValueFormated = (targetValue: any, metricId: any, currencyId: any) => {
		return (
			<>
				{metricId !== Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''}
				{metricId === Enums.FIVE ? 'No Unit' : metricId === Enums.FOUR ? 'Yes/No' : abbreviateNumber(targetValue)}
				{metricId === Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''}
			</>
		);
	};
	const getKrContributionValueFormated = (currentValue: any, metricId: any, currencyId: any) => {
		return (
			<>
				{' '}
				({metricId !== Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''}
				{metricId === Enums.ONE || metricId === Enums.TWO || metricId === Enums.THREE
					? abbreviateNumber(currentValue)
					: ''}
				{metricId === Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''})
			</>
		);
	};
	return (
		<Popper
			className={
				contributorClassName && alignmentMapPopover
					? contributorClassName
					: props.viewType && okr?.virtualAlignmentCount
					? 'contributor-details-popover virtual-contributors-list'
					: 'contributor-details-popover'
			}
			open={open}
			anchorEl={anchorEl}
			transition
			placement={
				popoverNoModifiers && alignmentMapPopover && actionLevel < 0 && scaleValue > 1.08
					? 'bottom-start'
					: popoverNoModifiers && alignmentMapPopover
					? 'bottom-end'
					: 'bottom-start'
			}
			modifiers={{
				preventOverflow: {
					enabled: popoverNoModifiers && alignmentMapPopover ? true : alignmentMapPopover ? false : true,
					boundariesElement:
						popoverNoModifiers && alignmentMapPopover
							? 'scrollParent'
							: alignmentMapPopover
							? 'window'
							: 'scrollParent',
				},
			}}
			container={poperContainer === true ? anchorEl : document.body}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Box className='contributor-details-inner' onKeyDown={handleListKeyDown} style={scaleStyle}>
					{props.viewType && (
						<Box className='contributors-details-head'>
							{popupView === 1 && (
								<OKRButton
									className='back-btn'
									id={'back-btn'}
									handleClick={(e) => handleTabChange(e, 0)}
									icon={<BackArrow />}
									text={t('back')}
								/>
							)}
							{popupView !== 1 && (
								<Box className='contributor-label'>
									<Typography variant='body2'>{t('contributorsLabel')}</Typography>
								</Box>
							)}
							{okr.isVirtualAlignment && (
								<Box className={`virtual-link-btn ${popupView === 1 ? 'active' : ''}`}>
									<OKRButton
										className='btn-link'
										id={'virtual-link-btn'}
										icon={<VirtualLinkContributorIcon />}
										text={t('virtuallylinked', { count: okr.virtualAlignmentCount })}
										handleClick={(e) => {
											handleTabChange(e, 1);
										}}
									/>
								</Box>
							)}
						</Box>
					)}

					{props.showEditHeader && (
						<Box className='contributors-details-head'>
							<Typography>{'Your all Contributor(s)'}</Typography>
							<OKRButton
								className='btn-link'
								text={'Edit'}
								icon={<EditIcon />}
								handleClick={() => {
									props.onEditHeaderclick && props.onEditHeaderclick();
								}}
							/>
						</Box>
					)}

					{props.showTarget && (
						<Box className='contributors-details-head contributors-details-targethead'>
							<Box className='col-1'>
								<Box className='contributor-label'>
									<Typography variant='body2'>{t('contributorsLabel')}</Typography>
								</Box>
							</Box>
							<Box className='col-2'>
								<Box className='contributor-label'>
									<Typography variant='body2'>{t('target')}</Typography>
								</Box>
							</Box>
							<Box className='col-3'>
								<Box className='contributor-label'>
									<Typography variant='body2'>{t('progress')}</Typography>
								</Box>
							</Box>
						</Box>
					)}

					{popupView === 0 && (
						<MenuList
							className={`contributor-details-list contributorsList ${hideLinks === true ? 'hide-linking' : ''} ${
								props.showTarget ? 'show-target' : ''
							}`}
						>
							{loginUserAvatar && (
								<MenuItem id={`login-contributor-${loginUserAvatar.employeeId}`}>
									<Box className='contributordetails-left ' onClick={() => onSelectedUser(loginUserAvatar)}>
										<ListItemAvatar
											className={
												loginUserAvatar.employeeId === userDetail.employeeId ? 'owner-avatar' : 'parent-avatar'
											}
										>
											{loginUserAvatar.imagePath || loginUserAvatar.image ? (
												<Avatar
													className='avatar-default'
													alt={`${loginUserAvatar.firstName} ${loginUserAvatar.lastName}`}
													src={loginUserAvatar.imagePath || loginUserAvatar.image}
												/>
											) : (
												<Avatar className='avatar-default'>
													{getUserName({
														firstName: loginUserAvatar.firstName || '',
														lastName: loginUserAvatar.lastName || '',
														fullName: loginUserAvatar.fullName || '',
													})}
												</Avatar>
											)}
										</ListItemAvatar>
										<Box className='contributor-info'>
											<ListItemText
												className='contributor-name'
												primary={
													props.showTarget &&
													loginUserAvatar?.firstName &&
													loginUserAvatar?.lastName &&
													(loginUserAvatar?.firstName + loginUserAvatar?.lastName).length > 15 ? (
														<Tooltip title={loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName} arrow>
															<Typography className='tooltip-text' component='span' variant='h4'>
																{loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName}
															</Typography>
														</Tooltip>
													) : loginUserAvatar?.firstName &&
													  loginUserAvatar?.lastName &&
													  (loginUserAvatar?.firstName + loginUserAvatar?.lastName).length > 15 ? (
														<Tooltip title={loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName} arrow>
															<Typography className='tooltip-text' component='span' variant='h4'>
																{loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName}
															</Typography>
														</Tooltip>
													) : (
														<Typography variant='h4'>
															{loginUserAvatar?.firstName} {loginUserAvatar?.lastName}
														</Typography>
													)
												}
												secondary={
													Boolean(loginUserAvatar.userType) ||
													loginUserAvatar?.userType === '' ||
													loginUserAvatar?.userType === null ? (
														loginUserAvatar.userType === null ? (
															''
														) : (
															loginUserAvatar.userType
														)
													) : loginUserAvatar.employeeId === loggedInUserDetail.employeeId ? (
														'Owner'
													) : (
														<Fragment>
															<LinkIcon /> <Typography variant='inherit'>Parent</Typography>
														</Fragment>
													)
												}
											/>
											{props.viewType === 'okrList' ? (
												okrOwnerInfo && okrOwnerInfo.length > 25 ? (
													<Tooltip title={okrOwnerInfo} arrow>
														<Typography variant='subtitle2'>{okrOwnerInfo.substring(0, 25) + '...'}</Typography>
													</Tooltip>
												) : (
													<Typography variant='subtitle2'>{okrOwnerInfo}</Typography>
												)
											) : loginUserAvatar?.designation && loginUserAvatar?.designation.length > 25 ? (
												<Tooltip title={loginUserAvatar.designation} arrow>
													<Typography variant='subtitle2'>
														{loginUserAvatar.designation.substring(0, 25) + '...'}
													</Typography>
												</Tooltip>
											) : (
												<Typography variant='subtitle2'>{loginUserAvatar?.designation}</Typography>
											)}
										</Box>
									</Box>
									{props.showTarget && (
										<Box className='contributordetails-middle'>
											<Typography variant='body2'>
												{getTargetValueFormated(
													loginUserAvatar?.targetValue,
													loginUserAvatar?.metricId,
													loginUserAvatar?.currencyId
												)}
											</Typography>
										</Box>
									)}
									{props.showTarget && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
										<Box className='contributordetails-right'>
											<Typography variant='h6'>{t('notSubmitted')}</Typography>
										</Box>
									) : (
										getScoreDetails(loginUserAvatar)
									)}
								</MenuItem>
							)}
							{contributorDetails ? (
								contributorDetails.map(
									(item: ContributorDetailsProps) =>
										item && (
											<MenuItem key={`cd${item.employeeId}`} id={`contributor-detail-${item.employeeId}`}>
												<Box className='contributordetails-left' onClick={() => onSelectedUser(item)}>
													<ListItemAvatar className={item.employeeId === userDetail.employeeId ? 'owner-avatar' : ''}>
														{item.imagePath || item.image ? (
															<Avatar
																className='avatar-default'
																alt={`${item.firstName} ${item.lastName}`}
																src={item.imagePath || item.image}
															/>
														) : (
															<Avatar className='avatar-default'>
																{getUserName({
																	firstName: item.firstName || '',
																	lastName: item.lastName || '',
																	fullName: item.fullName || '',
																})}
															</Avatar>
														)}
													</ListItemAvatar>
													<Box className='contributor-info'>
														<ListItemText
															className='contributor-name'
															primary={
																props.showTarget &&
																item?.firstName &&
																item?.lastName &&
																(item?.firstName + item?.lastName).length > 15 ? (
																	<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																		<Typography className='tooltip-text' component='span' variant='h4'>
																			{item?.firstName + ' ' + item?.lastName}
																		</Typography>
																	</Tooltip>
																) : item?.firstName &&
																  item?.lastName &&
																  (item?.firstName + item?.lastName).length > 15 ? (
																	<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																		<Typography className='tooltip-text' component='span' variant='h4'>
																			{item?.firstName + ' ' + item?.lastName}
																		</Typography>
																	</Tooltip>
																) : (
																	<Typography variant='h4'>
																		{item?.firstName} {item?.lastName}
																	</Typography>
																)
															}
															secondary={`${
																Boolean(item?.userType) || item?.userType === '' || item?.userType === null
																	? item.userType === null
																		? ''
																		: item.userType
																	: item.employeeId === loggedInUserDetail.employeeId
																	? 'Owner'
																	: ''
															}`}
														/>
														{item?.designation && item?.designation.length > 25 ? (
															<Tooltip title={item.designation} arrow>
																<Typography variant='subtitle2'>{item.designation.substring(0, 25) + '...'}</Typography>
															</Tooltip>
														) : (
															<Typography variant='subtitle2'>{item?.designation}</Typography>
														)}
														{showTeamDetails && item.teamName && (
															<Box className='teams-chips'>
																{item?.isExternal && (
																	<Tooltip title={t('externalContributorTooltip')} arrow>
																		<span className='external-link'>
																			{' '}
																			<ExternalContributorIcon />{' '}
																		</span>
																	</Tooltip>
																)}
																<Tooltip title={item.teamName} arrow>
																	<Chip
																		className='chip'
																		style={{
																			backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																			color: getRandomColor(item?.colorCode),
																		}}
																		label={item.teamName}
																	/>
																</Tooltip>
															</Box>
														)}
													</Box>
												</Box>
												{props.showTarget && (
													<Box className='contributordetails-middle'>
														<Typography variant='body2'>
															{getTargetValueFormated(item?.targetValue, item?.metricId, item?.currencyId)}
														</Typography>
													</Box>
												)}
												{props.showTarget && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
													<Box className='contributordetails-right'>
														<Typography variant='h6'>{t('notSubmitted')}</Typography>
													</Box>
												) : (
													props.viewType !== 'okrList' && hideScore !== true && getScoreDetails(item)
												)}
											</MenuItem>
										)
								)
							) : (
								<Typography></Typography>
							)}
						</MenuList>
					)}
					{popupView === 1 && (
						<MenuList className='contributor-details-list virtualAlignment'>
							<Box className='virtualAlignments'>
								{virtualAlignmentListPopUp && virtualAlignmentListPopUp.length ? (
									virtualAlignmentListPopUp.map(
										(item: any, index: any) =>
											item && (
												<MenuItem id={`virtual-card-${index}`}>
													<Box className='contributordetails-left' onClick={() => onSelectedUser(item)}>
														<Box className='user-info'>
															<Box className='user-img'>
																{item.imagePath || item.image ? (
																	<Avatar
																		className='avatar-default'
																		alt={`${item.firstName} ${item.lastName}`}
																		src={item.imagePath || item.image}
																	/>
																) : (
																	<Avatar className='avatar-default'>
																		{getUserName({
																			firstName: item.firstName || '',
																			lastName: item.lastName || '',
																			fullName: item.fullName || '',
																		})}
																	</Avatar>
																)}
															</Box>
															<Box className='user-info-details'>
																<ListItemText
																	className='contributor-name'
																	primary={
																		<Typography variant='h4'>
																			{item.firstName} {item.lastName}
																		</Typography>
																	}
																	secondary={`${
																		Boolean(item?.userType) || item?.userType === '' || item?.userType === null
																			? item.userType === null
																				? ''
																				: item.userType
																			: ''
																	}`}
																/>
																{item?.designation && item?.designation.length > 25 ? (
																	<Tooltip title={item.designation} arrow>
																		<Typography variant='subtitle2'>
																			{item.designation.substring(0, 25) + '...'}
																		</Typography>
																	</Tooltip>
																) : (
																	<Typography variant='subtitle2'>{item?.designation}</Typography>
																)}
																<Box className='teams-chips'>
																	<Tooltip title={item.teamName} arrow>
																		<Chip
																			className='chip'
																			style={{
																				backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																				color: getRandomColor(item?.colorCode),
																			}}
																			label={item.teamName}
																		/>
																	</Tooltip>
																</Box>
															</Box>
														</Box>
													</Box>
													{props.viewType !== 'okrList' && getScoreDetails(item)}
												</MenuItem>
											)
									)
								) : (
									<Typography></Typography>
								)}
							</Box>
						</MenuList>
					)}

					{popupView === 1 && props.viewType && virtualAlignmentListPopUp && virtualAlignmentListPopUp.length > 0 && (
						<Box className='virtual-contributor-actions'>
							<OKRButton
								handleClick={(e) => {
									handleTabChange(e, 0);
									handleViewAllVirtualAlignments();
									handleClose();
								}}
								className='btn-link'
								text={t('viewAll')}
								id={'view-all-btn'}
							/>
						</Box>
					)}
				</Box>
			</ClickAwayListener>
		</Popper>
	);
};

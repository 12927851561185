export const enableTaggingWithImage = ['Title','MediaEmbed','Indent', 'BlockQuote']
export const weeklyChekinRemovePlugins = ['Title','MediaEmbed','Indent', 'BlockQuote', 'Mention', 'Image', 'EasyImage', 'ImageUpload']
export const roleRemovePlugin = ['Title','MediaEmbed','Indent', 'BlockQuote', 'Mention', 'Image', 'EasyImage', 'ImageUpload', 'Table']

export const progressRemovePlugin = ['Title','paragraph', 'heading1', 'heading2', 'heading3','bulletedList', 'numberedList','MediaEmbed','Indent', 'BlockQuote', 'Image', 'EasyImage', 'ImageUpload', 'Table']


export const defaultToolbar = ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable'];
export const ImageUploadToolbar = [ '|', 'imageUpload'];
export const EmoticonsToolbar = ['localizedSpecialCharacter'];

import { Box, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { getUserDetails, getCurrentUserDetails, createUUID, formatDate } from '../../../config/utils';
import CompactViewOKRMap from '../CompactViewOKRMap';
import RelaxedMap from '../RelaxedMap';
import PeopleMap from '../PeopleMap';
import { Enums } from '../../../config/enums';
import { getLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import AlignmentImage from '../../../images/alignment-bg.svg';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { DirectReportsTabs } from './DirectReportsTabs';
import { TeamTabs } from './TeamTabs';
import UltraCompactViewOKRMap from '../UltraCompactViewOKRMap';
import TeamMap from '../TeamMap';
import CompactViewTeamMap from '../CompactViewTeamMap';
import UltraCompactViewTeamMap from '../UltraCompactViewTeamMap';
import noRecordFound from '../../../images/search-placeholder.svg';

export const AlignmentListing: React.FC<any> = (props) => {
	const {
		tabFilter,
		tabValue,
		handleDeleteOkr,
		alignResult,
		alignStatus,
		peopleResult,
		peopleStatus,
		isDashboard,
		listOkrResult,
		okrMasterData,
		addUpdateObjective,
		alignmentFilterData,
		teamStatus,
		teamResult,
		teamOkrFilterData,
		directReportSortBy,
	} = props;

	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [showEditLoader, setShowEditLoader] = useState<any>({ status: '', id: 0 });
	const [goalDetail, setGoalDetail] = useState<any>({
		actionType: 'editGoal',
		showAttributeDrawer: true,
		goalDetail: {},
	});
	const [isGoalCreated, setIsGoalCreated] = useState<Boolean>(false);
	const [formSubmit, setFormSubmit] = useState<Boolean>(false);
	const [highlightGoalId, setHighlightGoalId] = useState<Number>(0);

	const year = getLocalStorageItem('currentYear') || null;
	const currentCycleEndDate = getLocalStorageItem('currentCycleEndDate') || null;
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const selectedYear = getLocalStorageItem('year') || year;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const userDetail: any = getUserDetails();
	const currentUser: any = getCurrentUserDetails();
	const { employeeId }: any = getUserDetails();
	const [showChips, setShowChips] = useState<boolean>(false);
	const [prevCycleMsg, setPrevCycleMsg] = useState<boolean>(false);

	const updateProgress = async (goalKeyId: number, assignmentTypeId: number, value: number, year: number) => {
		const selectedYear = getLocalStorageItem('year') || year;
		setShowEditLoader({ status: 'pending', id: goalKeyId });
		const data = { goalKeyId: goalKeyId, currentValue: value, year: selectedYear };
		const resp = await props.updateProgress(data);
		if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
			const alignmentMapAction = {
				actionType: 'UPDATE_PROGRESS',
				status: 'PENDING',
				value: resp.data.entityList,
			};
			props.setAlignmentMapAction(alignmentMapAction);
			setShowEditLoader({ status: '', id: 0 });
			return true;
		}
	};

	useEffect(() => {
		getPeopleData();
		fetchTeamsAlignmentMap();
		fetchDirectsReportsMap();
	}, [alignmentFilterData]);

	useEffect(() => {
		if (teamOkrFilterData) {
			fetchTeamsAlignmentMap();
		}
	}, [teamOkrFilterData]);

	useEffect(() => {
		if (props.deleteTeamOkrKr) {
			fetchTeamsAlignmentMap();
		}
	}, [props.deleteTeamOkrKr]);

	useEffect(() => {
		if (props.reloadTeam) {
			fetchTeamsAlignmentMap();
		}
	}, [props.reloadTeam]);

	useEffect(() => {
		if (directReportSortBy) {
			fetchDirectsReportsMap();
		}
	}, [directReportSortBy]);
	useEffect(() => {
		if (currentCycleId === cycleId) {
			setPrevCycleMsg(false);
		} else if (currentCycleId !== cycleId) {
			setPrevCycleMsg(true);
		}
	}, [currentCycleId, cycleId]);
	const makeFilter = () => {
		let filters = alignmentFilterData.length
			? alignmentFilterData
					.filter((item: any) => item.isChecked)
					.map((item: any) => `searchTexts=${item.code}`)
					.join('&')
			: '';
		return (filters = filters ? `&${filters}` : '');
	};

	const teamOkrFilter = () => {
		let filters =
			teamOkrFilterData && teamOkrFilterData.length
				? teamOkrFilterData.map((item: any) => `teamId=${item.teamId}`).join('&')
				: '';
		return (filters = filters ? `&${filters}` : '');
	};

	const getPeopleData = () => {
		if (tabValue === 0 && ['peoplerelaxed', 'peoplecompact', 'peopleultracompact'].indexOf(tabFilter) > -1) {
			let filters = makeFilter();
			const data = `empId=${userDetail && userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}${filters}`;
			props.getPeopleMapList(data);
		}
	};

	const fetchTeamsAlignmentMap = () => {
		if (tabValue === 1 && ['relaxed', 'compact', 'ultracompact'].indexOf(tabFilter) > -1) {
			// calling all teams API if not in store to show red dot on all tabs
			if (!alignResult.okrViewResponses || !alignResult.okrViewResponses.length) {
				const endpoint = `empId=${userDetail && userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
				props.getAlignmentList(endpoint);
			}

			let filters = makeFilter();
			let teamFilter = teamOkrFilter();
			if (teamFilter !== '') {
				const data = `empId=${
					userDetail && userDetail.employeeId
				}&cycle=${cycleId}&year=${selectedYear}${filters}${teamFilter}`;
				props.fetchTeamsAlignmentMap(data);
			}
		}
	};

	const fetchDirectsReportsMap = () => {
		if (tabValue === 2) {
			const filters = makeFilter();
			const sortBy = directReportSortBy ? `&sortBy=${directReportSortBy}` : '';
			const data = `empId=${
				userDetail && userDetail.employeeId
			}&cycle=${cycleId}&year=${selectedYear}${filters}${sortBy}`;
			props.fetchDirectsReportsMap(data);
		}
	};

	const fetchOkr = (isLoader: boolean) => {
		// setGoalDetail({});
		if (!currentUser && userDetail && userDetail.employeeId && !isDashboard && cycleId !== 0) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getDashboardDetail && props.getDashboardDetail(data, isLoader);
		} else if (currentUser && currentUser.employeeId && cycleId !== 0) {
			const data = `empId=${currentUser.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getEmployeeViewGoals && props.getEmployeeViewGoals(data);
		} else if (isDashboard && cycleId !== 0 && !currentUser) {
			const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			// added CASE: team - so that api can triggered when team-okr progress updated.
			if (props.okrType === 'goal' || props.okrType === 'team') {
				props.getDashboardDetail && props.getDashboardDetail(data, isLoader);
			}
		}
	};

	const setGoalStatusId = (type: number, data: any) => {
		if (type === Enums.TWO && data.goalStatusId === Enums.GOAL_STATUS_DRAFT) {
			data.goalStatusId = type;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = type || item.goalStatusId;
					return item;
				});
			}
		}

		if (type === Enums.THREE || type === Enums.FOUR) {
			data.goalStatusId = Enums.GOAL_STATUS_DRAFT;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = Enums.GOAL_STATUS_DRAFT || item.goalStatusId;
					return item;
				});
			}
		}
	};

	const setDefaultData = (data: any, masterData: any, type: number) => {
		const { goalTypes, goalStatus } = okrMasterData;
		if (data.goalTypeId === Enums.ZERO) {
			const { goalTypeId } = goalTypes.find((item: any) => item.isDefault);
			data.goalTypeId = goalTypeId;
		}
		if (data.goalStatusId === Enums.ZERO) {
			const { goalStatusId } = goalStatus.find((item: any) => item.isDefault);
			data.goalStatusId = goalStatusId;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = goalStatusId || item.goalStatusId;
					return item;
				});
			}
		}
		setGoalStatusId(type, data);
	};

	const showAddGoalForm = (show: Boolean) => {
		setFormSubmit(false);
	};

	const saveAndEditObjectiveAndKr = async (objectiveDetail: any, type: number, isAdd: Boolean) => {
		let maxGoalObjectiveId = 0;
		if (listOkrResult.myGoalOkrResponses) {
			maxGoalObjectiveId = Math.max(...listOkrResult.myGoalOkrResponses.map((o: any) => o.goalObjectiveId), 0);
		}

		setLocalStorageItem('maxGoalObjectiveId', maxGoalObjectiveId);
		/**---Max Id ------------ */
		setFormSubmit(true);
		const { metricMasters } = okrMasterData || [];
		const noUnit = metricMasters?.find((item: any) => item.isDefault);
		// const standAlone = assignmentTypes && assignmentTypes.length && assignmentTypes.find((item: any) => item.isDefault);
		const updatedGoalDetail = objectiveDetail.myGoalsDetails.map((item: any) => {
			item.keyDescription = item?.keyDescription?.trim();
			if (item.metricId === 0) {
				item.metricId = noUnit?.metricId;
			}
			setGoalStatusId(type, item);
			item.goalStatusId = objectiveDetail?.goalStatusId || item.goalStatusId;
			item.contributors = objectiveDetail?.isPrivate
				? []
				: item.contributors.map((contributor: any) => {
						setGoalStatusId(type, contributor);
						return contributor;
				  });

			return item;
		});
		const filterData = updatedGoalDetail.filter((elem: any) => !elem.isNewKey);

		let data = { ...objectiveDetail, objectiveName: objectiveDetail?.objectiveName.trim(), myGoalsDetails: filterData };
		/** set default data to save goals */
		setDefaultData(data, okrMasterData, type);

		try {
			const dataToSave = isAdd ? [{ ...data }] : { ...data };
			const response = await addUpdateObjective(dataToSave, isAdd);
			if (response && response.data.status === Enums.STATUS_SUCCESS) {
				setFormSubmit(false);
				if (type === Enums.ONE) {
					showAddGoalForm(true);
					fetchOkr(true);
				} else if (type === Enums.TWO || type === Enums.THREE) {
					showAddGoalForm(false);
					fetchOkr(true);
				} else if (type === Enums.FOUR) {
					const responseModal = isAdd
						? response?.data?.entityList && response?.data?.entityList.length
							? response?.data?.entityList[0]
							: {}
						: response?.data?.entity || {};
					return responseModal || {};
				}
				setTimeout(() => {
					enqueueSnackbar(response?.data?.messageList?.Result, {
						variant: 'success',
						autoHideDuration: 3000,
					});
				}, 1200);
				return 'success';
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				setFormSubmit(false);
				const errorMessages = Object.values(response?.data?.messageList);
				if (errorMessages && errorMessages.length > 0) {
					errorMessages.forEach((item: any) =>
						enqueueSnackbar(item, {
							variant: 'error',
							autoHideDuration: 5000,
						})
					);
				}
				return 'error';
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				setFormSubmit(false);
				enqueueSnackbar(t('goalAddedFail'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
				return 'error';
			}
		} catch (e) {
			setFormSubmit(false);
			// console.error(e);
		}
	};

	const setDataUpdated = () => {};
	const setIsSaveDisabled = () => {};

	return (
		<Fragment>
			<Box className='tabpanel-inner'>
				{tabValue === 0 ? (
					<Fragment>
						{tabFilter === 'relaxed' || tabFilter === 'compact' || tabFilter === 'ultracompact' ? (
							<Box className='relaxed-tab'>
								{alignStatus === 'success' &&
								alignResult.okrViewResponses &&
								props.getPermissionResult?.employeePermissions &&
								alignResult.okrViewResponses.length > 0 ? (
									<RelaxedMap
										{...props}
										userDetail={userDetail}
										tabValue={tabValue}
										tabFilter={tabFilter}
										handleDeleteOkr={handleDeleteOkr}
										setOpenDrawer={props.setOpenDrawer}
										setModalProps={props.setModalProps}
										updateProgress={updateProgress}
										showEditLoader={showEditLoader}
										setGoalDetail={setGoalDetail}
										goalDetail={goalDetail}
										setIsGoalCreated={setIsGoalCreated}
										isGoalCreated={isGoalCreated}
										saveAndEditObjectiveAndKr={saveAndEditObjectiveAndKr}
										setFormSubmit={setFormSubmit}
										formSubmit={formSubmit}
										currentCycleId={currentCycleId}
										currentCycleEndDate={currentCycleEndDate}
										fetchOkr={fetchOkr}
										t={t}
										currentUser={currentUser}
										year={year}
										highlightGoalId={highlightGoalId}
										setHighlightGoalId={setHighlightGoalId}
										isLocked={props.alignResult && props.alignResult.isLocked}
										setDataUpdated={setDataUpdated}
										setIsSaveDisabled={setIsSaveDisabled}
										showChips={showChips}
										setShowChips={setShowChips}
										setIsUnreadFeedback={props.setIsUnreadFeedback}
									/>
								) : alignStatus === 'pending' ? (
									<Box className='welcome-content'>
										<Box textAlign='center'>
											<Typography>{t('loading')}</Typography>
										</Box>
									</Box>
								) : alignStatus === 'success' ? (
									<Fragment>
										{alignmentFilterData.length > 0 ? (
											<Box display='flex' justifyContent='center'>
												<Box className='no-record-message' textAlign='center'>
													<Typography variant='h2' component='h2'>
														{t('CommonOOPS')}
													</Typography>
													<Typography variant='h3'>{t('allDone')}</Typography>
													{/* <Typography>{t('noOkr')}</Typography> */}
													<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
												</Box>
											</Box>
										) : (
											<Box className='welcome-content'>
												<Box textAlign='center'>
													<AnimatedIcon
														className=''
														width='519'
														type='image/svg+xml'
														alt='WelcomeImage'
														imagePath={AlignmentImage}
													/>
													{prevCycleMsg ? (
														<>
															<Typography variant='h2'>{t('NoRecord')}</Typography>
															<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
														</>
													) : (
														<>
															<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
															<Typography>{t('myGoalNoDataMsg2')}</Typography>
														</>
													)}
												</Box>
											</Box>
										)}
									</Fragment>
								) : (
									<></>
								)}
							</Box>
						) : tabFilter === 'peoplerelaxed' ? (
							<Box className='people-relexed-tab'>
								{peopleStatus === 'success' && peopleResult && peopleResult.length > 0 ? (
									<>
										<PeopleMap
											{...props}
											userDetail={userDetail}
											tabValue={tabValue}
											tabFilter={tabFilter}
											updateProgress={updateProgress}
											showEditLoader={showEditLoader}
										/>
									</>
								) : peopleStatus === 'pending' ? (
									<Box className='welcome-content'>
										<Box textAlign='center'>
											<Typography>{t('loading')}</Typography>
										</Box>
									</Box>
								) : peopleStatus === 'success' ? (
									<Fragment>
										{alignmentFilterData.length > 0 ? (
											<Box display='flex' justifyContent='center'>
												<Box className='no-record-message' textAlign='center'>
													<Typography variant='h2' component='h2'>
														{t('CommonOOPS')}
													</Typography>
													<Typography variant='h3'>{t('allDone')}</Typography>
													{/* <Typography>{t('noOkr')}</Typography> */}
													<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
												</Box>
											</Box>
										) : (
											<Box className='welcome-content'>
												<Box textAlign='center'>
													<AnimatedIcon
														className=''
														width='519'
														type='image/svg+xml'
														alt='WelcomeImage'
														imagePath={AlignmentImage}
													/>
													{prevCycleMsg ? (
														<>
															<Typography variant='h2'>{t('NoRecord')}</Typography>
															<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
														</>
													) : (
														<>
															<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
															<Typography>{t('myGoalNoDataMsg2')}</Typography>
														</>
													)}
												</Box>
											</Box>
										)}
									</Fragment>
								) : (
									<></>
								)}
							</Box>
						) : tabFilter === 'peoplecompact' ? (
							<Box className='people-compact-tab'>
								{peopleStatus === 'success' && peopleResult && peopleResult.length > 0 ? (
									<PeopleMap
										{...props}
										userDetail={userDetail}
										tabValue={tabValue}
										tabFilter={tabFilter}
										updateProgress={updateProgress}
										showEditLoader={showEditLoader}
									/>
								) : peopleStatus === 'pending' ? (
									<Box className='welcome-content'>
										<Box textAlign='center'>
											<Typography>{t('loading')}</Typography>
										</Box>
									</Box>
								) : peopleStatus === 'success' ? (
									<Fragment>
										{alignmentFilterData.length > 0 ? (
											<Box display='flex' justifyContent='center'>
												<Box className='no-record-message' textAlign='center'>
													<Typography variant='h2' component='h2'>
														{t('CommonOOPS')}
													</Typography>
													<Typography variant='h3'>{t('allDone')}</Typography>
													{/* <Typography>{t('noOkr')}</Typography> */}
													<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
												</Box>
											</Box>
										) : (
											<Box className='welcome-content'>
												<Box textAlign='center'>
													<AnimatedIcon
														className=''
														width='519'
														type='image/svg+xml'
														alt='WelcomeImage'
														imagePath={AlignmentImage}
													/>
													{prevCycleMsg ? (
														<>
															<Typography variant='h2'>{t('NoRecord')}</Typography>
															<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
														</>
													) : (
														<>
															<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
															<Typography>{t('myGoalNoDataMsg2')}</Typography>
														</>
													)}
												</Box>
											</Box>
										)}
									</Fragment>
								) : (
									<></>
								)}
							</Box>
						) : tabFilter === 'peopleultracompact' ? (
							<Box className='people-ultracompact-tab'>
								{peopleStatus === 'success' && peopleResult && peopleResult.length > 0 ? (
									<PeopleMap
										{...props}
										userDetail={userDetail}
										tabValue={tabValue}
										tabFilter={tabFilter}
										updateProgress={updateProgress}
										showEditLoader={showEditLoader}
									/>
								) : peopleStatus === 'pending' ? (
									<Box className='welcome-content'>
										<Box textAlign='center'>
											<Typography>{t('loading')}</Typography>
										</Box>
									</Box>
								) : peopleStatus === 'success' ? (
									<Fragment>
										{alignmentFilterData.length > 0 ? (
											<Box display='flex' justifyContent='center'>
												<Box className='no-record-message' textAlign='center'>
													<Typography variant='h2' component='h2'>
														{t('CommonOOPS')}
													</Typography>
													<Typography variant='h3'>{t('allDone')}</Typography>
													{/* <Typography>{t('noOkr')}</Typography> */}
													<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
												</Box>
											</Box>
										) : (
											<Box className='welcome-content'>
												<Box textAlign='center'>
													<AnimatedIcon
														className=''
														width='519'
														type='image/svg+xml'
														alt='WelcomeImage'
														imagePath={AlignmentImage}
													/>
													{prevCycleMsg ? (
														<>
															<Typography variant='h2'>{t('NoRecord')}</Typography>
															<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
														</>
													) : (
														<>
															<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
															<Typography>{t('myGoalNoDataMsg2')}</Typography>
														</>
													)}
												</Box>
											</Box>
										)}
									</Fragment>
								) : (
									<></>
								)}
							</Box>
						) : (
							<></>
						)}
					</Fragment>
				) : tabValue === 1 ? (
					<Fragment>
						{teamStatus === 'success' && teamResult?.okrViewResponses && teamResult?.okrViewResponses?.length > 0 ? (
							<Box className='relaxed-tab'>
								{tabFilter === 'relaxed' ? (
									<TeamMap
										{...props}
										userDetail={userDetail}
										tabValue={tabValue}
										tabFilter={tabFilter}
										handleDeleteOkr={handleDeleteOkr}
										setOpenDrawer={props.setOpenDrawer}
										setModalProps={props.setModalProps}
										setGoalDetail={setGoalDetail}
										goalDetail={goalDetail}
										setDataUpdated={setDataUpdated}
										updateProgress={updateProgress}
										showEditLoader={showEditLoader}
										currentUser={currentUser}
										currentCycleId={currentCycleId}
										alignmentFilterData={alignmentFilterData}
										teamOkrFilterData={teamOkrFilterData}
									/>
								) : tabFilter === 'compact' ? (
									<CompactViewTeamMap
										{...props}
										userDetail={userDetail}
										tabValue={tabValue}
										tabFilter={tabFilter}
										handleDeleteOkr={handleDeleteOkr}
										setOpenDrawer={props.setOpenDrawer}
										setModalProps={props.setModalProps}
										setGoalDetail={setGoalDetail}
										goalDetail={goalDetail}
										setDataUpdated={setDataUpdated}
										updateProgress={updateProgress}
										showEditLoader={showEditLoader}
										currentUser={currentUser}
									/>
								) : tabFilter === 'ultracompact' ? (
									<UltraCompactViewTeamMap
										{...props}
										userDetail={userDetail}
										tabValue={tabValue}
										tabFilter={tabFilter}
										handleDeleteOkr={handleDeleteOkr}
										setOpenDrawer={props.setOpenDrawer}
										setModalProps={props.setModalProps}
										setGoalDetail={setGoalDetail}
										goalDetail={goalDetail}
										setDataUpdated={setDataUpdated}
										updateProgress={updateProgress}
										showEditLoader={showEditLoader}
										currentUser={currentUser}
									/>
								) : (
									<></>
								)}
							</Box>
						) : teamStatus === 'pending' || !teamStatus ? (
							<Box className='welcome-content'>
								{!teamStatus ? (
									<Box textAlign='center'>
										<Typography>{t('loading')}</Typography>
									</Box>
								) : (
									<>
										{teamOkrFilterData && teamOkrFilterData.length ? (
											<Box textAlign='center'>
												<Typography>{t('loading')}</Typography>
											</Box>
										) : (
											<Box display='flex' justifyContent='center'>
												<Box textAlign='center'>
													<AnimatedIcon
														className=''
														width='519'
														type='image/svg+xml'
														alt='WelcomeImage'
														imagePath={AlignmentImage}
													/>
													{prevCycleMsg ? (
														<>
															<Typography variant='h2'>{t('NoRecord')}</Typography>
															<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
														</>
													) : (
														<>
															<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
															<Typography>{t('myGoalNoDataMsg2')}</Typography>
														</>
													)}
												</Box>
											</Box>
										)}
									</>
								)}
							</Box>
						) : (
							<Fragment>
								{/* {!alignmentFilterData.length && (teamOkrFilterData && teamOkrFilterData.length) ? ( */}
								{!alignmentFilterData.length ? (
									<Box display='flex' justifyContent='center'>
										<Box textAlign='center'>
											<AnimatedIcon
												className=''
												width='519'
												type='image/svg+xml'
												alt='WelcomeImage'
												imagePath={AlignmentImage}
											/>
											{prevCycleMsg ? (
												<>
													<Typography variant='h2'>{t('NoRecord')}</Typography>
													<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
												</>
											) : (
												<>
													<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
													<Typography>{t('myGoalNoDataMsg2')}</Typography>
												</>
											)}
										</Box>
									</Box>
								) : (
									<Box display='flex' justifyContent='center'>
										<Box className='no-record-message' textAlign='center'>
											<Typography variant='h2' component='h2'>
												{t('NoRecord')}
											</Typography>
											<Typography variant='h3'>{t('allDone')}</Typography>
											{/* <Typography>{t('noOkr')}</Typography> */}
											<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
										</Box>
									</Box>
								)}
							</Fragment>
						)}
					</Fragment>
				) : tabValue === 2 ? (
					<Fragment>
						{tabFilter === 'relaxed' ? (
							<DirectReportsTabs
								{...props}
								userDetail={userDetail}
								tabValue={tabValue}
								tabFilter={tabFilter}
								alignmentFilterData={alignmentFilterData}
							/>
						) : tabFilter === 'compact' ? (
							<DirectReportsTabs
								{...props}
								userDetail={userDetail}
								tabValue={tabValue}
								tabFilter={tabFilter}
								alignmentFilterData={alignmentFilterData}
							/>
						) : tabFilter === 'ultracompact' ? (
							<DirectReportsTabs
								{...props}
								userDetail={userDetail}
								tabValue={tabValue}
								tabFilter={tabFilter}
								alignmentFilterData={alignmentFilterData}
							/>
						) : (
							<></>
						)}
					</Fragment>
				) : (
					<></>
				)}
			</Box>
		</Fragment>
	);
};

import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	listOrgPending: false,
	listOrgSuccess: false,
	listOrgResult: [],
	listOrgError: '',
	addOrgSuccess: false,
	addOrgPending: false,
	addOrgError: false,
	addUpdateOrgStatus: '',
	callApi: false,
	allUsers: [],
	fetchAllUsersSuccess: false,
	fetchAllOrganisationsStatus: false,
	organisations: [],
	cycleMasterData: [],
	parentOrganisation: {},
};

export default function organizationReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_ORG_PENDING:
			return {
				...state,
				listOrgPending: true,
				listOrgSuccess: false,
			};
		case actionTypes.FETCH_ORG_SUCCESS:
			return {
				...state,
				listOrgPending: false,
				listOrgSuccess: true,
				listOrgResult: action.payload,
			};
		case actionTypes.FETCH_ORG_FAILED:
			return {
				...state,
				listOrgPending: false,
				listOrgSuccess: false,
				listOrgError: action.error,
			};
		case actionTypes.ADD_ORG_PENDING:
			return {
				...state,
				addOrgError: false,
				addOrgPending: true,
				addOrgSuccess: false,
				addUpdateOrgStatus: 'pending',
			};
		case actionTypes.ADD_ORG_SUCCESS:
			return {
				...state,
				addOrgError: false,
				addOrgPending: false,
				addOrgSuccess: true,
				addUpdateOrgStatus: 'success',
			};
		case actionTypes.ADD_ORG_FAILED:
			return {
				...state,
				addOrgError: true,
				addOrgPending: false,
				addOrgSuccess: false,
				addUpdateOrgStatus: 'error',
			};
		case actionTypes.CALL_API: {
			return {
				...state,
				callApi: action.payload,
			};
		}
		case actionTypes.FETCH_ALL_USERS_PENDING: {
			return {
				...state,
				allUsers: [],
				allUsersSuccess: false,
			};
		}
		case actionTypes.FETCH_ALL_USERS_SUCCESS: {
			return {
				...state,
				allUsers: action.payload.entity,
				allUsersSuccess: true,
			};
		}
		case actionTypes.FETCH_ALL_USERS_FAILED: {
			return {
				...state,
				allUsers: action.error,
				allUsersSuccess: false,
			};
		}

		case actionTypes.FETCH_ALL_ORGANISATIONS_PENDING: {
			return {
				...state,
				fetchAllOrganisationsStatus: 'pending',
				organisations: [],
			};
		}
		case actionTypes.FETCH_ALL_ORGANISATIONS_SUCCESS: {
			return {
				...state,
				fetchAllOrganisationsStatus: 'success',
				organisations: action.payload.entityList,
			};
		}
		case actionTypes.FETCH_ALL_ORGANISATIONS_FAILED: {
			return {
				...state,
				fetchAllOrganisationsStatus: 'failed',
				organisations: action.error,
			};
		}
		case actionTypes.FETCH_CYCLES_PENDING: {
			return {
				...state,
				cycleMasterData: [],
			};
		}
		case actionTypes.FETCH_CYCLES_SUCCESS: {
			return {
				...state,
				cycleMasterData: action.payload.entityList,
			};
		}
		case actionTypes.FETCH_CYCLES_FAILED: {
			return {
				...state,
				cycleMasterData: action.error,
			};
		}

		case actionTypes.GET_PARENT_ORGANISATION_PENDING: {
			return {
				...state,
				parentOrganisation: {},
			};
		}
		case actionTypes.GET_PARENT_ORGANISATION_SUCCESS: {
			return {
				...state,
				parentOrganisation: action.payload.entity,
			};
		}
		case actionTypes.GET_PARENT_ORGANISATION_FAILED: {
			return {
				...state,
				parentOrganisation: action.error,
			};
		}
		case actionTypes.RESET_ORGANISATION: {
			return {
				...state,
				parentOrganisation: {},
			};
		}
		case actionTypes.UPDATE_ORG_LIST:
			return {
				...state,
				listOrgPending: false,
				listOrgSuccess: false,
				listOrgResult: [],
			};

		default:
			return state;
	}
}

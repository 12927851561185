/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import {
	Button,
	FormControl,
	Drawer,
	IconButton,
	Typography,
	TextField,
	InputLabel,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	FormLabel,
	Collapse,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import GlobalSearch from '../../Common/GlobalSearch';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { CalenderIcon, OrganizationIcon, CameraIcon } from '../../../config/svg/CommonSvg';
import LinearLoader from '../../Common/Loader';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import { CancelIcon } from '../../../config/svg/Action';
import AlertDialog from '../../Common/Dialog';
import { getMonthFirstDate } from '../../../config/utils';
import Tree from '../../ChartD3/Tree';
import { useTranslation } from 'react-i18next';
import LogoEditor from './LogoEditor';

export default function CreateOrganization(props) {
	const { enqueueSnackbar } = useSnackbar();
	const { createType, currentItem, closeSlider, header, getOrganization, uploadLogo, updateOrganization, open } = props;
	const { t } = useTranslation();
	const defaultValue = {
		organisationName: '',
		organisationLeader: 0,
		imagePath: '',
		cycleStartDate: new Date(),
		cycleDuration: 0,
		isPrivate: false,
		parentName: '',
		cycleDurationId: 0,
		leaderId: 0,
		leaderName: '',
		logoName: '',
		organisationLogo: '',
	};
	const [loader, setLoader] = useState(false);
	const [orgData, setOrgData] = useState(defaultValue);
	const [formError, setFormError] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [updateData, setUpdateData] = useState(defaultValue);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isImageEditor, setIsImageEditor] = useState(false);
	const [uploadedImage, setUploadedImage] = useState(defaultValue.imagePath || '');
	const [leaderId, setLeaderId] = useState(0);
	const [modalProps, setModalProps] = useState({
		open: false,
		data: null,
		message: '',
	});
	const [updateValue, setUpdateValue] = useState(false);

	useEffect(() => {
		if (createType === 'editOrg') {
			setLoader(true);
			const data = `organisationId=${currentItem.organisationId}`;
			getOrganization(data)
				.then((response) => {
					setLoader(false);
					if (response && response.data && response.data.entity) {
						setUpdateData(response.data.entity);
						const {
							organisationName,
							organisationLeader,
							organisationLogo,
							cycleStartDate,
							cycleDurationId,
							isPrivate,
							leaderName,
							logoName,
						} = response.data.entity;
						setLeaderId(organisationLeader);
						setOrgData({
							organisationName,
							organisationLeader,
							imagePath: Boolean(organisationLogo) && organisationLogo !== 'string' ? organisationLogo : '',
							cycleStartDate: new Date(cycleStartDate),
							cycleDuration: cycleDurationId,
							isPrivate,
							leaderName,
							logoName,
						});
						setUploadedImage(Boolean(organisationLogo) && organisationLogo !== 'string' ? organisationLogo : '');
					}
				})
				.catch((error) => {
					setLoader(false);
					enqueueSnackbar(t('fetchOrganizationFail'), {
						variant: 'error',
						autoHideDuration: 5000,
						TransitionComponent: Collapse,
					});
				});
		}
		return () => {
			setOrgData({});
		};
	}, [createType]);

	const handleDrawerConfirmation = () => {
		if (JSON.stringify(orgData) !== JSON.stringify(defaultValue) && !checkForUpdate()) {
			setModalOpen(true);
		} else {
			closeSlider();
		}
	};

	const handleCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			closeSlider();
		}
		setModalOpen(false);
	};

	const handleCloseModal1 = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			setIsUpdate(true);
			setOrgData({ ...orgData, organisationLeader: modalProps.data[0].employeeId });
			setLeaderId(modalProps.data[0].employeeId);
		} else {
			setIsUpdate(true);
			setOrgData({ ...orgData, organisationLeader: leaderId });
			setUpdateValue(true);
		}
		setModalProps({
			...modalProps,
			open: false,
		});
	};

	const checkForUpdate = () => {
		const { organisationName, organisationLeader, cycleDurationId, isPrivate, leaderName, logoName, imagePath } =
			updateData;

		if (
			organisationName !== orgData.organisationName ||
			organisationLeader !== orgData.organisationLeader ||
			logoName !== orgData.logoName ||
			leaderName !== orgData.leaderName ||
			isPrivate !== orgData.isPrivate ||
			cycleDurationId !== orgData.cycleDuration ||
			imagePath !== orgData.imagePath ||
			isUpdate
		) {
			return false;
		}

		return true;
	};

	const submitRequest = async (e, type) => {
		if (validRequest()) {
			try {
				setLoader(true);
				let data = orgData;
				if (orgData.logoName && orgData.logoUploaded) {
					try {
						const logoDetails = await uploadLogoHandler();
						data.imagePath = logoDetails;
					} catch (err) {
						enqueueSnackbar(t('uploadingLogoError'), {
							variant: 'error',
							autoHideDuration: 5000,
							TransitionComponent: Collapse,
						});
					}
				} else {
					if (!uploadedImage) {
						data.imagePath = '';
					}
				}
				data.cycleDuration = parseInt(data.cycleDuration);

				let resp = '';
				if (createType === 'addOrg') {
					resp = await props.addOrg(data);
				} else if (createType === 'addParent') {
					data.organisationId = currentItem.organisationId;
					data.parentName = data.organisationName;
					data.leaderId = data.organisationLeader ? parseInt(data.organisationLeader) : 0;
					resp = await props.addParent(data);
				} else if (createType === 'editOrg') {
					data.organisationId = currentItem.organisationId;
					resp = await updateOrganization(data);
				}

				if (type === 1) {
					setOrgData(defaultValue);
				} else if (type === 2 && resp.data.status !== 400) {
					setOrgData(defaultValue);
					closeSlider();
				}

				if (resp.status === 200) {
					Tree.updateData(props);
					setLoader(false);
					let message = t('orgAddedSuccess');
					if (resp.data.status === 400) {
						const responseAPI = resp.data.messageList;
						const keys = Object.keys(responseAPI);
						const messages = keys.map((item) => responseAPI[item]);
						enqueueSnackbar(`${messages} `, {
							variant: 'error',
							autoHideDuration: 5000,
							TransitionComponent: Collapse,
						});
					} else {
						if (createType === 'addOrg') {
							enqueueSnackbar(`${message} `, {
								variant: 'success',
								autoHideDuration: 5000,
								TransitionComponent: Collapse,
							});
						} else if (createType === 'addParent') {
							message = t('orgParentAddedSuccess');
							enqueueSnackbar(`${message} `, {
								variant: 'success',
								autoHideDuration: 5000,
								TransitionComponent: Collapse,
							});
						} else if (createType === 'editOrg') {
							message = t('orgUpdatedSuccess');
							enqueueSnackbar(`${message} `, {
								variant: 'success',
								autoHideDuration: 5000,
								TransitionComponent: Collapse,
							});
						}
					}
				} else {
					setLoader(false);
					enqueueSnackbar(t('saveOrgFail'), {
						variant: 'error',
						autoHideDuration: 5000,
						TransitionComponent: Collapse,
					});
				}
			} catch (err) {
				setLoader(false);
				enqueueSnackbar(t('saveOrgFail'), { variant: 'error', autoHideDuration: 5000, TransitionComponent: Collapse });
			}
		} else {
			setLoader(false);
			//enqueueSnackbar('Please fill mandatory details ', { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const validRequest = () => {
		setFormError({});
		if (orgData) {
			setFormError({
				...formError,
				organisationName: !orgData.organisationName ? true : false,
				cycleDuration: !orgData.cycleDuration ? true : false,
				cycleStartDate: !orgData.cycleStartDate ? true : false,
			});
			if (!orgData.organisationName || !orgData.cycleStartDate || !orgData.cycleDuration) {
				return false;
			}
		}
		return true;
	};

	const handleChange = (e, name) => {
		const { target } = e;
		if (name === 'isPrivate') {
			setOrgData({ ...orgData, isPrivate: target.checked });
		} else {
			setOrgData({ ...orgData, [name]: target.value });
		}
	};
	const handleDateChange = (date) => {
		setIsUpdate(true);
		setOrgData({ ...orgData, cycleStartDate: date });
	};

	const updateOrgDataImagePath = (formData, imageBase64) => {
		if (formData && imageBase64) {
			formData && setOrgData({ ...orgData, imagePath: formData, logoUploaded: true });
			imageBase64 && setUploadedImage(imageBase64);
		} else {
			setOrgData({ ...orgData, imagePath: '', logoUploaded: false });
			setUploadedImage('');
		}
	};
	const uploadLogoHandler = async () => {
		if (orgData.imagePath) {
			// const formData = new FormData();
			// formData.append('file', orgData.imagePath);
			// const response = await uploadLogo(formData);
			const response = await uploadLogo(orgData.imagePath);
			if (Boolean(response) && response.data && response.data.entity) {
				setOrgData({ ...orgData, imagePath: response.data.entity });
				return response.data.entity;
			}
		}
	};

	const handleFileChange = async (e, files) => {
		setIsUpdate(true);
		setFormError({});
		const file = files ? files : e.target.files[0];
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = ['image/jpg', 'image/svg+xml', 'image/png', 'image/jpeg'];
		if (size >= 1024) {
			setFormError({ fileSize: true });
		} else if (!format.includes(fileType.toLowerCase())) {
			setFormError({ fileType: true });
		} else {
			let fileData = { ...orgData, logoName: file.name, organisationLogo: file, logoUploaded: true };
			fileData = files ? fileData : { ...orgData, imagePath: file };
			setFormError({});
			setOrgData(fileData);
		}
	};

	const onSelectedUser = (selected) => {
		if (selected.length > 0) {
			if (leaderId !== 0 && leaderId !== selected[0].employeeId) {
				setModalProps({
					open: true,
					message: t('changeOrgLeader', {
						teamName: orgData.organisationName,
					}),
					data: selected,
				});
			} else {
				setIsUpdate(true);
				setOrgData({ ...orgData, organisationLeader: selected[0].employeeId });
			}
		} else {
			setIsUpdate(true);
			setOrgData({ ...orgData, organisationLeader: 0 });
		}
	};

	const removeLogo = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsUpdate(true);
		setOrgData({ ...orgData, imagePath: '', logoName: '', organisationLogo: '' });
		setUploadedImage('');
	};

	const showImageEditor = (_event, open) => {
		setIsImageEditor(open);
	};

	return (
		<Fragment>
			<Drawer
				className='okr-drawer edit-org-drawer'
				anchor='right'
				open={open}
				onEscapeKeyDown={handleDrawerConfirmation}
				onBackdropClick={handleDrawerConfirmation}
			>
				<IconButton className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerConfirmation}>
					<RightArrowIcon />
				</IconButton>
				<div className='okr-drawer-area create-org-area'>
					{isImageEditor ? (
						<LogoEditor
							{...props}
							orgData={orgData}
							handleFileChange={handleFileChange}
							removeLogo={removeLogo}
							showImageEditor={showImageEditor}
							updateOrgDataImagePath={updateOrgDataImagePath}
						></LogoEditor>
					) : (
						<Fragment>
							<div className='drawer-head'>
								<Typography variant='h3'>{header}</Typography>
							</div>
							{loader && <LinearLoader />}
							<div className='okr-drawer-content' id='custom-scroll'>
								<div className='okr-drawer-content-inner'>
									<div className='okr-drawer-form-area'>
										<form className='okr-form-fields' noValidate autoComplete='off'>
											<TextField
												required
												className='org-name'
												id='org-name'
												label={t('orgNameLabel')}
												placeholder={t('orgNamePlaceholder')}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												error={formError.organisationName}
												helperText={formError.organisationName && t('orgNameRequired')}
												value={orgData.organisationName}
												onChange={(e) => handleChange(e, 'organisationName')}
											/>
											<FormControl className='rbt-input-single-icon' variant='outlined' style={{ width: '100%' }}>
												<InputLabel shrink htmlFor='obj-cycle'>
													{t('leaderLabelText')}
												</InputLabel>
												<GlobalSearch
													onSelectedUser={onSelectedUser}
													{...props}
													module='organization'
													defaultValue={createType === 'editOrg' ? orgData.organisationLeader : ''}
													updateValue={updateValue}
													setUpdateValue={setUpdateValue}
													customId={'create-org-src'}
												/>
											</FormControl>
											{((currentItem && currentItem.parentId === 0) || createType === 'addOrg') && (
												<Fragment>
													<FormControl className='upload-wrapper' variant='outlined' style={{ width: '100%' }}>
														<FormLabel>{t('uploadLogoLabel')}</FormLabel>
														<div
															className='organization-logo-wrapper'
															style={{ cursor: 'pointer' }}
															onClick={(event) => showImageEditor(event, true)}
														>
															{orgData.imagePath ? (
																<img
																	className='org-logo'
																	src={uploadedImage ? uploadedImage : ''}
																	alt={orgData.logoName ? orgData.logoName : ''}
																/>
															) : (
																<div className='org-logo no-org-image'>
																	<OrganizationIcon />
																</div>
															)}
															<div className='camera-icon'>
																<label htmlFor='file-input' className='upload-image-label'>
																	<CameraIcon />
																</label>
																<input
																	className='upload-image-file-input'
																	accept='image/x-png,image/gif,image/jpeg'
																	type='file'
																	id='file-input'
																></input>
															</div>
														</div>
														<Typography variant='body2'>Note: {t('orgFileTypeMsg')}</Typography>
														{formError.fileSize && (
															<Typography color='error' variant='body2' style={{ color: 'red' }}>
																{t('fileSize1MB')}
															</Typography>
														)}
														{formError.fileType && (
															<Typography color='error' variant='body2' style={{ color: 'red' }}>
																{t('fileType4OrgLogo')}
															</Typography>
														)}
													</FormControl>
												</Fragment>
											)}
											<div className='due-date calender-date'>
												<FormLabel required>{t('orgCycleStart')}</FormLabel>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														disableToolbar
														autoOk
														variant='inline'
														format='MMMM d, yyyy'
														id='start-date'
														label={t('startDateLabel')}
														keyboardIcon={<CalenderIcon />}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														minDate={createType !== 'editOrg' ? getMonthFirstDate() : null}
														value={orgData.cycleStartDate}
														onChange={(e) => handleDateChange(e)}
														inputProps={{ readOnly: true }}
														disabled={createType === 'editOrg'} //&& currentItem && currentItem.parentId !== 0
													/>
												</MuiPickersUtilsProvider>
												{formError.cycleStartDate && (
													<Typography color='error' variant='body1' style={{ color: 'red' }}>
														{t('startDateRequired')}
													</Typography>
												)}
											</div>
											<FormControl variant='outlined' style={{ width: '100%' }}>
												<FormLabel required>{t('cycleLabel')}</FormLabel>
												<RadioGroup
													row
													aria-label='cycleDuration'
													name='cycleDuration'
													value={`${orgData.cycleDuration}`}
													onChange={(e) => handleChange(e, 'cycleDuration')}
												>
													<FormControlLabel
														disabled={createType === 'editOrg' && currentItem && currentItem.parentId !== 0}
														value={'1'}
														control={<Radio />}
														label={t('quarterLabel')}
													/>
													<FormControlLabel
														disabled={createType === 'editOrg' && currentItem && currentItem.parentId !== 0}
														value={'2'}
														control={<Radio />}
														label={t('halfYearLabel')}
													/>
													<FormControlLabel
														disabled={createType === 'editOrg' && currentItem && currentItem.parentId !== 0}
														value={'3'}
														control={<Radio />}
														label={t('yearLabel')}
													/>
													<FormControlLabel
														disabled={createType === 'editOrg' && currentItem && currentItem.parentId !== 0}
														value={'4'}
														control={<Radio />}
														label={t('3YearLabel')}
													/>
												</RadioGroup>
												{formError.cycleDuration && (
													<Typography color='error' variant='body1' style={{ color: 'red' }}>
														{t('cycleRequired')}
													</Typography>
												)}
											</FormControl>
											{/* <FormControl variant='outlined' style={{ width: '100%' }}>
												<FormLabel>{t('objectiveTypeLabel')}</FormLabel>
												<FormControlLabel
													control={
														<Checkbox
															id='private'
															checked={orgData.isPrivate ? true : false}
															color='primary'
															onChange={(e) => handleChange(e, 'isPrivate')}
														/>
													}
													label={t('privateLabel')}
												/>
												<Typography variant='body2'>{t('privateGoalLabel')}</Typography>
											</FormControl> */}
											<div className='okr-form-action'>
												{createType !== 'editOrg' && (
													<Fragment>
														<Button className='button-blue-border' color='primary' onClick={(e) => submitRequest(e, 2)}>
															{t('saveAndExitBtn')}
														</Button>
														<Button
															className='submitBtn btn-primary'
															color='primary'
															onClick={(e) => submitRequest(e, 1)}
														>
															{t('saveAndAddBtn')}
														</Button>
													</Fragment>
												)}
												{createType === 'editOrg' && (
													<Fragment>
														<Button className='cancel-btn btn-link' color='primary' onClick={handleDrawerConfirmation}>
															{t('cancelBtn')}
														</Button>
														<Button
															disabled={checkForUpdate()}
															className='submitBtn btn-primary'
															color='primary'
															onClick={(e) => submitRequest(e, 2)}
														>
															{t('saveAndExitBtn')}
														</Button>
													</Fragment>
												)}
											</div>
										</form>
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</Drawer>
			{modalOpen && (
				<AlertDialog
					module='organization'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
			{modalProps && modalProps.open && (
				<AlertDialog
					module='organization'
					message={modalProps.message}
					handleCloseModal={handleCloseModal1}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
}

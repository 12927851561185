import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { ImportOKR } from '.';
import AlertDialog from '../../Common/Dialog';
import { getLocalStorageItem } from '../../../services/StorageService';
import { getUserDetails } from '../../../config/utils';

export const ImportOkrsDrawer: React.FC<any> = (props) => {
	const { t, openImportDrawer, loader, handleImportDrawerClose } = props;
	const [okrKrSelectionData, setOkrKrSelectionData] = useState<any>({});
	const [importButtonDisabled, setImportButtonDisabled] = useState<boolean>(false);
	const [tabSelected, setTabSelected] = React.useState<Number>();
	const [modalProps, setModalProps] = useState({
		open: false,
		data: null,
		message: '',
		type: '',
	});
	const userDetail: any = getUserDetails();

	const handleImportDrawerAction = (event: any, type: string) => {
		if (tabSelected === 2 && Object.keys(okrKrSelectionData).length > 0) {
			if (type === 'cancel') {
				setModalProps({
					...modalProps,
					open: true,
					message: t('unSavedItemAlert'),
					type: 'import',
				});
			} else {
				handleImportLibraryDrawerClose(okrKrSelectionData, type);
			}
		} else {
			handleImportLibraryDrawerClose({}, 'cancel');
		}
	};

	const handleImportLibraryDrawerClose = async (data: any, type: string) => {
		if (type === 'cancel') {
			props.setOpenImportDrawer(false);
		} else {
			setImportButtonDisabled(true);
			let importOkrData: any[] = [];
			const selectedYear = getLocalStorageItem('year') || props.year;
			Object.keys(data).forEach((okrKey: any) => {
				let importKrData: any = {
					employeeId: userDetail.employeeId,
					year: selectedYear,
					objectiveName: data[okrKey].okrName,
					objectiveCycleId: props.cycleId,
					goalNatureId: 0,
					importKeyResults: []
				}
				Object.keys(data[okrKey].krList).forEach((krKey: any) => {
					importKrData.importKeyResults.push({
						keyDescription: data[okrKey].krList[krKey],
						metricId: 0,
						currencyId: 0,
						currencyCode: ""
					})
				});
				importOkrData.push(importKrData);
			});
			const response = await props.postOkrImport(importOkrData);
			setImportButtonDisabled(false);
			if (Boolean(response) && response.data.status === 200) {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				props.showApiMsgs(`${messages} `, 'success');
				props.setOpenImportDrawer(false);
				props.fetchOkr();

			} else {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				props.showApiMsgs(`${messages} `, 'error');
				props.setLoader(false);
			}
		}
	};

	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			setOkrKrSelectionData({});
			handleImportLibraryDrawerClose({}, 'cancel');
		}
		setModalProps({
			...modalProps,
			open: false,
		});
	};

	return (
		<>
			<OKRDrawer
				open={openImportDrawer}
				loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel '}
				headerTitle={t('importObjLevel')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-panel'>
							<ImportOKR
								{...props}
								okrKrSelectionData={okrKrSelectionData}
								setOkrKrSelectionData={setOkrKrSelectionData}
								tabSelected={tabSelected}
								setTabSelected={setTabSelected}
							/>
						</Box>
					</Box>
				}
				isSaveButtonDisabled={tabSelected === 2 && (Object.keys(okrKrSelectionData).length === 0 || importButtonDisabled)}
				moreButton={false}
				saveButtonText={t('importBtnLevel')}
				handleDrawerClose={(event) => handleImportDrawerAction(event, 'cancel')}
				handleSaveClick={(event) => handleImportDrawerAction(event, 'save')}
				handleCancelClick={(event) => handleImportDrawerAction(event, 'cancel')}
			/>

			{modalProps.open && (
				<AlertDialog
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};

import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	listOkrPending: false,
	listOkrSuccess: '',
	listOkrResult: [],
	listOkrError: '',
	listAlignOkrPending: false,
	listAlignOkrSuccess: false,
	listAlignOkrResult: [],
	listAlignOkrError: '',
	addGoalsStatus: '',
	editGoalsStatus: '',
	addGoalsSuccess: false,
	addGoalsPending: false,
	addGoalsError: false,
	updateKrProgressStatus: '',
	updateKrProgressSuccess: false,
	updateKrProgressPending: false,
	updateKrProgressError: false,
	alignGoalsStatus: '',
	listOkrStatusSuccess: '',
	listOkrStatusResult: [],
	deltaScoreStatus: '',
	deltaScoreResult: [],
	archiveList: [],
	archiveListStatus: '',
	dashboardProgressData: [],
	dashboardProgressStatus: '',
	teamsOkrProgressData: [],
	teamsOkrProgressStatus: '',
	tasks: [],
	taskStatus: '',
	checkInData: {},
	checkInStatus: '',
	freeTrialData: {},
	freeTrialStatus: '',
	freeTrialEmailIdData: {},
	freeTrialEmailIdStatus: '',
	freeTrialDomainData: {},
	freeTrialDomainStatus: '',
	freeTrialPasswordData: {},
	freeTrialPasswordStatus: '',
	okrImportData: {},
	okrImportDataStatus: '',
	okrImportCategoryData: {},
	okrImportCategoryDataStatus: '',
};

export default function okrReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GET_OKR_MASTER_PENDING:
			return {
				...state,
				okrMasterData: [],
				okrMasterDataStatus: 'pending',
			};
		case actionTypes.GET_OKR_MASTER_SUCCESS:
			return {
				...state,
				okrMasterDataStatus: 'success',
				okrMasterData: action.payload.entity,
			};
		case actionTypes.GET_OKR_MASTER_FAILED:
			return {
				...state,
				okrMasterDataStatus: 'error',
				listOkrError: action.error,
			};
		case actionTypes.FETCH_OKR_PENDING:
			return {
				...state,
				listOkrPending: true,
				listOkrSuccess: 'pending',
			};
		case actionTypes.FETCH_OKR_SUCCESS:
			return {
				...state,
				listOkrPending: false,
				listOkrSuccess: 'success',
				listOkrResult: action.payload.entity,
			};
		case actionTypes.FETCH_OKR_FAILED:
			return {
				...state,
				listOkrPending: false,
				listOkrSuccess: 'error',
				listOkrError: action.error,
			};
		case actionTypes.FETCH_ALIGN_OKR_PENDING:
			return {
				...state,
				listAlignOkrPending: true,
				listAlignOkrSuccess: false,
			};
		case actionTypes.FETCH_ALIGN_OKR_SUCCESS:
			return {
				...state,
				listAlignOkrPending: false,
				listAlignOkrSuccess: true,
				listAlignOkrResult: action.payload.entity,
			};
		case actionTypes.FETCH_ALIGN_OKR_FAILED:
			return {
				...state,
				listAlignOkrPending: false,
				listAlignOkrSuccess: false,
				listAlignOkrError: action.error,
			};
		case actionTypes.ADD_GOALS_PENDING:
			return {
				...state,
				addGoalsError: false,
				addGoalsPending: true,
				addGoalsSuccess: false,
				addGoalsStatus: 'pending',
			};
		case actionTypes.ADD_GOALS_SUCCESS:
			return {
				...state,
				addGoalsError: false,
				addGoalsPending: false,
				addGoalsSuccess: true,
				addGoalsStatus: 'success',
			};
		case actionTypes.ADD_GOALS_FAILED:
			return {
				...state,
				addGoalsError: true,
				addGoalsPending: false,
				addGoalsSuccess: false,
				addGoalsStatus: 'error',
			};

		case actionTypes.EDIT_GOALS_PENDING:
			return {
				...state,
				editGoalsStatus: 'pending',
			};
		case actionTypes.EDIT_GOALS_SUCCESS:
			return {
				...state,
				editGoalsStatus: 'success',
			};
		case actionTypes.EDIT_GOALS_FAILED:
			return {
				...state,
				editGoalsStatus: 'error',
			};
		case actionTypes.UPDATE_KR_PROGRESS_PENDING:
			return {
				...state,
				updateKrProgressError: false,
				updateKrProgressPending: true,
				updateKrProgressSuccess: false,
				updateKrProgressStatus: 'pending',
			};
		case actionTypes.UPDATE_KR_PROGRESS_SUCCESS:
			return {
				...state,
				updateKrProgressError: false,
				updateKrProgressPending: false,
				updateKrProgressSuccess: true,
				updateKrProgressStatus: 'success',
			};
		case actionTypes.UPDATE_KR_PROGRESS_FAILED:
			return {
				...state,
				updateKrProgressError: true,
				updateKrProgressPending: false,
				updateKrProgressSuccess: false,
				updateKrProgressStatus: 'error',
			};
		case actionTypes.FETCH_OKR_STATUS_PENDING:
			return {
				...state,
				listOkrStatusSuccess: 'pending',
				listOkrStatusResult: [],
			};
		case actionTypes.FETCH_OKR_STATUS_SUCCESS:
			return {
				...state,
				listOkrStatusSuccess: 'success',
				listOkrStatusResult: action.payload.entity,
			};
		case actionTypes.FETCH_OKR_STATUS_FAILED:
			return {
				...state,
				listOkrStatusSuccess: 'error',
				listOkrStatusResult: [],
			};
		case actionTypes.ALIGN_GOALS_PENDING:
			return {
				...state,
				alignGoalsStatus: 'pending',
			};
		case actionTypes.ALIGN_GOALS_SUCCESS:
			return {
				...state,
				alignGoalsStatus: 'success',
			};
		case actionTypes.ALIGN_GOALS_FAILED:
			return {
				...state,
				alignGoalsStatus: 'error',
			};
		case actionTypes.UPDATE_GOAL_STATUS: {
			return {
				...state,
				alignGoalsStatus: '',
				addGoalsStatus: '',
				editGoalsStatus: '',
				addGoalsSuccess: false,
				addGoalsPending: false,
				addGoalsError: false,
				updateKrProgressStatus: '',
				updateKrProgressSuccess: false,
				updateKrProgressPending: false,
				updateKrProgressError: false,
				listAlignOkrPending: false,
				listAlignOkrSuccess: false,
				listAlignOkrResult: [],
				listAlignOkrError: '',
				listOkrError: '',
			};
		}
		case actionTypes.FETCH_DELTA_SCORE_PENDING: {
			return {
				...state,
				deltaScoreStatus: 'pending',
				deltaScoreResult: [],
			};
		}
		case actionTypes.FETCH_DELTA_SCORE_SUCCESS: {
			return {
				...state,
				deltaScoreStatus: 'success',
				deltaScoreResult: action.payload.entity,
			};
		}
		case actionTypes.FETCH_DELTA_SCORE_FAILED: {
			return {
				...state,
				deltaScoreStatus: 'error',
				deltaScoreResult: [],
			};
		}
		case actionTypes.FETCH_RECENT_CONTRIBUTION_PENDING: {
			return {
				...state,
				recentContributorStatus: 'pending',
				recentContibutorSuccess: false,
				recentContributorResult: [],
			};
		}
		case actionTypes.FETCH_RECENT_CONTRIBUTION__SUCCESS: {
			return {
				...state,
				recentContributorStatus: 'success',
				recentContibutorSuccess: true,
				recentContributorResult: action.payload.entityList ? action.payload.entityList : [],
			};
		}
		case actionTypes.FETCH_RECENT_CONTRIBUTION__FAILED: {
			return {
				...state,
				recentContributorStatus: 'error',
				recentContibutorSuccess: false,
				recentContributorResult: [],
			};
		}
		case actionTypes.RESET_OKR: {
			return {
				...INITIAL_STATE,
				okrMasterData: state.okrMasterData,
			};
		}
		case actionTypes.ARCHIVE_OKR_PENDING: {
			return {
				...state,
				archiveList: [],
				archiveListStatus: 'pending',
			};
		}
		case actionTypes.ARCHIVE_OKR_SUCCESS: {
			return {
				...state,
				archiveList: action.payload.entity,
				archiveListStatus: 'success',
			};
		}
		case actionTypes.ARCHIVE_OKR_FAILED: {
			return {
				...state,
				archiveList: [],
				archiveListStatus: 'error',
			};
		}
		case actionTypes.DASHBOARD_PROGRESS_PENDING: {
			return {
				...state,
				// dashboardProgressData: [],
				dashboardProgressStatus: 'pending',
			};
		}
		case actionTypes.DASHBOARD_PROGRESS_SUCCESS: {
			return {
				...state,
				dashboardProgressData: action.payload.entity,
				dashboardProgressStatus: 'success',
			};
		}
		case actionTypes.DASHBOARD_PROGRESS_FAILED: {
			return {
				...state,
				dashboardProgressData: [],
				dashboardProgressStatus: 'error',
			};
		}
		case actionTypes.TEAMSOKR_PROGRESS_PENDING: {
			return {
				...state,
				// dashboardProgressData: [],
				teamsOkrProgressStatus: 'pending',
			};
		}
		case actionTypes.TEAMSOKR_PROGRESS_SUCCESS: {
			return {
				...state,
				teamsOkrProgressData: action.payload.entity,
				teamsOkrProgressStatus: 'success',
			};
		}
		case actionTypes.TEAMSOKR_PROGRESS_FAILED: {
			return {
				...state,
				teamsOkrProgressData: [],
				teamsOkrProgressStatus: 'error',
			};
		}
		case actionTypes.TASK_PENDING: {
			return {
				...state,
				taskStatus: 'pending',
				tasks: [],
			};
		}
		case actionTypes.TASK_SUCCESS: {
			return {
				...state,
				tasks: action.payload.entityList,
				taskStatus: 'success',
			};
		}
		case actionTypes.TASK_FAILED: {
			return {
				...state,
				tasks: [],
				taskStatus: 'error',
			};
		}
		case actionTypes.RESET_TASK: {
			return {
				...state,
				tasks: [],
				taskStatus: '',
			};
		}
		case actionTypes.CHECKIN_STATUS_PENDING: {
			return {
				...state,
				checkInData: {},
				checkInStatus: 'pending',
			};
		}
		case actionTypes.CHECKIN_STATUS_SUCCESS: {
			return {
				...state,
				checkInData: action.payload.entity,
				checkInStatus: 'success',
			};
		}
		case actionTypes.CHECKIN_STATUS_FAILED: {
			return {
				...state,
				checkInData: {},
				taskStatus: 'failed',
			};
		}
		case actionTypes.CONFIDENCE_ALERT_PENDING: {
			return {
				...state,
				confidenceAlertData: {},
				confidenceAlertStatus: 'pending',
			};
		}
		case actionTypes.CONFIDENCE_ALERT_SUCCESS: {
			return {
				...state,
				confidenceAlertData: action.payload.entity,
				confidenceAlertStatus: 'success',
			};
		}
		case actionTypes.CONFIDENCE_ALERT_FAILED: {
			return {
				...state,
				confidenceAlertData: {},
				confidenceAlertStatus: 'failed',
			};
		}
		case actionTypes.FREE_TRIAL_PENDING: {
			return {
				...state,
				freeTrialData: {},
				freeTrialStatus: 'pending',
			};
		}
		case actionTypes.FREE_TRIAL_SUCCESS: {
			return {
				...state,
				freeTrialData: action.payload.messageList,
				freeTrialStatus: 'success',
			};
		}
		case actionTypes.FREE_TRIAL_FAILED: {
			return {
				...state,
				freeTrialData: {},
				freeTrialStatus: 'failed',
			};
		}
		case actionTypes.FREE_TRIAL_EMAILID_PENDING: {
			return {
				...state,
				freeTrialEmailIdData: {},
				freeTrialEmailIdStatus: 'pending',
			};
		}
		case actionTypes.FREE_TRIAL_EMAILID_SUCCESS: {
			return {
				...state,
				freeTrialEmailIdData: action.payload.messageList.emailId,
				freeTrialEmailIdStatus: 'success',
			};
		}
		case actionTypes.FREE_TRIAL_EMAILID_FAILED: {
			return {
				...state,
				freeTrialEmailIdData: {},
				freeTrialEmailIdStatus: 'failed',
			};
		}
		case actionTypes.FREE_TRIAL_DOMAIN_PENDING: {
			return {
				...state,
				freeTrialDomainData: {},
				freeTrialDomainStatus: 'pending',
			};
		}
		case actionTypes.FREE_TRIAL_DOMAIN_SUCCESS: {
			return {
				...state,
				freeTrialDomainData: action.payload.messageList.emailId,
				freeTrialDomainStatus: 'success',
			};
		}
		case actionTypes.FREE_TRIAL_DOMAIN_FAILED: {
			return {
				...state,
				freeTrialDomainData: {},
				freeTrialDomainStatus: 'failed',
			};
		}
		case actionTypes.FREE_TRIAL_PASSWORD_PENDING: {
			return {
				...state,
				freeTrialPasswordData: {},
				freeTrialPasswordStatus: 'pending',
			};
		}
		case actionTypes.FREE_TRIAL_PASSWORD_SUCCESS: {
			return {
				...state,
				freeTrialPasswordData: action.payload.messageList.emailId,
				freeTrialPasswordStatus: 'success',
			};
		}
		case actionTypes.FREE_TRIAL_PASSWORD_FAILED: {
			return {
				...state,
				freeTrialPasswordData: {},
				freeTrialPasswordStatus: 'failed',
			};
		}
		case actionTypes.OKR_IMPORT_PENDING: {
			return {
				...state,
				okrImportData: {},
				okrImportDataStatus: 'pending',
			};
		}
		case actionTypes.OKR_IMPORT_SUCCESS: {
			return {
				...state,
				okrImportData: action.payload.entity,
				okrImportDataStatus: 'success',
			};
		}
		case actionTypes.OKR_IMPORT_FAILED: {
			return {
				...state,
				okrImportData: {},
				okrImportDataStatus: 'failed',
			};
		}
		case actionTypes.OKR_IMPORT_CATEGORY_PENDING: {
			return {
				...state,
				okrImportCategoryData: {},
				okrImportCategoryDataStatus: 'pending',
			};
		}
		case actionTypes.OKR_IMPORT_CATEGORY_SUCCESS: {
			return {
				...state,
				okrImportCategoryData: action.payload.entityList,
				okrImportCategoryDataStatus: 'success',
			};
		}
		case actionTypes.OKR_IMPORT_CATEGORY_FAILED: {
			return {
				...state,
				okrImportCategoryData: {},
				okrImportCategoryDataStatus: 'failed',
			};
		}
		default:
			return state;
	}
}

export enum TemplateType {
	Okr = 1,
}
export class DiagramJsonHelper {
	static bindProps = {
		okrId: 'objectiveUniqueId',
		okrParent: 'parent',
		krId: 'krUniqueId',
		krParent: 'parentId',
		staKrParent: 'krParentId',
	};
	static diagramIns: any;
	static jsonData: any;
	static allKrNodes = [];
	static allKrLink = [];
	constructor() {}
	static SetDiagramIns(diagramIns: any) {
		this.diagramIns = diagramIns;
	}
	static SetData(jsonData: any) {
		this.jsonData = jsonData;
	}
	static UpdateHelperProperties(jsonData: any) {
		this.SetData(jsonData);
		for (let i = 0; i < this.jsonData.length; i++) {
			let data = this.jsonData[i];
			data['visible'] = false;
			data['isExpanded'] = false;
			data['jsonNode'] = true;
			if (data.objectiveType === 2 && data[this.bindProps.staKrParent]) {
				let parentKrData = this.GetKrDataById(data[this.bindProps.staKrParent].toString(), true);
				if (parentKrData) {
					if (!parentKrData.childOkrs) {
						parentKrData.childOkrs = [];
					}
					if (!parentKrData.childOkrs.find((c: string) => c === 'sta:' + data[this.bindProps.krId]))
						parentKrData.childOkrs.push('sta:' + data[this.bindProps.krId]);
				}
			} else if (data?.okrViewKeyResults && data.okrViewKeyResults?.length > 0) {
				for (let j = 0; j < data.okrViewKeyResults.length; j++) {
					let result = data.okrViewKeyResults[j];
					if (result[this.bindProps.krParent]) {
						let parentKrData = this.GetKrDataById(result[this.bindProps.krParent].toString());
						if (parentKrData) {
							if (!parentKrData.childOkrs) {
								parentKrData.childOkrs = [];
							}
							if (!parentKrData.childOkrs.find((c: string) => c === result[this.bindProps.krId]))
								parentKrData.childOkrs.push(result[this.bindProps.krId]);
						}
					}
				}
			}
		}
		let myOkrList = this.jsonData.filter((j: any) => {
			return j.isMyOkr;
		});
		let ins = this;
		if (myOkrList) {
			myOkrList.forEach((d: any, index: number) => {
				d['sIndex'] = index;
				d['isExpanded'] = false;
				d['visible'] = true;
				ins.UpdatMyOkrParents(d, ins.jsonData);
			});
		}
	}
	static UpdatMyOkrParents(myOkrJson: any, jsonList: any) {
		if (myOkrJson) {
			for (let i = 0; i < myOkrJson.parent.length; i++) {
				let parentJson = jsonList.find((j: any) => j.objectiveUniqueId === myOkrJson.parent[i]);
				if (parentJson) {
					parentJson['isParentOkr'] = true;
					this.UpdatMyOkrParents(parentJson, jsonList);
				}
			}
		}
	}
	static GetKrDataById(krId: any, isStaType?: boolean) {
		let jsonData = this.jsonData;
		let okrResult;
		let okr;
		for (let i = 0; i < jsonData.length; i++) {
			okr = jsonData[i];
			if (okr.objectiveType === 2 && okr[this.bindProps.krId] === krId) {
				okrResult = okr;
			}
			if (!okrResult) okrResult = this.GetOkrResultObject(krId, okr);
		}
		if (okrResult) {
			return okrResult;
		}
		return null;
	}
	static GetOkrResultObject(krId: string, okr: any) {
		if (okr?.okrViewKeyResults && okr.okrViewKeyResults?.length > 0) {
			for (let j = 0; j < okr.okrViewKeyResults.length; j++) {
				if (okr.okrViewKeyResults[j][this.bindProps.krId] === krId) {
					return okr.okrViewKeyResults[j];
				}
			}
		}
	}
}

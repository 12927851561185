import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Tooltip } from '@material-ui/core';
import { Enums } from '../../../config/enums';

export const WeekRangeTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

WeekRangeTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any,
	value: PropTypes.any,
};

function a11yProps(index: number) {
	return {
		id: `weekrange-tabpanel-${index}`,
		'aria-controls': `weekrange-tabpanel-${index}`,
	};
}

export const WeekRangeTabs: React.FC<any> = (props) => {
	const { handleTabChange = {}, tabSelected, checkInDates, handleSelectDate } = props;

	return (
		<div>
			<Box className='tab-scroller'>
				<Tabs
					value={tabSelected}
					onChange={handleTabChange}
					indicatorColor='primary'
					textColor='primary'
					variant='scrollable'
					scrollButtons='on'
					aria-label='scrollable auto tabs example'
				>
					{checkInDates &&
						checkInDates.length > 0 &&
						checkInDates.map((item: any, index: number) => (
							<Tooltip
								key={index}
								arrow
								title={`${item.checkInStatusDetails}`}
								classes={{ popper: 'tooltip-layout-margin' }}
							>
								<Tab
									label={
										<>
											<span
												className={`week-status ${
													item.checkInStatus === Enums.CHECKED_IN_MISSED
														? 'checked-in-missed'
														: item.checkInStatus === Enums.NOT_YET_CHECKED_IN
														? 'not-yet-checked'
														: item.checkInStatus === Enums.STATUS_CHECKED_ID
														? 'checked-in'
														: item.checkInStatus === Enums.CHECKED_IN_NO_DATA
														? 'checked-no-data'
														: ''
												}`}
											></span>
											<span>{item.displayDate}</span>
										</>
									}
									{...a11yProps(index)}
									onClick={(e) => handleSelectDate(e, item)}
								/>
							</Tooltip>
						))}
				</Tabs>
			</Box>
		</div>
	);
};

import React from 'react';
import { OKRPopover } from './OKRPopover';
import { SearchUsers } from './SearchUsers';

export const SearchUserPopover: React.FC<any> = (props) => {
	const {
		addContributorAnchorEl,
		addContributorOpen,
		setAddContributorOpen,
		setAddContributorAnchorEl,
		isCustomStyle,
		onSelectedUser,
		handleSearchUserInputChange,
		popperAddClassName,
		popperAnchorOrigin,
		searchPlaceHolderText,
		addUserInRole,
		customId,
		popperTransformOrigin,
		popperAnchorPosition,
	} = props;
	return (
		<OKRPopover
			popperClassName={`user-quick-search-popover ${popperAddClassName}`}
			popperInnerClass={'search-user-inner'}
			addButtonClassName={addUserInRole ? '' : 'icon-search'}
			isButtonVisible={true}
			anchorEl={addContributorAnchorEl}
			open={addContributorOpen}
			setOpen={setAddContributorOpen}
			setAnchorEl={setAddContributorAnchorEl}
			handlePopperClose={(event, reason) => {
				setAddContributorOpen(false);
				setAddContributorAnchorEl(null);
			}}
			useCustomStyle={isCustomStyle}
			popperAnchorOrigin={popperAnchorOrigin}
			popperTransformOrigin={popperTransformOrigin}
			popperAnchorPosition={popperAnchorPosition}
			{...props}
		>
			<SearchUsers
				module={'myGoal'}
				{...props}
				onSelectedUser={onSelectedUser}
				defaultFocus={true}
				onInputChange={handleSearchUserInputChange}
				placeHolderText={searchPlaceHolderText}
				customId={customId}
			/>
		</OKRPopover>
	);
};

import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography, Button, Avatar, Chip, InputAdornment, InputLabel, Box } from '@material-ui/core';
import { DeskPhone, TwitterIcon, SkypeIcon, LinkedInIcon } from '../../../config/svg/GlobalSvg';
import { CameraIcon } from '../../../config/svg/CommonSvg';
import { getUserName } from '../../../config/utils';
import { useSnackbar } from 'notistack';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { EditIcon } from '../../../config/svg/CommonSvg';

export default function UpdateProfile(props) {
	const {
		firstName,
		lastName,
		employeeCode,
		emailId,
		designation,
		countryStdCode,
		showImageEditor,
		phoneNumber,
		deskPhoneNumber,
		skypeUrl,
		twitterUrl,
		linkedInUrl,
		role,
		imagePath,
		department,
		objectives,
		keyResults,
		score,
		setProfileDetail,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const [isReadOnlyMode, changeReadOnlyMode] = useState(true);
	const initialData = {
		phoneNumber: phoneNumber,
		deskPhoneNumber: deskPhoneNumber,
		skypeUrl: skypeUrl,
		twitterUrl: twitterUrl,
		linkedInUrl: linkedInUrl,
		countryStdCode: countryStdCode,
	};
	const [formData, setFormData] = useState(initialData);
	const { t } = useTranslation();

	const handleDrawerConfirmation = () => {
		if (JSON.stringify(formData) !== JSON.stringify(initialData)) {
			props.setModalProps({
				open: true,
				type: 'closeDrawer',
				message: t('unSavedItemAlert'),
				module: 'profile',
			});
		} else {
			props.setProfileOpen(false);
		}
	};

	const checkValidation = () => {
		return true;
	};

	const submitRequest = async (e, type) => {
		e.preventDefault();
		if (checkValidation()) {
			props.setLoader(true);
			let resp = await props.updateProfileDetail(formData);
			if (resp.data.status === 200) {
				props.getProfile('').then((response) => {
					if (response && response.data && response.data.entity) {
						setProfileDetail(response.data.entity);
					}
				});
				props.setLoader(false);
				enqueueSnackbar(t('ProfileUpdate'), { variant: 'success', autoHideDuration: 5000 });
				props.setProfileOpen(false);
			} else {
				props.setLoader(false);
				const responseAPI = resp.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages}`, { variant: 'error', autoHideDuration: 5000 });
			}
		}
	};

	const changePhoneNumber = (value) => {
		if (value && value.length >= 15) {
			return false;
		} else {
			setFormData({ ...formData, phoneNumber: value });
		}
		props.setFormDataUpdated(true);
	};
	const handleChange = (e, name) => {
		e.preventDefault();
		const { target } = e;
		if (name === 'deskPhoneNumber' && target.value.length >= 15) {
			return false;
		} else {
			setFormData({ ...formData, [name]: target.value });
		}
		props.setFormDataUpdated(true);
	};

	return (
		<Fragment>
			<Box className='drawer-content'>
				<Box className='drawer-inner-content' id='custom-scroll'>
					<Box className='drawer-content-panel'>
						<form className='drawer-form-fields'>
							<Box className='drawer-field-row profile-user-info'>
								<Box
									className='profile-image-wrapper'
									style={{ cursor: 'pointer' }}
									onClick={(event) => showImageEditor(event, true)}
									id={'profile-user-avatar'}
								>
									<Avatar
										id='profile-avatar'
										src={imagePath ? imagePath : ''}
										className='profile-avatar-style'
										alt={`${firstName} ${lastName}`}
									>
										{imagePath ? '' : getUserName({ firstName, lastName })}
									</Avatar>
									<Box className='camera-icon' id='profile-upload-btn'>
										<label htmlFor='file-input' className='upload-image-label'>
											<CameraIcon />
										</label>
										<input
											className='upload-image-file-input'
											accept='image/x-png,image/gif,image/jpeg'
											type='file'
											id='file-input'
										></input>
									</Box>
								</Box>
								<Box className='user-description'>
									<Typography className='title' variant='h4'>
										{`${firstName} ${lastName}`}
									</Typography>
									<Typography
										className='user-role-subtitle no-sentence-case'
										variant='h6'
									>{`${designation} | ${department}`}</Typography>
									<Box className='chip-wrapper'>
										<Chip label={`${score}${t('percentScore')}`} />
										<Chip label={`${objectives} ${t('okrsLabel')}`} />
										<Chip label={`${keyResults} ${t('keyResults')}`} />
									</Box>
								</Box>
							</Box>

							<Box className='drawer-form-fields-group'>
								<Box className='field-head'>
									<Typography variant='h4'>{t('generalDetails')}</Typography>
								</Box>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={true}
										label={t('firstNameLabel')}
										defaultValue={firstName}
										id='user-first-name'
									/>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={true}
										label={t('lastNameLabel')}
										defaultValue={lastName}
										id='user-last-name'
									/>
								</Box>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={true}
										label={t('employeeIDLabel')}
										defaultValue={employeeCode}
										id='user-emp-id'
									/>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={true}
										label={t('emailLabel')}
										defaultValue={emailId}
										id='user-email'
									/>
								</Box>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={true}
										label={t('designationLabel')}
										defaultValue={designation}
										id='user-designation'
									/>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={true}
										label={t('roleLabel')}
										defaultValue={role}
										id='user-role'
									/>
								</Box>
							</Box>

							<Box className='drawer-form-fields-group profile-contact-details'>
								<Box className='details-wrapper field-head'>
									<Typography className='title' variant='h4'>
										{t('contactDetails')}
									</Typography>
									<Box onClick={() => changeReadOnlyMode(false)} className='edit-details-wrapper' id='edit-details'>
										<EditIcon />
										<span className='underlined-text'>{t('editDetails')}</span>
									</Box>
								</Box>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<Box className={`field-col2 helperText-bottom phone-input-field ${isReadOnlyMode ? 'disable' : ''}`}>
										<InputLabel shrink>{t('phoneNumberLabel')}</InputLabel>
										<PhoneInput
											international
											limitMaxLength={true}
											placeholder={t('phoneNumberLabel')}
											value={formData.phoneNumber}
											onChange={(value) => changePhoneNumber(value)}
											disabled={isReadOnlyMode}
											id='user-phone-no'
										/>
									</Box>
									<TextField
										className='field-col2 helperText-bottom deskphone'
										type='text'
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<DeskPhone />
												</InputAdornment>
											),
										}}
										disabled={isReadOnlyMode}
										placeholder={t('deskPhoneNumberPlaceholder')}
										label={t('deskPhoneNumberLabel')}
										value={formData.deskPhoneNumber}
										onChange={(e) => handleChange(e, 'deskPhoneNumber')}
										id='user-deskphone-no'
									/>
								</Box>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={isReadOnlyMode}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SkypeIcon />
												</InputAdornment>
											),
										}}
										placeholder={t('skypePlaceholder')}
										label={t('ProfileSkypeText')}
										value={formData.skypeUrl}
										onChange={(e) => handleChange(e, 'skypeUrl')}
										id='user-skype-id'
									/>
									<TextField
										className='field-col2 helperText-bottom'
										disabled={isReadOnlyMode}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<TwitterIcon />
												</InputAdornment>
											),
										}}
										placeholder={t('twitterPlaceholder')}
										label={t('ProfileTwitterText')}
										value={formData.twitterUrl}
										onChange={(e) => handleChange(e, 'twitterUrl')}
										id='user-twitter-id'
									/>
								</Box>
								<Box className='drawer-input-field drawer-input-field-col2'>
									<TextField
										className='field-col2 helperText-bottom'
										fullWidth={true}
										disabled={isReadOnlyMode}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<LinkedInIcon />
												</InputAdornment>
											),
										}}
										placeholder={t('linkedinPlaceholder')}
										label={t('ProfileLinkedInText')}
										value={formData.linkedInUrl}
										onChange={(e) => handleChange(e, 'linkedInUrl')}
										id='user-linkedin-id'
									/>
								</Box>
							</Box>
						</form>
					</Box>
				</Box>
			</Box>
			<Box className='drawer-footer-actions'>
				<Box>
					<Button
						className='cancel-btn btn-link'
						id='profile-cancel-btn'
						color='primary'
						onClick={handleDrawerConfirmation}
					>
						{t('cancelBtn')}
					</Button>
				</Box>
				<Box>
					<Button
						className='submitBtn btn-primary'
						id='profile-submit-btn'
						color='primary'
						onClick={(e) => submitRequest(e, 2)}
					>
						{t('saveAndExitBtn')}
					</Button>
				</Box>
			</Box>
		</Fragment>
	);
}

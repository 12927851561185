import { Box, Button, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkPermission } from '../../../config/rolePermission';
import { AddIconSvg } from '../../../config/svg/CommonSvg';
import { addNewRole, addNewUsers, createTeams } from '../AdminConstant';
import { AdminListingTabs } from '../AdminListingTabs';
import { PopperMenu } from '../CommonComponent/PopperMenu';
import { AddRoleIcon, AddTeamsIcon, AddUserIcon } from '../../../config/svg/CommonSvgIcon';
import { DialogComponent } from '../CommonComponent/DialogComponent';

export const SubHeader: React.FC<any> = (props) => {
	const { tabSelected, handleMenuClick, getPermissionResult, licenseDetails } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (tabSelected) {
			setAnchorEl(null);
			setOpen(false);
		}
	}, [tabSelected]);

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
	};
	const handleClose = (e: any) => {
		setAnchorEl(null);
		setOpen(false);
	};
	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-admin-subheader');
		if (window.pageYOffset >= 88) {
			objElement?.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement?.classList.remove('sticky-header');
		}
	};
	return (
		<Box className='page-subheader' id='page-admin-subheader'>
			<Box className='page-subheader-inner'>
				<AdminListingTabs tabSelected={props.tabSelected} handleTabChange={props.handleTabChange} {...props} />

				<Box className='page-subheader-right' id='admin-plus-icon'>
					{checkPermission(getPermissionResult?.employeePermissions, addNewUsers) ||
					checkPermission(getPermissionResult?.employeePermissions, addNewRole) ||
					checkPermission(getPermissionResult.employeePermissions, createTeams) ? (
						<>
							<Button
								className={open ? 'add-btn active' : 'add-btn'}
								color='primary'
								onClick={handleClick}
								id={'quick-link-add'}
							>
								<AddIconSvg />
							</Button>
							<PopperMenu
								{...props}
								anchorEl={anchorEl}
								open={open}
								handleClose={handleClose}
								children={
									<>
										{checkPermission(getPermissionResult?.employeePermissions, addNewUsers) ? (
											<ListItem
												id='add-user-tab'
												onClick={(e: any) => {
													handleClose(e);
													if (!licenseDetails || licenseDetails.isAddUserAllow === false) {
														setModalOpen(true);
													} else {
														handleMenuClick(e, 'addNewUsers');
													}
												}}
											>
												<ListItemAvatar>
													<AddUserIcon />
												</ListItemAvatar>
												<ListItemText primary={t('addUserLabel')} />
											</ListItem>
										) : (
											<></>
										)}
										{checkPermission(getPermissionResult.employeePermissions, createTeams) ? (
											<ListItem
												id='add-team-tab'
												onClick={(e: any) => {
													handleClose(e);
													handleMenuClick(e, 'addNewTeam');
												}}
											>
												<ListItemAvatar>
													<AddTeamsIcon />
												</ListItemAvatar>
												<ListItemText primary={t('addTeamsLabel')} />
											</ListItem>
										) : (
											<></>
										)}
										{checkPermission(getPermissionResult?.employeePermissions, addNewRole) ? (
											<ListItem
												id='add-role-tab'
												onClick={(e: any) => {
													handleClose(e);
													handleMenuClick(e, 'addNewRole');
												}}
											>
												<ListItemAvatar>
													<AddRoleIcon />
												</ListItemAvatar>
												<ListItemText primary={t('addRolesLabel')} />
											</ListItem>
										) : (
											<></>
										)}
									</>
								}
								connectorIcon={true}
							></PopperMenu>
						</>
					) : (
						<></>
					)}
				</Box>
			</Box>

			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('upgradeBufferLicenses')}
					handleCloseModal={() => {
						setModalOpen(false);
					}}
					isCancel={true}
					modalOpen={modalOpen}
				/>
			)}
		</Box>
	);
};

import { Avatar, Box, ClickAwayListener, Drawer, List, ListItem, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchUserIcon, UserProfileIcon } from '../../config/svg/CommonSvg';
import { LogoutIcon, WhatsNewIcon } from '../../config/svg/ProfileSvg';
import { getUserName, getUserDetails } from '../../config/utils';
import GlobalSearch from './GlobalSearch';
import LinearLoader from './Loader';
import { AZURE_LOGOUTURI } from '../../config/constant';
import {
	clearLocalStorage,
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../../services/StorageService';
import { checkPermission } from '../../config/rolePermission';
import { impersonateUser } from '../Admin/AdminConstant';
import { useSnackbar } from 'notistack';
import { HOME, WHATS_NEW } from '../../config/app-url';
import { useHistory } from 'react-router-dom';

export const ProfileInfoDrawer: React.FC<any> = (props) => {
	const { open, handleClose, setProfileOpen, getPermissionResult } = props;
	const { t } = useTranslation();
	const [loader, setLoader] = useState(false);
	const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);
	const userDetail = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	useEffect(() => {
		if (!open) {
			setShowGlobalSearch(false);
			setLoader(false);
		}
	}, [open]);

	const logoutUser = () => {
		clearLocalStorage();
		sessionStorage.clear();
		window.location.href = window.location.origin + '/logout';
	};

	const onSelectedUser = (selectedUser: any) => {
		setLoader(true);
		handleImpersonateCall(selectedUser[0]);
	};

	const handleImpersonateCall = async (user: any) => {
		let data = {
			isImpersonate: true,
			impersonateUser: user.emailId,
			impersonatedByUser: userDetail.emailId,
			impersonatedById: userDetail.employeeId,
			impersonatedByUserName: `${userDetail?.firstName} ${userDetail?.lastName}`,
		};
		setLocalStorageItem('LoginAs', 'user');
		let response = await props.impersonateUser(data);
		if (response.data.status === 200) {
			setLocalStorageItem('userDetail', JSON.stringify(response.data.entity));
			setLocalStorageItem('currentRoute', HOME);
			removeLocalStorageItem('currentUser');
			removeLocalStorageItem('globalBackUrl');
			props.getEmployeePermission(undefined, history);
			props.resetOkr();
			history.push(HOME);
			props.updateRoute(HOME);
			handleClose();
			props.isImpersonated({
				redirection: true,
				tab: 0,
				userDetails: response.data.entity,
			});
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleSwitchBack = async () => {
		let data: any = {
			isImpersonate: false,
			impersonateUser: userDetail?.impersonatedBy,
			impersonatedByUser: userDetail?.impersonatedBy,
			impersonatedById: userDetail?.impersonatedById,
			impersonatedByUserName: userDetail.impersonatedByUserName,
		};
		setLoader(true);
		let response = await props.impersonateUser(data);
		if (response.data && response.data.status === 200) {
			setLocalStorageItem('userDetail', JSON.stringify(response.data.entity));
			removeLocalStorageItem('currentUser');
			removeLocalStorageItem('globalBackUrl');
			props.getEmployeePermission(undefined, history);
			handleClose();
			props.resetOkr();
			setLocalStorageItem('currentRoute', HOME);
			history.push(HOME);
			props.updateRoute(HOME);
			props.isImpersonated({
				redirection: true,
				tab: 0,
				userDetails: response.data.entity,
			});
		}
	};

	const handleClickAway = () => {
		setShowGlobalSearch(false);
	};

	return (
		<Drawer
			className='profile-setting-drawer'
			anchor='right'
			open={open}
			onClose={handleClose}
			id='profile-setting-drawer'
		>
			<Box className='profile-inner-wrapper'>
				{loader && <LinearLoader />}
				<Box className='user-account-info'>
					<Box className='user-info-wrap'>
						<Avatar
							id='profile-avatar'
							src={userDetail.imagePath ? userDetail.imagePath : ''}
							className='user-setting-avatar'
							alt={`${userDetail.firstName} ${userDetail.lastName}`}
						>
							{userDetail.imagePath
								? ''
								: getUserName({
										firstName: userDetail?.firstName,
										lastName: userDetail?.lastName,
										fullName: userDetail?.firstName,
								  })}
						</Avatar>
						<Typography>
							{userDetail?.firstName} {userDetail?.lastName}
						</Typography>
						{userDetail?.designation && <Typography variant='h6'>{userDetail?.designation}</Typography>}
						<Typography variant='subtitle2' className='user-role-chip'>
							{userDetail?.roleName}
						</Typography>
					</Box>
				</Box>
				<Box className='user-account-setting'>
					<Typography variant='h6' className='account-head'>
						{t('accountSettings')}
					</Typography>
					<List>
						<ListItem onClick={logoutUser}>
							<Box className='link-txt'>
								<LogoutIcon />
								<Typography>{t('logout')}</Typography>
							</Box>
						</ListItem>
					</List>
				</Box>
			</Box>
		</Drawer>
	);
};

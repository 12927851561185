import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { Line } from '../Charts/splineChart';
import { useTranslation } from 'react-i18next';
import ReportFilter from '../Filters';
import { getLocalStorageItem } from '../../../services/StorageService';
import { CombinationChart } from '../Charts/CombinationChart';
import { HeaderTooltip } from '../HeaderTooltip';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useEffect } from 'react';
import { ReportsLineChart } from '../HighchartReports/ReportsLineChart';
import { CHART_COLOR } from '../../../config/constant';
import { ReportsColumnChart } from '../HighchartReports/ReportsColumnChart';

const MyEngagementReport: React.FC<any> = (props: any) => {
	const {
		getTeamProgressDetails,
		okrNatures,
		getMyLeadingTeamsDetails,
		myLeadingTeams,
		headTitle,
		headTitleTooltip,
		color1,
		color2,
		color3,
		getEngagementOverallProgress,
	} = props;
	const { t } = useTranslation();
	const [nature, setNature] = React.useState<any>([]);
	const [teams, setTeams] = React.useState<any>([]);
	const [isFilterChanged, setIsFilterChanged] = React.useState<boolean>(false);
	const [teamsPreviousState, setTeamsPreviousState] = React.useState<any>([]);
	const [maxOverAllProgress, setMaxOverAllProgress] = React.useState<number>(0);
	const [filterSelectionError, setFilterSelectionError] = React.useState<boolean>(false);
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const [reportsData, setReportsData] = React.useState<any>({});
	const [engagementData, setEngagementData] = React.useState<any>({});
	const [maxValueRecord, setMaxValueRecord] = React.useState<any>({});
	const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
	const [resetClicked, setResetClicked] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || '';
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || '';

	React.useEffect(() => {
		let allTeams;
		if (teams.length) {
			validateFilter();
			allTeams = teams.filter((item: any) => item.isChecked).length === teams.length;
			if (!allTeams) {
				setIsFilterChanged(true);
			} else {
				setIsFilterChanged(false);
			}
		}
		setTeams(teams);
	}, [teams]);

	useEffect(() => {
		if (resetClicked) {
			getOverAllProgress();
			handleFilterBtnClick();
		}
	}, [resetClicked]);

	const handleFilterBtnClick = () => {
		setAnchorEl((prev) => !prev);
	};
	const handleClose = (event: any) => {
		setAnchorEl(false);
		setTeams(teamsPreviousState);
	};

	React.useEffect(() => {
		//Call Team data
		if (anchorEl) {
			let data = `cycleId=${currentCycleId}`;
			if (!myLeadingTeams.length) {
				getMyLeadingTeamsDetails(data);
			}
		}
	}, [anchorEl]);

	React.useEffect(() => {
		if (cycleStartDate && cycleEndDate) {
			getOverAllProgress();
		}
	}, []);

	React.useEffect(() => {
		if (myLeadingTeams.length) {
			let checkItem = myLeadingTeams.map((item: any) => {
				return {
					...item,
					isChecked: true,
				};
			});
			setTeams(checkItem);
			setTeamsPreviousState(checkItem);
		}
	}, [myLeadingTeams]);

	const preparePerformanceData = (data: any) => {
		let responseData = { ...data };
		delete responseData.myEngagementProgressOverall.progressDate;
		let overAllData = [];
		for (let key in responseData.myEngagementProgressOverall) {
			if (responseData.myEngagementProgressOverall.hasOwnProperty(key)) {
				overAllData.push({
					color:
						key === 'myEngagementProgress'
							? '#6C5BFA'
							: key === 'myOrgMemberEngagementProgress'
							? '#FA989D'
							: key === 'myTeamMemberEngagementProgress'
							? '#8FC3F6'
							: '',
					month:
						key === 'myEngagementProgress'
							? 'Your overall Engagement score'
							: key === 'myOrgMemberEngagementProgress'
							? 'Average Engagement score of the users in the Org'
							: key === 'myTeamMemberEngagementProgress'
							? 'Average Engagement score of members from Teams'
							: '',
					sales: responseData.myEngagementProgressOverall[key],
				});
			}
		}
		let getMax = _(responseData.myEngagementProgressOverall).values().max();
		setMaxOverAllProgress(getMax);
		responseData.myEngagementProgressOverall = overAllData;
		return responseData;
	};

	const prepareChartData = (chartData: any) => {
		let data = chartData.map((org: any) => {
			if (moment(org.date) > moment(new Date())) {
				return {
					...org,
					x: new Date(org.date),
				};
			} else {
				return {
					...org,
					x: new Date(org.date),
					y: org.progress,
				};
			}
		});
		return data;
	};

	const getOverAllProgress = async () => {
		let data = {};
		if (anchorEl) {
			let selectedTeams = [...teams];
			let getFilterList = _.filter(selectedTeams, 'isChecked');
			data = {
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: _.map(getFilterList, 'teamId'),
			};
		} else {
			data = {
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: [],
			};
		}
		setResetClicked(false);
		const apiRes = await getEngagementOverallProgress(data);
		if (apiRes && (apiRes.data.status === 200 || apiRes.data.status === 204)) {
			let res = apiRes.data?.entity;
			setEngagementData(preparePerformanceData(apiRes.data.entity));
			let allRecords = [
				...res.myOrgMemberEngagementDetails,
				...res.myEngagementProgressDetails,
				...res.myTeamMemberEngagementDetails,
			];
			let maxVal = _.maxBy(allRecords, 'progress');
			setMaxValueRecord(maxVal || {});
			setDataLoaded(true);
			setReportsData({
				myOrgMemberProgressDetails: prepareChartData(res.myOrgMemberEngagementDetails),
				myProgressDetails: prepareChartData(res.myEngagementProgressDetails),
				myTeamMemberProgressDetails: prepareChartData(res.myTeamMemberEngagementDetails),
			});
		} else {
			enqueueSnackbar(t('internalServerError'), {
				variant: 'error',
				autoHideDuration: 2000,
			});
		}
	};

	const handleFilterOption = (e: any, filterType: string, filterId?: number) => {
		// e.preventDefault();
		const { checked } = e.target;
		let natures = [...nature];
		let allTeams = [...teams];
		let numOfFilters = 0;
		if (filterType === 'natureOfOkr') {
			let updateNature = natures.map((item: any) => {
				if (item.okrNatureId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setNature(updateNature);
		} else if (filterType === 'selectAllNature') {
			let updateNature = natures.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setNature(updateNature);
		} else if (filterType === 'teams') {
			let updateTeams = allTeams.map((item: any) => {
				if (item.teamId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setTeams(updateTeams);
		} else if (filterType === 'selectAllTeam') {
			let updateTeams = allTeams.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setTeams(updateTeams);
		}
	};

	const validateFilter = () => {
		let findFilteredTeams = teams.filter((item: any) => item.isChecked);
		if (!findFilteredTeams.length) {
			setFilterSelectionError(true);
		} else {
			setFilterSelectionError(false);
		}
	};

	const handleApplyFilter = () => {
		setTeamsPreviousState(teams);
		handleFilterBtnClick();
		getOverAllProgress();
	};

	const handleResetFilter = () => {
		let updateTeams = teams.map((item: any) => {
			return {
				...item,
				isChecked: true,
			};
		});
		setTeams(updateTeams);
		setResetClicked(true);
	};

	return (
		<>
			<Box className='reports-head'>
				<Box className='reports-head-left'>
					<Typography variant='h2'>{headTitle}</Typography>
					<HeaderTooltip tooltipText={headTitleTooltip} btnId={'my-eng-tooltip'} />
				</Box>
				<Box className='reports-head-right'>
					<ReportFilter
						{...props}
						toolTipText={t('filterEngagementTooltip')}
						isSencondFilters={false}
						secondFiltersOption={nature}
						teams={teams}
						handleFilterOption={handleFilterOption}
						handleApplyFilter={handleApplyFilter}
						handleResetFilter={handleResetFilter}
						handleFilterBtnClick={handleFilterBtnClick}
						handleClose={handleClose}
						filterSelectionError={filterSelectionError}
						anchorEl={anchorEl}
						isFilterChanged={isFilterChanged}
						filterId={'my-eng'}
					/>
				</Box>
			</Box>
			<Box className='reports-card col-full'>
				<Box className='report-col col-one-third'>
					<Typography variant='h4'>
						{t('overallPerformanceLabel')}
						<span>{t('overAllCurrentDate', { currentDate: moment(new Date()).format('MMMM D, YYYY') })}</span>
					</Typography>
					<ReportsColumnChart
						{...props}
						t={t}
						progress={maxOverAllProgress}
						engagementDataOverall={engagementData?.myEngagementProgressOverall}
						engagementData={engagementData}
						colors={CHART_COLOR}
						labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
						yAxisText={'Engagement Count'}
					/>
					{/* <CombinationChart
						progress={maxOverAllProgress}
						engagementDataOverall={engagementData?.myEngagementProgressOverall}
						engagementData={engagementData}
						{...props}
						t={t}
						color1={color1}
						color2={color2}
						color3={color3}
						labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
					/> */}
				</Box>
				<Box className='report-col col-two-thirds'>
					<Typography variant='h4' className='title-center'>
						{t('fourWeekReportLabel')}
					</Typography>
					<ReportsLineChart
						{...props}
						showLegend={true}
						dataLoaded={dataLoaded}
						maxValueRecord={maxValueRecord}
						reportsData={reportsData}
						labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
						colors={CHART_COLOR}
						xAxisText={'Weeks'}
						yAxisText={'Progress'}
					/>
					{/* <Line
						{...props}
						t={t}
						color1={color1}
						color2={color2}
						color3={color3}
						showLegend={true}
						dataLoaded={dataLoaded}
						maxValueRecord={maxValueRecord}
						reportsData={reportsData}
						labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
					/> */}
				</Box>
			</Box>
		</>
	);
};

export default MyEngagementReport;

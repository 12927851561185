import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	alignResult: {},
	alignStatus: '',
	teamStatus: '',
	teamResult: {},
	peopleStatus: '',
	peopleResult: [],
	alignmentFilterData: [],
	directReportResultStatus: '',
	directReportResult: [],
	organisationFilterData: [],
	teamOkrFilterData: null,
	directReportSortBy: null,
	alignmentMapAction: {
		actionType: 'UPDATE_PROGRESS',
		status: '',
		value: null,
	},
};

export default function alignReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_ALIGN_PENDING:
			return {
				...state,
				alignStatus: 'pending',
			};
		case actionTypes.FETCH_ALIGN_SUCCESS:
			return {
				...state,
				alignStatus: 'success',
				alignResult: action.payload.entity,
			};
		case actionTypes.FETCH_ALIGN_FAILED:
			return {
				...state,
				alignStatus: 'error',
			};
		case actionTypes.FETCH_PEOPLE_PENDING:
			return {
				...state,
				peopleStatus: 'pending',
			};
		case actionTypes.FETCH_PEOPLE_SUCCESS:
			return {
				...state,
				peopleStatus: 'success',
				peopleResult: action.payload.entity,
			};
		case actionTypes.FETCH_PEOPLE_FAILED:
			return {
				...state,
				peopleStatus: 'error',
			};
		case actionTypes.FETCH_TEAM_ALIGN_PENDING:
			return {
				...state,
				teamStatus: 'pending',
			};
		case actionTypes.FETCH_TEAM_ALIGN_SUCCESS:
			return {
				...state,
				teamStatus: 'success',
				teamResult: action.payload.entity,
			};
		case actionTypes.FETCH_TEAM_ALIGN_FAILED:
			return {
				...state,
				teamStatus: 'error',
			};
		case actionTypes.FETCH_DIRECT_REPORTS_PENDING:
			return {
				...state,
				directReportResultStatus: 'pending',
			};
		case actionTypes.FETCH_DIRECT_REPORTS_SUCCESS:
			return {
				...state,
				directReportResultStatus: 'success',
				directReportResult: action.payload.entityList,
			};
		case actionTypes.FETCH_DIRECT_REPORTS_FAILED:
			return {
				...state,
				directReportResultStatus: 'error',
			};
		case actionTypes.UPDATE_ALIGNMENT:
			return {
				...state,
				alignStatus: '',
			};
		case actionTypes.RESET_ALIGNMENT:
			return {
				...INITIAL_STATE,
			};
		case actionTypes.ADD_ALIGNMENT_FILTER:
			return {
				...state,
				alignmentFilterData: action.filter,
			};
		case actionTypes.ADD_ORGANISATION_FILTER:
			return {
				...state,
				organisationFilterData: action.filter,
			};
		case actionTypes.ADD_TEAMOKR_FILTER:
			return {
				...state,
				teamOkrFilterData: action.filter,
			};
		case actionTypes.ADD_DIRECTREPORT_SORTBY:
			return {
				...state,
				directReportSortBy: action.sortBy,
			};
		case actionTypes.ALIGNMENT_MAP_ACTION:
			return {
				...state,
				alignmentMapAction: {
					actionType: action.actionType,
					status: action.status,
					value: action.value,
				},
			};
		default:
			return state;
	}
}

import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import {
	Box,
	ClickAwayListener,
	FormLabel,
	Grid,
	Grow,
	InputAdornment,
	Paper,
	Popper,
	Slider,
	Switch,
	TextField,
	Typography,
	withStyles,
	Tooltip,
	Collapse,
} from '@material-ui/core';
import '../Goals/OkrListing/style.scss';
import {
	abbreviateNumber,
	getCurrencyIcon,
	getOkrProgressDetails,
	getTime,
	getUserDetails,
	formatNumberWithCommas,
	getPercentage,
	getValueFromPercentage,
	getPercentageOfKr,
} from '../../config/utils';
import { Enums } from '../../config/enums';
import { NumbersIcon, PercentageIcon } from '../../config/svg/MyGoalSvg';
import _ from 'lodash';
import CircularProgress from './CircularProgress';
import { NumberFormatComponent } from '../Common/NumberFormat';
import { getLocalStorageItem } from '../../services/StorageService';
import { OKRButton } from '../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../config/svg/Action';
import SfEditor from '../Admin/CommonComponent/SfEditor/SfEditor';
import { toolbarProgressSettings } from '../Admin/CommonComponent/SfEditor/SfEditorEnum';

export const OkrProgressBar: React.FC<any> = (props) => {
	const {
		okr,
		type,
		t,
		updateProgress,
		showEditLoader,
		parentOkr,
		currentUser,
		okrMasterData,
		createKr,
		tabValue,
		showArchiveList,
		isScoreLocked = false,
		enqueueSnackbar,
	} = props;
	okr.krId = okr.krId ? okr.krId : okr.objectiveId;
	let scoreValue = okr.objectiveType === 1 ? okr.krScore : okr.myScore;
	let userDetail = getUserDetails();
	const { color } = getOkrProgressDetails({
		value: okr.score || okr.krScore,
		dueDate: okr.dueDate || okr.krDueDate,
	});
	const [openProgressDetail, setOpenProgressDetail] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [showTime, setShowTime] = useState<boolean>(true);
	const [progressValue, setProgressValue] = useState<any>(scoreValue);
	const [progressValuePercentage, setProgressValuePercentage] = useState<any>(scoreValue);
	const [progressPercentageValue, setProgressPercentageValue] = useState<number>();
	//let { currentYear } = getQuarterStartEnd();
	const [openComment, setOpenComment] = useState<boolean>(false);
	const [scoreInNumber, setScoreInNumber] = useState<any>(0);
	const [progressEdited, setProgressEdited] = useState<boolean>(false);
	const [isProgressUpdated, setIsProgressUpdated] = useState<boolean>(false);
	const [notesContent, setNotesContent] = React.useState<string>('');
	const progressUnsavedRef = useRef<any>(null);
	useEffect(() => {
		if (props.isAnyDrawerOpened) {
			if (anchorEl === null) {
				props.isAnyDrawerOpened({
					...props.appDrawerInfo,
					isProgressPopperOpened: false,
				});
				setIsProgressUpdated(false);
			} else {
				props.isAnyDrawerOpened({
					...props.appDrawerInfo,
					isProgressPopperOpened: true,
				});
			}
		}
	}, [anchorEl]);

	useEffect(() => {
		if (anchorEl === null) {
			setProgressEdited(false);
		}
		// } else {
		// 	if (notesContent || isProgressUpdated) {
		// 		setProgressEdited(true);
		// 	}
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notesContent, isProgressUpdated, anchorEl]);

	useEffect(() => {
		if (anchorEl !== null && type !== 'krAlignmentMap') {
			// let scoreFromPercentage: any = getValueFromPercentage(okr.targetValue, scoreValue, okr.startValue);
			if (okr?.isScored) {
				setScoreInNumber(okr?.currentValue);
			} else {
				setScoreInNumber('');
			}

			let percentage: any;
			if (okr.importedId === 0) {
				percentage = getPercentageOfKr(okr?.startValue, okr?.currentValue, okr?.targetValue);
			} else {
				percentage = getPercentageOfKr(okr?.startValue, okr?.currentValue, okr?.targetValue);
			}
			setProgressValuePercentage(Number(percentage) < 0 ? 0 : Number(percentage));
			setProgressValue(Number(percentage) < 0 ? 0 : Number(percentage));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchorEl]);

	let currentYear = getLocalStorageItem('currentYear');
	const [fieldProgress, setFieldProgress] = useState<any>({
		goalKeyId: 0,
		assignmentTypeId: 0,
		newValue: 0,
		currentYear: 0,
		parentOkr: parentOkr,
	});
	const [scaleValue, setScaleValue] = useState<any>(1);
	const [showNotes, setShowNotes] = useState<boolean>(false);
	const [coversationLoader, showCoversationLoader] = useState<boolean>(false);

	// useEffect(() => {
	// 	// if (showEditLoader.id === 0 && type === 'krAlignmentMap') {
	// 	setTimeout(() => {
	// 		// handleClose('');
	// 		// setShowNotes(true);
	// 	}, 1000);
	// 	// }
	// }, [showEditLoader]);

	useEffect(() => {
		if (showEditLoader.id === 0) {
			setAnchorEl(null);
			setOpenProgressDetail(false);
		}
	}, [showEditLoader]);

	useEffect(() => {
		if (fieldProgress.goalKeyId > 0 && type === 'krAlignmentMap') {
			// progressValue, setProgressValue
			setProgressValue(Number(progressValue));
			updateProgress(
				fieldProgress.goalKeyId,
				fieldProgress.assignmentTypeId,
				fieldProgress.newValue,
				fieldProgress.currentYear,
				parentOkr
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldProgress.newValue]);

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		if (props.userSelectedCycle?.cycleStatus !== Enums.PAST_CYCLE_ID) {
			setOpenProgressDetail(anchorEl ? false : true);
		} else if (props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && !isScoreLocked) {
			// Added extra check for fix OP-10865
			setOpenProgressDetail(anchorEl ? false : true);
		}

		if (type === 'kr' || type === 'okr') {
			setProgressValue(okr.currentValue || okr.krCurrentValue);
		} else {
			//For alignment
			if (okr.metricId === 3 || okr.metricId === 2 || okr.metricId === 1 || okr.metricId === 5) {
				setProgressValue(okr.krCurrentValue);
			} else {
				setProgressValue(okr.krScore || okr.score || okr.krCurrentValue);
			}
		}
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};

	const handleClose = (event: any) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		// setProgressValue(0);
		setAnchorEl(null);
		setOpenProgressDetail(false);
	};

	const handleCloseProgressPopper = (event: any, type: number) => {
		let currentId;
		if (event) {
			currentId = event.target?.lastChild?.className || null;
			event.preventDefault();
			event.stopPropagation();
		}
		// if (currentId !== 'tribute-container') {
		// }
		if (type !== 1 && (notesContent || isProgressUpdated)) {
			setIsProgressUpdated(true);
			setProgressEdited(true);
		} else {
			setAnchorEl(null);
			setOpenProgressDetail(false);
			setNotesContent('');
			setIsProgressUpdated(false);
			setProgressEdited(false);
		}
		// setShowNotes(false);
	};

	const getCurrencyName = (metricId: any, currencyId: any) => {
		const defaultName = 'Dollar';
		if (metricId === Enums.TWO && okrMasterData && okrMasterData?.metricMasters) {
			const { metricMasters } = okrMasterData;
			const currencyData =
				metricMasters && metricMasters.length > 0 && metricMasters.find((item: any) => item.metricId === metricId);
			if (currencyData) {
				const { metricDataMaster } = currencyData;
				const currencyDetail =
					metricDataMaster &&
					metricDataMaster.length > 0 &&
					metricDataMaster.find((item: any) => item.dataId === currencyId);
				return currencyDetail?.name || defaultName;
			}
			return defaultName;
		}
		return defaultName;
	};

	const handleSliderChange = (event: any, newValue: number | number[]) => {
		let assignmentTypeId = okr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		let score: any = getValueFromPercentage(okr.targetValue, newValue, okr.startValue, okr.currentValue);
		let percentage: any = getPercentageOfKr(okr.startValue, score, okr.targetValue);
		setScoreInNumber(score);
		setProgressPercentageValue(percentage);
		setProgressValuePercentage(Number(percentage) < 0 ? 0 : Number(percentage));
		setIsProgressUpdated(true);
		// setProgressEdited(false);
		// updateProgress(okr.goalKeyId || okr.krId, assignmentTypeId, newValue, currentYear, parentOkr);
	};

	const handleSwitchChange = (event: any) => {
		let assignmentTypeId = okr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		const newValue = event.target.checked ? 100 : 0;
		setProgressValue(newValue);
		updateProgress(okr.goalKeyId || okr.krId, assignmentTypeId, newValue, currentYear, parentOkr);
	};

	const handleFieldChange = (newValue: any) => {
		let assignmentTypeId = okr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		const regexp = /^[0-9\\.b]+$/;
		if (newValue === '' || regexp.test(newValue)) {
			newValue = !Boolean(newValue) ? '' : newValue;
			// setProgressValue(newValue);
			setProgressPercentageValue(newValue);
			if (type !== 'krAlignmentMap') {
				setIsProgressUpdated(true);
				setScoreInNumber(newValue);
				// let score = getPercentageOfKr(okr.startValue, newValue, okr.targetValue);
				// setProgressValue(score);
				// let scoreFromPercentage = getValueFromPercentage(okr.targetValue, newValue);
				// setScoreInNumber(scoreFromPercentage);
				let score = getPercentageOfKr(okr.startValue, newValue, okr.targetValue);
				let percentage: any = getPercentageOfKr(okr.startValue, newValue, okr.targetValue);
				setProgressValuePercentage(Number(percentage) < 0 ? 0 : Number(percentage));
				setProgressValue(score < 0 ? 0 : score);
			} else {
				setProgressValue(newValue);
				if (regexp.test(newValue)) {
					delayedQuery({
						goalKeyId: okr.goalKeyId || okr.krId,
						assignmentTypeId: assignmentTypeId,
						newValue: newValue,
						currentYear: currentYear,
					});
				}
			}

			// let score = getPercentageOfKr(okr.startValue, newValue, okr.targetValue);
			// let percentage = getPercentageOfKr(okr.startValue, score, okr.targetValue);
			// setProgressValue(score);
		}
	};

	const delayedQuery = useCallback(
		_.debounce((request: any) => {
			setFieldProgress({
				goalKeyId: request.goalKeyId,
				assignmentTypeId: request.assignmentTypeId,
				newValue: request.newValue,
				currentYear: request.currentYear,
				okr: okr,
			});
		}, 1000),
		[]
	);

	const saveProgress = () => {
		let assignmentTypeId = okr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		if (parseFloat(okr.currentValue) !== parseFloat(scoreInNumber) || !okr?.isScored) {
			updateProgress(okr.goalKeyId || okr.krId, assignmentTypeId, scoreInNumber || 0, currentYear, parentOkr);
		}
		// setAnchorEl(null);
		// setOpenProgressDetail(false);
		// setProgressEdited(false);
	};

	const handleSaveNotes = async (e: any) => {
		let data: any = {
			noteId: 0,
			description: notesContent,
			goalTypeId: type === 'kr' ? 2 : type === 'okr' ? 1 : 0,
			goalId: okr.goalKeyId,
			type: 2, // for progress
			goalSourceId: okr.source > 0 ? okr.source : okr.goalKeyId,
			employeeTags: [],
		};

		if (type === 'kr') {
			const elem = document.createElement('span');
			elem.innerHTML = notesContent;
			let tagged = elem.getElementsByClassName('tagged-name');
			for (let i = 0; i < tagged.length; i++) {
				let employeeId: any = tagged[i].getAttribute('rel');
				if (employeeId) {
					data.employeeTags.push({
						employeeId: parseInt(employeeId),
					});
				}
			}
			showCoversationLoader(true);
			const response = await props.postProgressConversation(data);
			if (Boolean(response) && response.data.status === 200) {
				setAnchorEl(null);
				setOpenProgressDetail(false);
				setNotesContent('');
				showCoversationLoader(false);
				setShowNotes(false);
				// setProgressEdited(false);
			} else {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 3000 });
			}
		}
	};

	const AntSwitch = withStyles((theme) => ({
		root: {
			width: 28,
			height: 16,
			padding: 0,
			display: 'flex',
		},
		switchBase: {
			padding: 2,
			color: theme.palette.grey[500],
			'&$checked': {
				transform: 'translateX(12px)',
				color: theme.palette.common.white,
				'& + $track': {
					opacity: 1,
					backgroundColor: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
				},
			},
		},
		thumb: {
			width: 12,
			height: 12,
			boxShadow: 'none',
		},
		track: {
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor: theme.palette.common.white,
		},
		checked: {},
	}))(Switch);

	const handleComment = () => {
		setOpenComment((prev) => !prev);
	};
	const handleBlanketClicked = () => {
		setProgressEdited(true);
		if (progressUnsavedRef && progressUnsavedRef?.current) {
			progressUnsavedRef.current.classList.add('progress-popper-unsaved');
		}
		setTimeout(() => {
			if (progressUnsavedRef && progressUnsavedRef?.current) {
				progressUnsavedRef.current.classList.remove('progress-popper-unsaved');
			}
		}, 2000);
	};
	const getActionableAlignmentMapPB = () => {
		return (
			<Paper>
				<Typography variant='h5' className='popper-progress-title'>
					{t('updateProgressLabel')}
				</Typography>
				{okr.metricId === 1 ? (
					//=== Percentage
					<Fragment>
						<Box className='currency-updated-field'>
							<TextField
								placeholder={'0'}
								value={progressValue}
								inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
								onChange={(e: any) => handleFieldChange(e.target.value)}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<PercentageIcon />
										</InputAdornment>
									),
								}}
							/>
							<Box className='current-traget-value'>
								<Typography>
									{abbreviateNumber(okr.startValue) || abbreviateNumber(okr.krStartValue)} to{' '}
									{abbreviateNumber(okr.targetValue) || abbreviateNumber(okr.krTargetValue)}
								</Typography>
								{/* <Typography>
									{okr.startValue || okr.krStartValue} to {okr.targetValue || okr.krTargetValue}
								</Typography> */}
							</Box>
						</Box>

						{showEditLoader &&
							(okr.goalKeyId === showEditLoader.id || okr.krId === showEditLoader.id) &&
							showEditLoader.status === 'pending' && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}
					</Fragment>
				) : okr.metricId === 2 ? (
					//=== Currency
					<Fragment>
						<Box className='currency-updated-field'>
							<TextField
								placeholder={'0'}
								value={progressValue}
								inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
								onChange={(e: any) => {
									handleFieldChange(e.target.value);
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
										</InputAdornment>
									),
								}}
							/>
							<Box className='current-traget-value'>
								<Typography>
									{abbreviateNumber(okr.startValue) || abbreviateNumber(okr.krStartValue)} to{' '}
									{abbreviateNumber(okr.targetValue) || abbreviateNumber(okr.krTargetValue)}
								</Typography>
							</Box>
						</Box>
						{showEditLoader &&
							(okr.goalKeyId === showEditLoader.id || okr.krId === showEditLoader.id) &&
							showEditLoader.status === 'pending' && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}
					</Fragment>
				) : okr.metricId === 3 ? (
					//=== Numbers
					<Fragment>
						<Box className='currency-updated-field'>
							<TextField
								placeholder={'0'}
								value={progressValue}
								inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
								onChange={(e: any) => handleFieldChange(e.target.value)}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<NumbersIcon />
										</InputAdornment>
									),
								}}
							/>
							<Box className='current-traget-value'>
								<Typography>
									{abbreviateNumber(okr.startValue) || abbreviateNumber(okr.krStartValue)} to{' '}
									{abbreviateNumber(okr.targetValue) || abbreviateNumber(okr.krTargetValue)}
								</Typography>
							</Box>
						</Box>
						{showEditLoader &&
							(okr.goalKeyId === showEditLoader.id || okr.krId === showEditLoader.id) &&
							showEditLoader.status === 'pending' && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}
					</Fragment>
				) : okr.metricId === 4 ? (
					//=== Boolean
					<Box className='boolean-popover-bar'>
						<Typography component='div' className='boolean-matric'>
							<Grid component='label' container alignItems='center' spacing={1}>
								<Grid item>Not achieved</Grid>
								<Grid item>
									<AntSwitch checked={progressValue ? true : false} onChange={handleSwitchChange} />
								</Grid>
								<Grid item>Achieved</Grid>
							</Grid>
						</Typography>
						{showEditLoader &&
							(okr.goalKeyId === showEditLoader.id || okr.krId === showEditLoader.id) &&
							showEditLoader.status === 'pending' && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}
					</Box>
				) : okr.metricId === 5 ? (
					//=== No Unit
					<Fragment>
						{/* <Typography>{okr.krScore || okr.score || 0}%</Typography> */}
						<Typography>{progressValue || 0}%</Typography>
						<Box className='no-unit-progress'>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								// value={okr.currentValue || okr.krCurrentValue}
								value={progressValue}
								min={0}
								max={100}
								valueLabelDisplay='off'
								marks={false}
								step={10}
								onChangeCommitted={handleSliderChange}
								onChange={(e, val) => setProgressValue(val)}
							/>
						</Box>

						{showEditLoader &&
							(okr.goalKeyId === showEditLoader.id || okr.krId === showEditLoader.id) &&
							showEditLoader.status === 'pending' && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}
					</Fragment>
				) : (
					<></>
				)}
			</Paper>
		);
	};

	const getMetricSymbol = (metricType: any) => {
		if (metricType === 2) {
			return <Fragment>$</Fragment>;
		} else if (metricType === 1) {
			return <Fragment>%</Fragment>;
		} else if (metricType === 3) {
			return <Fragment>#</Fragment>;
		}
	};

	const checkDisable = () => {
		if (!notesContent && !isProgressUpdated) {
			return true;
		}
		return false;
	};

	const getActionableProgressBar = () => {
		return (
			<Box>
				{okr.importedId === 0 ? (
					<Paper>
						<Box className='popup-progress-top'>
							<Typography variant='h5' className='popper-progress-title'>
								{t('myProgressLabel')}
							</Typography>
							<Box className='progress-input-area owner-progress-input-area'>
								<Box className='progress-owner-percent'>
									<Typography variant='h6'>{progressValuePercentage}%</Typography>
								</Box>
								<Box className='progress-input-box'>
									{okr.metricId === 1 ? (
										<TextField
											placeholder={'100.00'}
											value={scoreInNumber}
											inputProps={{
												maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
												inputType: 'numberFieldProgress',
											}}
											onChange={(e: any) => {
												handleFieldChange(e.target.value);
											}}
											name='percentage'
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														{okr.metricId === 1
															? '%'
															: okr.metricId === 2
															? getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))
															: '#'}
														{/* {getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))} */}
													</InputAdornment>
												),
											}}
										/>
									) : (
										<TextField
											placeholder={okr.metricId === 2 ? '1,000.00' : '1.000.00'}
											value={scoreInNumber}
											inputProps={{
												maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
												inputType: 'numberFieldProgress',
											}}
											onChange={(e: any) => {
												handleFieldChange(e.target.value);
											}}
											name='currency'
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														{okr.metricId === 1
															? '%'
															: okr.metricId === 2
															? getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))
															: '#'}
														{/* {getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))} */}
													</InputAdornment>
												),
											}}
										/>
									)}
								</Box>
								{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
									<Box className='progress-save-loader'>
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									</Box>
								)}
							</Box>
							{/* <Box className='progress-owner-notes'>
								<Typography variant='h6'>{t('ownerProgressNotes')}</Typography>
							</Box> */}
							<OKRButton
								className='btn-link-type1'
								handleClick={handleComment}
								text={t('commentLabel')}
								//icon={openComment ? <DashIcon /> : <AddIconSvg />}
							/>
							{coversationLoader && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}

							<Box className='progress-note-box'>
								<SfEditor
									{...props}
									key={okr.krId}
									enableTagging={true}
									toolbarSettings={toolbarProgressSettings}
									placeholder={t('progressNotePlaceholdarText')}
									handleEditorChange={(value: string) => {
										setNotesContent(value);
									}}
									value={notesContent}
									// isDisabled={!showNotes}
								/>
							</Box>
						</Box>
						<Box className='progress-popup-actions'>
							<OKRButton
								className='progress-cancel'
								id={'progress-cancel'}
								icon={<CancelIcon />}
								handleClick={(e) => {
									handleCloseProgressPopper(e, 1);
								}}
							/>
							<OKRButton
								className={`progress-save ${checkDisable() && 'btn-disabled'}`}
								id={'progress-save'}
								icon={<TickIcon />}
								// disabled={!notesContent}
								handleClick={(e: any) => {
									notesContent && handleSaveNotes(e);
									saveProgress();
								}}
							/>
						</Box>
					</Paper>
				) : (
					<Paper>
						<Box className='popup-progress-top'>
							<Typography variant='h5' className='popper-progress-title'>
								{t('myProgressLabel')}
							</Typography>
							<Box className='popup-progress-box'>
								<Typography variant='h6'>{progressValuePercentage}%</Typography>
								<Box className='popup-progress-track'>
									<Box className='popup-progress-value'>
										<Typography variant='subtitle2'>
											{okr.metricId === 1
												? '%'
												: okr.metricId === 2
												? getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))
												: '#'}
											{/* {getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))} */}
											{abbreviateNumber(okr.startValue)}
										</Typography>
										<Typography variant='subtitle2'>
											{okr.metricId === 1
												? '%'
												: okr.metricId === 2
												? getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))
												: '#'}
											{abbreviateNumber(okr.targetValue)}
										</Typography>
										{/* <Typography variant='subtitle2'>0</Typography>
										<Typography variant='subtitle2'>100</Typography> */}
									</Box>
									<Box className='popup-progress-rail'>
										<Slider
											aria-label={t('MyGoalProgressSlider')}
											style={{ color }}
											// value={okr.currentValue || okr.krCurrentValue}
											value={progressValue}
											min={0}
											max={100}
											// valueLabelDisplay='off'
											// marks={false}
											// step={10}
											// onChangeCommitted={handleSliderChange}
											onChange={(e, val) => {
												setProgressValue(val);
												handleSliderChange(e, val);
											}}
											className={okr.score < 1 ? 'show-blue-slider' : ''}
										/>
									</Box>
								</Box>
							</Box>
							<Box className='progress-input-area'>
								<Box className='progress-link-icon'></Box>
								<Box className='progress-input-box'>
									{okr.metricId === 1 ? (
										<TextField
											placeholder={'100.00'}
											value={scoreInNumber}
											inputProps={{
												maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
												inputType: 'numberFieldProgress',
											}}
											onChange={(e: any) => {
												handleFieldChange(e.target.value);
											}}
											name='percentage'
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														{okr.metricId === 1
															? '%'
															: okr.metricId === 2
															? getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))
															: '#'}
														{/* {getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))} */}
													</InputAdornment>
												),
											}}
										/>
									) : (
										<TextField
											placeholder={okr.metricId === 2 ? '1,000.00' : '1.000.00'}
											value={scoreInNumber}
											inputProps={{
												maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
												inputType: 'numberFieldProgress',
											}}
											onChange={(e: any) => {
												handleFieldChange(e.target.value);
											}}
											name='currency'
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														{okr.metricId === 1
															? '%'
															: okr.metricId === 2
															? getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))
															: '#'}
														{/* {getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))} */}
													</InputAdornment>
												),
											}}
										/>
									)}
								</Box>
								{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
									<Box className='progress-save-loader'>
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									</Box>
								)}
							</Box>
							{/* <Typography variant='h5' className='popper-progress-title'>
								{t('updateProgressLabel')}
							</Typography> */}
							{/* {okr.metricId === 1 ? (
								//=== Percentage
								<Fragment>
									<Box className='currency-updated-field'>
										<TextField
											placeholder={'0'}
											value={progressValue}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
											onChange={(e: any) => handleFieldChange(e.target.value)}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<PercentageIcon />
													</InputAdornment>
												),
											}}
										/>
										<Box className='current-traget-value'>
											<Typography>
												{abbreviateNumber(okr.startValue)} to {abbreviateNumber(okr.targetValue)}
											</Typography>
										</Box>
									</Box>
	
									{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
										<Box className='progress-save-loader'>
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										</Box>
									)}
								</Fragment>
							) : okr.metricId === 2 ? (
								//=== Currency
								<Fragment>
									<Box className='currency-updated-field'>
										<TextField
											placeholder={'0'}
											value={progressValue}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET, inputType: 'numberFieldProgress' }}
											onChange={(e: any) => {
												handleFieldChange(e.target.value);
											}}
											name='currency'
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
													</InputAdornment>
												),
											}}
										/>
										<Box className='current-traget-value'>
											<Typography>
												{abbreviateNumber(okr.startValue)} to {abbreviateNumber(okr.targetValue)}
											</Typography>
										</Box>
									</Box>
									{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
										<Box className='progress-save-loader'>
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										</Box>
									)}
								</Fragment>
							) : okr.metricId === 3 ? (
								//=== Numbers
								<Fragment>
									<Box className='currency-updated-field'>
										<TextField
											placeholder={'0'}
											value={progressValue}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET, inputType: 'numberFieldProgress' }}
											onChange={(e: any) => handleFieldChange(e.target.value)}
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														<NumbersIcon />
													</InputAdornment>
												),
											}}
										/>
										<Box className='current-traget-value'>
											<Typography>
												{abbreviateNumber(okr.startValue)} to {abbreviateNumber(okr.targetValue)}
											</Typography>
										</Box>
									</Box>
									{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
										<Box className='progress-save-loader'>
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										</Box>
									)}
								</Fragment>
							) : okr.metricId === 4 ? (
								//=== Boolean
								<Box className='boolean-popover-bar'>
									<Typography component='div' className='boolean-matric'>
										<Grid component='label' container alignItems='center' spacing={1}>
											<Grid item>Not achieved</Grid>
											<Grid item>
												<AntSwitch checked={progressValue ? true : false} onChange={handleSwitchChange} />
											</Grid>
											<Grid item>Achieved</Grid>
										</Grid>
									</Typography>
									{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
										<Box className='progress-save-loader'>
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										</Box>
									)}
								</Box>
							) : okr.metricId === 5 ? (
								//=== No Unit
								<Fragment>
									<Typography>{progressValue}%</Typography>
									<Box className='no-unit-progress'>
										<Slider
											aria-label={t('MyGoalProgressSlider')}
											style={{ color }}
											value={progressValue}
											min={0}
											max={100}
											valueLabelDisplay='off'
											marks={false}
											step={10}
											onChangeCommitted={handleSliderChange}
											onChange={(e, val) => setProgressValue(val)}
										/>
									</Box>
	
									{showEditLoader && okr.goalKeyId === showEditLoader.id && showEditLoader.status === 'pending' && (
										<Box className='progress-save-loader'>
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										</Box>
									)}
								</Fragment>
							) : (
								<></>
							)} */}
							<OKRButton
								className='btn-link-type1'
								handleClick={handleComment}
								text={t('commentLabel')}
								//icon={openComment ? <DashIcon /> : <AddIconSvg />}
							/>
							{coversationLoader && (
								<Box className='progress-save-loader'>
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								</Box>
							)}

							<Box className='progress-note-box'>
								<SfEditor
									{...props}
									key={okr.krId}
									enableTagging={true}
									toolbarSettings={toolbarProgressSettings}
									placeholder={t('progressNotePlaceholdarText')}
									handleEditorChange={(value: string) => {
										setNotesContent(value);
									}}
									value={notesContent}
									// isDisabled={!showNotes}
								/>
							</Box>
						</Box>
						<Box className='progress-popup-actions'>
							<OKRButton
								className='progress-cancel'
								id={'progress-cancel'}
								icon={<CancelIcon />}
								handleClick={(e) => {
									handleCloseProgressPopper(e, 1);
								}}
								// disabled={checkDisable()}
							/>
							<OKRButton
								className={`progress-save ${checkDisable() && 'btn-disabled'}`}
								id={'progress-save'}
								icon={<TickIcon />}
								disabled={checkDisable()}
								handleClick={(e: any) => {
									notesContent && handleSaveNotes(e);
									saveProgress();
								}}
							/>
						</Box>
					</Paper>
				)}
			</Box>
		);
	};

	const handleProgressChange = (event: any) => {
		const { value } = event.target;
		let assignmentTypeId = okr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		setProgressValue(value);
		let val: any = getValueFromPercentage(okr.targetValue, value, okr.startValue, okr.currentValue);
		setProgressPercentageValue(val);
		// delayedQuery({
		// 	goalKeyId: okr.goalKeyId || okr.krId,
		// 	assignmentTypeId: assignmentTypeId,
		// 	newValue: value,
		// 	currentYear: currentYear,
		// });
		// updateProgress(okr.goalKeyId || okr.krId, assignmentTypeId, value, currentYear, parentOkr);
	};

	const getActionableProgressScore = () => {
		return (
			<Box
				className={`kr-progress not-accepted-progress ${
					props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'future-progress'
				} ${props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && 'past-progress'}`}
				onClick={handleToggle}
				// onMouseEnter={() => setShowTime(false)}
				// onMouseLeave={() => setShowTime(true)}
				id={`kr-progress-${okr.goalKeyId || okr.krId}`}
			>
				{okr.metricId === 1 ? (
					//=== Percentage
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 2 ? (
					//=== Currency
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 			<Box className='hover-currency-tooltip'>
								// 				<Typography variant='body2'>(</Typography>
								// 				<Box className='hover-currency-icon'>
								// 					{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
								// 					<Typography variant='body2'>{formatNumberWithCommas(okr.currentValue)})</Typography>
								// 				</Box>
								// 			</Box>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 3 ? (
					//=== Numbers
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 			<Typography variant='body2'>({formatNumberWithCommas(okr.currentValue)})</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 4 ? (
					//=== Boolean
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								valueLabelDisplay='on'
								valueLabelFormat={() => {
									return (
										<Fragment>
											<Typography>{okr.score}%</Typography>
										</Fragment>
									);
								}}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 5 ? (
					//=== No Unit
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : (
					<></>
				)}
				{props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID ? (
					<Typography variant='h6'>{t('progRestricted')}</Typography>
				) : showTime || isScoreLocked ? (
					<Typography className='kr-progress-action' variant='h6'>
						Updated {getTime(okr.keyProgressTime)}
					</Typography>
				) : (
					<Typography className='kr-progress-action' variant='h6'>
						{t('clickToUpdateProgress')}
					</Typography>
				)}
			</Box>
		);
	};

	const getNonActionableProgressBar = () => {
		return (
			<Paper>
				<Box className='popup-progress-top progress-non-actionable'>
					<Typography variant='body1' className='non-action-text'>
						{t('wantsContribution', {
							name: okr.ownerFirstName
								? okr.ownerFirstName + ' ' + okr.ownerLastName
								: okr.okrOwner
								? okr.okrOwner
								: '',
						})}
					</Typography>
					{props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && (
						<Typography variant='h6' className='addOn'>
							{t('progRestricted')}
						</Typography>
					)}
					{okr.metricId === 1 ? (
						//=== Percentage
						<Box className='non-action-matric'>
							<Box className='non-action-left'>
								<FormLabel component='legend'>Current</FormLabel>
								<Typography variant='body1'>
									{type === 'krAlignmentMap'
										? abbreviateNumber(okr.startValue) || abbreviateNumber(okr.krStartValue)
										: abbreviateNumber(okr.startValue)}
									%
								</Typography>
							</Box>
							<Box className='non-action-right'>
								<FormLabel component='legend'>Target</FormLabel>
								<Typography variant='body1'>
									{type === 'krAlignmentMap'
										? abbreviateNumber(okr.targetValue) || abbreviateNumber(okr.krTargetValue)
										: abbreviateNumber(okr.targetValue)}
									%
								</Typography>
							</Box>
						</Box>
					) : okr.metricId === 2 ? (
						//=== Currency

						<Box className='non-action-matric'>
							<Box className='non-action-left'>
								<FormLabel component='legend'>Current</FormLabel>
								<Typography variant='body1' className='currencyWrapper'>
									<Typography className='currencyIcon'>
										{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
									</Typography>
									{type === 'krAlignmentMap'
										? formatNumberWithCommas(okr.startValue) || formatNumberWithCommas(okr.krStartValue)
										: formatNumberWithCommas(okr.startValue)}
								</Typography>
							</Box>
							<Box className='non-action-right'>
								<FormLabel component='legend'>Target</FormLabel>
								<Typography variant='body1' className='currencyWrapper'>
									<Typography className='currencyIcon'>
										{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
									</Typography>
									{type === 'krAlignmentMap'
										? formatNumberWithCommas(okr.targetValue) || formatNumberWithCommas(okr.krTargetValue)
										: formatNumberWithCommas(okr.targetValue)}
								</Typography>
							</Box>
						</Box>
					) : okr.metricId === 3 ? (
						//=== Numbers

						<Box className='non-action-matric'>
							<Box className='non-action-left'>
								<FormLabel component='legend'>Current</FormLabel>
								<Typography variant='body1'>
									#
									{type === 'krAlignmentMap'
										? formatNumberWithCommas(okr.startValue) || formatNumberWithCommas(okr.krStartValue)
										: formatNumberWithCommas(okr.startValue)}
								</Typography>
							</Box>
							<Box className='non-action-right'>
								<FormLabel component='legend'>Target</FormLabel>
								<Typography variant='body1'>
									#
									{type === 'krAlignmentMap'
										? formatNumberWithCommas(okr.targetValue) || formatNumberWithCommas(okr.krTargetValue)
										: formatNumberWithCommas(okr.targetValue)}
								</Typography>
							</Box>
						</Box>
					) : okr.metricId === 4 ? (
						//=== Boolean
						<Box className='non-action-matric'>
							<Typography variant='body1'>{'Not achieved'}</Typography>
						</Box>
					) : okr.metricId === 5 ? (
						//=== No Unit
						<Box className='non-action-matric'>
							<Typography variant='body1'>{0}%</Typography>
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Paper>
		);
	};

	const getProgressForPeople = () => {
		const time =
			okr.assignmentTypeId === 1
				? okr.keyProgressTime
				: okr.goalProgressTime
				? okr.goalProgressTime
				: okr.keyProgressTime;
		return (
			<Box
				className={`kr-progress people-progress ${
					props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'future-progress no-cursor'
				} ${props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && 'past-progress'}`}
				id={`kr-progress-${okr?.goalKeyId || okr?.krId}`}
			>
				{okr.metricId === 1 ? (
					//=== Percentage
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 2 ? (
					//=== Currency
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 			<Typography variant='body2' className='hover-currency-tooltip'>
								// 				<Typography variant='body2'>(</Typography>
								// 				<Typography className='hover-currency-icon' variant='body2'>
								// 					{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
								// 					<Typography variant='body2'>{formatNumberWithCommas(okr.currentValue)})</Typography>
								// 				</Typography>
								// 			</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 3 ? (
					//=== Numbers
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 			<Typography variant='body2'>({abbreviateNumber(okr.currentValue)})</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 4 ? (
					//=== Boolean
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography>{okr.score}%</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : okr.metricId === 5 ? (
					//=== No Unit
					<Fragment>
						{getKrSettingValue()}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									{okr.score}
									<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								// valueLabelDisplay='on'
								// valueLabelFormat={() => {
								// 	return (
								// 		<Fragment>
								// 			<Typography variant='h4'>{okr.score}%</Typography>
								// 		</Fragment>
								// 	);
								// }}
							/>
						</Box>
					</Fragment>
				) : (
					<></>
				)}

				{props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID ? (
					<Typography className='kr-progress-action' variant='h6'>
						{t('progRestricted')}
					</Typography>
				) : (
					time && (
						<Typography className='kr-progress-action' variant='h6'>
							Updated {getTime(time)}
						</Typography>
					)
				)}
			</Box>
		);
	};

	const getKrSettingValue = () => {
		return (
			<Fragment>
				{okr.metricId === 1 ? (
					<Box className='kr-settings-value'>
						<Typography variant='h6'>{abbreviateNumber(okr.startValue)}%</Typography>
						<Typography variant='h6'>{abbreviateNumber(okr.targetValue)}%</Typography>
					</Box>
				) : okr.metricId === 2 ? (
					<Box className='kr-settings-value'>
						<Tooltip arrow title={formatNumberWithCommas(okr.startValue)}>
							<Typography variant='h6'>
								{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
								{/* {okr.startValue} */}
								{abbreviateNumber(okr.startValue)}
							</Typography>
						</Tooltip>
						<Tooltip arrow title={formatNumberWithCommas(okr.targetValue)}>
							<Typography variant='h6'>
								{getCurrencyIcon(getCurrencyName(okr?.metricId, okr?.currencyId))}
								{abbreviateNumber(okr.targetValue)}
							</Typography>
						</Tooltip>
					</Box>
				) : okr.metricId === 3 ? (
					<Box className='kr-settings-value'>
						<Tooltip arrow title={`${formatNumberWithCommas(okr.startValue)}`}>
							<Typography variant='h6'>#{abbreviateNumber(okr.startValue)}</Typography>
						</Tooltip>
						<Tooltip arrow title={`${formatNumberWithCommas(okr.targetValue)}`}>
							<Typography variant='h6'>#{abbreviateNumber(okr.targetValue)}</Typography>
						</Tooltip>
					</Box>
				) : okr.metricId === 4 ? (
					<Box className='kr-settings-value'>
						<Typography variant='h6'>{t('notAchieved')}</Typography>
						<Typography variant='h6'>{t('achieved')}</Typography>
					</Box>
				) : okr.metricId === 5 ? (
					<Box className='kr-settings-value'>
						<Typography variant='h6'>{abbreviateNumber(okr.startValue)}%</Typography>
						<Typography variant='h6'>{abbreviateNumber(okr.targetValue)}%</Typography>
					</Box>
				) : (
					<></>
				)}
			</Fragment>
		);
	};
	const scaleStyle = {
		transform: `scale(${scaleValue})`,
	};

	return (
		<Fragment>
			{isProgressUpdated && type !== 'krAlignmentMap' && (
				<Box className='progress-blanket' onClick={handleBlanketClicked}></Box>
			)}
			{type === 'krAlignmentMap' ? (
				<Fragment>
					{okr?.objectiveType === 2 && okr?.krStatusId === Enums.KR_STATUS_PENDING ? (
						<Box className='cursor' id={`kr-prg-${okr?.krUniqueId}`}>
							<Box className='' onMouseEnter={handleToggle} onMouseLeave={handleClose}>
								<CircularProgress percentage={okr?.score} score={okr.score} dueDate={okr.dueDate} />
							</Box>
						</Box>
					) : okr?.krStatusId === Enums.KR_STATUS_PENDING ? (
						<Box id={`kr-prg-${okr?.krUniqueId}`}>
							<Typography
								className='kr-percentage cursor'
								style={{ color: color }}
								onMouseEnter={handleToggle}
								onMouseLeave={handleClose}
							>
								{okr?.krScore}%
							</Typography>
						</Box>
					) : okr?.score || okr?.score === 0 ? (
						<>
							{okr.employeeId !== userDetail.employeeId ? (
								<Box className='no-cursor' id={`kr-prg-${okr?.krUniqueId}`}>
									<CircularProgress percentage={okr?.score} score={okr.score} dueDate={okr.dueDate} />
								</Box>
							) : (
								<Box onClick={handleToggle} className='cursor' id={`kr-prg-${okr?.krUniqueId}`}>
									<CircularProgress percentage={okr?.score} score={okr.score} dueDate={okr.dueDate} />
								</Box>
							)}
						</>
					) : okr.employeeId !== userDetail.employeeId ||
					  currentUser ||
					  props.readOnlyView === true ||
					  props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID ? (
						<Box id={`kr-prg-${okr?.krUniqueId}`}>
							{props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID ? (
								<Tooltip arrow title={t('progRestricted')}>
									<Typography className='kr-percentage' style={{ color: color }}>
										{okr?.krScore}%
									</Typography>
								</Tooltip>
							) : (
								<Typography className='kr-percentage' style={{ color: color }}>
									{okr?.krScore}%
								</Typography>
							)}
						</Box>
					) : (
						<Box id={`kr-prg-${okr?.krUniqueId}`} onClick={handleToggle}>
							<Typography className='kr-percentage' style={{ color: color }}>
								{okr?.krScore}%
							</Typography>
						</Box>
					)}

					<Popper
						className='popper-progress-bar alignment-popper-progress-bar'
						open={okr.krStatusId === Enums.KR_STATUS_PENDING || isScoreLocked ? false : openProgressDetail}
						anchorEl={anchorEl || null}
						placement='bottom'
						//transition
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<ClickAwayListener onClickAway={handleClose}>
									<Box className='popper-inner' style={scaleStyle}>
										{okr.krStatusId === Enums.KR_STATUS_PENDING || isScoreLocked
											? getNonActionableProgressBar()
											: tabValue === 0 ||
											  (okr.teamId && okr.owner === userDetail.employeeId) ||
											  (!okr.teamId && okr.employeeId === userDetail.employeeId)
											? getActionableAlignmentMapPB()
											: getNonActionableProgressBar()}
									</Box>
								</ClickAwayListener>
							</Grow>
						)}
					</Popper>
				</Fragment>
			) : (okr.goalStatusId === Enums.GOAL_STATUS_DRAFT || showArchiveList || props.newKr) && !props.createKr ? (
				<Box className={`kr-progress orphan-kr-progress`} onMouseEnter={handleToggle} onMouseLeave={handleClose}>
					{getKrSettingValue()}
					<Box className='kr-progress-slider'>
						<Box className='progress-timestamp-info'>
							<Typography variant='h2'>
								0<sup>%</sup>
							</Typography>
						</Box>
						<Slider
							aria-label={t('MyGoalProgressSlider')}
							style={{ color }}
							value={props.newKr ? 0 : okr.score}
							valueLabelDisplay='off'
							marks={false}
							step={10}
							disabled
						/>
					</Box>
				</Box>
			) : okr.goalStatusId === Enums.GOAL_STATUS_DRAFT || showArchiveList || props.createKr ? (
				<Box className='kr-progress orphan-kr-progress' onMouseEnter={handleToggle} onMouseLeave={handleClose}>
					{getKrSettingValue()}
					<Box className='kr-progress-slider'>
						<Box className='progress-timestamp-info'>
							<Typography variant='h2'>
								0<sup>%</sup>
							</Typography>
						</Box>
						<Slider
							aria-label={t('MyGoalProgressSlider')}
							value={okr.score}
							valueLabelDisplay='off'
							marks={false}
							disabled
						/>
					</Box>
				</Box>
			) : type === 'okr' ? (
				<Box className='obj-progress'>
					<Box className='obj-progress-slider'>
						<Box className='progress-timestamp-info'>
							<Typography variant='h2'>
								{okr.score}
								<sup>%</sup>
							</Typography>
						</Box>
						<Slider
							aria-label={t('MyGoalProgressSlider')}
							style={{ color }}
							value={okr.score}
							valueLabelDisplay='off'
							marks={false}
							step={10}
							disabled
						/>
					</Box>
					{okr.goalProgressTime &&
						props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID &&
						okr.isAnyNonPendingOkr && <Typography variant='h6'>Updated {getTime(okr.goalProgressTime)}</Typography>}
				</Box>
			) : currentUser ||
			  createKr ||
			  props.readOnlyView === true ||
			  props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID ? (
				getProgressForPeople()
			) : okr.assignmentTypeId === 1 ? (
				//standalone Case
				<Fragment>
					{okr.krStatusId === Enums.KR_STATUS_PENDING ? (
						<Fragment>
							<Box
								className={`kr-progress orphan-kr-progress ${
									props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'future-progress'
								} ${props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && 'past-progress'}`}
								onMouseEnter={handleToggle}
								onMouseLeave={handleClose}
							>
								{getKrSettingValue()}
								{/* <Typography>{t('notYetAccepted')}</Typography> */}
								<Box className='kr-progress-slider'>
									<Box className='progress-timestamp-info'>
										<Typography variant='h2'>
											0<sup>%</sup>
										</Typography>
									</Box>
									<Slider
										aria-label={t('MyGoalProgressSlider')}
										style={{ color }}
										value={okr.score}
										valueLabelDisplay='off'
										marks={false}
										step={10}
										disabled
									/>
								</Box>
							</Box>
							<Popper
								style={{ pointerEvents: 'none' }}
								className='popper-progress-bar'
								open={okr.krStatusId === Enums.KR_STATUS_PENDING || isScoreLocked ? false : openProgressDetail}
								anchorEl={anchorEl}
								placement='bottom'
							>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<ClickAwayListener onClickAway={handleClose}>
											{okr.krStatusId === Enums.KR_STATUS_PENDING || isScoreLocked
												? getNonActionableProgressBar()
												: getActionableProgressBar()}
										</ClickAwayListener>
									</Grow>
								)}
							</Popper>
						</Fragment>
					) : (
						<Fragment>
							{getActionableProgressScore()}
							<Popper className='popper-progress-bar' open={openProgressDetail} anchorEl={anchorEl} placement='bottom'>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<ClickAwayListener onClickAway={handleClose}>
											{okr.krStatusId === Enums.KR_STATUS_PENDING || isScoreLocked
												? getNonActionableProgressBar()
												: getActionableProgressBar()}
										</ClickAwayListener>
									</Grow>
								)}
							</Popper>
						</Fragment>
					)}
				</Fragment>
			) : okr.assignmentTypeId === 2 && okr.krStatusId === Enums.KR_STATUS_PENDING ? (
				//Assign Kr with Parent
				<Fragment>
					<Box
						className={`kr-progress not-accepted-progress ${
							props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'future-progress'
						} ${props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && 'past-progress'}`}
						onMouseEnter={handleToggle}
						onMouseLeave={handleClose}
					>
						{getKrSettingValue()}
						{/* <Typography>{t('notYetAccepted')}</Typography> */}
						<Box className='kr-progress-slider'>
							<Box className='progress-timestamp-info'>
								<Typography variant='h2'>
									0<sup>%</sup>
								</Typography>
							</Box>
							<Slider
								aria-label={t('MyGoalProgressSlider')}
								style={{ color }}
								value={okr.score}
								valueLabelDisplay='off'
								marks={false}
								step={10}
								disabled
							/>
						</Box>
					</Box>
					<Popper
						style={{ pointerEvents: 'none' }}
						className='popper-progress-bar'
						//open={openProgressDetail}
						open={false}
						anchorEl={anchorEl}
						placement='bottom'
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<ClickAwayListener onClickAway={handleClose}>{getNonActionableProgressBar()}</ClickAwayListener>
							</Grow>
						)}
					</Popper>
				</Fragment>
			) : props.teamsOkr ? (
				<Fragment>
					{userDetail.employeeId === okr.owner ? (
						<Fragment>
							{getActionableProgressScore()}
							<Popper
								// className='popper-progress-bar'
								open={isScoreLocked ? false : openProgressDetail}
								anchorEl={anchorEl}
								placement='bottom'
								transition
								ref={progressUnsavedRef}
								className={`popper-progress-bar ${progressEdited ? 'progress-popper-unsaved' : ''}`}
							>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<ClickAwayListener onClickAway={handleClose}>
											{isScoreLocked ? getNonActionableProgressBar() : getActionableProgressBar()}
										</ClickAwayListener>
									</Grow>
								)}
							</Popper>
						</Fragment>
					) : (
						<Box
							className={`kr-progress ${
								props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'future-progress'
							} ${props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && 'past-progress'}`}
						>
							<Box className='kr-progress-slider'>
								<Box className='progress-timestamp-info'>
									<Typography variant='h2'>
										{okr.score}
										<sup>%</sup>
									</Typography>
								</Box>
								<Slider
									aria-label={t('MyGoalProgressSlider')}
									style={{ color }}
									value={okr.score}
									valueLabelDisplay='off'
									marks={false}
									step={10}
									disabled
								/>
							</Box>
							{okr.keyProgressTime && (
								<Typography className='kr-progress-action' variant='h6'>
									Updated {getTime(okr.keyProgressTime)}
								</Typography>
							)}
						</Box>
					)}
				</Fragment>
			) : (
				<Fragment>
					{showArchiveList ? (
						<Box
							className={`kr-progress not-accepted-progress ${
								props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && 'future-progress'
							} ${props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && 'past-progress'}`}
							onMouseEnter={handleToggle}
							onMouseLeave={handleClose}
						>
							{getKrSettingValue()}
							<Box className='kr-progress-slider'>
								<Box className='progress-timestamp-info'>
									<Typography variant='h2'>
										0<sup>%</sup>
									</Typography>
								</Box>
								<Slider
									aria-label={t('MyGoalProgressSlider')}
									style={{ color }}
									value={okr.score}
									valueLabelDisplay='off'
									marks={false}
									step={10}
									disabled
								/>
							</Box>
						</Box>
					) : (
						<Fragment>
							{getActionableProgressScore()}
							<Popper
								open={isScoreLocked ? false : openProgressDetail}
								anchorEl={anchorEl}
								placement='bottom'
								transition
								ref={progressUnsavedRef}
								className={`popper-progress-bar ${progressEdited ? 'progress-popper-unsaved' : ''}`}
							>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<ClickAwayListener onClickAway={(e) => handleCloseProgressPopper(e, 0)}>
											{isScoreLocked ? getNonActionableProgressBar() : getActionableProgressBar()}
										</ClickAwayListener>
									</Grow>
								)}
							</Popper>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

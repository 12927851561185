import React, { Fragment, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import clone from 'clone';
import { Enums } from '../../../config/enums';
import { DurationCycleDetailProps } from '../dataTypes';
import { ObjectiveList } from '../OkrListing/ObjectiveList';
import { StandaloneKrList } from '../OkrListing/StandaloneKrList';
import { getFullName, getUserDetails } from '../../../config/utils';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from '../../../services/StorageService';
import { NoListFound } from '../NoListFound';
import { Box } from '@material-ui/core';
import AlertDialog from '../../Common/Dialog';
import { SearchUserPopover } from '../../Common/SearchUserPopover';
import { DollarIcon, EuroIcon, PoundIcon, RupeeIcon, YenIcon } from '../../../config/svg/MyGoalSvg';
import { BecomeContributor } from '../../Common/assignment/BecomeContributor';

export const TeamsOkrList: React.FC<any> = (props) => {
	const {
		teamOKRData,
		currentUser,
		t,
		showAddGoalForm,
		setGoalDetail,
		currentCycleId,
		saveAndEditObjectiveAndKr,
		showArchiveList,
		getMyTeamOKR,
		teamsOKR,
		deleteContributor,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const contributorObject = {
		employeeId: 0,
		designation: '',
		firstName: '',
		lastName: '',
		fullName: '',
		imagePath: '',
		krStatusId: 1,
		krStatusName: 'Pending',
		assignmentTypeId: 0,
		keyResult: '',
		score: 0,
		startDate: '',
		dueDate: '',
		startValue: 0,
		targetValue: 0,
		krAssignerMessage: '',
		metricName: '',
		currencyName: '',
		objectiveName: '',
	};
	const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const currentCycleEndDate = getLocalStorageItem('currentCycleEndDate') || null;
	const [highlightTeamsOkrId, setHighlightTeamsOkrId] = useState(0);

	const [myGoalList, setMyGoalList] = useState<any>([]);
	const [myArchiveGoalList, setArchiveMyGoalList] = useState<any>([]);
	const [openCalender, setOpenCalender] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<any>({});
	const [showEditLoader, setShowEditLoader] = useState<any>({ status: '', id: 0 });
	const [addContributorAnchorEl, setAddContributorAnchorEl] = useState<HTMLElement | null>(null);
	const [dataBeforeEdit, setDataBeforeEdit] = useState<any>();
	const [objectiveKRDetails, setObjectiveKRDetails] = useState<any>({ krDetails: null, okrDetails: null });
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [addContributorOpen, setAddContributorOpen] = useState<boolean>(false);
	const [becomeAContributor, setBecomeAContributor] = useState<boolean>(false);
	const [openAddContributorDrawer, setOpenAddContributorDrawer] = useState<boolean>(false);
	const [selectedContributorDetails, setSelectedContributorDetails] = useState<any>(contributorObject);
	const [openObjDrawer, setOpenObjDrawer] = useState<Boolean>(false);
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [krContributorDetailDashboard, setKRContributorDetailDashboard] = useState<any>(selectedContributorDetails);
	const [loader, setLoader] = useState<boolean>(false);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const [objectiveModalProps, setObjectiveModalProps] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		id: 0,
	});
	const [currentObjective, setCurrentObjective] = useState<any>({});
	const [selectedAssignmentTypeId, setAssignmentTypeId] = useState<any>(null);
	const teamData: any = JSON.parse(getLocalStorageItem('teamId') || '{}');

	const [alignedStatusData, setAlignedStatusData] = useState<any>({
		source: 0,
		goalKeyId: 0,
		sourceType: 0,
	});

	useEffect(() => {
		// load teams details first if redirected from Notification
		const notificationRedirection = JSON.parse(getLocalStorageItem('notificationRedirection') || '{}');
		if (
			notificationRedirection &&
			notificationRedirection.tabToOpen &&
			notificationRedirection.tabToOpen === 'TEAMSOKR'
		) {
			const itemArray = notificationRedirection.data && notificationRedirection.data.split('/');
			const goalId = itemArray[2] ? parseInt(itemArray[2].trim()) : 0;
			if (goalId) {
				removeLocalStorageItem('notificationRedirection');
				setHighlightTeamsOkrId(goalId);

				setTimeout(() => {
					setHighlightTeamsOkrId(0);
				}, 10000);
			}
		}
		setMyGoalList(teamOKRData);
	}, [teamOKRData]);

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 2000,
		});
	};

	useEffect(() => {
		if (myGoalList.length) {
			let expandLength = myGoalList.filter((item: any) => item.expand).length;
			if (expandLength === myGoalList.length) {
				props.setIsExpandAll(true);
			} else {
				props.setIsExpandAll(false);
			}
		}
	}, [myGoalList]);

	const showActionButton = (e: any, goal: any, key: any) => {
		if (!showArchiveList) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					if (item[key]) {
						item[key] = false;
					} else {
						item[key] = true;
					}
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else {
			const updatedList = myArchiveGoalList.map((item: any) => {
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					if (item[key]) {
						item[key] = false;
					} else {
						item[key] = true;
					}
				}
				return item;
			});
			setArchiveMyGoalList(updatedList);
		}
	};
	const getItemStyle = (isDragging: any, draggableStyle: any) => ({
		// styles we need to apply on draggables
		...draggableStyle,

		...(isDragging && {
			background: 'rgb(235,235,235)',
		}),
	});
	const handleKREndOfMonthDateSelection = (details: DurationCycleDetailProps) => {
		setOpenCalender(false);
	};
	const handleDateChange = (date: any, _name: any) => {
		setOpenCalender(false);
	};
	const handleKeyboardButtonClick = (_event: React.ButtonHTMLAttributes<HTMLBaseElement>) => {
		setOpenCalender(true);
	};
	const updateTextValue = (e: any, data: any, type: number, keyData: any) => {
		const { value } = e.target;
		let updatedList = [];

		if (type === 3) {
			updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === data.goalKeyId) {
					item.keyDescription = value;
				}
				return item;
			});
		} else if (type === 2) {
			updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === data.goalObjectiveId) {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === keyData.goalKeyId) {
							keys.keyDescription = value;
						}
						return keys;
					});
				}
				return item;
			});
		} else if (type === 1) {
			// data.objectiveName = value;
			updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === data.goalObjectiveId) {
					item.objectiveName = value;
				}
				return item;
			});
		}

		setMyGoalList(updatedList);
	};
	const updateProgress = async (
		goalKeyId: number,
		assignmentTypeId: number,
		value: number,
		year: number,
		okr?: any
	) => {
		const selectedYear = getLocalStorageItem('year') || year;
		setShowEditLoader({ status: 'pending', id: goalKeyId });
		const data = { goalKeyId: goalKeyId, currentValue: value, year: year };
		const resp = await props.updateKrProgressValue(data);
		if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
			if (okr?.expand) {
				setLocalStorageItem('lastSavedGoal', JSON.stringify(okr));
			}
			props.setIsProgressUpdate(true);
			const respData = resp?.data?.entity;
			const { employeeId, score, updatedOn, okrScore } = respData;
			let newScore = 0;
			if (respData?.score) {
				newScore = respData.score < 0 ? 0 : respData.score > 100 ? 100 : respData.score;
			}
			//==== update Progress
			let goalData = { goalObjectiveId: 0, myGoalsDetails: [] };
			let updatedList = [];
			if (assignmentTypeId === 1) {
				updatedList = myGoalList.map((item: any) => {
					if (item.goalKeyId === goalKeyId) {
						goalData = item;
						item.currentValue = value;
						item.krStatusId = Enums.KR_STATUS_ACCEPTED;
						item.keyProgressTime = Boolean(respData.updatedOn) ? respData.updatedOn : item.keyProgressTime;
						item.score = newScore;
						item.contributors =
							item?.contributors && item?.contributors.length > 0
								? item.contributors.map((member: any) => {
									if (member.employeeId === employeeId) {
										member.contributorsContribution = score;
										member.updatedOn = updatedOn;
									}
									return member;
								})
								: [];
					}
					return item;
				});
			} else {
				const updatedKrList = myGoalList.map((item: any) => {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === goalKeyId) {
							goalData = item;
							keys.currentValue = value;
							item.krStatusId = Enums.KR_STATUS_ACCEPTED;
							keys.keyProgressTime = Boolean(respData.updatedOn) ? respData.updatedOn : keys.keyProgressTime;
							keys.score = newScore;
							keys.contributors =
								keys?.contributors && keys?.contributors.length > 0
									? keys.contributors.map((member: any) => {
										if (member.employeeId === employeeId) {
											member.contributorsContribution = score;
											member.updatedOn = updatedOn;
										}
										return member;
									})
									: [];
						}
						return keys;
					});
					return item;
				});
				// const getKeyPercentage = goalData.myGoalsDetails.map((keys: any) => keys.score);
				// const percentageKeys = getKeyPercentage.reduce((sum, keys) => sum + keys);
				// const progressionScore = parseInt(percentageKeys) / goalData.myGoalsDetails.length;
				const progressionScore = okrScore;

				updatedList = updatedKrList.map((item: any) => {
					if (item.goalObjectiveId === goalData.goalObjectiveId) {
						item.score = Math.round(progressionScore);
						item.goalProgressTime = Boolean(respData.updatedOn) ? respData.updatedOn : item.goalProgressTime;
					}
					return item;
				});
			}

			setMyGoalList(updatedList);
			setShowEditLoader({ status: '', id: 0 });
			const data = `teamId=${teamData?.teamId}&empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			props.getTeamsOkrProgress(data, true);
		} else {
			setShowEditLoader({ status: '', id: 0 });
		}
	};
	const handleAddContributor = (event: any, type: String) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'close') {
			setKRAttrDateError(defaultKRDateError);
			setOpenAddContributorDrawer(false);
		}
	};
	const handleAddUserClick = (event: any, krDetails: any, okrDetails: any) => {
		setObjectiveKRDetails({ krDetails: krDetails || null, okrDetails: okrDetails || null });
		setCustomStyle(false);
		setAddContributorAnchorEl(addContributorAnchorEl ? null : event.currentTarget);
		setAddContributorOpen(true);
		setBecomeAContributor(false);
		setCurrentObjective(krDetails);
	};
	const handleBecomeAContributorClick = async (event: any, krDetails: any, okrDetails: any) => {
		setObjectiveKRDetails({ krDetails: krDetails || null, okrDetails: okrDetails || null });
		setCustomStyle(false);

		const { objectiveName } = okrDetails;
		const {
			assignmentTypeId,
			startDate,
			dueDate,
			keyDescription,
			score,
			startValue,
			targetValue,
			currencyId,
			metricId,
			goalKeyId,
			source,
		} = Boolean(krDetails) ? krDetails : okrDetails;
		const selectedUser = getUserDetails();
		let sourceType = Enums.TWO;
		let goalId = okrDetails?.goalObjectiveId ? okrDetails?.goalObjectiveId : 0;
		setAlignedStatusData({
			source: source,
			goalKeyId: goalKeyId,
			sourceType: sourceType,
		});
		setSelectedContributorDetails({
			...selectedContributorDetails,
			...selectedUser,
			goalObjectiveId: goalId,
			goalStatusId: okrDetails?.goalStatusId || Enums.TWO,
			goalTypeId: okrDetails?.goalTypeId,
			krStatusId: Enums.TWO,
			krStatusName: 'Pending',
			assignmentTypeId: assignmentTypeId || Enums.TWO,
			keyResult: keyDescription || '',
			keyDescription: keyDescription || '',
			score: score || Enums.ZERO,
			startDate: startDate || '',
			dueDate: dueDate || '',
			startValue: startValue || Enums.ZERO,
			targetValue: targetValue || Enums.ZERO,
			message: '',
			metricName: '',
			currencyName: '',
			objectiveName: objectiveName || '',
			currencyId: currencyId || Enums.ZERO,
			metricId: metricId || Enums.ZERO,
			goalKeyId: goalKeyId || Enums.ZERO,
			isAddedNew: true,
		});
		setAddContributorAnchorEl(null);
		setAddContributorOpen(false);
		setOpenAddContributorDrawer(true);
		setBecomeAContributor(true);
	};
	const showAddToObjective = (e: any, okr: any, kr: any, type: any) => {
		handleDrawerObjOpen(e);
	};
	const handleDrawerObjOpen = (event: any) => {
		setOpenObjDrawer(true);
	};
	const handleDeleteOkr = (e: any, okr: any, kr: any, type: any) => {
		const keyData = type === 2 ? kr : okr;
		const id = type === 1 || type === 5 ? okr.goalObjectiveId : keyData.goalKeyId;
		let goalType = type === 1 || type === 5 ? 1 : 2;

		let message = t('deleteConfirmationForAlignment', {
			type: `${type === 1 ? t('objective') : type === 5 ? t('draft') : t('keyResult')}`,
		});

		if (goalType === 2) {
			const findKrLength = okr && okr.myGoalsDetails ? okr.myGoalsDetails?.length : 0;
			let hasContributor = keyData?.contributors.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (findKrLength === 1) {
				message = t('deleteConfirmationForAlignment', {
					type: t('keyResult'),
				});
			} else if (Boolean(hasContributor) && hasContributor.length > 0) {
				message = t('deleteConfirmationWithKr');
			}
		}

		setObjectiveModalProps({
			open: true,
			type: goalType,
			id,
			message,
		});
	};
	const showGoalAndKrAttribute = (e: any, okr: any, kr: any, type: any, key: number) => {
		showAddGoalForm(false);
		if (type === 1) {
			const updatedOkr =
				okr && okr?.contributors && okr?.myGoalsDetails
					? {
						...okr,
						contributors: okr.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
						myGoalsDetails:
							okr?.myGoalsDetails.length > 0
								? okr?.myGoalsDetails.map((item: any) => {
									let isContributor = false;
									if (item.contributors && item.contributors.length > 0) {
										item.contributors.forEach((item: any) => {
											if (item.employeeId === userDetail?.employeeId) {
												isContributor = true;
											}
										});
									}
									return {
										...item,
										isContributorOnly:
											okr.owner !== userDetail?.employeeId && okr.goalTypeId === 1 && isContributor,
										contributors: item.contributors.filter(
											(items: any) => !items.isSource && items.employeeId !== userDetail.employeeId
										),
									};
								})
								: [],
					}
					: okr;
			setGoalDetail({ actionType: 'editGoal', okr: updatedOkr, showAttributeDrawer: true });
		} else {
			const updatedOkr =
				okr && okr?.contributors && okr?.myGoalsDetails
					? {
						...okr,
						contributors: okr.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
						myGoalsDetails:
							okr?.myGoalsDetails.length > 0
								? okr?.myGoalsDetails.map((item: any) => {
									let isContributor = false;
									if (item.contributors && item.contributors.length > 0) {
										item.contributors.forEach((item: any) => {
											if (item.employeeId === userDetail?.employeeId) {
												isContributor = true;
											}
										});
									}
									return {
										...item,
										isContributorOnly:
											okr.owner !== userDetail?.employeeId && okr.goalTypeId === 1 && isContributor,
										contributors: item.contributors.filter(
											(items: any) => !items.isSource && items.employeeId !== userDetail.employeeId
										),
									};
								})
								: [],
					}
					: okr;

			let isContributor = false;
			if (kr && kr.contributors && kr.contributors.length > 0) {
				kr.contributors.forEach((item: any) => {
					if (item.employeeId === userDetail?.employeeId) {
						isContributor = true;
					}
				});
			}
			const updatedKr =
				kr && kr?.contributors && kr?.contributors.length
					? {
						...kr,
						isContributorOnly: okr.owner !== userDetail?.employeeId && isContributor,
						contributors: kr.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
					}
					: kr;
			setGoalDetail({
				actionType: key === 1 ? 'addNewKr' : 'editKR',
				okr: updatedOkr,
				kr: updatedKr,
				showAttributeDrawer: true,
			});
		}
	};
	const handleListAction = (e: any, goal: any, keyData: any, type: any) => {
		if (type === 1) {
			const updatedList = myGoalList.map((item: any) => {
				item.edit = false;
				item.expand = false;
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					setDataBeforeEdit(clone(goal));
					item.edit = true;
					item.expand = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (type === 2) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === goal.goalObjectiveId) {
					item.edit = false;
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						keys.edit = false;
						keys.expand = false;
						if (keys.goalKeyId === keyData.goalKeyId) {
							setDataBeforeEdit(keys);
							keys.edit = true;
							keys.expand = true;
						}
						return keys;
					});
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (type === 3) {
			const updatedList = myGoalList.map((item: any) => {
				item.edit = false;
				item.expand = false;
				if (item.goalKeyId === goal.goalKeyId) {
					setDataBeforeEdit(clone(goal));
					item.edit = true;
					item.expand = true;
				}
				return item;
			});
			setMyGoalList(updatedList);
		}
	};
	/** edit text for objective, key result and standalone */
	const updateName = async (e: any, okr: any, type: number, kr: any, value: any) => {
		if (value && value.trim() !== '') {
			if (type === 1) {
				setShowEditLoader({ status: 'pending', id: okr.goalObjectiveId });
				const data = { goalId: okr.goalObjectiveId, goalType: 1, description: value, dueDate: okr.dueDate };
				const resp = await props.updateGoalAndKrName(data);
				if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
					showApiMsgs(resp?.data?.messageList?.goal, 'success');
					const updatedList = myGoalList.map((item: any) => {
						if (item.goalObjectiveId === okr.goalObjectiveId) {
							item.edit = false;
							item.objectiveName = value;
						}
						return item;
					});

					setMyGoalList(updatedList);
					setShowEditLoader({ status: '', id: 0 });
				}
			} else if (type === 2) {
				setShowEditLoader({ status: 'pending', id: kr.goalKeyId });
				const data = { goalId: kr.goalKeyId, goalType: 2, description: value, dueDate: kr.dueDate };
				const resp = await props.updateGoalAndKrName(data);
				if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
					showApiMsgs(resp?.data?.messageList?.goal, 'success');
					const updatedList = myGoalList.map((item: any) => {
						if (item.goalObjectiveId === okr.goalObjectiveId) {
							item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
								if (keys.goalKeyId === kr.goalKeyId) {
									keys.edit = false;
									keys.keyDescription = value;
								}
								return keys;
							});
						}
						return item;
					});
					setMyGoalList(updatedList);
					setShowEditLoader({ status: '', id: 0 });
				}
			} else if (type === 3) {
				setShowEditLoader({ status: 'pending', id: okr.goalKeyId });
				const data = { goalId: okr.goalKeyId, goalType: 2, description: value, dueDate: okr.dueDate };
				const resp = await props.updateGoalAndKrName(data);
				if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
					showApiMsgs(resp?.data?.messageList?.goal, 'success');
					const updatedList = myGoalList.map((item: any) => {
						if (item.goalKeyId === okr.goalKeyId) {
							item.edit = false;
							item.keyDescription = value;
						}
						return item;
					});

					setMyGoalList(updatedList);
					setShowEditLoader({ status: '', id: 0 });
				}
			}
		} else {
			showApiMsgs(t('blankValueText'), 'error');
		}
	};
	const handleClickInputAway = (e: any, okr: any, type: number, kr: any) => {
		if (type === 1) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === dataBeforeEdit.goalObjectiveId) {
					item.objectiveName = dataBeforeEdit.objectiveName;
					item.edit = false;
				}
				return item;
			});
			setMyGoalList(updatedList);
		} else if (type === 2) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalObjectiveId === okr.goalObjectiveId) {
					item.myGoalsDetails = item.myGoalsDetails.map((keys: any) => {
						if (keys.goalKeyId === kr.goalKeyId) {
							keys.keyDescription = dataBeforeEdit.keyDescription;
							keys.edit = false;
						}
						return keys;
					});
				}
				return item;
			});

			setMyGoalList(updatedList);
		} else if (type === 3) {
			const updatedList = myGoalList.map((item: any) => {
				if (item.goalKeyId === dataBeforeEdit.goalKeyId) {
					item.keyDescription = dataBeforeEdit.keyDescription;
					item.edit = false;
				}
				return item;
			});
			setMyGoalList(updatedList);
		}
	};
	const addNewKeyResult = (event: any, okr: any) => {
		const newList = [...myGoalList];
		const goalListIndex = myGoalList.findIndex((item: any) => item.goalObjectiveId === okr.goalObjectiveId);
		newList[goalListIndex] = { ...newList[goalListIndex], isNewKey: !newList[goalListIndex].isNewKey };
		setMyGoalList(newList);
	};
	const saveChanges = (event: any, okr: any, saveType: number) => {
		const data = okr;
		const updatedOkr =
			data && data?.contributors && data?.myGoalsDetails
				? {
					...data,
					contributors: data.contributors.filter(
						(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
					),
					myGoalsDetails:
						data?.myGoalsDetails.length > 0
							? data?.myGoalsDetails.map((item: any) => {
								return {
									...item,
									contributors: item.contributors.filter(
										(items: any) => !items.isSource && items.employeeId !== userDetail.employeeId
									),
								};
							})
							: [],
				}
				: data;
		updatedOkr.objectiveCycleId = currentCycleId;
		if (saveType === 2 || saveType === 3) {
			if (updatedOkr.myGoalsDetails && updatedOkr.myGoalsDetails.length > 0) {
				saveAndEditObjectiveAndKr(updatedOkr, saveType, false);
			} else {
				showApiMsgs(t('atLeast1KrMsg'), 'error');
			}
		} else if (saveType === 1) {
			if (okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE) {
				const newList = [...myArchiveGoalList];
				const goalListIndex = myArchiveGoalList.findIndex((item: any) => item.goalObjectiveId === okr.goalObjectiveId);
				newList[goalListIndex] = { ...newList[goalListIndex], expand: !newList[goalListIndex].expand };
				setArchiveMyGoalList(newList);
			} else {
				const newList = [...myGoalList];
				const goalListIndex = myGoalList.findIndex((item: any) => item.goalObjectiveId === okr.goalObjectiveId);
				newList[goalListIndex] = { ...newList[goalListIndex], expand: !newList[goalListIndex].expand };
				setMyGoalList(newList);
			}
		}
	};
	const reorder = (list: any, startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};
	const onDragEnd = (result: any) => {
		try {
			// dropped outside the list
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}

			const items = reorder(myGoalList, result.source.index, result.destination.index);
			const data = items.map((ele: any, index: number) => {
				const goalType = ele.assignmentTypeId === 1 ? 1 : 2;
				const goalId = ele.assignmentTypeId === 1 ? ele.goalKeyId : ele.goalObjectiveId;
				return { goalId, sequence: index, goalType, goalCycleId: cycleId };
			});
			props.updateSequence(data);
			setMyGoalList(items);
		} catch (e) { }
	};
	const showErrorMsgWithObject = (response: any) => {
		try {
			const errorMessages = Object.values(response?.data?.messageList);
			if (errorMessages && errorMessages.length > 0) {
				errorMessages.forEach((item: any) =>
					enqueueSnackbar(item, {
						variant: 'error',
						autoHideDuration: 5000,
					})
				);
			}
		} catch (e) { }
	};
	/** Delete objective and kr api call */
	const handleCloseObjectiveModal = async (event: any, type: Number) => {
		const userDetail: any = getUserDetails();
		const dataProps = objectiveModalProps;
		const { id } = dataProps;
		const { employeeId } = userDetail;
		let message = '';
		if (type === 1) {
			message = 'Objective';

			if (dataProps.type === 1) {
				const data = `employeeId=${employeeId}&goalObjectiveId=${id}&goalType=${dataProps.type}`;
				const response = await props.deleteObjective(data);
				if (response.data.status === 200) {
					getMyTeamOKR(teamsOKR);
					showApiMsgs(response?.data?.messageList?.Result, 'success');
				} else {
					showApiMsgs(t('deletedMessageFail', { message: `${message}` }), 'error');
				}
			} else {
				message = 'Key Result';
				const data = `goalKeyId=${id}&employeeId=${employeeId}`;
				const response = await deleteContributor(data);
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					showApiMsgs(t('deletedMessage', { message: `${message}` }), 'success');
					getMyTeamOKR(teamsOKR);
				} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
					showErrorMsgWithObject(response);
				}
			}
		}
		setObjectiveModalProps({ open: false, type: '', message: '', module: '' });
	};

	const getCurrencyIcon = (currencyName: String) => {
		switch (currencyName) {
			case 'Dollar':
				return <DollarIcon />;
			case 'Euro':
				return <EuroIcon />;
			case 'Rupee':
				return <RupeeIcon />;
			case 'Yen':
				return <YenIcon />;
			case 'Pound':
				return <PoundIcon />;
			default:
				break;
		}
	};

	const onSaveContributorDetailClick = async (event: any, contributorDetail: any) => {
		event.preventDefault();
		event.stopPropagation();
		setLoader(true);
		setSnackbarProps({});
		if (contributorDetail) {
			let krDetails =
				objectiveKRDetails && objectiveKRDetails.krDetails
					? objectiveKRDetails.krDetails
					: objectiveKRDetails.okrDetails;
			krDetails = { ...krDetails, contributors: [contributorDetail] };
			if (selectedAssignmentTypeId !== null) {
				krDetails = { ...krDetails, assignmentTypeId: selectedAssignmentTypeId };
				if (krDetails && krDetails.contributors.length > 0) {
					krDetails.contributors[0].assignmentTypeId = selectedAssignmentTypeId;
				}
			}
			const response = await props.updateKrAttributes(krDetails);
			if (response && response.data && response.data.status === Enums.STATUS_SUCCESS) {
				setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
				setLoader(false);
				setOpenAddContributorDrawer(false);
				setKRAttrDateError(defaultKRDateError);
				setObjectiveKRDetails({ krDetails: null, okrDetails: null });
				props.fetchOkr();
			}
		}
	};

	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const onSaveBecomeContributorClick = async (event: any, contributorDetail: any) => {
		event.preventDefault();
		event.stopPropagation();
		setLoader(true);
		setSnackbarProps({});
		if (contributorDetail) {
			const krDetails = {
				goalKeyId: contributorDetail.goalKeyId,
				goalObjectiveId: contributorDetail.goalObjectiveId || Enums.ZERO,
				employeeId: contributorDetail.employeeId,
				startDate: contributorDetail.startDate,
				goalStatusId: contributorDetail.goalStatusId,
				goalTypeId: Enums.TWO,
				score: contributorDetail.score,
				keyDescription: contributorDetail.keyResult,
				dueDate: contributorDetail.dueDate,
				krStatusId: contributorDetail.krStatusId,
				currentValue: 0,
				targetValue: contributorDetail.targetValue,
				assignmentTypeId: contributorDetail.assignmentTypeId,
				krAssignerMessage: contributorDetail.krAssignerMessage,
				startValue: contributorDetail.startValue,
				metricId: contributorDetail.metricId,
				currencyId: contributorDetail.currencyId,
				ObjectiveCycleId: props.userSelectedCycle?.organisationCycleId || currentCycleId,
				objectiveName: contributorDetail.objectiveName,
				TeamId: contributorDetail?.TeamId ? contributorDetail?.TeamId : 0,
				IsSelf: true,
				Contributors: contributorDetail?.Contributors ? contributorDetail?.Contributors : [],
				GoalTypeId: contributorDetail?.GoalTypeId
					? contributorDetail?.GoalTypeId
					: contributorDetail.goalTypeId
						? contributorDetail.goalTypeId
						: 2,
				confidenceId: contributorDetail.confidenceId,
			};
			if (contributorDetail?.GoalTypeId === 1) {
				krDetails.IsSelf = false;
			}
			krDetails.GoalTypeId = contributorDetail.goalTypeId ? contributorDetail.goalTypeId : 2;
			const response = await props.becomeContributor(krDetails);
			if (response && response.data && response.data.status === Enums.STATUS_SUCCESS) {
				setSnackbarProps({ show: true, message: t('addedAsContributor'), variant: 'success' });
				setLoader(false);
				setOpenAddContributorDrawer(false);
				setBecomeAContributor(false);
				setObjectiveKRDetails({ krDetails: null, okrDetails: null });
				props.fetchOkr();
				props.getMyTeamOKR(teamsOKR);
			}
		}
	};

	const onSelectedUser = async (selected: any) => {
		if (Boolean(selected) && selected.length) {
			const { objectiveName } = objectiveKRDetails && objectiveKRDetails.okrDetails;
			const { assignmentTypeId, startDate, dueDate, keyDescription, score, startValue, targetValue } =
				objectiveKRDetails && objectiveKRDetails.krDetails
					? objectiveKRDetails.krDetails
					: objectiveKRDetails.okrDetails;
			const { contributors } =
				objectiveKRDetails && objectiveKRDetails.krDetails ? objectiveKRDetails.krDetails : { contributors: [] };
			const selectedUser = selected[0];
			const isExist =
				contributors && contributors.length
					? contributors.find((item: any) => item.employeeId === selectedUser.employeeId)
					: false;
			if (isExist) {
				setSelectedContributorDetails(contributorObject);
				setAddContributorAnchorEl(null);
				setAddContributorOpen(false);
				if (isExist.krStatusId === Enums.ONE) {
					//Pending
					enqueueSnackbar(`${t('pendingUserMessage')} ${getFullName(selectedUser)}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				} else if (isExist.krStatusId === Enums.THREE) {
					//Declined
					enqueueSnackbar(`${getFullName(selectedUser)} ${t('declinedUserMessage')}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				} else {
					enqueueSnackbar(`${getFullName(selectedUser)} ${t('alreadyAlign')}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				}
			} else {
				const { okrDetails, krDetails } = objectiveKRDetails;
				let sourceType = Enums.TWO;
				const details = krDetails ? krDetails : okrDetails;
				const goalKeyId = details?.goalKeyId ? details?.goalKeyId : 0;
				const sourceId = details?.source && details?.source > 0 ? details?.source : goalKeyId;

				setAlignedStatusData({
					source: details?.source,
					goalKeyId: goalKeyId,
					sourceType: sourceType,
				});
				setSelectedContributorDetails({
					...selectedContributorDetails,
					...selectedUser,
					goalStatusId: okrDetails?.goalStatusId || Enums.TWO,
					goalTypeId: okrDetails?.goalTypeId,
					krStatusId: Enums.ONE,
					krStatusName: 'Pending',
					assignmentTypeId: assignmentTypeId || Enums.TWO,
					keyResult: keyDescription || '',
					score: score || Enums.ZERO,
					startDate: startDate || '',
					dueDate: dueDate || '',
					startValue: startValue || Enums.ZERO,
					targetValue: targetValue || Enums.ZERO,
					message: '',
					metricName: '',
					currencyName: '',
					objectiveName: objectiveName || '',
					isAddedNew: true,
				});
				setAddContributorAnchorEl(null);
				setAddContributorOpen(false);
				setOpenAddContributorDrawer(true);
				setKRAttrDateError(defaultKRDateError);
			}
		} else {
			setSelectedContributorDetails(contributorObject);
		}
	};

	const updateConfidence = (okr: any, index: number, value: number) => {
		const updatedList = myGoalList.map((item: any) => {
			if (item.goalObjectiveId === okr.goalObjectiveId) {
				item.myGoalsDetails[index].confidenceId = value;
				item.myGoalsDetails[index].isConfidenceAlert = false;
				let okrConfidence = false;
				item.myGoalsDetails.forEach((krItem: any, krIndex: number) => {
					if (krIndex !== index && krItem.isConfidenceAlert) {
						okrConfidence = true;
					}
				});
				item.isConfidenceAlert = okrConfidence;
			}
			return item;
		});
		setMyGoalList(updatedList);
		if (cycleId) {
			const data = `cycle=${cycleId}`;
			props.getConfidenceAlertDetails(data);
		}
	}

	return (
		<Fragment>
			<Box className='teamOkrListing'>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId='list'>
						{(provided: any) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								<Box className='my-obj-listing'>
									{myGoalList && myGoalList.length ? (
										myGoalList.map((okr: any, index: number) => (
											<Draggable
												key={okr.assignmentTypeId === 1 ? okr.goalKeyId : okr.goalObjectiveId}
												draggableId={`draggable_${okr.goalObjectiveId}`}
												index={index}
												// isDragDisabled={currentUser ? true : false}
												isDragDisabled={true}
											>
												{(provided: any, snapshot: any) => (
													<Fragment>
														{okr.assignmentTypeId === 1 ? (
															<StandaloneKrList
																{...props}
																provided={provided}
																showActionButton={showActionButton}
																getItemStyle={getItemStyle}
																snapshot={snapshot}
																okr={okr}
																handleKREndOfMonthDateSelection={handleKREndOfMonthDateSelection}
																handleDateChange={handleDateChange}
																handleKeyboardButtonClick={handleKeyboardButtonClick}
																openCalender={openCalender}
																updateTextValue={updateTextValue}
																openDrawer={openDrawer}
																setOpenDrawer={setOpenDrawer}
																showEditLoader={showEditLoader}
																t={t}
																updateProgress={updateProgress}
																handleAddUserClick={handleAddUserClick}
																handleBecomeAContributorClick={handleBecomeAContributorClick}
																showAddToObjective={showAddToObjective}
																handleDeleteOkr={handleDeleteOkr}
																showGoalAndKrAttribute={showGoalAndKrAttribute}
																handleListAction={handleListAction}
																updateName={updateName}
																handleClickInputAway={handleClickInputAway}
																isLocked={props.listOkrResult && props.listOkrResult.isLocked}
																currentUser={currentUser}
																teamsOkr={true}
																currentCycleEndDate={currentCycleEndDate}
															/>
														) : (
															<ObjectiveList
																updateConfidence={updateConfidence}
																provided={provided}
																showActionButton={showActionButton}
																getItemStyle={getItemStyle}
																snapshot={snapshot}
																okr={okr}
																handleKREndOfMonthDateSelection={handleKREndOfMonthDateSelection}
																handleDateChange={handleDateChange}
																handleKeyboardButtonClick={handleKeyboardButtonClick}
																openCalender={openCalender}
																updateTextValue={updateTextValue}
																handleClickInputAway={handleClickInputAway}
																handleListAction={handleListAction}
																updateName={updateName}
																showGoalAndKrAttribute={showGoalAndKrAttribute}
																showEditLoader={showEditLoader}
																t={t}
																updateProgress={updateProgress}
																handleAddUserClick={handleAddUserClick}
																handleBecomeAContributorClick={handleBecomeAContributorClick}
																maxObjectiveId={props.maxObjectiveId}
																highlightTeamsOkrId={highlightTeamsOkrId}
																setHighlightTeamsOkrId={setHighlightTeamsOkrId}
																addNewKeyResult={addNewKeyResult}
																saveChanges={saveChanges}
																handleDeleteOkr={handleDeleteOkr}
																openDrawer={openDrawer}
																setOpenDrawer={setOpenDrawer}
																isLocked={props.listOkrResult && props.listOkrResult.isLocked}
																showArchiveList={showArchiveList}
																ObjectiveList={ObjectiveList}
																teamsOkr={true}
																currentCycleEndDate={currentCycleEndDate}
																{...props}
																selectedTeam={teamsOKR}
															/>
														)}
													</Fragment>
												)}
											</Draggable>
										))
									) : (
										<Fragment>{!showArchiveList && <NoListFound showSkeleton={false} />}</Fragment>
									)}
								</Box>
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Box>
			{objectiveModalProps.open && (
				<AlertDialog
					module={objectiveModalProps.module ? objectiveModalProps.module : 'goals'}
					message={objectiveModalProps.message}
					handleCloseModal={handleCloseObjectiveModal}
					modalOpen={objectiveModalProps.open}
				/>
			)}
			{addContributorOpen ? (
				<SearchUserPopover
					{...props}
					popperAddClassName={'quick-add-contributor-popup'}
					addContributorAnchorEl={addContributorAnchorEl}
					addContributorOpen={addContributorOpen}
					setAddContributorOpen={setAddContributorOpen}
					setAddContributorAnchorEl={setAddContributorAnchorEl}
					isCustomStyle={customStyle}
					onSelectedUser={onSelectedUser}
					handleSearchUserInputChange={handleSearchUserInputChange}
					currentObjective={currentObjective}
					customId={'team-add-contri-src-field'}
				/>
			) : (
				<></>
			)}
			{openAddContributorDrawer ? (
				<BecomeContributor
					{...props}
					type={becomeAContributor ? 3 : 1}
					becomeAContributor={becomeAContributor}
					myGoalList={myGoalList}
					krContributorDetailDashboard={krContributorDetailDashboard}
					setKRContributorDetailDashboard={setKRContributorDetailDashboard}
					krAttrDateError={krAttrDateError}
					handleAddContributor={handleAddContributor}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					onSaveBecomeContributorClick={onSaveBecomeContributorClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedContributorDetails={selectedContributorDetails}
					objectiveKRDetails={objectiveKRDetails}
					setAssignmentTypeId={setAssignmentTypeId}
					t={t}
					setKRAttrDateError={setKRAttrDateError}
					defaultKRDateError={defaultKRDateError}
					okrMasterData={props.okrMasterData}
					openDrawer={openAddContributorDrawer}
					loader={loader}
					alignedStatusData={alignedStatusData}
				/>
			) : (
				<></>
			)}
		</Fragment>
	);
};

import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	allVersionFeatureDetails: [],
	allVersionFeatureDetailsPending: false,
	allVersionFeatureDetailsError: null,
	allUserVersionDetails: [],
	allUserVersionDetailsPending: false,
	allUserVersionDetailsError: null,
	allVersionPageDetails: null,
};

export default function okrUpdateReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GET_VERSION_DETAILS_SUCCESS:
			return {
				...state,
				allVersionFeatureDetails: action.payload,
				allVersionFeatureDetailsPending: false,
				allVersionFeatureDetailsError: null,
			};
		case actionTypes.GET_VERSION_DETAILS_PENDING:
			return {
				...state,
				allVersionFeatureDetails: [],
				allVersionFeatureDetailsPending: true,
				allVersionFeatureDetailsError: null,
			};
		case actionTypes.GET_VERSION_DETAILS_FAILED:
			return {
				...state,
				allVersionFeatureDetails: [],
				allVersionFeatureDetailsPending: false,
				allVersionFeatureDetailsError: action.payload,
			};
		case actionTypes.GET_USER_VERSION_DETAILS_SUCCESS:
			return {
				...state,
				allUserVersionDetails: [...state?.allUserVersionDetails, ...action.payload],
				allUserVersionDetailsPending: false,
				allUserVersionDetailsError: null,
			};
		case actionTypes.GET_USER_VERSION_DETAILS_PENDING:
			return {
				...state,
				allUserVersionDetails: [],
				allUserVersionDetailsPending: true,
				allUserVersionDetailsError: null,
			};
		case actionTypes.GET_USER_VERSION_DETAILS_FAILED:
			return {
				...state,
				allUserVersionDetails: [],
				allUserVersionDetailsPending: false,
				allUserVersionDetailsError: action.payload,
			};
		case actionTypes.GET_USER_VERSION_PAGE_DETAILS:
			return {
				...state,
				allVersionPageDetails: action.payload,
			};
		default:
			return state;
	}
}

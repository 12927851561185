import React from 'react';
import {
	viewOrganizationManagementPage,
	viewRoleManagement,
	viewUserManagementPage,
} from '../components/Admin/AdminConstant';

export const checkPermission = (employeePermissions: any, permissionId: any) => {
	const permission =
		employeePermissions && employeePermissions.filter((item: any) => item.permissionId === permissionId);
	return permission && permission.length;
};

export const adminTabPermission = (employeePermissions: any) => {
	const permission =
		employeePermissions &&
		employeePermissions.filter((item: any) => {
			return (
				item.permissionId === viewOrganizationManagementPage || //view organization permission
				item.permissionId === viewUserManagementPage || //view User permission
				item.permissionId === viewRoleManagement //view Role permission
			);
		});

	return permission && permission.length;
};

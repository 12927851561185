import React, { Fragment } from 'react';
import { polyfill } from 'react-lifecycles-compat';
import T from 'prop-types';
import { layout, select, behavior, event } from 'd3';
import clone from 'clone';
import deepEqual from 'deep-equal';
import { v4 as uuid } from 'uuid';

import NodeWrapper from './NodeWrapper';
import Node from '../Node';
import Link from '../Link';
import OrganizationMenu from '../../Admin/Organizations/OrganizationMenu';
import AlertDialog from '../../Common/Dialog';
import CreateOrganization from '../../Admin/Organizations/CreateOrganization';
import CreateChild from '../../Admin/Organizations/CreateChild';
import OrgHeader from '../../Admin/Organizations/OrgHeader';
import OrganizationChart from '../../Admin/Organizations/OrganizationChart';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import './style.css';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { FitToScreenIcon } from '../../../config/svg/ActionSvg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import noRecordFound from '../../../images/search-placeholder.svg';
import { EditOrganization } from '../../Admin/Organizations/EditOrganization/EditOrganization';
import { AddTeam } from '../../Admin/Organizations/AddTeam/AddTeam';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import { Enums } from '../../../config/enums';
import { HOME, USER_VIEW, TEAM_VIEW } from '../../../config/app-url';
import { useHistory } from 'react-router-dom';
class Tree extends React.Component {
	state = {
		// eslint-disable-next-line react/no-unused-state
		dataRef: this.props.data,
		data: Tree.assignInternalProperties(this.props.data),
		d3: Tree.calculateD3Geometry(this.props),
		rd3tSvgClassName: `_${uuid()}`,
		rd3tGClassName: `_${uuid()}`,
		open: false,
		anchorEl: null,
		currentOrg: {},
		modalProps: { open: false, message: '' },
		drawerProps: { createType: '', header: '', callApi: false },
		designationList: [],
		deleteModal: {
			openDeleteModal: false,
		},
	};

	openDrawer = (type, header, currentItem) => {
		this.setState({
			...this.state,
			anchorEl: null,
			open: false,
			drawerProps: { createType: type, header, currentItem },
		});
	};

	closeDrawer = () => {
		this.setState({
			...this.state,
			drawerProps: { createType: '', header: '' },
		});

		// this.props.fetchOrganization();
	};

	static updateData = (props, organisation) => {
		let { fetchOrganization, currentItem } = props;
		if (organisation) {
			fetchOrganization(organisation);
		} else {
			fetchOrganization(currentItem);
		}
	};

	/*===== fetch Designation =========*/
	fetchDesignation = async (e) => {
		const { target } = e;
		let resp = await this.props.getDesignation(`designation=${target.value}`);
		if (resp.data.status === 200) {
			this.setState({ designationList: resp.data.entityList });
		}
	};
	/*===== fetch Designation End ======*/

	handleToggle = (event, targetVal, currentItem) => {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			...this.state,
			open: !this.state.open,
			anchorEl: targetVal,
			currentOrg: currentItem,
		});
	};

	handleClose = () => {
		this.setState({ ...this.state, anchorEl: null, open: false, openEditOrgDrawer: false });
	};

	openEditOrgDrawer = (e, type) => {
		if (type === 'editOrg') {
			this.setState({ ...this.state, anchorEl: null, open: false, openEditOrgDrawer: true });
		}
	};

	handleViewOrganisation = (e, currentOrg) => {
		let selectedOrg = { ...currentOrg };
		delete selectedOrg['parent'];
		this.setState({ ...this.state, anchorEl: null, open: false });

		//Set current organisation
		setLocalStorageItem('currentOrganisation', JSON.stringify(selectedOrg));
		// setting redirect url for dashboard teamsOkr tab
		setLocalStorageItem(
			'backUrl',
			JSON.stringify({
				url: HOME,
				tabToOpen: 'orgChart',
			})
		);
		this.props.resetOkr();
		this.props.history.push(TEAM_VIEW);
	};

	handleCloseModal = async (e, type) => {
		if (type === 1 && !this.state.modalProps.isCancel) {
			try {
				const { organisation } = this.state.modalProps;
				const data = `organisationId=${organisation.organisationId}`;

				this.setState({
					...this.state,
					modalProps: { open: false, message: '' },
				});
				const response = await this.props.deleteOrganization(data);
				if (response.data.status === 200) {
					Tree.updateData(this.props, organisation);
					this.props.enqueueSnackbar(`Organization deleted successfully`, {
						variant: 'success',
						autoHideDuration: 5000,
					});
				} else if (response.data && response.data.status === 400) {
					const responseAPI = response.data.messageList;
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					this.props.enqueueSnackbar(`${messages} `, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				} else {
					this.props.enqueueSnackbar(`Problem while deleting Organization`, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			} catch (e) {
				this.props.enqueueSnackbar(`Some error occurred`, { variant: 'error', autoHideDuration: 5000 });
			}
		}
		this.setState({
			...this.state,
			modalProps: { open: false, message: '' },
		});
	};

	handleDeleteOrganization = (e, organisation) => {
		e.preventDefault();
		e.stopPropagation();
		let isCancel = false;
		let message = '';
		if (organisation._children && organisation._children.length > 0) {
			isCancel = true;
			message = this.props.t('deleteNestedOrganisation');
			this.setState({
				...this.state,
				modalProps: {
					open: true,
					message: message,
					organisation,
					isCancel,
				},
			});
		} else if (organisation.parentId !== 0) {
			this.setState({
				...this.state,
				deleteModal: {
					openDeleteModal: true,
					organisation,
				},
			});
			// message = 'If you will delete this Organization, All the users will be moved to parent organization';
		} else if (organisation.parentId === 0 && organisation.employeeCount > 0) {
			isCancel = true;
			message =
				'Go and change the organization of all the user aligned to this organization first otherwise all the user aligned to organization will be deleted on the deletion of this organization.';
		} else {
			// this.setState({
			// 	...this.state,
			// 	showDeleteDialog: true,
			// });
			message = `Are you sure you want to delete this organization? This action can not be undone!`;
		}
	};

	internalState = {
		initialRender: true,
		targetNode: null,
		isTransitioning: false,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		let derivedState = null;

		// Clone new data & assign internal properties if `data` object reference changed.
		if (nextProps.orgData && nextProps.orgData.length > 0 && nextProps.data !== prevState.dataRef) {
			derivedState = {
				// eslint-disable-next-line react/no-unused-state
				dataRef: nextProps.data,
				data: Tree.assignInternalProperties(nextProps.data),
			};
		}
		if (nextProps.orgData && nextProps.orgData.length > 0) {
			const d3 = Tree.calculateD3Geometry(nextProps);
			if (!deepEqual(d3, prevState.d3)) {
				derivedState = derivedState || {};
				derivedState.d3 = d3;
			}
		}

		return derivedState;
	}

	componentDidMount() {
		this.bindZoomListener(this.props);
		this.internalState.initialRender = false;
		setTimeout(() => {
			this.redirectToUrl();
		}, 2000);
	}

	componentDidUpdate(prevProps) {
		// If zoom-specific props change -> rebind listener with new values
		// Or: rebind zoom listeners to new DOM nodes in case NodeWrapper switched <TransitionGroup> <-> <g>
		if (
			this.props.orgData.length > 0 ||
			!deepEqual(this.props.translate, prevProps.translate) ||
			!deepEqual(this.props.scaleExtent, prevProps.scaleExtent) ||
			this.props.zoom !== prevProps.zoom ||
			this.props.transitionDuration !== prevProps.transitionDuration
		) {
			if (this.props.orgData && this.props.orgData.length > 0) {
				this.bindZoomListener(this.props);
			}
		}

		if (this.props.isSearch) {
			setTimeout(() => this.zoomFit(0.75, 500), 505);
		} else if (this.props.searchClear) {
			setTimeout(() => this.zoomFit(0.75, 500), 505);
		}

		if (typeof this.props.onUpdate === 'function') {
			this.props.onUpdate({
				node: this.internalState.targetNode ? clone(this.internalState.targetNode) : null,
				zoom: this.state.d3.scale,
				translate: this.state.d3.translate,
			});
		}

		setTimeout(() => {
			this.redirectToUrl();
		}, 2000);

		// Reset the last target node after we've flushed it to `onUpdate`.
		this.internalState.targetNode = null;
	}

	redirectToUrl = () => {
		let redirectUrl = getLocalStorageItem('redirectUrl');
		if (Boolean(redirectUrl)) {
			redirectUrl = JSON.parse(redirectUrl);

			if (redirectUrl.url && redirectUrl.url.includes('/')) {
				if (redirectUrl.url.includes('Organization')) {
					const itemArray = redirectUrl.url.split('/');
					const parentId = itemArray[1] ? itemArray[1] : 0;
					const orgId = itemArray[2] ? itemArray[2] : 0;
					if (Boolean(parentId) && parentId > 0) {
						this.openDrawer('editChild', 'Edit Child', { parentId: parentId, organisationId: orgId });
					} else {
						this.openDrawer('editOrg', 'Edit Organization', { parentId: 0, organisationId: orgId });
					}
					removeLocalStorageItem('redirectUrl');
				}
			}
		}
	};

	/**
	 * setInitialTreeDepth - Description
	 *
	 * @param {array} nodeSet Array of nodes generated by `generateTree`
	 * @param {number} initialDepth Maximum initial depth the tree should render
	 *
	 * @return {void}
	 */
	setInitialTreeDepth(nodeSet, initialDepth) {
		nodeSet.forEach((n) => {
			n._collapsed = n.depth >= initialDepth;
		});
	}

	clickHandler = (nodeId, event) => {
		const { rd3tGClassName } = this.state;
		const g = select(`.${rd3tGClassName}`);
		const data = clone(this.state.data);
		const matches = this.findNodesById(nodeId, data, []);
		let targetNode = matches[0];
		let nodes = [];
		try {
			if (targetNode) {
				nodes.push(targetNode);

				while (targetNode.parent) {
					targetNode = targetNode.parent;
					nodes.push(targetNode);
				}

				const node = g.selectAll('.node')[0];
				const link = g.selectAll('.link')[0];
				const filterNode = node.filter((d) => nodes.find((item) => item?.id === d?.getAttribute('id')));
				const filterLinks = link.filter((d) => nodes.find((item) => item?.id === d?.getAttribute('target')));
				filterNode.forEach((el) => {
					if (el) {
						el.querySelector('.oc-node').classList.add('highlight-node');
					}
				});
				filterLinks.forEach((el) => {
					if (el) {
						el.querySelector('.linkBase').style.stroke = 'red';
						el.querySelector('.linkBase').style.strokeWidth = '3.0px';
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	removeHandler = () => {
		const element = document.querySelectorAll('.highlight-node');

		const element1 = document.querySelectorAll('.linkBase');
		if (element) {
			// element1.classList.remove('highlight-node');
			element.forEach((el) => {
				el.classList.remove('highlight-node');
			});
			element1.forEach((el) => {
				el.style.stroke = '#39A3FA';
				el.style.strokeWidth = '2.0px';
			});
		}
	};

	zoomFit = (paddingPercent, transitionDuration, node, type) => {
		let top = type === 1 ? 180 : 100;
		try {
			if (this.state.data[0] && this.state.data[0].children && this.state.data[0].children.length > 8) {
				paddingPercent = 0.95;
			}
			const { scaleExtent } = this.props;
			const { rd3tSvgClassName, rd3tGClassName } = this.state;
			const svg = select(`.${rd3tSvgClassName}`);
			const root = select(`.${rd3tGClassName}`);
			if (root && root.node()) {
				var bounds = root.node().getBBox();
				var parent = root.node().parentElement;
				var fullWidth = parent.clientWidth,
					fullHeight = parent.clientHeight;
				var width = bounds.width,
					height = bounds.height;
				var midX = bounds.x + width / 2,
					midY = bounds.y + height / 2;
				if (width === 0 || height === 0) return; // nothing to fit
				var scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
				// if (scale > 1) {
				// 	scale = 1;
				// }
				let x = fullWidth / 2 - scale * midX;
				let y = fullHeight / 2 - scale * midY;
				let xx = x;
				let yy = y;
				if (type === 1) {
					scale = 0.55;
					xx = -node.x;
					yy = -node.y;
					xx = xx * scale + fullWidth / 2;
					yy = yy * scale + fullHeight / 2;
					yy = yy - top;
					xx = xx - scale * 170;
				} else {
					yy = 0;
				}
				var translate = [xx, yy];

				svg
					.transition()
					.duration(3000)
					.call(
						behavior
							.zoom()
							.scaleExtent([scaleExtent.min, scaleExtent.max])
							.on('zoom', () => {
								// eslint-disable-next-line react/no-direct-mutation-state
								this.state.d3.scale = scale;
								// eslint-disable-next-line react/no-direct-mutation-state
								this.state.d3.translate = { x: translate[0], y: translate[1] };
								root.attr('transform', `translate(${event.translate}) scale(${event.scale})`);
								//this.bindZoomListener(this.props); // commented to stop loop calling bindZoomListener
							})
							.translate(translate)
							.scale(scale).event
					);
			}
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * bindZoomListener - If `props.zoomable`, binds a listener for
	 * "zoom" events to the SVG and sets scaleExtent to min/max
	 * specified in `props.scaleExtent`.
	 *
	 * @return {void}
	 */
	bindZoomListener(props) {
		const { zoomable, scaleExtent, onUpdate } = props;
		const { rd3tSvgClassName, rd3tGClassName } = this.state;
		const svg = select(`.${rd3tSvgClassName}`);
		const g = select(`.${rd3tGClassName}`);
		if (zoomable) {
			svg.call(
				behavior
					.zoom()
					.scaleExtent([scaleExtent.min, scaleExtent.max])
					.on('zoom', () => {
						if (event.scale === 1 && this.state.d3.scale !== 1) {
							event.scale = this.state.d3.scale;
						}

						g.attr('transform', `translate(${event.translate}) scale(${event.scale})`);

						if (typeof onUpdate === 'function') {
							// This callback is magically called not only on "zoom", but on "drag", as well,
							// even though event.type === "zoom".
							// Taking advantage of this and not writing a "drag" handler.
							onUpdate({
								node: null,
								zoom: event.scale,
								translate: { x: event.translate[0], y: event.translate[1] },
							});
						}
						// eslint-disable-next-line react/no-direct-mutation-state
						this.state.d3.scale = event.scale;
						// eslint-disable-next-line react/no-direct-mutation-state
						this.state.d3.translate = { x: event.translate[0], y: event.translate[1] };
					})
					.scale(this.state.d3.scale)
					.translate([this.state.d3.translate.x, this.state.d3.translate.y])
			);
		}
	}

	/**
	 * assignInternalProperties - Assigns internal properties to each node in the
	 * `data` set that are required for tree manipulation and returns
	 * a new `data` array.
	 *
	 * @static
	 * @param {array} data Hierarchical tree data
	 *
	 * @return {array} `data` array with internal properties added
	 */
	static assignInternalProperties(data) {
		// Wrap the root node into an array for recursive transformations if it wasn't in one already.
		const d = Array.isArray(data) ? data : [data];
		return d.map((node) => {
			node.id = uuid();
			// If the node's `_collapsed` state wasn't defined by the data set -> default to `false`.
			if (node._collapsed === undefined) {
				node._collapsed = false;
			}
			// If there are children, recursively assign properties to them too
			if (node.children && node.children.length > 0) {
				node.children = Tree.assignInternalProperties(node.children);
				node._children = node.children;
			}
			return node;
		});
	}

	/**
	 * findNodesById - Recursively walks the nested `nodeSet` until a node matching `nodeId` is found.
	 *
	 * @param {string} nodeId The `node.id` being searched for
	 * @param {array} nodeSet Array of nested `node` objects
	 * @param {array} hits Accumulator for matches, passed between recursive calls
	 *
	 * @return {array} Set of nodes matching `nodeId`
	 */
	// TODO: Refactor this into a more readable/reasonable recursive depth-first walk.
	findNodesById(nodeId, nodeSet, hits) {
		if (hits.length > 0) {
			return hits;
		}

		hits = hits.concat(nodeSet.filter((node) => node.id === nodeId));

		nodeSet.forEach((node) => {
			if (node._children && node._children.length > 0) {
				hits = this.findNodesById(nodeId, node._children, hits);
			}
		});

		return hits;
	}

	/**
	 * findNodesAtDepth - Recursively walks the nested `nodeSet` until all nodes at `depth` have been found.
	 *
	 * @param {number} depth Target depth for which nodes should be returned
	 * @param {array} nodeSet Array of nested `node` objects
	 * @param {array} accumulator Accumulator for matches, passed between recursive calls
	 * @return
	 */
	findNodesAtDepth(depth, nodeSet, accumulator) {
		accumulator = accumulator.concat(nodeSet.filter((node) => node.depth === depth));

		nodeSet.forEach((node) => {
			if (node._children && node._children.length > 0) {
				accumulator = this.findNodesAtDepth(depth, node._children, accumulator);
			}
		});

		return accumulator;
	}

	/**
	 * collapseNode - Recursively sets the `_collapsed` property of
	 * the passed `node` object and its children to `true`.
	 *
	 * @param {Node} node Node object with custom properties
	 *
	 * @return {void}
	 */
	static collapseNode(node, collapse) {
		node._collapsed = collapse;
		if (node._children && node._children.length > 0) {
			node._children.forEach((child) => {
				Tree.collapseNode(child, collapse);
			});
		}
	}

	/**
	 * expandNode - Sets the `_collapsed` property of
	 * the passed `node` object to `false`.
	 *
	 * @param {object} node Node object with custom properties
	 *
	 * @return {void}
	 */
	static expandNode(node) {
		node._collapsed = false;
	}

	/**
	 * collapseNodeNeighbors - Collapses all nodes in `nodeSet` that are neighbors (same depth) of `targetNode`.
	 *
	 * @param {object} targetNode
	 * @param {array} nodeSet
	 *
	 * @return {void}
	 */
	collapseNeighborNodes(targetNode, nodeSet) {
		const neighbors = this.findNodesAtDepth(targetNode.depth, nodeSet, []).filter((node) => node.id !== targetNode.id);
		neighbors.forEach((neighbor) => Tree.collapseNode(neighbor, true));
	}

	/**
	 * handleNodeToggle - Finds the node matching `nodeId` and
	 * expands/collapses it, depending on the current state of
	 * its `_collapsed` property.
	 * `setState` callback receives targetNode and handles
	 * `props.onClick` if defined.
	 *
	 * @param {string} nodeId A node object's `id` field.
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleNodeToggle = (nodeId, evt) => {
		const data = clone(this.state.data);
		const matches = this.findNodesById(nodeId, data, []);
		const targetNode = matches[0];

		// Persist the SyntheticEvent for downstream handling by users.
		evt.persist();

		if (this.props.collapsible && !this.state.isTransitioning) {
			if (targetNode._collapsed) {
				targetNode.highlight = true;
				Tree.expandNode(targetNode);
				this.props.shouldCollapseNeighborNodes && this.collapseNeighborNodes(targetNode, data);
				setTimeout(() => this.zoomFit(0.75, 750, targetNode, 1), 755);
			} else {
				targetNode.highlight = false;
				Tree.collapseNode(targetNode, true);
				setTimeout(() => this.zoomFit(0.95, 750, targetNode, 1), 755);
			}

			// Lock node toggling while transition takes place
			this.setState({ data, isTransitioning: true }, () => this.handleOnClickCb(targetNode, evt));
			// Await transitionDuration + 10 ms before unlocking node toggling again
			setTimeout(() => this.setState({ isTransitioning: false }), this.props.transitionDuration + 6);
			this.internalState.targetNode = targetNode;
		} else {
			this.handleOnClickCb(targetNode, evt);
		}
	};

	/**
	 * handleOnClickCb - Handles the user-defined `onClick` function
	 *
	 * @param {object} targetNode Description
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnClickCb = (targetNode, evt) => {
		const { onClick } = this.props;
		if (onClick && typeof onClick === 'function') {
			onClick(clone(targetNode), evt);
		}
	};

	/**
	 * handleOnLinkClickCb - Handles the user-defined `onLinkClick` function
	 *
	 * @param {object} linkSource Description
	 *
	 * @param {object} linkTarget Description
	 *
	 *  @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnLinkClickCb = (linkSource, linkTarget, evt) => {
		const { onLinkClick } = this.props;
		if (onLinkClick && typeof onLinkClick === 'function') {
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onLinkClick(clone(linkSource), clone(linkTarget), evt);
		}
	};

	/**
	 * handleOnMouseOverCb - Handles the user-defined `onMouseOver` function
	 *
	 * @param {string} nodeId
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnMouseOverCb = (nodeId, evt) => {
		this.clickHandler(nodeId, evt);
		const { onMouseOver } = this.props;
		if (onMouseOver && typeof onMouseOver === 'function') {
			const data = clone(this.state.data);
			const matches = this.findNodesById(nodeId, data, []);
			const targetNode = matches[0];

			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onMouseOver(clone(targetNode), evt);
		}
	};

	/**
	 * handleOnLinkMouseOverCb - Handles the user-defined `onLinkMouseOver` function
	 *
	 * @param {object} linkSource Description
	 *
	 * @param {object} linkTarget Description
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnLinkMouseOverCb = (linkSource, linkTarget, evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		const { onLinkMouseOver } = this.props;
		if (onLinkMouseOver && typeof onLinkMouseOver === 'function') {
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onLinkMouseOver(clone(linkSource), clone(linkTarget), evt);
		}
	};

	/**
	 * handleOnMouseOutCb - Handles the user-defined `onMouseOut` function
	 *
	 * @param {string} nodeId
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnMouseOutCb = (nodeId, evt) => {
		this.removeHandler(evt);
		const { onMouseOut } = this.props;
		if (onMouseOut && typeof onMouseOut === 'function') {
			const data = clone(this.state.data);
			const matches = this.findNodesById(nodeId, data, []);
			const targetNode = matches[0];
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onMouseOut(clone(targetNode), evt);
		}
	};

	/**
	 * handleOnLinkMouseOutCb - Handles the user-defined `onLinkMouseOut` function
	 *
	 * @param {string} linkSource
	 *
	 * @param {string} linkTarget
	 *
	 * @param {object} evt Event
	 *
	 * @return {void}
	 */
	handleOnLinkMouseOutCb = (linkSource, linkTarget, evt) => {
		const { onLinkMouseOut } = this.props;
		if (onLinkMouseOut && typeof onLinkMouseOut === 'function') {
			// Persist the SyntheticEvent for downstream handling by users.
			evt.persist();
			onLinkMouseOut(clone(linkSource), clone(linkTarget), evt);
		}
	};

	/**
	 * generateTree - Generates tree elements (`nodes` and `links`) by
	 * grabbing the rootNode from `this.state.data[0]`.
	 * Restricts tree depth to `props.initialDepth` if defined and if this is
	 * the initial render of the tree.
	 *
	 * @return {object} Object containing `nodes` and `links`.
	 */
	generateTree() {
		const { initialDepth, useCollapseData, separation, nodeSize, orientation } = this.props;

		const tree = layout
			.tree()
			.nodeSize(orientation === 'horizontal' ? [nodeSize.y, nodeSize.x] : [nodeSize.x, nodeSize.y])
			.separation((a, b) => (a.parent.id === b.parent.id ? separation.siblings : separation.nonSiblings))
			.children((d) => (d._collapsed ? null : d._children));

		const rootNode = this.state.data[0];
		let nodes = tree.nodes(rootNode);

		// set `initialDepth` on first render if specified
		if (useCollapseData === false && initialDepth !== undefined && this.internalState.initialRender) {
			this.setInitialTreeDepth(nodes, initialDepth);
			nodes = tree.nodes(rootNode);
		}

		//if (depthFactor) {
		nodes.forEach((node) => {
			node.y = node.level === 1 ? node.depth * (280 * 1.5) : node.depth * (270 * 1.5);
		});
		//}

		const links = tree.links(nodes);
		return { nodes, links };
	}

	/**
	 * calculateD3Geometry - Set initial zoom and position.
	 * Also limit zoom level according to `scaleExtent` on initial display. This is necessary,
	 * because the first time we are setting it as an SVG property, instead of going
	 * through D3's scaling mechanism, which would have picked up both properties.
	 *
	 * @param  {object} nextProps
	 * @return {object} {translate: {x: number, y: number}, zoom: number}
	 */
	static calculateD3Geometry(nextProps) {
		let scale;

		if (nextProps.zoom > nextProps.scaleExtent.max) {
			scale = nextProps.scaleExtent.max;
		} else if (nextProps.zoom < nextProps.scaleExtent.min) {
			scale = nextProps.scaleExtent.min;
		} else {
			scale = nextProps.zoom;
		}

		return {
			translate: nextProps.translate,
			scale,
		};
	}

	handleOrgUpdate = () => {
		this.props.fetchOrganization();
	};

	handleDialogCancelClick = () => {
		this.setState({
			...this.state,
			deleteModal: {
				openDeleteModal: false,
			},
		});
	};

	handleConfirmClick = async () => {
		let data = `teamId=${this.state.deleteModal.organisation.teamId}`;
		let response = await this.props.deleteTeam(data);
		if (response.data.status === 200) {
			this.props.enqueueSnackbar(this.props.t('teamDeleteSuccess'), {
				variant: 'success',
				autoHideDuration: 5000,
			});
			this.props.fetchOrganization();
		} else {
			if (response.data.messageList.teamId) {
				this.props.enqueueSnackbar(response.data.messageList.teamId, {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
		this.setState({
			...this.state,
			deleteModal: {
				openDeleteModal: false,
			},
		});
	};

	render() {
		const { nodes, links } = this.generateTree();
		const { rd3tSvgClassName, rd3tGClassName } = this.state;
		const { location, history } = this.props;
		const {
			nodeSvgShape,
			orientation,
			pathFunc,
			transitionDuration,
			zoomable,
			textLayout,
			nodeSize,
			depthFactor,
			initialDepth,
			separation,
			circleRadius,
			allowForeignObjects,
			styles,
			organizationList,
			filterSearchValue,
			loaderStatus,
			orgData,
			dataFound,
		} = this.props;
		const { translate, scale } = this.state.d3;
		const subscriptions = { ...nodeSize, ...separation, depthFactor, initialDepth };
		const { drawerProps } = this.state;
		return (
			<Fragment>
				<OrgHeader
					{...this.props}
					drawerProps={drawerProps}
					openDrawer={this.openDrawer}
					filterSearchValue={filterSearchValue}
					zoomFit={this.zoomFit}
				/>
				<Box className='global-header-info'>
					<Box className='fit-screen-action'>
						<Tooltip title={'Zoom to Fit'} arrow>
							<Button id='fit-to-screen-btn' className='fit-to-screen-icon' onClick={() => this.zoomFit(0.75, 500)}>
								<FitToScreenIcon />
							</Button>
						</Tooltip>
					</Box>
				</Box>
				{orgData && orgData.length > 0 ? (
					<Box className={`rd3t-tree-container ${zoomable ? 'rd3t-grabbable' : undefined}`} style={{ height: '100vh' }}>
						<svg className={rd3tSvgClassName} width='100%' height='calc(100% - 210px)'>
							<NodeWrapper
								transitionDuration={0}
								component='g'
								className={rd3tGClassName}
								transform={`translate(${translate.x},${translate.y}) scale(${scale})`}
							>
								<g className='links'>
									{links.map((linkData) => (
										<Link
											key={uuid()}
											orientation={orientation}
											pathFunc={pathFunc}
											linkData={linkData}
											onClick={this.handleOnLinkClickCb}
											onMouseOver={this.handleOnLinkMouseOverCb}
											onMouseOut={this.handleOnLinkMouseOutCb}
											transitionDuration={0}
											styles={styles.links}
											nodeSize={nodeSize}
											linkSize={250}
										/>
									))}
								</g>
								<g className='nodes'>
									{nodes.map((nodeData) => (
										<Node
											key={nodeData.id}
											nodeSvgShape={{ ...nodeSvgShape, ...nodeData.nodeSvgShape }}
											nodeLabelComponent={{
												render: (
													<OrganizationChart {...this.props} handleViewOrganisation={this.handleViewOrganisation} />
												),
											}}
											nodeSize={nodeSize}
											orientation={orientation}
											transitionDuration={transitionDuration}
											nodeData={nodeData}
											name={nodeData.name}
											attributes={nodeData.attributes}
											onClick={this.handleNodeToggle}
											onMouseOver={this.handleOnMouseOverCb}
											onMouseOut={this.handleOnMouseOutCb}
											textLayout={nodeData.textLayout || textLayout}
											circleRadius={circleRadius}
											subscriptions={subscriptions}
											allowForeignObjects={allowForeignObjects}
											styles={styles.nodes}
											handleToggle={this.handleToggle}
											handleDeleteOrganization={this.handleDeleteOrganization}
										/>
									))}
								</g>
							</NodeWrapper>
						</svg>
					</Box>
				) : (
					!loaderStatus &&
					dataFound && (
						<Box className='inner-wrapper' id='custom-scroll'>
							<Box className='welcome-content'>
								<Box align='center'>
									<Box className='no-record-message org-not-found'>
										<Typography variant='h2' component='h2'>
											{this.props.t('findMatch')}
										</Typography>
										<Typography variant='h4'>
											{this.props.t('donotBeSad')}&#x1F641;, {this.props.t('tryAgain')}
										</Typography>
										<AnimatedIcon
											className=''
											width='350'
											type='image/svg+xml'
											alt={'nofound'}
											imagePath={noRecordFound}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					)
				)}
				<Fragment>
					{(drawerProps.createType === 'addOrg' || drawerProps.createType === 'addParent') && (
						<CreateOrganization
							{...this.props}
							{...drawerProps}
							open={drawerProps.createType === 'addOrg' || drawerProps.createType === 'addParent'}
							closeSlider={this.closeDrawer}
						/>
					)}
					{drawerProps.createType === 'editOrg' && (
						<CreateOrganization
							{...this.props}
							{...drawerProps}
							open={drawerProps.createType === 'editOrg'}
							closeSlider={this.closeDrawer}
						/>
					)}

					{drawerProps.createType === 'addChild' && (
						<AddTeam
							{...this.props}
							refreshList={true}
							handleMenuClick={this.closeDrawer}
							parentDetails={{
								teamName: drawerProps.currentItem.teamName,
								teamId: drawerProps.currentItem.teamId,
								logoImagePath: drawerProps.currentItem.logoImagePath,
								teamHeadName: drawerProps.currentItem.teamHeadName,
								employeeCount: drawerProps.currentItem.employeeCount,
							}}
							isChildTeam={true}
							openAddTeamDrawer={drawerProps.createType === 'addChild'}
						/>
					)}

					{drawerProps.createType === 'editChild' && (
						<AddTeam
							{...this.props}
							isEditForm={true}
							refreshList={true}
							teamDetails={drawerProps.currentItem}
							handleMenuClick={this.closeDrawer}
							openAddTeamDrawer={drawerProps.createType === 'editChild'}
						/>
					)}

					{this.state.open && (
						<OrganizationMenu
							open={this.state.open}
							handleClose={this.handleClose}
							anchorEl={this.state.anchorEl}
							currentOrg={this.state.currentOrg}
							closeSlider={this.closeDrawer}
							openDrawer={this.openDrawer}
							openEditOrgDrawer={this.openEditOrgDrawer}
							handleViewOrganisation={this.handleViewOrganisation}
							{...this.props}
						/>
					)}
					{this.state.modalProps.open && (
						<AlertDialog
							module='organization'
							message={this.state.modalProps.message}
							handleCloseModal={this.handleCloseModal}
							modalOpen={this.state.modalProps.open}
							isCancel={this.state.modalProps.isCancel}
						/>
					)}

					{this.state.openEditOrgDrawer && (
						<EditOrganization
							{...this.props}
							handleClose={this.handleClose}
							openEditOrgDrawer={this.state.openEditOrgDrawer}
							currentOrg={this.state.currentOrg}
							handleOrgUpdate={this.handleOrgUpdate}
						/>
					)}

					{this.state.deleteModal.openDeleteModal && (
						<DialogComponent
							module='confirmation'
							modalOpen={this.state.deleteModal.openDeleteModal}
							handleCloseModal={this.handleDialogCancelClick}
							matchString={Enums.DELETE_TEXT}
							handleConfirmClick={this.handleConfirmClick}
							headingText={this.props.t('deleteOrgHeading')}
							subHeading={this.props.t('deleteOrgSubHeading')}
						/>
					)}
				</Fragment>
			</Fragment>
		);
	}
}

Tree.defaultProps = {
	nodeSvgShape: {
		shape: 'circle',
		shapeProps: {
			r: 10,
		},
	},
	nodeLabelComponent: null,
	onClick: undefined,
	onMouseOver: undefined,
	onMouseOut: undefined,
	onLinkClick: undefined,
	onLinkMouseOver: undefined,
	onLinkMouseOut: undefined,
	onUpdate: undefined,
	orientation: 'horizontal',
	translate: { x: 500, y: -370 },
	pathFunc: 'diagonal',
	transitionDuration: 10,
	depthFactor: undefined,
	collapsible: true,
	useCollapseData: false,
	initialDepth: undefined,
	zoomable: true,
	zoom: 1,
	scaleExtent: { min: 0.1, max: 1 },
	nodeSize: { x: 140, y: 140 },
	separation: { siblings: 1, nonSiblings: 2 },
	textLayout: {
		textAnchor: 'start',
		x: 10,
		y: -10,
		transform: undefined,
	},
	allowForeignObjects: false,
	shouldCollapseNeighborNodes: false,
	circleRadius: undefined, // TODO: DEPRECATE
	styles: {},
};

Tree.propTypes = {
	data: T.oneOfType([T.array, T.object]).isRequired,
	nodeSvgShape: T.shape({
		shape: T.string,
		shapeProps: T.object,
	}),
	nodeLabelComponent: T.object,
	onClick: T.func,
	onMouseOver: T.func,
	onMouseOut: T.func,
	onLinkClick: T.func,
	onLinkMouseOver: T.func,
	onLinkMouseOut: T.func,
	onUpdate: T.func,
	orientation: T.oneOf(['horizontal', 'vertical']),
	translate: T.shape({
		x: T.number,
		y: T.number,
	}),
	pathFunc: T.oneOfType([T.oneOf(['diagonal', 'elbow', 'straight', 'step']), T.func]),
	transitionDuration: T.number,
	depthFactor: T.number,
	collapsible: T.bool,
	useCollapseData: T.bool,
	initialDepth: T.number,
	zoomable: T.bool,
	zoom: T.number,
	scaleExtent: T.shape({
		min: T.number,
		max: T.number,
	}),
	nodeSize: T.shape({
		x: T.number,
		y: T.number,
	}),
	separation: T.shape({
		siblings: T.number,
		nonSiblings: T.number,
	}),
	textLayout: T.object,
	allowForeignObjects: T.bool,
	shouldCollapseNeighborNodes: T.bool,
	circleRadius: T.number,
	styles: T.shape({
		nodes: T.object,
		links: T.object,
	}),
};

// Polyfill React 16 lifecycle methods for compat with React 15.
polyfill(Tree);

export default Tree;

import {
	Avatar,
	Box,
	ClickAwayListener,
	Grow,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import * as React from 'react';
import { Fragment } from 'react';
import { OKRButton } from './OKRButton';
import { DownFilledArrow } from '../../config/svg/ArrowSvg';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { useTranslation } from 'react-i18next';

export interface TabsFilterProps {
	filters: any;
	handleFilterClick: any;
	showAll: boolean;
}

const TabsFilter: React.SFC<TabsFilterProps> = (props) => {
	const { filters, handleFilterClick, showAll } = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const [openTeamMenu, setOpenTeamMenu] = React.useState<boolean>(false);

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenTeamMenu(anchorEl ? false : true);
	};

	const handleMenuClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setOpenTeamMenu(false);
	};

	return (
		<Fragment>
			<Box className='teams-filter-btns'>
				{filters && filters.length > 1 && (
					<OKRButton
						className={`all-teams-btn ${showAll ? 'active' : ''}`}
						text='All'
						handleClick={() => handleFilterClick({}, '', 'all')}
					/>
				)}
				{filters.map((item: any, index: number) => (
					<Fragment key={index}>
						{index < 2 &&
							(item.teamName && item.teamName.length > 12 ? (
								<Tooltip title={item.teamName} arrow>
									<OKRButton
										key={index}
										className={`team-btn filterBtn ${item.isActive ? 'active' : ''}`}
										text={item.teamName.substring(0, 12) + '...'}
										handleClick={(e) => handleFilterClick(item, item.teamId)}
										style={{
											backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
											color: getRandomColor(item?.colorCode),
										}}
									/>
								</Tooltip>
							) : (
								<OKRButton
									key={index}
									className={`team-btn filterBtn ${item.isActive ? 'active' : ''}`}
									text={item.teamName}
									handleClick={(e) => handleFilterClick(item, item.teamId)}
									style={{
										backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
										color: getRandomColor(item?.colorCode),
									}}
								/>
							))}
					</Fragment>
				))}
			</Box>

			{filters && filters?.length > 2 && (
				<Fragment>
					<Box className='teams-filter-dropdown'>
						<OKRButton title={t('moreTeams')} icon={<DownFilledArrow />} handleClick={handleToggle} />
					</Box>
					<Popper
						className='popper-menu-list team-filter-list-menu direct-filter-menu'
						open={openTeamMenu}
						anchorEl={anchorEl}
						placement='bottom-start'
						transition
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handleMenuClose}>
										<MenuList id='menu-list-grow'>
											{filters.slice(2, filters.length).map((team: any, index: number) => (
												<MenuItem
													key={team?.teamId}
													onClick={(e) => {
														handleFilterClick(team, team.teamId);
														handleToggle(e);
													}}
												>
													<ListItemAvatar>
														{team?.imagePath ? (
															<Avatar className='team-avatar-small' alt={`${team.teamName}`} src={team?.imagePath} />
														) : (
															<Avatar
																className='team-avatar-small'
																style={{
																	backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
																	color: getRandomColor(team.colorCode),
																}}
															>
																{getUserName({ firstName: '', lastName: '', fullName: team.teamName })}
															</Avatar>
														)}
													</ListItemAvatar>
													<ListItemText
														primary={
															<>
																{team.teamName && team.teamName.length > 18 ? (
																	<Tooltip arrow title={team?.teamName} aria-label='Teams'>
																		<Typography>{team?.teamName.substring(0, 18)}...</Typography>
																	</Tooltip>
																) : (
																	<Typography>{team.teamName}</Typography>
																)}
															</>
														}
													/>
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Fragment>
			)}
		</Fragment>
	);
};

export default TabsFilter;

import {
	Avatar,
	Box,
	ClickAwayListener,
	Grow,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { OKRButton } from '../../Common/OKRButton';
import { DownFilledArrow } from '../../../config/svg/ArrowSvg';
import { getRandomBackgroundColor, getRandomColor, getUserName, getUserDetails } from '../../../config/utils';
import uniqBy from 'lodash/uniqBy';

const OrganisationFilters: React.FC<any> = (props) => {
	const { t, directReportResult, addOrganisationFilter, organisationFilterData } = props;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openTeamMenu, setOpenTeamMenu] = useState<boolean>(false);
	const [organisations, setOrganisations] = useState<any[]>([]);
	const userDetail: any = getUserDetails();
	useEffect(() => {
		if (directReportResult && directReportResult.length) {
			const copy = JSON.parse(JSON.stringify(directReportResult));
			// copy.shift();
			let flags: any = {};
			let allTeams: any = [];
			let filters = copy.filter((item: any) => item.employeeId !== userDetail.employeeId);
			let newFilters = filters.map((item: any) => {
				item.directReportsTeamResponse.filter((team: any) => {
					if (flags[team?.teamName]) {
						return false;
					} else {
						allTeams.push({
							organisationId: team.teamId,
							organisationName: team.teamName,
							backGroundColorCode: team.backGroundColorCode,
							colorCode: team.colorCode,
						});
						flags[team?.teamName] = true;
						return true;
					}
				});
			});
			filters = uniqBy(newFilters, 'organisationId');
			setOrganisations(allTeams);
		}
	}, [directReportResult]);

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenTeamMenu(anchorEl ? false : true);
	};

	const handleMenuClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setOpenTeamMenu(false);
	};

	const handleFilterClick = (e: any, filterItem: any, isAll = false) => {
		e.preventDefault();
		if (isAll) {
			addOrganisationFilter([]);
			return;
		}
		const copy = [...organisations];
		const index = organisations.indexOf(filterItem);
		const selected = organisations[index];
		copy.splice(index, 1);
		copy.unshift(selected);
		setOrganisations(copy);
		addOrganisationFilter([selected]);
	};

	return (
		<Fragment>
			<Box className='teams-filter-btns'>
				{organisations && organisations.length > 1 && (
					<OKRButton
						className={`all-teams-btn ${!organisationFilterData.length ? 'active' : ''} `}
						text='All'
						handleClick={(e) => handleFilterClick(e, '', true)}
					/>
				)}
				{organisations.slice(0, 2).map((item: any, index: number) => (
					<Fragment key={item.organisationName}>
						<OKRButton
							className={`team-btn filterBtn ${
								organisationFilterData.length && organisationFilterData[0].organisationId === item.organisationId
									? 'active'
									: ''
							}`}
							text={
								item.organisationName && item.organisationName.length > 12
									? item.organisationName.substring(0, 12) + '...'
									: item.organisationName
							}
							title={item.organisationName && item.organisationName.length > 12 ? item.organisationName : ''}
							handleClick={(e) => handleFilterClick(e, item)}
							style={{
								backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
								color: getRandomColor(item.colorCode),
							}}
						/>
					</Fragment>
				))}
			</Box>

			{organisations && organisations?.length > 2 && (
				<Fragment>
					<Box className='teams-filter-dropdown'>
						<OKRButton title={t('moreTeams')} icon={<DownFilledArrow />} handleClick={handleToggle} />
					</Box>
					<Popper
						className='popper-menu-list team-filter-list-menu direct-filter-menu'
						open={openTeamMenu}
						anchorEl={anchorEl}
						placement='bottom-start'
						transition
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handleMenuClose}>
										<MenuList id='menu-list-grow'>
											{organisations.slice(2, organisations.length).map((team: any, index: number) => (
												<MenuItem
													key={team.organisationName}
													onClick={(e) => {
														handleFilterClick(e, team);
														handleToggle(e);
													}}
												>
													<ListItemAvatar>
														<Avatar
															className='team-avatar-small'
															style={{
																backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
																color: getRandomColor(team.colorCode),
															}}
															src={team?.imagePath}
														>
															{getUserName({ firstName: '', lastName: '', fullName: team.organisationName })}
														</Avatar>
													</ListItemAvatar>
													<ListItemText
														primary={
															<>
																{team.organisationName && team.organisationName.length > 18 ? (
																	<Tooltip arrow title={team?.organisationName} aria-label='Teams'>
																		<Typography>{team?.organisationName.substring(0, 18)}...</Typography>
																	</Tooltip>
																) : (
																	<Typography>{team.organisationName}</Typography>
																)}
															</>
														}
													/>
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Fragment>
			)}
		</Fragment>
	);
};

export default OrganisationFilters;

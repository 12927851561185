import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { CompactIcon, RelaxedIcon, UltraCompactIcon } from '../../../config/svg/MyGoalSvg';
import { OKRButton } from '../../Common/OKRButton';
import { CancelIcon } from '../../../config/svg/Action';
import { Enums } from '../../../config/enums';
import TeamOkrFilters from '../AlignmentMapFilter/teamOkrFilters';
import { setLocalStorageItem } from '../../../services/StorageService';
import { useTranslation } from 'react-i18next';

export const AlignmentMapTeamsSecondaryTabs: React.FC<any> = (props) => {
	const { setTabFilter, tabFilter, teamResult } = props;
	const [showNudgeBar, setShowNudgeBar] = useState<any>(false);
	const { t } = useTranslation();

	useEffect(() => {
		setShowNudgeBar(true);
	}, []);

	const getAttentionTeamsCount = () => {
		const filterData =
			teamResult && teamResult.length > 0
				? teamResult.filter((item: any) => item.progress === Enums.ONE || item.progress === Enums.TWO)
				: 0;
		if (filterData && filterData.length === 0) {
			setShowNudgeBar(false);
		}
		return (filterData && filterData.length) || 0;
	};

	return (
		<Box className='tabs-secondary secondary-tab-team' id='secondary-tab'>
			<Box className='secondary-tab-team-left'>
				<List className='secondary-filters'>
					<ListItem className={tabFilter === 'relaxed' ? 'active' : ''} key={'relaxed'}>
						<OKRButton
							icon={<RelaxedIcon />}
							id={'team-okr-relaxed'}
							title={t('relaxed')}
							handleClick={(e) => {
								setTabFilter('relaxed');
								setLocalStorageItem('activeTab', 'teamsOkr_relaxed');
								setShowNudgeBar(true);
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'compact' ? 'active' : ''} key={'compact'}>
						<OKRButton
							icon={<CompactIcon />}
							id={'team-okr-compact'}
							title={t('compact')}
							handleClick={() => {
								setTabFilter('compact');
								setLocalStorageItem('activeTab', 'teamsOkr_compact');
								setShowNudgeBar(true);
							}}
						></OKRButton>
					</ListItem>
					<ListItem className={tabFilter === 'ultracompact' ? 'active' : ''} key={'ultracompact'}>
						<OKRButton
							icon={<UltraCompactIcon />}
							id={'team-okr-ultracompact'}
							title={t('ultraCompact')}
							handleClick={() => {
								setTabFilter('ultracompact');
								setLocalStorageItem('activeTab', 'teamsOkr_ultracompact');
								setShowNudgeBar(true);
							}}
						></OKRButton>
					</ListItem>
					<ListItem className='active-color'></ListItem>
				</List>
				<Box className='teams-filter'>
					<TeamOkrFilters {...props} />
				</Box>
			</Box>
		</Box>
	);
};

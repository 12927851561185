import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Box, Chip, Collapse, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import {
	DragIndicator,
	InfoIcon,
	AddIconSvg,
	ContributorIcon,
	AlignedAsContributor,
	teamTooltipIcon,
	parentTeamIcon,
} from '../../../config/svg/CommonSvg';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { OKRButton } from '../../Common/OKRButton';
import { OkrFieldActions } from '../OkrFieldAction';
import { Conversation } from '../Cfr/Conversations/Conversation';
import {
	createUUID,
	getMonthDate,
	getUserDetails,
	getRandomBackgroundColor,
	getRandomColor,
	formatDate,
	getUserName,
	isCollaboratorOkr,
	getDueDays,
	getSelectedCycle,
	getMonthDateYear,
	getDueDaysText,
} from '../../../config/utils';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { OkrTextEdit } from './OkrTextEdit';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { Enums } from '../../../config/enums';
import { SaveAsDraft } from '../../../config/svg/MyGoalSvg';
import AlertDialog from '../../Common/Dialog';
import { ListTooltip } from '../../OkrAction/ListTooltip';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import DeltaKrPopper from '../../Common/DeltaKrPopper';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { VirtualAlignment } from '../../Common/VirtualAlignment';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { checkPermission } from '../../../config/rolePermission';
import { createOkrs } from '../../Admin/AdminConstant';
import { Cfr } from '../Cfr';
import { keyDetailObject } from '../../Common/OkrUtil';
import OkrNature from '../../Common/OkrNature';
import AddNewKr from '../../Common/AddNewKr';
import { KrConfidencePopper } from '../../Common/KrConfidencePopper';
import { WeeklyTrendDetails } from '../../Common/WeeklyTrendDetails';

export const ObjectiveList: React.FC<any> = (props) => {
	const {
		provided,
		showActionButton,
		getItemStyle,
		snapshot,
		okr,
		t,
		updateTextValue,
		updateName,
		handleClickInputAway,
		handleListAction,
		showGoalAndKrAttribute,
		showEditLoader,
		handleAddUserClick,
		maxObjectiveId,
		handleDeleteOkr,
		setOpenDrawer,
		isLocked,
		updateProgress,
		showArchiveList,
		goalDetail,
		setGoalDetail,
		showApiMsgs,
		currentUser,
		handleBecomeAContributorClick,
		okrMasterData,
		highlightGoalId,
		highlightTeamsOkrId,
		listOkrResult,
		virtualAlignment,
		currentCycleEndDate,
		highlightKR,
		myGoalList,
		setMyGoalList,
		currentCycleId,
		saveAndEditObjectiveAndKr,
		myArchiveGoalList,
		setArchiveMyGoalList,
		isPastCycle,
		updateConfidence,
	} = props;

	const [newKeyList, setNewKeyList] = useState<any>([]);
	const [isAddClicked, setIsAddClicked] = useState<boolean>(false);
	const [isTaskModified, setIsTaskModified] = useState<boolean>(false);
	const [krModalProps, setKrModalProps] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		id: 0,
	});
	const [showDialog, setShowDialog] = React.useState<boolean>(false);
	const userDetail: any = getUserDetails();
	const [krFocus, setKrFocus] = useState(false);
	const handleKRFocus = () => setKrFocus(true);
	const handleKRBlur = () => setKrFocus(false);
	const { enqueueSnackbar } = useSnackbar();
	const [modalPropsOKR, setModalPropsOKR] = useState<any>({ open: false, type: '', message: '' });
	const [modalPropsOKRCalendar, setModalPropsOKRCalendar] = useState<any>({ open: false, type: '', message: '' });
	const loginUserAvatar = getUserDetails();
	const [finalSubmitted, setFinalSubmitted] = useState<boolean>(false);
	const [openCFR, setOpenCFR] = useState<boolean>(false);
	const [currentTab, setCurrentTab] = useState<string>('');
	const [isCfrUpdated, setIsCfrUpdated] = useState<boolean>(false);
	const [modalCfrData, setModalCfrData] = useState<any>({ type: 1, okr: {}, kr: {} });
	const [openWeeklyTrendDrawer, setOpenWeeklyTrendDrawer] = useState<any>({ open: false });

	useEffect(() => {
		props.setCfrOpened && props.setCfrOpened(openCFR);
		props.isAnyDrawerOpened({
			...props.appDrawerInfo,
			isDrawerOpened: openCFR,
		});
	}, [openCFR]);

	const handleCfrOpen = (event: any, type: number, kr: any, okr: any, tabToOpen?: string) => {
		if (Boolean(okr) || Boolean(kr)) {
			setOpenCFR(true);
			if (tabToOpen) {
				setCurrentTab(tabToOpen);
			}
			setModalCfrData({
				type: type,
				okr: okr,
				kr: kr,
			});
		}
	};

	const handleCfrDrawerClose = (event: any) => {
		if (isTaskModified) {
			setShowDialog(true);
		} else {
			event.preventDefault();
			setOpenCFR(false);
			if (modalCfrData.type === 1 && modalCfrData?.okr?.isUnreadConversation === true) {
				modalCfrData.okr.isUnreadConversation = false;
			} else if (modalCfrData.type === 2 && modalCfrData?.kr?.isUnreadConversation === true) {
				modalCfrData.kr.isUnreadConversation = false;
			}
			props.resetConversation();
			props.resetNotes();
		}
	};

	useEffect(() => {
		if (!openCFR && isCfrUpdated) {
			props.fetchOkr();
			setIsCfrUpdated(false);
		}
	}, [openCFR]);

	useEffect(() => {
		let isConversationRedirection = getLocalStorageItem('isConversationRedirection');
		if (currentUser) {
			if (
				props.listOkrSuccess === 'success' &&
				listOkrResult.myGoalOkrResponses?.length &&
				!isConversationRedirection &&
				listOkrResult.myGoalOkrResponses[0].employeeId !== userDetail?.employeeId
			) {
				if (getLocalStorageItem('conversationHighlight')) {
					setLocalStorageItem('isConversationRedirection', 'true');
					getConversationById();
				}
			}
		} else if (
			props.listOkrSuccess === 'success' &&
			listOkrResult.myGoalOkrResponses?.length &&
			!isConversationRedirection
		) {
			if (getLocalStorageItem('conversationHighlight')) {
				setLocalStorageItem('isConversationRedirection', 'true');
				getConversationById();
			}
		}

		let isNoteRedirection = getLocalStorageItem('isNoteRedirection');
		if (
			props.listOkrSuccess === 'success' &&
			listOkrResult.myGoalOkrResponses?.length &&
			listOkrResult.myGoalOkrResponses[0].employeeId !== userDetail?.employeeId &&
			!isNoteRedirection
		) {
			if (getLocalStorageItem('notesHighlight')) {
				setLocalStorageItem('isNoteRedirection', 'true');
				getNotesByNoteId();
			}
		}
	}, [props.listOkrSuccess, listOkrResult]);

	const getNotesByNoteId = async () => {
		if (!openCFR) {
			let hightLightNote: any = getLocalStorageItem('notesHighlight');
			if (Boolean(hightLightNote)) {
				hightLightNote = JSON.parse(hightLightNote);
				if (hightLightNote && hightLightNote.noteId && listOkrResult.myGoalOkrResponses?.length && props.getNoteById) {
					// props.setShowOverlay(true);
					props.showLoader(true);
					const elem = document.createElement('div');
					elem.className = 'overlayForRedirection';
					document.body.appendChild(elem);
					const getNoteResponse = await props.getNoteById(`noteId=${hightLightNote.noteId}`);
					if (getNoteResponse && getNoteResponse.data && getNoteResponse.data.status === 200) {
						props.showLoader(false);
						if (getNoteResponse.data.entity) {
							if (hightLightNote.goalType === 2) {
								let selectedKR = {};
								// eslint-disable-next-line array-callback-return
								let newData = listOkrResult.myGoalOkrResponses.map((obj: any) => {
									// eslint-disable-next-line array-callback-return
									obj.myGoalsDetails.map((goals: any) => {
										if (goals.goalKeyId === parseInt(hightLightNote.goalId)) {
											selectedKR = goals;
										}
									});
								});
								if (Boolean(selectedKR) || newData.length) {
									handleCfrOpen('', hightLightNote.goalType, selectedKR, selectedKR, 'notes');
									removeLocalStorageItem('isNoteRedirection');
								} else {
									removeLocalStorageItem('isNoteRedirection');
									removeLocalStorageItem('notesHighlight');
								}
							} else {
								let findGoal = listOkrResult.myGoalOkrResponses.filter(
									(item: any) => item.goalObjectiveId === parseInt(hightLightNote.goalId)
								);
								if (findGoal.length) {
									handleCfrOpen('', hightLightNote.goalType, findGoal[0], findGoal[0], 'notes');
									removeLocalStorageItem('isNoteRedirection');
								} else {
									removeLocalStorageItem('notesHighlight');
									removeLocalStorageItem('isNoteRedirection');
								}
							}
						} else {
							showApiMsgs(t('noteNotFound'), 'error');
							removeLocalStorageItem('notesHighlight');
							removeLocalStorageItem('isNoteRedirection');
						}
						// props.setShowOverlay(false);
						props.showLoader(false);
						document.querySelectorAll('.overlayForRedirection').forEach((el) => el.remove());
					} else {
						removeLocalStorageItem('notesHighlight');
						removeLocalStorageItem('isNoteRedirection');
						document.querySelectorAll('.overlayForRedirection').forEach((el) => el.remove());
						props.showLoader(false);
					}
				} else {
					removeLocalStorageItem('isNoteRedirection');
					removeLocalStorageItem('notesHighlight');
				}
			}
		}
	};

	const getConversationById = async () => {
		if (!openCFR) {
			let hightLightConversation: any = getLocalStorageItem('conversationHighlight');

			if (Boolean(hightLightConversation)) {
				hightLightConversation = JSON.parse(hightLightConversation);
				if (
					hightLightConversation &&
					hightLightConversation.conversationId &&
					listOkrResult.myGoalOkrResponses?.length
				) {
					const elem = document.createElement('div');
					elem.className = 'overlayForRedirection';
					document.body.appendChild(elem);
					props.showLoader(true);
					const getConversationResponse = await props.getConversationById(
						`conversationId=${hightLightConversation.conversationId}`
					);
					if (getConversationResponse && getConversationResponse.data && getConversationResponse.data.status === 200) {
						if (getConversationResponse.data.entity) {
							if (hightLightConversation.goalType === 2) {
								let selectedKR: any = {};
								// eslint-disable-next-line array-callback-return
								listOkrResult.myGoalOkrResponses.map((obj: any) => {
									// eslint-disable-next-line array-callback-return
									obj.myGoalsDetails.map((goals: any) => {
										if (
											goals.goalKeyId === parseInt(hightLightConversation.goalId) ||
											goals.source === parseInt(hightLightConversation.goalId)
										) {
											selectedKR = goals;
										}
									});
								});
								if (selectedKR.goalKeyId || selectedKR.source) {
									handleCfrOpen('', hightLightConversation.goalType, selectedKR, selectedKR, 'conversation');
									removeLocalStorageItem('isConversationRedirection');
								} else {
									removeLocalStorageItem('isConversationRedirection');
									removeLocalStorageItem('conversationHighlight');
								}
							} else {
								let findObjective = listOkrResult.myGoalOkrResponses.filter(
									(item: any) =>
										item.goalObjectiveId === parseInt(hightLightConversation.goalId) ||
										item.source === parseInt(hightLightConversation.goalId)
								);
								if (findObjective.length) {
									handleCfrOpen(
										'',
										hightLightConversation.goalType,
										findObjective[0],
										findObjective[0],
										'conversation'
									);
									removeLocalStorageItem('isConversationRedirection');
								} else {
									removeLocalStorageItem('isConversationRedirection');
									removeLocalStorageItem('conversationHighlight');
								}
							}
						} else {
							showApiMsgs(t('noteNotFound'), 'error');
							removeLocalStorageItem('conversationHighlight');
							removeLocalStorageItem('isConversationRedirection');
						}
						props.showLoader(false);
						document.querySelectorAll('.overlayForRedirection').forEach((el) => el.remove());
					} else {
						removeLocalStorageItem('isConversationRedirection');
						removeLocalStorageItem('conversationHighlight');
						props.showLoader(false);
						document.querySelectorAll('.overlayForRedirection').forEach((el) => el.remove());
					}
				}
			}
		}
	};

	useEffect(() => {
		if (props.teamsOkrProgressStatus === 'success') {
			setTimeout(() => {
				const element = document.getElementById(`myGoal${highlightTeamsOkrId}`);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
			}, 1000);
		}
	}, [highlightTeamsOkrId, props.teamsOkrProgressStatus]);

	useEffect(() => {
		if (maxObjectiveId) {
			const element = document.getElementsByClassName(`newly-highlighted`)[0];
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
		}
	}, [maxObjectiveId]);

	useEffect(() => {
		const { saved } = goalDetail;
		if (goalDetail && goalDetail.actionType === 'addNewKr' && saved) {
			const detail = goalDetail;
			updateDataBasedOnNewChanges(detail);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalDetail]);

	const updateDataBasedOnNewChanges = useCallback(
		(detail: any) => {
			setNewKeyList((prevList: any) => {
				const list = [...prevList];
				const index = list.findIndex((ele: any) => ele.id === detail?.kr?.id);
				list[index] = { ...goalDetail.kr };
				return list;
			});
			setGoalDetail({});
		},
		[goalDetail.kr, setGoalDetail]
	);

	const addNewKeyResult = (e: any, okr: any) => {
		setFinalSubmitted(false);
		handleUserClickOnCard(e);
		setIsAddClicked(true);
		setNewKeyList([
			...newKeyList,
			{
				...keyDetailObject,
				id: createUUID(),
				dueDate: okr.dueDate,
				startDate: okr.startDate,
				isNewKey: false,
				owner: okr.isCoach === true ? okr.owner : userDetail?.employeeId,
			},
		]);
	};

	const handleNewKeyResultChange = useCallback((value: any, key: any, item: any) => {
		if (key.trim()) {
			setNewKeyList((prevList: any) => {
				const list = [...prevList];
				const index = list.findIndex((ele: any) => ele.id === item.id);
				list[index][key] = value;
				return list;
			});
		}
	}, []);

	const deleteNewKeyResult = (okr: any, item: any) => {
		if (item.keyDescription && item.keyDescription.trim() !== '') {
			setKrModalProps({
				open: true,
				type: '',
				message: t('deleteKeyResultConfirmation'),
				module: '',
				id: item.id,
			});
		} else {
			filterKeyList(item.id);
		}
	};

	const handleAcceptDeclineClick = (open: Boolean, okr: any, kr: any, type: String, KRType: String) => {
		setOpenDrawer({ open, okr, kr, type, KRType });
	};

	const filterKeyList = (id: any) => {
		let newList = newKeyList.filter((ele: any) => ele.id !== id);
		if (newList.length === 0) {
			setIsAddClicked(false);
		}
		setNewKeyList(newList);
	};

	const handleCloseKrModal = (event: any, type: Number) => {
		if (type === 1) {
			filterKeyList(krModalProps.id);
		}
		setKrModalProps({
			open: false,
			type: '',
			message: '',
			module: '',
		});
	};

	const hasEmptyKr = () => {
		let isError: boolean = false;
		if (okr.myGoalsDetails && okr.myGoalsDetails.length > 0) {
			isError = false;
		} else if (newKeyList.find((ele: any) => ele.keyDescription === '' || ele.keyDescription?.trim() === '')) {
			isError = true;
		} else if (okr.myGoalsDetails && okr.myGoalsDetails.length === 0 && newKeyList.length === 0) {
			isError = true;
		}
		return isError;
	};

	const [isDataSaving, setIsDataSaving] = useState<boolean>(false);

	const saveChanges = async (event: any, okr: any, saveType: number) => {
		const data = okr;
		const updatedOkr =
			data && data?.contributors && data?.myGoalsDetails
				? {
						...data,
						contributors: data.contributors.filter(
							(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
						),
						myGoalsDetails:
							data?.myGoalsDetails.length > 0
								? data?.myGoalsDetails.map((item: any) => {
										return {
											...item,
											contributors: item.contributors.filter(
												(items: any) => !items.isSource && items.employeeId !== userDetail.employeeId
											),
										};
								  })
								: [],
				  }
				: data;
		updatedOkr.objectiveCycleId = props.userSelectedCycle.organisationCycleId;
		if (saveType === 2) {
			setIsDataSaving(true);
			if (updatedOkr.myGoalsDetails && updatedOkr.myGoalsDetails.length > 0) {
				const response = await saveAndEditObjectiveAndKr(updatedOkr, saveType, false);
				if (response === 'success') {
					setNewKeyList([]);
					setIsAddClicked(false);
					if (props.teamsOkr) {
						if (props.getMyTeamOKR) {
							props.getMyTeamOKR(props.selectedTeam);
						}
					}
					setIsDataSaving(false);
				}
			} else {
				showApiMsgs(t('atLeast1KrMsg'), 'error');
			}
		} else if (saveType === 3) {
			setIsDataSaving(true);
			const response = await saveAndEditObjectiveAndKr(updatedOkr, saveType, false);
			if (response === 'success') {
				setNewKeyList([]);
				setIsAddClicked(false);
				setIsDataSaving(false);
			}
		} else if (saveType === 1) {
			if (okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE) {
				const newList = [...myArchiveGoalList];
				const goalListIndex = myArchiveGoalList.findIndex((item: any) => item.goalObjectiveId === okr.goalObjectiveId);
				newList[goalListIndex] = { ...newList[goalListIndex], expand: !newList[goalListIndex].expand };
				setArchiveMyGoalList(newList);
			} else {
				const newList = [...myGoalList];
				const goalListIndex = myGoalList.findIndex((item: any) => item.goalObjectiveId === okr.goalObjectiveId);
				newList[goalListIndex] = { ...newList[goalListIndex], expand: !newList[goalListIndex].expand };
				setMyGoalList(newList);
			}
			setNewKeyList([]);
			setIsAddClicked(false);
		}
	};

	const checkNewKrChanges = (event: any, okr: any, saveType: number) => {
		if (saveType !== 3) {
			setFinalSubmitted(true);
		}
		if (newKeyList && newKeyList.length > 0) {
			let isError: boolean = false;
			if (okr.myGoalsDetails && okr.myGoalsDetails.length > 0) {
				isError = false;
			} else if (newKeyList.find((ele: any) => ele.keyDescription === '' || ele.keyDescription?.trim() === '')) {
				isError = true;
			}
			let isInvalid = false;
			if (!checkMandatoryFieldforObjective(okr) && saveType !== 3) {
				isInvalid = true;
				setModalPropsOKR({
					open: true,
					type: 'validateForm',
					message: t('confirmMandatoryObjectiveField'),
					module: 'validateForm',
					data: {},
				});
			} else if (
				saveType !== 3 &&
				(newKeyList.find(
					(ele: any) => !checkMandatoryFieldforKR(ele) && ele.keyDescription !== '' && ele.keyDescription?.trim() !== ''
				) ||
					okr.myGoalsDetails.find(
						(ele: any) =>
							!checkMandatoryFieldforKR(ele) && ele.keyDescription !== '' && ele.keyDescription?.trim() !== ''
					))
			) {
				isInvalid = true;
				setModalPropsOKR({
					open: true,
					type: 'validateForm',
					message: t('confirmMandatoryOkrField'),
					module: 'validateForm',
					data: {},
				});
			}

			if (isError === true) {
				showApiMsgs(t('Please enter Key Result description'), 'error');
			} else if (!isInvalid) {
				let newKeyListFiltered = newKeyList.filter((keyL: any) => keyL.keyDescription?.trim() !== '');
				const newList =
					newKeyListFiltered && newKeyListFiltered.length > 0
						? newKeyListFiltered.map((element: any, index: number) => {
								element.contributors.map((ele: any) => {
									ele.krStatusId = Enums.KR_STATUS_PENDING;
									return ele;
								});
								return element;
						  })
						: [];
				const updatedDetails = { ...okr, myGoalsDetails: [...okr.myGoalsDetails, ...newList] };
				if (
					updatedDetails.linkedObjective &&
					updatedDetails.linkedObjective.objectiveId &&
					!updatedDetails.linkedObjectiveId
				) {
					updatedDetails.linkedObjectiveId = updatedDetails.linkedObjective.objectiveId;
				}
				saveChanges(event, updatedDetails, saveType);
			} else if (saveType !== 3) {
				setModalPropsOKR({
					open: true,
					type: 'validateForm',
					message: t('confirmMandatoryOkrField'),
					module: 'validateForm',
					data: {},
				});
			}
		} else {
			if (okr.goalStatusId === Enums.GOAL_STATUS_ARCHIVE) {
				okr.goalStatusId = Enums.GOAL_STATUS_PUBLIC;
			}
			if (okr.linkedObjective && okr.linkedObjective.objectiveId && !okr.linkedObjectiveId) {
				okr.linkedObjectiveId = okr.linkedObjective.objectiveId;
			}
			let isInvalid = false;
			if (saveType !== 3 && !checkMandatoryFieldforObjective(okr)) {
				isInvalid = true;
				setModalPropsOKR({
					open: true,
					type: 'validateForm',
					message: t('confirmMandatoryObjectiveField'),
					module: 'validateForm',
					data: {},
				});
			} else if (
				saveType !== 3 &&
				okr.myGoalsDetails.find(
					(ele: any) => !checkMandatoryFieldforKR(ele) && ele.keyDescription !== '' && ele.keyDescription?.trim() !== ''
				)
			) {
				isInvalid = true;
				setModalPropsOKR({
					open: true,
					type: 'validateForm',
					message: t('confirmMandatoryOkrField'),
					module: 'validateForm',
					data: {},
				});
			}
			if (!isInvalid) {
				saveChanges(event, okr, saveType);
			}
		}
	};

	const handleUserClickOnCard = (e: any, okr: any = null) => {
		if ((okr && okr.edit) || !okr) {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	const expandKeyResult = (e: any, okr: any, expand: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (newKeyList && newKeyList.length > 0) {
			let newKeyListFiltered = newKeyList.filter((keyL: any) => keyL.keyDescription?.trim() !== '');
			setNewKeyList(newKeyListFiltered);
		}
		showActionButton(e, okr, expand);
	};

	const expendKeyResult = (goal: any) => {
		let hightLghtKr: any = getLocalStorageItem('highlightGoalKey') || 0;
		if (
			getLocalStorageItem('goalType') &&
			getLocalStorageItem('goalType') === '1' &&
			goal.myGoalsDetails.length > 0 &&
			goal.myGoalsDetails.find((item: any) => item.goalKeyId === parseInt(hightLghtKr))
		) {
			expandKeyResult(null, goal, 'expand');
			return true;
		} else if (props.lastProgressUpdate && props.lastProgressUpdate.goalObjectiveId === goal.goalObjectiveId) {
			// this is handled form showActionButton function in parent file
			//expandKeyResult(null, goal, 'expand');
			return true;
		} else if (localStorage.getItem('lastSavedGoal') !== null) {
			let currentOpened = JSON.parse(localStorage.getItem('lastSavedGoal') || '{}');
			if (currentOpened.goalObjectiveId === goal.goalObjectiveId) {
				expandKeyResult(null, goal, 'expand');
				setTimeout(() => {
					removeLocalStorageItem('lastSavedGoal');
				}, 5000);
				return true;
			}
		} else if (
			//checking if request 1:1
			parseInt(hightLghtKr) === okr.goalObjectiveId ||
			(goal.myGoalsDetails.find((item: any) => item.goalKeyId === parseInt(hightLghtKr)) &&
				props.listOkrSuccess === 'success')
		) {
			expandKeyResult(null, goal, 'expand');
			return true;
		} else {
			return false;
		}
	};

	const hasPendingKr = (objective: any, kr: any, addbtn = false) => {
		let isPending = false;
		let hasApprovedKr = false;
		if (objective.myGoalsDetails && objective.myGoalsDetails.length > 0) {
			objective.myGoalsDetails.forEach((objectiveKr: any) => {
				if (objectiveKr.contributors && objectiveKr.contributors.length > 0) {
					objectiveKr.contributors.forEach((contributor: any) => {
						if (
							addbtn === true &&
							contributor.employeeId === userDetail.employeeId &&
							contributor.krStatusId === Enums.KR_STATUS_ACCEPTED
						) {
							isPending = false;
							hasApprovedKr = true;
						} else if (
							contributor.employeeId === userDetail.employeeId &&
							contributor.krStatusId === Enums.KR_STATUS_PENDING &&
							hasApprovedKr === false &&
							(kr === null || (kr !== null && kr.goalKeyId === objectiveKr.goalKeyId))
						) {
							isPending = true;
						}
					});
				}
			});
		}
		return !isPending;
	};

	const checkCanAddKr = (objective: any, kr: any, addbtn = false) => {
		if (objective.teamId > 0) {
			if (
				(objective.importedId === 0 &&
					(objective.owner === userDetail.employeeId || objective.createdBy === userDetail.employeeId)) ||
				(addbtn === false && !props.teamsOkr && isKrContributor(objective, kr)) ||
				(addbtn === true && objective.owner === userDetail.employeeId)
			) {
				return hasPendingKr(objective, kr, addbtn);
			}
		} else if (
			objective.goalTypeId === 2 &&
			(objective.owner === userDetail.employeeId || objective.createdBy === userDetail.employeeId)
		) {
			return hasPendingKr(objective, kr, addbtn);
		} else if (objective.goalTypeId === 2) {
			return hasPendingKr(objective, kr, addbtn);
		} else if (objective.goalTypeId === 0) {
			return true;
		}
		return false;
	};

	const [open, setOpen] = useState<boolean>(false);

	const handleViewAllVirtualAlignments = () => {
		setOpen(true);
	};

	const handleDrawerClose = (event: any) => {
		event.preventDefault();
		setOpen(false);
	};

	const [virtualAlignmentList, setVirtualAlignmentList] = useState<any>([]);
	const [virtualAlignmentListPopUp, setVirtualAlignmentListPopUp] = useState<any>([]);

	const getVirtuals = async () => {
		const data = `goalObjectiveId=${okr.goalObjectiveId}`;
		const resp = await virtualAlignment(data);

		if (resp && resp.data.status === Enums.STATUS_SUCCESS) {
			let non_duplicated_data = _.uniqBy(resp?.data?.entity?.virtualDetails, 'employeeId');
			setVirtualAlignmentListPopUp(non_duplicated_data);
			setVirtualAlignmentList(resp.data?.entity);
		}
	};
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortingColumn, setSortingColumn] = useState('');

	const sortVirtualAlignmentList = (e: any, column: string) => {
		let sortClass = 'desc';
		if (column === sortingColumn) {
			sortClass = sortOrder === 'asc' ? 'desc' : 'asc';
		}
		let alignList = virtualAlignmentList.virtualDetails;
		// @ts-ignore: Unreachable code error
		let list = _.orderBy(alignList, column, sortClass);
		setVirtualAlignmentList({
			...virtualAlignmentList,
			virtualDetails: list,
		});
		setSortOrder(sortClass);
		setSortingColumn(column);
	};

	const isKrContributor = (objective: any, kr: any) => {
		let isContributor = false;
		if (objective.myGoalsDetails && objective.myGoalsDetails.length > 0) {
			objective.myGoalsDetails.forEach((objectiveKr: any) => {
				if (objectiveKr.contributors && objectiveKr.contributors.length > 0) {
					objectiveKr.contributors.forEach((contributor: any) => {
						if (
							contributor.employeeId === userDetail.employeeId &&
							(kr === null || (kr !== null && kr.goalKeyId === objectiveKr.goalKeyId))
						) {
							isContributor = true;
						}
					});
				}
			});
		}
		return isContributor;
	};

	const handleCloseModal = async (event: any, type: Number) => {
		if (type === 1) {
			if (modalPropsOKR.type === 'updateDateForOKR') {
				setModalPropsOKR({ open: false, type: '', message: '', module: '', data: {} });
				updateDashboardDueDate(modalPropsOKR.data.date, modalPropsOKR.data.okr, 1, modalPropsOKR.data.startDate);
			} else if (modalPropsOKR.type === 'updateDateForKR') {
				setModalPropsOKR({ open: false, type: '', message: '', module: '', data: {} });
				updateDashboardDueDate(modalPropsOKR.data.date, modalPropsOKR.data.okr, 2, modalPropsOKR.data.startDate);
			} else if (modalPropsOKR.type === 'updateDateForKRNew') {
				handleNewKeyResultChange(modalPropsOKR.data.date, 'dueDate', modalPropsOKR.data.okr);
				handleNewKeyResultChange(modalPropsOKR.data.startDate, 'startDate', modalPropsOKR.data.okr);
				handleNewKeyResultChange(modalPropsOKR.dateRange, 'dateRange', modalPropsOKR.data.okr);
			}
		} else {
			getCurrentCycle();
		}
		setModalPropsOKR({ open: false, type: '', message: '', module: '' });
	};

	const handleCycleChangeCloseModal = async (event: any, type: Number) => {
		if (type === 1) {
			if (modalPropsOKRCalendar.type === 'changeCycle') {
				setModalPropsOKRCalendar({ open: false, type: '', message: '', module: '', data: {} });
				updateDashboardDueDate(
					modalPropsOKRCalendar.data.date,
					modalPropsOKRCalendar.data.okr,
					1,
					modalPropsOKRCalendar.data.startDate
				);
			}
		} else {
			getCurrentCycle();
		}
		setModalPropsOKRCalendar({ open: false, type: '', message: '', module: '' });
	};

	const redirectToCycle = (cycleId: number) => {
		if (props.teamCycleDetails?.length) {
			let cycleObj = getSelectedCycle(cycleId, props.teamCycleDetails);
			if (cycleObj?.quarter) {
				props.updateCurrentCycleId(cycleObj?.quarter);
			}
		}
	};

	const updateDashboardDueDate = async (dueDate: any, okr: any, type: Number, startDate?: any) => {
		const data = {
			goalType: type,
			goalId: okr.goalObjectiveId || okr.goalKeyId || 0,
			endDate: formatDate(dueDate),
			startDate: startDate,
			objectiveCycleId: selectedCycleDetails?.organisationCycleId,
			year: selectedCycleDetails.year,
		};
		const response = await props.updateObjetciveDueDate(data);
		if (response && response.data && response.data.status === 200) {
			if (selectedCycleDetails.organisationCycleId !== cycleId) {
				redirectToCycle(selectedCycleDetails.organisationCycleId);
			} else {
				props.fetchOkr();
			}
		} else if (response && response.data && response.data.status === 400) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const checkMandatoryFieldforKR = (krData: any) => {
		if (krData?.metricId === Enums.ONE || krData?.metricId === Enums.TWO || krData?.metricId === Enums.THREE) {
			krData.startValue = !krData.startValue ? 0 : krData.startValue;
			krData.targetValue = !krData.targetValue ? 0 : krData.targetValue;
			if (krData.startValue === krData.targetValue) {
				return true;
			}
		}
		return Boolean(krData) && krData.keyDescription.length >= Enums.DEFAULT_TYPE_CHAR && krData.metricId;
	};

	const checkMandatoryFieldforObjective = (okr: any) => {
		return (
			Boolean(okr) &&
			okr?.objectiveName &&
			okr?.objectiveName.length >= Enums.DEFAULT_TYPE_CHAR &&
			okr?.goalNatureId &&
			okr?.goalTypeId
		);
	};

	const checkIfOkrList = () => {
		return !showArchiveList && !isLocked && !currentUser;
	};

	const checkTeamIdAndCreatedBy = (okr: any) => {
		return (
			okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT &&
			okr.teamId !== Enums.ZERO &&
			okr?.createdBy === userDetail?.employeeId
		);
	};

	const checkTeamIdAndOwner = (okr: any) => {
		return okr.teamId !== Enums.ZERO && okr?.owner === userDetail?.employeeId;
	};

	const findKrStatusPending = (okr: any) => {
		return (
			okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ||
			(okr.myGoalsDetails && okr.myGoalsDetails.find((item: any) => item.krStatusId !== Enums.KR_STATUS_PENDING))
		);
	};

	const checkOkrOwner = (okr: any) => {
		return okr.teamId === Enums.ZERO && okr?.employeeId === userDetail?.employeeId;
	};

	const cycleDetail = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const cycleId = getLocalStorageItem('cycleId') && parseInt(getLocalStorageItem('cycleId') || '0');
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(props.cycleIdDetails);
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [allCycleDetails, setAllCycleDetails] = useState<any>(cycleDetail);
	const [isCurrectCycle, setIsCurrectCycle] = useState<any>(null);

	useEffect(() => {
		getCurrentCycle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allCycleDetails, okr]);

	const getCurrentCycle = () => {
		if (allCycleDetails && allCycleDetails.cycleDetails && allCycleDetails.cycleDetails.length) {
			allCycleDetails.cycleDetails.forEach((cycleDetails: any) => {
				const currentQuarterDetails = cycleDetails.quarterDetails.find(
					(item: any) => item.organisationCycleId === cycleId
				);
				if (currentQuarterDetails) {
					setSelectedCycleDetails({ ...currentQuarterDetails, year: cycleDetails?.year });
					setIsCurrectCycle({ ...currentQuarterDetails, year: cycleDetails?.year });
				}
			});
		}
	};

	const handleWeightClick = (e: any, okr: any, kr: any, type: number) => {
		if (!currentUser) {
			showGoalAndKrAttribute(e, okr, kr, type);
		}
	};

	const handleOpenWeeklyTrend = (event: any, kr: any, type: number, confidenceValue: number, okr: any) => {
		setOpenWeeklyTrendDrawer({ open: true, data: { okr: kr, type, confidenceValue, rootDetail: okr } });
	};

	return (
		<Fragment>
			<Box
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				className={
					(getLocalStorageItem('goalType') && okr.goalObjectiveId === parseInt(highlightGoalId)) ||
					okr.goalObjectiveId === highlightTeamsOkrId
						? 'my-obj-listing-content obj-listing-content newly-highlighted'
						: maxObjectiveId && okr.goalObjectiveId > maxObjectiveId
						? 'newly-highlighted my-obj-listing-content obj-listing-content'
						: 'my-obj-listing-content obj-listing-content'
				}
				key={`okr${okr.goalObjectiveId}`}
				id={`my-okr-${okr.goalObjectiveId}`}
				name={'ObjectiveTitle'}
				style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
			>
				<Box className='my-obj-listing-inner' onClick={(e) => expandKeyResult(e, okr, 'expand')}>
					<Box className='okr-head'>
						<DragIndicator />
						<List component='ul'>
							<ListItem className='okr-col1 okr-drag-wrap'>
								<Box className='okr-col1-left'>
									<Box className='okr-status-info'>
										{!isLocked && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
											<Chip className='draft-chip' label={t('draft')} />
										) : // </Tooltip>
										!isLocked && okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE && okr?.isLastStatusDraft ? (
											<Chip className='draft-chip' label={t('draft')} />
										) : (
											// </Tooltip>
											<></>
										)}
										<OkrNature {...props} okr={okr} />
									</Box>
									<Box className='okr-objective' onClick={(e) => handleUserClickOnCard(e, okr)}>
										<Box
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											{!okr.edit ? (
												<Box className='okr-after-edit'>
													<ListTooltip
														{...props}
														type={1}
														okr={okr}
														objName={okr?.objectiveName || ''}
														description={okr?.objectiveDescription || ''}
														showTasks={okr.totalTask > 0 ? true : false}
													/>
												</Box>
											) : (
												<Fragment>
													<OkrTextEdit
														updateName={updateName}
														updateTextValue={updateTextValue}
														handleClickInputAway={handleClickInputAway}
														okr={okr}
														goalType={1}
													/>
												</Fragment>
											)}
										</Box>
									</Box>
									<Box className='okr-keys card-toggle' onClick={handleUserClickOnCard}>
										<OKRButton
											className={okr.expand ? 'btn-blue-chip expand' : 'btn-blue-chip collapse'}
											icon={<RightArrowIcon />}
											text={`${okr?.myGoalsDetails?.length} ${t('keyResults')}`}
											handleClick={(e) => expandKeyResult(e, okr, 'expand')}
											id={`card-toggle-btn-${okr.goalObjectiveId}`}
										/>
										{okr.teamName && okr.teamName.length > 0 && !props.teamsOkr && (
											<Box className='teams-chips'>
												{checkIfOkrList() &&
												findKrStatusPending(okr) &&
												(checkOkrOwner(okr) ||
													checkTeamIdAndOwner(okr) ||
													(okr.teamId !== Enums.ZERO && checkCanAddKr(okr, null)) ||
													checkTeamIdAndCreatedBy(okr)) ? (
													<Tooltip title={okr.teamName} arrow>
														<Chip
															style={{
																backgroundColor: getRandomBackgroundColor(okr?.backGroundColorCode),
																color: getRandomColor(okr?.colorCode),
																cursor: 'pointer',
															}}
															onClick={(e: any) => {
																props.updateCalloutPanel('');
																showGoalAndKrAttribute(e, okr, null, 1);
																removeLocalStorageItem('showCallouts');
															}}
															label={getUserName({
																firstName: '',
																lastName: '',
																fullName: okr.teamName || '',
															})}
														/>
													</Tooltip>
												) : (
													<Tooltip title={okr.teamName} arrow>
														<Chip
															style={{
																backgroundColor: getRandomBackgroundColor(okr?.backGroundColorCode),
																color: getRandomColor(okr?.colorCode),
															}}
															label={getUserName({
																firstName: '',
																lastName: '',
																fullName: okr.teamName || '',
															})}
														/>
													</Tooltip>
												)}
												{okr.parentTeamDetail && okr.parentTeamDetail.teamName && (
													<Tooltip title={okr.parentTeamDetail.teamName} arrow>
														<Chip
															style={{
																backgroundColor: getRandomBackgroundColor(okr.parentTeamDetail.backGroundColorCode),
																color: getRandomColor(okr.parentTeamDetail.colorCode),
															}}
															icon={parentTeamIcon(okr.parentTeamDetail.colorCode)}
															label={getUserName({
																firstName: '',
																lastName: '',
																fullName: okr.parentTeamDetail.teamName || '',
															})}
														/>
													</Tooltip>
												)}
											</Box>
										)}
										<Box className='set-timeline-area'>
											<Box
												className='calender-date'
												id={`cal-date-${okr.goalObjectiveId}`}
												onClick={handleUserClickOnCard}
											>
												{checkIfOkrList() &&
												findKrStatusPending(okr) &&
												(checkOkrOwner(okr) ||
													checkTeamIdAndOwner(okr) ||
													(okr.teamId !== Enums.ZERO && checkCanAddKr(okr, null)) ||
													checkTeamIdAndCreatedBy(okr)) ? (
													<Box className='timeline-field'>
														<Box className='calendar-btn'>
															<OKRButton
																tooltipClassName='tooltip-layout3'
																text={`${getMonthDate(okr.dueDate)} (${okr?.dueCycle})`}
																handleClick={(e: any) => {
																	props.updateCalloutPanel('');
																	showGoalAndKrAttribute(e, okr, null, 1);
																	removeLocalStorageItem('showCallouts');
																}}
																title={
																	<>
																		<Typography variant='h6'>
																			{`${getMonthDateYear(okr.startDate)} - ${getMonthDateYear(okr.dueDate)}`}
																		</Typography>
																		<Typography variant='h6'>
																			{getDueDaysText(okr.startDate, okr.dueDate, selectedCycleDetails)}
																		</Typography>
																	</>
																}
															/>
														</Box>
													</Box>
												) : (
													<Box className='timeline-field'>
														<Box className='calendar-btn'>
															<OKRButton
																tooltipClassName='tooltip-layout3'
																disabled
																text={`${getMonthDate(okr.dueDate)} (${props.userSelectedCycle.label})`}
																buttonType={'disableButtonTooltip'}
																title={
																	<>
																		<Typography variant='h6'>
																			{`${getMonthDateYear(okr.startDate)} - ${getMonthDateYear(okr.dueDate)}`}
																		</Typography>
																		<Typography variant='h6'>
																			{getDueDaysText(okr.startDate, okr.dueDate, selectedCycleDetails)}
																		</Typography>
																	</>
																}
															/>
														</Box>
													</Box>
												)}
											</Box>
										</Box>
										{isPastCycle && (
											<OkrFieldActions
												handleListAction={handleListAction}
												showGoalAndKrAttribute={showGoalAndKrAttribute}
												showEditLoader={showEditLoader}
												okr={okr}
												openCFR={openCFR}
												setOpenCFR={setOpenCFR}
												handleCfrOpen={handleCfrOpen}
												type={1}
												settingBtnclassName={
													!checkMandatoryFieldforObjective(okr) && finalSubmitted
														? 'settings-btn settings-btn-error'
														: 'settings-btn'
												}
												{...props}
											/>
										)}

										{checkIfOkrList() &&
										findKrStatusPending(okr) &&
										(checkOkrOwner(okr) || checkTeamIdAndOwner(okr) || checkTeamIdAndCreatedBy(okr)) ? (
											<OkrFieldActions
												handleListAction={handleListAction}
												showGoalAndKrAttribute={showGoalAndKrAttribute}
												showEditLoader={showEditLoader}
												okr={okr}
												openCFR={openCFR}
												setOpenCFR={setOpenCFR}
												handleCfrOpen={handleCfrOpen}
												type={1}
												settingBtnclassName={
													!checkMandatoryFieldforObjective(okr) && finalSubmitted
														? 'settings-btn settings-btn-error'
														: 'settings-btn'
												}
												{...props}
											/>
										) : (
											checkIfOkrList() &&
											findKrStatusPending(okr) &&
											(checkOkrOwner(okr) ||
												checkTeamIdAndOwner(okr) ||
												(okr.teamId !== Enums.ZERO && checkCanAddKr(okr, null)) ||
												checkTeamIdAndCreatedBy(okr)) && (
												<OkrFieldActions
													handleListAction={handleListAction}
													showGoalAndKrAttribute={showGoalAndKrAttribute}
													showEditLoader={showEditLoader}
													okr={okr}
													type={1}
													settingBtnclassName={
														!checkMandatoryFieldforObjective(okr) && finalSubmitted
															? 'settings-btn settings-btn-error'
															: 'settings-btn'
													}
													{...props}
													openCFR={openCFR}
													setOpenCFR={setOpenCFR}
													handleCfrOpen={handleCfrOpen}
												/>
											)
										)}

										{currentUser && !isPastCycle && (
											<OkrFieldActions
												peopleViewActions={true}
												handleListAction={handleListAction}
												showGoalAndKrAttribute={showGoalAndKrAttribute}
												showEditLoader={showEditLoader}
												okr={okr}
												type={1}
												settingBtnclassName={
													!checkMandatoryFieldforObjective(okr) && finalSubmitted
														? 'settings-btn settings-btn-error'
														: 'settings-btn'
												}
												{...props}
												openCFR={openCFR}
												setOpenCFR={setOpenCFR}
												handleCfrOpen={handleCfrOpen}
											/>
										)}
									</Box>
								</Box>
							</ListItem>
							<ListItem className='okr-col2' onClick={handleUserClickOnCard}>
								<UserAvatarGroup
									{...props}
									contributorDetails={
										okr.contributors && okr.contributors.length && !okr.isCoach
											? okr.contributors
													.slice(1, okr.contributors.length)
													.filter((items: any) => items.goalStatusId === Enums.KR_STATUS_ACCEPTED)
											: okr.isCoach
											? okr.contributors
											: []
									}
									okr={okr}
									addButtonClassName='add-btn grey-out-section'
									avatarClassName=''
									isCoach={okr.isCoach}
									isButtonVisible={false}
									max={3}
									loginUserAvatar={okr.isCoach ? false : okr}
									currentUser={currentUser}
									contributorDetailsType={true}
									isOnHoverVisible={!currentUser && !props.teamsOkr}
									viewType='okrList'
									handleViewAllVirtualAlignments={handleViewAllVirtualAlignments}
									virtualAlignmentList={virtualAlignmentList}
									virtualAlignmentListPopUp={virtualAlignmentListPopUp}
									getVirtuals={getVirtuals}
								/>
							</ListItem>
							<ListItem className='okr-col3'>
								<Box className='confidence-btn-wrap'>
									<KrConfidencePopper
										okr={okr}
										type={1}
										handleOpenWeeklyTrend={(event: any) => handleOpenWeeklyTrend(event, okr, 1, 0, '')}
										{...props}
										confidenceValue={0}
									/>
								</Box>
								{props.readOnlyView === true ? (
									<></>
								) : (
									<Box className='conversation-listing-action' onClick={(e) => handleUserClickOnCard(e)}>
										<Conversation
											selectTab={'conversation'}
											handleCfrOpen={handleCfrOpen}
											modalCfrData={modalCfrData}
											type={1}
											okr={okr}
											currentUser={currentUser}
											{...props}
										/>
									</Box>
								)}
							</ListItem>
							<ListItem className='okr-col4'>
								<OkrProgressBar
									{...props}
									parentOkr={[]}
									okr={okr}
									type={'okr'}
									t={t}
									showArchiveList={showArchiveList}
									updateProgress={updateProgress}
									showEditLoader={showEditLoader}
									currentUser={currentUser}
									okrMasterData={okrMasterData}
									isScoreLocked={listOkrResult.isScoreLocked}
									enqueueSnackbar={enqueueSnackbar}
									isAnyDrawerOpened={props.isAnyDrawerOpened}
									appDrawerInfo={props.appDrawerInfo}
								/>
								<Box onClick={handleUserClickOnCard}>
									{currentUser && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
										<></>
									) : (
										findKrStatusPending(okr) && (
											<OkrPopperMenu
												okr={okr}
												type={1}
												t={t}
												currentUser={currentUser}
												handleDeleteOkr={handleDeleteOkr}
												{...props}
												isDeleteBtnVisible={props.teamsOkr && userDetail.employeeId === okr.owner ? false : true}
											/>
										)
									)}
								</Box>
							</ListItem>
						</List>
					</Box>
					<Collapse className='okr-keys-listing' in={okr.expand || expendKeyResult(okr)}>
						{okr.myGoalsDetails &&
							okr.myGoalsDetails.map((kr: any, krIndex: number) => (
								<Box
									className={
										highlightKR === kr.goalKeyId
											? 'show-highlighted my-obj-listing-content kr-listing-content orphen-kr-content'
											: 'my-obj-listing-content kr-listing-content orphen-kr-content'
									}
									key={'kr_list' + kr.goalKeyId}
									onClick={(e) => handleUserClickOnCard(e)}
									id={`kr-list-${kr.goalKeyId}`}
								>
									<Box className='my-obj-listing-inner'>
										<List component='ul'>
											<ListItem className='okr-col1 okr-drag-wrap'>
												<Box className='okr-col1-left'>
													<Box className='okr-objective'>
														<Box
															onClick={(e) => {
																e.stopPropagation();
															}}
														>
															{!kr.edit ? (
																<Box className='okr-after-edit'>
																	<ListTooltip
																		{...props}
																		type={2}
																		kr={kr}
																		objName={kr?.keyDescription || ''}
																		description={kr?.keyNotes || ''}
																		showTasks={kr.totalTask > 0 ? true : false}
																	/>
																</Box>
															) : (
																<OkrTextEdit
																	updateName={updateName}
																	updateTextValue={updateTextValue}
																	handleClickInputAway={handleClickInputAway}
																	showEditLoader={showEditLoader}
																	okr={okr}
																	kr={kr}
																	goalType={2}
																/>
															)}
														</Box>
													</Box>
													<Box className='okr-keys card-toggle'>
														<OKRButton
															title={t('weightHover')}
															className='btn-oval-shape'
															text={`${kr.weightId}x`}
															buttonType={'disableButtonTooltip'}
															wrapClassName='btn-weight'
															handleClick={(e) => handleWeightClick(e, okr, kr, 2)}
															disabled={
																currentUser ||
																isLocked ||
																props.readOnlyView ||
																kr.krStatusId === Enums.KR_STATUS_PENDING ||
																(props.teamsOkr && kr?.owner !== userDetail?.employeeId)
															}
														/>
														{kr.teamName && kr.teamName.length > 0 && (
															<Box className='teams-chips'>
																<Tooltip title={kr.teamName} arrow>
																	<Chip
																		style={{
																			backgroundColor: getRandomBackgroundColor(kr?.backGroundColorCode),
																			color: getRandomColor(kr?.colorCode),
																		}}
																		//label={kr.teamName}
																		label={getUserName({
																			firstName: '',
																			lastName: '',
																			fullName: kr.teamName || '',
																		})}
																	/>
																</Tooltip>
																{kr.parentTeamDetail && kr.parentTeamDetail.teamName && (
																	<Tooltip title={kr.parentTeamDetail.teamName} arrow>
																		<Chip
																			style={{
																				backgroundColor: getRandomBackgroundColor(
																					kr.parentTeamDetail.backGroundColorCode
																				),
																				color: getRandomColor(kr.parentTeamDetail.colorCode),
																			}}
																			icon={teamTooltipIcon(getRandomColor(kr.parentTeamDetail.colorCode))}
																			label={getUserName({
																				firstName: '',
																				lastName: '',
																				fullName: kr.parentTeamDetail.teamName || '',
																			})}
																		/>
																	</Tooltip>
																)}
															</Box>
														)}
														<Box className='set-timeline-area'>
															<Box className='calender-date' id={`cal-date-${kr.goalKeyId}`}>
																{checkIfOkrList() &&
																kr.keyDescription &&
																kr.keyDescription.trim() !== '' &&
																(kr?.owner === userDetail?.employeeId ||
																	checkTeamIdAndCreatedBy(okr) ||
																	checkCanAddKr(okr, kr)) ? (
																	<Box className='timeline-field'>
																		<Box className='calendar-btn'>
																			<OKRButton
																				tooltipClassName='tooltip-layout3'
																				text={`${getMonthDate(kr.dueDate)} (${okr?.dueCycle})`}
																				handleClick={(e: any) => {
																					props.updateCalloutPanel('');
																					showGoalAndKrAttribute(e, okr, kr, 2);
																					removeLocalStorageItem('showCallouts');
																				}}
																				title={
																					<>
																						<Typography variant='h6'>
																							{`${getMonthDateYear(kr.startDate)} - ${getMonthDateYear(kr.dueDate)}`}
																						</Typography>
																						<Typography variant='h6'>
																							{getDueDaysText(kr?.startDate, kr.dueDate, selectedCycleDetails)}
																						</Typography>
																					</>
																				}
																			/>
																		</Box>
																	</Box>
																) : (
																	<Box className='timeline-field'>
																		<Box className='calendar-btn'>
																			<OKRButton
																				tooltipClassName='tooltip-layout3'
																				disabled
																				text={`${getMonthDate(kr.dueDate)} (${okr?.dueCycle})`}
																				buttonType={'disableButtonTooltip'}
																				title={
																					<>
																						<Typography variant='h6'>
																							{`${getMonthDateYear(kr.startDate)} - ${getMonthDateYear(kr.dueDate)}`}
																						</Typography>
																						<Typography variant='h6'>
																							{getDueDaysText(kr?.startDate, kr.dueDate, selectedCycleDetails)}
																						</Typography>
																					</>
																				}
																			/>
																		</Box>
																	</Box>
																)}
															</Box>
														</Box>
														{okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT &&
														(currentUser || kr.isCollaborator) &&
														!kr.isContributor &&
														!isLocked ? (
															<Fragment>
																<OKRButton
																	className='alignment-btn'
																	icon={<ContributorIcon />}
																	title={t('becomeAContributor')}
																	handleClick={(event: any) => handleBecomeAContributorClick(event, kr, okr)}
																	id={`become-a-contributor-${kr.goalKeyId}`}
																/>
															</Fragment>
														) : kr.isContributor &&
														  userDetail &&
														  kr.contributors[0].employeeId !== userDetail.employeeId ? (
															<Fragment>
																<OKRButton
																	className='alignment-btn'
																	icon={<AlignedAsContributor />}
																	title={t('alignedAsContributor')}
																	id={`aligned-as-contributor-${kr.goalKeyId}`}
																/>
															</Fragment>
														) : (
															''
														)}
														{!isLocked && kr.krStatusId === Enums.KR_STATUS_PENDING ? (
															<Box className='action-message-wrap'>
																<Box className='action-message'>
																	<InfoIcon />
																	<Typography variant='body2'>{t('actionRequired')}</Typography>
																</Box>

																<Box className='orphen-action-btn'>
																	<OKRButton
																		className='btn-accept'
																		text={'Accept'}
																		handleClick={() =>
																			setOpenDrawer({ open: true, okr, kr, type: 'accept', KRType: 'WithParent' })
																		}
																		id={`accept-btn-${kr.goalKeyId}`}
																	/>
																	<OKRButton
																		className='btn-decline'
																		text={'Decline'}
																		handleClick={() =>
																			setOpenDrawer({ open: true, okr, kr, type: 'decline', KRType: 'WithParent' })
																		}
																		id={`decline-btn-${kr.goalKeyId}`}
																	/>
																</Box>
															</Box>
														) : (
															checkIfOkrList() &&
															(kr?.owner === userDetail?.employeeId ||
																checkTeamIdAndCreatedBy(okr) ||
																checkCanAddKr(okr, kr)) && (
																<OkrFieldActions
																	openCFR={openCFR}
																	setOpenCFR={setOpenCFR}
																	handleCfrOpen={handleCfrOpen}
																	handleListAction={handleListAction}
																	showGoalAndKrAttribute={showGoalAndKrAttribute}
																	showEditLoader={showEditLoader}
																	okr={okr}
																	kr={kr}
																	type={2}
																	{...props}
																	settingBtnclassName={
																		!checkMandatoryFieldforKR(kr) && finalSubmitted
																			? 'settings-btn settings-btn-error'
																			: 'settings-btn'
																	}
																/>
															)
														)}
														{currentUser && !isPastCycle && (
															<OkrFieldActions
																peopleViewActions={true}
																openCFR={openCFR}
																setOpenCFR={setOpenCFR}
																handleCfrOpen={handleCfrOpen}
																handleListAction={handleListAction}
																showGoalAndKrAttribute={showGoalAndKrAttribute}
																showEditLoader={showEditLoader}
																okr={okr}
																kr={kr}
																type={2}
																{...props}
																settingBtnclassName={
																	!checkMandatoryFieldforKR(kr) && finalSubmitted
																		? 'settings-btn settings-btn-error'
																		: 'settings-btn'
																}
															/>
														)}
														{isPastCycle && (
															<OkrFieldActions
																openCFR={openCFR}
																setOpenCFR={setOpenCFR}
																handleCfrOpen={handleCfrOpen}
																handleListAction={handleListAction}
																showGoalAndKrAttribute={showGoalAndKrAttribute}
																showEditLoader={showEditLoader}
																okr={okr}
																kr={kr}
																type={2}
																{...props}
																settingBtnclassName={
																	!checkMandatoryFieldforKR(kr) && finalSubmitted
																		? 'settings-btn settings-btn-error'
																		: 'settings-btn'
																}
															/>
														)}
													</Box>
												</Box>
											</ListItem>
											<ListItem className='okr-col2'>
												{props.teamsOkr ? (
													<UserAvatarGroup
														{...props}
														contributorDetails={
															kr.contributors && kr.contributors.length
																? kr.contributors.filter((itemKR: any) => !itemKR.isSource)
																: []
														}
														kr={kr}
														addButtonClassName='grey-out-section'
														avatarClassName=''
														isButtonVisible={
															!isLocked &&
															!showArchiveList &&
															kr.krStatusId === Enums.KR_STATUS_ACCEPTED &&
															userDetail?.employeeId === okr?.owner
														}
														tooltipText={
															okr?.goalTypeId === 0
																? t('noGoalTypeContributor')
																: kr.metricId === 0
																? t('noMetricContributor')
																: ''
														}
														buttonType={okr?.goalTypeId === 0 || kr.metricId === 0 ? 'disableButtonTooltip' : ''}
														isButtonDisable={okr?.goalTypeId === 0 || kr.metricId === 0}
														max={3}
														handleAddUserClick={(event: any) => handleAddUserClick(event, kr, okr)}
														loginUserAvatar={
															kr.contributors && kr.contributors.length
																? kr.contributors.find((itemKR: any) => itemKR.isSource)
																	? kr.contributors.find((itemKR: any) => itemKR.isSource)
																	: okr
																: okr
														}
														currentUser={currentUser}
														contributorDetailsType={true}
														showTarget={true}
													/>
												) : (
													<UserAvatarGroup
														{...props}
														contributorDetails={
															kr.contributors && kr.contributors.length && okr.goalStatusId === Enums.GOAL_STATUS_PUBLIC
																? kr.contributors.filter((itemKR: any) => !itemKR.isSource)
																: okr.goalStatusId === Enums.GOAL_STATUS_DRAFT ||
																  okr.goalStatusId === Enums.GOAL_STATUS_ARCHIVE
																? kr.contributors.filter((itemKR: any) => !itemKR.isSource)
																: []
														}
														kr={kr}
														addButtonClassName='grey-out-section'
														avatarClassName=''
														isButtonVisible={
															!props.readOnlyView &&
															!isLocked &&
															!showArchiveList &&
															!okr.isPrivate &&
															kr.krStatusId === Enums.KR_STATUS_ACCEPTED &&
															isKrContributor(okr, kr)
														}
														tooltipText={
															okr?.goalTypeId === 0
																? t('noGoalTypeContributor')
																: kr.metricId === 0
																? t('noMetricContributor')
																: ''
														}
														buttonType={okr?.goalTypeId === 0 || kr.metricId === 0 ? 'disableButtonTooltip' : ''}
														isButtonDisable={okr?.goalTypeId === 0 || kr.metricId === 0}
														max={3}
														handleAddUserClick={(event: any) => handleAddUserClick(event, kr, okr)}
														loginUserAvatar={
															kr.contributors && kr.contributors.length
																? kr.contributors.find((itemKR: any) => itemKR.isSource)
																	? kr.contributors.find((itemKR: any) => itemKR.isSource)
																	: okr
																: okr
														}
														currentUser={currentUser}
														contributorDetailsType={true}
														showTarget={true}
													/>
												)}
											</ListItem>
											<ListItem className='okr-col3'>
												<Box className='confidence-btn-wrap'>
													{((props.teamsOkr && okr?.owner === userDetail?.employeeId) || !props.teamsOkr) &&
														!props.isTeamView && (
															<KrConfidencePopper
																kr={kr}
																okr={okr}
																krIndex={krIndex}
																type={
																	kr.krStatusId === Enums.KR_STATUS_PENDING &&
																	okr?.goalStatusId !== Enums.GOAL_STATUS_ARCHIVE
																		? 3
																		: 2
																}
																canUpdateConfidence={
																	!currentUser &&
																	kr.krStatusId !== Enums.KR_STATUS_PENDING &&
																	okr?.goalStatusId !== Enums.GOAL_STATUS_ARCHIVE &&
																	!props.isPastCycle
																}
																currentUser={currentUser}
																handleOpenWeeklyTrend={(event: any) =>
																	handleOpenWeeklyTrend(
																		event,
																		kr,
																		kr.krStatusId === Enums.KR_STATUS_PENDING ? 3 : 2,
																		kr.confidenceId,
																		okr
																	)
																}
																confidenceValue={kr.confidenceId}
																isTeamView={props.isTeamView}
																hidePopover={
																	(currentUser && props.isFutureCycle) ||
																	okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE
																}
																showWeekelyTrend={!props.isFutureCycle && okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT}
																updateConfidence={updateConfidence}
																{...props}
															/>
														)}
												</Box>

												{props.readOnlyView === true ? (
													<></>
												) : (
													<Box className='conversation-listing-action' onClick={(e) => handleUserClickOnCard(e)}>
														<Conversation
															selectTab={'conversation'}
															handleCfrOpen={handleCfrOpen}
															modalCfrData={modalCfrData}
															kr={kr}
															type={2}
															{...props}
														/>
													</Box>
												)}
											</ListItem>
											<ListItem className='okr-col4'>
												{kr.hasRecent === true &&
													checkIfOkrList() &&
													props.userSelectedCycle.cycleStatus === Enums.CURRENT_CYCLE_ID &&
													kr.contributors.length > 0 &&
													(okr?.owner === userDetail?.employeeId ||
														checkTeamIdAndCreatedBy(okr) ||
														(okr.goalTypeId !== 1 &&
															okr.myGoalsDetails &&
															kr.krStatusId !== Enums.KR_STATUS_PENDING)) && (
														<DeltaKrPopper {...props} recentContribution={kr.recentContributorData} kr={kr} />
													)}
												<OkrProgressBar
													{...props}
													parentOkr={okr}
													okr={kr}
													type={'kr'}
													t={t}
													updateProgress={updateProgress}
													showEditLoader={showEditLoader}
													currentUser={currentUser}
													okrMasterData={okrMasterData}
													teamsOkr={props.teamsOkr}
													showArchiveList={showArchiveList}
													isScoreLocked={listOkrResult.isScoreLocked}
													enqueueSnackbar={enqueueSnackbar}
													isAnyDrawerOpened={props.isAnyDrawerOpened}
													appDrawerInfo={props.appDrawerInfo}
												/>
												{!isLocked &&
													(kr.krStatusId === Enums.KR_STATUS_PENDING ? (
														<OkrPopperMenu
															okr={okr}
															kr={kr}
															type={4}
															t={t}
															handleDeleteOkr={handleDeleteOkr}
															KRType={'WithParent'}
															handleAcceptDeclineClick={handleAcceptDeclineClick}
															{...props}
															currentUser={currentUser}
														/>
													) : currentUser && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
														<></>
													) : (
														<OkrPopperMenu
															okr={okr}
															kr={kr}
															type={2}
															t={t}
															handleDeleteOkr={handleDeleteOkr}
															{...props}
															currentUser={currentUser}
															isDeleteBtnVisible={props.teamsOkr && userDetail.employeeId === okr.owner ? false : true}
														/>
													))}
											</ListItem>
										</List>
									</Box>
								</Box>
							))}
						<AddNewKr
							{...props}
							okr={okr}
							newKeyList={newKeyList}
							handleKRFocus={handleKRFocus}
							handleKRBlur={handleKRBlur}
							handleUserClickOnCard={handleUserClickOnCard}
							handleNewKeyResultChange={handleNewKeyResultChange}
							checkMandatoryFieldforKR={checkMandatoryFieldforKR}
							showGoalAndKrAttribute={showGoalAndKrAttribute}
							isAddClicked={isAddClicked}
							finalSubmitted={finalSubmitted}
							deleteNewKeyResult={deleteNewKeyResult}
							krFocus={krFocus}
							loginUserAvatar={loginUserAvatar}
							checkIfOkrList={checkIfOkrList}
							userDetail={userDetail}
							checkTeamIdAndCreatedBy={checkTeamIdAndCreatedBy}
							checkCanAddKr={checkCanAddKr}
							setModalPropsOKR={setModalPropsOKR}
							updateProgress={updateProgress}
							t={t}
							showEditLoader={showEditLoader}
							currentUser={currentUser}
							showArchiveList={showArchiveList}
							enqueueSnackbar={enqueueSnackbar}
							setNewKeyList={setNewKeyList}
							currentCycleEndDate={currentCycleEndDate}
							allCycleDetails={allCycleDetails}
							isCurrectCycle={isCurrectCycle}
							selectedCycleDetails={selectedCycleDetails}
						/>

						{checkPermission(props.getPermissionResult?.employeePermissions, createOkrs) ? (
							<Box className='key-result-action'>
								{checkIfOkrList() && !isCollaboratorOkr(okr) && checkCanAddKr(okr, null, true) && !props.readOnlyView && (
									<Fragment>
										<OKRButton
											className='btn-add-key'
											handleClick={(e: any) => {
												addNewKeyResult(e, okr);
												props.isAnyDrawerOpened({
													...props.appDrawerInfo,
													isDrawerOpened: true,
												});
											}}
											icon={<AddIconSvg />}
											text={'Add Key Result'}
											id={`add-key-btn-${okr.goalObjectiveId}`}
										/>
									</Fragment>
								)}
							</Box>
						) : (
							<></>
						)}
					</Collapse>
					{okr.expand && !currentUser && (
						<Box className='drafted-okr-actions' onClick={handleUserClickOnCard}>
							{!showArchiveList && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT && !isLocked ? (
								<Fragment>
									<OKRButton
										className='btn-link draft-btn'
										text={t('saveAsDraft')}
										handleClick={(e: any) => checkNewKrChanges(e, okr, 3)}
										id={`save-draft-btn-${okr.goalObjectiveId}`}
										disabled={isDataSaving}
									/>
									<Typography>{t('orLabel')}</Typography>
								</Fragment>
							) : showArchiveList &&
							  okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE &&
							  okr?.isLastStatusDraft &&
							  !isLocked ? (
								<Fragment>
									<OKRButton
										className='btn-link draft-btn'
										icon={<SaveAsDraft />}
										text={t('saveAsDraft')}
										handleClick={(e: any) => checkNewKrChanges(e, okr, 3)}
										id={`save-draft-btn-${okr.goalObjectiveId}`}
									/>
									<Typography>{t('orLabel')}</Typography>
								</Fragment>
							) : (
								<></>
							)}
							{!isLocked &&
								((okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE &&
									okr?.myGoalsDetails.length &&
									okr?.isLastStatusDraft) ||
									(okr?.goalStatusId !== Enums.GOAL_STATUS_ARCHIVE &&
										(okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ||
											isAddClicked ||
											(okr?.goalStatusId === Enums.GOAL_STATUS_ARCHIVE && okr?.isLastStatusDraft)))) && (
									<Box onClick={handleUserClickOnCard}>
										<OKRButton
											className='btn-primary'
											text={t('createObjective')}
											disabled={hasEmptyKr() || isDataSaving}
											handleClick={(e: any) => checkNewKrChanges(e, okr, 2)}
											id={`save-draft-btn-${okr.goalObjectiveId}`}
										/>
									</Box>
								)}
						</Box>
					)}
				</Box>
			</Box>
			{krModalProps.open && (
				<AlertDialog
					module={krModalProps.module ? krModalProps.module : 'goals'}
					message={krModalProps.message}
					handleCloseModal={handleCloseKrModal}
					modalOpen={krModalProps.open}
				/>
			)}

			<OKRDrawer
				open={open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel drawer-no-footer'}
				headerTitle={t('virtuallylinked', { count: okr.virtualAlignmentCount })}
				children={
					<VirtualAlignment
						t={t}
						okr={okr}
						virtualAlignmentList={virtualAlignmentList}
						sortVirtualAlignmentList={sortVirtualAlignmentList}
						sortOrder={sortOrder}
						sortingColumn={sortingColumn}
					/>
				}
				moreButton={false}
				handleDrawerClose={(event: any) => handleDrawerClose(event)}
				handleSaveClick={(event: any) => handleDrawerClose(event)}
				handleCancelClick={(event: any) => handleDrawerClose(event)}
				hideDefaultButton={true}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{modalPropsOKR.open && (
				<AlertDialog
					module={modalPropsOKR.module ? modalPropsOKR.module : 'user'}
					message={modalPropsOKR.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalPropsOKR.open}
					isCancel={modalPropsOKR.module === 'validateForm' ? true : false}
				/>
			)}
			{modalPropsOKRCalendar.open && (
				<AlertDialog
					module={modalPropsOKRCalendar.module ? modalPropsOKRCalendar.module : 'user'}
					message={modalPropsOKRCalendar.message}
					handleCloseModal={handleCycleChangeCloseModal}
					modalOpen={modalPropsOKRCalendar.open}
					isCancel={modalPropsOKRCalendar.module === 'validateForm' ? true : false}
				/>
			)}
			{
				<OKRDrawer
					open={openCFR}
					drawerClassName={'main-drawer-panel main-drawer-titlepanel ok-cfr-panel'}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={''}
					drawerHead={false}
					children={
						<Cfr
							isCfrUpdated={isCfrUpdated}
							setIsCfrUpdated={setIsCfrUpdated}
							selectTab={currentTab}
							{...props}
							kr={modalCfrData.kr}
							okr={modalCfrData.okr}
							type={modalCfrData.type}
							setIsTaskModified={setIsTaskModified}
							setShowDialog={setShowDialog}
							showDialog={showDialog}
						/>
					}
					moreButton={false}
					handleDrawerClose={(event: any) => handleCfrDrawerClose(event)}
					handleSaveClick={(event: any) => handleCfrDrawerClose(event)}
					handleCancelClick={(event: any) => handleCfrDrawerClose(event)}
					hideDefaultButton={true}
					isAnyDrawerOpened={props.isAnyDrawerOpened}
					appDrawerInfo={props.appDrawerInfo}
				/>
			}
			{openWeeklyTrendDrawer.open && (
				<WeeklyTrendDetails
					{...props}
					openWeeklyTrendDrawer={openWeeklyTrendDrawer}
					setOpenWeeklyTrendDrawer={setOpenWeeklyTrendDrawer}
				/>
			)}
		</Fragment>
	);
};

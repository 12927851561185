import { graphConfig } from './azureAuthConfig';
import CryptoJS from 'crypto-js'; //imports
import { OkrTrialEnv } from './url';
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append('Authorization', bearer);

	const options = {
		method: 'GET',
		headers: headers,
	};

	return fetch(graphConfig.graphMeEndpoint, options)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function callMsGraphPostAPI(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append('Authorization', bearer);
	headers.append('Content-Type', 'application/json');

	var body = {
		currentPassword: 'Happy@100',
		newPassword: 'Puja@123',
	};
	const options = {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(body),
	};

	return fetch('https://graph.microsoft.com/beta/me/changePassword', options)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}
export const getEncryptString = (plainText) => {
	var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_Key);
	var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);
	var encryptedToken = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key, {
		keySize: 128 / 8,
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	var result = CryptoJS.enc.Base64.stringify(encryptedToken.ciphertext);
	return result;
};
export const getDecryptString = (ciphertextB64) => {
	var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_Key);
	var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);

	var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv });
	return decrypted.toString(CryptoJS.enc.Utf8);
};

export async function callLoginPostAPI(id) {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	headers.append('Access-Control-Allow-Origin', '*');
	headers.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
	headers.append('Ocp-Apim-Subscription-Key', process.env.REACT_APP_AZURE_SUBSCRIPTIONKEY);
	headers.append('token', getEncryptString(new Date().toUTCString()));
	var body = { code: id };
	const options = {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(body),
	};

	return fetch(`${OkrTrialEnv}/oAuthToken`, options)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

import React, { Fragment, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { ContributorList } from '../TeamsKr/ContributorList';
import AlertDialog from '../../Common/Dialog';
import { IndividualKrMembersList } from './IndividualKrMembersList';

export const IndividualKr: React.FC<any> = (props) => {
	const {
		open,
		t,
		setOpen,
		keyAttribute,
		selectedContributorDetails,
		contributorDetails,
		deleteContributor,
		setSelectedValues,
		onSaveIndividualContributorsDetailClick,
		completeOrgData,
		selectedValues,
		selectedMetricDetail,
		keyData,
		setCompleteOrgData,
		dueCycle,
		userAligned,
		disabledNodes,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		showAssignKrDateCallouts,
		setShowAssignKrDateCallouts,
		setShowAssignKrTargetCallouts,
		showAssignKrTargetCallouts,
		showAssignKrTitleCallouts,
		setShowAssignKrTitleCallouts,
		calloutsData,
		krMsg,
	} = props;
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		data: null,
		message: '',
	});
	const [selectedTeamIds, setSelectedTeamIds] = useState<any[]>([]);
	const [currentScreen, setCurrentScreen] = useState<number>(0);
	const [selectedUsersData, setSelectedUsersData] = useState<any[]>([]);
	const [targetValue, setTargetValue] = useState<number>(0);

	useEffect(() => {
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			setTargetValue(keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue);
		} else {
			setTargetValue(0);
		}
	}, [keyAttribute, keyData, selectedMetricDetail]);

	useEffect(() => {
		setSelectedTeamIds(selectedValues);
	}, [selectedValues]);

	const handleClose = () => {
		setOpen(false);
		setCurrentScreen(0);
	};

	const deleteContributorFromKR = async (contributor: any, showMessage: boolean) => {
		await deleteContributor(contributor, false, showMessage);
	};

	const handleCloseModal = (e: any, action: any) => {
		if (action === 1) {
			setModalProps({
				...modalProps,
				open: false,
			});
			let showMessage = false;
			let total = modalProps.data.missingContributor.length;
			let counter = 0;
			modalProps.data.missingContributor.forEach((contributor: any) => {
				let childId = contributor;
				let contributorIs = contributorDetails.filter((cont: any) => childId == cont.employeeId);
				if (contributorIs.length > 0) {
					counter++;
					if (total === counter) {
						showMessage = true;
					}
					deleteContributorFromKR(contributorIs[0], showMessage);
				}
			});
			setSelectedValues(modalProps.data.selectedT);
			handleClose();
			let selectedTeamsData: any[] = [];
			completeOrgData.forEach((child: any) => {
				if (modalProps.data.selectedT.indexOf(child.id) !== -1) {
					selectedTeamsData.push(child);
				}
			});
			onSaveIndividualContributorsDetailClick(
				modalProps.data.event,
				modalProps.data.krContributorDetail,
				selectedTeamsData,
				false
			);
			setCurrentScreen(0);
		} else {
			setModalProps({
				...modalProps,
				open: false,
				data: null,
			});
		}
	};

	const onSaveContributorDetailClick = (event: any, krContributorDetail: any) => {
		let missingContributor: any[] = [];
		let selectedT: any[] = [];
		if (selectedValues.length > 0) {
			selectedValues.forEach((selectedVal: any) => {
				let exists = false;
				selectedTeamIds.forEach((existingTeam: any) => {
					if (existingTeam === selectedVal) {
						exists = true;
					}
				});
				if (!exists) {
					missingContributor.push(selectedVal);
				}
			});
		}
		selectedTeamIds.forEach((existingTeam: any) => {
			selectedT.push(existingTeam);
		});
		if (missingContributor.length > 0) {
			setModalProps({
				open: true,
				message: t('deleteContributorFromTeam'),
				data: {
					missingContributor: missingContributor,
					selectedT: selectedT,
					event: event,
					krContributorDetail: krContributorDetail,
				},
			});
		} else {
			setSelectedValues(selectedT);
			handleClose();
			let selectedTeamsData: any[] = [];
			completeOrgData.forEach((child: any) => {
				if (selectedT.indexOf(child.id) !== -1) {
					selectedTeamsData.push(child);
				}
			});
			onSaveIndividualContributorsDetailClick(event, krContributorDetail, selectedTeamsData, false);
			setCurrentScreen(0);
		}
	};

	const prepareContributorList = () => {
		let selectedT: any[] = [];
		selectedTeamIds.forEach((existingTeam: any) => {
			selectedT.push(existingTeam);
		});
		let selectedTeamsData: any[] = [];
		completeOrgData.forEach((child: any) => {
			if (selectedT.indexOf(child.id) !== -1) {
				selectedTeamsData.push(child);
			}
		});
		setSelectedUsersData(selectedTeamsData);
	};
	const handleCancel = () => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		setCurrentScreen(0);
		handleClose();
	};

	const handleBack = (screenNum: number) => {
		setCurrentScreen(screenNum);
	};

	const handleNext = async (screenNumber: number) => {
		if (screenNumber === 1) {
			setCurrentScreen(screenNumber);
			prepareContributorList();
		} else {
			onSaveContributorDetailClick({}, selectedContributorDetails);
		}
	};

	return (
		<Fragment>
			{open ? (
				<Box
					className={
						keyAttribute && !keyAttribute.goalKeyId ? 'createdPopover' : 'contributor-details-popover teams-section-kr'
					}
				>
					{currentScreen === 1 ? (
						<ContributorList
							{...props}
							handleBack={handleBack}
							handleNext={handleNext}
							handleCancel={handleCancel}
							contributorList={selectedUsersData}
							targetValue={targetValue}
							dueCycle={dueCycle}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							showAssignKrDateCallouts={showAssignKrDateCallouts}
							setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
							setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
							showAssignKrTargetCallouts={showAssignKrTargetCallouts}
							showAssignKrTitleCallouts={showAssignKrTitleCallouts}
							setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
							calloutsData={calloutsData}
							saveButtonText={'Assign'}
						/>
					) : (
						<IndividualKrMembersList
							{...props}
							handleBack={handleBack}
							handleNext={handleNext}
							handleCancel={handleCancel}
							setSelectedTeamIds={setSelectedTeamIds}
							selectedTeamIds={selectedTeamIds}
							prepareContributorList={prepareContributorList}
							setCompleteOrgData={setCompleteOrgData}
							keyAttribute={keyAttribute}
							keyData={keyData}
							userAligned={userAligned}
							disabledNodes={disabledNodes}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							calloutsData={calloutsData}
							krMsg={krMsg}
						/>
					)}
				</Box>
			) : (
				<span></span>
			)}
			{modalProps.open && (
				<AlertDialog
					module='organization'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};

import {
	createVersionDetailAPI,
	createVersionFeatureAPI,
	deleteFeatureDocumentAPI,
	deleteVersionDetailAPI,
	deleteVersionFeatureAPI,
	emailSentAPI,
	featureDocumentUploadAPI,
	getAllVersionAPI,
	getAllVersionForUserAPI,
	notifyVersionAPI,
	publishVersionAPI,
	updateVersionDetailAPI,
	updateVersionFeatureAPI,
} from '../services/okrUpdateService';
import * as actionTypes from './actionTypes';

const versionPending = () => ({
	type: actionTypes.GET_VERSION_DETAILS_PENDING,
});

const versionCompleted = (payload) => ({
	type: actionTypes.GET_VERSION_DETAILS_SUCCESS,
	payload,
});

const versionFailed = (payload) => ({
	type: actionTypes.GET_VERSION_DETAILS_FAILED,
	payload,
});
export const getAllVersion = (data) => {
	return (dispatch) => {
		dispatch(versionPending());
		return getAllVersionAPI(data)
			.then((response) => {
				response?.data?.entityList && dispatch(versionCompleted(response.data?.entityList));
			})
			.catch((error) => {
				dispatch(versionFailed(error));
			});
	};
};

export const createVersionDetail = (data) => {
	return () => {
		return createVersionDetailAPI(data);
	};
};

export const updateVersionDetail = (data) => {
	return () => {
		return updateVersionDetailAPI(data);
	};
};

export const deleteVersionDetail = (data) => {
	return (dispatch) => {
		dispatch(versionCompleted([]));
		return deleteVersionDetailAPI(data);
	};
};

export const publishVersion = (data) => {
	return () => {
		return publishVersionAPI(data);
	};
};

export const notifyVersion = (data) => {
	return () => {
		return notifyVersionAPI(data);
	};
};

export const emailSent = (data, queryParams) => {
	return () => {
		return emailSentAPI(data, queryParams);
	};
};

export const createVersionFeature = (data) => {
	return () => {
		return createVersionFeatureAPI(data);
	};
};

export const updateVersionFeature = (data) => {
	return () => {
		return updateVersionFeatureAPI(data);
	};
};

export const deleteVersionFeature = (data) => {
	return () => {
		return deleteVersionFeatureAPI(data);
	};
};
/**
 * What's new Action for User screens
 */
const userVersionPending = () => ({
	type: actionTypes.GET_USER_VERSION_DETAILS_PENDING,
});
export const userVersionCompleted = (payload) => ({
	type: actionTypes.GET_USER_VERSION_DETAILS_SUCCESS,
	payload,
});
const userVersionFailed = (payload) => ({
	type: actionTypes.GET_USER_VERSION_DETAILS_FAILED,
	payload,
});
export const userVersionPageDetails = (payload) => ({
	type: actionTypes.GET_USER_VERSION_PAGE_DETAILS,
	payload,
});
export const getAllVersionForUser = (data) => {
	return (dispatch) => {
		dispatch(userVersionPending());
		return getAllVersionForUserAPI(data)
			.then((response) => {
				if (response?.data?.isSuccess) {
					const { entity } = response.data || {};
					entity && dispatch(userVersionPageDetails(entity));
					entity?.records && dispatch(userVersionCompleted(entity?.records));
				}
			})
			.catch((error) => {
				dispatch(userVersionFailed(error));
			});
	};
};

export const getVersionDetailsForPagination = (data) => {
	return () => {
		return getAllVersionForUserAPI(data);
	};
};

export const featureDocumentUpload = (data, queryParams) => {
	return featureDocumentUploadAPI(data, queryParams);
};
export const deleteFeatureDocument = (data) => {
	return () => {
		return deleteFeatureDocumentAPI(data);
	};
};

import React, { Fragment, useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Link,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableContainer,
	Paper,
	TableBody,
	Dialog,
	Slide,
	DialogContent,
	DialogContentText,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	MenuList,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
} from '@material-ui/core';
import '../../styles/pages/error-pages/errors.scss';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { DrawerTransitionSetting, getMonthDateYear, getEncryptString, getUserDetails } from '../../config/utils';
import { useHistory } from 'react-router-dom';
import { HOME, ORGANIZATIONS } from '../../config/app-url';
import { AlertDeleteIcon, AlertUnsavedIcon } from '../../config/svg/GlobalSvg';
import GlobalSearch from '../Common/GlobalSearch';
import { EditIcon, KebabIcon } from '../../config/svg/CommonSvg';
import { OKRPopper } from '../Common/OKRPopper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInstance } from '../../action/common';
import AlertDialog from '../Common/Dialog';
import { useSnackbar } from 'notistack';
import { EditOrgDrawer } from './EditOrgDrawer';
import { PopperMenu } from '../Admin/CommonComponent/PopperMenu';
import LinearLoader from '../Common/Loader';
import { OKRDrawer } from '../Common/OKRDrawer';
import { ImpersonateDrawer } from './ImpersonateDrawer';
// import CryptoJS from 'crypto-js';
// interface Window {
// 	ATL_JQ_PAGE_PROPS?: any;
// }
declare global {
	interface Window {
		ATL_JQ_PAGE_PROPS?: any;
	}
}

export const Support: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const supportUserDetails: any = getLocalStorageItem('supportUser');
	const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');

	const [rows, setRows] = useState<any>({});
	const [copyTenantData, setCopyTenantData] = useState<any>([]);
	const [moduleList, setModuleList] = useState<any>({});
	const [openPopper, setOpenPopper] = useState<boolean>(false);
	const [selectedInstance, setSelectedInstance] = useState<any>({});
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [deleteInstanceDialog, setDeleteInstanceDialog] = useState<boolean>(false);
	const [filterValue, setFilterValue] = useState<number>(1);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [drawerProps, setDrawerProps] = useState<any>({ currentItem: '', open: false });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [openSsoDialog, setOpenSsoDialog] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');
	const [moveInstanceDialog, setMoveInstanceDialog] = useState<boolean>(false);
	const [forceLogoutDialog, setForceLogoutDialog] = useState<boolean>(false);
	const [ssoValue, setSsoValue] = useState<any>('');
	const [forceLogoutBtnText, setForceLogoutBtnText] = useState<any>('Force Logout');
	const [isAllForceLogout, setIsAllForceLogout] = useState<boolean>(false);
	const [isForceLogout, setIsForceLogout] = useState<boolean>(true);
	const [downloadReportValue, setDownloadReportValue] = useState<number>(0);
	const [openImpersonateDrawer, setOpenImpersonateDrawer] = useState<any>(false);
	const [paggingInfo, setPaggingInfo] = useState<any>({ pageIndex: 1, pageSize: 15, totalRecords: 0, totalPages: 1 });
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortingColumn, setSortingColumn] = useState('');
	const [searchTextArray, setSearchTextArray] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [userList, setUserList] = useState<any>([]);
	const [loader, setLoader] = useState<boolean>(false);

	useEffect(() => {
		setSearchText('');
		if (filterValue === 1) {
			let filterActive = copyTenantData.filter((item: any) => item.isActive);
			setRows(filterActive);
		} else {
			let filterActive = copyTenantData.filter((item: any) => !item.isActive);
			setRows(filterActive);
		}
	}, [filterValue]);

	useEffect(() => {
		if (downloadReportValue > 0) {
			setIsLoading(true);
			props.showLoader(true);
			props.clientEngagementReport(`reportType=` + downloadReportValue).then((response: any) => {
				setIsLoading(false);
				props.showLoader(false);
				const defaultName = 'ClientEngagementReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		}
	}, [downloadReportValue]);

	useEffect(() => {
		fetchTenantList();
		fetchModuleList();
	}, []);

	const fetchTenantList = () => {
		setIsLoading(true);
		props.showLoader(true);
		setCopyTenantData([]);
		props.getTenantList().then((response: any) => {
			setIsLoading(false);
			props.showLoader(false);
			if (response?.data?.entityList.length) {
				let filterActive = [];
				if (filterValue === 1) {
					filterActive = response.data.entityList.filter((item: any) => item.isActive);
				} else {
					filterActive = response.data.entityList.filter((item: any) => !item.isActive);
				}

				const forceLogoutFlag = response.data.entityList.filter((item: any) => item.isForceLogout && item.isActive);
				setForceLogoutBtnText(forceLogoutFlag.length > 0 ? 'Undo Force Logout' : 'Force Logout');
				setIsForceLogout(forceLogoutFlag.length > 0 ? false : true);

				setRows(filterActive);
				setCopyTenantData(JSON.parse(JSON.stringify(response.data.entityList)));
			}
		});
	};

	const fetchModuleList = () => {
		setIsLoading(true);
		props.showLoader(true);
		props.getModuleList().then((response: any) => {
			setIsLoading(false);
			props.showLoader(false);
			if (response?.data?.entityList.length) {
				let moduleListData = response.data.entityList;
				setModuleList(moduleListData);
			}
		});
	};

	/** Move Db to SSO Login start */
	const handleMoveSSOLogin = async (e: any, instance: any) => {
		setSelectedInstance(instance);
		setMoveInstanceDialog(true);
	};

	const handleMoveDialogAction = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			let data = {
				subDomain: selectedInstance.subDomain,
				tenantId: selectedInstance.tenantId,
				isSSO: selectedInstance.isSSO,
			};
			props.showLoader(true);
			setMoveInstanceDialog(!moveInstanceDialog);
			let moveSSOToDbResponse = await props.moveSSOToDb(data);
			if (moveSSOToDbResponse && moveSSOToDbResponse.data && moveSSOToDbResponse.data.status === 200) {
				props.showLoader(false);
				enqueueSnackbar(
					`${
						moveSSOToDbResponse.data.messageList[0] ? moveSSOToDbResponse.data.messageList[0] : 'Moved Successfully.'
					}`,
					{
						variant: 'success',
					}
				);
				fetchTenantList();
			}
		} else {
			setMoveInstanceDialog(!moveInstanceDialog);
			setSelectedInstance({});
		}
	};
	/** Move Db to SSO Login end */

	/** Force Logout start */
	const handleInstanceForceLogout = (e: any, instance: any) => {
		setIsAllForceLogout(false);
		setSelectedInstance(instance);
		setForceLogoutDialog(true);
	};

	const handleForceLogout = (e: any) => {
		e.preventDefault();
		setIsAllForceLogout(true);
		setForceLogoutDialog(true);
	};

	const handleForceLogoutDialogAction = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			let data = {
				tenantId: selectedInstance.tenantId,
				isForceLogout: isAllForceLogout ? isForceLogout : !selectedInstance.isForceLogout,
				isAllForceLogout: isAllForceLogout,
			};
			props.showLoader(true);
			setForceLogoutDialog(!forceLogoutDialog);
			let forceLogoutResponse = await props.updateForceLogout(data);
			if (forceLogoutResponse && forceLogoutResponse.data && forceLogoutResponse.data.status === 200) {
				props.showLoader(false);
				enqueueSnackbar(
					`${
						forceLogoutResponse.data.messageList
							? forceLogoutResponse.data.messageList.success
							: 'Logged Out Successfully.'
					}`,
					{
						variant: 'success',
					}
				);
				fetchTenantList();
			}
		} else {
			setForceLogoutDialog(!forceLogoutDialog);
		}
	};
	/** Force Logout  end */

	/** Delete instance start */
	const handleDeleteInstance = (e: any, instance: any) => {
		setSelectedInstance(instance);
		setDeleteInstanceDialog(true);
	};

	const handleDeleteDialogAction = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			let domain = {
				domains: [selectedInstance.subDomain],
			};
			props.showLoader(true);
			setDeleteInstanceDialog(!deleteInstanceDialog);
			let dataRes = await props.deleteInstance(domain);
			if (dataRes && dataRes.data && dataRes.data.status === 200) {
				props.showLoader(false);
				fetchTenantList();
				setSelectedInstance({});
			} else {
				const responseAPI = dataRes.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				// props.showApiMsgs(`${messages}`, 'error');
				enqueueSnackbar(`${messages}`, {
					variant: 'error',
				});
			}
		} else {
			setDeleteInstanceDialog(!deleteInstanceDialog);
			setSelectedInstance({});
		}
	};
	/** Delete instance end */

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenPopper((prev) => !prev);
	};

	const handleImpersonate = (e: any, instance: any) => {
		setLocalStorageItem('TenantId', instance.tenant);
		setSelectedInstance(instance);
		setOpenDialog(true);
	};

	const handleCloseModal = (e: any) => {
		setOpenDialog(false);
		setOpenSsoDialog(false);
		setSelectedInstance({});
	};

	// const getEncryptString = (plainText: string) => {
	// 	var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_Key);
	// 	var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);
	// 	var encryptedToken = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key, {
	// 		keySize: 128 / 8,
	// 		iv: iv,
	// 		mode: CryptoJS.mode.CBC,
	// 		padding: CryptoJS.pad.Pkcs7,
	// 	});
	// 	var result = CryptoJS.enc.Base64.stringify(encryptedToken.ciphertext);
	// 	return result;
	// };

	const onSelectedUser = async (user: any) => {
		let isImpersonate = true;
		let selectedUser = user[0];
		let plaintText = {
			isImpersonate: isImpersonate,
			emailId: selectedUser.emailId,
			impersonatedByUser: userDetail && userDetail.emailId,
		};
		let domainString = getEncryptString(JSON.stringify(plaintText));
		console.log(plaintText, domainString);
		let url = `https://${selectedInstance.subDomain}/secretlogin?code=${domainString}`;
		window.open(url, '_blank');
		setOpenDialog(false);
	};

	const handleImpersonateUserClick = async (e: any, user: any) => {
		let isImpersonate = true;
		let plaintText = {
			isImpersonate: isImpersonate,
			emailId: user.emailId,
			impersonatedByUser: userDetail && userDetail.emailId,
		};
		let domainString = getEncryptString(JSON.stringify(plaintText));
		console.log(plaintText, domainString);
		let url = `https://${selectedInstance.subDomain}/secretlogin?code=${domainString}`;
		window.open(url, '_blank');
	};

	const handleAddOrganisation = (e: any) => {
		e.preventDefault();
		history.push('/free-trial');
		// window.open('https://unlockokr.com/lp/okr-signup/', '_blank');
	};

	const handleEdit = (row: any) => {
		setSelectedInstance(row);
		console.log('selectedInstance', selectedInstance);
		setDrawerProps({ open: true });
	};

	const handleSearch = (e: any) => {
		const { value } = e.target;
		// let filterBySearch = copyTenantData.filter((item: any) => item.isActive);
		let filterBySearch = copyTenantData.filter((element: any) => {
			if (filterValue === 1) {
				if (
					(element.companyName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
						element.subDomain.toLowerCase().indexOf(value.toLowerCase()) > -1) &&
					element.isActive
				) {
					return element;
				}
			} else {
				if (
					(element.companyName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
						element.subDomain.toLowerCase().indexOf(value.toLowerCase()) > -1) &&
					element.isActive === false
				) {
					return element;
				}
			}
		});
		setRows(filterBySearch);
		setSearchText(value);
	};

	const handleFederated = async (e: any, instance: any) => {
		let data = { subDomain: instance.subDomain, emailDomain: instance?.emailDomain };
		let federatedResponse = await props.convertFederated(data);
		if (federatedResponse && federatedResponse.data && federatedResponse.data.ststusCode === 200) {
			// props.showApiMsgs(federatedResponse.data.message, 'success');
			enqueueSnackbar(`${federatedResponse.data.message}`, {
				variant: 'success',
			});
			fetchTenantList();
		}
	};

	const handleSSO = (e: any, instance: any) => {
		setSsoValue(instance.emailDomain);
		setOpenSsoDialog(true);
		setSelectedInstance(instance);
	};

	const handleSSOValue = (e: any) => {
		const { value } = e.target;
		setSsoValue(value);
	};

	const handleSaveSSO = async () => {
		let data = { subDomain: selectedInstance.subDomain, emailDomain: ssoValue };
		props.showLoader(true);
		let federatedResponse = await props.convertFederated(data);
		if (federatedResponse && federatedResponse.data && federatedResponse.data.ststusCode === 200) {
			// props.showApiMsgs(federatedResponse.data.message, 'success');
			props.showLoader(false);
			enqueueSnackbar(`${federatedResponse.data.message}`, {
				variant: 'success',
			});
			setSelectedInstance({});
			setOpenSsoDialog(false);
			fetchTenantList();
			setSsoValue('');
		} else {
			props.showLoader(false);
			setSsoValue('');
			enqueueSnackbar(`${federatedResponse.data.message}`, {
				variant: 'error',
			});
		}
	};

	const handleOpenImpersonate = (e: any, instance: any) => {
		setLocalStorageItem('TenantId', instance.tenant);
		setSelectedInstance(instance);
		fetchUsers({
			listingPageIndex: pageIndex,
			listingPageSize: pageSize,
			sortingColumn: sortingColumn,
			sortOrder: sortOrder,
			finder: searchTextArray,
		});
		setOpenImpersonateDrawer(true);
	};
	const handleDrawerAction = (event: any, type: string) => {
		setOpenImpersonateDrawer(false);
	};

	const handleChange = (e: any) => {
		setSearchValue(e.target.value);
	};
	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleUserSearch(e.target.value);
			}
			setSearchValue('');
		}
	};
	const handleUserSearch = (value: any) => {
		const updatedValue: any = [value];
		setPageIndex(1);
		setSearchTextArray(updatedValue);
		fetchUsers({
			listingPageIndex: 1,
			listingPageSize: pageSize,
			sortingColumn: '',
			sortOrder: '',
			finder: updatedValue,
		});
	};

	const clearSearch = () => {
		setSearchTextArray([]);
		setPageIndex(1);
		fetchUsers({
			listingPageIndex: 1,
			listingPageSize: pageSize,
			sortingColumn: '',
			sortOrder: '',
			finder: [],
		});
	};

	const handleSortUsers = () => {
		fetchUsers({
			listingPageIndex: pageIndex,
			listingPageSize: pageSize,
			sortingColumn: 'firstName',
			sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
			finder: searchTextArray,
		});
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	const loadMoreUser = () => {
		fetchUsers({
			listingPageIndex: pageIndex + 1,
			listingPageSize: pageSize,
			sortingColumn: sortingColumn,
			sortOrder: sortOrder,
			finder: searchTextArray,
		});
		setPageIndex(pageIndex + 1);
	};

	const fetchUsers = ({
		listingPageIndex,
		listingPageSize,
		sortingColumn = '',
		sortOrder = '',
		finder = [],
	}: {
		listingPageIndex: number;
		listingPageSize: number;
		sortingColumn: string;
		sortOrder: string;
		finder: any;
	}) => {
		setLoader(true);
		listingPageIndex = listingPageIndex > 0 ? listingPageIndex : 1;
		props
			.getAllUserList({
				pageIndex: listingPageIndex,
				pageSize: listingPageSize,
				sortingText: sortingColumn,
				order: sortOrder,
				finder: finder,
				teamToFilter: [],
			})
			.then((response: any) => {
				setLoader(false);
				if (pageIndex > 1) {
					setUserList([...userList, ...response?.data?.entityList]);
				} else {
					setUserList(response?.data?.entityList);
				}
				setPaggingInfo(response?.data?.paggingInfo);
				setPageIndex(response?.data?.paggingInfo?.pageIndex);
			});
	};
	return (
		<Fragment>
			<Box className='internal-server-error-area1'>
				<Box className='page-content-wrapper'>
					<Box className='admin-role-tab orgList support-org-wrapper'>
						<Box display={'flex'} paddingBottom={2} justifyContent='space-between'>
							<Box className='filters'>
								<FormControl>
									<Select
										id='select-filter'
										className='select-filter'
										value={filterValue}
										onChange={(e: any) => setFilterValue(e.target.value)}
										label='Filter'
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left',
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'left',
											},
											classes: { paper: 'okr-default-dropdown select-filter-dropdown' },
										}}
									>
										<MenuItem value={1}>Active</MenuItem>
										<MenuItem value={2}>Inactive</MenuItem>
									</Select>
								</FormControl>

								<TextField
									className='ml-10'
									placeholder='Search instance...'
									value={searchText}
									onChange={handleSearch}
								/>
							</Box>
							<Box>
								<OKRButton
									text={forceLogoutBtnText}
									className='btn-primary'
									handleClick={handleForceLogout}
									color='primary'
								></OKRButton>
								<OKRButton
									text='Add Client'
									className='btn-primary'
									handleClick={handleAddOrganisation}
									color='primary'
								></OKRButton>
								<FormControl>
									<Select
										id='select-filter'
										className='select-filter download-dropdown'
										value={downloadReportValue}
										onChange={(e: any) => setDownloadReportValue(e.target.value)}
										label='Filter'
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left',
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'left',
											},
											classes: { paper: 'okr-default-dropdown select-filter-dropdown' },
										}}
									>
										<MenuItem value={0}>Reports</MenuItem>
										<MenuItem value={1}>Client</MenuItem>
										<MenuItem value={2}>Prospects</MenuItem>
										<MenuItem value={3}>Internal</MenuItem>
										<MenuItem value={4}>Purge</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</Box>
						<TableContainer component={Paper}>
							<Table aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell align='left'>
											<b>Client Name</b>
										</TableCell>
										<TableCell align='left'>
											<b>Sub Domain</b>
										</TableCell>
										<TableCell align='center'>
											<b>Instance Type</b>
										</TableCell>
										<TableCell align='center'>
											<b>SSO Type</b>
										</TableCell>
										<TableCell align='center'>
											<b>Purchase License</b>
										</TableCell>
										<TableCell align='center'>
											<b>Buffer License</b>
										</TableCell>
										<TableCell align='left'>
											<b>Created On</b>
										</TableCell>
										<TableCell align='center'>
											<b>Expiry Date</b>
										</TableCell>
										<TableCell align='center'>
											<b>Action</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows && rows.length > 0 ? (
										<>
											{rows.map((row: any, index: number) => (
												<TableRow key={`${index}-122`}>
													<TableCell align='left'>{row.companyName ? row.companyName : '-'}</TableCell>
													<TableCell align='left'>{row.subDomain}</TableCell>
													<TableCell align='center'>
														{row.isDemoClient ? 'Demo' : row.isLicensed ? 'Paid' : 'Free'}
													</TableCell>
													<TableCell align='center'>{row.isFederated ? 'Federated' : 'Non Federated'}</TableCell>
													<TableCell align='center'>{row.purchaseLicense}</TableCell>
													<TableCell align='center'>{row.bufferLicense ? row.bufferLicense : '-'}</TableCell>
													<TableCell align='left'>{getMonthDateYear(row.createdOn)}</TableCell>
													<TableCell align='center'>
														{row.demoExpiryDate ? getMonthDateYear(row.demoExpiryDate) : '-'}
													</TableCell>
													<TableCell align='center'>
														<OKRPopper
															{...props}
															handleEdit={handleEdit}
															setDrawerProps={setDrawerProps}
															setSelectedInstance={setSelectedInstance}
															instance={row}
															handleImpersonate={handleImpersonate}
															popperClassName='popper-menu-list acceptance-menu-list'
															handleDeleteInstance={handleDeleteInstance}
															handleSSO={handleSSO}
															handleFederated={handleFederated}
															handleMoveSSOLogin={handleMoveSSOLogin}
															handleInstanceForceLogout={handleInstanceForceLogout}
															handleOpenImpersonate={handleOpenImpersonate}
														></OKRPopper>
													</TableCell>
												</TableRow>
											))}
										</>
									) : (
										<>
											<TableRow>
												<TableCell colSpan={8} align='center'>
													{isLoading ? 'Loading...' : 'No Record'}
												</TableCell>
											</TableRow>
										</>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Box>
			{openDialog && (
				<Dialog
					className={`default-dialog`}
					open={openDialog}
					keepMounted
					transitionDuration={DrawerTransitionSetting}
					// TransitionComponent={Transition}
					onClose={(e) => handleCloseModal(e)}
					aria-labelledby='alert-dialog-slide-title'
					aria-describedby='alert-dialog-slide-description'
				>
					<DialogContent className='dialogWrapperSupport'>
						<AlertUnsavedIcon />
						<Typography>Search and select a user to impersonate</Typography>
						<DialogContentText id='alert-dialog-slide-description'>
							<Box>
								<GlobalSearch
									{...props}
									module={'profile'}
									customClass={'user-quick-search-type2'}
									placeHolderText={'Search Employee'}
									customId={'profile-user-src'}
									onSelectedUser={onSelectedUser}
									fetchCycleUsers={true}
								/>
							</Box>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)}

			{deleteInstanceDialog && (
				<AlertDialog
					message={'Are you sure want delete this instance?'}
					handleCloseModal={handleDeleteDialogAction}
					modalOpen={deleteInstanceDialog}
				/>
			)}
			{moveInstanceDialog && (
				<AlertDialog
					message={'Are you sure want to move?'}
					handleCloseModal={handleMoveDialogAction}
					modalOpen={moveInstanceDialog}
				/>
			)}
			{forceLogoutDialog && (
				<AlertDialog
					message={'Are you sure want to ' + forceLogoutBtnText + '?'}
					handleCloseModal={handleForceLogoutDialogAction}
					modalOpen={forceLogoutDialog}
				/>
			)}
			{drawerProps.open && (
				<EditOrgDrawer
					{...props}
					currentItem={selectedInstance}
					drawerProps={drawerProps}
					setDrawerProps={setDrawerProps}
					setSelectedInstance={setSelectedInstance}
					fetchTenantList={fetchTenantList}
					moduleList={moduleList}
				/>
			)}

			{openSsoDialog && (
				<Dialog
					className={`default-dialog`}
					open={openSsoDialog}
					keepMounted
					transitionDuration={DrawerTransitionSetting}
					// TransitionComponent={Transition}
					onClose={(e) => handleCloseModal(e)}
					aria-labelledby='alert-dialog-slide-title'
					aria-describedby='alert-dialog-slide-description'
				>
					<DialogContent className='dialogWrapperSupport'>
						<AlertUnsavedIcon />
						<DialogContentText id='alert-dialog-slide-description'>
							<Box style={{ marginBottom: '20px' }}>
								<TextField placeholder='Enter domain name' fullWidth value={ssoValue} onChange={handleSSOValue} />
							</Box>
							<Box>
								<OKRButton
									disabled={ssoValue === ''}
									text='Save'
									className='btn-primary'
									handleClick={handleSaveSSO}
									color='primary'
								></OKRButton>
								<OKRButton
									text='Cancel'
									className='btn-primary'
									handleClick={() => {
										setSelectedInstance({});
										setOpenSsoDialog(false);
									}}
									color='primary'
								></OKRButton>
							</Box>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
			{openImpersonateDrawer && (
				<OKRDrawer
					open={openImpersonateDrawer}
					transitionDuration={{ enter: 500, exit: 1000 }}
					drawerClassName={'main-drawer-panel main-drawer-titlepanel '}
					headerTitle={t('userList')}
					children={
						<Box className='drawer-inner-content'>
							<Box className='drawer-content-panel'>
								<ImpersonateDrawer
									{...props}
									selectedInstance={selectedInstance}
									userList={userList}
									paggingInfo={paggingInfo}
									loadMoreUser={loadMoreUser}
									handleSortUsers={handleSortUsers}
									sortOrder={sortOrder}
									searchValue={searchValue}
									handleChange={handleChange}
									searchClicked={searchClicked}
									clearSearch={clearSearch}
									searchTextArray={searchTextArray}
									handleImpersonateUserClick={handleImpersonateUserClick}
								/>
							</Box>
						</Box>
					}
					loader={loader}
					moreButton={false}
					hideDefaultButton={true}
					handleDrawerClose={(event) => handleDrawerAction(event, 'cancel')}
					handleCancelClick={(event) => handleDrawerAction(event, 'cancel')}
				/>
			)}
		</Fragment>
	);
};

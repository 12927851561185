import React, { Fragment } from 'react';
import {
	Typography,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Avatar,
	CardActions,
	Box,
	Tooltip,
} from '@material-ui/core';
import { KebabIcon, DeleteIcon, OrganizationIcon } from '../../../config/svg/CommonSvg';
import { getUserName } from '../../../config/utils';
import { useTranslation } from 'react-i18next';
import { checkPermission } from '../../../config/rolePermission';
import { deleteTeams, teamsPermissionModule } from '../AdminConstant';
import { createTeams, editMainOrganization } from '../AdminConstant';

const OrganizationChart = (props) => {
	const { nodeData, handleToggle, handleDeleteOrganization, getPermissionResult } = props;
	const { t } = useTranslation();

	return (
		<Box className='edit-chart-wrapper' id={`org-card-${nodeData?.teamId}`}>
			<Box className={nodeData.highlight ? 'oc-node highlight-node-default' : 'oc-node'}>
				<Card className='org-node-card'>
					<Box className='card-head-action'>
						{checkPermission(getPermissionResult.employeePermissions, editMainOrganization) &&
						checkPermission(getPermissionResult.employeePermissions, createTeams) ? (
							<Tooltip title={t('moreOptions')} arrow>
								<IconButton
									aria-label='settings'
									aria-controls='simple-menu'
									aria-haspopup='true'
									onClick={(e) => handleToggle(e, e.currentTarget, nodeData)}
									id='org-menu-btn'
								>
									<KebabIcon />
								</IconButton>
							</Tooltip>
						) : (
							<></>
						)}
					</Box>
					{nodeData.parentId === 0 ? (
						<CardHeader
							avatar={
								nodeData.logoImagePath ? (
									<Avatar alt={nodeData.teamName} className='' src={nodeData.logoImagePath} />
								) : (
									<Avatar alt={nodeData.teamName} className=''>
										{getUserName({
											firstName: '',
											lastName: '',
											fullName: nodeData.teamName || '',
										})}
									</Avatar>
									// <Box className='no-org-image'>
									// 	<OrganizationIcon />
									// </Box>
								)
							}
							title={
								props.isOrgDashboard ? (
									<Typography
										className='org-title'
										variant='h3'
										align='center'
										onClick={(e) => props.handleViewOrganisation(e, nodeData)}
									>
										{nodeData.teamName}
									</Typography>
								) : (
									<Typography variant='h3' align='center'>
										{nodeData.teamName}
									</Typography>
								)
							}
						/>
					) : (
						<>
							{props.isOrgDashboard ? (
								<CardHeader
									title={
										<Typography
											variant='h3'
											className='org-title'
											onClick={(e) => props.handleViewOrganisation(e, nodeData)}
										>
											{nodeData.teamName}
										</Typography>
									}
								/>
							) : (
								<CardHeader title={<Typography variant='h3'>{nodeData.teamName}</Typography>} />
							)}
						</>
					)}
					<CardContent>
						{nodeData.teamHeadImage ? (
							<Box className='map-user-avatar'>
								<img alt={nodeData.teamHeadName} src={nodeData.teamHeadImage ? nodeData.teamHeadImage : null} />
							</Box>
						) : (
							<Box className='map-user-avatar'>
								<Typography>{getUserName({ fullName: nodeData.teamHeadName })}</Typography>
							</Box>
						)}
						<Typography variant='h4'>
							{nodeData?.teamHeadName?.trim() !== '' ? nodeData.teamHeadName : t('OrgLeader')}
						</Typography>
						<Typography variant='body2'>{'Leader'}</Typography>
						<Typography className='org-people-count'>{nodeData.employeeCount}</Typography>
					</CardContent>
					<CardActions>
						{!props.isOrgDashboard && nodeData.parentId !== 0 ? (
							<Fragment>
								<Box className='map-delete-icon' onClick={(e) => handleDeleteOrganization(e, nodeData)}>
									{checkPermission(getPermissionResult.employeePermissions, deleteTeams) ? (
										<span>
											<DeleteIcon />
										</span>
									) : (
										<></>
									)}
								</Box>
							</Fragment>
						) : (
							<Box className='map-delete-icon'></Box>
						)}
					</CardActions>
				</Card>
			</Box>
		</Box>
	);
};

export default OrganizationChart;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PopperMenu } from '../Admin/CommonComponent/PopperMenu';
import { BottomArrowIcon, ReportsIcon, TeamReportsIcon, OrgIcon, OrgReportsIcon } from '../../config/svg/CommonSvgIcon';
import { getLocalStorageItem } from '../../services/StorageService';
import { getUserName, getUserDetails, getRandomBackgroundColor, getRandomColor } from '../../config/utils';

export const ReportTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`admin-tab-${index}`}
			aria-labelledby={`admin-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

ReportTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `report-tabpanel-${index}`,
		'aria-controls': `admin-tabpanel-${index}`,
	};
}

export const HeaderTabs: React.FC<any> = (props) => {
	const { handleTabChange = {}, tabSelected = 0, setTabSelectedTeam, getLeadingTeamsDetails, leadingTeams } = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const loggedInUserDetail = getUserDetails();

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
		if (!leadingTeams || leadingTeams.length === 0) {
			prepareTeamsData();
		}
	};
	const prepareTeamsData = async () => {
		const selectedCycleId = getLocalStorageItem('cycleId') || 0;
		let data = `cycleId=${selectedCycleId}`;
		await getLeadingTeamsDetails(data);
	};
	const handleClose = (teamId: any) => {
		setAnchorEl(null);
		setOpen(false);
		setTabSelectedTeam(teamId);
	};

	useEffect(() => {
		if (tabSelected !== 1) {
			setTabSelectedTeam(0);
		}
	}, [tabSelected]);

	return (
		<>
			<Tabs className='okr-tabs report-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Report Tabs'>
				<Tab icon={<ReportsIcon />} label={t('myReportsLabel')} value={0} {...a11yProps(0)} />
				{loggedInUserDetail.isTeamLeader !== false && (
					<Tab
						aria-haspopup='true'
						icon={<TeamReportsIcon />}
						label={
							<>
								{t('teamReportsLabel')}
								{leadingTeams.length > 1 && (
									<span className='arrowIcon' onClick={handleClick}>
										<BottomArrowIcon />
									</span>
								)}
							</>
						}
						value={1}
						className={open && leadingTeams.length > 1 ? 'menu-open' : ''}
						{...a11yProps(1)}
					/>
				)}
				{(loggedInUserDetail.roleId === 1 || loggedInUserDetail.roleId === 2) && (
					<Tab icon={<OrgReportsIcon />} label={t('orgReportLabel')} value={2} {...a11yProps(2)} />
				)}
				{/* <Tab icon={<ReportsIcon />} label={'Reports Part 2'} value={3} {...a11yProps(3)} /> */}
			</Tabs>
			<PopperMenu
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				connectorIcon={true}
				popperClassName={'popper-menu popper-menu-type1 popper-team-menu'}
				popperPlacement={'bottom-start'}
				{...props}
				children={
					<>
						<ListItem
							onClick={() => {
								handleClose('All');
							}}
							id='all-teams'
						>
							<ListItemAvatar>
								<Avatar className='all-team team-avatar-small'>
									<OrgIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={'All Teams'} />
						</ListItem>
						{leadingTeams?.map((team: any) => {
							return (
								<ListItem
									key={team.teamId}
									onClick={() => {
										handleClose(team.teamId);
									}}
									id={`leading-teams-${team?.teamId}`}
								>
									<ListItemAvatar>
										{team.teamLogo ? (
											<Avatar className='team-avatar-small' src={team.teamLogo} />
										) : (
											<Avatar
												className='team-avatar-small'
												style={{
													backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
													color: getRandomColor(team.colorCode),
												}}
											>
												{getUserName({ fullName: team.teamName, firstName: '', lastName: '' })}
											</Avatar>
										)}
									</ListItemAvatar>
									<ListItemText primary={team.teamName} />
								</ListItem>
							);
						})}
					</>
				}
			></PopperMenu>
		</>
	);
};
